<template>
  <v-textarea
    :value="value"
    filled
    :error="error"
    :error-count="1"
    :error-messages="errorMessage"
    :placeholder="placeholder"
    :label="label"
    @input="handleInput"
  />
</template>

<script>
export default {
  name: 'FormFieldTextArea',
  props: {
    error: {
      type: Boolean,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
@include text-field-filled-bg-color;
</style>