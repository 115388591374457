<template>
  <v-alert
    outlined
    type="error"
    class="form-error"
  >
    <slot />
  </v-alert>
</template>

<script>
export default {
  name: 'CheckoutError',
};
</script>

<style lang="scss" scoped>
.form-error {
  // Vuetify styles use !important, so overrides need !important
  border-width: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 90% !important;

  // This is not ideal, but it works.
  >>> .v-icon {
    color: $form-error-text-color !important;
  }
}
</style>
