<script>
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import SchemaFormFieldBoolean from '@/onboarding/components/formfields/SchemaFormFieldBoolean';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';
import {
  applicationIsProgram2,
  getFullAddressState,
} from '@/onboarding/lib/selectors/storeSelectors';
import { stateNameMap } from '@/onboarding/lib/selectors/addressSelectors';

const formFieldData = [
  {
    component: SchemaFormFieldBoolean,
    schemaPath: '2019-07-01--HAS_CURRENT_CLAIMS',
    dataPath: '2019-07-01--INSURANCE_HISTORY_CONTAINER.2019-07-01--HAS_CURRENT_CLAIMS',
  },
  {
    component: SchemaFormFieldBoolean,
    schemaPath: '2019-07-01--HAS_FILED_LIABILITY_CLAIM',
    dataPath: '2019-07-01--INSURANCE_HISTORY_CONTAINER.2019-07-01--HAS_FILED_LIABILITY_CLAIM',
  },
];
export default {
  name: 'InsuranceHistoryCard',
  components: { SchemaFormFieldBoolean },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('core'),
      submitPath: getUpdateEndpoint('core'),
      cardConfigPath: '2019-07-01--INSURANCE_HISTORY_CONTAINER',
      formFieldData:
        // We can't ask the HAS_DENIED_COVERAGE question in Missouri, but only in Program 2
        (applicationIsProgram2(this) && getFullAddressState(this)) === stateNameMap.MO
          ? formFieldData
          : [
              ...formFieldData,
              {
                component: SchemaFormFieldBoolean,
                schemaPath: '2019-07-01--HAS_DENIED_COVERAGE',
                dataPath: '2019-07-01--INSURANCE_HISTORY_CONTAINER.2019-07-01--HAS_DENIED_COVERAGE',
              },
            ],
    };
  },
};
</script>
