<script>
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import SchemaFormFieldNumber from '@/onboarding/components/formfields/SchemaFormFieldNumber';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';

// TODO
export default {
  name: 'InvoluntaryTerminationCard',
  components: { SchemaFormFieldNumber },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('core'),
      submitPath: getUpdateEndpoint('core'),
      cardConfigPath: '2019-07-01--NUM_INVOLUNTARY_TERMINATION',
      formFieldData: [
        {
          component: SchemaFormFieldNumber,
          dataPath: '2019-07-01--NUM_INVOLUNTARY_TERMINATION',
          schemaPath: '2019-07-01--NUM_INVOLUNTARY_TERMINATION',
        },
      ],
    };
  },
};
</script>
