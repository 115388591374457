<template>
  <span />
</template>

<script>
import BaseSchemaFormField from './BaseSchemaFormField';

// Use this class if you have a field that:
//  - should not be shown
//  - just needs to be persisted on future requests
// Potential use cases: ids, persisted meta fields,
//  or grouped fields that are not being shown on this page.

export default {
  name: 'SchemaFormNoopField',
  extends: BaseSchemaFormField,
  methods: {
    init() {
      this.emitInput(this.value, true);
    },
  },
};
</script>
