<template>
  <a
    class="vouch-link"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'LinkExternal',
};
</script>

<style lang="scss" scoped>
.vouch-link {
  color: currentColor;
  text-decoration: underline;

  transition: color $easing-timing;

  &:hover {
    color: $link-text-color;
  }
}
</style>
