<template>
  <div>
    <slot :cardData="finalCardData" />
  </div>
</template>
<script>
import { getApplicationData } from '../../../onboarding/lib/selectors/storeSelectors';
import { marshallCardData } from '@/onboarding/services/marshallCardData';

export default {
  name: 'WithCardData',
  dependencies: ['SchemaServiceFactory', 'FunnelCardSchemaHelper'],
  props: {
    cardData: {
      type: Object,
      default: () => ({}),
    },
    schemaPath: {
      type: String,
      required: true,
    },
    cardConfigPath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      schemaCardData: {},
    };
  },
  computed: {
    finalCardData() {
      return { ...this.schemaCardData, ...this.cardData };
    },
  },
  watch: {
    cardData: {
      handler: 'computeSchemaData',
      immediate: true,
    },
  },
  methods: {
    async computeSchemaData() {
      this.schemaCardData = marshallCardData(this.cardConfigPath, getApplicationData(this));
    },
  },
};
</script>
