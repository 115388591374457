const pricingDetailsForPaymentSchedule = (pricingDetails, paymentSchedule) => {
  return pricingDetails.find(p => {
    return p.billingInterval.interval === paymentSchedule;
  });
};

const totalActivePricingItemsTaxesFees = items => {
  return items.reduce((sum, item) => {
    return sum + item.amountCents;
  }, 0);
};

const totalActivePricingItems = items => {
  return items.reduce((sum, item) => {
    if (item.active && item.eligible) {
      return sum + item.premiumCents;
    }
    return sum;
  }, 0);
};

export const getPartnerDiscountAmount = (quotePricingDetails, paymentSchedule) => {
  if (quotePricingDetails.length === 0) return 0;
  const pricingDetails = pricingDetailsForPaymentSchedule(quotePricingDetails, paymentSchedule);

  return pricingDetails.discounts.reduce((sum, item) => {
    if (item.active && item.eligible && item.uiToken === 'partnership_discount') {
      return sum + item.premiumCents;
    }
    return sum;
  }, 0);
};

export const totalOfType = (type, quotePricingDetails, paymentSchedule) => {
  if (quotePricingDetails.length === 0) return 0;

  const pricingDetails = pricingDetailsForPaymentSchedule(quotePricingDetails, paymentSchedule);
  if (type === 'taxes' || type === 'fees') {
    return totalActivePricingItemsTaxesFees(pricingDetails[type]);
  }
  return totalActivePricingItems(pricingDetails[type]);
};
