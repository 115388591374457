<template>
  <IsMobile v-slot="{ isMobile }">
    <component
      :is="officerSignatureComponent"
      v-if="readyState"
      :package-slug="packageSlug"
      :application-id="applicationId"
      :is-mobile="isMobile"
    />
  </IsMobile>
</template>

<script>
import IsMobile from '@/shared/frames/IsMobile';
import { PackageConfig } from '@/onboarding/constants/PackageConfig';
import { fetchInitialData } from '@/shared/routing/fetchInitialData';
import { get } from '@/shared/lib/vouch_dash';

export default {
  name: 'OfficerSignaturePage',
  dependencies: ['tracking'],
  components: {
    IsMobile,
  },
  props: {
    packageSlug: {
      type: String,
      required: true,
    },
    applicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      readyState: false,
    };
  },
  computed: {
    officerSignatureComponent() {
      return PackageConfig[this.packageSlug].officerSignatureComponent;
    },
  },
  async beforeMount() {
    if (!this.stateIsInitialized()) {
      await fetchInitialData(this.$store, { applicationId: this.applicationId });
    }
    this.readyState = true;
  },
  methods: {
    stateIsInitialized() {
      return get(this.$store, `state.applicationId`, null);
    },
  },
};
</script>
