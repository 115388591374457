import { RenewalNavSteps } from '@/members/constants/Constants';
import {
  generateCardConfigsFromJson,
  generateNicheCardsFunnelWizardConfig,
  getAllNicheQuestions,
} from '@/onboarding2/niche.domain';

const nicheConfig = generateNicheCardsFunnelWizardConfig(
  generateCardConfigsFromJson(getAllNicheQuestions()),
  5,
  RenewalNavSteps.REFRESH_INFO
);

export const renewalWizardConfigProgram2 = {
  'renewal-start': { percent: 1, step: RenewalNavSteps.REFRESH_INFO, hasBackButton: false },
  '2019-07-01--NUM_FULL_TIME_WORKERS': { percent: 1, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--CAPITAL_RAISED_CONTAINER': { percent: 1, step: RenewalNavSteps.REFRESH_INFO },
  ...nicheConfig,
  '2019-07-01--HAS_INSURANCE_REQS': { percent: 16, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--HAS_INSURANCE_REQS_DESC': { percent: 16, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--LOCATION_CONTAINER': { percent: 16, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--LOCATION_CONTAINER_attributes': {
    percent: 16,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2019-07-01--LOCATIONS': { percent: 16, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--HAS_BIG_TICKET_ITEM': { percent: 16, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--BIG_TICKET_ITEM_DESC': { percent: 16, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_OTHER_BOARD_DIRECTORS': {
    percent: 16,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--LEAD_INVESTOR_LAST_ROUND': {
    percent: 16,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--FOUNDER_PREVIOUS_RAISE': { percent: 16, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--PERCENT_OWNED_NONVC': { percent: 16, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--PREF_SHARES_SALES': { percent: 16, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--SHARES_PUBLICLY_LISTED': { percent: 20, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--ANNUAL_REVENUE_CONTAINER': {
    percent: 20,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2019-07-01--NUM_BIG_REVENUE_CONTRACTS': {
    percent: 20,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2019-07-01--TOTAL_CASH_AND_EQUIVALENTS_CENTS': {
    percent: 20,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--ACQUIRE_10M_COMPANY': { percent: 20, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_401K': { percent: 20, step: RenewalNavSteps.REFRESH_INFO },
  '2021-16-02--SUBJECT_TO_ERISA': { percent: 20, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--BENEFITS_WORTH': { percent: 20, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_PRODUCT_IN_MARKET': { percent: 20, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--OFFERS_CONSULTING': { percent: 20, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_ANY_PII': { percent: 20, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--PII_AMOUNT': { percent: 20, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--SOC_COMPLIANCE': { percent: 20, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--LOGIN_REQUIRES_MFA': { percent: 1, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_ENGINEERING_CONTRACTORS': {
    percent: 30,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2019-07-01--NUM_ENGINEERING_CONTRACTORS': {
    percent: 30,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2019-07-01--CURRENT_CLAIMS_DESCRIPTION': {
    percent: 30,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021_06_01--CURRENT_CLAIMS_NUM': { percent: 30, step: RenewalNavSteps.REFRESH_INFO },
  // // Supplementals
  '2021-06-01--HAS_DANGEROUS_PRODUCTS': { percent: 30, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--HAS_DANGEROUS_PRODUCTS_DESC': {
    percent: 30,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--MANUFACTURING_STATUS': { percent: 30, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--MANUFACTURING_STATUS_DESC': {
    percent: 30,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--CAUSED_HARM': { percent: 40, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--CAUSED_HARM_DESC': { percent: 40, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--HAS_PHYSICAL_RETAIL': { percent: 40, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--MEDIA_HAS_STATE_REG_DEMANDS': {
    percent: 40,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--MEDIA_HAS_STATE_REG_DEMANDS_DESC': {
    percent: 40,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--MEDIA_HAS_ACT_RELATED_DEMANDS': {
    percent: 40,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--MEDIA_HAS_ACT_RELATED_DEMANDS_DESC': {
    percent: 40,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--MEDIA_HAS_COPYRIGHT_LIBEL_PRIVACY_DEMANDS': {
    percent: 40,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--MEDIA_HAS_COPYRIGHT_LIBEL_PRIVACY_DEMANDS_DESC': {
    percent: 40,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS': {
    percent: 40,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS_DESC': {
    percent: 50,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--MEDIA_CONTROV_MATERIAL': { percent: 50, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--MEDIA_CONTROV_MATERIAL_DESC': {
    percent: 50,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--MEDIA_NUM_CONTENT_CONSUMERS': {
    percent: 50,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--MEDIA_MAKES_OR_SELLS_PG': { percent: 50, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--MEDIA_MAKES_OR_SELLS_PG_DESC': {
    percent: 50,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--MEDIA_LOANS_OR_TRANSFERS': {
    percent: 50,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--MEDIA_LOANS_OR_TRANSFERS_DESC': {
    percent: 50,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES': {
    percent: 50,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES_DESC': {
    percent: 50,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--TRANSP_LICENSING_DISPUTES': {
    percent: 60,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--TRANSP_LICENSING_DISPUTES_DESC': {
    percent: 60,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--TRANSP_OWNS_TRANSPORT': { percent: 60, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--TRANSP_OWNS_TRANSPORT_DESC': {
    percent: 60,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--PS_PROF_SERVICES': { percent: 60, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--PS_PROF_SERVICES_DESC': { percent: 60, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--FT_OPERATES_FINTECH': { percent: 60, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--PS_DEMANDS_MADE': { percent: 60, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--PS_DEMANDS_MADE_DESC': { percent: 60, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--FT_OPERATES_FINTECH_DESC': {
    percent: 60,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--FT_HAS_DEMANDS': { percent: 60, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--FT_HAS_DEMANDS_DESC': { percent: 60, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--FT_GTM_STRATEGY': { percent: 60, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--FT_ANNUAL_LOANS': { percent: 60, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--FT_INTERNATIONAL_SALES': { percent: 60, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--FT_HAS_BLOCKCHAIN_BUSINESS': {
    percent: 60,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--FT_LARGEST_LOAN_SIZE': { percent: 70, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--FT_TOTAL_ANNUAL_LOANS': { percent: 70, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--FT_HANDLES_INSURANCE_CLAIMS': {
    percent: 70,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--FT_IS_DIRECTLY_REGULATED': {
    percent: 70,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--DID_TRIAL': { percent: 70, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--DID_TRIAL_DESC': { percent: 70, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--WILL_TRIAL': { percent: 70, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--WILL_TRIAL_DESC': { percent: 70, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--PHARMA_WHEN_IN_MARKET': { percent: 70, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--PHARMA_IS_IN_RADIO': { percent: 70, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--MEDDEV_SPECIAL_DEVICE': { percent: 70, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--MEDDEV_SPECIAL_DEVICE_DESC': {
    percent: 70,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--MEDDEV_IN_MARKET': { percent: 80, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--MEDDEV_IN_MARKET_DESC': { percent: 80, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--HW_HAS_DANGEROUS_PRODUCTS': {
    percent: 80,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--EPRISE_DEMANDS_MADE': { percent: 80, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--EPRISE_DEMANDS_MADE_DESC': {
    percent: 80,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--EPRISE_ADVICE_REPORTING': { percent: 80, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--EPRISE_ADVICE_REPORTING_DESC': {
    percent: 80,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--EPRISE_SPECIAL_CATEGORY': { percent: 80, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--EPRISE_EXPECTED_INTL_REVENUE': {
    percent: 80,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-06-01--EPRISE_LOAN_SERVICES': { percent: 80, step: RenewalNavSteps.REFRESH_INFO },
  '2021-06-01--EPRISE_LOAN_SERVICES_DESC': {
    percent: 80,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2022-12-15--FT_INVESTMENT_SERVICES_ACTIVITIES': {
    percent: 80,
    step: RenewalNavSteps.REFRESH_INFO,
  },

  // not sure if we need these, but here we are
  '2019-07-01--LEGAL_ENTITY_NAME': { percent: 90, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_PENDING_CLAIMS': { percent: 90, step: RenewalNavSteps.REFRESH_INFO },

  '2019-07-01--NUM_INVOLUNTARY_TERMINATION': {
    percent: 90,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2019-07-01--NUM_PLANNED_INVOLUNTARY_TERMINATION': {
    percent: 90,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2019-07-01--INSURANCE_HISTORY_CONTAINER': {
    percent: 90,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2019-07-01--DENIED_COVERAGE_DESCRIPTION': {
    percent: 90,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2019-07-01--PENDING_CLAIMS_DESCRIPTION': {
    percent: 90,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2019-07-01--LIABILITIES_CLAIMS_CONTAINER': {
    percent: 90,
    step: RenewalNavSteps.REFRESH_INFO,
  },

  'renewal-setup-call': { percent: 90, step: RenewalNavSteps.REFRESH_INFO },
  review: { percent: 95, step: RenewalNavSteps.REFRESH_INFO },
  applicant: { percent: 97, step: RenewalNavSteps.REFRESH_INFO },
  'renewal-end': { percent: 100, step: RenewalNavSteps.REFRESH_INFO, hasBackButton: false },
};
