import requests from '@/shared/services/requests';

export const dataShareOptIn = async ({ applicationId }) => {
  const response = await requests.getOptInData({
    application_id: applicationId,
    partner_name: 'svb',
  });

  if (response?.data[0]?.id) {
    return true;
  }
  return false;
};

export default {
  dataShareOptIn,
};
