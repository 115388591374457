import { autoYes } from '@/shared/routing/routingDecisionHelpers';
import {
  capitalRaisedContainerCard,
  hasInsuranceSelfCard,
  numFullTimeWorkersCard,
  verticalNicheCard,
} from '@/shared/routing/questionDecisions';

// Prequal
const prequalStartCard = {
  id: 'prequal-start',
  isSatisfied: autoYes,
  shouldRender: autoYes,
};

const endOfPrequal = {
  id: 'prequal-end',
  isSatisfied: autoYes,
  shouldRender: autoYes,
};

export const prequalRoutingDecisions = [
  prequalStartCard,
  hasInsuranceSelfCard,
  numFullTimeWorkersCard,
  capitalRaisedContainerCard,
  verticalNicheCard,
  endOfPrequal,
];
