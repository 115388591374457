<template>
  <div>
    <div>
      <label class="checkout-form-section__label">
        Card Number
        <div
          id="card-number"
          class="form-controls"
          :class="{ 'card-error': cardFieldsState.number.error }"
        />
      </label>
    </div>
    <div class="card-row">
      <div class="card-row-item">
        <label class="checkout-form-section__label">
          Expiration Date
          <div
            id="card-expiry"
            class="form-controls"
            :class="{ 'card-error': cardFieldsState.expiry.error }"
          />
        </label>
      </div>
      <div class="card-row-item">
        <label class="checkout-form-section__label">
          Card CVC
          <div
            id="card-cvc"
            class="form-controls"
            :class="{ 'card-error': cardFieldsState.cvc.error }"
          />
        </label>
      </div>
      <div class="card-row-item">
        <label class="checkout-form-section__label">
          ZIP Code
          <div
            id="card-postal-code"
            class="form-controls"
            :class="{ 'card-error': cardFieldsState.postalCode.error }"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Env from '@/shared/services/Env';
import { getStripeErrorMessage } from '@/onboarding/constants/stripeErrorStrings';
import Colors from '@/Colors';

export default {
  name: 'CheckoutStripeCard',
  data() {
    return {
      stripe: null,
      numberElement: '',
      expiryElement: '',
      cvcElement: '',
      cardFieldsState: {
        number: {
          complete: false,
          error: false,
        },
        expiry: {
          complete: false,
          error: false,
        },
        cvc: {
          complete: false,
          error: false,
        },
        postalCode: {
          complete: false,
          error: false,
        },
      },
    };
  },
  computed: {
    allFieldsValid() {
      return (
        this.cardFieldsState.number.complete &&
        this.cardFieldsState.expiry.complete &&
        this.cardFieldsState.cvc.complete &&
        this.cardFieldsState.postalCode.complete
      );
    },
  },

  created() {
    this.setUpStripe();
  },

  mounted() {
    this.createStripeElements();
  },

  methods: {
    setUpStripe() {
      if (window.Stripe === undefined) {
        this.$rollbar.error('Stripe V3 library not loaded!');
      } else {
        this.stripe = window.Stripe(Env.getConfig().stripe_key);
      }
    },

    createStripeElements() {
      // Stripe's setup does not allow us to get at the reference in fonts.scss.
      // Believe me, I tried.
      const stripeFonts = [
        {
          family: 'Stripe-Stolzl',
          src: 'url(https://cdn.jsdelivr.net/gh/svinstech/assets/fonts/Stolzl-Book.otf)',
        },
      ];

      const stripeStyle = {
        style: {
          base: {
            fontFamily: 'Stripe-Stolzl, Arial, sans-serif',
            fontSize: '16px',
          },
          invalid: {
            color: Colors.errorColor,
          },
        },
      };

      const elements = this.stripe.elements({ fonts: stripeFonts });
      const cardNumberStyle = { ...stripeStyle };
      cardNumberStyle.placeholder = 'Enter card number';
      const postalCodeStyle = { ...stripeStyle };
      postalCodeStyle.placeholder = 'Enter ZIP code';

      this.cvcElement = elements.create('cardCvc', stripeStyle);
      this.expiryElement = elements.create('cardExpiry', stripeStyle);
      this.numberElement = elements.create('cardNumber', cardNumberStyle);
      this.postalCodeElement = elements.create('postalCode', postalCodeStyle);

      this.cvcElement.mount('#card-cvc');
      this.expiryElement.mount('#card-expiry');
      this.numberElement.mount('#card-number');
      this.postalCodeElement.mount('#card-postal-code');

      this.addListenersToAllElements();
    },
    addListenersToAllElements() {
      this.numberElement.addEventListener('change', event =>
        this.addListenerToElement(event, 'number')
      );
      this.expiryElement.addEventListener('change', event =>
        this.addListenerToElement(event, 'expiry')
      );
      this.cvcElement.addEventListener('change', event => this.addListenerToElement(event, 'cvc'));
      this.postalCodeElement.addEventListener('change', event =>
        this.addListenerToElement(event, 'postalCode')
      );
    },
    addListenerToElement(event, creditCardProp) {
      this.cardFieldsState[creditCardProp].complete = !!event.complete;
      this.cardFieldsState[creditCardProp].error = !!event.error;
      this.tryTokenFetch();
    },
    tryTokenFetch() {
      if (this.allFieldsValid) {
        this.getStripeToken();
      }
    },
    getStripeToken() {
      this.stripe.createToken(this.numberElement).then(result => {
        if (result.error) {
          const stripeErrorMessage = getStripeErrorMessage(result.error.type, result.error.code);
          this.$emit('stripeErrorMessage', stripeErrorMessage);
        } else {
          this.$emit('stripeToken', result.token.id);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: $space-medium;
  .card-row-item {
    flex: 1;
    .form-controls {
      min-width: $checkout-form-control-min-width / 2 - $space-xsmall;
    }
  }
}

.form-controls {
  background: $vouch-tint-light;
  &:hover,
  &:active {
    background: $form-text-input-filled-bg;
  }
  padding: $space-small;
  margin-bottom: $space-small;
}

.checkout-form-section__label {
  @include label-text;
}

.card-error {
  border-style: solid;
  border-width: 1px;
  border-color: $form-error-text-color;
}
</style>