export const RenewalNavSteps = {
  REFRESH_INFO: 1,
  SCHEDULE_CALL: 2,
  REVIEW_QUOTE: 3,
  SIGN_AND_PAY: 4,
};
Object.freeze(RenewalNavSteps);

export const RenwalQuestions = {
  assumedMinutes: 6,
};

const millisecondsInDay = 86400000;
export const renewalCongratsWindow = millisecondsInDay * 10;
