<template>
  <v-autocomplete
    autocorrect="off"
    filled
    spellcheck="false"
    :error="error"
    :error-count="1"
    :error-messages="errorMessage"
    :no-data-text="getNoDataText"
    :items="items"
    :value="value"
    :label="label"
    hide-details="auto"
    :placeholder="placeholder"
    :search-input="value"
    @input="$emit('input', $event)"
    @blur="$emit('blur', $event)"
    @keydown="handleKeydown"
    @update:search-input="handleSearchUpdate"
  />
</template>

<script>
export default {
  name: 'FormFieldAutocomplete',
  props: {
    value: {
      type: String,
      required: false,
      default: undefined,
    },
    items: {
      type: Array,
      required: true,
    },
    error: {
      type: Boolean,
    },
    errorMessage: {
      type: String,
      required: false,
      default: undefined,
    },
    noDataText: {
      type: String,
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      searchValue: '',
    };
  },
  computed: {
    getNoDataText() {
      return `No results for: "${this.searchValue}"`;
    },
  },
  methods: {
    handleKeydown(event) {
      this.$emit('keydown', event);
    },

    handleSearchUpdate(event) {
      this.searchValue = event;
      this.$emit('update:search-input', event);
    },
  },
};
</script>

<style lang="scss" scoped>
@include text-field-filled-bg-color;
</style>
