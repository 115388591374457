export const Program2EndpointMap = {
  prequal: 'program_2_pre_auth',
  core: 'program_2_post_auth',
  core_location: 'core_location',
  niche_specific: 'program_2_post_auth',
  program_2_pre_auth: 'program_2_pre_auth',
  program_2_post_auth: 'program_2_post_auth',
  review: 'review',
};

export const APIUrlMap = {
  prequal: {
    SCHEMA_ENDPOINT: 'update_application_prequal',
    UPDATE_ENDPOINT: 'prequal',
  },
  core: {
    SCHEMA_ENDPOINT: 'update_application_core',
    UPDATE_ENDPOINT: 'core',
  },
  core_location: {
    SCHEMA_ENDPOINT: 'update_application_core_location',
    UPDATE_ENDPOINT: 'core_location',
  },
  review: {
    SCHEMA_ENDPOINT: 'create_application_review',
    UPDATE_ENDPOINT: 'review',
  },
  niche: {
    SCHEMA_ENDPOINT: 'update_application_niche_specific',
    UPDATE_ENDPOINT: 'niche_specific',
  },
  program_2_pre_auth: {
    SCHEMA_ENDPOINT: 'update_application_program_2_pre_auth',
    UPDATE_ENDPOINT: 'program_2_pre_auth',
  },
  program_2_post_auth: {
    SCHEMA_ENDPOINT: 'update_application_program_2_post_auth',
    UPDATE_ENDPOINT: 'program_2_post_auth',
  },
  // TODO
  // review: {
  //   SCHEMA_ENDPOINT: 'application_review_program_2',
  //   UPDATE_ENDPOINT: 'program_2_review',
  // },
};

export const States = {
  delaware: 'Delaware',
};

export const ApplicationDecisions = {
  yesDecision: 'yes',
  noDecision: 'no',
  maybeDecision: 'maybe',
  unknownDecision: 'unknown',
};
Object.freeze(ApplicationDecisions);

export const FunnelNavSteps = {
  PREQUAL: 1,
  CORE: 2,
  GET_PRICE: 3,
  SIGN_POLICY: 4,
  CHECKOUT: 5,
};
Object.freeze(FunnelNavSteps);

// The Location question cards maintain a list of form fields to display for each address.
// We have to a bunch of array ops to track/add/remove addresses in multiple location scenarios.
// We could build this constant by reading from the schema definition but we're not gonna.
export const NumFieldsPerLocObject = 10;

export const Discounts = {
  svbConnected: 0.9,
};
Object.freeze(Discounts);

export const Funnel = {
  assumedMinutes: 10,
};

// https://mediag.com/blog/popular-screen-resolutions-designing-for-all/
// TODO Add popular Android devices, filter list to popular devices
export const mobileViewportDimensions = {
  iPhoneXR: { width: 414, height: 896 },
  iPhoneXS: { width: 375, height: 812 },
  iPhoneXSMax: { width: 414, height: 896 },
  iPhoneX: { width: 375, height: 812 },
  iPhone8Plus: { width: 414, height: 736 },
  iPhone8: { width: 375, height: 667 },
  iPhone7Plus: { width: 414, height: 736 },
  iPhone7: { width: 375, height: 667 },
  iPhone6Plus: { width: 414, height: 736 },
  iPhone6: { width: 375, height: 667 },
};

const thirtySecondsInMilliseconds = 300000;
export const timeConstants = {
  stalledPageTimeout: thirtySecondsInMilliseconds,
};

export const dollarValues = {
  twoPointFiveMillionInCents: 2_500_000_00,
  twentyMillionInCents: 20_000_000_00,
  oneHundredMillionInCents: 100_000_000_00,
};

export const UserJobTitleOptions = [
  'Founder',
  'CEO',
  'Operations',
  'Finance',
  'Legal',
  'Risk',
  'Other',
];
