import { Store } from 'vuex';
import { FunnelStore } from '@/onboarding/types/internal/storeTypes';

export const fetchInitialData = async (
  $store: Store<FunnelStore>,
  { applicationId }: { applicationId: string }
) => {
  $store.commit('setApplicationId', applicationId);
  await Promise.all([
    $store.dispatch('fetchApplicationData'),
    $store.dispatch('fetchApplicationParentData'),
  ]);

  if ($store.getters.isApplicationLocked) {
    await $store.dispatch('fetchDecisionData');
    await $store.dispatch('fetchPackagesData');
  }
};
