/* istanbul ignore file */
// this file is strictly configuration, and aligns with the 'humble object' pattern, so testing isn't necessary.

import { prequalRoutingDecisions } from './prequalRoutingDecisions';
import { coreRoutingDecisions } from './coreRoutingDecisions';
import { endOfFunnelRoutingDecisions } from './endOfFunnelRoutingDecisions';
import { nicheRoutingDecisions } from './nicheRoutingDecisions';

export const routingDecisions = [
  ...prequalRoutingDecisions,
  ...coreRoutingDecisions,
  ...nicheRoutingDecisions,
  ...endOfFunnelRoutingDecisions,
];
