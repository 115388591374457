<template>
  <div
    class="coverage-list-item"
  >
    {{ name }}
  </div>
</template>

<script>
export default {
  name: 'CoverageListItem',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.coverage-list-item {
  position: relative;
  @include subtitle-text;

  opacity: 1;

  display: flex;
  align-items: flex-start;

  text-align: right;
}
</style>
