import Mustache from 'mustache';
import { get, isNil } from '../../shared/lib/vouch_dash';
import { getUIConfig } from '@/onboarding/services/questionUIConfig';
import { ApplicationVersions, ApplicationData } from '@/onboarding/types/internal/applicationTypes';

function checkUIConfig(schemaPath, isProgram2) {
  const config = getUIConfig(schemaPath, isProgram2);
  if (config === undefined) {
    window.vueRoot.$rollbar.error(
      `Unable to get UI Config. isProgram2? ${isProgram2}. schemaPath: ${JSON.stringify(
        schemaPath
      )}`
    );
  }
}

/**
 * This class's sole purpose is to marshall data in a suitable format for consumption by cards
 * It is directly tied to the structure of the backend schema, so it's secondary purpose is to
 * abstract away the particulars of the schema design from the rest of this project
 */
export default class FunnelCardSchemaHelper {
  schemaService: any;

  applicationData: ApplicationData;

  constructor(schemaService, applicationData) {
    this.schemaService = schemaService;
    this.applicationData = applicationData;
  }

  isProgram2() {
    return this.applicationData.sha_onboarding_funnel === ApplicationVersions.program2;
  }

  marshallCardFields(formFieldData) {
    return formFieldData.map(
      ({ prefilledValue, name, dataPath, schemaPath, component, ...rest }) => {
        let value = get(this.applicationData, dataPath) as any;
        const validator = this.schemaService.getPropertyValidator(schemaPath);
        const rawDefinition = get(this.schemaService.getSchema().definitions, schemaPath);

        if (isNil(value)) {
          value = prefilledValue || rawDefinition.default;
        }

        return {
          // Object shallow copying does not work properly, hence this vomit of code to protect against accidental mutation of the original object
          name,
          component,
          dataPath,
          schemaPath,
          value,
          validator,
          propertyDefinition: this.mustachedPropertyDefinition({
            definition: rawDefinition,
            schemaPath,
          }),
          ...rest,
        };
      }
    );
  }

  mustachedPropertyDefinition({ definition, schemaPath }) {
    const isProgram2 = this.isProgram2();
    checkUIConfig(schemaPath, isProgram2);
    const { title, hidePreviousAnswer } = getUIConfig(schemaPath, isProgram2).question;
    return {
      title: Mustache.render(title || '', this.applicationData),
      hidePreviousAnswer,
      ...definition,
    };
  }
}
