<template>
  <div>
    <FormFieldBigMoney
      :value="inputValue"
      :description="isSingleField ? undefined : propertyDefinition.title"
      :error="isValid === false"
      :error-message="errorMessage"
      :warning-message="warningMessage"
      @input="setInputValue"
    />
    <v-row
      v-show="isValid !== false"
      no-gutters
    >
      <v-col
        cols="6"
        class="currency-description-container"
      >
        <div class="spacing">
          <PreviousAnswer :value="inputParentValue | currency" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseSchemaFormField from './BaseSchemaFormField';
import FormFieldBigMoney from '@/shared/components/ui/atoms/form_fields/FormFieldBigMoney';
import PreviousAnswer from '@/shared/components/wizard/PreviousAnswer';
import { longCurrencyNoCents } from '@/shared/lib/filters/currency_filters';

export default {
  name: 'SchemaFormFieldBigMoney',
  components: { FormFieldBigMoney, PreviousAnswer },
  filters: {
    currency(value) {
      if (value === null || value === undefined) return null;
      return longCurrencyNoCents(value);
    },
  },
  extends: BaseSchemaFormField,
};
</script>

<style lang="scss" scoped>
.spacing {
  padding: $space-xsmall 0 $space-xsmall;
}
</style>
