interface Breakpoints {
  xs: Number;
  s: Number;
  m: Number;
  l: Number;
  xl: Number;
}

export type Breakpoint = keyof Breakpoints;

const breakpoints: Breakpoints = {
  xs: 300,
  s: 500,
  m: 770,
  l: 1200,
  xl: Infinity,
};

export default breakpoints;
