<template>
  <GenericStatusPage
    :image="image"
    :title="title"
  >
    <template v-slot:description-content>
      We need to do one last check before we process your payment. After that's complete, you will
      receive an email from
      <b>Dropbox Sign</b> to collect your <b>officer's signature</b>. <br><br>
      <b>Your coverage is not active until you sign</b>, so keep an eye on your inbox!
    </template>
  </GenericStatusPage>
</template>

<script>
import GenericStatusPage from '@/shared/views/layouts/GenericStatusPage';

export default {
  name: 'DelayedPayment',
  components: { GenericStatusPage },
  data() {
    return {
      title: 'PAYMENT DELAYED',
      // TODO replace with new image (brand refresh)
      image: '',
    };
  },
};
</script>
