export enum validSchemaVersions {
  versionSeven = '007',
  versionEight = '008',
  versionNine = '009',
  versionTen = '010',
  versionEleven = '011',
  versionTwelve = '012',
  versionFourteen = '014',
  versionTwenty = '020',
}

export enum schemaNames {
  definitions = 'definitions',
  createUser = 'create_user',
  createCompany = 'create_company',
  createApplication = 'create_application',
  createApplicationReview = 'create_application_review',
  prequal = 'update_application_prequal',
  core = 'update_application_core',
  coreLocation = 'update_application_core_location',
  niche = 'update_application_niche_specific',
  applicationReview = 'application_review',
  // Program 2 specific
  updateApplicationProgram2PreAuth = 'update_application_program_2_pre_auth',
  updateApplicationProgram2PostAuth = 'update_application_program_2_post_auth',
}

type Program2SchemaMap = {
  [key in schemaNames]: string;
};

const program2SchemaMap: Program2SchemaMap = {
  definitions: 'definitions',
  create_user: 'create_user',
  create_company: 'create_company',
  create_application: 'create_application',
  create_application_review: 'create_application_review',
  update_application_prequal: 'update_application_program_2_pre_auth',
  update_application_core: 'update_application_program_2_post_auth',
  update_application_core_location: 'update_application_core_location',
  update_application_niche_specific: 'update_application_program_2_post_auth',
  application_review: 'application_review',
  update_application_program_2_pre_auth: 'update_application_program_2_pre_auth',
  update_application_program_2_post_auth: 'update_application_program_2_post_auth',
};

export const currentProgram1SchemaVersion = validSchemaVersions.versionFourteen;
export const currentProgram2SchemaVersion = validSchemaVersions.versionTwenty;

export const getSchema = async ({
  schemaName,
  isProgram2 = false,
}: {
  schemaName: schemaNames;
  isProgram2?: boolean;
}) => {
  let programSavvySchemaName = schemaName;
  let programSavvySchemaVersion = currentProgram1SchemaVersion;
  if (isProgram2) {
    programSavvySchemaName = program2SchemaMap[schemaName] as schemaNames;
    programSavvySchemaVersion = currentProgram2SchemaVersion;
  }
  const schemaFile = await import(
    `./schemas/${programSavvySchemaVersion}/${programSavvySchemaName}.json`
  );
  return schemaFile.default;
};
