<template>
  <div class="discretion-container">
    <slot name="banner-content" />
    <v-container class="status-page">
      <div class="status-primary-reason">
        <slot name="primary-reason-content" />
      </div>
      <div class="status-description">
        <slot name="description-content" />
      </div>
      <div
        v-if="$scopedSlots['status-button']"
        class="status-button"
      >
        <slot name="status-button" />
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'GreenStatusPage',
};
</script>

<style lang="scss" scoped>
.discretion-container {
  height: 100%;
  min-width: 100% !important;
  background-color: $vouch-primary-light;
}

.status-page {
  text-align: center;
  max-width: $discretion-end-page-max-width;
  padding-top: $space-xxlarge;
}

.status-title {
  margin-top: $space-large;
}

.status-description {
  margin-top: $space-medium;
}

.status-button {
  margin-top: $space-xlarge;
}
</style>
