<script>
export default {
  name: 'WithBackground',
  props: {
    color: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.originalBackgroundColor = document.body.style['background-color'];
    document.body.style['background-color'] = this.color;
  },
  beforeDestroy() {
    document.body.style['background-color'] = this.originalBackgroundColor;
  },
  render() {
    return this.$slots.default;
  },
  originalBackgroundColor: null,
};
</script>
