import Env from '@/shared/services/Env';

export default {
  accessToken: Env.getConfig().rollbar_access_token,
  autoInstrument: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: Env.getConfig().rollbar_access_token !== '',
  environment: Env.getEnvName(),
  payload: {
    client: {
      javascript: {
        guess_uncaught_frames: true,
        source_map_enabled: true,
      },
    },
  },
  verbose: Env.getEnvName() !== 'production', // Also output to console unless Production
};
