<template>
  <div
    class="vouch-container"
    :class="{'vouch-container--mobile': isMobile}"
  >
    <div class="vouch-container-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CenteredContainer',
  computed: {
    isMobile() {
      return ['xs', 's', 'm'].includes(this.$mq);
    },
  },
};
</script>

<style lang="scss" scoped>
.vouch-container {
  padding-left: $page-content-horizontal-padding;
  padding-right: $page-content-horizontal-padding;

  &--mobile {
    padding-left: $page-content-horizontal-padding--mobile;
    padding-right: $page-content-horizontal-padding--mobile;
  }

  .vouch-container-content {
    max-width: $page-content-max-width;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
