<template>
  <IsMobile v-slot="{ isMobile }">
    <div class="svb-data-share-opt-in">
      <WizardPageWrapper
        header-text="Get Your Quote"
        :minutes-left="0.1"
        :progress="99"
        :has-back-button="true"
      >
        <template v-slot:left-panel>
          <OnboardingNavigationBar
            class="nav-bar"
            :current-nav-step="currentNavStep"
          />
        </template>
        <template>
          <div class="svb-card-body">
            <h2 class="svb-title-text">
              Opt-in to data sharing between
              <br>
              SVB and Vouch to get an
              <br>
              additional 5% off
            </h2>
            <p>
              Vouch may use this data to proactively help you manage risk as your business evolves.
              Your information is protected in accordance with 
              <a
                target="_blank"
                href="https://www.vouch.us/privacy"
                @click.stop
              >
                Vouch's Privacy Notice
              </a>
              and
              <a
                target="_blank"
                href="https://www.svb.com/privacy-notice"
                @click.stop
              >
                Silicon Valley Bank's Privacy Notice.
              </a>
            </p>
            <v-form
              v-if="!connectionSuccess"
              ref="form"
              v-model="valid"
              class="checkboxForm"
            >
              <v-checkbox
                v-model="svbTermsCheckbox"
                class="termsCheckbox"
                data-testid="svb-terms-checkbox"
              >
                <div
                  slot="label"
                  class="label"
                >
                  <div class="svb-vouch-terms">
                    By clicking "Connect with SVB" you authorize Silicon Valley Bank and Vouch to share 
                    data with each other to provide you with, and inform you of, products and services.  
                    You may opt out at any time by emailing
                    <a
                      target="_blank"
                      href="mailto:hello@vouch.us"
                      @click.stop
                    >hello@vouch.us</a>.
                  </div>
                </div>
              </v-checkbox>
              <div
                v-if="apiError"
                class="error-text"
              >
                {{ errorMsg }}
              </div>
              <div
                class="mt-5"
              >
                <ButtonPrimary
                  :class="{ mobileButton: isMobile }"
                  data-testid="submit-svb-data"
                  :loading="loading"
                  :disabled="!optedIn && !svbTermsCheckbox"
                  class="connect-page-button"
                  @click="optedIn ? submitOptOut() : submitSvbData()"
                >
                  {{ optedIn ? "Disconnect SVB" : "Connect with SVB" }}
                </ButtonPrimary>
                <ButtonText
                  class="connect-page-button css-magic"
                  @click="continueToReview"
                >
                  X Skip
                </ButtonText>
              </div>
            </v-form>
          </div>
        </template>
      </WizardPageWrapper>
    </div>
  </IsMobile>
</template>

<script>
import { onboardingUrl } from '@/onboarding/router/routeHelper';
import WizardPageWrapper from '@/shared/components/wizard/WizardPageWrapper';
import OnboardingNavigationBar from '@/onboarding/components/wizard/OnboardingNavigationBar';
import { FunnelNavSteps } from '@/onboarding/constants/Constants';
import ButtonText from '@/shared/components/ui/atoms/buttons/ButtonText';
import ButtonPrimary from '@/shared/components/ui/atoms/buttons/ButtonPrimary';
import IsMobile from '@/shared/frames/IsMobile';
import {
  SVB_CONNECT_ATTEMPTED,
  SVB_CONNECT_FAILED,
  SVB_CONNECT_SUCCESSFUL,
  SVB_CONNECT_VIEWED,
  DATA_SHARE_OPT_OUT_ATTEMPT,
  DATA_SHARE_OPT_OUT_FAILED,
  DATA_SHARE_OPT_OUT_SUCCESSFUL,
} from '@/onboarding/services/SegmentEventTypes';

export default {
  name: 'ConnectSVBPage',
  components: {
    OnboardingNavigationBar,
    WizardPageWrapper,
    ButtonText,
    ButtonPrimary,
    IsMobile,
  },
  dependencies: ['requests', 'tracking'],
  props: {
    applicationId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      svbTermsCheckbox: null,
      optedIn: null,
      partnerName: 'svb',
      valid: true,
      apiError: false,
      errorMsg: null,
      connectionSuccess: false,
      loading: false,
      currentNavStep: FunnelNavSteps.CORE,
      requestAmountComplete: 10,
    };
  },
  async created() {
    this.tracking.sendEvent(SVB_CONNECT_VIEWED);
    this.loading = true;
    const { data } = await this.requests.getOptInData({
      application_id: this.applicationId,
      partner_name: this.partnerName,
    });
    if (data[0]?.id) {
      this.svbTermsCheckbox = true;
      this.optedIn = true;
    }
    this.loading = false;
  },
  methods: {
    continueToReview() {
      this.$router.push({
        path: onboardingUrl({
          cardId: 'review',
          applicationId: this.applicationId,
        }),
      });
    },
    submitOptOut() {
      this.loading = true;
      this.apiError = false;
      this.errorMsg = null;
      this.tracking.sendEvent(DATA_SHARE_OPT_OUT_ATTEMPT);
      this.requests
        .deleteDataShareAgreementData({
          params: {
            application_id: this.applicationId,
            partner_name: this.partnerName,
          },
          onSuccess: () => {
            this.tracking.sendEvent(DATA_SHARE_OPT_OUT_SUCCESSFUL);
            this.connectionSuccess = true;
            this.continueToReview();
          },
          onError: response_data => {
            this.tracking.sendEvent(DATA_SHARE_OPT_OUT_FAILED);
            this.errorMsg = this.getDisplayedErrorText(response_data.response.data);
            this.apiError = true;
          },
        })
        .then(() => {
          this.loading = false;
        });
    },
    submitSvbData() {
      this.loading = true;
      this.requestAmountComplete = 60;
      this.apiError = false;
      this.errorMsg = null;
      if (this.$refs.form.validate()) {
        this.tracking.sendEvent(SVB_CONNECT_ATTEMPTED);
        this.requests
          .postDataShareAgreementData({
            data: {
              application_id: this.applicationId,
              partner_name: 'svb',
            },
            onSuccess: () => {
              this.tracking.sendEvent(SVB_CONNECT_SUCCESSFUL);
              this.requestAmountComplete = 100;
              this.connectionSuccess = true;
              this.continueToReview();
            },
            onError: response_data => {
              this.tracking.sendEvent(SVB_CONNECT_FAILED);
              this.requestAmountComplete = 30;
              this.errorMsg = this.getDisplayedErrorText(response_data.response.data);
              this.apiError = true;
            },
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    getDisplayedErrorText() {
      return 'There was an error accepting SVB Data Share terms. Please try again or skip this step.';
    },
  },
};
</script>

<style lang="scss" scoped>
.svb-data-share-opt-in {
  .svb-title-text {
    @include question-title-text;
    margin-bottom: 0;
  }
  .svb-banner {
    background-image: none;
    background-color: $vouch-accent-orange;
  }
  .svb-card-body {
    h2 {
      margin-top: 56px;
      font-size: 28px;
      margin-bottom: 10px;
    }
    .mobileButton {
      font-size: 11px !important;
      width: 50%;
    }

    .svb-vouch-terms {
      @include detail-text;
      text-transform: none;
    }
  }
  .checkboxForm {
    padding-top: 37px;
  }
  .error-text {
    text-align: left;
    margin-bottom: $space-xsmall;
    color: $error-color;
  }
}
</style>
