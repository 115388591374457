<template>
  <div class="question-card-description">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'QuestionCardDescription',
};
</script>

<style lang="scss" scoped>
.question-card-description {
  @include body-text;
}
</style>
