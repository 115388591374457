import { ApplicationDecisions } from '@/onboarding/constants/Constants';
import { getCardId } from '@/shared/routing/getCardId';
import { redirectAwayOnPageLoad, redirectAwayOnRouteChange } from '@/shared/routing/redirect';
import { renewalUrl } from '@/members/router/routeHelper';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};
const redirectToReview = () => 'review';
const redirectToEnd = () => 'package';
const redirectToFin = () => 'fin';
const requirePackageParam = ({ route }) => (!route.query.packageSlug ? 'package' : null);

const isLockedAndYesDecisionCase = {
  'renewal-start': noop,
  package: noop,
  quote: requirePackageParam,
  checkout: requirePackageParam,
  billing: requirePackageParam,
  success: noop,
  'officer-signature': noop,

  base: redirectToEnd,
};

const isLockedAndNotYesDecisionCase = {
  base: redirectToFin,
  fin: noop,
};

const isNotLockedCase = {
  package: redirectToReview,
  quote: redirectToReview,
  checkout: redirectToReview,
  base: noop,
};

// ----------------------------------------------------------

const cases = {
  isLocked: {
    true: {
      decisionIsYes: {
        true: isLockedAndYesDecisionCase,
        false: isLockedAndNotYesDecisionCase,
      },
    },
    false: {
      decisionIsYes: {
        true: isNotLockedCase,
        false: isNotLockedCase,
      },
    },
  },
};

export const redirectTo = ({ $store, route }) => {
  const isLocked = $store.getters.isApplicationLocked;
  const isYesDecision =
    $store.state.application.decisionData.decision === ApplicationDecisions.yesDecision;

  const caseSet = cases.isLocked[isLocked].decisionIsYes[isYesDecision];
  const cardId = getCardId(route);
  const redirectPathFn = caseSet[cardId] || caseSet.base;
  return redirectPathFn({ $store, route });
};

export const renewalRedirectAwayOnRouteChange = redirectAwayOnRouteChange(redirectTo, renewalUrl);
export const renewalRedirectAwayOnPageLoad = redirectAwayOnPageLoad(redirectTo, renewalUrl);
