<template>
  <div class="status-page">
    <div class="status-info">
      <h1 class="page-title">
        {{ title }}
      </h1>
      <div
        class="status-description-a"
      >
        Your <strong>coverage is not active</strong> until we have the officer's signature
      </div>
      <v-img
        class="status-image"
        :src="image"
        height="125px"
        width="200px"
        :contain="true"
      />
      <div
        class="status-description-b"
      >
        An email from Dropbox Sign is on its way!
      </div>
    </div>

    <div class="status-prompt">
      <div class="prompt-text">
        Have feedback? <a
          class="prompt-link"
          href="https://calendly.com/carrie-vouchinsurance/"
        >Schedule a 15 minute Zoom call</a> with our product team or email us at <a
          class="prompt-link"
          href="mailto:hello@vouch.us"
        >hello@vouch.us</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OfficerSignatureFramePWYR',
  props: {
    packageSlug: {
      type: String,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      title: 'SIGNATURE NEEDED',
      // TODO replace with new image (brand refresh)
      image: '',
    };
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  @include h3-text;
}

.status-page {
  text-align: center;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.status-description-a {
  color: $special-offer-text-color;
}

.status-description-a,
.status-description-b {
  margin-top: $space-small;
}

.status-image {
  margin: 0 auto;
}

.status-info {
  flex: 0 1 auto;
  max-width: $white-status-page-text-width;
  margin: $space-xlarge auto;
}

.status-prompt {
  flex: 1 1 auto;
  background-color: $feedback-box-color;
}

.prompt-text {
  max-width: $white-status-page-text-width;
  margin: $space-medium auto;
  @include detail-text;

  color: $feedback-box-text-color;

  .prompt-link {
    color: $feedback-box-text-color;
  }
}
</style>
