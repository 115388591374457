<template>
  <OfficerSignature
    :package-slug="packageSlug"
    :is-mobile="isMobile"
    :application-id="applicationId"
  />
</template>

<script>
import OfficerSignature from '@/onboarding/views/dead_ends/officer_signature_page/OfficerSignature';

export default {
  name: 'OfficerSignatureFrame',
  components: {
    OfficerSignature,
  },
  props: {
    applicationId: {
      type: String,
      required: true,
    },
    packageSlug: {
      type: String,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
