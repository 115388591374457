<template>
  <div class="question-card-progress">
    <ProgressBar
      class="question-card-progress__bar"
      aria-describedby="question-card-progress__detail"
      :value="progress"
    />
    <div
      id="question-card-progress__detail"
      class="question-card-progress__detail"
    >
      {{ detailText }}
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/shared/components/ui/atoms/question_card/ProgressBar';

export default {
  name: 'QuestionCardProgress',
  components: { ProgressBar },
  props: {
    progress: {
      type: Number,
      required: false,
      default: 0,
    },
    detailText: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card-progress {
  position: relative;
  margin: 0;
  // only takes up the space of the progress bar so it center aligns properly

  .question-card-progress__detail {
    margin-top: $space-xsmall;
    position: absolute;
    right: 0;
    @include detail-text;
  }
  .question-card-progress__bar {
    border-radius: $corner-roundness;
  }
}
</style>
