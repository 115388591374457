<template>
  <h1
    class="card-title"
  >
    <slot />
  </h1>
</template>

<script>
export default {
  name: 'QuestionCardTitle',
};
</script>

<style lang="scss" scoped>
.card-title {
  @include question-title-text;
}
</style>
