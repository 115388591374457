<template>
  <WithDataSync
    :update-fn="updateFn"
    :submit-path="submitPath"
    :schema-path="schemaPath"
    :application-id="applicationId"
    :on-submit-success="onSubmitSuccess"
  >
    <template slot-scope="{onInputChange, onSubmit, isValidForm}">
      <BaseWizardPage
        :card-data="{}"
        :loading="isLoading"
        :is-submit-disabled="isValidForm !== true"
        @submit="withLoading(onSubmit)"
      >
        <WithFormFields
          v-for="(fieldsSetData, index) in cardFieldSetsData"
          :key="index"
          :index="index"
          :form-field-data="fieldsSetData"
          :schema-path="schemaPath"
        >
          <template slot-scope="{formFields}">
            <h1
              data-testid="card-title"
              class="card-title"
            >
              {{ titleForCards(index) }}
            </h1>
            <CardFields
              :form-fields="formFields"
              :on-input-change="onInputChange"
            />
          </template>
        </WithFormFields>
      </BaseWizardPage>
    </template>
  </WithDataSync>
</template>

<script>
import BaseCard from '@/shared/components/wizard/BaseCard';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';
import CardFields from '@/shared/views/wizard/core/shared/CardFields';
import { mapMutations } from 'vuex';
import SchemaFormFieldAutocomplete from '../../../../../onboarding/components/formfields/SchemaFormFieldAutocomplete';
import SchemaFormNoopField from '../../../../../onboarding/components/formfields/SchemaFormNoOpField';
import SchemaFormFieldNumber from '../../../../../onboarding/components/formfields/SchemaFormFieldNumber';
import WithFormFields from '../../../../components/wizard/WithFormFields';
import WithDataSync from '../../../../components/wizard/WithDataSync';
import {
  additionalAddressAttributesFields,
  primaryAddressAttributesFields,
} from '@/onboarding/services/address_fields';
import BaseWizardPage from '@/shared/components/wizard/BaseWizardPage';

export default {
  name: 'AddressAttributesCard',
  components: {
    BaseWizardPage,
    // eslint-disable-next-line
      CardFields, SchemaFormFieldNumber, SchemaFormFieldAutocomplete, SchemaFormNoopField,
    WithDataSync,
    WithFormFields,
  },
  extends: BaseCard,
  dependencies: ['requests'],
  data() {
    return {
      isLoading: false,
      schemaPath: getSchemaEndpoint('core_location'),
      submitPath: getUpdateEndpoint('core_location'),
      cardConfigPath: '2019-07-01--LOCATION_CONTAINER',
      additionalLocationCount: 0,
      cardFieldSetsData: [primaryAddressAttributesFields()],
    };
  },
  created() {
    const additionalLocations =
      (this.applicationData['2019-07-01--LOCATION_CONTAINER'] || {}).additional_locations || [];
    additionalLocations.forEach(() => this.onAddAddress());
  },
  methods: {
    ...mapMutations(['updateApplicationData']),
    updateFn(args) {
      this.requests.updateApplicationData({ ...args, context: this });
    },
    withLoading(fn) {
      this.isLoading = true;
      fn();
    },

    onSubmitSuccess(applicationData) {
      this.isLoading = false;
      this.updateApplicationData({ applicationData });
      this.questionRouter.next(this);
    },

    onAddAddress() {
      this.cardFieldSetsData.push(additionalAddressAttributesFields(this));
      this.additionalLocationCount += 1;
    },

    titleForCards(arrayIndex) {
      const locationIndex = arrayIndex - 1;
      if (arrayIndex === 0) {
        const address1 = this.applicationData['2019-07-01--LOCATION_CONTAINER'][
          '2019-07-01--ADDRESS_LINE_1'
        ];
        return `Tell us about ${address1}`;
      }
      const address1 = this.applicationData['2019-07-01--LOCATION_CONTAINER'].additional_locations[
        locationIndex
      ]['2019-07-01--ADDRESS_LINE_1'];
      return `Tell us about ${address1}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  @include question-title-text;
  margin-bottom: $space-medium; // this is cheating but this page is weird
}
</style>
