<template>
  <div>
    PII, or personally identifiable information, could include social security numbers, credit scores, credit card & payment info, birth dates, health information, etc. This only includes data collected and stored on your systems. If you do not store this data directly (e.g. use Stripe for payments) then do not include that data.
    <Tooltip>
      <template slot="trigger">
        <VouchIcon name="help" />
      </template>
      <span slot="text">
        <b>Personally Identifiable Information</b>
        <br><br>
        PII, or personal identifiable information, includes social security numbers, credit scores, credit card & payment information, birth dates, personal health information, and etc.
        <br><br>
        If you have any questions, please <a @click="showChat">talk to an advisor</a>
      </span>
    </Tooltip>
  </div>
</template>

<script>
import Tooltip from '@/onboarding/components/Tooltip';
import VouchIcon from '@/shared/components/ui/atoms/icons/VouchIcon';
import { showChatIfEnabled } from '@/onboarding/services/chatHelper';

export default {
  name: 'HasAnyPiiDescriptionProgram2',
  dependencies: ['tracking'],
  components: { VouchIcon, Tooltip },
  methods: {
    showChat() {
      showChatIfEnabled({ tracking: this.tracking });
    },
  },
};
</script>
