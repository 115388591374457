<template>
  <CardDataSync
    :submit-path="submitPath"
    :schema-path="schemaPath"
    :card-config-path="cardConfigPath"
    :form-field-data="formFieldData"
    :application-id="applicationId"
    :update-fn="noop"
    :on-submit-success="noop"
    :on-submit-error="noop"
  >
    <template slot-scope="{ onSubmit, isValidForm, formFields, onInputChange, cardData }">
      <v-progress-linear
        v-if="isLoading"
        color="black"
        :indeterminate="true"
      />
      <BaseWizardPage
        :loading="isLoading"
        :card-data="cardData"
        :is-submit-disabled="hasReviewErrors || (!isValidForm)"
        :has-back-button="true"
        :fill-height="false"
        submit-text="Confirm"
        @submit="handleSubmit()"
      >
        <CardFields
          :form-fields="formFields"
          :on-input-change="onInputChange"
        />
      </BaseWizardPage>
    </template>
  </CardDataSync>
</template>

<script>
import BaseCard from '@/shared/components/wizard/BaseCard';
import CardFields from '@/shared/views/wizard/core/shared/CardFields';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';
import CardDataSync from '@/shared/components/wizard/CardDataSync';
import { getDecisionData } from '@/onboarding/lib/selectors/storeSelectors';
import { mapActions, mapMutations } from 'vuex';
import { setUserHasBeenOnReview } from '@/onboarding/router/routeHelper';
import BaseWizardPage from '@/shared/components/wizard/BaseWizardPage';
import { checkAppForRenewalNewOrOldFunnel } from '@/onboarding/router/newVsOldFunnelGuards';

export default {
  name: 'ReviewInformationCard',
  components: {
    BaseWizardPage,
    CardFields,
    CardDataSync,
  },
  extends: BaseCard,
  dependencies: ['tracking', 'requests'],
  data() {
    return {
      isLoading: false,
      shouldAlert: false,
      schemaPath: getSchemaEndpoint('review'),
      submitPath: getUpdateEndpoint('review'),
      cardConfigPath: '2019-07-01--REVIEW_INFORMATION',
      formFieldData: [],
    };
  },
  computed: {
    hasReviewErrors() {
      return this.$store.getters.hasReviewErrors;
    },
    hasSetReviewErrors() {
      return this.$store.getters.hasSetReviewErrors;
    },
    decisionData() {
      return getDecisionData(this);
    },
  },
  watch: {
    hasSetReviewErrors: {
      immediate: true,
      handler(newVal, _oldVal) {
        if (newVal === true && !this.hasReviewErrors) {
          this.requests.postPreCacheQuote({ applicationId: this.applicationId });
        }
      },
    },
  },
  async mounted() {
    await checkAppForRenewalNewOrOldFunnel(this);
  },
  created() {
    setUserHasBeenOnReview(this);
  },
  methods: {
    ...mapActions(['fetchDecisionData', 'fetchPackagesData']),
    ...mapMutations(['updateDecisionData', 'updateApplicationData']),
    noop() {},
    handleSubmit() {
      this.isLoading = true;
      this.questionRouter.next(this);
    },
  },
};
</script>

<style scoped lang="scss">
.review-fields {
  margin-bottom: $space-xlarge;
}
</style>
