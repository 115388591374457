<script>
import BaseCardWithFields from '../../../../components/wizard/BaseCardWithFields';
import SchemaFormFieldBoolean from '../../../../../onboarding/components/formfields/SchemaFormFieldBoolean';
import {
  getSchemaEndpoint,
  getUpdateEndpoint,
} from '../../../../../onboarding/lib/selectors/apiPathSelectors';

export default {
  name: 'HasPendingClaimsCard',
  components: { SchemaFormFieldBoolean },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('core'),
      submitPath: getUpdateEndpoint('core'),
      cardConfigPath: '2019-07-01--HAS_PENDING_CLAIMS',
      formFieldData: [
        {
          component: SchemaFormFieldBoolean,
          schemaPath: '2019-07-01--HAS_PENDING_CLAIMS',
          dataPath: '2019-07-01--HAS_PENDING_CLAIMS',
        },
      ],
    };
  },
};
</script>
