import { getUIConfig } from '@/onboarding/services/questionUIConfig';
import { longCurrencyNoCents } from '@/shared/lib/filters/currency_filters';
import { getKeyGuid } from '@/onboarding/services/keyGuid';
import {
  getFormattedPrimaryAddress,
  getFormattedParentPrimaryAddress,
  getFormattedAdditionalAddress,
  getFormattedParentAdditionalAddress,
  applicationIsRenewal,
} from '@/onboarding/lib/selectors/storeSelectors';
import { omit } from 'lodash/object';
import { routingDecisions as onboardingP1RoutingDecisions } from '@/onboarding/services/routing_decisions/routingDecisions';
import { routingDecisionsProgram2 as onboardingP2RoutingDecisions } from '@/onboarding/services/routing_decisions_program_2/routingDecisionsProgram2';
// import { routingDecisions as renewalP1RoutingDecisions} from '@/members/services/routingDecisions'
import { routingDecisionsProgram2 as renewalP2RoutingDecisions } from '@/members/services/routingDecisionsProgram2';
import {
  nicheIndustryDependencies,
  P2NicheGoodsIndustries,
  P2NicheHwIndustries,
  P2NicheSwIndustries,
  P2NicheServicesIndustries,
} from '@/onboarding/constants/P2NicheConstants';

import { get, isArray, isPlainObject } from '@/shared/lib/vouch_dash';
import { checkAuthentication } from '@/shared/vue-plugins/auth0';
import requests from '@/shared/services/requests';

type ReviewFieldConfig = {
  name: String;
  title: String;
  value: String;
  parentValue?: String;
  isValid: Boolean;
  route: any;
  isEditable: Boolean;
  key: Number;
  isChildApplication: Boolean;
  wrap?: Boolean;
};

// TODO: Clean up this type? Not sure where it's defined
export const getFormattedClaim = (claim: string, itemized: any) => {
  if (!itemized) return null;

  const item = itemized[claim];

  if (!item) return null;

  const amount = item['2019-07-01--FILED_CLAIMS_AMOUNTS'];
  const description = item['2019-07-01--FILED_CLAIMS_DESCRIPTION'];
  return `${claim} (${amount}) ${description}`;
};

const routeDecisionMap = {
  isProgram2: {
    true: {
      isRenewal: {
        true: renewalP2RoutingDecisions,
        false: onboardingP2RoutingDecisions,
      },
    },
    false: {
      isRenewal: {
        true: onboardingP1RoutingDecisions,
        false: onboardingP1RoutingDecisions,
      },
    },
  },
};

export class GenerateReviewFields {
  applicationData: any;

  applicationParentData: any;

  applicationId: string;

  definitions: any;

  $store: any;

  reviewFields: ReviewFieldConfig[];

  canEdit: boolean;

  routingComponent: any;

  routingDecisions: any;

  isProgram2: boolean;

  constructor({
    applicationData,
    applicationParentData,
    applicationId,
    $store,
    routingComponent,
    definitions,
    isProgram2,
    isRenewal,
  }) {
    this.applicationData = applicationData;
    this.applicationParentData = applicationParentData;
    this.applicationId = applicationId;
    this.canEdit = !applicationData.is_locked;
    this.reviewFields = [];
    this.$store = $store;
    this.routingComponent = routingComponent;
    this.isProgram2 = isProgram2;
    this.routingDecisions = routeDecisionMap.isProgram2[isProgram2].isRenewal[isRenewal];
    this.definitions = definitions;
    this.definitions['2019-07-01--LOCATIONS'] = {};
  }

  generate(router) {
    const routeForDecision = cardId => ({
      path: router({ cardId, applicationId: this.applicationId }),
      props: true,
    });
    const getReviewTitle = id => {
      const idForLookup = id === '2019-07-01--LOCATIONS' ? '2019-07-01--LOCATION_CONTAINER' : id;
      const config = getUIConfig(idForLookup, this.isProgram2);
      if (config) {
        return config.review.title;
      }
      window.vueRoot.$rollbar.error('uh oh, empty ui config for', id);
      return id;
    };
    const getDisplayType = id =>
      get(getUIConfig(id, this.isProgram2), 'meta.displayType', 'string');
    const parsedValue = ({ value, id }) => {
      if (isArray(value)) {
        return value.join(', ');
      }
      if (typeof value === 'boolean') {
        return value === true ? 'Yes' : 'No';
      }
      if (getDisplayType(id) === 'currency') {
        return longCurrencyNoCents(value);
      }
      return value;
    };

    const renderSingleReviewField = ({
      id,
      isValid,
      value,
      parentValue,
      isChildApplication = false,
      route = routeForDecision(id),
    }) => {
      this.reviewFields.push({
        name: id,
        title: getReviewTitle(id),
        value: parsedValue({ value, id }),
        parentValue: parsedValue({ value: parentValue, id }),
        isValid,
        route,
        isEditable: this.canEdit,
        key: getKeyGuid(),
        isChildApplication,
      });
    };
    const renderContainer = ({ id, value, parentValue, isValid, isChildApplication = false }) => {
      const parentEntries = Object.entries(parentValue || {});
      const defaultValue = isChildApplication ? 'Not Applicable' : null;

      Object.entries(value || {}).forEach(([subId, subValue], index) => {
        if (typeof subValue === 'object') {
          const [, subParentValue] = (parentEntries && parentEntries[index]) || [
            null,
            defaultValue,
          ];
          renderContainer({ id, value: subValue, parentValue: subParentValue, isValid });
        } else {
          const [, subParentValue] = (parentEntries && parentEntries[index]) || [
            null,
            defaultValue,
          ];
          this.reviewFields.push({
            name: subId,
            title: getReviewTitle(subId),
            value: parsedValue({ id: subId, value: subValue }),
            parentValue: parsedValue({ id: subId, value: subParentValue }),
            isValid,
            route: routeForDecision(id),
            isEditable: this.canEdit,
            key: getKeyGuid(),
            isChildApplication,
          });
        }
      });
    };
    const renderSingleAddress = ({
      id,
      value,
      parentValue,
      isValid,
      index,
      isChildApplication = false,
    }) => {
      const isPrimaryAddress = index === undefined;
      const locationContainerId = id === '2019-07-01--LOCATIONS' ? id : `${id}_attributes`;

      // render address
      renderSingleReviewField({
        id,
        value: isPrimaryAddress
          ? getFormattedPrimaryAddress(this)
          : getFormattedAdditionalAddress(this, index),
        parentValue: isPrimaryAddress
          ? getFormattedParentPrimaryAddress(this)
          : getFormattedParentAdditionalAddress(this, index, 'Not applicable'),
        isValid,
        isChildApplication,
      });

      let parentLocationValue;
      if (!isChildApplication) {
        parentLocationValue = null;
      } else if (!parentValue || !parentValue['2019-07-01--LOCATION_TYPE']) {
        parentLocationValue = 'Not Applicable';
      } else {
        parentLocationValue = parentValue['2019-07-01--LOCATION_TYPE'];
      }

      renderSingleReviewField({
        id: isPrimaryAddress
          ? '2019-07-01--LOCATION_TYPE_PRIMARY'
          : '2019-07-01--LOCATION_TYPE_ADDITIONAL',
        value: value['2019-07-01--LOCATION_TYPE'],
        parentValue: parentLocationValue,
        isValid,
        isChildApplication,
        route: routeForDecision(locationContainerId),
      });

      // now any other meta data left related to location
      const skipFields = [
        '2019-07-01--ADDRESS_LINE_1',
        '2019-07-01--ADDRESS_LINE_2',
        '2019-07-01--ADDRESS_CITY',
        '2019-07-01--ADDRESS_STATE',
        '2019-07-01--ADDRESS_COUNTY',
        '2019-07-01--ADDRESS_POSTAL_CODE',
        'location_id',
        '2019-07-01--LOCATION_TYPE',
        'additional_locations',
        'territory_code',
        '2019-07-01--HOW_DID_YOU_HEAR_ABOUT_US',
        '2019-07-01--HOW_DID_YOU_HEAR_ABOUT_US_OTHER',
      ];
      renderContainer({
        id: locationContainerId,
        value: omit(value, skipFields),
        parentValue: omit(parentValue, skipFields),
        isValid,
        isChildApplication,
      });
    };
    const generateLocationReviewFields = ({
      id,
      value,
      parentValue,
      definition,
      isValid,
      isChildApplication = false,
    }) => {
      if (!value) {
        renderSingleReviewField({
          id,
          value,
          parentValue,
          isValid,
          isChildApplication,
        });
      } else {
        renderSingleAddress({
          id,
          value,
          parentValue,
          isValid,
          index: undefined,
          isChildApplication,
        });
        const additionalLocations = value?.additional_locations || [];
        const additionalParentLocations = parentValue?.additional_locations || [];
        additionalLocations.forEach((locationValue, index) => {
          renderSingleAddress({
            id,
            value: locationValue,
            parentValue: additionalParentLocations[index] ?? null,
            isValid,
            index,
            isChildApplication,
          });
        });
      }
    };

    this.routingDecisions.forEach(async ({ id, shouldRender, isSatisfied }) => {
      const value = this.applicationData[id];
      const applicationValues =
        (this.applicationParentData && this.applicationParentData[id]) ?? {};
      const isChildApplication =
        typeof applicationValues === 'object'
          ? Object.keys(applicationValues).length > 0
          : applicationValues !== null || applicationValues !== undefined;
      const definition = this.definitions[id];
      if (id === 'niche/2021-06-01_NICHE_QUESTION_5') {
        const formatBusinessType = applicationType => {
          let nicheDisplayValue = ``;

          nicheDisplayValue += get(applicationType, '2021-06-01--NICHE-CATEGORY', [])
            .map(category => {
              const id = nicheIndustryDependencies[category];
              const values = get(applicationType, id, []).join(', ');

              return `${category}: ${values},`;
            })
            .join('\n');

          const businessModel = get(applicationType, '2021-06-01--NICHE_BUSINESS_MODEL_DESC', []);
          if (businessModel.length > 0) {
            nicheDisplayValue += `\n Business Model: ${businessModel.join(', ')}`;
          }
          return nicheDisplayValue;
        };

        const nicheSelectionsRequiringUserEntry = {
          '2021-06-01--NICHE_SW_INDUSTRY': [
            P2NicheSwIndustries.SOMETHING_ELSE,
            P2NicheSwIndustries.CANNABIS,
          ],
          '2021-06-01--NICHE_HW_INDUSTRY': [P2NicheHwIndustries.SOMETHING_ELSE],
          '2021-06-01--NICHE_GOODS_INDUSTRY': [
            P2NicheGoodsIndustries.SOMETHING_ELSE,
            P2NicheGoodsIndustries.HEALTH,
            P2NicheGoodsIndustries.CANNABIS,
          ],
          '2021-06-01--NICHE_PS_INDUSTRY': [P2NicheServicesIndustries.SOMETHING_ELSE],
        };

        const validArray = array => {
          return !!array && array.filter(e => !!e).length > 0;
        };

        const requiresUserEntered = () => {
          return Object.keys(nicheSelectionsRequiringUserEntry).some(nicheIndustry => {
            if (this.applicationData[nicheIndustry]) {
              return nicheSelectionsRequiringUserEntry[nicheIndustry].includes(
                this.applicationData[nicheIndustry]
              );
            }
            return false;
          });
        };

        const businessDescriptionRequirements = [
          this.applicationData['2021-06-01--PRIMARY_NICHE'],
          this.applicationData['2019-07-01--CATEGORY_NICHE'],
          validArray(this.applicationData['2021-06-01--NICHE-CATEGORY']),
          validArray(this.applicationData['2021-06-01--NICHE_LIST']),
          validArray(this.applicationData['2021-06-01--NICHE_BUSINESS_MODEL_DESC']),
          requiresUserEntered() ? this.applicationData['2021-06-01--NICHE_USER_ENTERED'] : true,
        ];

        if (!applicationIsRenewal(this)) {
          this.reviewFields.push({
            name: id,
            title: 'Business Description',
            value: formatBusinessType(this.applicationData),
            parentValue: formatBusinessType(this.applicationParentData),
            isValid: businessDescriptionRequirements.every(e => !!e),
            route: routeForDecision('niche/2021-06-01_NICHE_QUESTION_5'),
            isEditable: this.canEdit,
            key: getKeyGuid(),
            isChildApplication,
            wrap: true,
          });
        } else {
          const isAdmin = await checkAuthentication().then(requests.getAdminStatus);
          if (isAdmin) {
            this.reviewFields.splice(2, 0, {
              name: id,
              title: 'Business Description',
              value: formatBusinessType(this.applicationData),
              parentValue: formatBusinessType(this.applicationParentData),
              isValid: businessDescriptionRequirements.every(e => !!e),
              route: routeForDecision('niche/2021-06-01_NICHE_QUESTION_5'),
              isEditable: this.canEdit,
              key: getKeyGuid(),
              isChildApplication,
              wrap: true,
            });
          }
        }

        return;
      }

      if (definition === undefined || !shouldRender(this.routingComponent)) {
        // case where we're showing a card that is not a question,
        //   because there's no corresponding definition
        // Or we shouldn't even be showing the card.
        return;
      }

      // TODO: Improve me!  Validate me against the schema.
      //  This requires a full review schema with all relevant properties.

      const isValid = isSatisfied(this.routingComponent);
      const options = {
        id,
        value,
        parentValue: isChildApplication ? applicationValues : null,
        definition,
        isValid,
        isChildApplication,
      };

      if (id === '2019-07-01--LOCATION_CONTAINER') {
        generateLocationReviewFields(options);
      } else if (id === '2019-07-01--LOCATIONS') {
        options.definition = this.definitions['2019-07-01--LOCATION_CONTAINER'];
        options.value = this.applicationData['2019-07-01--LOCATION_CONTAINER'];
        options.parentValue = this.applicationParentData['2019-07-01--LOCATION_CONTAINER'] ?? {};
        options.isChildApplication = Object.keys(options.parentValue).length > 0;
        generateLocationReviewFields(options);
      } else if (id === '2019-07-01--VERTICAL_NICHE') {
        const app = {
          technology: this.applicationData['2019-07-01--CATEGORY_TECHNOLOGY'],
          customer: this.applicationData['2019-07-01--CATEGORY_CUSTOMER'],
          vertical: this.applicationData['2019-07-01--CATEGORY_VERTICAL'],
          niche: this.applicationData['2019-07-01--CATEGORY_NICHE'],
        };

        const parentApp = {
          technology: applicationValues['2019-07-01--CATEGORY_TECHNOLOGY'],
          customer: applicationValues['2019-07-01--CATEGORY_CUSTOMER'],
          vertical: applicationValues['2019-07-01--CATEGORY_VERTICAL'],
          niche: applicationValues['2019-07-01--CATEGORY_NICHE'],
        };

        const formattedValue = `${app.technology}, ${app.customer}, ${app.vertical}, ${app.niche}`;
        const formattedParentValue = parentApp.technology
          ? `${parentApp.technology}, ${parentApp.customer}, ${parentApp.vertical}, ${parentApp.niche}`
          : null;

        renderSingleReviewField({
          ...options,
          value: formattedValue,
          parentValue: formattedParentValue,
        });
      } else if (id === '2019-07-01--LIABILITIES_CLAIMS_CONTAINER') {
        if (isValid) {
          const {
            '2019-07-01--FILED_CLAIMS_TYPES': selectedClaims,
            '2019-07-01--CLAIMS_AMOUNTS_ITEMIZED': itemizedClaims,
          } = value;
          const itemizedParentClaims =
            applicationValues && applicationValues['2019-07-01--CLAIMS_AMOUNTS_ITEMIZED'];
          selectedClaims.forEach(claimType => {
            const formattedValue = getFormattedClaim(claimType, itemizedClaims);
            const formattedParentValue = getFormattedClaim(claimType, itemizedParentClaims);
            renderSingleReviewField({
              ...options,
              value: formattedValue,
              parentValue: formattedParentValue,
            });
          });
        } else {
          // error field
          renderSingleReviewField(options);
        }
      } else if (isPlainObject(value)) {
        renderContainer(options);
      } else {
        renderSingleReviewField(options);
      }
    });

    return this.reviewFields;
  }
}
