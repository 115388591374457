<template>
  <v-radio-group
    v-model="value"
    @change="$emit('change', $event)"
  >
    <v-radio 
      v-for="option in options"
      :key="option.value"
      :color="checkColor"
      :value="option.value"
    >
      <template v-slot:label>
        <div
          class="label"
          v-text="option.label"
        />
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script>
import Colors from '@/Colors';

export default {
  name: 'FormFieldRadioGroup',
  props: {
    options: {
      type: Array,
      required: true,
    },
    initialValue: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      value: this.initialValue,
    };
  },
  computed: {
    checkColor() {
      return Colors.vouchPrimaryGreen;
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  text-transform: none;
  color: $vouch-black;
}
</style>