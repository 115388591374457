<template>
  <HeaderFixed>
    <template
      v-if="$scopedSlots['banner-content']"
      v-slot:banner-content
    >
      <slot name="banner-content" />
    </template>
    <HeaderNav :is-white="whiteHeader" />
  </HeaderFixed>
</template>

<script>
import HeaderFixed from '@/onboarding/views/HeaderFixed';
import HeaderNav from '@/onboarding/views/HeaderNav';

export default {
  name: 'BasicHeader',
  components: { HeaderNav, HeaderFixed },
  props: {
    whiteHeader: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
