<script>
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import SchemaFormFieldBigMoney from '@/onboarding/components/formfields/SchemaFormFieldBigMoney';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';

export default {
  name: 'WizardCardCoreCapitalRaised',
  components: { SchemaFormFieldBigMoney },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('prequal'),
      submitPath: getUpdateEndpoint('prequal'),
      cardConfigPath: '2019-07-01--CAPITAL_RAISED_CONTAINER',
      formFieldData: [
        {
          component: SchemaFormFieldBigMoney,
          dataPath: '2019-07-01--CAPITAL_RAISED_CONTAINER.2019-07-01--CAPITAL_RAISED_EQUITY_CENTS',
          schemaPath: '2019-07-01--CAPITAL_RAISED_EQUITY_CENTS',
          warning: captialInCents => {
            return captialInCents < 1000_00 ? 'Are you sure? Equity is less than $1,000.' : '';
          },
        },
        {
          component: SchemaFormFieldBigMoney,
          dataPath: '2019-07-01--CAPITAL_RAISED_CONTAINER.2019-07-01--CAPITAL_RAISED_DEBT_CENTS',
          schemaPath: '2019-07-01--CAPITAL_RAISED_DEBT_CENTS',
        },
      ],
    };
  },
};
</script>
