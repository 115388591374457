<template>
  <Interstitial
    :next="next"
    :message="message"
  />
</template>

<script>
import Interstitial from '@/shared/views/layouts/Interstitial.vue';
import { rerouteAuthenticatedUser } from '@/onboarding/router/rerouteUser';

export default {
  name: 'RerouteOnAuth',
  components: { Interstitial },
  props: {
    appAttempt: {
      type: String,
      default: 'false',
    },
    applicationId: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  data() {
    return {
      next: '/loginportal/authError',
      message: '',
    };
  },
  computed: {
    userAttemptedNewApp() {
      // Convert URL param to boolean
      return this.appAttempt === 'true';
    },
  },

  async created() {
    const { message, next } = await rerouteAuthenticatedUser({
      router: this.$router,
      userAttemptedNewApp: this.userAttemptedNewApp,
      givenApplicationId: this.applicationId,
    });

    this.message = message;
    this.next = next;
  },
};
</script>
