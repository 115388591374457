<template>
  <CenteredContainer class="checkout-page__footer">
    <v-row
      no-gutters
      justify="space-between"
    >
      <v-col md="5">
        <SectionWithTitle title="How It Works">
          <p>
            Once you raise $1M or more or experience another qualifying event (relocate, renew, upgrade, or claim),
            we'll send you an invoice for the premium amount. You'll have up to 30 days to pay.
          </p>
          <p>
            Yes, it's that simple.
          </p>
        </SectionWithTitle>
      </v-col>
    </v-row>
  </CenteredContainer>
</template>

<script>
import CenteredContainer from '@/shared/components/layouts/CenteredContainer';
import SectionWithTitle from '@/onboarding/views/SectionWithTitle';

export default {
  name: 'CheckoutFooterPayWhenYouRaise',
  components: {
    CenteredContainer,
    SectionWithTitle,
  },
};
</script>

<style lang="scss" scoped>
.checkout-page__footer {
  background-color: $checkout-footer-background-color;
  box-shadow: 0 50vh 0 50vh $checkout-footer-background-color;

  padding-top: $space-xlarge;
  padding-bottom: $space-xlarge;
}
</style>
