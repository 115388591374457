<template>
  <FormFieldTextArea
    :value="inputValue"
    :error="isValid === false"
    :error-message="errorMessage"
    :placeholder="propertyDefinition.examples[0]"
    @input="setInputValue"
  />
</template>

<script>
import BaseSchemaFormField from './BaseSchemaFormField';
import FormFieldTextArea from '@/shared/components/ui/atoms/form_fields/FormFieldTextArea';

export default {
  name: 'SchemaFormFieldTextArea',
  components: { FormFieldTextArea },
  extends: BaseSchemaFormField,
};
</script>
