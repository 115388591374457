import { get } from '../../shared/lib/vouch_dash';
import { nicheRoutingDecisions } from './routing_decisions/nicheRoutingDecisions';

const dependencyMap = [
  {
    dataPath: ['2019-07-01--HAS_ENGINEERING_CONTRACTORS'],
    dependentFields: ['2019-07-01--NUM_ENGINEERING_CONTRACTORS'],
  },
  {
    dataPath: ['2019-07-01--HAS_401K'],
    dependentFields: ['2019-07-01--BENEFITS_WORTH'],
  },
  {
    dataPath: ['2019-07-01--HAS_ANY_PII'],
    dependentFields: ['2019-07-01--PII_AMOUNT'],
  },
  {
    dataPath: ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_CURRENT_CLAIMS'],
    dependentFields: ['2019-07-01--CURRENT_CLAIMS_DESCRIPTION'],
  },
  {
    dataPath: ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_DENIED_COVERAGE'],
    dependentFields: ['2019-07-01--DENIED_COVERAGE_DESCRIPTION'],
  },
  {
    dataPath: ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_FILED_LIABILITY_CLAIM'],
    dependentFields: ['2019-07-01--LIABILITIES_CLAIMS_CONTAINER'],
  },
  {
    dataPath: ['2019-07-01--HAS_PENDING_CLAIMS'],
    dependentFields: ['2019-07-01--PENDING_CLAIMS_DESCRIPTION'],
  },
];

const nicheFieldNames = [
  '2019-07-01--CATEGORY_TECHNOLOGY',
  '2019-07-01--CATEGORY_CUSTOMER',
  '2019-07-01--CATEGORY_VERTICAL',
  '2019-07-01--CATEGORY_NICHE',
];

const dataIncludesNiches = data => Object.keys(data).find(k => nicheFieldNames.includes(k));

const hadPreviousNicheData = store => {
  // @ts-ignore
  const previousApplicationData = store.state.application.applicationData;
  const oldTechnology = previousApplicationData['2019-07-01--CATEGORY_TECHNOLOGY'];
  const oldCustomer = previousApplicationData['2019-07-01--CATEGORY_CUSTOMER'];
  const oldVertical = previousApplicationData['2019-07-01--CATEGORY_VERTICAL'];
  const oldNiche = previousApplicationData['2019-07-01--CATEGORY_NICHE'];
  return oldTechnology || oldCustomer || oldVertical || oldNiche;
};

const nicheDataSame = (data, store) => {
  // @ts-ignore
  const previousApplicationData = store.state.application.applicationData;

  const newTechnology = data['2019-07-01--CATEGORY_TECHNOLOGY'];
  const newCustomer = data['2019-07-01--CATEGORY_CUSTOMER'];
  const newVertical = data['2019-07-01--CATEGORY_VERTICAL'];
  const newNiche = data['2019-07-01--CATEGORY_NICHE'];

  const oldTechnology = previousApplicationData['2019-07-01--CATEGORY_TECHNOLOGY'];
  const oldCustomer = previousApplicationData['2019-07-01--CATEGORY_CUSTOMER'];
  const oldVertical = previousApplicationData['2019-07-01--CATEGORY_VERTICAL'];
  const oldNiche = previousApplicationData['2019-07-01--CATEGORY_NICHE'];

  return (
    newTechnology === oldTechnology &&
    newCustomer === oldCustomer &&
    newVertical === oldVertical &&
    newNiche === oldNiche
  );
};

// const nicheRoutingDecisions=[]
const allNicheFields = nicheRoutingDecisions.map(({ id }) => id);

export const clearDependentFields = (data, store) => {
  let fields = [] as string[];

  if (dataIncludesNiches(data) && hadPreviousNicheData(store) && !nicheDataSame(data, store)) {
    fields = [...fields, ...allNicheFields];
  }

  dependencyMap.forEach(({ dataPath, dependentFields }) => {
    if (get(data, dataPath) === false) {
      fields = [...fields, ...dependentFields];
    }
  });
  return fields;
};
