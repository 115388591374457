import { autoYes } from '@/shared/routing/routingDecisionHelpers';
import {
  hasInsuranceSelfCard,
  numFullTimeWorkersCard,
  capitalRaisedContainerCard,
} from '@/shared/routing/questionDecisionsProgram2';
import { generateRoutingDecisions, getAllNicheQuestions } from '@/onboarding2/niche.domain';

// Prequal
const prequalStartCard = {
  id: 'prequal-start',
  isSatisfied: autoYes,
  shouldRender: autoYes,
};

const endOfPrequal = {
  id: 'prequal-end',
  isSatisfied: autoYes,
  shouldRender: component => !component.$auth.isAuthenticated,
};

export const prequalRoutingDecisions = [
  prequalStartCard,
  hasInsuranceSelfCard,
  numFullTimeWorkersCard,
  capitalRaisedContainerCard,
  ...generateRoutingDecisions(getAllNicheQuestions()),
  endOfPrequal,
];
