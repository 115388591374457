<template>
  <GenericStatusPage
    :image="image"
    :title="title"
  >
    <template v-slot:primary-reason-content>
      Something went sideways.
    </template>
    <template v-slot:description-content>
      We're sorry - an engineer has been alerted, and a Vouch pro will reach out once we can recover
      from the situation.
    </template>
    <template v-slot:status-button>
      <ButtonPrimary @click="showChat">
        Talk to an advisor
      </ButtonPrimary>
    </template>
  </GenericStatusPage>
</template>

<script>
import GenericStatusPage from '@/shared/views/layouts/GenericStatusPage';
import bigLogo from '@/shared/assets/images/big-logo.png';
import ButtonPrimary from '@/shared/components/ui/atoms/buttons/ButtonPrimary';
import { CRUMBS_PAGE_DISPLAYED } from '@/onboarding/services/SegmentEventTypes';
import LogRocket from 'logrocket';
import { get } from 'lodash';
import { showChatIfEnabled } from '@/onboarding/services/chatHelper';

export default {
  name: 'CrumbsPage',
  dependencies: ['tracking'],
  components: { ButtonPrimary, GenericStatusPage },
  props: {
    sourceUrl: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      decisionData: { decision: null },
      title: 'Crumbs.',
      image: bigLogo,
    };
  },
  mounted() {
    // The LogRocket sessionUrl is unavailable under some conditions
    const lrSdkSessionUrl = get(LogRocket, 'sessionURL', 'Must call LogRocket');
    const lrSessionUrl =
      lrSdkSessionUrl === null || lrSdkSessionUrl.includes('Must call LogRocket')
        ? 'Unavailable'
        : LogRocket.sessionURL;
    this.tracking.sendEvent(CRUMBS_PAGE_DISPLAYED, {
      sourceUrl: this.sourceUrl,
      error: this.error,
      lrSessionUrl,
    });
  },
  methods: {
    showChat() {
      showChatIfEnabled({ tracking: this.tracking });
    },
  },
};
</script>
