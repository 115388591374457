<template>
  <component
    :is="formField['component']"
    :value="formField['value']"
    :data-testid="formField['name'] || formField['schemaPath']"
    :data-path="formField['dataPath']"
    :validator="formField['validator']"
    :warning="formField['warning']"
    :placeholder="formField['placeholder']"
    :property-definition="formField['propertyDefinition']"
    :property-name="formField['name'] || formField['schemaPath']"
    :is-single-field="isSingleField"
    @input="onInputChange"
  />
</template>

<script>
export default {
  name: 'CardField',
  props: {
    isSingleField: {
      required: false,
      default: false,
      type: Boolean,
    },
    formField: {
      required: true,
      type: Object,
    },
    onInputChange: {
      required: true,
      type: Function,
    },
  },
};
</script>
