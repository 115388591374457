class SchemaService {
  schema: any;

  ajv: any;

  constructor(schema, ajv) {
    this.schema = schema;
    this.ajv = ajv;
  }

  getSchema() {
    return this.schema;
  }

  getPropertyValidator(definitionKeys) {
    const definitionKeysArr = Array.isArray(definitionKeys) ? definitionKeys : [definitionKeys];

    // access the definitions part of the schema, not the properties section
    let schema = this.schema.definitions[definitionKeysArr.shift()];

    // walk down the json structure by property name
    schema = definitionKeysArr.reduce(
      (currSchema, definitionKey) => currSchema.properties[definitionKey],
      schema
    );

    return this.ajv.compile(schema);
  }
}

export default SchemaService;
