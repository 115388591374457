<template>
  <v-checkbox 
    value="value" 
    @change="$emit('change', !!$event)" 
  >
    <template v-slot:label>
      <div
        class="coi-ack-label"
        data-testid="checkout-coi-ack-label"
      >
        I understand that my Certificate of Insurance will be available only after my signed policy documents are received and after my ACH payment settles, which can take up to 5 business days after debiting.
      </div>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: 'CoiAcknowledgement',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.coi-ack-label {
  text-transform: none;
  font-family: $sans-font-family;
  color: $vouch-black;
}

::v-deep .v-input__slot {
  align-items: flex-start;
}
</style>
