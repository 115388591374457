import Vue from 'vue';
import injector from 'vue-inject';
import LoadScript from 'vue-plugin-load-script';
import LogRocket from 'logrocket';

import App from '@/App.vue';
import router from '@/router';
import store from '@/shared/store';
import Auth0LockPlugin from '@/shared/vue-plugins/auth0';
import auth0Config from '@/shared/app-config/auth0-config';

import rollbarPlugin from '@/shared/vue-plugins/rollbar-plugin';
import rollbarConfig from '@/shared/app-config/rollbar-config';

import requests from '@/shared/services/requests';
import SchemaServiceFactory from '@/onboarding/services/SchemaServiceFactory';
import FunnelCardSchemaHelper from '@/onboarding/services/FunnelCardSchemaHelper';
import TrackingService from '@/onboarding/services/TrackingService';
import featureFlagging from '@/onboarding/services/FeatureFlaggingService';
import QuestionRouterService from '@/shared/services/QuestionRouterService';
import Env from '@/shared/services/Env';
import setupSuppressNavigationErrors from '@/onboarding/router/navigationErrorSuppression';
import { bootZendesk } from '@/onboarding/services/zendesk';

import VueMq from 'vue-mq';
import breakpoints from '@/shared/app-config/breakpoints';

import '@/onboarding/styles/fonts.scss';
import '@/onboarding/styles/print.scss';
import vuetify from '@/onboarding/plugins/vuetify';

import { shouldIgnoreError } from '@/onboarding/services/ErrorHandling';

import logRocketConfig from '@/shared/app-config/logrocket-config';
import trackingSetup from '@/onboarding/services/TrackingServiceSetup';
import { earlyIdentifyAnonymousUser } from '@/onboarding/services/AnonymousTracking';
import PlaidService from '@/onboarding/services/PlaidService';

const { logRocketKey } = Env.getConfig();

if (logRocketKey !== '') {
  LogRocket.init(logRocketKey, logRocketConfig);
}

// Do bootstrapping configuration and initialization here
Vue.use(LoadScript);
Vue.use(VueMq, { breakpoints });
Vue.use(injector);
Vue.use(rollbarPlugin, rollbarConfig);

const tracking = new TrackingService({ requests });

Vue.use(Auth0LockPlugin, {
  ...auth0Config,
  onRedirectCallback: state => {
    router.push(
      state?.targetUrl ? state.targetUrl : `${window.location.pathname}${window.location.search}`
    );
  },
  tracking,
  router,
});

earlyIdentifyAnonymousUser();

bootZendesk({ tracking });

setupSuppressNavigationErrors(router);

// do dependency injection here
injector.factory('store', [], () => store);
injector.service('questionRouter', ['store'], QuestionRouterService);
injector.factory('tracking', [], () => tracking);
injector.service('plaid', ['tracking'], PlaidService);
injector.factory('trackingSetup', [], () => trackingSetup);
injector.factory('featureFlags', [], () => featureFlagging);
injector.factory('requests', [], () => requests);
injector.factory('SchemaServiceFactory', [], () => SchemaServiceFactory);
injector.factory('FunnelCardSchemaHelper', [], () => FunnelCardSchemaHelper);

Vue.config.productionTip = false;

Vue.config.errorHandler = (err, vm, info) => {
  if (shouldIgnoreError(err)) {
    vm.$rollbar.debug(err, info);
  } else {
    vm.$rollbar.error(err, info);
  }
};

window.vueRoot = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
