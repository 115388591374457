import {
  PLAID_LINK_OPENED,
  PLAID_LINK_EXITED,
  PLAID_LINK_HANDOFF_COMPLETED,
  PLAID_LINK_INSTITUTION_SEARCHED,
  PLAID_LINK_INSTITUTION_SELECTED,
  PLAID_LINK_ERROR,
} from '@/onboarding/services/SegmentEventTypes';

const TrackedPlaidEvents = {
  OPEN: {
    event: PLAID_LINK_OPENED,
    fields: ['request_id'],
  },
  EXIT: {
    event: PLAID_LINK_EXITED,
    fields: ['request_id', 'error_type', 'error_code', 'error_message', 'exit_status'],
  },
  HANDOFF: {
    event: PLAID_LINK_HANDOFF_COMPLETED,
    fields: ['request_id', 'institution_id', 'institution_name'],
  },
  SEARCH_INSTITUTION: {
    event: PLAID_LINK_INSTITUTION_SEARCHED,
    fields: ['request_id', 'institution_search_query'],
  },
  SELECT_INSTITUTION: {
    event: PLAID_LINK_INSTITUTION_SELECTED,
    fields: ['request_id', 'institution_id', 'institution_name'],
  },
  ERROR: {
    event: PLAID_LINK_ERROR,
    fields: ['request_id', 'error_type', 'error_code', 'error_message'],
  },
};

type SuccessfulLinkResponse = {
  plaidToken: string;
  plaidAccountId: string;
};

type CanceledLinkResponse = {
  canceled: true;
};

type LinkResponse = SuccessfulLinkResponse | CanceledLinkResponse;

class PlaidService {
  tracking;

  constructor(tracking) {
    this.tracking = tracking;
  }

  isInitialized(): boolean {
    return window.Plaid !== undefined;
  }

  open(linkToken): Promise<LinkResponse> {
    return new Promise((resolve, reject) => {
      window.Plaid.create({
        token: linkToken,
        onSuccess: (plaidToken, metadata) => {
          if (metadata.accounts.length === 1) {
            resolve({ plaidToken, plaidAccountId: metadata.accounts[0].id });
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
              error_message: `Invalid number of selected accounts from Plaid: ${metadata.accounts.length}`,
            });
          }
        },
        onEvent: (eventName, metadata) => {
          if (!TrackedPlaidEvents[eventName]) return;
          const eventData = TrackedPlaidEvents[eventName].fields.reduce((data, field) => {
            return { ...data, [field]: metadata[field] };
          }, {});
          this.tracking.sendEvent(TrackedPlaidEvents[eventName].event, eventData);
        },
        onExit: error => {
          if (error) {
            reject(error);
          } else {
            resolve({ canceled: true });
          }
        },
      }).open();
    });
  }
}

export default PlaidService;
