var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WithDataSync',{attrs:{"update-fn":_vm.updateFn,"submit-path":_vm.submitPath,"schema-path":_vm.schemaPath,"application-id":_vm.applicationId,"on-submit-success":_vm.onSubmitSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var onInputChange = ref.onInputChange;
var onSubmit = ref.onSubmit;
var isValidForm = ref.isValidForm;
return [_c('WithCardData',{attrs:{"schema-path":_vm.schemaPath,"card-config-path":_vm.cardConfigPath},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cardData = ref.cardData;
return [_c('BaseWizardPage',{attrs:{"card-data":cardData,"loading":_vm.isLoading,"is-submit-disabled":isValidForm !== true},on:{"submit":function($event){return _vm.withLoading(onSubmit)}}},[_c('WithFormFields',{attrs:{"form-field-data":_vm.formFieldData,"schema-path":_vm.schemaPath},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formFields = ref.formFields;
return [_c('CardFields',{attrs:{"index":0,"form-fields":formFields,"on-input-change":onInputChange}})]}}],null,true)}),_vm._l((_vm.selectedFormFields),function(fieldsSetData,index){return _c('WithFormFields',{key:fieldsSetData.guid,attrs:{"index":fieldsSetData.guid,"form-field-data":fieldsSetData.fields,"schema-path":_vm.schemaPath},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formFields = ref.formFields;
return [_c('div',{staticClass:"claims-section",attrs:{"data-testid":("claims-section-" + index)}},[_c('h2',{staticClass:"claims-section-heading"},[_vm._v(" "+_vm._s(_vm.selectedClaims[index])+" ")]),_c('CardFields',{staticClass:"claims-section-form-field",attrs:{"form-fields":formFields,"on-input-change":onInputChange}})],1)]}}],null,true)})})],2)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }