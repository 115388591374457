/* istanbul ignore file */
// this file is strictly configuration, and aligns with the 'humble object' pattern, so testing isn't necessary.

import {
  hasDangerousProductsP2Card,
  hasDangerousProductsDescP2Card,
  manufacturingStatusCard,
  manufacturingStatusDescCard,
  causedHarmCard,
  causedHarmDescCard,
  hasPhysicalRetailCard,
  mediaHasStateRegDemandsCard,
  mediaHasStateRegDemandsDescCard,
  mediaHasActRelatedDemandsCard,
  mediaHasActRelatedDemandsDescCard,
  mediaHasClpDemandsCard,
  mediaHasClpDemandsDescCard,
  mediaHasCertificateBizCard,
  mediaHasCertificateBizDescCard,
  mediaHasControvMaterialCard,
  mediaHasControvMaterialDescCard,
  mediaNumContentConsumersCard,
  mediaMakesOrSellsPgCard,
  mediaMakesOrSellsPgDescCard,
  mediaLoansOrTransfersCard,
  mediaLoansOrTransfersDescCard,
  transpHasContractDisputesCard,
  transpHasContractDisputesDescCard,
  transpLicensingDisputesCard,
  transpLicensingDisputesDescCard,
  transpOwnsTransportCard,
  transpOwnsTransportDescCard,
  psProfServicesCard,
  psProfServicesDescCard,
  psDemandsMadeCard,
  psDemandsMadeDescCard,
  ftOperatesFintechCard,
  ftOperatesFintechDescCard,
  ftHasDemandsCard,
  ftHasDemandsDescCard,
  ftGtmStrategyCard,
  ftAnnualLoansCard,
  ftInternationalSalesCard,
  ftHasBlockchainBizCard,
  ftLargestLoanSize,
  ftTotalAnnualLoansCard,
  ftHandlesInsuranceClaimsCard,
  ftIsDirectlyRegulatedCard,
  didTrialCard,
  didTrialDescCard,
  willTrialCard,
  willTrialDescCard,
  pharmaWhenInMarketCard,
  pharmaIsInRadioCard,
  meddevSpecialDeviceCard,
  meddevSpecialDeviceDescCard,
  meddevInMarketCard,
  meddevInMarketDescCard,
  hwHasDangerousProductsCard,
  epriseAdviceReportingCard,
  epriseAdviceReportingDescCard,
  epriseDemandsMadeCard,
  epriseDemandsMadeDescCard,
  epriseSpecialCategoryCard,
  epriseExpectedIntlRevenueCard,
  epriseLoanServicesCard,
  epriseLoanServicesDescCard,
  ftInvestmentServicesActivities,
} from '@/shared/routing/questionDecisionsProgram2';

export const supplementalRoutingDecisions = [
  hasDangerousProductsP2Card,
  hasDangerousProductsDescP2Card,
  manufacturingStatusCard,
  manufacturingStatusDescCard,
  causedHarmCard,
  causedHarmDescCard,
  hasPhysicalRetailCard,
  mediaHasStateRegDemandsCard,
  mediaHasStateRegDemandsDescCard,
  mediaHasActRelatedDemandsCard,
  mediaHasActRelatedDemandsDescCard,
  mediaHasClpDemandsCard,
  mediaHasClpDemandsDescCard,
  mediaHasCertificateBizCard,
  mediaHasCertificateBizDescCard,
  mediaHasControvMaterialCard,
  mediaHasControvMaterialDescCard,
  mediaNumContentConsumersCard,
  mediaMakesOrSellsPgCard,
  mediaMakesOrSellsPgDescCard,
  mediaLoansOrTransfersCard,
  mediaLoansOrTransfersDescCard,
  transpHasContractDisputesCard,
  transpHasContractDisputesDescCard,
  transpLicensingDisputesCard,
  transpLicensingDisputesDescCard,
  transpOwnsTransportCard,
  transpOwnsTransportDescCard,
  psProfServicesCard,
  psProfServicesDescCard,
  psDemandsMadeCard,
  psDemandsMadeDescCard,
  ftOperatesFintechCard,
  ftOperatesFintechDescCard,
  ftHasDemandsCard,
  ftHasDemandsDescCard,
  ftGtmStrategyCard,
  ftAnnualLoansCard,
  ftInternationalSalesCard,
  ftHasBlockchainBizCard,
  ftLargestLoanSize,
  ftTotalAnnualLoansCard,
  ftHandlesInsuranceClaimsCard,
  ftIsDirectlyRegulatedCard,
  didTrialCard,
  didTrialDescCard,
  willTrialCard,
  willTrialDescCard,
  pharmaWhenInMarketCard,
  pharmaIsInRadioCard,
  meddevSpecialDeviceCard,
  meddevSpecialDeviceDescCard,
  meddevInMarketCard,
  meddevInMarketDescCard,
  hwHasDangerousProductsCard,
  epriseAdviceReportingCard,
  epriseAdviceReportingDescCard,
  epriseDemandsMadeCard,
  epriseDemandsMadeDescCard,
  epriseSpecialCategoryCard,
  epriseExpectedIntlRevenueCard,
  epriseLoanServicesCard,
  epriseLoanServicesDescCard,
  ftInvestmentServicesActivities,
];
