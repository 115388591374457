<template>
  <div class="layout-card-page">
    <div
      class="panel-container"
      :class="{'panel-container--mobile': isMobile}"
    >
      <QuestionCardHeader
        v-if="!isMobile"
        class="left-header"
        :text="headerText"
      />

      <div
        v-if="!isMobile"
        class="left-panel"
      >
        <slot name="left-panel" />
      </div>

      <div
        v-if="!isMobile"
        class="divider"
      />

      <div class="main-header">
        <slot name="main-header" />
      </div>

      <div class="main-panel">
        <slot name="main-panel" />
      </div>
    </div>
  </div>
</template>

<script>
import QuestionCardHeader from '@/shared/components/ui/molecules/QuestionCardHeader';

export default {
  name: 'LayoutCardPage',
  components: {
    QuestionCardHeader,
  },
  props: {
    headerText: {
      required: true,
      type: String,
    },
    isMobile: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-card-page {
  min-height: $panel-height;
  position: relative;
}

.panel-container {
  min-height: inherit;
  height: 100%;
  display: grid;
  grid-template-columns: [sidebar] max-content [divider] 1px [main] auto;
  grid-template-rows: [header] auto [main] 1fr;
  grid-column-gap: $space-large;
  grid-row-gap: $space-large;

  &--mobile {
    grid-template-columns: [main] auto;
  }
}

.divider {
  grid-column: divider;
  grid-row: header / span 2;
  border-right: 1px solid $coverage-card-separator-color;
}

.main-panel {
  align-self: center;

  grid-row: main;
  grid-column: main;
}

.carta-data-share-opt-in .main-panel {
  align-self: start;
}

.main-header {
  grid-row: header;
  grid-column: main;
  align-self: center;
}

.left-panel {
  grid-row: main;
  grid-column: sidebar;
}

.left-header {
  grid-row: header;
  grid-column: sidebar;
  align-self: center;
}
</style>
