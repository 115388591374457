<script>
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';
import SchemaFormFieldTextArea from '../../../../onboarding/components/formfields/SchemaFormFieldTextArea';

export default {
  name: 'BusinessDescription',
  components: { SchemaFormFieldTextArea },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('niche'),
      submitPath: getUpdateEndpoint('niche'),
      cardConfigPath: '2019-07-01--BUSINESS_DESCRIPTION',
      formFieldData: [
        {
          component: SchemaFormFieldTextArea,
          dataPath: '2019-07-01--BUSINESS_DESCRIPTION',
          schemaPath: '2019-07-01--BUSINESS_DESCRIPTION',
        },
      ],
    };
  },
};
</script>
