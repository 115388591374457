import RenewalStartCard from '@/members/views/questions/RenewalStartCard.vue';
import RenewalEndCard from '@/members/views/questions/RenewalEndCard.vue';
import RenewalScheduleCall from '@/members/views/questions/RenewalScheduleCall.vue';
import NumWorkersCard from '@/shared/views/wizard/prequalification/NumWorkersCard.vue';
import CapitalRaisedCard from '@/shared/views/wizard/core/normal/CapitalRaisedCard.vue';
import ApplicantInformationCard from '@/members/views/wizard/ApplicantInformationCard.vue';
import ReviewInformationCard from '@/members/views/wizard/ReviewInformationCard.vue';
import ReviewFieldsContainer from '@/members/views/wizard/ReviewFieldsContainer.vue';

import HasInsuranceReqsCard from '@/shared/views/wizard/core/normal/HasInsuranceReqsCard.vue';
import HasInsuranceReqsDescCard from '@/shared/views/wizard/core/normal/HasInsuranceReqsDescCard.vue';
import AddressCard from '@/shared/views/wizard/core/outliers/AddressCard.vue';
import AddressAttributesCard from '@/shared/views/wizard/core/outliers/AddressAttributesCard.vue';
import HasBigTicketItemCard from '@/shared/views/wizard/core/normal/HasBigTicketItemCard.vue';
import BigTicketItemDescCard from '@/shared/views/wizard/core/normal/BigTicketItemDescCard.vue';
import LeadInvestorLastRoundCard from '@/shared/views/wizard/core/normal/LeadInvestorLastRoundCard.vue';
import FounderPreviousRaiseCard from '@/shared/views/wizard/core/normal/FounderPreviousRaiseCard.vue';
import PercentOwnedNonVcCard from '@/shared/views/wizard/core/normal/PercentOwnedNonVcCard.vue';
import PrefShareSalesCard from '@/shared/views/wizard/core/normal/PrefShareSalesCard.vue';
import SharesPubliclyListedCard from '@/shared/views/wizard/core/normal/SharesPubliclyListedCard.vue';
import AnnualRevenueCard from '@/shared/views/wizard/core/normal/AnnualRevenueCard.vue';
import NumBigRevenueContractsCard from '@/shared/views/wizard/niches/NumBigRevenueContractsCard.vue';
import TotalCashCard from '@/shared/views/wizard/core/normal/TotalCashCard.vue';
import AcquireCompanyCard from '@/shared/views/wizard/core/normal/AcquireCompanyCard.vue';
import Has401kCard from '@/shared/views/wizard/core/normal/Has401kCard.vue';
import HasERISACard from '@/shared/views/wizard/core/normal/HasERISACard.vue';
import BenefitsWorthCard from '@/shared/views/wizard/core/normal/BenefitsWorthCard.vue';
import OffersConsultingCard from '@/shared/views/wizard/core/normal/OffersConsultingCard.vue';
import HasPIICard from '@/shared/views/wizard/core/normal/HasPIICard.vue';
import PIIAmountCard from '@/shared/views/wizard/core/normal/PIIAmountCard.vue';
import SocComplianceCard from '@/shared/views/wizard/core/normal/SocComplianceCard.vue';
import LoginRequiresMfaCard from '@/shared/views/wizard/core/normal/LoginRequiresMfaCard.vue';
import HasEngContractorsCard from '@/shared/views/wizard/core/normal/HasEngContractorsCard.vue';
import NumEngContractorsCard from '@/shared/views/wizard/core/normal/NumEngContractorsCard.vue';
import InsuranceHistoryCard from '@/shared/views/wizard/core/normal/InsuranceHistoryCard.vue';
import CurrentClaimsCard from '@/shared/views/wizard/core/normal/CurrentClaimsCard.vue';
import CurrentClaimsNumCard from '@/shared/views/wizard/core/normal/CurrentClaimsNumCard.vue';

// Program 2 supplemental cards
import HasDangerousProductsP2Card from '@/shared/views/wizard/program2_supplemental/HasDangerousProductsP2Card.vue';
import HasDangerousProductsDescP2Card from '@/shared/views/wizard/program2_supplemental/HasDangerousProductsDescP2Card.vue';
import ManufacturingStatusCard from '@/shared/views/wizard/program2_supplemental/ManufacturingStatusCard.vue';
import ManufacturingStatusDescCard from '@/shared/views/wizard/program2_supplemental/ManufacturingStatusDescCard.vue';
import CausedHarmCard from '@/shared/views/wizard/program2_supplemental/CausedHarmCard.vue';
import CausedHarmDescCard from '@/shared/views/wizard/program2_supplemental/CausedHarmDescCard.vue';
import HasPhysicalRetailCard from '@/shared/views/wizard/program2_supplemental/HasPhysicalRetailCard.vue';
import MediaHasStateRegDemandsCard from '@/shared/views/wizard/program2_supplemental/MediaHasStateRegDemandsCard.vue';
import MediaHasStateRegDemandsDescCard from '@/shared/views/wizard/program2_supplemental/MediaHasStateRegDemandsDescCard.vue';
import MediaHasActRelatedDemandsCard from '@/shared/views/wizard/program2_supplemental/MediaHasActRelatedDemandsCard.vue';
import MediaHasActRelatedDemandsDescCard from '@/shared/views/wizard/program2_supplemental/MediaHasActRelatedDemandsDescCard.vue';
import MediaHasClpDemandsCard from '@/shared/views/wizard/program2_supplemental/MediaHasClpDemandsCard.vue';
import MediaHasClpDemandsDescCard from '@/shared/views/wizard/program2_supplemental/MediaHasClpDemandsDescCard.vue';
import MediaHasCertificateBizCard from '@/shared/views/wizard/program2_supplemental/MediaHasCertificateBizCard.vue';
import MediaHasCertificateBizDescCard from '@/shared/views/wizard/program2_supplemental/MediaHasCertificateBizDescCard.vue';
import MediaHasControvMaterialCard from '@/shared/views/wizard/program2_supplemental/MediaHasControvMaterialCard.vue';
import MediaHasControvMaterialDescCard from '@/shared/views/wizard/program2_supplemental/MediaHasControvMaterialDescCard.vue';
import MediaNumContentConsumersCard from '@/shared/views/wizard/program2_supplemental/MediaNumContentConsumersCard.vue';
import MediaMakesOrSellsPgCard from '@/shared/views/wizard/program2_supplemental/MediaMakesOrSellsPgCard.vue';
import MediaMakesOrSellsPgDescCard from '@/shared/views/wizard/program2_supplemental/MediaMakesOrSellsPgDescCard.vue';
import MediaLoansOrTransfersCard from '@/shared/views/wizard/program2_supplemental/MediaLoansOrTransfersCard.vue';
import MediaLoansOrTransfersDescCard from '@/shared/views/wizard/program2_supplemental/MediaLoansOrTransfersDescCard.vue';
import TranspHasContractDisputesCard from '@/shared/views/wizard/program2_supplemental/TranspHasContractDisputesCard.vue';
import TranspHasContractDisputesDescCard from '@/shared/views/wizard/program2_supplemental/TranspHasContractDisputesDescCard.vue';
import TranspLicensingDisputesCard from '@/shared/views/wizard/program2_supplemental/TranspLicensingDisputesCard.vue';
import TranspLicensingDisputesDescCard from '@/shared/views/wizard/program2_supplemental/TranspLicensingDisputesDescCard.vue';
import TranspOwnsTransportCard from '@/shared/views/wizard/program2_supplemental/TranspOwnsTransportCard.vue';
import TranspOwnsTransportDescCard from '@/shared/views/wizard/program2_supplemental/TranspOwnsTransportDescCard.vue';
import PsProfServicesCard from '@/shared/views/wizard/program2_supplemental/PsProfServicesCard.vue';
import PsProfServicesDescCard from '@/shared/views/wizard/program2_supplemental/PsProfServicesDescCard.vue';
import FtOperatesFintechCard from '@/shared/views/wizard/program2_supplemental/FtOperatesFintechCard.vue';
import FtInternationalSalesCard from '@/shared/views/wizard/program2_supplemental/FtInternationalSalesCard.vue';
import FtHasBlockchainBizCard from '@/shared/views/wizard/program2_supplemental/FtHasBlockchainBizCard.vue';
import FtLargestLoanSize from '@/shared/views/wizard/program2_supplemental/FtLargestLoanSize.vue';
import FtTotalAnnualLoansCard from '@/shared/views/wizard/program2_supplemental/FtTotalAnnualLoansCard.vue';
import FtHandlesInsuranceClaimsCard from '@/shared/views/wizard/program2_supplemental/FtHandlesInsuranceClaimsCard.vue';
import FtIsDirectlyRegulatedCard from '@/shared/views/wizard/program2_supplemental/FtIsDirectlyRegulatedCard.vue';
import DidTrialCard from '@/shared/views/wizard/program2_supplemental/DidTrialCard.vue';
import DidTrialDescCard from '@/shared/views/wizard/program2_supplemental/DidTrialDescCard.vue';
import WillTrialCard from '@/shared/views/wizard/program2_supplemental/WillTrialCard.vue';
import WillTrialDescCard from '@/shared/views/wizard/program2_supplemental/WillTrialDescCard.vue';
import PharmaWhenInMarketCard from '@/shared/views/wizard/program2_supplemental/PharmaWhenInMarketCard.vue';
import PharmaIsInRadioCard from '@/shared/views/wizard/program2_supplemental/PharmaIsInRadioCard.vue';
import MeddevSpecialDeviceCard from '@/shared/views/wizard/program2_supplemental/MeddevSpecialDeviceCard.vue';
import MeddevSpecialDeviceDescCard from '@/shared/views/wizard/program2_supplemental/MeddevSpecialDeviceDescCard.vue';
import MeddevInMarketCard from '@/shared/views/wizard/program2_supplemental/MeddevInMarketCard.vue';
import MeddevInMarketDescCard from '@/shared/views/wizard/program2_supplemental/MeddevInMarketDescCard.vue';
import HwHasDangerousProductsCard from '@/shared/views/wizard/program2_supplemental/HwHasDangerousProductsCard.vue';
import EpriseAdviceReportingCard from '@/shared/views/wizard/program2_supplemental/EpriseAdviceReportingCard.vue';
import EpriseAdviceReportingDescCard from '@/shared/views/wizard/program2_supplemental/EpriseAdviceReportingDescCard.vue';
import EpriseSpecialCategoryCard from '@/shared/views/wizard/program2_supplemental/EpriseSpecialCategoryCard.vue';
import EpriseExpectedIntlRevenueCard from '@/shared/views/wizard/program2_supplemental/EpriseExpectedIntlRevenueCard.vue';
import EpriseLoanServicesCard from '@/shared/views/wizard/program2_supplemental/EpriseLoanServicesCard.vue';
import EpriseLoanServicesDescCard from '@/shared/views/wizard/program2_supplemental/EpriseLoanServicesDescCard.vue';
import LegalEntityCard from '@/shared/views/wizard/core/normal/LegalEntityCard.vue';
import InvoluntaryTerminationCard from '@/shared/views/wizard/core/normal/InvoluntaryTerminationCard.vue';
import PlannedInvoluntaryTerminationCard from '@/shared/views/wizard/core/normal/PlannedInvoluntaryTerminationCard.vue';
import HasBoardCard from '@/shared/views/wizard/core/normal/HasBoardCard.vue';
import ProductInMarketCard from '@/shared/views/wizard/core/normal/ProductInMarketCard.vue';
import DeniedCoverageCard from '@/shared/views/wizard/core/normal/DeniedCoverageCard.vue';
import HasPendingClaimsCard from '@/shared/views/wizard/core/normal/HasPendingClaimsCard.vue';
import PendingClaimsDescriptionCard from '@/shared/views/wizard/core/normal/PendingClaimsDescriptionCard.vue';
import LiabilitiesClaimsItemizedCard from '@/shared/views/wizard/core/outliers/LiabilitiesClaimsItemizedCard.vue';
import FtHasDemandsCard from '@/shared/views/wizard/program2_supplemental/FtHasDemandsCard.vue';
import FtHasDemandsDescCard from '@/shared/views/wizard/program2_supplemental/FtHasDemandsDescCard.vue';
import PsDemandsMadeCard from '@/shared/views/wizard/program2_supplemental/PsDemandsMadeCard.vue';
import PsDemandsMadeDescCard from '@/shared/views/wizard/program2_supplemental/PsDemandsMadeDescCard.vue';
import FtOperatesFintechDescCard from '@/shared/views/wizard/program2_supplemental/FtOperatesFintechDescCard.vue';
import FtGtmStrategyCard from '@/shared/views/wizard/program2_supplemental/FtGtmStrategyCard.vue';
import FtAnnualLoansCard from '@/shared/views/wizard/program2_supplemental/FtAnnualLoansCard.vue';
import EpriseDemandsMadeCard from '@/shared/views/wizard/program2_supplemental/EpriseDemandsMadeCard.vue';
import EpriseDemandsMadeDescCard from '@/shared/views/wizard/program2_supplemental/EpriseDemandsMadeDescCard.vue';
import AddressAttributesAndAdditionalCard from '@/shared/views/wizard/core/outliers/AddressAttributesAndAdditionalCard.vue';
import FtInvestmentServicesActivitiesCard from '@/shared/views/wizard/program2_supplemental/FtInvestmentServicesActivitiesCard.vue';

export default [
  { path: 'renewal-start', component: RenewalStartCard },
  { path: '2019-07-01--NUM_FULL_TIME_WORKERS', component: NumWorkersCard },
  { path: '2019-07-01--CAPITAL_RAISED_CONTAINER', component: CapitalRaisedCard },
  // Niche cards happen here - see renewalWizardConfigProgram2
  { path: '2019-07-01--LEGAL_ENTITY_NAME', component: LegalEntityCard },
  { path: '2019-07-01--HAS_INSURANCE_REQS', component: HasInsuranceReqsCard },
  { path: '2021-06-01--HAS_INSURANCE_REQS_DESC', component: HasInsuranceReqsDescCard },
  { path: '2019-07-01--LOCATION_CONTAINER', component: AddressCard },
  { path: '2019-07-01--LOCATION_CONTAINER_attributes', component: AddressAttributesCard },
  { path: '2019-07-01--LOCATIONS', component: AddressAttributesAndAdditionalCard },
  { path: '2021-06-01--HAS_BIG_TICKET_ITEM', component: HasBigTicketItemCard },
  { path: '2021-06-01--BIG_TICKET_ITEM_DESC', component: BigTicketItemDescCard },
  { path: '2021-06-01--LEAD_INVESTOR_LAST_ROUND', component: LeadInvestorLastRoundCard },
  { path: '2021-06-01--FOUNDER_PREVIOUS_RAISE', component: FounderPreviousRaiseCard },
  { path: '2021-06-01--PERCENT_OWNED_NONVC', component: PercentOwnedNonVcCard },
  { path: '2021-06-01--PREF_SHARES_SALES', component: PrefShareSalesCard },
  { path: '2021-06-01--SHARES_PUBLICLY_LISTED', component: SharesPubliclyListedCard },
  { path: '2019-07-01--ANNUAL_REVENUE_CONTAINER', component: AnnualRevenueCard },
  { path: '2019-07-01--NUM_BIG_REVENUE_CONTRACTS', component: NumBigRevenueContractsCard },
  { path: '2019-07-01--TOTAL_CASH_AND_EQUIVALENTS_CENTS', component: TotalCashCard },
  { path: '2021-06-01--ACQUIRE_10M_COMPANY', component: AcquireCompanyCard },
  { path: '2019-07-01--HAS_401K', component: Has401kCard },
  { path: '2021-16-02--SUBJECT_TO_ERISA', component: HasERISACard },
  { path: '2019-07-01--BENEFITS_WORTH', component: BenefitsWorthCard },
  { path: '2021-06-01--OFFERS_CONSULTING', component: OffersConsultingCard },
  { path: '2019-07-01--HAS_ANY_PII', component: HasPIICard },
  { path: '2019-07-01--PII_AMOUNT', component: PIIAmountCard },
  { path: '2021-06-01--SOC_COMPLIANCE', component: SocComplianceCard },
  { path: '2021-06-01--LOGIN_REQUIRES_MFA', component: LoginRequiresMfaCard },
  { path: '2019-07-01--HAS_ENGINEERING_CONTRACTORS', component: HasEngContractorsCard },
  { path: '2019-07-01--NUM_ENGINEERING_CONTRACTORS', component: NumEngContractorsCard },
  { path: '2019-07-01--INSURANCE_HISTORY_CONTAINER', component: InsuranceHistoryCard },
  { path: '2019-07-01--CURRENT_CLAIMS_DESCRIPTION', component: CurrentClaimsCard },
  { path: '2021_06_01--CURRENT_CLAIMS_NUM', component: CurrentClaimsNumCard },
  // Supplementals
  { path: '2021-06-01--HAS_DANGEROUS_PRODUCTS', component: HasDangerousProductsP2Card },
  { path: '2021-06-01--HAS_DANGEROUS_PRODUCTS_DESC', component: HasDangerousProductsDescP2Card },
  { path: '2021-06-01--MANUFACTURING_STATUS', component: ManufacturingStatusCard },
  { path: '2021-06-01--MANUFACTURING_STATUS_DESC', component: ManufacturingStatusDescCard },
  { path: '2021-06-01--CAUSED_HARM', component: CausedHarmCard },
  { path: '2021-06-01--CAUSED_HARM_DESC', component: CausedHarmDescCard },
  { path: '2021-06-01--HAS_PHYSICAL_RETAIL', component: HasPhysicalRetailCard },
  { path: '2021-06-01--MEDIA_HAS_STATE_REG_DEMANDS', component: MediaHasStateRegDemandsCard },
  {
    path: '2021-06-01--MEDIA_HAS_STATE_REG_DEMANDS_DESC',
    component: MediaHasStateRegDemandsDescCard,
  },
  { path: '2021-06-01--MEDIA_HAS_ACT_RELATED_DEMANDS', component: MediaHasActRelatedDemandsCard },
  {
    path: '2021-06-01--MEDIA_HAS_ACT_RELATED_DEMANDS_DESC',
    component: MediaHasActRelatedDemandsDescCard,
  },
  {
    path: '2021-06-01--MEDIA_HAS_COPYRIGHT_LIBEL_PRIVACY_DEMANDS',
    component: MediaHasClpDemandsCard,
  },
  {
    path: '2021-06-01--MEDIA_HAS_COPYRIGHT_LIBEL_PRIVACY_DEMANDS_DESC',
    component: MediaHasClpDemandsDescCard,
  },
  { path: '2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS', component: MediaHasCertificateBizCard },
  {
    path: '2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS_DESC',
    component: MediaHasCertificateBizDescCard,
  },
  { path: '2021-06-01--MEDIA_CONTROV_MATERIAL', component: MediaHasControvMaterialCard },
  { path: '2021-06-01--MEDIA_CONTROV_MATERIAL_DESC', component: MediaHasControvMaterialDescCard },
  { path: '2021-06-01--MEDIA_NUM_CONTENT_CONSUMERS', component: MediaNumContentConsumersCard },
  { path: '2021-06-01--MEDIA_MAKES_OR_SELLS_PG', component: MediaMakesOrSellsPgCard },
  { path: '2021-06-01--MEDIA_MAKES_OR_SELLS_PG_DESC', component: MediaMakesOrSellsPgDescCard },
  { path: '2021-06-01--MEDIA_LOANS_OR_TRANSFERS', component: MediaLoansOrTransfersCard },
  { path: '2021-06-01--MEDIA_LOANS_OR_TRANSFERS_DESC', component: MediaLoansOrTransfersDescCard },
  { path: '2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES', component: TranspHasContractDisputesCard },
  {
    path: '2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES_DESC',
    component: TranspHasContractDisputesDescCard,
  },
  { path: '2021-06-01--TRANSP_LICENSING_DISPUTES', component: TranspLicensingDisputesCard },
  {
    path: '2021-06-01--TRANSP_LICENSING_DISPUTES_DESC',
    component: TranspLicensingDisputesDescCard,
  },
  { path: '2021-06-01--TRANSP_OWNS_TRANSPORT', component: TranspOwnsTransportCard },
  { path: '2021-06-01--TRANSP_OWNS_TRANSPORT_DESC', component: TranspOwnsTransportDescCard },
  { path: '2021-06-01--PS_PROF_SERVICES', component: PsProfServicesCard },
  { path: '2021-06-01--PS_PROF_SERVICES_DESC', component: PsProfServicesDescCard },
  { path: '2021-06-01--PS_DEMANDS_MADE', component: PsDemandsMadeCard },
  { path: '2021-06-01--PS_DEMANDS_MADE_DESC', component: PsDemandsMadeDescCard },
  { path: '2021-06-01--FT_OPERATES_FINTECH', component: FtOperatesFintechCard },
  { path: '2021-06-01--FT_OPERATES_FINTECH_DESC', component: FtOperatesFintechDescCard },
  { path: '2021-06-01--FT_HAS_DEMANDS', component: FtHasDemandsCard },
  { path: '2021-06-01--FT_HAS_DEMANDS_DESC', component: FtHasDemandsDescCard },
  { path: '2021-06-01--FT_GTM_STRATEGY', component: FtGtmStrategyCard },
  { path: '2021-06-01--FT_ANNUAL_LOANS', component: FtAnnualLoansCard },
  { path: '2021-06-01--FT_INTERNATIONAL_SALES', component: FtInternationalSalesCard },
  { path: '2021-06-01--FT_HAS_BLOCKCHAIN_BUSINESS', component: FtHasBlockchainBizCard },
  { path: '2021-06-01--FT_LARGEST_LOAN_SIZE', component: FtLargestLoanSize },
  { path: '2021-06-01--FT_TOTAL_ANNUAL_LOANS', component: FtTotalAnnualLoansCard },
  { path: '2021-06-01--FT_HANDLES_INSURANCE_CLAIMS', component: FtHandlesInsuranceClaimsCard },
  { path: '2021-06-01--FT_IS_DIRECTLY_REGULATED', component: FtIsDirectlyRegulatedCard },
  { path: '2021-06-01--DID_TRIAL', component: DidTrialCard },
  { path: '2021-06-01--DID_TRIAL_DESC', component: DidTrialDescCard },
  { path: '2021-06-01--WILL_TRIAL', component: WillTrialCard },
  { path: '2021-06-01--WILL_TRIAL_DESC', component: WillTrialDescCard },
  { path: '2021-06-01--PHARMA_WHEN_IN_MARKET', component: PharmaWhenInMarketCard },
  { path: '2021-06-01--PHARMA_IS_IN_RADIO', component: PharmaIsInRadioCard },
  { path: '2021-06-01--MEDDEV_SPECIAL_DEVICE', component: MeddevSpecialDeviceCard },
  { path: '2021-06-01--MEDDEV_SPECIAL_DEVICE_DESC', component: MeddevSpecialDeviceDescCard },
  { path: '2021-06-01--MEDDEV_IN_MARKET', component: MeddevInMarketCard },
  { path: '2021-06-01--MEDDEV_IN_MARKET_DESC', component: MeddevInMarketDescCard },
  { path: '2021-06-01--HW_HAS_DANGEROUS_PRODUCTS', component: HwHasDangerousProductsCard },
  { path: '2021-06-01--EPRISE_ADVICE_REPORTING', component: EpriseAdviceReportingCard },
  { path: '2021-06-01--EPRISE_ADVICE_REPORTING_DESC', component: EpriseAdviceReportingDescCard },
  { path: '2021-06-01--EPRISE_DEMANDS_MADE', component: EpriseDemandsMadeCard },
  { path: '2021-06-01--EPRISE_DEMANDS_MADE_DESC', component: EpriseDemandsMadeDescCard },
  { path: '2021-06-01--EPRISE_SPECIAL_CATEGORY', component: EpriseSpecialCategoryCard },
  { path: '2021-06-01--EPRISE_EXPECTED_INTL_REVENUE', component: EpriseExpectedIntlRevenueCard },
  { path: '2021-06-01--EPRISE_LOAN_SERVICES', component: EpriseLoanServicesCard },
  { path: '2021-06-01--EPRISE_LOAN_SERVICES_DESC', component: EpriseLoanServicesDescCard },
  {
    path: '2022-12-15--FT_INVESTMENT_SERVICES_ACTIVITIES',
    component: FtInvestmentServicesActivitiesCard,
  },
  { path: '2019-07-01--NUM_INVOLUNTARY_TERMINATION', component: InvoluntaryTerminationCard },
  {
    path: '2019-07-01--NUM_PLANNED_INVOLUNTARY_TERMINATION',
    component: PlannedInvoluntaryTerminationCard,
  },
  { path: '2019-07-01--HAS_OTHER_BOARD_DIRECTORS', component: HasBoardCard },
  { path: '2019-07-01--HAS_PRODUCT_IN_MARKET', component: ProductInMarketCard },
  { path: '2019-07-01--DENIED_COVERAGE_DESCRIPTION', component: DeniedCoverageCard },
  { path: '2019-07-01--HAS_PENDING_CLAIMS', component: HasPendingClaimsCard },
  { path: '2019-07-01--PENDING_CLAIMS_DESCRIPTION', component: PendingClaimsDescriptionCard },
  {
    path: '2019-07-01--LIABILITIES_CLAIMS_CONTAINER',
    component: LiabilitiesClaimsItemizedCard,
  },

  // End of flow questions
  { path: 'renewal-setup-call', component: RenewalScheduleCall },
  {
    path: 'review',
    components: {
      default: ReviewInformationCard,
      belowCardContent: ReviewFieldsContainer,
    },
  },
  {
    path: 'applicant',
    components: {
      default: ApplicantInformationCard,
      belowCardContent: ReviewFieldsContainer,
    },
  },
  { path: 'renewal-end', component: RenewalEndCard },

  // Edit questions
  // ----
  // User should only see these if they choose to edit one of these values
  // during the review process

  // Add/move cards here
];
