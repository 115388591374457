import { ApplicationDecisions } from '@/onboarding/constants/Constants';
import { autoYes } from '../../../shared/routing/routingDecisionHelpers';
import featureFlagging from '@/onboarding/services/FeatureFlaggingService';

const satisfiedOnLockedAndYesDecision = ({ applicationData, decisionData }) =>
  applicationData.is_locked && decisionData.decision === ApplicationDecisions.yesDecision;
const isSVBCustomer = ({ applicationData }) =>
  featureFlagging.getFlag({ flag: 'svb-connect' }) && applicationData.incubator_program === 'svb';

const isCartaCustomer = ({ applicationData }) =>
  featureFlagging.getFlag({ flag: 'carta-connect' }) &&
  applicationData.incubator_program === 'carta';

const isEligibleSVBCustomer = applicationData => {
  return isSVBCustomer({ applicationData }) && applicationData.eligible_for_partner_discounts;
};

const isEligibleCartaCustomer = applicationData => {
  return isCartaCustomer({ applicationData }) && applicationData.eligible_for_partner_discounts;
};

const svbConnectPage = {
  id: 'svb-connect',
  isSatisfied: satisfiedOnLockedAndYesDecision,
  shouldRender: ({ applicationData }) => isEligibleSVBCustomer(applicationData),
};

const cartaDataSharePage = {
  id: 'carta-connect',
  isSatisfied: satisfiedOnLockedAndYesDecision,
  shouldRender: ({ applicationData }) => isEligibleCartaCustomer(applicationData),
};

const reviewCard = {
  id: 'review',
  isSatisfied: satisfiedOnLockedAndYesDecision,
  shouldRender: autoYes,
};

export const endOfFunnelRoutingDecisions = [cartaDataSharePage, svbConnectPage, reviewCard];
