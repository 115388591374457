import RenewalStartCard from '@/members/views/questions/RenewalStartCard.vue';
import RenewalEndCard from '@/members/views/questions/RenewalEndCard.vue';
import HasInsuranceCard from '@/shared/views/wizard/prequalification/HasInsuranceCard.vue';
import HasInsuranceReqCard from '@/shared/views/wizard/prequalification/HasInsuranceReqCard.vue';
import NumWorkersCard from '@/shared/views/wizard/prequalification/NumWorkersCard.vue';
import CapitalRaisedCard from '@/shared/views/wizard/core/normal/CapitalRaisedCard.vue';
import VerticalNicheCard from '@/shared/views/wizard/core/outliers/VerticalNicheCard.vue';
import TotalCashCard from '@/shared/views/wizard/core/normal/TotalCashCard.vue';
import HasPIICard from '@/shared/views/wizard/core/normal/HasPIICard.vue';
import PIIAmountCard from '@/shared/views/wizard/core/normal/PIIAmountCard.vue';
import HasPendingClaimsCard from '@/shared/views/wizard/core/normal/HasPendingClaimsCard.vue';
import InvoluntaryTerminationCard from '@/shared/views/wizard/core/normal/InvoluntaryTerminationCard.vue';
import PlannedInvoluntaryTerminationCard from '@/shared/views/wizard/core/normal/PlannedInvoluntaryTerminationCard.vue';
import PendingClaimsDescriptionCard from '@/shared/views/wizard/core/normal/PendingClaimsDescriptionCard.vue';
import RenewalScheduleCall from '@/members/views/questions/RenewalScheduleCall.vue';
import AddressCard from '@/shared/views/wizard/core/outliers/AddressCard.vue';
import AddressAttributesCard from '@/shared/views/wizard/core/outliers/AddressAttributesCard.vue';
import AnnualRevenueCard from '@/shared/views/wizard/core/normal/AnnualRevenueCard.vue';
// import FinPage from '@/members/views/FinPage.vue';

import ApplicantInformationCard from '@/members/views/wizard/ApplicantInformationCard.vue';
import ReviewInformationCard from '@/members/views/wizard/ReviewInformationCard.vue';
import ReviewFieldsContainer from '@/members/views/wizard/ReviewFieldsContainer.vue';

import LegalEntityCard from '@/shared/views/wizard/core/normal/LegalEntityCard.vue';
import YearIncorporatedCard from '@/shared/views/wizard/core/normal/YearIncorporatedCard.vue';
import ProductInMarketCard from '@/shared/views/wizard/core/normal/ProductInMarketCard.vue';
import HasBoardCard from '@/shared/views/wizard/core/normal/HasBoardCard.vue';
import HasEngContractorsCard from '@/shared/views/wizard/core/normal/HasEngContractorsCard.vue';
import NumEngContractorsCard from '@/shared/views/wizard/core/normal/NumEngContractorsCard.vue';
import Has401kCard from '@/shared/views/wizard/core/normal/Has401kCard.vue';
import HasERISACard from '@/shared/views/wizard/core/normal/HasERISACard.vue';
import BenefitsWorthCard from '@/shared/views/wizard/core/normal/BenefitsWorthCard.vue';
import InsuranceHistoryCard from '@/shared/views/wizard/core/normal/InsuranceHistoryCard.vue';
import LiabilitiesClaimsItemizedCard from '@/shared/views/wizard/core/outliers/LiabilitiesClaimsItemizedCard.vue';
import CurrentClaimsCard from '@/shared/views/wizard/core/normal/CurrentClaimsCard.vue';
import DeniedCoverageCard from '@/shared/views/wizard/core/normal/DeniedCoverageCard.vue';
import HasDangerousProductsCard from '@/shared/views/wizard/niches/HasDangerousProductsCard.vue';
import HasProfessionalLicenseBusinessCard from '@/shared/views/wizard/niches/HasProfessionalLicenseBusinessCard.vue';
import HasCertificateBusinessCard from '@/shared/views/wizard/niches/HasCertificateBusinessCard.vue';
import HasMobileGamesCard from '@/shared/views/wizard/niches/HasMobileGamesCard.vue';
import HasPhysicalActivityBusinessCard from '@/shared/views/wizard/niches/HasPhysicalActivityBusinessCard.vue';
import HasContractorsDisputeCard from '@/shared/views/wizard/niches/HasContractorsDisputeCard.vue';
import HasLoansBusinessCard from '@/shared/views/wizard/niches/HasLoansBusinessCard.vue';
import HasSensitivePIICard from '@/shared/views/wizard/core/normal/HasSensitivePIICard.vue';
import BusinessDescriptionCard from '@/shared/views/wizard/niches/BusinessDescriptionCard.vue';
import HasMedicalDataCard from '@/shared/views/wizard/niches/HasMedicalDataCard.vue';
import HasRegulatorLicenseCard from '@/shared/views/wizard/niches/HasRegulatorLicenseCard.vue';
import HasRealEstateLicenseCard from '@/shared/views/wizard/niches/HasRealEstateLicenseCard.vue';
import HasAdvertisingClaimCard from '@/shared/views/wizard/niches/HasAdvertisingClaimCard.vue';
import InternationalSalesCard from '@/shared/views/wizard/niches/InternationalSalesCard.vue';
import HasPhysicalProductsCard from '@/shared/views/wizard/niches/HasPhysicalProductsCard.vue';
import NumBigRevenueContractsCard from '@/shared/views/wizard/niches/NumBigRevenueContractsCard.vue';
import HasServicesBusinessCard from '@/shared/views/wizard/niches/HasServicesBusinessCard.vue';
import HasBenefitsBusinessCard from '@/shared/views/wizard/niches/HasBenefitsBusinessCard.vue';
import HasChemicalsCard from '@/shared/views/wizard/niches/HasChemicalsCard.vue';
import HasPollutantsCard from '@/shared/views/wizard/niches/HasPollutantsCard.vue';
import HasConsumableBusinessCard from '@/shared/views/wizard/niches/HasConsumableBusinessCard.vue';
import HasMoneyTransferBusinessCard from '@/shared/views/wizard/niches/HasMoneyTransferBusinessCard.vue';
import HasAccountingBusinessCard from '@/shared/views/wizard/niches/HasAccountingBusinessCard.vue';
import HasFinanceClientsBusinessCard from '@/shared/views/wizard/niches/HasFinanceClientsBusinessCard.vue';
import HasFinanceBusinessCard from '@/shared/views/wizard/niches/HasFinanceBusinessCard.vue';
import HasRealEstateBusinessCard from '@/shared/views/wizard/niches/HasRealEstateBusinessCard.vue';
import HasMobilityBusinessCard from '@/shared/views/wizard/niches/HasMobilityBusinessCard.vue';
import HasHighRiskClientsCard from '@/shared/views/wizard/niches/HasHighRiskClientsCard.vue';
import HasBlockchainBusinessCard from '@/shared/views/wizard/niches/HasBlockchainBusinessCard.vue';
import HasRoboticsBusinessCard from '@/shared/views/wizard/niches/HasRoboticsBusinessCard.vue';
import HasCopyrightClaimCard from '@/shared/views/wizard/niches/HasCopyrightClaimCard.vue';
import AddressAttributesAndAdditionalCard from '@/shared/views/wizard/core/outliers/AddressAttributesAndAdditionalCard.vue';

export default [
  // Regular path questions
  { path: 'renewal-start', component: RenewalStartCard },
  { path: '2019-07-01--NUM_FULL_TIME_WORKERS', component: NumWorkersCard },
  { path: '2019-07-01--NUM_INVOLUNTARY_TERMINATION', component: InvoluntaryTerminationCard },
  {
    path: '2019-07-01--NUM_PLANNED_INVOLUNTARY_TERMINATION',
    component: PlannedInvoluntaryTerminationCard,
  },
  { path: '2019-07-01--CAPITAL_RAISED_CONTAINER', component: CapitalRaisedCard },
  { path: '2019-07-01--LOCATION_CONTAINER', component: AddressCard },
  { path: '2019-07-01--LOCATION_CONTAINER_attributes', component: AddressAttributesCard },
  { path: '2019-07-01--LOCATIONS', component: AddressAttributesAndAdditionalCard },
  { path: '2019-07-01--ANNUAL_REVENUE_CONTAINER', component: AnnualRevenueCard },
  { path: '2019-07-01--TOTAL_CASH_AND_EQUIVALENTS_CENTS', component: TotalCashCard },
  { path: '2019-07-01--HAS_ANY_PII', component: HasPIICard },
  { path: '2019-07-01--PII_AMOUNT', component: PIIAmountCard },
  { path: '2019-07-01--HAS_PENDING_CLAIMS', component: HasPendingClaimsCard },
  { path: '2019-07-01--PENDING_CLAIMS_DESCRIPTION', component: PendingClaimsDescriptionCard },

  // End of flow questions
  { path: 'renewal-setup-call', component: RenewalScheduleCall },
  {
    path: 'review',
    components: {
      default: ReviewInformationCard,
      belowCardContent: ReviewFieldsContainer,
    },
  },
  {
    path: 'applicant',
    components: {
      default: ApplicantInformationCard,
      belowCardContent: ReviewFieldsContainer,
    },
  },
  { path: 'renewal-end', component: RenewalEndCard },

  // Edit questions
  // ----
  // User should only see these if they choose to edit one of these values
  // during the review process
  { path: '2019-07-01--HAS_INSURANCE_SELF', component: HasInsuranceCard },
  { path: '2019-07-01--HAS_INSURANCE_REQS', component: HasInsuranceReqCard },
  { path: '2019-07-01--NUM_FULL_TIME_WORKERS', component: NumWorkersCard },
  { path: '2019-07-01--CAPITAL_RAISED_CONTAINER', component: CapitalRaisedCard },
  { path: '2019-07-01--VERTICAL_NICHE', component: VerticalNicheCard },

  { path: '2019-07-01--LEGAL_ENTITY_NAME', component: LegalEntityCard },
  { path: '2019-07-01--YEAR_INCORPORATED', component: YearIncorporatedCard },
  { path: '2019-07-01--HAS_PRODUCT_IN_MARKET', component: ProductInMarketCard },
  { path: '2019-07-01--HAS_OTHER_BOARD_DIRECTORS', component: HasBoardCard },
  { path: '2019-07-01--LOCATION_CONTAINER_attributes', component: AddressAttributesCard },
  { path: '2019-07-01--HAS_ENGINEERING_CONTRACTORS', component: HasEngContractorsCard },
  { path: '2019-07-01--NUM_ENGINEERING_CONTRACTORS', component: NumEngContractorsCard },
  { path: '2019-07-01--HAS_401K', component: Has401kCard },
  { path: '2021-16-02--SUBJECT_TO_ERISA', component: HasERISACard },
  { path: '2019-07-01--BENEFITS_WORTH', component: BenefitsWorthCard },
  { path: '2019-07-01--INSURANCE_HISTORY_CONTAINER', component: InsuranceHistoryCard },
  { path: '2019-07-01--CURRENT_CLAIMS_DESCRIPTION', component: CurrentClaimsCard },
  { path: '2019-07-01--DENIED_COVERAGE_DESCRIPTION', component: DeniedCoverageCard },
  {
    path: '2019-07-01--LIABILITIES_CLAIMS_CONTAINER',
    component: LiabilitiesClaimsItemizedCard,
  },
  { path: '2019-07-01--HAS_DANGEROUS_PRODUCTS', component: HasDangerousProductsCard },
  {
    path: '2019-07-01--HAS_PROFESSIONAL_LICENSE_BUSINESS',
    component: HasProfessionalLicenseBusinessCard,
  },
  { path: '2019-07-01--HAS_CERTIFICATE_BUSINESS', component: HasCertificateBusinessCard },
  { path: '2019-07-01--HAS_MOBILE_GAMES', component: HasMobileGamesCard },
  {
    path: '2019-07-01--HAS_PHYSICAL_ACTIVITY_BUSINESS',
    component: HasPhysicalActivityBusinessCard,
  },
  { path: '2019-07-01--HAS_CONTRACTORS_DISPUTES', component: HasContractorsDisputeCard },
  { path: '2019-07-01--HAS_LOANS_BUSINESS', component: HasLoansBusinessCard },
  { path: '2019-07-01--HAS_ANY_SENSITIVE_PII', component: HasSensitivePIICard },
  { path: '2019-07-01--BUSINESS_DESCRIPTION', component: BusinessDescriptionCard },
  { path: '2019-07-01--HAS_MEDICAL_DATA', component: HasMedicalDataCard },
  { path: '2019-07-01--HAS_REGULATOR_LICENSE', component: HasRegulatorLicenseCard },
  { path: '2019-07-01--HAS_REAL_ESTATE_LICENSE', component: HasRealEstateLicenseCard },
  { path: '2019-07-01--HAS_ADVERTISING_CLAIM', component: HasAdvertisingClaimCard },
  { path: '2019-07-01--INTERNATIONAL_SALES', component: InternationalSalesCard },
  { path: '2019-07-01--HAS_PHYSICAL_PRODUCTS', component: HasPhysicalProductsCard },
  { path: '2019-07-01--NUM_BIG_REVENUE_CONTRACTS', component: NumBigRevenueContractsCard },
  { path: '2019-07-01--HAS_SERVICES_BUSINESS', component: HasServicesBusinessCard },
  { path: '2019-07-01--HAS_BENEFITS_BUSINESS', component: HasBenefitsBusinessCard },
  { path: '2019-07-01--HAS_CHEMICALS', component: HasChemicalsCard },
  { path: '2019-07-01--HAS_POLLUTANTS', component: HasPollutantsCard },
  { path: '2019-07-01--HAS_CONSUMABLE_BUSINESS', component: HasConsumableBusinessCard },
  { path: '2019-07-01--HAS_MONEY_TRANSFER_BUSINESS', component: HasMoneyTransferBusinessCard },
  { path: '2019-07-01--HAS_ACCOUNTING_BUSINESS', component: HasAccountingBusinessCard },
  {
    path: '2019-07-01--HAS_FINANCE_CLIENTS_BUSINESS',
    component: HasFinanceClientsBusinessCard,
  },
  { path: '2019-07-01--HAS_FINANCE_BUSINESS', component: HasFinanceBusinessCard },
  { path: '2019-07-01--HAS_REAL_ESTATE_BUSINESS', component: HasRealEstateBusinessCard },
  { path: '2019-07-01--HAS_MOBILITY_BUSINESS', component: HasMobilityBusinessCard },
  { path: '2019-07-01--HAS_HIGH_RISK_CLIENTS', component: HasHighRiskClientsCard },
  { path: '2019-07-01--HAS_BLOCKCHAIN_BUSINESS', component: HasBlockchainBusinessCard },
  { path: '2019-07-01--HAS_ROBOTICS_BUSINESS', component: HasRoboticsBusinessCard },
  { path: '2019-07-01--HAS_COPYRIGHT_CLAIM', component: HasCopyrightClaimCard },
];
