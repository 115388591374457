<template>
  <GenericStatusPage
    :image="image"
    :title="title"
  >
    <template v-slot:primary-reason-content>
      {{ primaryReason }}
    </template>
    <template v-slot:description-content>
      {{ description }}
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="descriptionRaw" />
    </template>
    <template
      v-if="showButton"
      v-slot:status-button
    >
      <ButtonPrimary
        data-testid="fin-page-button"
        @click="onClick"
      >
        {{ buttonText }}
      </ButtonPrimary>
    </template>
  </GenericStatusPage>
</template>

<script>
import GenericStatusPage from '@/shared/views/layouts/GenericStatusPage';
import ButtonPrimary from '@/shared/components/ui/atoms/buttons/ButtonPrimary';
import {
  isApplicationLocked,
  getPreCoreDecisionData,
  getApplicationData,
  isStateInitialized,
  applicationIsProgram2,
} from '../../lib/selectors/storeSelectors';
import selectors from '../../lib/selectors/decisionSelectors';
import { showChatIfEnabled } from '@/onboarding/services/chatHelper';
import { isInPrequal } from '../../store/modules/helpers';

const specialCases = [
  {
    application_property: 'incubator_program',
    application_value: 'wework',
    decision: selectors.isMaybeDecision,
    data: {
      title: 'Hang Tight...',
      primaryReason: 'Your application requires a quick review.',
      descriptionRaw: `Our underwriters need to make sure that our policies adequately cover the size, stage, and riskiest activities inherent to your industry or business.<br /><br />
        You will receive an <b>email</b> once a decision has been made (<b>typically within three business days</b>).`,
      buttonText: 'CHAT NOW',
    },
  },
  {
    application_property: 'incubator_program',
    application_value: 'wework',
    decision: selectors.isNoDecision,
    data: {
      title: '',
      primaryReason: ``,
      description: `Our analysis of your business has concluded that the right insurance carrier for you to work with is Hiscox. We've partnered with their Small Business Unit to help you quickly find insurance coverage that fits your business.`,
      referral_link:
        'https://www.hiscox.com/small-business-insurance/vouch?utm_source=vouch&utm_medium=referral&utm_campaign=vouch_august_newsletter&leadorigin=vouch',
      buttonText: 'GET COVERAGE THROUGH HISCOX',
    },
  },
  {
    application_property: 'incubator_program',
    application_value: 'stripe',
    decision: selectors.isMaybeDecision,
    data: {
      title: '',
      primaryReason: 'Your application requires a quick review.',
      descriptionRaw: `Our underwriters need to make sure that our policies adequately cover the size, stage, and riskiest activities inherent to your industry or business.<br /><br />
        You will receive an <b>email</b> once a decision has been made (<b>typically within three business days</b>).`,
      buttonText: 'CHAT NOW',
    },
  },
  {
    application_property: 'incubator_program',
    application_value: 'stripe',
    decision: selectors.isNoDecision,
    data: {
      title: '',
      primaryReason: `Your application requires further review`,
      description: `A Vouch team member will need to review your application. We'll reach out within 48 hours with an update about the next steps in the process. If you have any questions, please reach out to an Insurance Advisor.`,
      buttonText: 'CHAT NOW',
    },
  },
];

const isSpecialCase = (context, decisionData) => {
  const applicationData = getApplicationData(context);

  return specialCases.find(specialCase => {
    return (
      applicationData[specialCase.application_property] === specialCase.application_value &&
      specialCase.decision(decisionData)
    );
  });
};

const noPrequalReasons = {
  unsupported_niche: 'We’ll need talk with you to cover your particular business activities.',
  too_many_people:
    'Vouch focuses on insuring early startups. You’ve already scaled to the next level.',
  too_much_capital:
    'Vouch focuses on insuring early startups. You’ve already scaled to the next level.',
  because_high_historic_claim:
    'Thanks for your input. After reviewing your application, we unfortunately cannot provide coverage for you at this time given your prior claim or litigation activity.',
};

const genericError = 'Unfortunately, Vouch is not a good fit for your company right now.';
const genericDescription = `Vouch's proprietary coverages are not currently designed to cover companies like yours. If you have any questions, please reach out to an Insurance Advisor.`;
const unsupportedLocationDescription =
  'Our automated system does not support your location yet, but our Insurance Advisors can still help.';

const program2GenericError = 'Your application requires further review';
const program2genericDescription =
  'A Vouch team member will need to review your application due to some of your responses. We’ll reach out within 48 hours with an update about the next steps in the process. If you have any questions, please reach out to an Insurance Advisor.';

export default {
  name: 'FinPage',
  dependencies: ['requests', 'tracking'],
  components: { ButtonPrimary, GenericStatusPage },
  props: {
    applicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      decisionData: {
        decision: null,
        reason_token: null,
      },
      title: '',
      primaryReason: '',
      description: program2genericDescription,
      descriptionRaw: '',
      showButton: false,
      buttonText: '',
      image: '',
    };
  },
  async created() {
    const onSuccess = data => {
      this.decisionData = data;

      const specialCase = isSpecialCase(this, this.decisionData);

      if (selectors.isMaybeDecision(this.decisionData)) {
        this.title = 'APPLICATION REVIEW';
        this.primaryReason =
          'One or more of your answers tripped up our underwriting algorithm, and a human needs to review.';
        this.description =
          'Sorry for the slight delay.  A Vouch advisor will get back to you via email in three business days or less.  You can close this tab.';
        // TODO replace with new image (brand refresh)
        // this.image = require(''); // eslint-disable-line
      }

      if (selectors.isNoDecision(this.decisionData)) {
        let title = 'Hang tight...';
        let primaryReason = `${noPrequalReasons[this.decisionData.reason_token] || genericError}`;
        let description = genericDescription;
        let buttonText = 'CHAT NOW';

        const isUnsupportedLocation = this.decisionData.reason_token === 'unsupported_location';

        if (isUnsupportedLocation) {
          title = 'Let’s talk...';
          primaryReason = '';
          description = unsupportedLocationDescription;
          buttonText = 'CHAT WITH US';
        } else if (applicationIsProgram2(this)) {
          primaryReason = program2GenericError;
          description = program2genericDescription;
        }

        this.title = title;
        this.primaryReason = primaryReason;
        this.description = description;
        this.showButton = true;
        this.buttonText = buttonText;
      }

      if (this.decisionData.reason_token === 'locations_are_incompatible') {
        this.primaryReason = `To obtain coverage for the office location(s) you have provided, you need to speak to an Insurance Advisor.`;
        this.description = '';
        this.descriptionRaw = `You can also email us at
            <a href="mailto:success@vouch.us">success@vouch.us</a>`;
        this.showButton = true;
        this.buttonText = 'Talk to an advisor';
      }

      if (specialCase) {
        this.title = specialCase.data.title;
        this.primaryReason = specialCase.data.primaryReason;
        this.description = specialCase.data.description;
        this.descriptionRaw = specialCase.data.descriptionRaw;
        this.showButton = true;
        this.buttonText = specialCase.data.buttonText;
      }
    };

    const preCoreDecisionData = getPreCoreDecisionData(this);

    if (preCoreDecisionData && preCoreDecisionData.decision) {
      onSuccess(preCoreDecisionData);
      return;
    }

    if (isApplicationLocked(this.$store) || !isStateInitialized(this)) {
      this.requests.getApplicationDecision({ applicationId: this.applicationId, onSuccess });
    } else if (isInPrequal(getApplicationData(this))) {
      // if we get here without being routed from a rejection, fetch the rejection reason
      this.requests.getPrequalDecision({ applicationId: this.applicationId, onSuccess });
    } else {
      this.requests.getMadlibDecision({ applicationId: this.applicationId, onSuccess });
    }
  },
  methods: {
    onClick() {
      const specialCase = isSpecialCase(this, this.decisionData);

      if (specialCase && specialCase.data.referral_link) {
        window.location.replace(specialCase.data.referral_link);

        return;
      }

      showChatIfEnabled({ tracking: this.tracking });
    },
  },
};
</script>
