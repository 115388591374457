<template>
  <div class="pricing-breakdown">
    <div class="pricing-types">
      <div class="price-breakdown-type">
        Subtotal:
      </div>

      <div class="price-breakdown-type">
        Taxes & Other fees:
        <span class="info-icon">
          <CheckoutFormTaxesTooltip
            :show-taxes-and-fees="showTaxesAndFees"
            :taxes="restTaxesAmount || initialTaxesAmount"
            :fees="restFeesAmount || initialFeesAmount"
          />
        </span>
      </div>

      <div class="price-breakdown-type">
        Adjustments:
        <span class="info-icon">
          <Tooltip>
            <template slot="trigger">
              <VouchIcon name="help" />
            </template>
            <div
              slot="text"
              class="adjustments-tooltip-text"
            >
              <span>Includes rounding to state required "minimum premiums" for Vouch policies and
                underwriter adjustments.</span>
            </div>
          </Tooltip>
        </span>
      </div>

      <div
        v-if="showTransactionFees"
        class="price-breakdown-type"
      >
        Transaction fees:
      </div>

      <div
        v-if="showSavingsAmount"
        class="price-breakdown-type vouch-savings"
      >
        Vouch savings:
      </div>
    </div>

    <div class="pricing-values">
      <div class="price-breakdown-price subtotal">
        {{ subtotalAmount | longCurrency }}
      </div>

      <div class="price-breakdown-price taxes-fees">
        {{ totalInitialTaxesAndFees | longCurrency }}
      </div>

      <div class="price-breakdown-price adjustments">
        {{ isMonthly ? adjustmentsAmount / 12 : adjustmentsAmount | longCurrency }}
      </div>

      <div
        v-if="showTransactionFees"
        class="price-breakdown-price"
      >
        {{ transactionFeesAmount | longCurrency }}
      </div>

      <div
        v-if="showSavingsAmount"
        class="price-breakdown-price vouch-savings vouch-savings-amount"
      >
        {{ isMonthly ? savingsAmount / 12 : savingsAmount | longCurrency }}
      </div>
    </div>
  </div>
</template>

<script>
import { longCurrency } from '@/shared/lib/filters/currency_filters';
import Tooltip from '@/onboarding/components/Tooltip';
import VouchIcon from '@/shared/components/ui/atoms/icons/VouchIcon';
import CheckoutFormTaxesTooltip from './CheckoutFormTaxesTooltip';

const total = arr => {
  const reducer = (acc, el) => acc + el.amount_cents;
  return arr.reduce(reducer, 0);
};

export default {
  name: 'CheckoutFormPricingBreakdown',
  components: {
    Tooltip,
    VouchIcon,
    CheckoutFormTaxesTooltip,
  },
  filters: { longCurrency },
  props: {
    subtotalAmount: {
      type: Number,
      required: true,
    },
    isMonthly: {
      type: Boolean,
      required: true,
    },
    adjustmentsAmount: {
      type: Number,
      required: true,
    },
    savingsAmount: {
      type: Number,
      required: true,
    },
    transactionFeesAmount: {
      type: Number,
      required: false,
      default: 0,
    },
    initialMonthlyTaxes: {
      type: Array,
      required: false,
      default: () => [],
    },
    initialMonthlyFees: {
      type: Array,
      required: false,
      default: () => [],
    },
    totalTaxes: {
      type: Array,
      required: false,
      default: () => [],
    },
    totalFees: {
      type: Array,
      required: false,
      default: () => [],
    },
    restMonthlyFees: {
      type: Array,
      required: false,
      default: () => [],
    },
    restMonthlyTaxes: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    showTaxesAndFees() {
      return this.totalInitialTaxesAndFees !== 0;
    },
    showAdjustments() {
      return this.adjustmentsAmount !== 0;
    },
    showSavingsAmount() {
      return this.savingsAmount !== 0;
    },
    showTransactionFees() {
      return this.transactionFeesAmount !== 0;
    },
    totalInitialTaxesAndFees() {
      return this.initialFeesAmount + this.initialTaxesAmount;
    },
    initialFeesAmount() {
      const initialFees = this.isMonthly ? this.initialMonthlyFees : this.totalFees;
      const totala = total(initialFees);
      return this.isMonthly ? totala / 2 : totala;
    },
    initialTaxesAmount() {
      const initialTaxes = this.isMonthly ? this.initialMonthlyTaxes : this.totalTaxes;
      const totala = total(initialTaxes);
      return this.isMonthly ? totala / 2 : totala;
    },
    restFeesAmount() {
      const restFees = this.isMonthly ? this.restMonthlyFees : [];
      return total(restFees);
    },
    restTaxesAmount() {
      const restTaxes = this.isMonthly ? this.restMonthlyTaxes : [];
      return total(restTaxes);
    },
  },
};
</script>

<style lang="scss" scoped>
.pricing-breakdown {
  display: flex;
  flex-direction: row;
}

.pricing-types {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.pricing-values {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.price-breakdown-type {
  @include detail-text;
  padding-right: 10px;
  height: 30px;
  display: flex;
}

.taxes-fees {
  display: flex;
}

.info-icon {
  display: flex;
  align-items: top;
  margin-left: 4px;
  margin-top: -1px;
}

.price-breakdown-price {
  @include detail-text;
  height: 30px;
}

.adjustments-tooltip-text {
  @include detail-text;
}

.vouch-savings {
  font-weight: bold;
}

.taxes-and-fees-item {
  padding-bottom: $space-medium;
}

.required-text {
  @include detail-text;
  margin-bottom: $space-small;
}

.state-required-text {
  @include detail-text;
}

.taxes-and-fees {
  width: 80%;
}
</style>
