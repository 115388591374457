<template>
  <div class="promo-block">
    <v-img
      :src="image"
      width="100px"
      :contain="true"
      class="promo-image"
    />
    <p>
      {{ promoText }}
    </p>
  </div>
</template>

<script>
import cardImg from '@/onboarding/assets/images/svb-card.png';

export default {
  name: 'CheckoutFormSectionSvbePromo',
  data() {
    return {
      promoText:
        'Pay with your Silicon Valley Bank debit or credit card and get a credit against your purchase in 45 days.',
      image: cardImg,
    };
  },
};
</script>

<style lang="scss" scoped>
.promo-block {
  font-weight: bold;
  color: $special-offer-text-color;
  margin-bottom: $space-medium;
}
.promo-image {
  float: left;
  margin-top: $space-small;
  margin-right: $space-medium;
}
</style>
