<template>
  <div
    class="promotion-banner"
    :class="{small, 'white-text': whiteText, 'dark-text': !whiteText }"
  >
    <div>
      <span class="promotion-banner-title">
        {{ title }}
      </span>
      <span class="promotion-banner-subtitle">
        {{ subtitle }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageBannerPromotion',
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    whiteText: {
      default: true,
      required: false,
      type: Boolean,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes color-shift {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes slight-movement {
  0% {
    transform: translateY(-5%);
  }

  50% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-5%);
  }
}

.promotion-banner {
  @include body-text;

  &.white-text {
    color: $page-banner-promotion-text-color--white;
  }

  &.dark-text {
    color: $page-banner-promotion-text-color--dark;
  }

  position: relative;
  overflow: hidden;

  background-size: 150%;

  padding: 16px;
  width: 100%;

  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  // horrific default so you don't forget to override it
  background-image: linear-gradient(to left, #bb00ff, #002aff, #44ff00);

  animation: 7s infinite ease color-shift;

  min-height: gridUnit(6);

  &.small {
    min-height: gridUnit(4);
    padding: $space-small;
  }

  &::before {
    animation: 5s infinite ease slight-movement;
    // TODO replace with new image (brand refresh)
    // background-image: url('');
    content: '';
    position: absolute;
    width: 100%;
    height: 110%;
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat;

    opacity: 0.5;
  }

  & > * {
    position: relative;
  }
}

.promotion-banner-title {
  @include h5-text;
  font-weight: bold;
}
</style>
