import Vue, { VueConstructor } from 'vue';
import Auth0Lock from 'auth0-lock';
import { Auth0DecodedHash, Auth0Error } from 'auth0-js';
import axios from 'axios';
import Colors from '@/Colors';
import { merge } from 'lodash';
import TrackingService from '@/onboarding/services/TrackingService';
import featureFlagging from '@/onboarding/services/FeatureFlaggingService';
import {
  AUTHENTICATION_SUCCESSFUL,
  AUTH_ATTEMPT_UNVERIFIED,
  AUTH_USER_LOGGED_OUT,
  PASSWORD_RESET_REQUESTED,
  REGISTRATION_AWAIT_VERIFICATION,
  REGISTRATION_ERROR,
  REGISTRATION_SUCCESSFUL,
  REGISTRATION_SUBMITTED,
} from '@/onboarding/services/SegmentEventTypes';
import trackingSetup from '@/onboarding/services/TrackingServiceSetup';
import VueRouter from 'vue-router';

interface AuthConfig {
  clientID: string;
  customDomain: string;
  logoutUrl: string;
  onRedirectCallback: Function;
  redirectUrl: string;
  tracking: TrackingService;
  router: VueRouter;
}

type AppState = { targetUrl?: string } | string;

enum authErrorCodes {
  CONSENT_REQUIRED = 'consent_required',
  UNAUTHORIZED = 'unauthorized',
  ACCESS_DENIED = 'access_denied',
  LOGIN_REQUIRED = 'login_required',
}
enum auth0LockEvents {
  AUTHENTICATED = 'authenticated',
  SIGNUP_SUBMIT = 'signup submit',
  UNRECOVERABLE = 'unrecoverable_error',
  AUTHORIZATION_ERROR = 'authorization_error',
  HASH_PARSED = 'hash_parsed',
  SHOW = 'show',
  FORGOT_PASSWORD = 'forgot_password submit',
}

// Magic string values for the message parameter returned by Auth0 upon a verification attempt
// See https://auth0.com/docs/auth0-email-services/email-template-descriptions#redirect-to-results-for-verification-email-template
export const AUTH0_VERIFICATION_STRINGS = {
  oneUseOnly: 'This URL can be used only once',
  accessExpired: 'Access expired.',
  alreadyVerified: 'This account is already verified.',
};

// To-do: This module offers the getAuthPluginInstance method for consumers that need to call the
// plug-in's methods or data before the Vue app has finished booting (the plugin is available on
// the global app instance once the app is booted). Ideally, globals like this would be exposed in
// a single, consistent interface.
let instance;
export const getAuthPluginInstance = () => instance;

export const checkAuthentication = async (): Promise<{
  isAuthenticated: boolean;
  isNewFunnelUser: boolean;
}> => {
  const authPluginInstance: any = await new Promise(resolve => {
    const timer = setInterval(() => {
      const result = getAuthPluginInstance();
      if (result) {
        clearInterval(timer);
        resolve(result);
      }
    }, 100);
  });

  return new Promise((resolve, reject) => {
    try {
      const unwatch = authPluginInstance.$watch(
        'authStatusKnown',
        (authStatusKnown: boolean) => {
          if (authStatusKnown) {
            if (unwatch) {
              unwatch();
            }
            resolve({
              isAuthenticated: authPluginInstance.isAuthenticated,
              isNewFunnelUser: authPluginInstance.isNewFunnelUser,
            });
          }
        },
        { immediate: true }
      );
    } catch (e) {
      reject(e);
    }
  });
};

type Data = {
  accessToken: string;
  auth0Lock: null | Auth0LockStatic;
  earlyAuthFlagIsOn: boolean;
  signupEmailPrefill: string;
  idToken: string;
  isAuthenticated: boolean;
  loading: boolean;
  authStatusKnown: boolean;
  tokenExpiry: number;
  user?: Auth0DecodedHash['idTokenPayload'];
};

export const useAuth0 = (authConfig: AuthConfig) => {
  if (instance) return instance;

  instance = new Vue({
    router: authConfig.router,
    data(): Data {
      return {
        accessToken: '',
        auth0Lock: null,
        earlyAuthFlagIsOn: false,
        signupEmailPrefill: '',
        idToken: '',
        isAuthenticated: false,
        loading: true,
        authStatusKnown: false,
        tokenExpiry: Date.now(),
        user: null,
      };
    },
    computed: {
      applicationId(): string {
        return (this.$route?.query.applicationId as string) ?? '';
      },
      isNewFunnelUser(): boolean {
        return this.user?.['https://vouch.us/funnel'] === 'react';
      },
    },
    watch: {
      applicationId() {
        this.reset();
      },
    },
    async created() {
      this.earlyAuthFlagIsOn = await featureFlagging.getFlagWhenReady({
        flag: 'authenticate-before-madlib',
      });
      this.initLock();
      this.setListenersOnLock();
    },
    methods: {
      reset() {
        this.initLock(true);
        this.setListenersOnLock();
      },
      initLock(force: boolean = false) {
        const { clientID, customDomain, redirectUrl } = authConfig;
        try {
          if (this.$rollbar) {
            this.$rollbar.info('Initializing Auth0 Lock', { clientID, customDomain });
          }

          if (this.auth0Lock && !force) {
            this.loading = false;
            return;
          }
          this.auth0Lock = new Auth0Lock(clientID, customDomain, {
            avatar: null,
            configurationBaseUrl: 'https://cdn.auth0.com',
            closable: false,
            allowShowPassword: true,
            prefill: {
              email: this.signupEmailPrefill,
            },
            languageDictionary: {
              title: 'Log In',
              signUpTitle: 'Create Account',
              signUpLabel: 'Create Account',
              signUpSubmitLabel: 'Create Account',
              signUpWithLabel: 'Create Account with %s',
              signUpTerms:
                'By signing up, you agree to our <a href="https://www.vouch.us/legal/terms-of-use">terms of use</a>' +
                ' and <a href="https://www.vouch.us/legal/privacy-notice">privacy notice.',
              'lock.fallback':
                'Sorry; something went wrong when attempting to create your account.',
            },
            theme: {
              logo: require('@/shared/assets/images/Vouch-Logo-Sm.png'), // eslint-disable-line
              primaryColor: Colors.primaryGreenForLightBG,
            },
            auth: {
              responseType: 'token id_token', // Preferred for single-page apps
              redirectUrl: this.addParamsToRedirectUrl(redirectUrl),
              params: { scope: 'openid profile email' },
            },
            additionalSignUpFields: [
              // In the unlikely case that they signup with email and it doesn't match, we can use
              // this metadata field to route them back to their application
              {
                type: 'hidden',
                name: 'application_id',
                value:
                  this.applicationId ||
                  // if we're mid guard, then the route won't be available. Use location instead
                  new URLSearchParams(window.location.search).get('applicationId') ||
                  '',
              },
            ],
          });
        } catch (e) {
          this.$rollbar?.error('Error initializing Auth0 Lock', e as Error);
        } finally {
          this.loading = false;
        }
      },
      addParamsToRedirectUrl(url) {
        const urlObj = new URL(url);
        if (this.earlyAuthFlagIsOn) {
          // Let Auth0 action know this flag is on via param on redirectUrl
          urlObj.searchParams.append('earlyAuthFlag', 'true');
        }

        // If the user selects a different email through google auth, we will need this to navigate back
        // to the application
        if (this.applicationId) {
          urlObj.searchParams.append('applicationId', this.applicationId);
        }

        return urlObj.toString();
      },
      setListenersOnLock() {
        this.auth0Lock?.on(auth0LockEvents.AUTHENTICATED, (authResult: Auth0DecodedHash) => {
          this.onAuthenticated(authResult);
        });
        this.auth0Lock?.on(auth0LockEvents.SIGNUP_SUBMIT, () =>
          // No data in this event
          this.trackRegistrationSubmittedEvent()
        );
        this.auth0Lock?.on(auth0LockEvents.UNRECOVERABLE, e => {
          this.$rollbar?.error('Error from Auth0: "unrecoverable_error"', e);
          this.trackRegistrationErrorEvent(e);
        });
        this.auth0Lock?.on(auth0LockEvents.AUTHORIZATION_ERROR, e => {
          this.onAuthorizationError(e);
        });
        // If the Lock initializes, but there was no hash, we are not redirecting and should check the session
        this.auth0Lock?.on(auth0LockEvents.HASH_PARSED, (hash: Auth0DecodedHash) => {
          if (hash === null) {
            this.checkSession({ state: '{}' }, this.handleSessionCheckResult);
          }
        });
        // The Lock SDK only allows specifying a prefill at instantiation. Since we don't know the
        // user's email then, we pass it as an option to 'showLogin', and set it the OG way
        this.auth0Lock?.on(auth0LockEvents.FORGOT_PASSWORD, () => {
          const email = document.querySelector(
            "input[name='email'].auth0-lock-input"
          ) as HTMLInputElement;
          this.trackForgotPasswordEvent({ email: email.value });
        });
      },
      checkSession(
        options: object = {},
        callback: (e, authResult) => void = (e, authresult) => {}
      ) {
        this.auth0Lock?.checkSession(options, callback);
      },
      handleSessionCheckResult(e, authResult: Auth0DecodedHash) {
        if (e) {
          if ([authErrorCodes.UNAUTHORIZED, authErrorCodes.ACCESS_DENIED].includes(e.code)) {
            if (e.description.includes('unverified_email')) {
              // The unverified_email error emitted after registration is formatted differently
              // than the one emitted when sign-in is attempted without a verified email. This
              // handles the latter (defined by the relevant rule in the Auth0 admin config)
              // This error is returned if user hasn't verified their email (and the relevant rule
              // is activated in the Auth0 admin config.
              const email = e.description.split('=')[1];
              this.$rollbar?.info('Attempted authentication with unverified email', { email });
              authConfig.tracking.sendEvent(AUTH_ATTEMPT_UNVERIFIED, { email });
              this.routeToEmailVerificationPage({ code: 'verificationRequired', email });
            }
            return;
          }
          if (e.code === authErrorCodes.LOGIN_REQUIRED) {
            // Session check shows user is not authenticated
            this.$rollbar.info('Auth checkSession: user is not authenticated', e);
            this.resetUserSessionData();
            return;
          }
          if (e.code === authErrorCodes.CONSENT_REQUIRED) {
            // Should only apply to localhost, but we need to catch this to give devs the chance
            // authorize the app post-verification
            this.showLogin({
              initialScreen: 'login',
              flashMessage: {
                type: 'error',
                text: `You need to authorize this application to authenticate on ${window.location.hostname}`,
              },
            });
            this.$rollbar?.info('Consent required to authenticate', {
              hostname: window.location.hostname,
            });
            return;
          }

          this.trackRegistrationErrorEvent(e);
          this.$rollbar?.error('Error checking Auth0 session', e);
          window.vueRoot.$router.replace({ name: 'AuthError' });
          return;
        }

        this.handleAuthentication(authResult);
      },
      routeToEmailVerificationPage({ code, email }) {
        // Don't route to this error page if we're already there (avoid navigationDuplicated)
        // or if we've just been routed to EmailVerified
        if (
          window.vueRoot.$route.name !== 'EmailVerification' &&
          !window.location.pathname.toLowerCase().includes('emailverified')
        ) {
          window.vueRoot.$router.replace({
            name: 'EmailVerification',
            query: { code, email },
          });
        }
      },
      showLogin(
        options: Auth0LockShowOptions,
        targetUrl: string = `${window.location.pathname}${window.location.search}`,
        signupEmailPrefill: string = ''
      ) {
        const showLockOptions: Auth0LockShowOptions = merge({}, options, {
          auth: {
            params: {
              state: JSON.stringify({
                targetUrl,
              }),
            },
          },
        });
        // This, along with the 'on show' listener, lets us prefill & disable the email address
        this.signupEmailPrefill = signupEmailPrefill;
        this.reset();
        this.auth0Lock?.show(showLockOptions);
      },
      hideLogin() {
        this.auth0Lock?.hide();
      },
      handleAuthentication(authResult: Auth0DecodedHash) {
        this.trackAuthenticationEvent(authResult);

        this.hideLogin();
        this.setUserAndSessionData(authResult);

        if (authResult.state) {
          const appState = this.parseAppStateFromAuthResult(authResult.state);
          if (typeof appState === 'object' && appState.targetUrl) {
            authConfig.onRedirectCallback(appState);
          }
        }
      },
      parseAppStateFromAuthResult(stateString: string): AppState {
        try {
          // If state property contains a stringified object
          return JSON.parse(stateString);
        } catch (e) {
          // else state doesn't include a stringified object
          return stateString;
        }
      },
      setUserAndSessionData(authResult: Auth0DecodedHash) {
        this.isAuthenticated = true;
        this.accessToken = authResult.accessToken as string;
        this.idToken = authResult.idToken as string;
        this.user = authResult.idTokenPayload;
        this.loading = false;
        // Convert the expiry time from seconds to milliseconds, required by the Date constructor
        this.tokenExpiry = authResult.idTokenPayload.exp * 1000;

        this.setTokenOnAxiosHeaders(authResult.idToken);

        this.authStatusKnown = true;
        trackingSetup.identifyForTracking({
          email: this.user.email,
          applicationId: '',
          companyName: '',
        });
        featureFlagging.identify({ email: this.user.email });
      },
      resetUserSessionData() {
        this.isAuthenticated = false;
        this.user = null;
        this.accessToken = '';
        this.idToken = '';
        this.tokenExpiry = Date.now();

        this.signupEmailPrefill = '';

        this.unsetTokenFromAxiosHeaders();

        this.authStatusKnown = true;
      },
      setTokenOnAxiosHeaders(idToken) {
        axios.defaults.headers.common.Authorization = `Bearer ${idToken}`;
        localStorage.setItem('auth0_id_token', idToken);
      },
      unsetTokenFromAxiosHeaders() {
        delete axios.defaults.headers.common.Authorization;
        localStorage.removeItem('auth0_id_token');
      },
      logout({ logoutUrl } = { logoutUrl: authConfig.logoutUrl }) {
        const email = this.getUserEmail();
        this.$rollbar?.info(`Logging out user ${email}`);
        authConfig.tracking.sendEvent(AUTH_USER_LOGGED_OUT, { email });
        this.auth0Lock?.logout({
          returnTo: logoutUrl,
        });
      },
      getUserEmail(): string {
        return this.user?.email ? this.user.email : '';
      },
      isVouchUser(): boolean {
        return this.getUserEmail().endsWith('@vouch.us');
      },
      onAuthenticated(authResult: Auth0DecodedHash) {
        if (authResult === null) {
          this.$rollbar?.error('Error on authentication: authResult is null, nothing in hash');
          return;
        }
        // [Snippet ported from old auth service] This parameter is generated from a RULE in
        //   the Auth0 UI. Auth0 doesn't tell you if the user was creating an account or just
        //   logging in. See: https://manage.auth0.com/dashboard/us/vouch-dev/rules
        if (authResult.idTokenPayload['https://vouch.us/isCreatingAccount']) {
          this.trackRegistrationSuccessfulEvent(authResult);
        }
        this.handleAuthentication(authResult);
      },
      onAuthorizationError(e: Auth0Error) {
        if (e?.code === 'invalid_user_password') {
          this.$rollbar?.log('Error from Auth0: user entered an invalid password', e);
        } else if (e.errorDescription?.includes('unverified_email')) {
          // The unverified_email error emitted after registration is formatted differently
          // than the one emitted when sign-in is attempted without a verified email. This
          // handles the former
          const email = e.errorDescription.split('=')[1];
          this.$rollbar?.info('Registered via username/pw, email verification required', { email });
          authConfig.tracking.sendEvent(REGISTRATION_AWAIT_VERIFICATION, { email });
          this.routeToEmailVerificationPage({ code: 'newlyRegistered', email });
        } else {
          this.$rollbar?.error('Error from Auth0: "authorization_error"', e);
        }
        this.trackRegistrationErrorEvent(e);
      },
      trackRegistrationErrorEvent(e) {
        return authConfig.tracking.sendEvent(REGISTRATION_ERROR, e);
      },
      trackRegistrationSuccessfulEvent(authResult) {
        const {
          'https://vouch.us/application_id': applicationId,
          email,
          name,
          sub,
          updated_at,
        } = authResult.idTokenPayload;

        const connection = sub?.split('|')[0];

        this.$rollbar?.info('New user successfully registered', { email });

        return authConfig.tracking.sendEvent(REGISTRATION_SUCCESSFUL, {
          applicationId,
          email,
          name,
          sub,
          updated_at,
          connection,
        });
      },
      trackRegistrationSubmittedEvent() {
        return authConfig.tracking.sendEvent(REGISTRATION_SUBMITTED);
      },
      trackForgotPasswordEvent(email) {
        return authConfig.tracking.sendEvent(PASSWORD_RESET_REQUESTED, email);
      },
      trackAuthenticationEvent(authResult) {
        const { email, name, sub, updated_at } = authResult.idTokenPayload;
        this.$rollbar?.info('User successfully authenticated', { appState: authResult.appState });

        return authConfig.tracking.sendEvent(AUTHENTICATION_SUCCESSFUL, {
          email,
          name,
          sub,
          updated_at,
        });
      },
    },
  });

  return instance;
};

const Auth0LockPlugin = {
  install(vc: VueConstructor, authConfig: AuthConfig) {
    vc.prototype.$auth = useAuth0(authConfig);
  },
};
export default Auth0LockPlugin;
