import { appDataPathValue } from '@/shared/routing/routingDecisionHelpers';

export enum P2NicheCategories {
  GOODS = 'Physical or Consumer Goods',
  HARDWARE = 'Hardware',
  SERVICES = 'Services',
  SOFTWARE = 'Software',
}

export enum P2NicheSwIndustries {
  AGTECH = 'Agtech',
  ADTECH = 'Adtech or Martech',
  BLOCKCHAIN = 'Blockchain',
  CANNABIS = 'Cannabis',
  CRYPTO = 'Cryptocurrency',
  ANALYTICS = 'Data Analytics or Business Intelligence',
  TRANSPO = 'Delivery or Transportation',
  DIGITAL_MEDIA = 'Digital Media',
  ECOMMERCE = 'Ecommerce',
  EDTECH = 'Edtech',
  ENERGY_TECH = 'Energy Tech',
  FINTECH = 'Fintech',
  FITNESS = 'Fitness',
  GAMING = 'Gaming',
  GOVTECH = 'Govtech',
  HEALTHCARE = 'Healthcare or Wellness',
  INSURTECH = 'Insurtech',
  PHARMA = 'Pharmaceutical',
  PROPTECH = 'Proptech',
  SAAS = 'SaaS',
  SECURITY = 'Security',
  SELF_IMPROVEMENT = 'Self-Improvement',
  SERVICES_PROF = 'Services (Professional)',
  SERVICES_NONPROF = 'Services (Non-Professional)',
  SOCIAL_MEDIA = 'Social Media',
  TRAVEL = 'Travel or Hospitality',
  SOMETHING_ELSE = 'Something Else',
}

export enum P2NicheHwIndustries {
  AEROSPACE = 'Aerospace or Aviation',
  AUTO = 'Automotive or Other Transportation',
  COMMS = 'Communications',
  COMPUTERS = 'Computers or Computer Parts',
  CONSTRUCTION = 'Construction',
  GAMING = 'Gaming',
  HEALTH = 'Healthcare or Life Sciences',
  HOME = 'Home and/or Personal Technology',
  INDUSTRIAL = 'Industrial',
  RECORDING = 'Recording Devices',
  ROBOTICS = 'Robotics',
  SENSORS = 'Sensors',
  SOMETHING_ELSE = 'Something Else',
}

export enum P2NicheGoodsIndustries {
  APPAREL = 'Apparel',
  BUILDING = 'Building Materials or Tools',
  CANNABIS = 'Cannabis',
  CHEMICALS = 'Chemicals',
  CLEANING = 'Cleaning Supplies',
  ENTERTAINMENT = 'Entertainment or Games',
  FITNESS = 'Fitness or Sports',
  FOOD = 'Food or Beverage',
  FURNITURE = 'Furniture',
  HEALTH = 'Healthcare or Life Sciences',
  HOUSEHOLD = 'Household Goods',
  LAND = 'Land, Space, or Buildings',
  RESOURCES = 'Natural Resources',
  NUTRA = 'Nutraceuticals or Food Supplements',
  PERSONAL_CARE = 'Personal Care',
  PETS = 'Pets or Wildlife',
  PHARMA = 'Pharmaceuticals',
  TRANSPO = 'Powered Transportation',
  TOYS = 'Toys or Toy Parts',
  SOMETHING_ELSE = 'Something Else',
}

export enum P2NicheServicesIndustries {
  ACCOUNTING = 'Accounting',
  ADULT_CONTENT = 'Adult Content',
  ARCHITECTURE = 'Architecture',
  CANNABIS = 'Cannabis',
  CONSTRUCTION = 'Construction Contractor',
  CONSULTING = 'Business or Strategic Consulting',
  ENGINEERING = 'Engineering',
  FINANCE = 'Financial Services',
  GENERAL_PROFESSIONAL_SERVICES = 'General Professional Services',
  HEALTHCARE = 'Healthcare',
  INSURANCE = 'Insurance',
  LEGAL = 'Legal Services',
  PE_OR_VC = 'Private Equity or Venture Capital',
  REAL_ESTATE = 'Real Estate',
  SOMETHING_ELSE = 'Something Else',
}

export type P2NicheCode =
  | 'Business_Software_Solutions1'
  | 'Business_Software_Solutions2'
  | 'Data_Analytics1'
  | 'Data_Analytics2'
  | 'Digital_Media1'
  | 'Digital_Media2'
  | 'Digital_Media3'
  | 'Digital_Wellness1'
  | 'Digital_Wellness2'
  | 'Digital_Wellness3'
  | 'Digital_Wellness4'
  | 'eCommerce1'
  | 'eCommerce10'
  | 'eCommerce11'
  | 'eCommerce12'
  | 'eCommerce13'
  | 'eCommerce2'
  | 'eCommerce3'
  | 'eCommerce4'
  | 'eCommerce5'
  | 'eCommerce6'
  | 'eCommerce7'
  | 'eCommerce8'
  | 'eCommerce9'
  | 'Fintech_Blockchain_and_Crypto1'
  | 'Fintech_Blockchain_and_Crypto2'
  | 'Hardware_and_Computers1'
  | 'Hardware_and_Computers2'
  | 'Hardware_and_Computers3'
  | 'Hardware_and_Computers4'
  | 'Hardware_and_Computers5'
  | 'Hardware_and_Computers6'
  | 'Hardware_for_Transportation1'
  | 'Hardware_for_Transportation2'
  | 'Hardware_for_Transportation3'
  | 'Medical_Devices1'
  | 'Medical_Devices2'
  | 'Medical_Devices3'
  | 'Medical_Software1'
  | 'Medical_Software2'
  | 'Medical_Software3'
  | 'Medical_Software4'
  | 'Medical_Testing1'
  | 'Medical_Testing2'
  | 'Nutraceuticals_and_Food_Supplements1'
  | 'Nutraceuticals_and_Food_Supplements2'
  | 'Nutraceuticals_and_Food_Supplements3'
  | 'Other_Healthcare_or_Life_Sciences1'
  | 'Other_Software1'
  | 'Other_Type_of_Business1'
  | 'Pharmaceuticals1'
  | 'Pharmaceuticals2'
  | 'Pharmaceuticals3'
  | 'Pharmaceuticals4'
  | 'Pharmaceuticals5'
  | 'Physical_Goods1'
  | 'Physical_Goods10'
  | 'Physical_Goods11'
  | 'Physical_Goods12'
  | 'Physical_Goods13'
  | 'Physical_Goods14'
  | 'Physical_Goods15'
  | 'Physical_Goods16'
  | 'Physical_Goods17'
  | 'Physical_Goods18'
  | 'Physical_Goods19'
  | 'Physical_Goods2'
  | 'Physical_Goods20'
  | 'Physical_Goods3'
  | 'Physical_Goods4'
  | 'Physical_Goods5'
  | 'Physical_Goods6'
  | 'Physical_Goods7'
  | 'Physical_Goods8'
  | 'Physical_Goods9'
  | 'Sensors_and_Recording_Devices1'
  | 'Sensors_and_Recording_Devices2'
  | 'Sensors_and_Recording_Devices3'
  | 'Social_Media1'
  | 'Social_Media2';

type nicheCodeDatum = {
  displayString: string;
  id: string;
  dataIntensityClass: 'High' | 'Medium' | 'Low';
  isB2BServicesClass: boolean;
  isAddOnServicesClass: boolean;
};

// Duplicative of data in niche.domain.ts but handy for look-ups
export const nicheCodeData: Record<P2NicheCode, nicheCodeDatum> = {
  Pharmaceuticals3: {
    displayString:
      'Both Pharmaceutical research/drug development and manufacturing and distribution',
    id: 'Pharmaceuticals3',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Pharmaceuticals2: {
    displayString: 'Pharmaceutical manufacturing or distribution',
    id: 'Pharmaceuticals2',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Pharmaceuticals1: {
    displayString: 'Pharmaceutical research and drug development',
    id: 'Pharmaceuticals1',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Pharmaceuticals5: {
    displayString: 'Clinical trial management services (other than software)',
    id: 'Pharmaceuticals5',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Pharmaceuticals4: {
    displayString: 'Software that supports other pharmaceutical companies',
    id: 'Pharmaceuticals4',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Nutraceuticals_and_Food_Supplements3: {
    displayString:
      'Nutraceutical and food supplement - both manufacturing and development and distribution',
    id: 'Nutraceuticals_and_Food_Supplements3',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Nutraceuticals_and_Food_Supplements1: {
    displayString: 'Nutraceutical and food supplement - manufacturing and development',
    id: 'Nutraceuticals_and_Food_Supplements1',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Nutraceuticals_and_Food_Supplements2: {
    displayString: 'Nutraceutical and food supplement - distribution',
    id: 'Nutraceuticals_and_Food_Supplements2',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Medical_Devices3: {
    displayString: 'Both medical device research/development and manufacturing/distribution',
    id: 'Medical_Devices3',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Medical_Devices1: {
    displayString: 'Medical device research and development',
    id: 'Medical_Devices1',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Medical_Devices2: {
    displayString: 'Medical device manufacturing and distribution',
    id: 'Medical_Devices2',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Medical_Testing2: {
    displayString: 'Laboratory work',
    id: 'Medical_Testing2',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Medical_Testing1: {
    displayString: 'Medical device testing',
    id: 'Medical_Testing1',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Digital_Wellness4: {
    displayString:
      'e health - devices (not requiring a prescription) used to manage personal health (other than fitness)',
    id: 'Digital_Wellness4',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Digital_Wellness3: {
    displayString:
      'e health - consumer software used to manage personal health (other than fitness)',
    id: 'Digital_Wellness3',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Fintech_Blockchain_and_Crypto2: {
    displayString:
      'For other financial institutions to improve their products, offerings, analysis, marketing, access, systems, efficiency or any other improvement',
    id: 'Fintech_Blockchain_and_Crypto2',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Fintech_Blockchain_and_Crypto1: {
    displayString:
      'For consumers or businesses to purchase or access financial products, advice or tools (including payments, real estate, loans, insurance, investment advice, etc.)',
    id: 'Fintech_Blockchain_and_Crypto1',
    dataIntensityClass: 'High',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Digital_Wellness2: {
    displayString: 'To help consumers monitor and improve their fitness',
    id: 'Digital_Wellness2',
    dataIntensityClass: 'High',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Digital_Wellness1: {
    displayString:
      'To help consumers more effectively or efficiently manage their lives (other than their business)',
    id: 'Digital_Wellness1',
    dataIntensityClass: 'High',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Medical_Software3: {
    displayString:
      'Software that supports medical providers (hospitals, doctors offices, dentists, etc)',
    id: 'Medical_Software3',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Medical_Software4: {
    displayString:
      'Software and related technology supporting the agriculture and/or the food industry',
    id: 'Medical_Software4',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Medical_Software1: {
    displayString: 'Software that supports other medical device companies',
    id: 'Medical_Software1',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Medical_Software2: {
    displayString: 'Health data management for business, healthcare providers or government',
    id: 'Medical_Software2',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Other_Healthcare_or_Life_Sciences1: {
    displayString: 'Life Sciences - Other',
    id: 'Other_Healthcare_or_Life_Sciences1',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Hardware_and_Computers2: {
    displayString: 'Industrial technology',
    id: 'Hardware_and_Computers2',
    dataIntensityClass: 'Low',
    isB2BServicesClass: true,
    isAddOnServicesClass: true,
  },
  Hardware_and_Computers5: {
    displayString: 'Construction Tech',
    id: 'Hardware_and_Computers5',
    dataIntensityClass: 'Low',
    isB2BServicesClass: true,
    isAddOnServicesClass: true,
  },
  Hardware_and_Computers1: {
    displayString: 'Computer hardware',
    id: 'Hardware_and_Computers1',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Hardware_and_Computers3: {
    displayString: 'Home and personal technology',
    id: 'Hardware_and_Computers3',
    dataIntensityClass: 'Low',
    isB2BServicesClass: true,
    isAddOnServicesClass: true,
  },
  Hardware_and_Computers4: {
    displayString: 'Communications',
    id: 'Hardware_and_Computers4',
    dataIntensityClass: 'Low',
    isB2BServicesClass: true,
    isAddOnServicesClass: true,
  },
  Hardware_and_Computers6: {
    displayString: 'Hardware - Other',
    id: 'Hardware_and_Computers6',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: true,
    isAddOnServicesClass: true,
  },
  Hardware_for_Transportation2: {
    displayString: 'Aerospace technology',
    id: 'Hardware_for_Transportation2',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Hardware_for_Transportation1: {
    displayString: 'Auto technology',
    id: 'Hardware_for_Transportation1',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Hardware_for_Transportation3: {
    displayString: 'Other transportation technology',
    id: 'Hardware_for_Transportation3',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Sensors_and_Recording_Devices3: {
    displayString: 'Sensors and other recording devices (Home or business)',
    id: 'Sensors_and_Recording_Devices3',
    dataIntensityClass: 'Low',
    isB2BServicesClass: true,
    isAddOnServicesClass: true,
  },
  Sensors_and_Recording_Devices1: {
    displayString: 'Sensors and other recording devices (home)',
    id: 'Sensors_and_Recording_Devices1',
    dataIntensityClass: 'Low',
    isB2BServicesClass: true,
    isAddOnServicesClass: true,
  },
  Sensors_and_Recording_Devices2: {
    displayString: 'Sensors or other recording devices (business)',
    id: 'Sensors_and_Recording_Devices2',
    dataIntensityClass: 'Low',
    isB2BServicesClass: true,
    isAddOnServicesClass: true,
  },
  Physical_Goods12: {
    displayString: 'Chemicals or chemical related supplies',
    id: 'Physical_Goods12',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods17: {
    displayString: 'Natural Resources within/on the land (soil, air, water, minerals, plants)',
    id: 'Physical_Goods17',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods18: {
    displayString: 'Wildlife and Pets',
    id: 'Physical_Goods18',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods11: {
    displayString: 'Building Materials',
    id: 'Physical_Goods11',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods16: {
    displayString: 'Building materials or tools/devices and industrial use products',
    id: 'Physical_Goods16',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods19: {
    displayString: 'Land, Space, and Buildings',
    id: 'Physical_Goods19',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods6: {
    displayString: 'Powered forms of transportation',
    id: 'Physical_Goods6',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods15: {
    displayString: 'Food and/or beverage (including alcoholic)',
    id: 'Physical_Goods15',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods4: {
    displayString: 'Food and/or beverage (non-alcoholic)',
    id: 'Physical_Goods4',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods8: {
    displayString: 'Cleaning Supplies',
    id: 'Physical_Goods8',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods13: {
    displayString: 'Fitness or sports equipment or devices',
    id: 'Physical_Goods13',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods1: {
    displayString: 'Toys',
    id: 'Physical_Goods1',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods9: {
    displayString: 'Household Goods (Other than cleaning supplies)',
    id: 'Physical_Goods9',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods10: {
    displayString: 'Furniture',
    id: 'Physical_Goods10',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods5: {
    displayString: 'Clothes/shoes/apparel',
    id: 'Physical_Goods5',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods3: {
    displayString: 'Media devices',
    id: 'Physical_Goods3',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods2: {
    displayString: 'Entertainment devices',
    id: 'Physical_Goods2',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods7: {
    displayString: 'Games',
    id: 'Physical_Goods7',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods14: {
    displayString: 'Devices providing personal assistance',
    id: 'Physical_Goods14',
    dataIntensityClass: 'Low',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Physical_Goods20: {
    displayString: 'Physical Goods - Other',
    id: 'Physical_Goods20',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: false,
    isAddOnServicesClass: true,
  },
  Digital_Media3: {
    displayString:
      'To consume digital content and media (including educational material) that either you produce or others produce',
    id: 'Digital_Media3',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Digital_Media1: {
    displayString:
      'To consume digital content and media (including educational material) that you produce',
    id: 'Digital_Media1',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Digital_Media2: {
    displayString:
      'To consume digital content and media (including educational material) that others produce',
    id: 'Digital_Media2',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  Social_Media1: {
    displayString: 'To match or connect users with other people for social reasons',
    id: 'Social_Media1',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Social_Media2: {
    displayString:
      'To share information, content or data between users (social media, communication tools, etc)',
    id: 'Social_Media2',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  eCommerce5: {
    displayString: 'Marketplace - To access ground transportation (other than delivery)',
    id: 'eCommerce5',
    dataIntensityClass: 'High',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  eCommerce6: {
    displayString:
      'Marketplace - To access ground transportation provided by others (other than delivery)',
    id: 'eCommerce6',
    dataIntensityClass: 'High',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  eCommerce7: {
    displayString: 'Marketplace - To access transportation (other than ground or delivery)',
    id: 'eCommerce7',
    dataIntensityClass: 'High',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  eCommerce9: {
    displayString:
      'Marketplace - To secure professional services or related advice (other than financial related)',
    id: 'eCommerce9',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  eCommerce10: {
    displayString: 'Marketplace - To secure lodging, storage, real estate or space',
    id: 'eCommerce10',
    dataIntensityClass: 'High',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  eCommerce8: {
    displayString: 'Marketplace - To access forms of delivery to homes or business',
    id: 'eCommerce8',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  eCommerce13: {
    displayString:
      'To match either businesses or consumers or users with services, other than (a) financial products, services or advice or (b) professional services',
    id: 'eCommerce13',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  eCommerce11: {
    displayString:
      'To match consumers or users with services, other than (a) financial products, services or advice or (b) professional services',
    id: 'eCommerce11',
    dataIntensityClass: 'High',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  eCommerce12: {
    displayString:
      'To match businesses or users with services, other than (a) financial products, services or advice or (b) professional services',
    id: 'eCommerce12',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  eCommerce1: {
    displayString: 'eCommerce - to purchase physical products (including food) from your company',
    id: 'eCommerce1',
    dataIntensityClass: 'High',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  eCommerce2: {
    displayString: 'eCommerce - to purchase services from your company',
    id: 'eCommerce2',
    dataIntensityClass: 'High',
    isB2BServicesClass: false,
    isAddOnServicesClass: false,
  },
  eCommerce4: {
    displayString: 'Marketplace - To allow users to exchange physical products (including food)',
    id: 'eCommerce4',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  eCommerce3: {
    displayString:
      'Marketplace - To allow users to exchange services (other than professional services or financial related)',
    id: 'eCommerce3',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Business_Software_Solutions1: {
    displayString:
      'To help a business (other than a financial institution) more effectively or efficiently manage their business (other than sales, promotion or advertising)',
    id: 'Business_Software_Solutions1',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Business_Software_Solutions2: {
    displayString:
      'To help a business (other than a financial institution) sell product (promotion, sales optimization, technical product/funnel optimization, etc.)',
    id: 'Business_Software_Solutions2',
    dataIntensityClass: 'Medium',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Data_Analytics2: {
    displayString: 'For a business or consumer to monitor property or equipment',
    id: 'Data_Analytics2',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Data_Analytics1: {
    displayString: 'For a business (other than financial institution) to access or analyze data',
    id: 'Data_Analytics1',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Other_Software1: {
    displayString: 'Software - Other',
    id: 'Other_Software1',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
  Other_Type_of_Business1: {
    displayString: 'Other Type of Business',
    id: 'Other_Type_of_Business1',
    dataIntensityClass: 'High',
    isB2BServicesClass: true,
    isAddOnServicesClass: false,
  },
};

// Defined in the Question sheet condition for 2021-06-01--HAS_DANGEROUS_PRODUCTS
const possiblyDangerousNiches: Array<P2NicheCode> = [
  'eCommerce1',
  'eCommerce4',
  'Physical_Goods1',
  'Physical_Goods2',
  'Physical_Goods3',
  'Physical_Goods4',
  'Physical_Goods5',
  'Physical_Goods6',
  'Physical_Goods7',
  'Physical_Goods8',
  'Physical_Goods9',
  'Physical_Goods10',
  'Physical_Goods11',
  'Physical_Goods12',
  'Physical_Goods13',
  'Physical_Goods14',
  'Physical_Goods15',
  'Physical_Goods16',
  'Physical_Goods18',
];

// Defined in the Question sheet condition for 2021-06-01--MANUFACTURING_STATUS
const manufacturingNiches: Array<P2NicheCode> = [
  'eCommerce1',
  'eCommerce4',
  'Physical_Goods1',
  'Physical_Goods2',
  'Physical_Goods3',
  'Physical_Goods4',
  'Physical_Goods5',
  'Physical_Goods6',
  'Physical_Goods7',
  'Physical_Goods8',
  'Physical_Goods9',
  'Physical_Goods10',
  'Physical_Goods11',
  'Physical_Goods12',
  'Physical_Goods13',
  'Physical_Goods14',
  'Physical_Goods15',
  'Physical_Goods16',
  'Physical_Goods18',
  'Other_Software1',
  'Hardware_and_Computers1',
  'Sensors_and_Recording_Devices1',
  'Sensors_and_Recording_Devices2',
  'Sensors_and_Recording_Devices3',
  'Hardware_and_Computers2',
  'Hardware_and_Computers3',
  'Hardware_and_Computers4',
  'Hardware_and_Computers5',
  'Hardware_and_Computers6',
  'Physical_Goods17',
  'Physical_Goods19',
  'Physical_Goods20',
  'Other_Healthcare_or_Life_Sciences1',
  'Other_Type_of_Business1',
  'Hardware_for_Transportation1',
  'Hardware_for_Transportation2',
  'Hardware_for_Transportation3',
];

const mediaNiches = [
  'Digital_Media1',
  'Digital_Media2',
  'Digital_Media3',
  'Social_Media1',
  'Social_Media2',
  'Business_Software_Solutions2',
];

const transportNiches = [
  'eCommerce5',
  'eCommerce6',
  'eCommerce7',
  'eCommerce8',
  'Hardware_for_Transportation1',
  'Hardware_for_Transportation2',
  'Hardware_for_Transportation3',
];

const profServicesNiches = [
  'eCommerce2',
  'eCommerce3',
  'eCommerce9',
  'eCommerce10',
  'eCommerce11',
  'eCommerce12',
  'eCommerce13',
];

const medDeviceNiches = [
  'Medical_Devices1',
  'Medical_Devices2',
  'Medical_Devices3',
  'Medical_Testing1',
  'Medical_Testing2',
  'Digital_Wellness4',
];

const hwNiches = [
  'Other_Software1',
  'Hardware_and_Computers1',
  'Sensors_and_Recording_Devices1',
  'Sensors_and_Recording_Devices2',
  'Sensors_and_Recording_Devices3',
  'Hardware_and_Computers2',
  'Hardware_and_Computers3',
  'Hardware_and_Computers4',
  'Hardware_and_Computers5',
  'Hardware_and_Computers6',
  'Physical_Goods17',
  'Physical_Goods19',
  'Physical_Goods20',
  'Other_Healthcare_or_Life_Sciences1',
  'Other_Type_of_Business1',
  'Hardware_for_Transportation1',
  'Hardware_for_Transportation2',
  'Hardware_for_Transportation3',
];

const enterpriseNiches = ['Business_Software_Solutions1', 'Business_Software_Solutions2'];

const pharmaNiches = ['Pharmaceuticals1', 'Pharmaceuticals2', 'Pharmaceuticals3'];

const fintechNiches = ['Fintech_Blockchain_and_Crypto1', 'Fintech_Blockchain_and_Crypto2'];

const dangerousManufacturingOptions = ['We manufacture products', 'Other'];

export const noneOfTheseApply = 'None of these apply';

export const primaryNicheIsAddOnServiceClass = ({ applicationData }) => {
  const nc: P2NicheCode = appDataPathValue('2021-06-01--PRIMARY_NICHE')({ applicationData });
  return nicheCodeData[nc]?.isAddOnServicesClass;
};

export const primaryNicheIsB2BServiceClass = ({ applicationData }) => {
  const nc: P2NicheCode = appDataPathValue('2021-06-01--PRIMARY_NICHE')({ applicationData });
  return nicheCodeData[nc]?.isB2BServicesClass;
};

export const primaryNicheHasMediumHighDataIntensity = ({ applicationData }) => {
  const nc: P2NicheCode = appDataPathValue('2021-06-01--PRIMARY_NICHE')({ applicationData });
  return ['High', 'Medium'].includes(nicheCodeData[nc]?.dataIntensityClass);
};

export const primaryNicheCouldIncludeDangerousProduct = ({ applicationData }) => {
  const nc: P2NicheCode = appDataPathValue('2021-06-01--PRIMARY_NICHE')({ applicationData });
  return possiblyDangerousNiches.includes(nc);
};

export const primaryNicheCouldInvolveManufacturing = ({ applicationData }) => {
  const nc: P2NicheCode = appDataPathValue('2021-06-01--PRIMARY_NICHE')({ applicationData });
  return manufacturingNiches.includes(nc);
};

export const selectedDangerousManufacturingOption = ({ applicationData }) => {
  const manuOption = appDataPathValue('2021-06-01--MANUFACTURING_STATUS')({ applicationData });
  return dangerousManufacturingOptions.includes(manuOption);
};

export const primaryNicheIsMediaRelated = ({ applicationData }) => {
  const nc: P2NicheCode = appDataPathValue('2021-06-01--PRIMARY_NICHE')({ applicationData });
  return mediaNiches.includes(nc);
};

export const primaryNicheIsTransportRelated = ({ applicationData }) => {
  const nc: P2NicheCode = appDataPathValue('2021-06-01--PRIMARY_NICHE')({ applicationData });
  return transportNiches.includes(nc);
};

export const primaryNicheIsProfServicesRelated = ({ applicationData }) => {
  const nc: P2NicheCode = appDataPathValue('2021-06-01--PRIMARY_NICHE')({ applicationData });
  return profServicesNiches.includes(nc);
};

export const primaryNicheIsFintechRelated = ({ applicationData }) => {
  const nc: P2NicheCode = appDataPathValue('2021-06-01--PRIMARY_NICHE')({ applicationData });
  return fintechNiches.includes(nc);
};

export const primaryNicheIsPharmaRelated = ({ applicationData }) => {
  const nc: P2NicheCode = appDataPathValue('2021-06-01--PRIMARY_NICHE')({ applicationData });
  return pharmaNiches.includes(nc);
};

export const primaryNicheIsMedDeviceRelated = ({ applicationData }) => {
  const nc: P2NicheCode = appDataPathValue('2021-06-01--PRIMARY_NICHE')({ applicationData });
  return medDeviceNiches.includes(nc);
};

export const primaryNicheIsMedDeviceorPharmaRelated = ({ applicationData }) => {
  const nc: P2NicheCode = appDataPathValue('2021-06-01--PRIMARY_NICHE')({ applicationData });
  return medDeviceNiches.includes(nc) || pharmaNiches.includes(nc);
};

export const primaryNicheIsHwRelated = ({ applicationData }) => {
  const nc: P2NicheCode = appDataPathValue('2021-06-01--PRIMARY_NICHE')({ applicationData });
  return hwNiches.includes(nc);
};

export const primaryNicheIsEnterpriseRelated = ({ applicationData }) => {
  const nc: P2NicheCode = appDataPathValue('2021-06-01--PRIMARY_NICHE')({ applicationData });
  return enterpriseNiches.includes(nc);
};

export const nicheIndustryDependencies = {
  [P2NicheCategories.SOFTWARE]: ['2021-06-01--NICHE_SW_INDUSTRY'],
  [P2NicheCategories.HARDWARE]: ['2021-06-01--NICHE_HW_INDUSTRY'],
  [P2NicheCategories.GOODS]: ['2021-06-01--NICHE_GOODS_INDUSTRY'],
  [P2NicheCategories.SERVICES]: ['2021-06-01--NICHE_PS_INDUSTRY'],
};
