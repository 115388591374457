<template>
  <div>
    <div class="wrapper">
      <div class="icon-wrapper">
        <PlaidSvg />
      </div>
      <p class="description">
        When you click “Send for Signature” you'll be redirected to Plaid to connect your bank account.
      </p>
    </div>
  </div>
</template>

<script>
import PlaidSvg from '@/onboarding/assets/images/plaid.svg';

export default {
  name: 'CheckoutACH',
  components: { PlaidSvg },
};
</script>

<style lang="scss" scoped>
.wrapper {
  font-family: $sans-font-family;
  background-color: $vouch-accent-mango;
  padding: $space-medium;
  display: flex;
  align-items: center;
  border-radius: 12px 12px 12px 0px;
}

.icon-wrapper {
  width: 79px;
}

.description {
  padding-left: $space-medium;
  font-size: $font-size-m;
  margin-bottom: 0;
}

@media only screen and (max-width: $medium-device) {
  .wrapper {
    flex-direction: column;
  }

  .description {
    padding-left: 0;
    padding-top: $space-medium;
  }
}
</style>
