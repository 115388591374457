<script>
import SchemaFormFieldText from '@/onboarding/components/formfields/SchemaFormFieldText';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';
import BaseCardWithFields from '../../../../components/wizard/BaseCardWithFields';

export default {
  name: 'WizardCardCoreLegalEntity',
  components: { SchemaFormFieldText },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('core'),
      submitPath: getUpdateEndpoint('core'),
      cardConfigPath: '2019-07-01--LEGAL_ENTITY_NAME',
      formFieldData: [
        {
          component: SchemaFormFieldText,
          schemaPath: '2019-07-01--LEGAL_ENTITY_NAME',
          dataPath: '2019-07-01--LEGAL_ENTITY_NAME',
        },
      ],
    };
  },
};
</script>
