<script>
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import SchemaFormFieldTextArea from '@/onboarding/components/formfields/SchemaFormFieldTextArea';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';

export default {
  name: 'TranspHasContractDisputesDescCard',
  components: { SchemaFormFieldTextArea },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('program_2_post_auth'),
      submitPath: getUpdateEndpoint('program_2_post_auth'),
      cardConfigPath: '2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES_DESC',
      formFieldData: [
        {
          component: SchemaFormFieldTextArea,
          schemaPath: '2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES_DESC',
          dataPath: '2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES_DESC',
        },
      ],
    };
  },
};
</script>
