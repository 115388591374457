<template>
  <ReviewFields
    v-if="applicationDataLoaded"
    class="review-fields"
    :route-formatter="routeFormatter"
    :is-editable="true"
    :application-data="applicationData"
    :application-id="applicationId"
  />
</template>

<script>
import ReviewFields from '../../../../components/formfields/ReviewFields';
import { getApplicationData } from '@/onboarding/lib/selectors/storeSelectors';
import isEmpty from 'lodash/isEmpty';
import { onboardingUrl } from '@/onboarding/router/routeHelper';

export default {
  name: 'ReviewFieldsContainer',
  components: { ReviewFields },
  props: {
    applicationId: {
      type: String,
      required: true,
    },
  },
  computed: {
    applicationData() {
      return getApplicationData(this);
    },
    applicationDataLoaded() {
      return !isEmpty(this.applicationData);
    },
  },
  methods: {
    routeFormatter: onboardingUrl,
  },
};
</script>
