import { getAuthPluginInstance, checkAuthentication } from '@/shared/vue-plugins/auth0';

export const authGuard = async (to, from, next) => {
  const { isAuthenticated } = await checkAuthentication();
  if (isAuthenticated) {
    return next();
  }

  const authPluginInstance = getAuthPluginInstance();
  return authPluginInstance.showLogin({ initialScreen: 'login' }, to.fullPath);
};

export const initAuthBeforeEnter = async (to, from, next) => {
  await checkAuthentication();
  return next();
};
