<template>
  <div>
    <v-text-field
      :id="`autocomplete-${index}`"
      ref="autocomplete"
      v-model="fullAddress"
      autocorrect="off"
      spellcheck="false"
      filled
      placeholder="Street address, city, state, zip code"
      hide-details="auto"
      :error-messages="errorMessage"
      :data-testid="`autocomplete-address-${index}`"
      @input="onAutoCompleteInput"
    />
    <v-row no-gutters>
      <v-col cols="12">
        <div class="spacing">
          <PreviousAnswer :value="previousApplicationData" />
        </div>
      </v-col>
    </v-row>
    <div>
      <CardFields
        :form-fields="formFields"
        :on-input-change="onInputChange"
      />
    </div>
  </div>
</template>

<script>
import { get, isNil, setProp } from '@/shared/lib/vouch_dash';
import SchemaFormFieldText from '@/onboarding/components/formfields/SchemaFormFieldText';
import CardFields from '@/shared/views/wizard/core/shared/CardFields';
import SchemaFormHiddenField from '@/onboarding/components/formfields/SchemaFormHiddenField';
import { fullAddress } from '@/onboarding/lib/selectors/addressSelectors';
import PreviousAnswer from '@/shared/components/wizard/PreviousAnswer';
import {
  getFormattedParentPrimaryAddress,
  getFormattedParentAdditionalAddress,
} from '@/onboarding/lib/selectors/storeSelectors';

export default {
  name: 'AutoCompleteLocation',
  components: { CardFields, PreviousAnswer },
  props: {
    index: {
      type: Number,
      required: true,
    },
    formFields: {
      type: Array,
      required: true,
    },
    onInputChange: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      autocomplete: null,
      fullAddress: '',
      previousFullAddress: '',
      fullAddressSelectedFromGoogle: null,
      errorMessage: '',
    };
  },
  computed: {
    previousApplicationData() {
      if (this.index > 0) {
        return getFormattedParentAdditionalAddress(this, this.index - 1);
      }

      return getFormattedParentPrimaryAddress(this);
    },
  },
  watch: {
    formFields: {
      handler(newFormFields) {
        if (newFormFields.length === 0) {
          return;
        }

        const getValue = name => this.getFormFieldWithName(newFormFields, name).value;
        const address1 = getValue('2019-07-01--ADDRESS_LINE_1');
        const city = getValue('2019-07-01--ADDRESS_CITY');
        const state = getValue('2019-07-01--ADDRESS_STATE');
        const postalCode = getValue('2019-07-01--ADDRESS_POSTAL_CODE');

        if (!address1) {
          // If address1 is null/undefined, bail, because it's not filled out and we don't
          // have an address to pre-load
          const { dataPath } = this.getFormFieldWithName(
            newFormFields,
            '2019-07-01--ADDRESS_LINE_1'
          );
          this.onInputChange(null, { dataPath, isValid: false });
          return;
        }

        this.fullAddress = fullAddress({
          address1,
          city,
          state,
          postalCode,
        });

        this.fullAddressSelectedFromGoogle = this.fullAddress;
      },
    },
  },
  mounted() {
    this.initAutoComplete();
  },
  methods: {
    getFormFieldWithName(formFields, name) {
      return formFields.find(({ schemaPath }) => name === schemaPath);
    },
    onAutoCompleteInput(newAddress) {
      if (
        !this.fullAddressSelectedFromGoogle ||
        newAddress === this.fullAddressSelectedFromGoogle
      ) {
        return;
      }

      const { dataPath } = this.getFormFieldWithName(this.formFields, '2019-07-01--ADDRESS_LINE_1');
      this.onInputChange(null, { dataPath, isValid: false });
    },
    initAutoComplete() {
      const autoCompleteRef = this.$refs.autocomplete.$refs.input;
      this.autocomplete = new google.maps.places.Autocomplete(autoCompleteRef, {
        types: ['address'],
      }); // eslint-disable-line no-undef
      this.autocomplete.setComponentRestrictions({ country: ['us'] });

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      this.autocomplete.setFields(['address_component', 'formatted_address']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      this.autocomplete.addListener('place_changed', this.fillInAddress);
    },
    fillInAddress() {
      const place = this.autocomplete.getPlace();
      const addressComponents = place.address_components;
      if (isNil(addressComponents)) {
        return;
      }
      const getPlaceType = type =>
        get(
          addressComponents.find(c => c.types.includes(type)),
          'short_name'
        );

      this.fullAddress = place.formatted_address;
      this.fullAddressSelectedFromGoogle = this.fullAddress;
      this.errorMessage = '';

      const autoCompleteTypes = {
        '2019-07-01--ADDRESS_LINE_1': { types: ['street_number', 'route'] },
        '2019-07-01--ADDRESS_CITY': { types: ['locality'] },
        '2019-07-01--ADDRESS_STATE': { types: ['administrative_area_level_1'] },
        '2019-07-01--ADDRESS_COUNTY': { types: ['administrative_area_level_2'] },
        '2019-07-01--ADDRESS_POSTAL_CODE': {
          types: ['postal_code'],
          onInvalid: index => {
            setProp(this.formFields, [index, 'component'], SchemaFormFieldText);
          },
          onValid: index => {
            setProp(this.formFields, [index, 'component'], SchemaFormHiddenField);
          },
        },
      };
      const setValueOnComponent = (newValue, index) => {
        setProp(this.formFields, [index, 'value'], newValue);
      };

      this.formFields.forEach(({ name, validator, dataPath }, index) => {
        if (autoCompleteTypes[name]) {
          const { types = null, onValid = () => {}, onInvalid = () => {} } = autoCompleteTypes[
            name
          ];
          const newValue = types
            .map(getPlaceType)
            .filter(v => !!v)
            .join(' ');
          const isValid = validator(newValue);
          setValueOnComponent(newValue, index);
          if (isValid) {
            onValid(index);
          } else {
            onInvalid(index);
          }

          this.onInputChange(newValue, { dataPath, isValid });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@include text-field-filled-bg-color;

// Remove the bottom border for the unvalidated state
.v-input {
  ::v-deep .v-input__slot:before {
    content: none;
  }
}

.spacing {
  padding: $space-xsmall 0 $space-small;
}
</style>
