<template>
  <v-text-field
    :value="value"
    :disabled="disabled"
    filled
    autocorrect="off"
    spellcheck="false"
    v-bind="$attrs"
    :error="error"
    :error-count="1"
    :hide-details="hideDetails"
    :placeholder="placeholder"
    :label="label"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'FormFieldText',
  props: {
    error: {
      type: Boolean,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDetails: {
      type: [Boolean, String],
      required: false,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
@include text-field-filled-bg-color;

// Remove the bottom border for the unvalidated state
.v-input {
  ::v-deep .v-input__slot:before {
    content: none;
  }
}
</style>
