<template>
  <a
    class="icon-link"
    :data-testid="dataTestId"
    href="#"
    @click="$emit('click')"
  >
    <transition name="squeeze">
      <VouchIcon
        v-if="loading"
        key="loading-icon"
        name="loading"
        class="icon-img"
      />
      <VouchIcon
        v-else
        key="icon-icon"
        :name="iconName"
        class="icon-img"
      />
    </transition>

    <span
      v-if="!isIconOnly"
      class="icon-link-text"
    >
      {{ text }}
    </span>
  </a>
</template>

<script>
import VouchIcon from '@/shared/components/ui/atoms/icons/VouchIcon';

export default {
  name: 'IconLink',
  components: { VouchIcon },
  props: {
    dataTestId: {
      type: String,
      required: false,
      default: 'icon-link',
    },
    isIconOnly: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-link {
  @include link-button-text;
  color: $icon-link-text-color;
  height: $vouch-icon-size;
  display: inline-flex;
  align-items: center;
}
.icon-img {
  margin-right: $space-xsmall;
}

.download-quote-link-text {
  margin-left: 1px;
}

.squeeze-enter {
  /*opacity: 0;*/
  transform: scaleY(0);
}
.squeeze-enter-active {
  transition: transform $easing-timing;
  transform: scaleY(1);
}
.squeeze-leave {
  transform: scaleY(1);
}
.squeeze-leave-active {
  transform: scaleY(0);
  transition: transform $easing-timing;

  position: absolute !important;
}
</style>
