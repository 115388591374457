<template>
  <div
    v-if="paymentMethod.charge_type === 'card'"
    class="card-row"
  >
    <div>{{ paymentMethod.network }}</div>
    <div>Ending in ... {{ paymentMethod.last_four }}</div>
  </div>

  <div
    v-else
    class="card-row"
  >
    <div>{{ paymentMethod.bank }}</div>
    <div>Ending in ... {{ paymentMethod.last_four }}</div>
  </div>
</template>

<script>
export default {
  name: 'PaymentMethodDetails',
  props: {
    paymentMethod: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-row {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  border: 1px solid #979797;
  border-radius: $corner-roundness;
  padding: $space-medium;
}
</style>