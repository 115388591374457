import {
  anyApply,
  appDataPathIs,
  applicationDataHas,
  autoNo,
  autoYes,
  nicheAndCustomerIs,
  nicheAndTechnologyIs,
  nicheIncludes,
  workersGreaterThan,
} from '@/shared/routing/routingDecisionHelpers';
import { get } from '../lib/vouch_dash';
import featureFlagging from '@/onboarding/services/FeatureFlaggingService';

export const liabilitiesClaimsItemizedContainerCard = {
  id: '2019-07-01--LIABILITIES_CLAIMS_CONTAINER',
  isSatisfied: applicationDataHas('2019-07-01--LIABILITIES_CLAIMS_CONTAINER'),
  shouldRender: appDataPathIs(
    ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_FILED_LIABILITY_CLAIM'],
    true
  ),
};

export const hasInsuranceSelfCard = {
  id: '2019-07-01--HAS_INSURANCE_SELF',
  isSatisfied: applicationDataHas('2019-07-01--HAS_INSURANCE_SELF'),
  shouldRender: autoYes,
};

export const numFullTimeWorkersCard = {
  id: '2019-07-01--NUM_FULL_TIME_WORKERS',
  isSatisfied: applicationDataHas('2019-07-01--NUM_FULL_TIME_WORKERS'),
  shouldRender: autoYes,
};

export const capitalRaisedContainerCard = {
  id: '2019-07-01--CAPITAL_RAISED_CONTAINER',
  isSatisfied: applicationDataHas('2019-07-01--CAPITAL_RAISED_CONTAINER'),
  shouldRender: autoYes,
};

export const verticalNicheCard = {
  id: '2019-07-01--VERTICAL_NICHE',
  isSatisfied: applicationDataHas('2019-07-01--CATEGORY_NICHE'),
  shouldRender: autoYes,
};

export const legalEntityContainerCard = {
  id: '2019-07-01--LEGAL_ENTITY_NAME',
  isSatisfied: applicationDataHas('2019-07-01--LEGAL_ENTITY_NAME'),
  shouldRender: autoYes,
};

export const yearIncorporatedCard = {
  id: '2019-07-01--YEAR_INCORPORATED',
  isSatisfied: applicationDataHas('2019-07-01--YEAR_INCORPORATED'),
  shouldRender: autoYes,
};

export const hasProductInMarketCard = {
  id: '2019-07-01--HAS_PRODUCT_IN_MARKET',
  isSatisfied: applicationDataHas('2019-07-01--HAS_PRODUCT_IN_MARKET'),
  shouldRender: autoYes,
};

export const hasOtherBoardDirectorsCard = {
  id: '2019-07-01--HAS_OTHER_BOARD_DIRECTORS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_OTHER_BOARD_DIRECTORS'),
  shouldRender: autoYes,
};

export const locationContainerCard = {
  id: '2019-07-01--LOCATION_CONTAINER',
  isSatisfied: applicationDataHas('2019-07-01--LOCATION_CONTAINER'),
  shouldRender: ({ applicationData }) => {
    return (
      !get(applicationData, '2019-07-01--LOCATION_CONTAINER') ||
      !featureFlagging.getFlag({ flag: 'biz-address-on-madlib' })
    );
  },
};

export const locationContainerAttributesCard = {
  id: '2019-07-01--LOCATION_CONTAINER_attributes',
  isSatisfied: autoYes,
  shouldRender: ({ applicationData }) => {
    return !featureFlagging.getFlag({ flag: 'biz-address-on-madlib' });
  },
};

export const locationContainerAttributesAndAdditionalCard = {
  id: '2019-07-01--LOCATIONS',
  isSatisfied: autoYes,
  shouldRender: () => {
    return featureFlagging.getFlag({ flag: 'biz-address-on-madlib' });
  },
};

export const hasEngineeringContractorsCard = {
  id: '2019-07-01--HAS_ENGINEERING_CONTRACTORS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_ENGINEERING_CONTRACTORS'),
  shouldRender: autoYes,
};

export const numEngineeringContractorsCard = {
  id: '2019-07-01--NUM_ENGINEERING_CONTRACTORS',
  isSatisfied: applicationDataHas('2019-07-01--NUM_ENGINEERING_CONTRACTORS'),
  shouldRender: appDataPathIs('2019-07-01--HAS_ENGINEERING_CONTRACTORS', true),
};

export const numInvoluntaryTerminationCard = {
  id: '2019-07-01--NUM_INVOLUNTARY_TERMINATION',
  isSatisfied: applicationDataHas('2019-07-01--NUM_INVOLUNTARY_TERMINATION'),
  shouldRender: autoYes,
};

export const numPlannedInvoluntaryTerminationCard = {
  id: '2019-07-01--NUM_PLANNED_INVOLUNTARY_TERMINATION',
  isSatisfied: applicationDataHas('2019-07-01--NUM_PLANNED_INVOLUNTARY_TERMINATION'),
  shouldRender: autoYes,
};

export const has401kCard = {
  id: '2019-07-01--HAS_401K',
  isSatisfied: applicationDataHas('2019-07-01--HAS_401K'),
  shouldRender: workersGreaterThan(25),
};

export const hasERISACard = {
  id: '2021-16-02--SUBJECT_TO_ERISA',
  isSatisfied: applicationDataHas('2021-16-02--SUBJECT_TO_ERISA'),
  shouldRender: autoYes,
};

export const benefitsWorthCard = {
  id: '2019-07-01--BENEFITS_WORTH',
  isSatisfied: applicationDataHas('2019-07-01--BENEFITS_WORTH'),
  shouldRender: appDataPathIs('2019-07-01--HAS_401K', true),
};

export const annualRevenueContainerCard = {
  id: '2019-07-01--ANNUAL_REVENUE_CONTAINER',
  isSatisfied: applicationDataHas('2019-07-01--ANNUAL_REVENUE_CONTAINER'),
  shouldRender: autoYes,
};

export const totalCashAndEquivalentsCentsCard = {
  id: '2019-07-01--TOTAL_CASH_AND_EQUIVALENTS_CENTS',
  isSatisfied: applicationDataHas('2019-07-01--TOTAL_CASH_AND_EQUIVALENTS_CENTS'),
  shouldRender: autoYes,
};

export const hasAnyPiiCard = {
  id: '2019-07-01--HAS_ANY_PII',
  isSatisfied: applicationDataHas('2019-07-01--HAS_ANY_PII'),
  shouldRender: autoYes,
};

export const piiAmountCard = {
  id: '2019-07-01--PII_AMOUNT',
  isSatisfied: applicationDataHas('2019-07-01--PII_AMOUNT'),
  shouldRender: appDataPathIs('2019-07-01--HAS_ANY_PII', true),
};

export const insuranceHistoryContainerCard = {
  id: '2019-07-01--INSURANCE_HISTORY_CONTAINER',
  isSatisfied: applicationDataHas('2019-07-01--INSURANCE_HISTORY_CONTAINER'),
  shouldRender: autoYes,
};

export const currentClaimsDescriptionCard = {
  id: '2019-07-01--CURRENT_CLAIMS_DESCRIPTION',
  isSatisfied: applicationDataHas('2019-07-01--CURRENT_CLAIMS_DESCRIPTION'),
  shouldRender: appDataPathIs(
    ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_CURRENT_CLAIMS'],
    true
  ),
};

export const deniedCoverageDescriptionCard = {
  id: '2019-07-01--DENIED_COVERAGE_DESCRIPTION',
  isSatisfied: applicationDataHas('2019-07-01--DENIED_COVERAGE_DESCRIPTION'),
  shouldRender: appDataPathIs(
    ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_DENIED_COVERAGE'],
    true
  ),
};

export const payoutAmountCard = {
  id: '2019-07-01--PAYOUT_AMOUNT',
  isSatisfied: applicationDataHas('2019-07-01--PAYOUT_AMOUNT'),
  shouldRender: appDataPathIs(
    ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_FILED_LIABILITY_CLAIM'],
    true
  ),
};

export const payoutDescriptionCard = {
  id: '2019-07-01--PAYOUT_DESCRIPTION',
  isSatisfied: applicationDataHas('2019-07-01--PAYOUT_DESCRIPTION'),
  shouldRender: appDataPathIs(
    ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_FILED_LIABILITY_CLAIM'],
    true
  ),
};

export const hasPendingClaimsCard = {
  id: '2019-07-01--HAS_PENDING_CLAIMS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_PENDING_CLAIMS'),
  shouldRender: autoYes,
};

export const pendingClaimsDescriptionCard = {
  id: '2019-07-01--PENDING_CLAIMS_DESCRIPTION',
  isSatisfied: applicationDataHas('2019-07-01--PENDING_CLAIMS_DESCRIPTION'),
  shouldRender: appDataPathIs('2019-07-01--HAS_PENDING_CLAIMS', true),
};

export const Customers = {
  enterprise: 'Enterprise & SMB',
  consumer: 'Consumer',
};

export const Technology = {
  hardware: 'Hardware',
  software: 'Software',
};

export const hasSensitivePIICard = {
  id: '2019-07-01--HAS_ANY_SENSITIVE_PII',
  isSatisfied: applicationDataHas('2019-07-01--HAS_ANY_SENSITIVE_PII'),
  shouldRender: anyApply([
    nicheIncludes([
      'e-Commerce',
      'Retail Brands',
      'On-Demand Services',
      'Marketplaces',
      'Subscription',
      'Other Commerce',
      'Productivity and Utilities',
      'Search Engines/Research Tools',
      'Connected Home',
      'Toys, Entertainment & Media',
      'Drones',
      'Wearables, Health Tech, Connected Self',
      'Manufacturing/3D Printing',
      'Robotics',
      'IOT Network Infrastructure and Sensor Developers',
      'Aerospace Technology',
      'Wired/Wireless Telecom Infrastructure',
      'Networking/Security - WAN/LAN',
      'Servers & Storage',
      'Semiconductors',
    ]),
    nicheAndCustomerIs({
      niche: 'VR/AR Hardware and Distribution',
      customer: Customers.enterprise,
    }),
    nicheAndCustomerIs({ niche: 'VR/AR Hardware and Distribution', customer: Customers.consumer }),
    nicheAndTechnologyIs({ niche: 'Automotive Technology', technology: Technology.hardware }),
  ]),
};

export const hasDangerousProductsCard = {
  id: '2019-07-01--HAS_DANGEROUS_PRODUCTS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_DANGEROUS_PRODUCTS'),
  shouldRender: nicheIncludes(['e-Commerce', 'Retail Brands', 'Marketplaces', 'Subscription']),
};

export const hasProfessionalLicenseBusinessCard = {
  id: '2019-07-01--HAS_PROFESSIONAL_LICENSE_BUSINESS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_PROFESSIONAL_LICENSE_BUSINESS'),
  shouldRender: nicheIncludes(['On-Demand Services', 'Marketplaces']),
};

export const hasCertificateBusinessCard = {
  id: '2019-07-01--HAS_CERTIFICATE_BUSINESS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_CERTIFICATE_BUSINESS'),
  shouldRender: nicheIncludes(['Education Technology', 'Education Technology - Enterprise']),
};

export const hasMobileGamesCard = {
  id: '2019-07-01--HAS_MOBILE_GAMES',
  isSatisfied: applicationDataHas('2019-07-01--HAS_MOBILE_GAMES'),
  shouldRender: nicheIncludes(['Gaming']),
};

export const hasPhysicalActivityBusinessCard = {
  id: '2019-07-01--HAS_PHYSICAL_ACTIVITY_BUSINESS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_PHYSICAL_ACTIVITY_BUSINESS'),
  shouldRender: nicheIncludes([
    'Health & Wellness',
    'Connected Home',
    'Toys, Entertainment & Media',
    'Drones',
    'Wearables, Health Tech, Connected Self',
    'VR/AR Hardware and Distribution',
  ]),
};

export const hasContractorsDisputesCard = {
  id: '2019-07-01--HAS_CONTRACTORS_DISPUTES',
  isSatisfied: applicationDataHas('2019-07-01--HAS_CONTRACTORS_DISPUTES'),
  shouldRender: nicheIncludes(['On-Demand Services', 'Marketplaces']),
};

export const businessDescriptionCard = {
  id: '2019-07-01--BUSINESS_DESCRIPTION',
  isSatisfied: autoYes,
  shouldRender: autoNo,
};

export const hasMedicalDataCard = {
  id: '2019-07-01--HAS_MEDICAL_DATA',
  isSatisfied: applicationDataHas('2019-07-01--HAS_MEDICAL_DATA'),
  shouldRender: nicheIncludes(['Health & Wellness']),
};

export const hasRegulatorLicenseCard = {
  id: '2019-07-01--HAS_REGULATOR_LICENSE',
  isSatisfied: applicationDataHas('2019-07-01--HAS_REGULATOR_LICENSE'),
  shouldRender: nicheIncludes([
    'Insurance Tech - Life or Annuities',
    'Insurance Tech - Property & Casualty',
    'Insurance Tech - Health',
    'Alternative Lending',
    'Personal Finance and Wealth Management',
  ]),
};

export const hasRealEstateLicenseCard = {
  id: '2019-07-01--HAS_REAL_ESTATE_LICENSE',
  isSatisfied: applicationDataHas('2019-07-01--HAS_REAL_ESTATE_LICENSE'),
  shouldRender: nicheIncludes(['Real Estate Tech']),
};

export const hasAdvertisingClaimCard = {
  id: '2019-07-01--HAS_ADVERTISING_CLAIM',
  isSatisfied: applicationDataHas('2019-07-01--HAS_ADVERTISING_CLAIM'),
  shouldRender: nicheIncludes([
    'Ad Placement Management',
    'Data Platforms/Analysis',
    'Marketing Software',
  ]),
};

export const internationalSalesCard = {
  id: '2019-07-01--INTERNATIONAL_SALES',
  isSatisfied: applicationDataHas('2019-07-01--INTERNATIONAL_SALES'),
  shouldRender: nicheIncludes([
    'Insurance Tech - Life or Annuities',
    'Insurance Tech - Property & Casualty',
    'Insurance Tech - Health',
    'Alternative Lending',
    'Personal Finance and Wealth Management',
    'Payments',
    'Real Estate Tech',
    'Ad Placement Management',
    'Data Platforms/Analysis',
    'Insurance Tech (agency/MGA/carrier)',
    'Insurance Tech (other than agency/MGA/carrier)',
  ]),
};

export const hasPhysicalProductsCard = {
  id: '2019-07-01--HAS_PHYSICAL_PRODUCTS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_PHYSICAL_PRODUCTS'),
  shouldRender: nicheIncludes([
    'Artificial Intelligence and Machine Learning',
    'VR/AR Software, Platforms and Delivery Services',
    'Industrial Internet of Things (IIOT)',
    'Automotive Technology',
    'Aerospace Software and Services',
  ]),
};

export const numBigRevenueContractsCard = {
  id: '2019-07-01--NUM_BIG_REVENUE_CONTRACTS',
  isSatisfied: applicationDataHas('2019-07-01--NUM_BIG_REVENUE_CONTRACTS'),
  shouldRender: anyApply([
    nicheAndCustomerIs({ niche: 'Real Estate Tech', customer: Customers.enterprise }),
    nicheIncludes([
      'Artificial Intelligence and Machine Learning',
      'Big Data Analytics and Business Intelligence',
      'Cloud Infrastructure/Infrastructure as a Service',
      'CRM/ERP/Supply Chain Management/Operations Software',
      'Developer Tools and Microservices',
      'eCommerce Applications',
      'Education Technology - Enterprise',
      'Enterprise Communication/Collaboration Software',
      'HR and Workforce Management',
      'Marketing Software',
      'Cyber Security/ Network Management Software',
      'VR/AR Software, Platforms and Delivery Services',
      'Industrial Internet of Things (IIOT)',
      'Automotive Technology',
      'Aerospace Software and Services',
      'Backend Infrastructure',
      'Financial Business Process Software',
      'Blockchain (non-currency related)',
      'Blockchain (currency related)',
    ]),
  ]),
};

export const hasServicesBusinessCard = {
  id: '2019-07-01--HAS_SERVICES_BUSINESS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_SERVICES_BUSINESS'),
  shouldRender: nicheIncludes([
    'Alternative Lending',
    'Artificial Intelligence and Machine Learning',
    'eCommerce Applications',
    'Personal Finance and Wealth Management',
  ]),
};

export const hasBenefitsBusinessCard = {
  id: '2019-07-01--HAS_BENEFITS_BUSINESS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_BENEFITS_BUSINESS'),
  shouldRender: nicheIncludes(['HR and Workforce Management']),
};

export const hasChemicalsCard = {
  id: '2019-07-01--HAS_CHEMICALS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_CHEMICALS'),
  shouldRender: nicheIncludes([
    'VR/AR Software, Platforms and Delivery Services',
    'Industrial Internet of Things (IIOT)',
    'Automotive Technology',
    'Aerospace Software and Services',
    'Connected Home',
    'Toys, Entertainment & Media',
    'Drones',
    'Wearables, Health Tech, Connected Self',
    'VR/AR Hardware and Distribution',
    'Manufacturing/3D Printing',
    'Robotics',
    'IOT Network Infrastructure and Sensor Developers',
    'Aerospace Technology',
    'Wired/Wireless Telecom Infrastructure',
    'Networking/Security - WAN/LAN',
    'Servers & Storage',
    'Semiconductors',
  ]),
};

export const hasPollutantsCard = {
  id: '2019-07-01--HAS_POLLUTANTS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_POLLUTANTS'),
  shouldRender: nicheIncludes([
    'Automotive Technology',
    'Aerospace Software and Services',
    'Manufacturing/3D Printing',
    'Robotics',
    'Aerospace Technology',
    'Semiconductors',
  ]),
};

export const hasConsumableBusinessCard = {
  id: '2019-07-01--HAS_CONSUMABLE_BUSINESS',
  isSatisfied: autoYes,
  shouldRender: autoNo,
};

export const hasMoneyTransferBusinessCard = {
  id: '2019-07-01--HAS_MONEY_TRANSFER_BUSINESS',
  isSatisfied: autoYes,
  shouldRender: autoNo,
};

export const hasAccountingBusinessCard = {
  id: '2019-07-01--HAS_ACCOUNTING_BUSINESS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_ACCOUNTING_BUSINESS'),
  shouldRender: nicheIncludes(['Financial Business Process Software', 'Payments']),
};

export const hasFinanceClientsBusinessCard = {
  id: '2019-07-01--HAS_FINANCE_CLIENTS_BUSINESS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_FINANCE_CLIENTS_BUSINESS'),
  shouldRender: nicheIncludes([
    'Alternative Lending',
    'Personal Finance and Wealth Management',
    'Payments',
    'Real Estate Tech',
    'Big Data Analytics and Business Intelligence',
    'Cloud Infrastructure/Infrastructure as a Service',
    'CRM/ERP/Supply Chain Management/Operations Software',
    'Cyber Security/ Network Management Software',
    'Financial Business Process Software',
    'Blockchain (non-currency related)',
    'Blockchain (currency related)',
  ]),
};

export const hasFinanceBusinessCard = {
  id: '2019-07-01--HAS_FINANCE_BUSINESS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_FINANCE_BUSINESS'),
  shouldRender: anyApply([
    nicheIncludes(['Personal Finance and Wealth Management']),
    nicheAndCustomerIs({ niche: 'Real Estate Tech', customer: Customers.enterprise }),
  ]),
};

export const hasRealEstateBusinessCard = {
  id: '2019-07-01--HAS_REAL_ESTATE_BUSINESS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_REAL_ESTATE_BUSINESS'),
  shouldRender: nicheIncludes(['Real Estate Tech']),
};

export const hasMobilityBusinessCard = {
  id: '2019-07-01--HAS_MOBILITY_BUSINESS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_MOBILITY_BUSINESS'),
  shouldRender: nicheIncludes([
    'On-Demand Services',
    'Marketplaces',
    'Subscription',
    'Toys, Entertainment & Media',
  ]),
};

export const hasHighRiskClientsCard = {
  id: '2019-07-01--HAS_HIGH_RISK_CLIENTS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_HIGH_RISK_CLIENTS'),
  shouldRender: nicheIncludes([
    'On-Demand Services',
    'Marketplaces',
    'Subscription',
    'VR/AR Content and Publishing',
    'Artificial Intelligence and Machine Learning',
    'Developer Tools and Microservices',
    'VR/AR Software, Platforms and Delivery Services',
    'Industrial Internet of Things (IIOT)',
    'Automotive Technology',
    'Aerospace Software and Services',
    'Connected Home',
    'Toys, Entertainment & Media',
    'Drones',
    'Wearables, Health Tech, Connected Self',
    'Manufacturing/3D Printing',
    'Robotics',
    'IOT Network Infrastructure and Sensor Developers',
    'Semiconductors',
  ]),
};

export const hasBlockchainBusinessCard = {
  id: '2019-07-01--HAS_BLOCKCHAIN_BUSINESS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_BLOCKCHAIN_BUSINESS'),
  shouldRender: nicheIncludes(['Blockchain (non-currency related)']),
};

export const hasRoboticsBusinessCard = {
  id: '2019-07-01--HAS_ROBOTICS_BUSINESS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_ROBOTICS_BUSINESS'),
  shouldRender: nicheIncludes(['Robotics']),
};

export const hasLoansBusinessCard = {
  id: '2019-07-01--HAS_LOANS_BUSINESS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_LOANS_BUSINESS'),
  shouldRender: nicheAndCustomerIs({ niche: 'Real Estate Tech', customer: Customers.consumer }),
};

export const hasCopyrightClaimCard = {
  id: '2019-07-01--HAS_COPYRIGHT_CLAIM',
  isSatisfied: applicationDataHas('2019-07-01--HAS_COPYRIGHT_CLAIM'),
  shouldRender: nicheIncludes([
    'Media and Entertainment',
    'Messaging',
    'Original Content and Publishing',
    'Search Engines/Research Tools',
    'VR/AR Content and Publishing',
    'Ad Placement Management',
    'Data Platforms/Analysis',
    'eCommerce Applications',
    'Enterprise Communication/Collaboration Software',
    'Marketing Software',
  ]),
};
