<template>
  <div>
    <BaseWizardPage
      :card-data="_cardData"
      :is-submit-disabled="false"
      :fill-height="false"
      submit-text="Confirm and Continue"
      @submit="onSubmit"
    />
    <ReviewFields
      v-if="applicationData"
      :application-data="applicationData"
      :application-id="applicationId"
    />
  </div>
</template>

<script>
import BaseCard from '../../../../../shared/components/wizard/BaseCard';
import { getCompanyName, getDecisionData } from '@/onboarding/lib/selectors/storeSelectors';
import ReviewFields from '../../../../components/formfields/ReviewFields';
import BaseWizardPage from '@/shared/components/wizard/BaseWizardPage';

export default {
  name: 'ReengagementCard',
  components: { BaseWizardPage, ReviewFields },
  extends: BaseCard,
  computed: {
    _cardData() {
      return {
        title: `Welcome back, ${this.companyName}`,
        description:
          "Confirm that the information you've given so far is still correct, and let's continue your in-progress application",
      };
    },
    companyName() {
      return getCompanyName(this);
    },
    decisionData() {
      return getDecisionData(this);
    },
  },
  methods: {
    onSubmit() {
      const path = this.questionRouter.getReinsertionPath(this);
      this.$router.push({ path });
    },
  },
};
</script>
