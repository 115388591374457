<template>
  <div class="promo-section">
    <p class="promo-text">
      {{ promoText }}
    </p>
    <v-img
      :src="image"
      :contain="true"
      class="promo-image"
    >
      <div :class="{'promo-img-text': true, 'promo-img-text-mobile': isMobile}">
        Pay with your Brex card and receive up to 10,000 Brex Points!
      </div>
    </v-img>
  </div>
</template>

<script>
import cardImg from '@/onboarding/assets/images/brex-card.png';

export default {
  name: 'CheckoutFormSectionBrex10KPointsAbovePayment',
  props: {
    isMobile: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      promoText: 'Pay with your Brex credit card and receive up to 10,000 Brex Points.',
      image: cardImg,
    };
  },
};
</script>

<style lang="scss" scoped>
.promo-section {
  margin-bottom: $space-large;
}

.promo-text {
  @include body-text;
  margin-top: -$space-medium;
}

.promo-img-text {
  @include h3-text;
  text-align: right;
  position: absolute;
  bottom: $space-large;
  right: $space-medium;
  max-width: $space-xxxlarge;

  &-mobile {
    @include h4-text;
    max-width: $space-xxlarge;
    bottom: $space-small;
  }
}
</style>
