import { render, staticRenderFns } from "./VouchIcon.vue?vue&type=template&id=2316e545&scoped=true&"
import script from "./VouchIcon.vue?vue&type=script&lang=js&"
export * from "./VouchIcon.vue?vue&type=script&lang=js&"
import style0 from "./VouchIcon.vue?vue&type=style&index=0&id=2316e545&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2316e545",
  null
  
)

export default component.exports