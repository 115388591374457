<template>
  <LayoutCenteredCard :has-back-button="hasBackButton">
    <LayoutCardPage
      :is-mobile="isMobile"
      :header-text="headerText"
    >
      <template v-slot:left-panel>
        <slot name="left-panel" />
      </template>
      <template v-slot:main-header>
        <ProgressBar
          :progress="progress"
          :minutes-left="minutesLeft"
        />
      </template>
      <template v-slot:main-panel>
        <TransitionFade>
          <slot />
        </TransitionFade>
      </template>
    </LayoutCardPage>
    <template v-slot:below-card-content>
      <slot name="below-card-content" />
    </template>
  </LayoutCenteredCard>
</template>

<script>
import ProgressBar from '@/shared/components/wizard/ProgressBar';
import LayoutCardPage from '@/shared/components/layouts/LayoutCardPage';
import LayoutCenteredCard from '@/shared/components/layouts/LayoutCenteredCard';
import TransitionFade from '@/shared/components/stateful/TransitionFade';

export default {
  name: 'WizardPageWrapper',
  components: {
    LayoutCenteredCard,
    LayoutCardPage,
    ProgressBar,
    TransitionFade,
  },
  props: {
    headerText: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isSubmitDisabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    submitText: {
      type: String,
      required: false,
      default: 'Next',
    },
    hasBackButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    minutesLeft: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isMobile() {
      // using this instead of `<IsMobile>` bc vue wasn't update the slot properly
      // why? i have no idea
      return ['xs', 's', 'm'].includes(this.$mq);
    },
  },
};
</script>
