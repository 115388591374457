<template>
  <div
    v-bind="$attrs"
    class="vouch-card"
    :class="{
      'vouch-card--mobile': isMobile,
      'vouch-card--hoverable': hoverable,
      'vouch-card--padded': padded,
    }"
    v-on="$listeners"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VouchCard',
  props: {
    hoverable: {
      type: Boolean,
      required: false,
    },
    padded: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    isMobile() {
      return ['xs', 's', 'm'].includes(this.$mq);
    },
  },
};
</script>

<style lang="scss" scoped>
.vouch-card {
  border: 1px solid $card-border-color;
  border-radius: $corner-roundness;
  transition: filter $easing-timing;
  filter: drop-shadow($drop-shadow-level-1);

  padding: $card-padding;

  background-color: $card-background-color;

  &--mobile {
    padding: $card-padding--mobile;
  }
  &--padded#{&}--mobile {
    padding: $space-large $card-padding--mobile;
  }

  &--hoverable:hover {
    filter: drop-shadow($drop-shadow-level-2);
  }
}
</style>
