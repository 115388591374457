<template>
  <Interstitial
    :next="next"
    :message="cardTitle"
  />
</template>

<script>
import { AUTH0_VERIFICATION_STRINGS } from '@/shared/vue-plugins/auth0';
import {
  REGISTRATION_VERIFICATION_COMPLETE,
  REGISTRATION_VERIFICATION_ERROR,
  REGISTRATION_VERIFICATION_EXPIRED,
  REGISTRATION_VERIFICATION_RETRIED,
} from '@/onboarding/services/SegmentEventTypes';
import Interstitial from '@/shared/views/layouts/Interstitial.vue';

export default {
  name: 'EmailVerified',
  components: { Interstitial },
  // These properties are passed as URL params from Auth0. When the user clicks the verification
  // link in their email, Auth0 processes the verification and then redirects to this page
  // (specified in the Auth0 email template settings) with several params
  // https://auth0.com/docs/auth0-email-services/email-template-descriptions
  props: {
    email: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    success: {
      type: String,
      default: 'true',
    },
  },
  data() {
    return { next: '/', timerId: null };
  },
  dependencies: ['tracking'],
  computed: {
    cardTitle() {
      return this.success === 'true' ? 'Email verified!' : 'Email already verified';
    },
    linkAlreadyUsed() {
      return (
        this.message.includes(AUTH0_VERIFICATION_STRINGS.oneUseOnly) ||
        this.message.includes(AUTH0_VERIFICATION_STRINGS.alreadyVerified)
      );
    },
  },
  async mounted() {
    if (this.success === 'true') {
      this.$rollbar?.info('Email verified');
      this.tracking.sendEvent(REGISTRATION_VERIFICATION_COMPLETE, { email: this.email });
      await new Promise((resolve, _reject) => {
        this.timerId = setTimeout(() => {
          const applicationId = this.$auth.user['https://vouch.us/application_id'];
          // If the user does email/pw signup that doesn't match the email from madlib, they will
          // be lost navigating to /send without an application id. Set during Auth0 Lock initialization in src/shared/vue-plugins/auth0.ts
          this.next = `/loginportal/send${applicationId ? `?applicationId=${applicationId}` : ''}`;
          resolve(clearTimeout(this.timerId));
        }, 1000);
      });
    } else if (this.linkAlreadyUsed) {
      this.$rollbar?.info('Email verification attempted, but already verified');
      this.tracking.sendEvent(REGISTRATION_VERIFICATION_RETRIED, { email: this.email });
      this.timerId = setTimeout(() => {
        this.next = '/loginportal/send';
        clearTimeout(this.timerId);
      }, 1000);
    } else if (this.message.includes(AUTH0_VERIFICATION_STRINGS.accessExpired)) {
      // If verification was not sucessful and email is not verified, redirect
      this.tracking.sendEvent(REGISTRATION_VERIFICATION_EXPIRED, { email: this.email });
      this.$rollbar?.info('Attempted email verification with expired link', {
        email: this.email,
      });

      const params = new URLSearchParams({ code: 'emailLinkExpired', email: this.email });
      this.next = `/loginportal/emailVerification?${params.toString()}`;
    } else {
      this.tracking.sendEvent(REGISTRATION_VERIFICATION_ERROR, { email: this.email });
      this.$rollbar?.info('Error verifying email address', { email: this.email });
      this.next = '/loginportal/authError?errorCode=failed_to_verify';
    }
  },
};
</script>

<style lang="scss" scoped>
.button-row {
  display: flex;
  margin-top: $space-large;
}
</style>
