import { get } from '../lib/vouch_dash';

export const autoYes = _routingComponent => true;
export const autoNo = _routingComponent => false;

export const appDataPathValue: any = (path: string) => ({ applicationData }) =>
  get(applicationData, path);

export const applicationDataHas = prop => ({ applicationData }) =>
  Object.prototype.hasOwnProperty.call(applicationData, prop);

export const appDataPathIs = (path, value) => ({ applicationData }) =>
  get(applicationData, path) === value;

export const appDataPathIsOneOf = (path, valueList) => ({ applicationData }) =>
  valueList.includes(get(applicationData, path));

export const appDataPathIsGreaterThan = (path, value) => ({ applicationData }) =>
  get(applicationData, path) > value;

export const appDataPathsSummedAreGreaterThan = (path1, path2, value) => ({ applicationData }) =>
  get(applicationData, path1) + get(applicationData, path2) > value;

export const appDataPathIncludes = (path, value) => ({ applicationData }) =>
  get(applicationData, path, []).includes(value);

export const appDataPathIncludesAny = (path, value) => ({ applicationData }) =>
  get(applicationData, path, []).filter(x => value.includes(x)).length > 0;

export const appDataPathDoesNotInclude = (path, value) => ({ applicationData }) => {
  const preCondition = get(applicationData, path, []);
  return preCondition.length > 0 && !preCondition.includes(value);
};

export const anyPropertyIsTrue = pathList => ({ applicationData }) =>
  pathList.some(path => get(applicationData, path) === true);

export const locationWorkersGreaterThan = value => ({ applicationData }) => {
  const primaryWorkers = get(
    applicationData,
    ['2019-07-01--LOCATION_CONTAINER', '2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS'],
    0
  );

  const additionalWorkers = get(
    applicationData,
    ['2019-07-01--LOCATION_CONTAINER', 'additional_locations'],
    []
  ).map(location => location['2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS'] || 0);

  const workers = [primaryWorkers, ...additionalWorkers].reduce((sum, n) => sum + n);
  return workers > value;
};

export const anyApply = fns => component => fns.some(f => f(component));

export const workersGreaterThan = n =>
  anyApply([
    appDataPathIsGreaterThan('2019-07-01--NUM_FULL_TIME_WORKERS', n),
    locationWorkersGreaterThan(n),
  ]);

export const locationDetailsComplete = () => ({ applicationData }) => {
  const locationType = get(
    applicationData,
    ['2019-07-01--LOCATION_CONTAINER', '2019-07-01--LOCATION_TYPE'],
    null
  );
  const assetRange = get(
    applicationData,
    ['2019-07-01--LOCATION_CONTAINER', '2019-07-01--LOCATION_ASSET_RANGE'],
    null
  );
  const primaryWorkers = get(
    applicationData,
    ['2019-07-01--LOCATION_CONTAINER', '2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS'],
    null
  );
  return !!primaryWorkers && !!assetRange && !!locationType;
};

export const nicheIncludes = niches => ({ applicationData }) =>
  niches.includes(applicationData['2019-07-01--CATEGORY_NICHE']);

export const nicheAndCustomerIs = ({ niche, customer }) => ({ applicationData }) =>
  niche === applicationData['2019-07-01--CATEGORY_NICHE'] &&
  customer === applicationData['2019-07-01--CATEGORY_CUSTOMER'];

export const nicheAndTechnologyIs = ({ niche, technology }) => ({ applicationData }) =>
  niche === applicationData['2019-07-01--CATEGORY_NICHE'] &&
  technology === applicationData['2019-07-01--CATEGORY_TECHNOLOGY'];
