<template>
  <div>
    <FormFieldBoolean
      :value="inputValue"
      @input="setInputValue"
    />
    <v-row
      v-show="isValid !== false"
      no-gutters
    >
      <v-col cols="4">
        <div class="spacing">
          <PreviousAnswer :value="inputParentValue | format" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseSchemaFormField from './BaseSchemaFormField';
import FormFieldBoolean from '@/shared/components/ui/atoms/form_fields/FormFieldBoolean';
import PreviousAnswer from '@/shared/components/wizard/PreviousAnswer';

// TBD: this doesnt run any validation

export default {
  name: 'SchemaFormFieldBoolean',
  components: { FormFieldBoolean, PreviousAnswer },
  filters: {
    format(value) {
      if (value === null || value === undefined) return null;
      return value ? 'Yes' : 'No';
    },
  },
  extends: BaseSchemaFormField,
  methods: {
    trackChanges() {
      this.tracking.funnelCardEditedEvent();
    },
  },
};
</script>

<style lang="scss" scoped>
.spacing {
  padding: $space-xsmall 0 $space-xsmall;
}
</style>
