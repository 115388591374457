<template>
  <WithDataSync
    :update-fn="updateFn"
    :submit-path="submitPath"
    :schema-path="schemaPath"
    :application-id="applicationId"
    :on-submit-success="onSubmitSuccess"
  >
    <template slot-scope="{onInputChange, onSubmit, isValidForm}">
      <WithCardData
        :schema-path="schemaPath"
        :card-config-path="cardConfigPath"
      >
        <template slot-scope="{cardData}">
          <BaseWizardPage
            :card-data="cardData"
            :loading="isLoading"
            :is-submit-disabled="isValidForm !== true"
            @submit="withLoading(onSubmit)"
          >
            <WithFormFields
              v-for="(formFieldDataSet, index) in formFieldData"
              :key="formFieldDataSet.guid"
              :index="formFieldDataSet.guid"
              :form-field-data="formFieldDataSet.fields"
              :schema-path="schemaPath"
              class="address-form-group"
            >
              <template slot-scope="{formFields}">
                <div
                  v-if="index > 0"
                  class="text-h6"
                >
                  <h3>Additional business address:</h3>
                </div>
                <div
                  v-if="index > 0"
                  class="description"
                >
                  We only need coworking spaces or commercial offices at this time.  You don't need to include employees working from home.
                </div>
                <span class="field-label">Address Line 1</span>
                <AutoCompleteLocation
                  :form-fields="formFields"
                  :index="index"
                  :on-input-change="onInputChange"
                  :google-maps-loaded="googleMapsLoaded"
                />
                <v-row
                  no-gutters
                  class="button-row d-inline-flex"
                >
                  <v-col
                    v-if="index === formFieldData.length - 1"
                    class="button-col"
                  >
                    <ButtonText
                      class="add-address-button"
                      data-testid="add-address"
                      @click="onAddAddress"
                    >
                      <VouchIcon name="add" />
                      Add another business address
                    </ButtonText>
                  </v-col>
                  <v-col class="button-col">
                    <ButtonText
                      v-if="index > 0"
                      :data-testid="`remove-row-${index}`"
                      @click="onRemove({index, guid: formFieldDataSet.guid})"
                    >
                      <VouchIcon name="clear" />
                      Remove address
                    </ButtonText>
                  </v-col>
                </v-row>
              </template>
            </WithFormFields>
          </BaseWizardPage>
        </template>
      </WithCardData>
    </template>
  </WithDataSync>
</template>

<script>
import BaseCard from '@/shared/components/wizard/BaseCard';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';
import { mapMutations } from 'vuex';
import WithFormFields from '@/shared/components/wizard/WithFormFields';
import WithDataSync from '@/shared/components/wizard/WithDataSync';
import AutoCompleteLocation from '@/shared/views/wizard/core/shared/AutoCompleteLocation';
import Env from '@/shared/services/Env';
import WithCardData from '@/shared/components/wizard/WithCardData';
import {
  additionalAddressFields,
  primaryAddressFields,
} from '@/onboarding/services/address_fields';
import VouchIcon from '@/shared/components/ui/atoms/icons/VouchIcon';
import ButtonText from '@/shared/components/ui/atoms/buttons/ButtonText';
import BaseWizardPage from '@/shared/components/wizard/BaseWizardPage';

export default {
  name: 'AddressCard',
  components: {
    BaseWizardPage,
    ButtonText,
    WithDataSync,
    AutoCompleteLocation,
    VouchIcon,
    WithFormFields,
    WithCardData,
  },
  extends: BaseCard,
  dependencies: ['requests', 'tracking'],
  data() {
    return {
      isLoading: false,
      schemaPath: getSchemaEndpoint('core_location'),
      submitPath: getUpdateEndpoint('core_location'),
      cardConfigPath: '2019-07-01--LOCATION_CONTAINER',
      formFieldData: [primaryAddressFields(this)],
      googleMapsLoaded: false,
    };
  },
  created() {
    const additionalLocations =
      (this.applicationData['2019-07-01--LOCATION_CONTAINER'] || {}).additional_locations || [];
    additionalLocations.forEach(() => this.onAddAddress());
  },
  mounted() {
    const mapsScript = `https://maps.googleapis.com/maps/api/js?key=${
      Env.getConfig().google_maps_api_key
    }&libraries=places`;
    this.$loadScript(mapsScript).then(() => (this.googleMapsLoaded = true)); // eslint-disable-line no-return-assign
  },
  methods: {
    ...mapMutations(['updateApplicationData']),

    updateFn(args) {
      this.requests.updateApplicationData({ ...args, context: this });
    },

    withLoading(fn) {
      this.isLoading = true;
      fn();
    },

    onSubmitSuccess(applicationData) {
      this.isLoading = false;
      this.updateApplicationData({ applicationData });
      this.questionRouter.next(this);
    },

    onAddAddress() {
      this.formFieldData.push(
        additionalAddressFields({
          index: this.formFieldData.length - 1,
          applicationData: this.getApplicationData(),
        })
      );
    },

    onRemove({ index, guid }) {
      this.$store.commit('removeAddress', { index, guid });
      this.formFieldData.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
.description {
  margin-top: $intra-form-spacing;
  margin-bottom: $intra-form-spacing;
}

.address-form-group:not(:last-child) {
  &:not(:first-child) {
    margin-bottom: $card-spacing;
  }
}

.button-row {
  margin-top: $intra-form-spacing;

  & > *:first-child {
    margin-right: $intra-form-spacing;
  }
}

.button-col {
  margin-top: $intra-form-spacing;

  > .add-address-button.v-btn.vouch-button {
    padding: 0;
  }
}

.field-label {
  @include label-text;
}
</style>
