<template>
  <CheckoutPageHeaderBase />
</template>

<script>
import CheckoutPageHeaderBase from './CheckoutHeaderBase';

export default {
  name: 'CheckoutHeaderStandard',
  components: {
    CheckoutPageHeaderBase,
  },
  props: {
    isMobile: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
