/* istanbul ignore file */
// this file is strictly configuration, and aligns with the 'humble object' pattern, so testing isn't necessary.

import {
  hasInsuranceReqsCard,
  hasInsuranceReqsDescCard,
  legalEntityContainerCard,
  yearIncorporatedCard,
  locationContainerCard,
  locationContainerAttributesCard,
  locationContainerAttributesAndAdditionalCard,
  hasBigTicketItemCard,
  bigTicketItemDescCard,
  hasOtherBoardDirectorsCard,
  leadInvestorLastRoundCard,
  founderPreviousRaiseCard,
  percentOwnedNonVcCard,
  prefSharesSalesCard,
  sharesPubliclyListedCard,
  annualRevenueContainerCard,
  numBigRevenueContractsCard,
  totalCashAndEquivalentsCentsCard,
  acquire10MCard,
  has401kCard,
  hasERISACard,
  benefitsWorthCard,
  hasProductInMarketCard,
  offersConsultingCard,
  hasAnyPiiCard,
  piiAmountCard,
  socComplianceCard,
  loginRequiresMfaCard,
  hasEngineeringContractorsCard,
  numEngineeringContractorsCard,
  numInvoluntaryTerminationCard,
  numPlannedInvoluntaryTerminationCard,
  insuranceHistoryContainerCard,
  currentClaimsDescriptionCard,
  deniedCoverageDescriptionCard,
  currentClaimsNumCard,
  hasPendingClaimsCard,
  pendingClaimsDescriptionCard,
  liabilitiesClaimsItemizedContainerCard,
} from '@/shared/routing/questionDecisionsProgram2';

export const coreRoutingDecisions = [
  hasInsuranceReqsCard,
  hasInsuranceReqsDescCard,
  legalEntityContainerCard,
  yearIncorporatedCard,
  locationContainerCard,
  locationContainerAttributesCard,
  locationContainerAttributesAndAdditionalCard,
  hasBigTicketItemCard,
  bigTicketItemDescCard,
  hasOtherBoardDirectorsCard,
  leadInvestorLastRoundCard,
  founderPreviousRaiseCard,
  percentOwnedNonVcCard,
  prefSharesSalesCard,
  sharesPubliclyListedCard,
  annualRevenueContainerCard,
  numBigRevenueContractsCard,
  totalCashAndEquivalentsCentsCard,
  acquire10MCard,
  has401kCard,
  hasERISACard,
  benefitsWorthCard,
  hasProductInMarketCard,
  offersConsultingCard,
  hasAnyPiiCard,
  piiAmountCard,
  socComplianceCard,
  loginRequiresMfaCard,
  hasEngineeringContractorsCard,
  numEngineeringContractorsCard,
  numInvoluntaryTerminationCard,
  numPlannedInvoluntaryTerminationCard,
  insuranceHistoryContainerCard,
  currentClaimsDescriptionCard,
  deniedCoverageDescriptionCard,
  currentClaimsNumCard,
  liabilitiesClaimsItemizedContainerCard,
  hasPendingClaimsCard,
  pendingClaimsDescriptionCard,
];
