<script>
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import SchemaFormFieldBigMoney from '@/onboarding/components/formfields/SchemaFormFieldBigMoney';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';

export default {
  name: 'WizardCardCoreAnnualRevenue',
  components: { SchemaFormFieldBigMoney },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('core'),
      submitPath: getUpdateEndpoint('core'),
      cardConfigPath: '2019-07-01--ANNUAL_REVENUE_CONTAINER',
      formFieldData: [
        {
          component: SchemaFormFieldBigMoney,
          schemaPath: '2019-07-01--ANNUAL_REVENUE_CURRENT_CENTS',
          dataPath: '2019-07-01--ANNUAL_REVENUE_CONTAINER.2019-07-01--ANNUAL_REVENUE_CURRENT_CENTS',
          warning: this.warnSmallAmount,
        },
        {
          component: SchemaFormFieldBigMoney,
          schemaPath: '2019-07-01--ANNUAL_REVENUE_NEXT_YEAR_CENTS',
          dataPath:
            '2019-07-01--ANNUAL_REVENUE_CONTAINER.2019-07-01--ANNUAL_REVENUE_NEXT_YEAR_CENTS',
          warning: this.warnSmallAmount,
        },
      ],
    };
  },
  methods: {
    warnSmallAmount: revenueInCents => {
      return revenueInCents < 1000_00 ? 'Are you sure? Revenue is less than $1,000.' : '';
    },
  },
};
</script>
