import { TopLevelDomains } from '@/shared/constants/TopLevelDomains';

// This service as it stands is a temporary solution to a larger problem.
// See https://vouchinc.atlassian.net/browse/IA-372 for notes regarding next
// steps for this service.

const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

export const validateEmailAddress = email => {
  if (!emailRegEx.test(email)) {
    return false;
  }

  const tldRegEx = /^.*?.([^.]+)$/;
  const tldMatch = email.match(tldRegEx);
  const emailTld = tldMatch[1].toUpperCase();
  return TopLevelDomains.includes(emailTld);
};
