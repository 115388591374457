<template>
  <div>
    <PriceTag
      class="price-tag-v-space"
      amount="$0.00"
      :color="specialOfferTextColor"
      frequency="paid today"
    />
    <div
      class="detail-text"
    >
      {{ paymentAmount | longCurrency }} per year, due when you raise
    </div>
  </div>
</template>

<script>
import { longCurrency } from '@/shared/lib/filters/currency_filters';
import PriceTag from '@/shared/components/ui/molecules/PriceTag';
import Colors from '@/Colors';

export default {
  name: 'CheckoutFormSectionPricePayWhenYouRaise',
  components: { PriceTag },
  filters: { longCurrency },
  props: {
    paymentAmount: {
      type: Number,
      required: true,
    },
    paymentFrequencyString: {
      type: String,
      required: true,
    },
    monthlyPayment: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    specialOfferTextColor: () => Colors.specialOfferTextColor,
  },
};
</script>

<style lang="scss" scoped>
.price-tag-v-space {
  margin-bottom: $space-small;
}
</style>
