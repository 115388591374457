<template>
  <v-progress-circular
    v-bind="$attrs"
    indeterminate
    :width="2"
    :size="15"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'IconLoading',
};
</script>
