<template>
  <PageBannerPromotion
    class="svbc-banner"
    data-testid="banner-svb-credit"
    :white-text="false"
    :small="small"
    title="SVB LENDING OFFER AVAILABLE"
    :subtitle="subtitle"
  />
</template>

<script>
import PageBannerPromotion from '@/onboarding/components/page_banners/PageBannerPromotion';
import { dataShareOptIn } from '@/onboarding/services/page_banners/banner_helpers';

export default {
  name: 'SVBCreditSolutionsBanner',
  components: { PageBannerPromotion },
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    applicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      acceptedDataShare: false,
    };
  },
  computed: {
    subtitle() {
      return `Save up to ${
        this.acceptedDataShare ? '10' : '5'
      }% by purchasing the Insurance Requirements Package.`;
    },
  },
  async beforeMount() {
    this.acceptedDataShare = await dataShareOptIn({ applicationId: this.applicationId });
  },
};
</script>

<style lang="scss" scoped>
.svbc-banner {
  background: $partner-banner-color;
  animation-duration: $banner-animation-duration;
}
</style>
