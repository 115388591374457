import SchemaFormHiddenField from '@/onboarding/components/formfields/SchemaFormHiddenField.vue';
import SchemaFormNoopField from '@/onboarding/components/formfields/SchemaFormNoOpField.vue';
import { getKeyGuid } from '@/onboarding/services/keyGuid';
import { get } from '@/shared/lib/vouch_dash';
import SchemaFormFieldText from '@/onboarding/components/formfields/SchemaFormFieldText.vue';
import SchemaFormFieldAutocomplete from '@/onboarding/components/formfields/SchemaFormFieldAutocomplete.vue';
import SchemaFormFieldNumber from '@/onboarding/components/formfields/SchemaFormFieldNumber.vue';

const additionalFields = (applicationData, dataPath, isPrimary) => {
  if (get(applicationData, `${dataPath}.2019-07-01--LOCATION_TYPE`)) {
    const locationTypeSchemaPath = isPrimary
      ? '2019-07-01--LOCATION_TYPE_PRIMARY'
      : '2019-07-01--LOCATION_TYPE_ADDITIONAL';
    return [
      {
        component: SchemaFormNoopField,
        dataPath: `${dataPath}.2019-07-01--LOCATION_TYPE`,
        name: '2019-07-01--LOCATION_TYPE',
        schemaPath: locationTypeSchemaPath,
      },
      {
        component: SchemaFormNoopField,
        dataPath: `${dataPath}.2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS`,
        name: '2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS',
        schemaPath: '2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS',
      },
      {
        component: SchemaFormNoopField,
        dataPath: `${dataPath}.2019-07-01--LOCATION_ASSET_RANGE`,
        name: '2019-07-01--LOCATION_ASSET_RANGE',
        schemaPath: '2019-07-01--LOCATION_ASSET_RANGE',
      },
    ];
  }
  return [];
};

export const additionalAddressFields = ({ index, applicationData }) => {
  const base = `2019-07-01--LOCATION_CONTAINER.additional_locations.${index}`;
  return {
    guid: getKeyGuid(),
    fields: [
      {
        component: SchemaFormHiddenField,
        name: '2019-07-01--ADDRESS_LINE_1',
        schemaPath: '2019-07-01--ADDRESS_LINE_1',
        dataPath: `${base}.2019-07-01--ADDRESS_LINE_1`,
      },
      {
        component: SchemaFormFieldText,
        name: '2019-07-01--ADDRESS_LINE_2',
        schemaPath: '2019-07-01--ADDRESS_LINE_2',
        dataPath: `${base}.2019-07-01--ADDRESS_LINE_2`,
      },
      {
        component: SchemaFormHiddenField,
        name: '2019-07-01--ADDRESS_CITY',
        schemaPath: '2019-07-01--ADDRESS_CITY',
        dataPath: `${base}.2019-07-01--ADDRESS_CITY`,
      },
      {
        component: SchemaFormHiddenField,
        name: '2019-07-01--ADDRESS_COUNTY',
        schemaPath: '2019-07-01--ADDRESS_COUNTY',
        dataPath: `${base}.2019-07-01--ADDRESS_COUNTY`,
      },
      {
        component: SchemaFormHiddenField,
        name: '2019-07-01--ADDRESS_STATE',
        schemaPath: '2019-07-01--ADDRESS_STATE',
        dataPath: `${base}.2019-07-01--ADDRESS_STATE`,
      },
      {
        component: SchemaFormHiddenField,
        name: '2019-07-01--ADDRESS_POSTAL_CODE',
        schemaPath: '2019-07-01--ADDRESS_POSTAL_CODE',
        dataPath: `${base}.2019-07-01--ADDRESS_POSTAL_CODE`,
      },
      {
        component: SchemaFormNoopField,
        name: 'location_id',
        schemaPath: 'location_id',
        dataPath: `${base}.location_id`,
      },
      ...additionalFields(
        applicationData,
        `2019-07-01--LOCATION_CONTAINER.additional_locations.${index}`,
        false
      ),
    ],
  };
};

export const primaryAddressFields = context => ({
  guid: getKeyGuid(),
  fields: [
    {
      component: SchemaFormHiddenField,
      dataPath: '2019-07-01--LOCATION_CONTAINER.2019-07-01--ADDRESS_LINE_1',
      name: '2019-07-01--ADDRESS_LINE_1',
      schemaPath: '2019-07-01--ADDRESS_LINE_1',
    },
    {
      component: SchemaFormFieldText,
      dataPath: '2019-07-01--LOCATION_CONTAINER.2019-07-01--ADDRESS_LINE_2',
      name: '2019-07-01--ADDRESS_LINE_2',
      schemaPath: '2019-07-01--ADDRESS_LINE_2',
    },
    {
      component: SchemaFormHiddenField,
      dataPath: '2019-07-01--LOCATION_CONTAINER.2019-07-01--ADDRESS_CITY',
      name: '2019-07-01--ADDRESS_CITY',
      schemaPath: '2019-07-01--ADDRESS_CITY',
    },
    {
      component: SchemaFormHiddenField,
      dataPath: '2019-07-01--LOCATION_CONTAINER.2019-07-01--ADDRESS_COUNTY',
      name: '2019-07-01--ADDRESS_COUNTY',
      schemaPath: '2019-07-01--ADDRESS_COUNTY',
    },
    {
      component: SchemaFormHiddenField,
      dataPath: '2019-07-01--LOCATION_CONTAINER.2019-07-01--ADDRESS_STATE',
      name: '2019-07-01--ADDRESS_STATE',
      schemaPath: '2019-07-01--ADDRESS_STATE',
    },
    {
      component: SchemaFormNoopField,
      dataPath: '2019-07-01--LOCATION_CONTAINER.2019-07-01--ADDRESS_POSTAL_CODE',
      name: '2019-07-01--ADDRESS_POSTAL_CODE',
      schemaPath: '2019-07-01--ADDRESS_POSTAL_CODE',
    },
    {
      component: SchemaFormNoopField,
      dataPath: '2019-07-01--LOCATION_CONTAINER.location_id',
      name: 'location_id',
      schemaPath: 'location_id',
    },
    ...additionalFields(context.getApplicationData(), ['2019-07-01--LOCATION_CONTAINER'], true),
  ],
});

export const additionalAddressAttributesFields = context => {
  const base = `2019-07-01--LOCATION_CONTAINER.additional_locations.${context.additionalLocationCount}`;
  return [
    {
      component: SchemaFormFieldAutocomplete,
      name: '2019-07-01--LOCATION_TYPE',
      schemaPath: '2019-07-01--LOCATION_TYPE_ADDITIONAL',
      dataPath: `${base}.2019-07-01--LOCATION_TYPE`,
    },
    {
      component: SchemaFormFieldNumber,
      name: '2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS',
      schemaPath: '2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS',
      dataPath: `${base}.2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS`,
    },
    {
      component: SchemaFormFieldAutocomplete,
      name: '2019-07-01--LOCATION_ASSET_RANGE',
      schemaPath: '2019-07-01--LOCATION_ASSET_RANGE',
      dataPath: `${base}.2019-07-01--LOCATION_ASSET_RANGE`,
    },
    {
      component: SchemaFormNoopField,
      name: '2019-07-01--ADDRESS_LINE_1',
      schemaPath: '2019-07-01--ADDRESS_LINE_1',
      dataPath: `${base}.2019-07-01--ADDRESS_LINE_1`,
    },
    {
      component: SchemaFormNoopField,
      name: '2019-07-01--ADDRESS_LINE_2',
      schemaPath: '2019-07-01--ADDRESS_LINE_2',
      dataPath: `${base}.2019-07-01--ADDRESS_LINE_2`,
    },
    {
      component: SchemaFormNoopField,
      name: '2019-07-01--ADDRESS_CITY',
      schemaPath: '2019-07-01--ADDRESS_CITY',
      dataPath: `${base}.2019-07-01--ADDRESS_CITY`,
    },
    {
      component: SchemaFormNoopField,
      name: '2019-07-01--ADDRESS_COUNTY',
      schemaPath: '2019-07-01--ADDRESS_COUNTY',
      dataPath: `${base}.2019-07-01--ADDRESS_COUNTY`,
    },
    {
      component: SchemaFormNoopField,
      name: '2019-07-01--ADDRESS_STATE',
      schemaPath: '2019-07-01--ADDRESS_STATE',
      dataPath: `${base}.2019-07-01--ADDRESS_STATE`,
    },
    {
      component: SchemaFormNoopField,
      name: '2019-07-01--ADDRESS_POSTAL_CODE',
      schemaPath: '2019-07-01--ADDRESS_POSTAL_CODE',
      dataPath: `${base}.2019-07-01--ADDRESS_POSTAL_CODE`,
    },
    {
      component: SchemaFormNoopField,
      name: 'location_id',
      schemaPath: 'location_id',
      dataPath: `${base}.location_id`,
    },
  ];
};

export const primaryAddressAttributesFields = () => [
  {
    component: SchemaFormFieldAutocomplete,
    name: '2019-07-01--LOCATION_TYPE',
    schemaPath: '2019-07-01--LOCATION_TYPE_PRIMARY',
    dataPath: '2019-07-01--LOCATION_CONTAINER.2019-07-01--LOCATION_TYPE',
  },
  {
    component: SchemaFormFieldNumber,
    name: '2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS',
    schemaPath: '2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS',
    dataPath: '2019-07-01--LOCATION_CONTAINER.2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS',
  },
  {
    component: SchemaFormFieldAutocomplete,
    name: '2019-07-01--LOCATION_ASSET_RANGE',
    schemaPath: '2019-07-01--LOCATION_ASSET_RANGE',
    dataPath: '2019-07-01--LOCATION_CONTAINER.2019-07-01--LOCATION_ASSET_RANGE',
  },
  {
    component: SchemaFormNoopField,
    name: '2019-07-01--ADDRESS_LINE_1',
    schemaPath: '2019-07-01--ADDRESS_LINE_1',
    dataPath: '2019-07-01--LOCATION_CONTAINER.2019-07-01--ADDRESS_LINE_1',
  },
  {
    component: SchemaFormNoopField,
    name: '2019-07-01--ADDRESS_LINE_2',
    schemaPath: '2019-07-01--ADDRESS_LINE_2',
    dataPath: '2019-07-01--LOCATION_CONTAINER.2019-07-01--ADDRESS_LINE_2',
  },
  {
    component: SchemaFormNoopField,
    name: '2019-07-01--ADDRESS_CITY',
    schemaPath: '2019-07-01--ADDRESS_CITY',
    dataPath: '2019-07-01--LOCATION_CONTAINER.2019-07-01--ADDRESS_CITY',
  },
  {
    component: SchemaFormNoopField,
    name: '2019-07-01--ADDRESS_COUNTY',
    schemaPath: '2019-07-01--ADDRESS_COUNTY',
    dataPath: '2019-07-01--LOCATION_CONTAINER.2019-07-01--ADDRESS_COUNTY',
  },
  {
    component: SchemaFormNoopField,
    name: '2019-07-01--ADDRESS_STATE',
    schemaPath: '2019-07-01--ADDRESS_STATE',
    dataPath: '2019-07-01--LOCATION_CONTAINER.2019-07-01--ADDRESS_STATE',
  },
  {
    component: SchemaFormNoopField,
    name: '2019-07-01--ADDRESS_POSTAL_CODE',
    schemaPath: '2019-07-01--ADDRESS_POSTAL_CODE',
    dataPath: '2019-07-01--LOCATION_CONTAINER.2019-07-01--ADDRESS_POSTAL_CODE',
  },
  {
    component: SchemaFormNoopField,
    name: 'location_id',
    schemaPath: 'location_id',
    dataPath: '2019-07-01--LOCATION_CONTAINER.location_id',
  },
];

export const addressFields = addressIndex => {
  const isPrimary = addressIndex === 0;
  const addressPath = isPrimary
    ? `2019-07-01--LOCATION_CONTAINER`
    : `2019-07-01--LOCATION_CONTAINER.additional_locations.${addressIndex - 1}`;

  return [
    {
      component: SchemaFormNoopField,
      name: 'location_id',
      schemaPath: 'location_id',
      dataPath: `${addressPath}.location_id`,
    },
    {
      component: SchemaFormHiddenField,
      name: '2019-07-01--ADDRESS_LINE_1',
      schemaPath: '2019-07-01--ADDRESS_LINE_1',
      dataPath: `${addressPath}.2019-07-01--ADDRESS_LINE_1`,
    },
    {
      component: SchemaFormFieldText,
      name: '2019-07-01--ADDRESS_LINE_2',
      schemaPath: '2019-07-01--ADDRESS_LINE_2',
      dataPath: `${addressPath}.2019-07-01--ADDRESS_LINE_2`,
    },
    {
      component: SchemaFormHiddenField,
      name: '2019-07-01--ADDRESS_CITY',
      schemaPath: '2019-07-01--ADDRESS_CITY',
      dataPath: `${addressPath}.2019-07-01--ADDRESS_CITY`,
    },
    {
      component: SchemaFormHiddenField,
      name: '2019-07-01--ADDRESS_COUNTY',
      schemaPath: '2019-07-01--ADDRESS_COUNTY',
      dataPath: `${addressPath}.2019-07-01--ADDRESS_COUNTY`,
    },
    {
      component: SchemaFormHiddenField,
      name: '2019-07-01--ADDRESS_STATE',
      schemaPath: '2019-07-01--ADDRESS_STATE',
      dataPath: `${addressPath}.2019-07-01--ADDRESS_STATE`,
    },
    {
      component: SchemaFormHiddenField,
      name: '2019-07-01--ADDRESS_POSTAL_CODE',
      schemaPath: '2019-07-01--ADDRESS_POSTAL_CODE',
      dataPath: `${addressPath}.2019-07-01--ADDRESS_POSTAL_CODE`,
    },
    {
      component: SchemaFormFieldAutocomplete,
      name: '2019-07-01--LOCATION_TYPE',
      schemaPath: isPrimary
        ? '2019-07-01--LOCATION_TYPE_PRIMARY'
        : '2019-07-01--LOCATION_TYPE_ADDITIONAL',
      dataPath: `${addressPath}.2019-07-01--LOCATION_TYPE`,
    },
    {
      component: SchemaFormFieldNumber,
      name: '2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS',
      schemaPath: '2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS',
      dataPath: `${addressPath}.2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS`,
    },
    {
      component: SchemaFormFieldAutocomplete,
      name: '2019-07-01--LOCATION_ASSET_RANGE',
      schemaPath: '2019-07-01--LOCATION_ASSET_RANGE',
      dataPath: `${addressPath}.2019-07-01--LOCATION_ASSET_RANGE`,
    },
  ];
};
