import Vue from 'vue';

const withConditionSwitch = conditionEvaluator => EnabledComponent => DisabledComponent =>
  Vue.extend({
    name: 'WithConditionSwitch',
    functional: true,
    render(h, context) {
      // context: FunctionalRenderContext
      // context.parent: VueComponent
      const condition = conditionEvaluator(context.parent);
      if (condition) {
        return h(EnabledComponent, {
          attrs: context.props,
          on: context.listeners,
        });
      }

      return h(DisabledComponent, {
        attrs: context.props,
        on: context.listeners,
      });
    },
  });

export default withConditionSwitch;
