<script>
export default {
  name: 'QuestionCardConfigFrame',
  props: {
    cardId: {
      type: String,
      required: true,
    },
    funnelConfig: {
      type: Object,
      required: true,
    },
    timeTotal: {
      type: Number,
      required: true,
    },
  },
  computed: {
    cardConfig() {
      return this.funnelConfig[this.cardId];
    },
    hasBackButton() {
      return this.cardConfig.hasBackButton;
    },
    percentComplete() {
      return this.cardConfig.percent;
    },
    minutesLeft() {
      const computedTime = ((100 - this.percentComplete) / 100) * this.timeTotal;

      return Math.max(1, computedTime);
    },
    currentNavStep() {
      return this.cardConfig.step;
    },
  },
  render() {
    return this.$scopedSlots.default({
      hasBackButton: this.hasBackButton,
      percentComplete: this.percentComplete,
      minutesLeft: this.minutesLeft,
      currentNavStep: this.currentNavStep,
    });
  },
};
</script>
