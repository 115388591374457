<template>
  <div class="faq">
    <div
      class="faq-container"
      :class="{mobile: isMobile}"
    >
      <h2 class="faq-heading">
        Billing FAQ
      </h2>
      <v-row no-gutters>
        <v-col
          v-for="(FAQ, index) in FAQs"
          :key="index"
          md="4"
          class="q-and-a"
        >
          <div>
            <div>Q</div>
            <p>
              {{ FAQ.question }}
            </p>
          </div>
          <div>
            <div>A</div>
            <p>
              {{ FAQ.answer }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQs',
  props: {
    isMobile: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      FAQs: [
        {
          question: 'Why do I have to pay two months up front?',
          answer:
            'Insurance is like a gym membership, you pay for the upcoming month before it begins. Insurance has special requirements that we need to follow if we need to cancel because of nonpayment (for example if your credit card expires) so we ask for 2 months up front to avoid frustration with monthly billing.',
        },
        {
          question: 'Will I be sent an email invoice each month?',
          answer:
            'No, we will attempt to charge your existing payment method on file each month until the end of the policy term, unless otherwise cancelled. You will receive a receipt for each monthly payment via email.',
        },
        {
          question: 'Why is my last payment slightly different than my other monthly payments?',
          answer:
            'Insurance premiums are typically rounded to the nearest dollar. Though, insurance premiums aren’t always cleanly divisible by 12. We take the fractional cents per month and add them to the last monthly payment.',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.faq-container {
  padding: $space-xlarge $space-large 0;
  margin: auto;
  &.mobile {
    padding-left: 0;
    padding-right: 0;

    > .row {
      flex-direction: column;
    }
  }
  .faq-heading {
    @include h4-text;
    text-align: center;
    padding-bottom: $space-large;
  }
  .q-and-a {
    > div {
      display: flex;
      > div:first-child {
        @include detail-text;
        font-weight: bold;
      }
    }
    p {
      padding: 0 $space-medium;

      @include detail-text;
    }
  }
}
</style>
