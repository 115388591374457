<template>
  <ButtonBase
    color="primary"
    v-bind="$attrs"
    class="button-primary"
    v-on="$listeners"
  >
    <slot />
  </ButtonBase>
</template>

<script>
import ButtonBase from './ButtonBase';

export default {
  name: 'ButtonPrimary',
  components: { ButtonBase },
};
</script>

<style lang="scss" scoped>
.button-primary.vouch-button.vouch-button.vouch-button.vouch-button {
  margin-right: 25px;
  padding: 0 24px;
  &:hover {
    background-color: $button-primary-background-color--hover !important;
    box-shadow: 0 2px 3px $button-primary-box-shadow-color--hover !important;
  }
}
</style>
