<template>
  <v-stepper-step
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-stepper-step>
</template>

<script>
export default {
  name: 'StepperStep',
};
</script>
