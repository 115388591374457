<script>
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import SchemaFormFieldBoolean from '@/onboarding/components/formfields/SchemaFormFieldBoolean';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';

export default {
  name: 'WizardCardPrequalHasInsurance',
  components: { SchemaFormFieldBoolean },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('niche'),
      submitPath: getUpdateEndpoint('niche'),
      cardConfigPath: '2019-07-01--HAS_ROBOTICS_BUSINESS',
      formFieldData: [
        {
          component: SchemaFormFieldBoolean,
          dataPath: '2019-07-01--HAS_ROBOTICS_BUSINESS',
          schemaPath: '2019-07-01--HAS_ROBOTICS_BUSINESS',
        },
      ],
    };
  },
};
</script>
