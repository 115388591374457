<template>
  <GenericStatusPage
    :image="image"
    title="Congratulations, You've chosen your insurance"
  >
    <template
      v-if="$scopedSlots['banner-content']"
      v-slot:banner-content
    >
      <slot name="banner-content" />
    </template>

    <template v-slot:description-content>
      <div v-if="steps.covered.completed">
        <div class="subtitle">
          Your insurance is active!
        </div>
        <div>
          <LinkExternal
            data-testid="link-to-members"
            :href="memberPortalLocation"
          >Head Over</LinkExternal>
          to our member portal to see your policy documents.
        </div>
      </div>
      <div v-else>
        <div class="subtitle">
          Here are your next steps:
        </div>
        <StepperRoot
          :vertical="true"
          :value="activeStep"
        >
          <StepperStep
            :complete="steps.shop.completed"
            :step="steps.shop.id"
            :editable="true"
            edit-icon="$complete"
          >
            Choose the right insurance for you.
          </StepperStep>
          <StepperContent
            :step="steps.shop.id"
            class="step-content"
          >
            <v-progress-circular
              v-if="loading"
              indeterminate
              size="20"
              align="inherit"
            />
            <p v-else>
              Did you make a mistake? Our insurance advisors can help you adjust your coverages and
              limits!
            </p>
          </StepperContent>

          <StepperStep
            :complete="steps.sign.completed"
            :step="steps.sign.id"
            :editable="true"
            edit-icon="$complete"
          >
            Sign for your quote.
          </StepperStep>

          <StepperContent
            :step="steps.sign.id"
            class="step-content"
          >
            We've sent an email from Dropbox Sign to the
            <b>Officer</b> you designated on the previous page. Your coverage is not active until
            this is signed.
          </StepperContent>

          <StepperStep
            :complete="steps.charge.completed"
            :step="steps.charge.id"
            :editable="steps.sign.completed"
            edit-icon="$complete"
          >
            We charge the payment method you provided.
          </StepperStep>
          <StepperContent
            :step="steps.charge.id"
            class="step-content"
          >
            We'll charge the payment method provided, and you'll receive a confirmation email and access to your Vouch Account. Payments via ACH can take up to 5 business days to finish processing.
          </StepperContent>

          <StepperStep
            :complete="steps.covered.completed"
            :step="steps.covered.id"
          >
            You're covered with Vouch!
          </StepperStep>
          <StepperContent
            v-if="placements.length"
            :step="steps.covered.id"
            class="step-content"
          />

          <StepperStep
            v-if="placements.length"
            :complete="steps.covered.completed"
            :step="5"
            :editable="true"
            edit-icon="$complete"
            data-testid="placements-step"
          >
            Check your email for next steps.
          </StepperStep>
          <StepperContent
            :step="5"
            class="step-content"
            data-testid="placements-step-content"
          >
            You'll receive an email on how to sign up for the additional coverages you requested
            from our partners. These coverages are:
            <ul data-testid="placements">
              <li
                v-for="(item, index) in placements"
                :key="index"
              >
                {{ humanizedPlacements[item] }}
              </li>
            </ul>
          </StepperContent>
        </StepperRoot>
      </div>
    </template>
  </GenericStatusPage>
</template>

<script>
import GenericStatusPage from '@/shared/views/layouts/GenericStatusPage';
import {
  getApplicationData,
  getSelectedPackageData,
} from '@/onboarding/lib/selectors/storeSelectors';
import { sendToReactQuotePage } from '@/onboarding/router/routeHelper';
import LinkExternal from '@/shared/components/ui/atoms/LinkExternal';
import StepperContent from '@/shared/components/ui/atoms/stepper/StepperContent';
import StepperStep from '@/shared/components/ui/atoms/stepper/StepperStep';
import StepperRoot from '@/shared/components/ui/atoms/stepper/StepperRoot';
import { get } from '@/shared/lib/vouch_dash';

export default {
  name: 'OfficerSignature',
  components: {
    StepperRoot,
    StepperStep,
    StepperContent,
    LinkExternal,
    GenericStatusPage,
  },
  dependencies: ['requests'],
  props: {
    applicationId: {
      type: String,
      required: true,
    },
    packageSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      stepper: 2,
      // TODO replace with new image (brand refresh)
      image: '',
      humanizedPlacements: {
        key_person: 'Key Person',
        workers_compensation: 'Workers Compensation',
      },
      memberPortalLocation: '/members',
    };
  },
  computed: {
    applicationData() {
      return getApplicationData(this);
    },
    placements() {
      const partnered_coverages =
        get(
          getSelectedPackageData({ $store: this.$store, packageSlug: this.packageSlug }),
          'partnered_coverages',
          {}
        ) || {};
      return Object.entries(partnered_coverages).reduce((previousValue, currentValue) => {
        const [type, requested] = currentValue;
        if (requested) {
          previousValue.push(type);
        }
        return previousValue;
      }, []);
    },
    steps() {
      return {
        shop: {
          id: 1,
          completed: !!this.applicationData.quote_accepted_at,
        },
        sign: {
          id: 2,
          completed: !!this.applicationData.signed_at,
        },
        charge: {
          id: 3,
          completed: !!this.applicationData.is_paid,
        },
        covered: {
          id: 4,
          completed: !!this.applicationData.is_completed,
        },
      };
    },
    activeStep() {
      const idLastCompletedStep = Object.values(this.steps)
        .sort((a, b) => b.id - a.id)
        .find(step => step.completed)?.id;
      const idNextStep = idLastCompletedStep ? idLastCompletedStep + 1 : 1;
      return idNextStep;
    },
  },
  created() {
    if (!this.applicationData.quote_accepted_at) {
      this.gotoPackagePage();
    }
  },
  methods: {
    gotoPackagePage() {
      sendToReactQuotePage();
    },
  },
};
</script>

<style lang="scss" scoped>
.step-content {
  text-align: left;
}

.subtitle {
  @include h3-text;
  margin-bottom: $space-medium;
}
</style>
