<script>
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';
import SchemaFormFieldAutocomplete from '../../../../../onboarding/components/formfields/SchemaFormFieldAutocomplete';

export default {
  name: 'PIIAmountCard',
  components: { SchemaFormFieldAutocomplete },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('core'),
      submitPath: getUpdateEndpoint('core'),
      cardConfigPath: '2019-07-01--PII_AMOUNT',
      formFieldData: [
        {
          component: SchemaFormFieldAutocomplete,
          schemaPath: '2019-07-01--PII_AMOUNT',
          dataPath: '2019-07-01--PII_AMOUNT',
        },
      ],
    };
  },
};
</script>
