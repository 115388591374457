<template>
  <span />
</template>

<script>
import BaseSchemaFormField from './BaseSchemaFormField';

// Use this class if you have a field that:
//  - should not be shown
//  - but will have data being changed, so you should have
//  you
// Potential use case: some other component is controlling the
// UI, and feeding data through.

export default {
  name: 'SchemaFormHiddenField',
  extends: BaseSchemaFormField,
};
</script>
