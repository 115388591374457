<template>
  <WithDataSync
    :update-fn="updateFn"
    :submit-path="submitPath"
    :schema-path="schemaPath"
    :application-id="applicationId"
    :on-submit-success="onSubmitSuccess"
  >
    <template slot-scope="{onInputChange, onSubmit}">
      <BaseWizardPage
        :card-data="{}"
        :loading="isLoading"
        :is-submit-disabled="!cardDataIsValid"
        :submit-text="submitText"
        @submit="handleSubmit(onSubmit)"
      >
        <WithFormFields
          :key="currentAddressIndex"
          :form-field-data="formFieldData"
          :schema-path="schemaPath"
        >
          <template slot-scope="{formFields}">
            <h1
              data-testid="card-title"
              class="card-title"
            >
              {{ cardTitle }}
            </h1>
            <CardFields
              :form-fields="formFields.slice(...sliceIndicesForAttributeFields)"
              :on-input-change="onInputChange"
              class="form-group"
            />
            <div
              v-if="nextAddressExists"
            >
              <div class="next-address-subtitle text-h6">
                <h3>Additional business address:</h3>
              </div>
              <div class="description">
                We only need coworking spaces or commercial offices at this time.  You don't need to include employees working from home.
              </div>
              <span class="field-label">Address Line 1</span>
              <AutoCompleteLocation
                :form-fields="formFields.slice(...sliceIndicesForAddressFields)"
                :index="currentAddressIndex + 1"
                :on-input-change="onInputChange"
              />
            </div>
            <v-row
              no-gutters
              class="button-row d-inline-flex"
            >
              <v-col
                v-if="!nextAddressExists"
                class="button-col"
              >
                <ButtonText
                  class="address-buttons"
                  data-testid="add-address"
                  @click="onAddAddress"
                >
                  <VouchIcon name="add" />
                  Add another business address
                </ButtonText>
              </v-col>
              <v-col
                v-if="nextAddressExists"
                class="button-col"
              >
                <ButtonText
                  class="address-buttons"
                  data-testid="remove-additional-address"
                  @click="onRemoveAddress"
                >
                  <VouchIcon name="clear" />
                  Remove address
                </ButtonText>
              </v-col>
            </v-row>
          </template>
        </WithFormFields>
      </BaseWizardPage>
    </template>
  </WithDataSync>
</template>

<script>
import BaseCard from '@/shared/components/wizard/BaseCard';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';
import CardFields from '@/shared/views/wizard/core/shared/CardFields';
import { mapGetters, mapMutations } from 'vuex';
import AutoCompleteLocation from '@/shared/views/wizard/core/shared/AutoCompleteLocation';
import SchemaFormFieldAutocomplete from '@/onboarding/components/formfields/SchemaFormFieldAutocomplete';
import SchemaFormNoopField from '@/onboarding/components/formfields/SchemaFormNoOpField';
import SchemaFormFieldNumber from '@/onboarding/components/formfields/SchemaFormFieldNumber';
import WithFormFields from '@/shared/components/wizard/WithFormFields';
import WithDataSync from '@/shared/components/wizard/WithDataSync';
import { addressFields } from '@/onboarding/services/address_fields';
import VouchIcon from '@/shared/components/ui/atoms/icons/VouchIcon';
import ButtonText from '@/shared/components/ui/atoms/buttons/ButtonText';
import BaseWizardPage from '@/shared/components/wizard/BaseWizardPage';
import { NumFieldsPerLocObject } from '@/onboarding/constants/Constants';
import { get } from 'lodash';
import { shouldRedirectBackToReview } from '@/onboarding/router/routeHelper';

const LOCATION_ADDR_KEYS = [
  '2019-07-01--ADDRESS_CITY',
  '2019-07-01--ADDRESS_COUNTY',
  '2019-07-01--ADDRESS_LINE_1',
  '2019-07-01--ADDRESS_LINE_2',
  '2019-07-01--ADDRESS_POSTAL_CODE',
  '2019-07-01--ADDRESS_STATE',
];
const LOCATION_ATTR_KEYS = [
  '2019-07-01--LOCATION_ASSET_RANGE',
  '2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS',
  '2019-07-01--LOCATION_TYPE',
];

export default {
  name: 'AddressAttributesAndAdditionalCard',
  components: {
    AutoCompleteLocation,
    BaseWizardPage,
    ButtonText,
    VouchIcon,
    CardFields,
    // eslint-disable-next-line
      SchemaFormFieldNumber, SchemaFormFieldAutocomplete, SchemaFormNoopField,
    WithDataSync,
    WithFormFields,
  },
  extends: BaseCard,
  dependencies: ['requests'],
  data() {
    return {
      currentAddressIndex: 0,
      formFieldData: [],
      isLoading: false,
      schemaPath: getSchemaEndpoint('core_location'),
      submitPath: getUpdateEndpoint('core_location'),
      cardConfigPath: '2019-07-01--LOCATION_CONTAINER',
    };
  },
  computed: {
    ...mapGetters(['getSubmitDataValidity']),
    submitText() {
      if (shouldRedirectBackToReview(this) && !this.nextAddressExists) {
        return 'Return to Review';
      }
      return 'Next';
    },
    numLocations() {
      return Math.floor(this.formFieldData.length / NumFieldsPerLocObject);
    },
    sliceIndicesForAttributeFields() {
      const start = 0;
      const end = NumFieldsPerLocObject;
      const addend = NumFieldsPerLocObject * this.currentAddressIndex;
      return [addend + start, addend + end];
    },
    sliceIndicesForAddressFields() {
      const start = 0;
      const end = 7;
      const addend = NumFieldsPerLocObject * (this.currentAddressIndex + 1);
      return [addend + start, addend + end];
    },
    nextAddressExists() {
      return this.numLocations > this.currentAddressIndex + 1;
    },
    cardTitle() {
      const addressLabel =
        this.currentAddressIndex === 0
          ? this.applicationData['2019-07-01--LOCATION_CONTAINER']['2019-07-01--ADDRESS_LINE_1']
          : this.applicationData['2019-07-01--LOCATION_CONTAINER'].additional_locations[
              this.currentAddressIndex - 1
            ]['2019-07-01--ADDRESS_LINE_1'];
      return `Tell us about ${addressLabel}`;
    },
    cardDataIsValid() {
      // Override default 'getSubmitDataValidity' logic to compensate that we split addresses across cards
      const currentLocationDataValidity =
        this.currentAddressIndex === 0
          ? this.getSubmitDataValidity['2019-07-01--LOCATION_CONTAINER']
          : this.getSubmitDataValidity['2019-07-01--LOCATION_CONTAINER']?.additional_locations?.[
              this.currentAddressIndex - 1
            ];

      const additionalLocationDataValidity = get(
        this.getSubmitDataValidity,
        ['2019-07-01--LOCATION_CONTAINER', 'additional_locations', this.currentAddressIndex],
        []
      );

      const attributesAreValid = LOCATION_ATTR_KEYS.reduce(
        (acc, key) => acc && currentLocationDataValidity?.[key],
        true
      );

      const additionalLocationIsValid =
        !this.nextAddressExists ||
        LOCATION_ADDR_KEYS.reduce(
          (acc, key) =>
            key in additionalLocationDataValidity
              ? acc && additionalLocationDataValidity[key]
              : true,
          true
        );

      return attributesAreValid && additionalLocationIsValid;
    },
  },
  created() {
    this.initFormFieldData();
  },
  mounted() {
    this.$root.$on('address-card-step-back', cardComponent => {
      this.handleBackButton(cardComponent);
    });

    // Init submitData with all additional locations, not just those displayed
    const appDataAdditionalLocs = get(
      this.applicationData,
      ['2019-07-01--LOCATION_CONTAINER', 'additional_locations'],
      []
    );

    this.patchSubmitData({
      value: appDataAdditionalLocs.map(loc => loc),
      dataPath: '2019-07-01--LOCATION_CONTAINER.additional_locations',
    });
  },
  beforeDestroy() {
    this.$root.$off('address-card-step-back');
  },
  methods: {
    ...mapMutations([
      'clearEmptyAddrAttributeValidity',
      'removeAddressV2',
      'updateApplicationData',
      'patchSubmitData',
    ]),
    updateFn(args) {
      this.requests.updateApplicationData({ ...args, context: this });
    },
    initFormFieldData() {
      // Add fields for the primary address
      const fieldList = addressFields(0);
      // Add fields for all addresses in the additional_locations
      const additionalLocations = get(
        this.applicationData,
        ['2019-07-01--LOCATION_CONTAINER', 'additional_locations'],
        []
      );
      additionalLocations.forEach((location, i) => fieldList.push(...addressFields(i + 1)));

      this.formFieldData = fieldList;
    },
    onAddAddress() {
      this.formFieldData = [...this.formFieldData, ...addressFields(this.currentAddressIndex + 1)];
    },
    onRemoveAddress() {
      // Update application dataa
      const additionalLocations = get(
        this.applicationData,
        ['2019-07-01--LOCATION_CONTAINER', 'additional_locations'],
        []
      );
      additionalLocations.splice(this.currentAddressIndex, 1);

      // Update local form field data
      this.formFieldData = [];
      this.initFormFieldData();

      this.removeAddressV2({ additionalLocIndex: this.currentAddressIndex });
    },
    handleSubmit(submitFn) {
      this.isLoading = true;
      submitFn();
    },
    onSubmitSuccess(applicationData) {
      this.isLoading = false;
      this.updateApplicationData({ applicationData });
      if (this.nextAddressExists) {
        // Update card to display attribute questions for additional address
        ++this.currentAddressIndex;
      } else if (shouldRedirectBackToReview(this)) {
        this.questionRouter.goto({ cardId: 'review', component: this });
      } else {
        this.questionRouter.next(this);
      }
    },
    handleBackButton(fakeCardComponent) {
      if (this.currentAddressIndex === 0) {
        this.questionRouter.previous(fakeCardComponent);
      } else {
        this.clearEmptyAddrAttributeValidity(this.currentAddressIndex);
        --this.currentAddressIndex;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  @include question-title-text;
  margin-bottom: $space-medium;
}

.field-label {
  @include label-text;
}

// Address field list is defined in src/onboarding/services/address_fields.ts
// (reminder: nth-child is not zero-based)
$index-of-line2-field: 3;
// Hide the Address Line 2 field
.form-group::v-deep li:nth-child(#{$index-of-line2-field}) {
  display: none;
}

.next-address-subtitle {
  margin-top: $card-spacing;
}

.description {
  margin-top: $intra-form-spacing;
  margin-bottom: $intra-form-spacing;
}

.button-row {
  & > *:first-child {
    margin-right: $intra-form-spacing;
  }
}

.button-col {
  margin-top: $intra-form-spacing;

  > .address-buttons.v-btn.vouch-button {
    padding: 0;
  }
}
</style>
