import { fullAddress, stateNameMap } from './addressSelectors';
import { FunnelStore } from '@/onboarding/types/internal/storeTypes';
import { ApplicationVersions, StateShortName } from '@/onboarding/types/internal/applicationTypes';
import { get } from '@/shared/lib/vouch_dash';

const getApplicationRoot = ($store: FunnelStore) => $store.state.application;
export const isApplicationLocked = ($store: FunnelStore) => $store.getters.isApplicationLocked;
export const getPackagesData = ({ $store }: { $store: FunnelStore }) =>
  getApplicationRoot($store).packagesData;
export const getOtherPackageSlug = ({
  $store,
  packageSlug,
}: {
  $store: FunnelStore;
  packageSlug: string;
}) =>
  Object.keys(getApplicationRoot($store).packagesData.packages).find(slug => slug !== packageSlug);

export const getSelectedPackageData = ({
  $store,
  packageSlug,
}: {
  $store: FunnelStore;
  packageSlug: any;
}) => getPackagesData({ $store }).packages[packageSlug];

export const getApplicationData = ({ $store }: { $store: FunnelStore }) =>
  getApplicationRoot($store).applicationData;
export const getApplicationParentData = ({ $store }: { $store: FunnelStore }) =>
  getApplicationRoot($store).applicationParentData;
export const getDecisionData = ({ $store }: { $store: FunnelStore }) =>
  getApplicationRoot($store).decisionData;
export const getPreCoreDecisionData = ({ $store }: { $store: FunnelStore }) =>
  getApplicationRoot($store).preCoreDecisionData;

export const isStateInitialized = ({ $store }: { $store: FunnelStore }) =>
  getApplicationRoot($store).applicationId;

export const eligibleForPartnerDiscounts = ({ $store }: { $store: FunnelStore }) =>
  get(getApplicationData({ $store }), 'eligible_for_partner_discounts', true);
export const getApplicationVersion = ({ $store }: { $store: FunnelStore }) =>
  getApplicationData({ $store }).sha_onboarding_funnel;
export const getSpecificApplicationVersion = ({
  $store,
  applicationId,
}: {
  $store: any;
  applicationId;
}) => {
  let app = get($store, 'state.member.applications', []).find(({ id }) => id === applicationId);
  app = app || getApplicationData({ $store });
  return app.sha_onboarding_funnel;
};

export const isWindowRenewal = () => {
  const path = window.location.pathname;
  if (path.includes('/renewal/') || path.includes('/renew/')) {
    return true;
  }
  return false;
};

export const isWindowProgram2 = () => {
  const path = window.location.pathname;
  if (path.includes('/renewal/') || path.includes('/application/')) {
    return true;
  }
  return false;
};

export const applicationIsRenewal = ({ $store }: { $store: FunnelStore }) => {
  return getApplicationData({ $store })?.renewal?.is_renewal_application;
};

export const applicationIsProgram2 = (
  { $store }: { $store: FunnelStore },
  applicationId?: string
) => {
  let value;
  if (applicationId) {
    value = getSpecificApplicationVersion({ $store, applicationId });
  } else {
    value = getApplicationVersion({ $store });
  }
  if (!value) {
    value = isWindowProgram2() ? ApplicationVersions.program2 : ApplicationVersions.program1;
  }
  return value === ApplicationVersions.program2;
};

export const getCompanyName = ({ $store }: { $store: FunnelStore }) =>
  getApplicationData({ $store })['2019-07-01--FRIENDLY_ENTITY_NAME'];
export const getApplicantEmail = ({ $store }: { $store: FunnelStore }) =>
  getApplicationData({ $store })?.['2019-07-01--EMAIL'];
export const getEmailConfirmed = ({ $store }: { $store: FunnelStore }) =>
  getApplicationData({ $store })?.email_confirmed;
export const getApplicationUpgradeState = ({ $store }: { $store: FunnelStore }) =>
  getApplicationData({ $store })?.is_upgrade;
export const getCompanyHqState = ({ $store }: { $store: FunnelStore }) =>
  getApplicationData({ $store })['2019-07-01--HQ_PREQUAL_LOCATION'];
export const getFullAddressState = ({ $store }: { $store: FunnelStore }) => {
  const location = getApplicationData({ $store })['2019-07-01--LOCATION_CONTAINER'];
  if (!location) {
    return '';
  }
  const state = location['2019-07-01--ADDRESS_STATE'] as StateShortName;
  return stateNameMap[state];
};

const createPrimaryAddressFormatter = (applicationGetter: any) => ({
  $store,
}: {
  $store: FunnelStore;
}) => {
  const location = applicationGetter({ $store })['2019-07-01--LOCATION_CONTAINER'];
  if (!location) {
    return null;
  }

  const address1 = location['2019-07-01--ADDRESS_LINE_1'];
  const address2 = location['2019-07-01--ADDRESS_LINE_2'];
  const city = location['2019-07-01--ADDRESS_CITY'];
  const state = location['2019-07-01--ADDRESS_STATE'];
  const postalCode = location['2019-07-01--ADDRESS_POSTAL_CODE'];

  if (address1 === null) return '';

  return fullAddress({
    address1,
    address2,
    city,
    state,
    postalCode,
  });
};

export const getFormattedPrimaryAddress = createPrimaryAddressFormatter(getApplicationData);
export const getFormattedParentPrimaryAddress = createPrimaryAddressFormatter(
  getApplicationParentData
);

export const createAdditionalAddressFormatter = (applicationGetter: any) => (
  { $store }: { $store: FunnelStore },
  index: number,
  defaultValue: string | null = null
) => {
  const locationContainer = applicationGetter({ $store })['2019-07-01--LOCATION_CONTAINER'];
  if (!locationContainer) return null;

  const additionalLocations = locationContainer?.additional_locations ?? [];
  const additionalLocation = additionalLocations[index] ?? null;

  if (!additionalLocation) {
    return defaultValue;
  }

  const address1 = additionalLocation['2019-07-01--ADDRESS_LINE_1'];
  const address2 = additionalLocation['2019-07-01--ADDRESS_LINE_2'];
  const city = additionalLocation['2019-07-01--ADDRESS_CITY'];
  const state = additionalLocation['2019-07-01--ADDRESS_STATE'];
  const postalCode = additionalLocation['2019-07-01--ADDRESS_POSTAL_CODE'];

  if (!address1) {
    return defaultValue;
  }
  return fullAddress({
    address1,
    address2,
    city,
    state,
    postalCode,
  });
};

export const getFormattedAdditionalAddress = createAdditionalAddressFormatter(getApplicationData);
export const getFormattedParentAdditionalAddress = createAdditionalAddressFormatter(
  getApplicationParentData
);
