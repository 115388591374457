import featureFlagging from '@/onboarding/services/FeatureFlaggingService';
import { CHAT_WIDGET_CLICKED } from '@/onboarding/services/SegmentEventTypes';

export const showChatIfEnabled = ({ tracking }) => {
  tracking.sendEvent(CHAT_WIDGET_CLICKED, {
    path: window.location.href.split('?')[0],
    openViaLink: true,
  });

  if (typeof window.zE === 'function') {
    window.zE('messenger', 'open');
  }
};

export default {
  showChatIfEnabled,
};
