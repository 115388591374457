<template>
  <v-container
    fluid
    class="wizard-page"
    :class="{'wizard-page-tiny-screen': $vuetify.breakpoint.xs}"
  >
    <v-row
      no-gutters
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="8"
        xl="6"
      >
        <v-row
          no-gutters
          class="align-left"
        >
          <v-col
            cols="12"
            class="back-button-container"
          >
            <transition name="rise">
              <BackButton
                v-if="hasBackButton"
                class="back-button"
              />
            </transition>
          </v-col>
        </v-row>
        <VouchCard class="card">
          <slot />
        </VouchCard>
      </v-col>
    </v-row>
    <div>
      <slot name="below-card-content" />
    </div>
    <v-footer
      color="transparent"
      absolute
    >
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <p class="footer-text">
          Your responses will be kept confidential in accordance with our
          <a href="https://www.vouch.us/legal/terms-of-use">Terms of Use</a>
          and
          <a href="https://www.vouch.us/legal/privacy-notice">Privacy Notice</a>.
        </p>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import BackButton from '@/onboarding/components/BackButton';
import VouchCard from '@/shared/components/ui/atoms/VouchCard';

export default {
  name: 'LayoutCenteredCard',
  components: {
    VouchCard,
    BackButton,
  },
  props: {
    hasBackButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.wizard-page {
  margin: $card-vertical-margin 0;
  padding: 0 $space-medium;

  .back-button-container {
    position: relative;
  }

  .back-button {
    position: relative;

    &:focus {
      outline: none;
    }
  }

  a {
    color: unset;
    text-decoration: none;
  }

  .card {
    min-height: $card-height;
    background: $card-background-color;
  }
}
.wizard-page-tiny-screen {
  margin: $space-medium 0 100px;
}

.footer-text {
  @include detail-text;
  text-align: center;
}

.rise-enter-active,
.rise-leave-active {
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease;
  transform: translateY(0);
}

.rise-enter,
.rise-leave-active {
  transform: translateY(100%);
}
</style>
