<template>
  <div>
    <ButtonBase
      :class="'boolean-button ' + (value === false ? 'faded-text' : '')"
      :color="value === true ? 'mint' : 'default'"
      @click="$emit('input', true)"
    >
      Yes
    </ButtonBase>
    <ButtonBase
      :class="'boolean-button ' + (value === true ? 'faded-text' : '')"
      :color="value === false ? 'mint' : 'default'"
      @click="$emit('input', false)"
    >
      No
    </ButtonBase>
  </div>
</template>

<script>
import ButtonBase from '@/shared/components/ui/atoms/buttons/ButtonBase';

export default {
  name: 'FormFieldBoolean',
  components: { ButtonBase },
  props: {
    value: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.boolean-button {
  min-width: $boolean-button-width !important;
  min-height: $boolean-button-height;
  padding: 0 $boolean-button-padding !important;
  margin: $space-xsmall $space-small $space-xsmall 0;

  &:hover,
  &:focus {
    background-color: $boolean-button-background-color--hover !important;
  }

  &.faded-text {
    color: $vouch-inactive-text !important;
  }
}
</style>
