import Env from '@/shared/services/Env';
import featureFlagging from '../services/FeatureFlaggingService';

/**
 * Hard switch to turn off the new funnel. If this is not enabled all users
 * should be sent to the old funnel regardless of previous bucketing. This takes
 * precedence over the query param overrides.
 */
export const newFunnelEnabled = (): Promise<boolean> => {
  return featureFlagging.getFlagWhenReady({
    flag: 'new-funnel-rollout',
  });
};

/**
 * Hard switch to turn off the renewal new funnel. If this is not enabled all users
 * should be sent to the old funnel regardless of previous bucketing.
 */
export const newRenewalFunnelEnabled = (): Promise<boolean> => {
  return featureFlagging.getFlagWhenReady({
    flag: 'intake-client-renewals-rollout',
  });
};

export const routeToNewFunnel = (applicationId: string = '') => {
  const currentUrl = new URL(window.location.href);

  const params = new URLSearchParams();
  const passedParams = [
    'offer_code',
    'partner',
    'utm_content',
    'utm_name',
    'utm_source',
    'utm_term',
    'utm_medium',
    'utm_campaign',
    'referrer_id',
    'account_id',
    'opportunity_id',
  ];

  if (applicationId) {
    params.set('legacyApplicationId', applicationId);
  }

  passedParams.forEach(param_name => {
    if (currentUrl.searchParams.has(param_name)) {
      params.set(param_name, currentUrl.searchParams.get(param_name) || '');
    }
  });

  const paramsString = params.toString();

  const IntakeClientUrl = Env.getConfig().intake_client_url;

  window.location.assign(`${IntakeClientUrl}${paramsString ? `?${paramsString}` : ''}`);
};
