<template>
  <div>
    <PriceTag
      class="price-tag-v-space"
      :amount="paymentAmount | longCurrency"
      :frequency="paymentFrequencyString"
    />

    <div class="payment-description">
      <div v-if="isMonthly">
        <p class="description-text">
          <span
            v-if="!showTransactionFeeText"
            class="mr-sm"
          >(including {{ totalInitialTaxesAndFees | longCurrency }} in taxes and fees)</span>
          <span
            v-if="showTransactionFeeText"
            class="mr-sm"
          >
            The above total includes {{ totalInitialTaxesAndFees | longCurrency }} in taxes and fees
            of which {{ transactionFeesAmount | longCurrency }} are transaction fees.
          </span>
          <span class="info-icon">
            <CheckoutFormTaxesTooltip
              :show-taxes-and-fees="showTaxesAndFees"
              :taxes="restMonthlyTaxesAmount || initialMonthlyTaxesAmount"
              :fees="restMonthlyFeesAmount || initialMonthlyFeesAmount"
            />
          </span>
        </p>
      </div>
      <div
        v-if="monthlyPayment"
        class="detail-text"
      >
        <span v-if="!showTransactionFeeText">
          Followed by 10 monthly payments of {{ monthlyPayment | longCurrency }} including
          {{ totalRestTaxesAndFees | longCurrency }} in taxes and fees</span>
        <span v-if="showTransactionFeeText">
          Followed by 10 monthly payments of {{ totalMonthlyAmount | longCurrency }} of which
          {{ monthlyTransactionFees | longCurrency }} are transaction fees.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { longCurrency } from '@/shared/lib/filters/currency_filters';
import PriceTag from '@/shared/components/ui/molecules/PriceTag';
import CheckoutFormTaxesTooltip from './CheckoutFormTaxesTooltip';

const total = arr => {
  const reducer = (acc, el) => acc + el.amount_cents;
  return arr.reduce(reducer, 0);
};

export default {
  name: 'CheckoutFormSectionPrice',
  components: {
    CheckoutFormTaxesTooltip,
    PriceTag,
  },
  dependencies: ['featureFlags'],
  filters: { longCurrency },
  props: {
    paymentAmount: {
      type: Number,
      required: true,
    },
    paymentFrequencyString: {
      type: String,
      required: true,
    },
    monthlyPayment: {
      type: Number,
      required: false,
      default: 0,
    },
    restMonthlyTaxes: {
      type: Array,
      required: false,
      default: () => [],
    },
    restMonthlyFees: {
      type: Array,
      required: false,
      default: () => [],
    },
    initialMonthlyTaxes: {
      type: Array,
      required: false,
      default: () => [],
    },
    initialMonthlyFees: {
      type: Array,
      required: false,
      default: () => [],
    },
    transactionFeesAmount: {
      type: Number,
      required: false,
      default: 0,
    },
    monthlyTransactionFees: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    totalRestTaxesAndFees() {
      return this.restMonthlyFeesAmount + this.restMonthlyTaxesAmount;
    },
    restMonthlyFeesAmount() {
      return total(this.restMonthlyFees);
    },
    restMonthlyTaxesAmount() {
      return total(this.restMonthlyTaxes);
    },
    isMonthly() {
      return this.monthlyPayment !== 0;
    },
    showTaxesAndFees() {
      return this.totalInitialTaxesAndFees !== 0;
    },
    totalInitialTaxesAndFees() {
      return (
        this.initialMonthlyFeesAmount + this.initialMonthlyTaxesAmount + this.transactionFeesAmount
      );
    },
    initialMonthlyFeesAmount() {
      return total(this.initialMonthlyFees);
    },
    initialMonthlyTaxesAmount() {
      return total(this.initialMonthlyTaxes);
    },
    totalMonthlyAmount() {
      return this.monthlyPayment + this.monthlyTransactionFees;
    },
    showTransactionFeeText() {
      return (
        this.featureFlags.getFlag({ flag: 'charge-transaction-fees' }) &&
        this.transactionFeesAmount > 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.price-tag-v-space {
  margin-bottom: $space-small;
}

.detail-text {
  @include detail-text;
  color: $vouch-placeholder;
}

.payment-description {
  @include detail-text;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mr-sm {
  margin-right: $space-small;
}

.description-text {
  display: flex;
  align-items: center;
}

.info-icon {
  margin-top: -2px;
}
</style>
