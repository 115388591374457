<template>
  <ButtonBase
    v-bind="$attrs"
    class="button-outline"
    v-on="$listeners"
  >
    <slot />
  </ButtonBase>
</template>

<style lang="scss" scoped>
.button-outline.button-outline.button-outline.button-outline {
  background-color: transparent !important;
  border: 1px solid $button-outline-border-color;
  margin-right: 25px;
  padding: 0 24px;

  box-shadow: none !important;

  &:disabled {
    border-color: $button-outline-border-color--disabled;
  }

  &.next-button .vouch-icon {
    margin-left: 11px;
    display: inline-block;
    transform: rotate(180deg);
  }
}
</style>

<script>
import ButtonBase from './ButtonBase';

export default {
  name: 'ButtonOutline',
  components: { ButtonBase },
};
</script>
