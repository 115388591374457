import Vue from 'vue';
import Vuex from 'vuex';
import onboardingModules from '@/onboarding/store';
import membersModules from '@/members/store';
import notification from './modules/notification';
import review from './modules/review';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ...onboardingModules,
    ...membersModules,
    notification,
    review,
  },
});
