<template>
  <ButtonBase
    :color="buttonColor"
    v-bind="$attrs"
    class="button-primary"
    depressed
    v-on="$listeners"
  >
    <slot />
  </ButtonBase>
</template>

<script>
import ButtonBase from './ButtonBase';
import Colors from '@/Colors';

export default {
  name: 'ButtonSpecial',
  components: { ButtonBase },
  computed: {
    buttonColor() {
      return Colors.buttonSpecialColor;
    },
  },
};
</script>
