<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      no-gutters
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="6"
        lg="6"
      >
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'BaseAuthPage',
};
</script>

<style lang="scss" scoped>
.auth-page-content {
  padding: $space-large;
}
</style>
