<template>
  <CardDataSync
    :key="dataSyncKey"
    :submit-path="submitPath"
    :schema-path="schemaPath"
    :card-data="cardData"
    :card-config-path="cardConfigPath"
    :form-field-data="formFieldData"
    :application-id="applicationId"
    :update-fn="updateFn"
    :on-submit-success="onSubmitSuccess"
  >
    <template slot-scope="{ onSubmit, isValidForm, formFields, onInputChange, cardData }">
      <BaseWizardPage
        v-if="shouldShowCard"
        :loading="isLoading"
        :submit-text="submitText"
        :card-data="cardData"
        :is-submit-disabled="isValidForm !== true"
        :page-component="this"
        @submit="onSubmit"
      >
        <CardFields
          v-if="formFields.length > 1"
          :form-fields="formFields"
          :on-input-change="onInputChange"
        />
        <SingleCardField
          v-else-if="formFields.length === 1"
          :form-fields="formFields"
          :on-input-change="onInputChange"
        />
      </BaseWizardPage>
    </template>
  </CardDataSync>
</template>
<script>
import BaseCard from './BaseCard';
import CardFields from '@/shared/views/wizard/core/shared/CardFields';
import CardDataSync from './CardDataSync';
import SingleCardField from '@/shared/views/wizard/core/shared/SingleCardField';
import { mapMutations } from 'vuex';
import { shouldRedirectBackToReview } from '@/onboarding/router/routeHelper';
import BaseWizardPage from '@/shared/components/wizard/BaseWizardPage';

/**
 * Extend from this class to create a card that is interactive (i.e. gets schema data from the backend and then
 * sends data back) but also where you don't need a special layout -- this just vomits out the form fields one
 * after another, which is adequate for many of our cards.
 */

export default {
  name: 'BaseCardWithFields',
  components: {
    BaseWizardPage,
    CardDataSync,
    CardFields,
    SingleCardField,
  },
  extends: BaseCard,
  dependencies: ['requests'],
  data() {
    return {
      // Subclasses CAN override.
      isLoading: false,
      dataSyncKey: 0,

      // override if you need to coordinate multiple requests
      // before you can let the user interact.
      shouldShowCard: true,

      // Subclasses MUST override
      submitPath: '',
      schemaPath: 'definitions',
      cardConfigPath: '',
      formFieldData: [],
    };
  },
  computed: {
    submitText() {
      if (shouldRedirectBackToReview(this)) {
        return 'Return to Review';
      }
      return 'Next';
    },
  },
  methods: {
    ...mapMutations(['updateApplicationData']),

    updateFn(options) {
      this.isLoading = true;
      this.requests.updateApplicationData({ ...options, context: this });
    },

    onSubmitSuccess(applicationData) {
      this.isLoading = false;
      this.updateApplicationData({ applicationData });
      if (shouldRedirectBackToReview(this)) {
        this.questionRouter.goto({ cardId: 'review', component: this });
      } else {
        this.questionRouter.next(this);
      }
    },
  },
};
</script>
