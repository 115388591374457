import { autoYes } from '@/shared/routing/routingDecisionHelpers';
import { ApplicationDecisions } from '@/onboarding/constants/Constants';
import {
  numFullTimeWorkersCard,
  capitalRaisedContainerCard,
  hasInsuranceReqsCard,
  hasInsuranceReqsDescCard,
  locationContainerCard,
  locationContainerAttributesCard,
  hasBigTicketItemCard,
  bigTicketItemDescCard,
  hasOtherBoardDirectorsCard,
  leadInvestorLastRoundCard,
  founderPreviousRaiseCard,
  percentOwnedNonVcCard,
  prefSharesSalesCard,
  sharesPubliclyListedCard,
  annualRevenueContainerCard,
  numBigRevenueContractsCard,
  totalCashAndEquivalentsCentsCard,
  acquire10MCard,
  has401kCard,
  hasERISACard,
  benefitsWorthCard,
  hasProductInMarketCard,
  offersConsultingCard,
  hasAnyPiiCard,
  piiAmountCard,
  socComplianceCard,
  loginRequiresMfaCard,
  hasEngineeringContractorsCard,
  numEngineeringContractorsCard,
  numInvoluntaryTerminationCard,
  numPlannedInvoluntaryTerminationCard,
  insuranceHistoryContainerCard,
  currentClaimsDescriptionCard,
  deniedCoverageDescriptionCard,
  currentClaimsNumCard,
  hasPendingClaimsCard,
  pendingClaimsDescriptionCard,
  liabilitiesClaimsItemizedContainerCard,
  locationContainerAttributesAndAdditionalCard,
} from '@/shared/routing/questionDecisionsProgram2';
import { generateRoutingDecisions, getAllNicheQuestions } from '@/onboarding2/niche.domain';
import { supplementalRoutingDecisions } from '@/onboarding/services/routing_decisions_program_2/supplementalRoutingDecisions';

const satisfiedOnLockedAndYesDecision = ({ applicationData, decisionData }) =>
  applicationData.is_locked && decisionData.decision === ApplicationDecisions.yesDecision;

const renewalStartCard = {
  id: 'renewal-start',
  isSatisfied: autoYes,
  shouldRender: autoYes,
};

const renewalEndCard = {
  id: 'renewal-end',
  isSatisfied: autoYes,
  shouldRender: autoYes,
};

const reviewCard = {
  id: 'review',
  isSatisfied: satisfiedOnLockedAndYesDecision,
  shouldRender: autoYes,
};

const applicantCard = {
  id: 'applicant',
  isSatisfied: satisfiedOnLockedAndYesDecision,
  shouldRender: autoYes,
};

export const routingDecisionsProgram2 = [
  renewalStartCard,
  // hasInsuranceSelfCard - skipping!
  numFullTimeWorkersCard,
  capitalRaisedContainerCard,
  ...generateRoutingDecisions(getAllNicheQuestions()),

  // Core questions
  // legalEntityContainerCard,
  // yearIncorporatedCard,
  hasInsuranceReqsCard,
  hasInsuranceReqsDescCard,
  locationContainerCard,
  locationContainerAttributesCard,
  locationContainerAttributesAndAdditionalCard,
  hasBigTicketItemCard,
  bigTicketItemDescCard,
  hasOtherBoardDirectorsCard,
  leadInvestorLastRoundCard,
  founderPreviousRaiseCard,
  percentOwnedNonVcCard,
  prefSharesSalesCard,
  sharesPubliclyListedCard,
  annualRevenueContainerCard,
  numBigRevenueContractsCard,
  totalCashAndEquivalentsCentsCard,
  acquire10MCard,
  has401kCard,
  hasERISACard,
  benefitsWorthCard,
  hasProductInMarketCard,
  offersConsultingCard,
  hasAnyPiiCard,
  piiAmountCard,
  socComplianceCard,
  loginRequiresMfaCard,
  hasEngineeringContractorsCard,
  numEngineeringContractorsCard,
  numInvoluntaryTerminationCard,
  numPlannedInvoluntaryTerminationCard,
  insuranceHistoryContainerCard,
  currentClaimsDescriptionCard,
  deniedCoverageDescriptionCard,
  currentClaimsNumCard,
  liabilitiesClaimsItemizedContainerCard,
  hasPendingClaimsCard,
  pendingClaimsDescriptionCard,
  ...supplementalRoutingDecisions,

  reviewCard,
  applicantCard,
  renewalEndCard,
];
