<script>
// I listen to window scroll events and tell components in my slot
// when the window is scrolled past the given threshold
export default {
  name: 'ScrollThresholdListener',
  props: {
    threshold: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isPastThreshold: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      const checkThreshold = () => {
        const currentScrollPosition = window.scrollY || window.pageYOffset;
        this.isPastThreshold = currentScrollPosition > this.threshold;
      };

      if (this.animationFrame) {
        cancelAnimationFrame(this.animationFrame);
      }

      this.animationFrame = requestAnimationFrame(checkThreshold);
    },
  },
  render() {
    return this.$scopedSlots.default({
      isPastThreshold: this.isPastThreshold,
    });
  },
};
</script>
