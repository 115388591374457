<template>
  <GenericStatusPage
    title=""
  >
    <template v-slot:primary-reason-content>
      <span class="verify-page-title">{{ messageStrings.headerText }}</span>
    </template>
    <template v-slot:description-content>
      <div v-if="isNewlyRegistered">
        <p>Please click the link in the email we sent to <span class="user-email">{{ email }}</span> to verify your account.</p>
        <p>Don't see an email from advisors@vouch.us?</p>
      </div>

      <div v-if="isVerificationRequired">
        <p>Please click the link in the email we sent to <span class="user-email">{{ email }}</span> to verify your account.</p>
        <p>Don't see an email from advisors@vouch.us?</p>
      </div>

      <div v-if="isEmailLinkExpired">
        Log in to trigger an email to <span class="user-email">{{ email }}</span> with a new verification link.
      </div>
    </template>
    <template v-slot:status-button>
      <div class="verification-buttons">
        <ButtonPrimary
          v-if="isEmailLinkExpired"
          data-testid="email-verification-login-button"
          @click.native="handleLoginClick"
        >
          Log in
        </ButtonPrimary>
        <ButtonPrimary
          class="verification-button"
          @click="handleChatClick"
        >
          Talk to an advisor
        </ButtonPrimary>
      </div>
    </template>
  </GenericStatusPage>
</template>

<script>
import GenericStatusPage from '@/shared/views/layouts/GenericStatusPage';
import ButtonPrimary from '@/shared/components/ui/atoms/buttons/ButtonPrimary';
import { showChatIfEnabled } from '@/onboarding/services/chatHelper';

export default {
  name: 'EmailVerification',
  dependencies: ['tracking'],
  components: { ButtonPrimary, GenericStatusPage },
  props: {
    email: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      default: 'verificationRequired',
    },
  },
  data() {
    return {
      messageStringsMap: {
        newlyRegistered: {
          headerText: 'Verify your account',
        },
        verificationRequired: {
          headerText: 'Verify your account',
        },
        emailLinkExpired: {
          headerText: 'Your link has expired',
        },
      },
    };
  },
  computed: {
    messageStrings() {
      return this.messageStringsMap[this.code];
    },
    isVerificationRequired() {
      return this.code === 'verificationRequired';
    },
    isNewlyRegistered() {
      return this.code === 'newlyRegistered';
    },
    isEmailLinkExpired() {
      return this.code === 'emailLinkExpired';
    },
  },
  methods: {
    handleLoginClick() {
      if (this.$auth.isAuthenticated) {
        // Log out and return to login screen
        this.$auth.logout({ logoutUrl: `${window.location.origin}/loginportal/send` });
      } else {
        this.$auth.showLogin();
      }
    },
    handleChatClick() {
      showChatIfEnabled({ tracking: this.tracking });
    },
  },
};
</script>

<style lang="scss" scoped>
.verify-page-title {
  font-size: $space-medium;
}

.user-email {
  font-weight: bold;
}

.verification-buttons {
  display: flex;
  justify-content: space-around;
  align-content: space-between;
  flex-flow: row wrap;
}
</style>
