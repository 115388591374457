<template>
  <v-select
    :value="value"
    :error="error"
    :error-messages="errorMessage"
    :items="items"
    :menu-props="{ bottom: true, offsetY: true }"
    multiple
    filled
    chips
    deletable-chips
    class="v-multiselect--container"
    @input="handleInput"
  >
    <template v-slot:item="data">
      <div class="v-list-item__content">
        <div class="v-list-item__title multi-select-item">
          <span v-if="itemDescription(data.item)"><b>{{ data.item }}:</b> {{ itemDescription(data.item) }}</span>
          <span v-else>{{ data.item }}</span>
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
import { isEmpty } from 'lodash';

export default {
  name: 'FormFieldMultiSelect',
  props: {
    value: {
      type: Array,
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    error: {
      type: Boolean,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    multiSelectDescriptions: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
    itemDescription(itemValue) {
      if (!this.multiSelectDescriptions || isEmpty(this.multiSelectDescriptions)) {
        return null;
      }

      return this.multiSelectDescriptions[itemValue];
    },
  },
};
</script>

<style lang="scss">
.v-multiselect--container {
  .v-menu__content {
    max-width: 40%;
  }

  .multi-select-item,
  .v-chip .v-chip__content {
    white-space: normal;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }

  .v-chip.v-size--default {
    height: 100%;
  }
}
</style>
