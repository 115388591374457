<template>
  <div>
    <v-container>
      <v-row
        justify="center"
        align-content="center"
      >
        <v-col
          align-self="center"
          cols="auto"
          class="text-center"
        >
          <VouchLoadingArcs
            class="vouch-arcs-loader"
            data-testid="interstitial-animation"
          />
          <h1
            class="font-weight-bold"
            data-testid="auth-card-title"
          >
            {{ config.message }}
          </h1>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Colors from '@/Colors';
import VouchLoadingArcs from '@/shared/assets/images/Vouch-load-spinner.svg';

export const EMAIL_VERIFIED = 'email_verified';
export const EMAIL_ALREADY_VERIFIED = 'email_already_verified';
export const BACK_TO_APPLICATION = 'back_to_application';

const pathConfig = {
  [EMAIL_VERIFIED]: {
    message: 'Email Verified!',
  },
  [EMAIL_ALREADY_VERIFIED]: {
    message: 'Email Already Verified',
  },
  [BACK_TO_APPLICATION]: {
    message: 'Back to your application...',
  },
};

export default {
  name: 'InterstitialPage',
  components: { VouchLoadingArcs },
  props: {
    next: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: pathConfig[BACK_TO_APPLICATION].message,
    },
    dontReroute: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    config() {
      return (
        pathConfig[this.$route.query.action] || { message: this.message } ||
        pathConfig[BACK_TO_APPLICATION]
      );
    },
    backgroundColor() {
      return Colors.lightBackgroundColor;
    },
  },
  watch: {
    next: (newPath, _oldPath) => {
      window.vueRoot.$router.replace(newPath);
    },
  },
};
</script>

<style lang="scss" scoped>
.vouch-arcs-loader {
  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
