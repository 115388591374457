import BaseAuthPage from '@/onboarding/components/auth/BaseAuthPage.vue';
import RerouteOnAuth from '@/onboarding/views/auth/RerouteOnAuth.vue';
import EmailVerification from '@/onboarding/views/auth/EmailVerification.vue';
import EmailVerified from '@/onboarding/views/auth/EmailVerified.vue';
import Auth0LoginCallback from '@/onboarding/views/auth/Auth0LoginCallback.vue';
import AuthError from '@/onboarding/views/auth/AuthError.vue';
import PageNotFound from '@/onboarding/views/dead_ends/PageNotFound.vue';
import OnboardingQuestionBase from '@/onboarding/router/OnboardingQuestionBase.vue';
import OnboardingQuoteBase from '@/onboarding/router/OnboardingQuoteBase.vue';
import OnboardingWizardBase from '@/onboarding/router/OnboardingWizardBase.vue';
import Madlib from '@/onboarding/views/madlib/Madlib.vue';
import CrumbsPage from '@/onboarding/views/dead_ends/CrumbsPage.vue';
import questionCardConfig from '@/onboarding/router/questionCardConfig';
import questionCardConfigProgram2 from '@/onboarding/router/questionCardConfigProgram2';
import ConnectSVBPage from '@/onboarding/components/svb/ConnectSVBPage.vue';
import AcceptCartaStandardDataShare from '@/onboarding/components/carta/AcceptCartaStandardDataShare.vue';
import BillingSelectionPage from '@/onboarding/views/wizard/card/checkout/BillingSelectionPage.vue';
import CheckoutPage from '@/onboarding/views/wizard/card/checkout/CheckoutPage.vue';
import FinPage from '@/onboarding/views/dead_ends/FinPage.vue';
import QuoteRequestReviewDiscretion from '@/onboarding/views/discretion-views/QuoteRequestReview.vue';
import QuoteBlockingDiscretion from '@/onboarding/views/discretion-views/QuoteBlocking.vue';
import QuoteBlockingRenewalDiscretion from '@/onboarding/views/discretion-views/QuoteBlockingRenewal.vue';
import QuoteRejectionDiscretion from '@/onboarding/views/discretion-views/QuoteBlockingRejection.vue';
import DelayedPayment from '@/onboarding/views/dead_ends/DelayedPayment.vue';
import OfficerSignaturePage from '@/onboarding/views/dead_ends/OfficerSignaturePage.vue';
import TransitionWithBackgroundBase from '@/onboarding/router/TransitionWithBackgroundBase.vue';
import NicheQuestionCardBase from '@/onboarding2/NicheQuestionCardBase.vue';
import { authGuard, initAuthBeforeEnter } from '@/shared/routing/authGuard';
import {
  selectivelyApplyAuthGuard,
  selectivelyApplyAuthGuardProgram2,
  sendToReactQuotePage,
} from '@/onboarding/router/routeHelper';
import Logout from '@/onboarding/views/auth/Logout.vue';

const extractProps = route => ({ ...route.query, ...route.params });

const onboardingRoutes = [
  {
    path: '/getquote',
    props: extractProps,
    component: OnboardingQuoteBase,
    beforeEnter: authGuard,
    children: [
      {
        path: 'billing',
        component: BillingSelectionPage,
        props: { cardId: 'billing' },
      },
      {
        path: 'package',
        component: BillingSelectionPage,
        beforeEnter(to, from, next) {
          sendToReactQuotePage();
        },
      },
      {
        path: 'checkout',
        component: CheckoutPage,
        props: { cardId: 'checkout' },
      },
    ],
  },
  // Discretion end pages
  {
    path: '/discretion-quote',
    props: extractProps,
    component: QuoteRequestReviewDiscretion,
    beforeEnter: authGuard,
  },

  {
    path: '/discretion-quote-blocking',
    props: extractProps,
    component: QuoteBlockingDiscretion,
    beforeEnter: authGuard,
  },
  {
    path: '/discretion-quote-blocking-renewal',
    props: extractProps,
    component: QuoteBlockingRenewalDiscretion,
    beforeEnter: authGuard,
  },

  {
    path: '/discretion-quote-rejection',
    props: extractProps,
    component: QuoteRejectionDiscretion,
    beforeEnter: authGuard,
  },
  // Dead ends
  {
    path: '/getquote/fin',
    props: extractProps,
    component: FinPage,
  },
  {
    path: '/getquote/officer-signature',
    props: extractProps,
    component: OfficerSignaturePage,
    beforeEnter: authGuard,
  },
  {
    path: '/getquote/delayed-payment',
    props: extractProps,
    component: DelayedPayment,
    beforeEnter: authGuard,
  },
  // end deads
  {
    path: '',
    component: TransitionWithBackgroundBase,
    children: [
      {
        path: '/',
        name: 'Madlib',
        component: Madlib,
        props: extractProps,
      },
      // Route for Program 1 questions
      {
        path: '/getquote',
        props: extractProps,
        component: OnboardingWizardBase,
        beforeEnter: selectivelyApplyAuthGuard,
        children: [
          {
            path: '',
            props: extractProps,
            component: OnboardingQuestionBase,
            children: questionCardConfig,
          },
          {
            path: 'svb-connect',
            props: extractProps,
            component: ConnectSVBPage,
          },
          {
            path: 'carta-connect',
            props: extractProps,
            component: AcceptCartaStandardDataShare,
          },
        ],
      },
      // Route for Program 2 questions
      {
        path: '/application',
        props: extractProps,
        component: OnboardingWizardBase,
        beforeEnter: selectivelyApplyAuthGuardProgram2,
        children: [
          {
            path: '',
            props: extractProps,
            component: OnboardingQuestionBase,
            children: questionCardConfigProgram2,
          },
          {
            path: 'niche',
            props: extractProps,
            component: NicheQuestionCardBase,
          },
          {
            path: 'niche/:id',
            props: extractProps,
            component: NicheQuestionCardBase,
          },
          {
            path: 'svb-connect',
            props: extractProps,
            component: ConnectSVBPage,
          },
          {
            path: 'carta-connect',
            props: extractProps,
            component: AcceptCartaStandardDataShare,
          },

          // Dead ends (Program 2)
          {
            path: 'fin',
            props: extractProps,
            component: FinPage,
          },
          {
            path: 'officer-signature',
            props: extractProps,
            component: OfficerSignaturePage,
          },
          {
            path: 'delayed-payment',
            props: extractProps,
            component: DelayedPayment,
          },

          // Payment (Program 2)
          {
            path: 'billing',
            component: BillingSelectionPage,
            props: { cardId: 'billing' },
          },
          {
            path: 'checkout',
            component: CheckoutPage,
            props: { cardId: 'checkout' },
          },
        ],
      },
      {
        path: '/loginportal',
        component: BaseAuthPage,
        children: [
          {
            path: 'send',
            name: 'RerouteOnAuth',
            component: RerouteOnAuth,
            beforeEnter: authGuard,
            props: extractProps,
          },
          {
            path: 'loginCallback',
            name: 'Auth0LoginCallback',
            component: Auth0LoginCallback,
            props: extractProps,
          },
          {
            path: 'authError',
            name: 'AuthError',
            component: AuthError,
            props: extractProps,
          },
          {
            path: 'emailVerification',
            name: 'EmailVerification',
            component: EmailVerification,
            props: extractProps,
          },
          {
            path: 'emailVerified',
            name: 'EmailVerified',
            component: EmailVerified,
            beforeEnter: initAuthBeforeEnter,
            props: extractProps,
          },
          {
            path: 'logout',
            name: 'Logout',
            component: Logout,
            beforeEnter: initAuthBeforeEnter,
          },
        ],
      },
    ],
  },
  {
    path: '/crumbs',
    name: 'crumbs',
    component: CrumbsPage,
    beforeEnter: initAuthBeforeEnter,
    props: extractProps,
  },
  {
    path: '*',
    component: PageNotFound,
  },
];

export default onboardingRoutes;
