<template>
  <ReviewFields
    v-if="applicationDataLoaded"
    class="review-fields"
    :route-formatter="routeFormatter"
    :is-editable="true"
    :application-data="applicationData"
    :application-parent-data="applicationParentData"
    :application-id="applicationId"
  />
</template>

<script>
import ReviewFields from '@/onboarding/components/formfields/ReviewFields';
import isEmpty from 'lodash/isEmpty';
import { renewalUrl } from '@/members/router/routeHelper';
import {
  getApplicationData,
  getApplicationParentData,
} from '@/onboarding/lib/selectors/storeSelectors';

export default {
  name: 'ReviewFieldsContainer',
  components: { ReviewFields },
  props: {
    applicationId: {
      type: String,
      required: true,
    },
  },
  computed: {
    applicationData() {
      return getApplicationData(this);
    },
    applicationParentData() {
      return getApplicationParentData(this);
    },
    applicationDataLoaded() {
      return !isEmpty(this.applicationData) && !isEmpty(this.applicationParentData);
    },
  },
  methods: {
    routeFormatter: renewalUrl,
  },
};
</script>
