<template>
  <ul class="coverage-list">
    <li
      v-for="(name, i) in coverageNames"
      :key="i"
    >
      <CoverageListItem
        :name="name"
      />
    </li>
  </ul>
</template>
<script>
import CoverageListItem from './CoverageListItem';

export default {
  name: 'CoverageList',
  components: { CoverageListItem },
  props: {
    coverageNames: {
      type: Array,
      required: true,
    },
    isChecked: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
.coverage-list {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;

  & * + * {
    margin-top: $space-xsmall;
  }
}
</style>
