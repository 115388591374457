<template>
  <GreenStatusPage>
    <template v-slot:primary-reason-content>
      <span class="discretion-title">Your request is under review.</span>
    </template>
    <template v-slot:description-content>
      <div class="discretion-body">
        <span>
          We’ll follow up with additional details via
          <span class="discretion-body-bold">email typically within three business days.</span></span>
        <br>
        <span>If you have any
          questions, our Insurance Advisors are standing by to chat.</span>
      </div>
    </template>
    <template v-slot:status-button>
      <div class="action-container">
        <div class="cancel-request">
          <VouchIcon
            class="vouch-icon"
            name="arrowLeft"
          />
          <span @click="onClick">CANCEL MY REQUEST</span>
        </div>
        <ButtonPrimary @click="showChat">
          Talk to an advisor
        </ButtonPrimary>
      </div>
    </template>
  </GreenStatusPage>
</template>

<script>
import GreenStatusPage from '@/shared/views/layouts/GreenStatusPage';
import ButtonPrimary from '@/shared/components/ui/atoms/buttons/ButtonPrimary';
import VouchIcon from '@/shared/components/ui/atoms/icons/VouchIcon';
import { sendToReactQuotePageWithId } from '@/onboarding/router/routeHelper';
import { rerouteAuthenticatedUser } from '@/onboarding/router/rerouteUser';
import { showChatIfEnabled } from '@/onboarding/services/chatHelper';
import {
  DISCRETION_REQUEST_CANCEL_CLICKED,
  DISCRETION_REQUEST_REVIEW_VIEWED,
} from '@/onboarding/services/SegmentEventTypes';
import { checkAppForRenewalNewOrOldFunnel } from '@/onboarding/router/newVsOldFunnelGuards';
import { onQuestionLoad } from '@/shared/services/onQuestionLoad';

export default {
  name: 'QuoteRequestReview',
  dependencies: ['featureFlags', 'requests', 'tracking', 'store'],
  components: {
    ButtonPrimary,
    GreenStatusPage,
    VouchIcon,
  },
  props: {
    applicationId: {
      type: String,
      required: true,
    },
    pendingDiscretionId: {
      type: String,
      required: true,
    },
  },
  async created() {
    if (!this.applicationId || !this.pendingDiscretionId) {
      this.$router.replace('/loginportal/send');
    }
    const { next } = await rerouteAuthenticatedUser({
      router: this.$router,
      givenApplicationId: this.applicationId,
    });
    if (next && !next.match(/\/discretion-quote/)) {
      this.$router.replace(next);
    }
    this.tracking.sendEvent(DISCRETION_REQUEST_REVIEW_VIEWED);
  },
  async mounted() {
    await onQuestionLoad({ context: this });
    await checkAppForRenewalNewOrOldFunnel(this);
  },
  methods: {
    onClick() {
      this.tracking.sendEvent(DISCRETION_REQUEST_CANCEL_CLICKED);
      this.requests.cancelDiscretion({
        discretionId: this.pendingDiscretionId,
        onSuccess: () => sendToReactQuotePageWithId(this.applicationId),
      });
    },
    showChat() {
      showChatIfEnabled({ tracking: this.tracking });
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-request {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: $space-large;
  @media only screen and (max-width: $medium-device) {
    margin-right: 0px;
    padding-bottom: 30px;
  }
  font-weight: 700;
}

.vouch-icon {
  padding-right: $space-medium;
}
.discretion-title {
  font-size: $font-size-l;
  font-weight: 500;
}

.discretion-body {
  @include description-text;
}

.discretion-body-bold {
  font-weight: 700;
}

.action-container {
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: $medium-device) {
    flex-direction: column;
  }
}
</style>
