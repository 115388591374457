<template>
  <ul class="card-fields">
    <li
      v-for="formField in formFields"
      :key="formField['name'] || formField['schemaPath']"
    >
      <div class="formField">
        <!-- eslint-disable -->
        <span
          v-if="shouldShowTitle(formField)"
          class="form-title field-label"
          v-html="formField['propertyDefinition'].title"
        />
        <!-- eslint-enable -->
        <CardField
          :form-field="formField"
          :on-input-change="onInputChange"
        />
      </div>
    </li>
  </ul>
</template>
<script>
import CardField from '@/shared/views/wizard/core/shared/CardField';
import SchemaFormNoopField from '@/onboarding/components/formfields/SchemaFormNoOpField';
import SchemaFormFieldBigMoney from '@/onboarding/components/formfields/SchemaFormFieldBigMoney';
import SchemaFormHiddenField from '@/onboarding/components/formfields/SchemaFormHiddenField';

export default {
  name: 'CardFields',
  components: { CardField },
  props: {
    formFields: {
      required: true,
      type: Array,
    },
    onInputChange: {
      required: true,
      type: Function,
    },
  },
  methods: {
    shouldShowTitle({ component }) {
      return ![SchemaFormNoopField, SchemaFormFieldBigMoney, SchemaFormHiddenField].includes(
        component
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card-fields {
  list-style: none;
  padding: 0 !important;
}
.field-label {
  @include label-text;
}
</style>
