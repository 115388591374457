<template>
  <ul class="card-fields">
    <li
      v-for="formField in formFields"
      :key="formField['name'] || formField['schemaPath']"
    >
      <div>
        <CardField
          :form-field="formField"
          :on-input-change="onInputChange"
          :is-single-field="true"
        />
      </div>
    </li>
  </ul>
</template>
<script>
import CardField from '@/shared/views/wizard/core/shared/CardField';

export default {
  name: 'SingleCardField',
  components: { CardField },
  props: {
    formFields: {
      required: true,
      type: Array,
    },
    onInputChange: {
      required: true,
      type: Function,
    },
  },
};
</script>

<style scoped>
.card-fields {
  list-style: none;
  padding: 0 !important;
}
</style>
