import { ApplicationDecisions } from '../../constants/Constants';
import { DecisionData } from '@/onboarding/types/internal/decisionTypes';

const isNotYesDecision = (decisionData: DecisionData) => {
  const { decision } = decisionData;
  return decision && decision !== ApplicationDecisions.yesDecision;
};

const isYesDecision = (decisionData: DecisionData) => {
  const { decision } = decisionData;
  return decision && decision === ApplicationDecisions.yesDecision;
};

const isMaybeDecision = (decisionData: DecisionData) => {
  const { decision } = decisionData;
  return decision && decision === ApplicationDecisions.maybeDecision;
};

const isNoDecision = (decisionData: DecisionData) => {
  const { decision } = decisionData;
  return decision && decision === ApplicationDecisions.noDecision;
};

export default {
  isNotYesDecision,
  isYesDecision,
  isMaybeDecision,
  isNoDecision,
};
