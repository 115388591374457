<template>
  <CenteredContainer :class="{container: true, 'container-dark': dark}">
    <div :class="{'brex-10k-points-disclaimer': true, 'brex-10k-points-disclaimer-dark': dark}">
      Offer is limited to Vouch proprietary coverages purchased using your Brex card and excludes partner or third party coverages.  Brex Points will be added to your Brex account in 45 days. Offer subject to change without notice.  Offer may not be available in all states.
    </div>
  </CenteredContainer>
</template>

<script>
import CenteredContainer from '@/shared/components/layouts/CenteredContainer';

export default {
  name: 'Brex10kPointsDisclaimer',
  components: {
    CenteredContainer,
  },
  props: {
    dark: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.container-dark {
  background-color: $disclaimer-dark-background;
  margin-left: 0px;
  margin-right: 0px;
  max-width: inherit;
}

.brex-10k-points-disclaimer {
  @include detail-text;
  color: $disclaimer-light-background-text;

  &-dark {
    background-color: $disclaimer-dark-background;
    color: $disclaimer-dark-background-text;
  }
}
</style>
