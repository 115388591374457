import Ajv from 'ajv';
import $RefParser from '@apidevtools/json-schema-ref-parser';
import SchemaService from './SchemaService';
import { getSchema, schemaNames } from '@/onboarding/questions_library/questions';
import {
  generateSchemaDefinitionsForNiches,
  getAllNicheQuestions,
} from '@/onboarding2/niche.domain';

const ajv = new Ajv({ allErrors: true, verbose: true });

class SchemaServiceFactory {
  ajvSchemas = {}; // cache schema retrievals to save on repetitive calls to the backend

  async getService(schemaName: schemaNames, isProgram2?: boolean) {
    if (schemaName in this.ajvSchemas) {
      return this.ajvSchemas[schemaName];
    }

    const fullSchema = await getSchema({ schemaName, isProgram2 });
    const derefFullSchema = await $RefParser.dereference(fullSchema, {
      resolve: { external: false },
    });

    const derefFullSchemaWithNiches = generateSchemaDefinitionsForNiches(
      derefFullSchema,
      getAllNicheQuestions()
    );

    this.ajvSchemas[schemaName] = new SchemaService(derefFullSchemaWithNiches, ajv);
    return this.ajvSchemas[schemaName];
  }
}

const schemaServiceFactory = new SchemaServiceFactory();
export default schemaServiceFactory;
