export type Uuid = string;

export enum VouchEnv {
  local = 'local',
  staging = 'staging',
  uat = 'uat',
  dev = 'dev',
  production = 'production',
}

declare global {
  interface Window {
    analytics: any;
    grecaptcha: any;
    session: any;
    Stripe: any;
    Plaid: any;
    VOUCH_ENV: VouchEnv;
    vueRoot: any;
    zE: any;
  }
}
