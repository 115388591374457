export enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  ACH = 'ach',
}

type CreditCardOnlyExperience = {
  mode: 'credit_card_only';
};

type AchAndCreditCardExperience = {
  mode: 'ach_and_credit_card';
  data: {
    type: PaymentMethod;
  };
};

type UninitializedPreviousPayment = { type: undefined; charge_type: undefined };
type NoPreviousPayment = { type: 'none'; charge_type: undefined };
type PreviousPayment = {
  type: undefined;
  charge_type: 'card' | 'bank_account' | 'ach_debit' | 'ach_credit_transfer';
};
type PreviousPaymentData = UninitializedPreviousPayment | NoPreviousPayment | PreviousPayment;
type PreviousPaymentMethodExperience = {
  mode: 'previous_payment_method';
  data: PreviousPaymentData;
};

type Experience =
  | CreditCardOnlyExperience
  | AchAndCreditCardExperience
  | PreviousPaymentMethodExperience;

class PaymentExperience {
  static creditCardOnly() {
    return new PaymentExperience({
      mode: 'credit_card_only',
    });
  }

  static achAndCreditCard(type: PaymentMethod) {
    return new PaymentExperience({
      mode: 'ach_and_credit_card',
      data: { type },
    });
  }

  static previousPaymentMethod(data?: PreviousPaymentData) {
    return new PaymentExperience({
      mode: 'previous_payment_method',
      data: data || { type: undefined, charge_type: undefined },
    });
  }

  experience: Experience;

  constructor(experience: Experience) {
    this.experience = experience;
  }

  get isPreviousPaymentAvailable(): boolean {
    return this.experience.mode === 'previous_payment_method' && !!this.experience.data.charge_type;
  }

  get isPreviousPaymentMethod(): boolean {
    return this.experience.mode === 'previous_payment_method';
  }

  get isAchAndCreditCard(): boolean {
    return this.experience.mode === 'ach_and_credit_card';
  }

  get isCreditCardOnly(): boolean {
    return this.experience.mode === 'credit_card_only';
  }

  get isAch(): boolean {
    if (this.experience.mode === 'ach_and_credit_card' && this.experience.data.type === 'ach') {
      return true;
    }

    return (
      this.experience.mode === 'previous_payment_method' &&
      ['bank_account', 'ach_debit', 'ach_credit_transfer'].includes(
        this.experience.data.charge_type || ''
      )
    );
  }

  get isCreditCard(): boolean {
    if (
      this.experience.mode === 'ach_and_credit_card' &&
      this.experience.data.type === 'credit_card'
    ) {
      return true;
    }

    if (
      this.experience.mode === 'previous_payment_method' &&
      this.experience.data.charge_type === 'card'
    ) {
      return true;
    }

    return this.experience.mode === 'credit_card_only';
  }

  get paymentMethod(): PaymentMethod | 'none' {
    if (this.isAch) return PaymentMethod.ACH;
    if (this.isCreditCard) return PaymentMethod.CREDIT_CARD;
    return 'none';
  }

  get mode() {
    return this.experience.mode;
  }

  get data() {
    if (this.experience.mode === 'credit_card_only') return {};
    return this.experience.data;
  }
}

export default PaymentExperience;
