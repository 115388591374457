export enum ApplicationVersions {
  // string values for sha_onboarding_funnel must match those in
  // https://github.com/svinstech/pa_shim/blob/master/app/models/application_data.rb
  program1 = '1.1',
  program2 = '2.0',
}
export interface ApplicationData {
  id: string;
  sha_onboarding_funnel: ApplicationVersions;
  '2019-07-01--FRIENDLY_ENTITY_NAME': string;
  '2019-07-01--EMAIL': string;

  '2019-07-01--LOCATION_CONTAINER'?: {
    '2019-07-01--ADDRESS_LINE_1': string;
    '2019-07-01--ADDRESS_LINE_2': string | null;
    '2019-07-01--ADDRESS_CITY': string;
    '2019-07-01--ADDRESS_STATE': string;
    '2019-07-01--ADDRESS_POSTAL_CODE': string;
    // eslint-disable-next-line camelcase
    additional_locations: [];
  };
  completed_at: string | null;
  incubator_program: string | null;
  intake_application: boolean | null;
  is_upgrade: boolean;
  renewal: {
    is_renewal_application: boolean;
    is_ready_for_renewal: boolean;
    has_started_renewal_process: boolean;
    has_renewal_questions_answered: boolean;
    is_renewal_completed: boolean;
  };
  email_confirmed: boolean;
}

export interface Address {
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
}

export enum StateShortName {
  AL = 'AL',
  AK = 'AK',
  AS = 'AS',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  DC = 'DC',
  FM = 'FM',
  FL = 'FL',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MH = 'MH',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  MP = 'MP',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PW = 'PW',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VI = 'VI',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}
