<template>
  <div class="google-recaptcha">
    <div
      id="g-recaptcha"
      class="g-recaptcha"
      data-size="invisible"
    />
  </div>
</template>

<script>
import Env from '../../../shared/services/Env';

const shouldRecaptcha = () => Env.getConfig().recaptcha.enable;
export default {
  name: 'Recaptcha',
  data() {
    return {
      widgetId: 0,
    };
  },
  created() {
    if (shouldRecaptcha()) {
      window.onRecaptchaLoad = this.onRecaptchaLoad;
      const script = `https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit`;
      this.$loadScript(script);
    }
  },
  methods: {
    onRecaptchaLoad() {
      window.grecaptcha.render('g-recaptcha', {
        sitekey: Env.getConfig().recaptcha.v2_key,
        size: 'invisible',
        // the callback executed when the user solve the recaptcha
        callback: response => {
          // emit an event called verify with the response as payload
          this.$emit('verify', response);
          // reset the recaptcha widget so you can execute it again
          this.reset();
        },
      });
    },
    execute() {
      if (shouldRecaptcha()) {
        try {
          window.grecaptcha.execute(this.widgetId);
        } catch (e) {
          this.$rollbar.debug(`RecaptchaError ${e}`);
          window.location.reload();
        }
      } else {
        this.$emit('verify', null);
      }
    },
    reset() {
      window.grecaptcha.reset(this.widgetId);
    },
  },
};
</script>
