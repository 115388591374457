<template>
  <FormFieldSingleSelect
    :value="inputValue"
    :items="propertyDefinition['enum']"
    :error="isValid === false"
    :error-messages="errorMessage"
    @input="setInputValue"
  />
</template>

<script>
import BaseSchemaFormField from './BaseSchemaFormField';
import FormFieldSingleSelect from '@/shared/components/ui/atoms/form_fields/FormFieldSingleSelect';

export default {
  name: 'SchemaFormFieldSingleSelect',
  components: { FormFieldSingleSelect },
  extends: BaseSchemaFormField,
};
</script>
