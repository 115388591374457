// This options object is used to initialize LogRocket with privacy restrictions and other settings
import Env from '@/shared/services/Env';
import { omit, pick } from 'lodash';

// These interfaces are copied from the LogRocket d.ts file
interface LrRequest {
  reqId: string;
  url: string;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
  referrer?: string;
  mode?: string;
  credentials?: string;
}

interface LrResponse {
  reqId: string;
  status?: number;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
}

const { funnel_url } = Env.getConfig();
const domain = funnel_url.slice(funnel_url.indexOf('vouch'));
const logRocketConfig = {
  rootHostname: domain,
  network: {
    requestSanitizer: (request: LrRequest) => {
      // By default, remove request headers & body content for all requests recorded by LogRocket
      // To opt-in some requests, add a conditional here to filter on the endpoint URL.
      // For config docs, see: https://docs.logrocket.com/reference#network

      // Don't record these 3rd party analytics requests
      if (
        request.url.toLowerCase().indexOf('api.amplitude.com') !== -1 ||
        request.url.toLowerCase().indexOf('api.rollbar.com') !== -1 ||
        request.url.toLowerCase().indexOf('auth0.com') !== -1 ||
        request.url.toLowerCase().indexOf('google-analytics.com') !== -1
      ) {
        return null;
      }

      const sanitizedResponse = omit(request, ['headers', 'body']) as LrRequest;
      return sanitizedResponse;
    },
    responseSanitizer: (response: LrResponse) => {
      const responseBodyObj =
        response.body && response.headers?.['content-type'] === 'application/json'
          ? JSON.parse(response.body)
          : {};
      const sanitizedResponse = omit(response, ['headers', 'body']) as LrResponse;
      sanitizedResponse.body =
        // Whitelist properties we're okay to log on the response (request URL is not available on the response
        // object to apply this selectively, so this filter applies to all responses)
        JSON.stringify(
          pick(responseBodyObj, [
            'error',
            'errorCode',
            'errors',
            'id',
            'is_locked',
            'is_completed',
            'program_version',
            'status',
          ])
        );

      return sanitizedResponse;
    },
  },
};

export default logRocketConfig;
