import {
  getApplicantEmail,
  applicationIsRenewal,
  getApplicationData,
} from '../lib/selectors/storeSelectors';
import { uuid } from 'vue-uuid';
import Env from '@/shared/services/Env';
import { timeConstants } from '@/onboarding/constants/Constants';
import * as globalsHelper from '@/onboarding/services/globalsHelper';
import { storageAvailable } from '@/shared/lib/vouch_dash';
import { getCardId } from '@/shared/routing/getCardId';
import {
  FUNNEL_CARD_COMPLETED,
  FUNNEL_CARD_EDITED,
  MADLIB_EDITED,
  STALLED_PAGE,
} from '@/onboarding/services/SegmentEventTypes';
import _ from 'lodash';

export const userTrackedCards = [
  '2019-07-01--CAPITAL_RAISED_CONTAINER',
  '2021-06-01--LEAD_INVESTOR_LAST_ROUND',
  'renewal-start',
];

class TrackingService {
  postTrackingEvent: any;

  anonymousId: string | null;

  constructor({ requests, getUuid = uuid }) {
    this.postTrackingEvent = requests.postTrackingEvent;
    const localStorageExists = storageAvailable('localStorage');

    const anonymousIdKey = 'anonymousId';

    if (localStorageExists && localStorage.getItem(anonymousIdKey)) {
      this.anonymousId = localStorage.getItem(anonymousIdKey);
    } else {
      this.anonymousId = getUuid.v1();
      if (localStorageExists) localStorage.setItem(anonymousIdKey, this.anonymousId ?? '');
    }
  }

  context() {
    return window.vueRoot;
  }

  getEmail() {
    const { $store } = this.context();
    if ($store) {
      return getApplicantEmail({ $store });
    }
    return null;
  }

  getApplicationRenewalState() {
    const { $store } = this.context();
    if ($store) {
      return applicationIsRenewal({ $store });
    }
    return null;
  }

  getSubmitData() {
    const { $store } = this.context();
    return $store.state.cards.submitData;
  }

  isVouchUserInProduction() {
    return this.context().$auth.isVouchUser() && Env.isProduction();
  }

  timeStamp() {
    return new Date().toISOString();
  }

  getUserAgent() {
    return window.navigator.userAgent;
  }

  onPageChange() {
    const { $route, $store } = this.context();
    const applicationData = getApplicationData({ $store });
    const cardId = getCardId($route);
    const { applicationId } = $route.query;

    const beforeHistoryLength = globalsHelper.getHistoryLength();
    const eventPath = cardId || $route.path;
    this.pageLoad({
      path: eventPath,
      applicationData,
      applicationId,
    });

    setTimeout(() => {
      const afterHistoryLength = globalsHelper.getHistoryLength();
      if (beforeHistoryLength === afterHistoryLength) {
        this.sendEvent(STALLED_PAGE, { path: eventPath });
      }
    }, timeConstants.stalledPageTimeout);
  }

  pageLoad({ path, applicationData, applicationId }) {
    // Ignore events generated by @vouch.us emails
    if (this.isVouchUserInProduction()) {
      return;
    }

    const properties = {
      applicationId,
      // Passing an observer into the Segment call breaks reporting; this round trip converts the observer
      applicationData: JSON.parse(JSON.stringify(applicationData)),
    };
    window.analytics.page(path, properties);
  }

  baseEventProperties() {
    const { $route } = this.context();
    const { applicationId } = $route.query;

    return {
      leadID: this.getEmail(),
      currentUserEmail: this.context().$auth.getUserEmail(),
      applicationId,
      cardId: getCardId($route),
      cardSubmitData: this.getSubmitData(),
      timestamp: this.timeStamp(),
      userAgent: this.getUserAgent(),
      is_renewal: this.getApplicationRenewalState(),
    };
  }

  sendEvent(name, data: { [k: string]: any } = {}) {
    // Passing an observer into the Segment call breaks reporting; parsing converts any observers to objects
    const sanitizedBaseEventProperties = JSON.parse(JSON.stringify(this.baseEventProperties()));
    const sanitizedData = JSON.parse(JSON.stringify(data));
    const { $rollbar } = this.context();
    $rollbar.debug(`EVENT RECORDED: ${name}`, sanitizedData);
    // Ignore events generated by @vouch.us emails
    if (this.isVouchUserInProduction()) {
      return;
    }

    const properties = {
      ...sanitizedBaseEventProperties,
      ...sanitizedData,
    };

    window.analytics.track(name, properties);
    this.sendUserEvent(name, properties);
  }

  getUserPayload(properties) {
    let data;

    switch (properties.cardId) {
      case '2019-07-01--CAPITAL_RAISED_CONTAINER':
        data = _.get(properties, [
          'cardSubmitData',
          '2019-07-01--CAPITAL_RAISED_CONTAINER',
          '2019-07-01--CAPITAL_RAISED_EQUITY_CENTS',
        ]);

        return {
          capital_raised: data,
        };

      case '2021-06-01--LEAD_INVESTOR_LAST_ROUND':
        data = _.get(properties, ['cardSubmitData', '2021-06-01--LEAD_INVESTOR_LAST_ROUND']);

        return {
          lead_investor_last_round: data,
        };

      case 'renewal-start':
        data = _.get(properties, ['is_renewal']);

        return {
          is_renewal: data,
        };

      default:
        break;
    }

    return {};
  }

  sendUserEvent(name, properties) {
    const { cardId } = properties;

    if (name !== FUNNEL_CARD_COMPLETED || !userTrackedCards.includes(cardId)) {
      return;
    }

    const payload = this.getUserPayload(properties);

    window.analytics.identify(this.context().$auth.getUserEmail(), payload);
  }

  // TODO: Remove when we're certain of no downstream effects
  madlibEditedEvent({ key, value }) {
    const eventName = MADLIB_EDITED;
    this.sendEvent(eventName, { fieldName: key, fieldValue: value, WARNING: 'DEPRECATED' });
  }

  // TODO: Remove when we're certain of no downstream effects
  funnelCardEditedEvent() {
    const { propertyName, inputValue } = this.context();
    const fieldName = propertyName;
    const fieldValue = inputValue;
    const eventName = FUNNEL_CARD_EDITED;
    this.sendEvent(eventName, { fieldName, fieldValue, WARNING: 'DEPRECATED' });
  }
}

export default TrackingService;
