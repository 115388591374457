<template>
  <SectionWithTitle
    title="Certificate of Insurance"
    class="checkout-form-section"
  >
    <p>
      Silicon Valley Bank lending requirements include proof that you have purchased General Liability and Property Insurance.
    </p>
    <p>
      Once you have signed and paid for your policy, we will provide you and Silicon Valley Bank with Certificates of Insurance for both policies, as well as add them as additional insured and loss payees.
    </p>
    <p>
      We will email you copies of these documents once this is completed.
    </p>
    <p>
      By checking this box, you acknowledge that we will be adding Silicon Valley Bank as additional insureds and loss payees.
    </p>
    <v-checkbox
      v-model="localCheckboxValue"
      data-testid="svb-coi-agreement"
    >
      <div
        slot="label"
        class="label"
      >
        Send certificate
      </div>
    </v-checkbox>
  </SectionWithTitle>
</template>

<script>
import SectionWithTitle from '@/onboarding/views/SectionWithTitle';

export default {
  name: 'CheckoutFormSectionSvbcCoiAgreement',
  components: {
    SectionWithTitle,
  },
  props: {
    value: {
      type: Boolean,
    },
  },
  computed: {
    localCheckboxValue: {
      get() {
        return this.value;
      },
      set(localCheckboxValue) {
        this.$emit('input', localCheckboxValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-form-section__label {
  font-weight: bold;
  font-size: 0.9em;
  text-transform: uppercase;
}
</style>
