<script>
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import SchemaFormFieldNumber from '@/onboarding/components/formfields/SchemaFormFieldNumber';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';

export default {
  name: 'WizardCardCoreYearIncorporated',
  components: { SchemaFormFieldNumber },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('core'),
      submitPath: getUpdateEndpoint('core'),
      cardConfigPath: '2019-07-01--YEAR_INCORPORATED',
      formFieldData: [
        {
          component: SchemaFormFieldNumber,
          schemaPath: '2019-07-01--YEAR_INCORPORATED',
          dataPath: '2019-07-01--YEAR_INCORPORATED',
        },
      ],
    };
  },
};
</script>
