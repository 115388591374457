import { get } from '@/shared/lib/vouch_dash';

export const shouldIgnoreError = err => {
  const status = get(err, 'response.status');
  const ignoreableStatuses = [400, 401, 403, 404, 418, 420, 422];
  const ignoreableMessages = [/Network Error/];

  if (ignoreableStatuses.includes(status)) {
    return true;
  }

  if (ignoreableMessages.some(reg => reg.test(err.message))) {
    return true;
  }

  return false;
};

export default {
  shouldIgnoreError,
};
