<template>
  <SectionWithTitle title="Payment Information">
    <div class="body-text mb-medium">
      <p>You will not be charged until after we get your signature.</p>
    </div>

    <slot name="promo-content" />

    <template v-if="paymentExperience.isAchAndCreditCard">
      <FormFieldRadioGroup
        :options="paymentOptions"
        :initial-value="paymentExperience.paymentMethod"
        @change="$emit('paymentMethodSelected', $event)"
      />

      <div
        v-show="paymentExperience.isAch"
        class="mb-medium"
      >
        <CheckoutACH />
      </div>

      <CheckoutStripeCard
        v-show="paymentExperience.isCreditCard"
        @stripeToken="$emit('stripeToken', $event)"
        @stripeErrorMessage="$emit('stripeErrorMessage', $event)"
      />
    </template>

    <template v-else>
      <CheckoutStripeCard
        @stripeToken="$emit('stripeToken', $event)"
        @stripeErrorMessage="$emit('stripeErrorMessage', $event)"
      />
    </template>
  </SectionWithTitle>
</template>

<script>
import SectionWithTitle from '@/onboarding/views/SectionWithTitle';
import FormFieldRadioGroup from '@/shared/components/ui/atoms/form_fields/FormFieldRadioGroup';
import CheckoutStripeCard from '@/onboarding/views/wizard/card/checkout/CheckoutStripeCard';
import CheckoutACH from '@/onboarding/views/wizard/card/checkout/CheckoutACH';
import { PaymentMethod } from '@/onboarding/services/PaymentExperience';
import { PackageConfig } from '@/onboarding/constants/PackageConfig';
import featureFlagging from '@/onboarding/services/FeatureFlaggingService';

const PaymentMethodRadioOptions = [
  { label: 'Credit Card', value: PaymentMethod.CREDIT_CARD },
  { label: 'Bank Account (ACH)', value: PaymentMethod.ACH },
];

const limitCreditCardFlag = () =>
  featureFlagging.getFlag({ flag: 'limit-credit-card-payments', defaultValue: false });

export default {
  name: 'NewPaymentMethodForm',
  components: {
    SectionWithTitle,
    CheckoutStripeCard,
    CheckoutACH,
    FormFieldRadioGroup,
  },
  props: {
    paymentExperience: {
      type: Object,
      required: true,
    },
    packageSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      paymentMethodRadioOptions: PaymentMethodRadioOptions,
    };
  },
  computed: {
    paymentOptions() {
      // no-op if the flag is off
      if (limitCreditCardFlag() === false) {
        return PaymentMethodRadioOptions;
      }
      return PaymentMethodRadioOptions.filter(option => {
        // Package config indicates credit card should NOT be shown and the
        // option being observed IS credit card, we need to remove it.
        if (
          PackageConfig[this.packageSlug].creditCardEnabled === false &&
          option.value === PaymentMethod.CREDIT_CARD
        ) {
          return false;
        }
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.body-text {
  @include body-text;
}

.mb-medium {
  margin-bottom: $space-medium;
}
</style>
