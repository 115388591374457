<script>
import { debounce } from 'lodash/function';
import { get, isArray } from '@/shared/lib/vouch_dash';
import { unescape } from 'lodash';

const singleSelectOptions = ['None of these apply', 'None of these'];

export default {
  name: 'BaseSchemaFormField',
  dependencies: ['tracking'],
  components: {},
  props: {
    dataPath: {
      required: true,
      type: String,
    },
    isSingleField: {
      required: true,
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-prop-types
    value: {
      // type: [String, Number, null],
      required: true,
    },
    validator: {
      type: Function,
      required: true,
    },
    warning: {
      type: Function,
      required: false,
    },
    propertyDefinition: {
      type: Object,
      required: true,
    },
    propertyName: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      errorMessage: '',
      warningMessage: '',
      isValid: null,
    };
  },
  computed: {
    backgroundColor() {
      return '#f2f2f2';
    },
    inputValue() {
      const v = get(this.$store.state.cards.submitData, this.dataPath);
      return this.formatGetInput(v);
    },
    hidePreviousAnswer() {
      return this.propertyDefinition.hidePreviousAnswer ?? false;
    },
    inputParentValue() {
      const v = get(this.$store.state.application.applicationParentData, this.dataPath);
      return this.formatGetArrayInput(v);
    },
  },
  watch: {
    value: {
      handler: 'init',
      immediate: true,
    },
  },
  methods: {
    formatGetInput(value) {
      if (typeof value === 'string') {
        return unescape(value);
      }
      return value;
    }, // over-write me
    formatSetInput: value => value, // over-write me
    formatGetArrayInput(value) {
      if (Array.isArray(value)) {
        return value.reduce((prev, curr) => `${prev} + ${curr}`);
      }
      return value;
    },
    init() {
      this.emitInput(this.value, this.validator(this.value), true);
    },
    // handles multiselect options that should clear all other options
    /* eslint-disable no-restricted-syntax */
    ensureValidInput(rawValue) {
      const existingValues = get(this.$store.state.cards.submitData, this.dataPath) || [];

      if (!isArray(rawValue)) return rawValue;

      for (const value of rawValue) {
        const isSingleOption = singleSelectOptions.includes(value);
        const singleOptionAlreadySelected = existingValues.find(val =>
          singleSelectOptions.includes(val)
        );

        if (singleOptionAlreadySelected) {
          return rawValue.filter(val => !singleSelectOptions.includes(val));
        }

        if (isSingleOption && !singleOptionAlreadySelected) {
          return [value];
        }
      }

      return rawValue;
    },
    /* eslint-enable no-restricted-syntax */
    setInputValue(rawValue) {
      const validatedRawValue = this.ensureValidInput(rawValue);
      const value = this.formatSetInput(validatedRawValue);
      this.isValid = this.validator(value);
      this.emitInput(value, this.isValid);
      this.errorMessage = this.isValid
        ? ''
        : this.validator.errors.map(item => item.message).join(',');
      this.warningMessage = this.warning?.(value);
    },
    emitInput(value, isValid, initialEvent = false) {
      this.$emit('input', value, { dataPath: this.dataPath, isValid, initialEvent });

      if (!initialEvent) {
        this.trackChanges();
      }
    },
    trackChanges: debounce(function trackFunnelCardEdited() {
      this.tracking.funnelCardEditedEvent();
    }, 500),
  },
};
</script>
