<script>
import BaseCardWithFields from '../../../../components/wizard/BaseCardWithFields';
import SchemaFormFieldBoolean from '../../../../../onboarding/components/formfields/SchemaFormFieldBoolean';
import {
  getSchemaEndpoint,
  getUpdateEndpoint,
} from '../../../../../onboarding/lib/selectors/apiPathSelectors';

// TODO
export default {
  name: 'ProductInMarketCard',
  components: { SchemaFormFieldBoolean },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('core'),
      submitPath: getUpdateEndpoint('core'),
      cardConfigPath: '2019-07-01--HAS_PRODUCT_IN_MARKET',
      formFieldData: [
        {
          component: SchemaFormFieldBoolean,
          schemaPath: '2019-07-01--HAS_PRODUCT_IN_MARKET',
          dataPath: '2019-07-01--HAS_PRODUCT_IN_MARKET',
        },
      ],
    };
  },
};
</script>
