import Env from '@/shared/services/Env';

const {
  auth0_client_id,
  auth0_custom_domain,
  auth0_redirect_url,
  marketing_site_url,
} = Env.getConfig();

export default {
  customDomain: auth0_custom_domain,
  clientID: auth0_client_id,
  redirectUrl: auth0_redirect_url,
  logoutUrl: marketing_site_url,
};
