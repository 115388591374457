<template>
  <SectionWithTitle
    title="Signature Information"
  >
    <div>
      <p>
        To activate your coverage, we need to collect a signature on
        the policy document.
      </p>
      <p>
        The person signing your application must be a duly elected
        Officer of your company, such as the Chief Executive Officer,
        Chief Financial Officer, or General Counsel.
      </p>
      <p v-if="showCancelationReminder">
        Remember you can cancel the policy at any time and receive full
        reimbursement if changes are necessary before signature is
        received.
      </p>
    </div>
    <label
      class="checkout-form-section__label"
    >
      Officer Name
      <v-text-field
        v-model="value.name"
        data-testid="officer-name"
        :rules="[rules.required]"
        filled
      />
    </label>
    <label
      class="checkout-form-section__label"
    >
      Officer Email
      <v-text-field
        v-model="value.email"
        data-testid="officer-email"
        validate-on-blur
        :rules="[rules.required, rules.email]"
        filled
      />
    </label>
    <label
      class="checkout-form-section__label"
    >
      Officer Title
      <FormFieldAutocomplete
        v-model="value.title"
        :items="officerTitleOptions"
        data-testid="officer-title"
        :rules="[rules.required]"
        @keydown="officerTitleKeydown"
      />
    </label>
  </SectionWithTitle>
</template>

<script>
import SectionWithTitle from '@/onboarding/views/SectionWithTitle';
import FormFieldAutocomplete from '@/shared/components/ui/atoms/form_fields/FormFieldAutocomplete';
import { getSchema, schemaNames } from '@/onboarding/questions_library/questions';
import { applicationIsProgram2 } from '@/onboarding/lib/selectors/storeSelectors';
import { validateEmailAddress } from '@/shared/services/validateEmailAddress';

export default {
  name: 'CheckoutFormSectionOfficerSignature',
  components: {
    SectionWithTitle,
    FormFieldAutocomplete,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    showCancelationReminder: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      rules: {
        required: value => !!value || 'Required.',
        email: value => validateEmailAddress(value) || 'Must be a valid email',
      },
      schema: null,
      officerTitleOptions: [],
    };
  },
  computed: {
    isProgram2() {
      return applicationIsProgram2(this);
    },
  },
  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },
  created() {
    this.loadSchema();
  },
  methods: {
    async loadSchema() {
      this.schema = await getSchema({
        schemaName: schemaNames.definitions,
        isProgram2: this.isProgram2,
      });
      this.officerTitleOptions = this.schema.definitions[
        '2020-06-15--OFFICER_RELATIONSHIP_TO_COMPANY'
      ].enum.filter(e => e);
    },
    officerTitleKeydown() {
      this.$emit('officerTitleChange');
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-form-section__label {
  @include label-text;
}

// Remove bottom border from input field
.v-input {
  ::v-deep .v-input__slot:before {
    content: none;
  }
}

@include text-field-filled-bg-color;
</style>
