<template>
  <QuestionCardProgress
    :progress="progress"
    :detail-text="timeMessage"
  />
</template>

<script>
import pluralize from 'pluralize';
import QuestionCardProgress from '@/shared/components/ui/molecules/QuestionCardProgress';

export default {
  name: 'ProgressBar',
  components: { QuestionCardProgress },
  props: {
    progress: {
      type: Number,
      required: true,
    },
    minutesLeft: {
      type: Number,
      required: true,
    },
  },
  computed: {
    timeMessage() {
      const minutes = Math.round(this.minutesLeft);

      return this.progress === 100
        ? 'Application complete'
        : `Around ${minutes} ${pluralize('minute', minutes)} left`;
    },
  },
};
</script>
