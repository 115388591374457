<script>
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import SchemaFormFieldNumber from '@/onboarding/components/formfields/SchemaFormFieldNumber';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';

export default {
  name: 'WizardCardPrequalNumWorkers',
  components: { SchemaFormFieldNumber },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('prequal'),
      submitPath: getUpdateEndpoint('prequal'),
      cardConfigPath: '2019-07-01--NUM_FULL_TIME_WORKERS',
      formFieldData: [
        {
          component: SchemaFormFieldNumber,
          schemaPath: '2019-07-01--NUM_FULL_TIME_WORKERS',
          dataPath: '2019-07-01--NUM_FULL_TIME_WORKERS',
        },
      ],
    };
  },
};
</script>
