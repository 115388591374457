<template>
  <CenteredContainer :class="{'header-nav--white': isWhite, 'header-nav': !isWhite}">
    <div class="header-nav-content">
      <div class="header-nav-logo">
        <VouchLogoSVG class="vouch-logo-header" />
      </div>
      <slot />
    </div>
  </CenteredContainer>
</template>

<script>
import CenteredContainer from '@/shared/components/layouts/CenteredContainer';
import VouchLogoSVG from '@/shared/components/ui/atoms/logos/VouchLogoSVG';

export default {
  name: 'HeaderNav',
  components: { CenteredContainer, VouchLogoSVG },
  props: {
    isWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.header-nav {
  background-color: $header-background-color;
}

.header-nav--white {
  background-color: $header-background-color-white;
}

.header-nav-content {
  height: gridUnit(7);
  width: 100%;

  display: flex;
  align-items: center;
}

.header-nav-logo {
  margin-right: auto;
}

.vouch-logo-header {
  width: 36px;
  height: 36px;
}
</style>
