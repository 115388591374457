<template>
  <WithDataSync
    :application-id="applicationId"
    :update-fn="updateFn"
    :submit-path="submitPath"
    :on-submit-success="onSubmitSuccess"
    :on-submit-error="onSubmitError"
  >
    <template slot-scope="dataSyncProps">
      <WithCardData
        :card-data="cardData"
        :schema-path="schemaPath"
        :card-config-path="cardConfigPath"
      >
        <template slot-scope="cardDataProps">
          <WithFormFields
            :form-field-data="formFieldData"
            :schema-path="schemaPath"
          >
            <template slot-scope="formFieldProps">
              <slot
                :onSubmit="dataSyncProps.onSubmit"
                :isValidForm="dataSyncProps.isValidForm"
                :onInputChange="dataSyncProps.onInputChange"
                :formFields="formFieldProps.formFields"
                :cardData="cardDataProps.cardData"
              />
            </template>
          </WithFormFields>
        </template>
      </WithCardData>
    </template>
  </WithDataSync>
</template>

<script>
import marshallSubmitData from '../../../onboarding/lib/selectors/marshallSelectors';
import WithFormFields from './WithFormFields';
import WithCardData from './WithCardData';
import WithDataSync from './WithDataSync';

export default {
  name: 'CardDataSync',
  components: { WithDataSync, WithCardData, WithFormFields },
  props: {
    cardData: {
      type: Object,
      default: () => ({}),
    },
    applicationId: {
      type: String,
      required: true,
    },
    updateFn: {
      type: Function,
      required: true,
    },
    formFieldData: {
      type: Array,
      required: true,
    },
    submitPath: {
      type: String,
      required: true,
    },
    schemaPath: {
      type: String,
      required: true,
    },
    onSubmitSuccess: {
      type: Function,
      required: true,
    },
    onSubmitError: {
      type: Function,
      required: false,
      default: data => {
        window.vueRoot.$rollbar.error('Unhandled onSubmitError', data);
      },
    },
    marshallSubmitDataFn: {
      type: Function,
      default: marshallSubmitData.arrayMarshall,
      required: false,
    },
    cardConfigPath: {
      type: String,
      required: true,
    },
  },
};
</script>
