<template>
  <BaseNavigationBar
    :steps="steps"
    :current-nav-step="currentNavStep"
  />
</template>

<script>
import { FunnelNavSteps } from '@/onboarding/constants/Constants';
import BaseNavigationBar from '@/shared/components/wizard/BaseNavigationBar';

export default {
  name: 'OnboardingNavigationBar',
  components: { BaseNavigationBar },
  props: {
    currentNavStep: {
      type: Number,
      required: true,
    },
  },
  computed: {
    steps() {
      return [
        {
          title: 'Pre-Qualification',
          navStep: FunnelNavSteps.PREQUAL,
        },
        {
          title: 'Core Questions',
          navStep: FunnelNavSteps.CORE,
        },
        {
          title: 'Your Quote',
        },
        {
          title: 'Checkout',
        },
        {
          title: 'Sign the Policy',
        },
      ];
    },
  },
};
</script>
