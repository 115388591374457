import requests from '@/shared/services/requests';
import { get } from 'lodash';
import { isInPrequal } from './helpers';

const initialState = {
  applicationData: {},
  applicationParentData: {},
  decisionData: {
    decision: null,
  },
  packagesData: null,
  applicationId: null,
  preCoreDecisionData: {
    decision: null,
    reason_token: null,
    // if we need to, we can add a field
    // to keep track of if this came from madlib or
    // prequal, but we don't use that information atm
  },
};

const getters = {
  isApplicationLocked: ({ applicationData }) => applicationData.is_locked === true,
  hasValidPackages: ({ validPackages }) => !!validPackages,
  applicationVersion: ({ applicationData }) => applicationData.program_version,
  isRenewal: ({ applicationData }) =>
    !!get(applicationData, 'renewal.renewal_parent_application_id'),
};

const actions = {
  async fetchApplicationData({ state, commit }) {
    const { applicationId } = state;
    const applicationData = await requests.getApplicationData({ applicationId });

    commit({
      type: 'updateApplicationData',
      applicationData,
    });
  },

  async fetchApplicationParentData({ state, commit }) {
    const { applicationId } = state;
    const applicationParentData = await requests.getApplicationParentData({ applicationId });

    if (applicationParentData.errors) return;

    commit({
      type: 'updateApplicationParentData',
      applicationParentData,
    });
  },

  async fetchDecisionData({ state, commit }) {
    const { applicationId } = state;
    // @ts-ignore
    const response = (await requests.getApplicationDecision({ applicationId })) as AxiosReponse;

    commit({
      type: 'updateDecisionData',
      decisionData: response.data,
    });
  },

  async loadPackagesData({ state, commit, dispatch }) {
    if (!state.packagesData) {
      await dispatch('fetchPackagesData');
    }
  },

  async fetchPackagesData({ state, commit }) {
    const { applicationId } = state;
    let packagesData = null;
    // @ts-ignore
    await requests.getPackages({
      applicationId,
      onSuccess: response => {
        packagesData = response;
      },
    });

    commit({
      type: 'updatePackagesData',
      packagesData,
    });
  },

  async fetchPreCoreDecisionData({ state, commit }, { applicationId }) {
    let getPreCoreDecisionData = requests.getPrequalDecision;
    if (!isInPrequal(state.applicationData)) {
      // which request is made won't matter, we only care about what the decision
      // result will be. 'preCoreDecisionData' here will refer to any decision
      // made before prequal is finished (can come from getMadlibDecision or
      // getPrequalDecisionData)
      getPreCoreDecisionData = requests.getMadlibDecision;
    }

    await getPreCoreDecisionData({
      applicationId,
      onError: e => {
        throw e;
      },
      onSuccess: data => {
        commit({
          type: 'updatePreCoreDecisionData',
          preCoreDecisionData: data,
        });
      },
    });
  },
};

const mutations = {
  updateApplicationData: (state, { applicationData }) => {
    state.applicationData = applicationData;
  },
  updateApplicationParentData: (state, { applicationParentData }) => {
    state.applicationParentData = applicationParentData;
  },
  updateDecisionData: (state, { decisionData }) => {
    state.decisionData = decisionData;
  },
  updatePackagesData: (state, { packagesData }) => {
    state.packagesData = packagesData;
  },
  updatePreCoreDecisionData: (state, { preCoreDecisionData }) => {
    state.preCoreDecisionData = preCoreDecisionData;
  },
  setApplicationId: (state, value) => {
    state.applicationId = value;
  },
};

export default {
  state: initialState,
  getters,
  actions,
  mutations,
};
