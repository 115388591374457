<template>
  <li
    class="question-card-nav-step"
    :class="{ 'question-card-nav-step--current': current}"
  >
    <slot />
  </li>
</template>

<script>
export default {
  name: 'QuestionCardNavStep',
  props: {
    current: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card-nav-step {
  list-style: none;

  &--current {
    font-weight: bold;
  }
}
</style>
