<template>
  <router-view
    v-if="loaded"
    :card-id="cardId"
    :application-id="applicationId"
    :is-renewal="true"
    v-bind="$attrs"
  />
</template>

<script>
import { getCardId } from '@/shared/routing/getCardId';
import { onQuestionLoad } from '@/shared/services/onQuestionLoad';
import {
  renewalRedirectAwayOnPageLoad,
  renewalRedirectAwayOnRouteChange,
} from '@/members/router/renewalsRedirect';

export default {
  name: 'RenewalQuoteBase',
  dependencies: ['trackingSetup', 'featureFlags'],
  props: {
    applicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return { loaded: false };
  },
  computed: {
    cardId() {
      return getCardId(this.$route);
    },
  },
  beforeRouteUpdate(nextRoute, previousRoute, next) {
    if (getCardId(previousRoute) === 'fin') {
      // going back from the fin page means that you'll be going to the
      // page that directed you to it. so we gotta skip over that page
      // so you're not stuck.
      this.$router.go(-1);
    } else {
      renewalRedirectAwayOnRouteChange({
        next,
        nextRoute,
        previousRoute,
        $store: this.$store,
        applicationId: this.applicationId,
      });
    }
  },
  async created() {
    await onQuestionLoad({ context: this });
    renewalRedirectAwayOnPageLoad(this);
    this.loaded = true;
  },
};
</script>
