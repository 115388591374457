import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Card, CardId } from '@/onboarding/types/internal/routingTypes';
import { routingDecisions } from '@/members/services/routingDecisions';
import { routingDecisionsProgram2 } from '@/members/services/routingDecisionsProgram2';
import { routingDecisions as onboardingDecisions } from '@/onboarding/services/routing_decisions/routingDecisions';
import { routingDecisionsProgram2 as onboardingDecisionsProgram2 } from '@/onboarding/services/routing_decisions_program_2/routingDecisionsProgram2';
import { onboardingUrl, onboardingUrlP2 } from '@/onboarding/router/routeHelper';
import { renewalUrl, renewalUrlP2 } from '@/members/router/routeHelper';

const findAsync = async (arr, asyncCallback) => {
  const promises = arr.map(asyncCallback);
  const results = await Promise.all(promises);
  const index = results.findIndex(result => result);
  return arr[index];
};

const allDecisionsAfterIndex = ({ decisions, index }) => decisions.slice(index + 1);
const allDecisionsBeforeIndex = ({ decisions, index }) => decisions.slice(0, index);
const currentCardIndex = ({ decisions, cardId }) => decisions.findIndex(({ id }) => id === cardId);
const findRoutingDecision = ({ decisions, component }) =>
  findAsync(decisions, async decision => decision.shouldRender(component));

export const continuingCard = async ({ decisions, component, onErrorCardId }) => {
  const findContinuingCard = async ({ isSatisfied, shouldRender }) =>
    !isSatisfied(component) && (await shouldRender(component)); // eslint-disable-line no-return-await
  const { id } = (await findAsync(decisions, findContinuingCard)) || { id: onErrorCardId };
  return id;
};

export const previousCardForComponent = async ({ decisions, component, onErrorCardId }) => {
  const index = currentCardIndex({ decisions, cardId: component.cardId });
  const scopedDecisions = [...allDecisionsBeforeIndex({ decisions, index })].reverse();
  const { id } = (await findRoutingDecision({ decisions: scopedDecisions, component })) || {
    id: onErrorCardId,
  };
  return id;
};

export const nextCardForComponent = async ({ decisions, component, onErrorCardId }) => {
  const index = currentCardIndex({ decisions, cardId: component.cardId });
  const scopedDecisions = allDecisionsAfterIndex({ index, decisions });
  const { id } = (await findRoutingDecision({ decisions: scopedDecisions, component })) || {
    id: onErrorCardId,
  };
  return id;
};

export default class QuestionRouterService {
  programVersion$ = new BehaviorSubject<number>(1);

  isRenewal$ = new BehaviorSubject<boolean>(false);

  decisions$ = combineLatest([this.programVersion$, this.isRenewal$]).pipe(
    map(([programVersion, isRenewal]) => {
      if (isRenewal) {
        return programVersion === 2 ? routingDecisionsProgram2 : routingDecisions;
      }
      return programVersion === 2 ? onboardingDecisionsProgram2 : onboardingDecisions;
    })
  );

  finalCard = 'package';

  abortCard = 'fin';

  onErrorCard$ = this.isRenewal$.pipe(map(isRenewal => (isRenewal ? 'renewal-start' : 'review')));

  cancelDiscretionCard = 'discretion-quote';

  urlFormatter$ = combineLatest([this.programVersion$, this.isRenewal$]).pipe(
    map(([programVersion, isRenewal]) => {
      if (isRenewal) {
        return programVersion === 2 ? renewalUrlP2 : renewalUrl;
      }
      return programVersion === 2 ? onboardingUrlP2 : onboardingUrl;
    })
  );

  constructor(public store: any) {
    store.watch(
      (state, { applicationVersion }) => applicationVersion,
      applicationVersion => this.programVersion$.next(applicationVersion),
      { immediate: true }
    );
    store.watch(
      (state, { isRenewal }) => isRenewal,
      isRenewal => this.isRenewal$.next(isRenewal),
      { immediate: true }
    );
  }

  finish(component: Card) {
    this.goto({ cardId: this.finalCard, component });
  }

  abort(component: Card) {
    this.goto({ cardId: this.abortCard, component });
  }

  async next(component: Card) {
    combineLatest([this.decisions$, this.onErrorCard$])
      .pipe(take(1))
      .subscribe(async ([decisions, onErrorCard]) => {
        const cardId = await nextCardForComponent({
          component,
          decisions,
          onErrorCardId: onErrorCard,
        });
        this.goto({ cardId, component });
      });
  }

  async previous(component: Card) {
    combineLatest([this.decisions$, this.onErrorCard$])
      .pipe(take(1))
      .subscribe(async ([decisions, onErrorCard]) => {
        const cardId = await previousCardForComponent({
          component,
          decisions,
          onErrorCardId: onErrorCard,
        });
        this.goto({ cardId, component });
      });
  }

  async getReinsertionPath(component: Card) {
    return firstValueFrom(
      combineLatest([this.decisions$, this.urlFormatter$, this.onErrorCard$]).pipe(
        take(1),
        map(async ([decisions, urlFormatter, onErrorCard]) => {
          const cardId = await continuingCard({
            component,
            decisions,
            onErrorCardId: onErrorCard,
          });
          return urlFormatter({ cardId, applicationId: component.applicationId });
        })
      )
    );
  }

  goto({ cardId, component, params }: { cardId: CardId; component: Card; params?: any }) {
    this.urlFormatter$.pipe(take(1)).subscribe(urlFormatter => {
      const path = urlFormatter({
        cardId,
        applicationId: component.applicationId,
        params,
      });

      component.$router
        .push({ path })
        .catch(e =>
          window.vueRoot.$rollbar.critical(
            'Question funnel routing error',
            { destinationPath: path },
            e
          )
        );
    });
  }
}
