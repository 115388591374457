export const AUTH_ATTEMPT_UNVERIFIED = 'Attempted authentication with unverified email';
export const AUTHENTICATION_SUCCESSFUL = 'Authentication Successful';
export const AUTH_USER_LOGGED_OUT = 'User logged out';
export const AUTH_LOGOUT_ATTEMPTED_BY_UNAUTHED_USER =
  'Logout action attempted by unauthenticated user';
export const BACK_TO_PACKAGE_SCREEN_EVENT = 'Back to Package Screen Event';
export const BACK_TO_QUOTE_SCREEN_EVENT = 'Back to Quote Screen Event';
export const BILLING_PAGE_VIEWED = 'Billing Page Viewed';
export const CHAT_WIDGET_AUTO_OPENED = 'Chat Widget Auto Opened';
export const CHAT_WIDGET_CLICKED = 'Chat Widget Clicked';
export const CORE_DECISION = 'Core Decision';
export const COVERAGE_CARD_TOGGLED = 'Coverage Card Toggled';
export const CRUMBS_PAGE_DISPLAYED = 'Crumbs Page Displayed';
export const DISCRETION_REQUEST_REVIEW_VIEWED = 'Discretion Request Review Viewed';
export const DISCRETION_REQUEST_CANCEL_CLICKED = 'Discretion Request Cancel Clicked';
export const EFFECTIVE_DATE_UPDATED = 'Effective Date updated';
export const FUNNEL_CARD_COMPLETED = 'Funnel Card Completed';
export const FUNNEL_CARD_EDITED = 'Funnel Card Edited';
export const LIABILITY_CLAIM_ADDED = 'Liability Claim Added';
export const LIABILITY_CLAIM_REMOVED = 'Liability Claim Removed';
export const LIMIT_CHANGED = 'Limit Changed';
export const MADLIB_COMPLETE = 'Madlib Complete';
export const MADLIB_DECISION = 'Madlib Decision';
export const MADLIB_EDITED = 'Madlib Edited';
export const MADLIB_LOADED = 'Madlib Loaded';
export const MADLIB_SIGNUP_WIDGET_VIEWED = 'Madlib Signup Widget Viewed';
export const MAYBE_ACCOUNT_CREATED_ERROR = 'Maybe Account Created Error';
export const PACKAGE_SCREEN_VIEWED = 'Package Screen Viewed';
export const PACKAGE_SELECTED = 'Package Selected';
export const PARTNERED_COVERAGE_ADDED = 'Partnered Coverage Added';
export const PARTNERED_COVERAGE_REMOVED = 'Partnered Coverage Removed';
export const PASSWORD_RESET_REQUESTED = 'Password Reset Requested';
export const PAYMENT_COMPLETED = 'Payment Completed';
export const PAYMENT_COMPLETED_NEW_BIZ = 'New Biz Payment Completed';
export const PAYMENT_COMPLETED_RENEWAL = 'Renewal Payment Completed';
export const PAYMENT_SELECTION_SCREEN_VIEWED = 'Payment Selection Screen Viewed';
export const PAYMENT_SCHEDULE_SELECTED_EVENT = 'Payment Schedule Selected Event';
export const PAYMENT_VIEWED = 'Payment Viewed';
export const PERK_CLICKED_FOR_REDEMPTION = 'Perk Clicked for Redemption';
export const PERK_EXPANDED = 'Perk Expanded';
export const PREQUAL_DECISION = 'Prequal Decision';
export const QUOTE_COMPLETED = 'Quote Completed';
export const QUOTE_DOWNLOADED = 'Quote Downloaded';
export const QUOTE_VIEWED = 'Quote Viewed';
export const REGISTRATION_AWAIT_VERIFICATION = 'Registration Processed, Verification Required';
export const REGISTRATION_ERROR = 'Registration Error';
export const REGISTRATION_SUBMITTED = 'Registration Submitted';
export const REGISTRATION_SUCCESSFUL = 'Registration Successful';
export const REGISTRATION_VERIFICATION_COMPLETE = 'Email Verification Completed';
export const REGISTRATION_VERIFICATION_ERROR = 'Email Verification Failure';
export const REGISTRATION_VERIFICATION_EXPIRED = 'Email Verification Attempted with Expired Link';
export const REGISTRATION_VERIFICATION_RETRIED =
  'Email Verification Attempted but Previously Completed';
export const RENEWAL_LANDING_PAGE_VIEWED = 'Renewal Landing Viewed';
export const REPRICE_QUOTE = 'Re-Price Quote';
export const REVIEW_PAGE_ERRORED = 'Review Page Errored';
export const PHONE_NUMBER_ENTERED = 'Phone Number Entered';
export const STALLED_PAGE = 'Stalled Page';
export const SVB_CONNECT_ATTEMPTED = 'SVB Connect Attempted';
export const SVB_CONNECT_FAILED = 'SVB Connect Failed';
export const SVB_CONNECT_SUCCESSFUL = 'SVB Connect Successful';
export const SVB_CONNECT_VIEWED = 'SVB Connect Viewed';
export const SVBC_COI_REQUESTED = 'SVBC COI requested';
export const SWITCH_FROM_BASIC_QUOTE_TO_RECOMMENDED = 'Switch From Basic Quote to Recommended';
export const DATA_SHARE_OPT_OUT_ATTEMPT = 'Date Share Opt-out Attempt';
export const DATA_SHARE_OPT_OUT_FAILED = 'Date Share Opt-out Attempt Failed';
export const DATA_SHARE_OPT_OUT_SUCCESSFUL = 'Date Share Opt-out Attempt Successful';

// Member Portal
export const PORTAL_NAV_ITEM_CLICKED = 'Users Page: Member Portal Nav Item Clicked';
// Users Page
export const ADD_USER_BUTTON_CLICKED = 'Users Page: Add User Button Clicked';
export const SEND_INVITE_BUTTON_CLICKED = 'Users Page: Send Invite Button Clicked';
export const INVITATION_SENT = 'Users Page: Invitation Successfully Sent';
export const EDIT_USER_BUTTON_CLICKED = 'Users Page: Edit Button Clicked';
export const SAVE_CHANGES_BUTTON_CLICKED = 'Users Page: Save Changes Button Clicked';
export const USER_EDITED = 'Users Page: User Successfully Edited';
export const REMOVE_USER_BUTTON_CLICKED = 'Users Page: Remove User Button Clicked';
export const CONFIRM_REMOVAL_BUTTON_CLICKED = 'Users Page: Confirm Remove User Button Clicked';
export const USER_REMOVED = 'Users Page: User Successfully Removed';
export const USER_ACCEPTED_INVITATION = 'Users Page: User Accepted Invitation';

// Checkout
export const PAYMENT_METHOD_SELECTED = 'Payment Method Selected';
export const PLAID_LINK_OPENED = 'Plaid Link Opened';
export const PLAID_LINK_EXITED = 'Plaid Link Exited';
export const PLAID_LINK_HANDOFF_COMPLETED = 'Plaid Link Handoff Completed';
export const PLAID_LINK_INSTITUTION_SEARCHED = 'Plaid Link Institution Searched';
export const PLAID_LINK_INSTITUTION_SELECTED = 'Plaid Link Institution Selected';
export const PLAID_LINK_ERROR = 'Plaid Link Error';

// TrackingServiceSetup
export const TRACKING_SETUP_BOOT = 'Analytics Initialized';
