<template>
  <BaseWizardPage
    :card-data="computedCardData"
    :is-submit-disabled="false"
    submit-text="Create Account"
    @submit="onSubmit"
  />
</template>

<script>
import BaseCard from '@/shared/components/wizard/BaseCard';
import {
  applicationIsProgram2,
  getApplicantEmail,
  getPreCoreDecisionData,
} from '@/onboarding/lib/selectors/storeSelectors';
import selectors from '@/onboarding/lib/selectors/decisionSelectors';
import { mapActions, mapMutations } from 'vuex';
import { timeConstants } from '@/onboarding/constants/Constants';
import BaseWizardPage from '@/shared/components/wizard/BaseWizardPage';
import {
  MAYBE_ACCOUNT_CREATED_ERROR,
  PREQUAL_DECISION,
} from '@/onboarding/services/SegmentEventTypes';

export default {
  name: 'EndOfPrequalCard',
  components: { BaseWizardPage },
  extends: BaseCard,
  dependencies: ['requests', 'tracking'],
  props: {
    minutesLeft: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      decisionData: {},
    };
  },
  computed: {
    applicationEmail() {
      return getApplicantEmail(this);
    },
    isProgram2() {
      return applicationIsProgram2(this);
    },
    computedCardData() {
      if ((!this.decisionData.decision && !this.isProgram2) || this.$auth.isAuthenticated) {
        return {};
      }

      return {
        title: 'Next, create an account to continue your application.',
        description: `This allows you to securely save your progress.`,
      };
    },

    isYesDecision() {
      return selectors.isYesDecision(this.decisionData);
    },

    isNoDecision() {
      return selectors.isNoDecision(this.decisionData);
    },
  },
  async created() {
    const { applicationId } = this;

    await this.fetchPreCoreDecisionData({ applicationId });
    this.decisionData = getPreCoreDecisionData(this);

    if (this.decisionData) {
      this.tracking.sendEvent(PREQUAL_DECISION, {
        decision: this.decisionData.decision,
        applicationId,
      });
    } else return;

    if (this.isNoDecision) {
      this.questionRouter.abort(this);
    }

    if (this.isYesDecision && this.$auth.isAuthenticated) {
      this.questionRouter.next(this);
    }
  },
  methods: {
    ...mapActions(['fetchPreCoreDecisionData']),
    ...mapMutations(['updatePreCoreDecisionData']),
    onSubmit() {
      if ((this.decisionData.decision && this.isYesDecision) || this.isProgram2) {
        if (this.$auth.isAuthenticated) {
          this.questionRouter.next(this);
        } else {
          this.$auth.showLogin(
            {
              allowSignUp: true,
              initialScreen: 'signUp',
            },
            `/loginportal/send${window.location.search}`,
            this.applicationEmail
          );
        }

        setTimeout(() => {
          this.tracking.sendEvent(MAYBE_ACCOUNT_CREATED_ERROR, {
            applicationId: this.applicationId,
          });
        }, timeConstants.stalledPageTimeout);
      }
    },
  },
};
</script>
