<script>
import SchemaFormFieldBoolean from '@/onboarding/components/formfields/SchemaFormFieldBoolean';
import { getSchemaEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import SchemaFormFieldTextArea from '@/onboarding/components/formfields/SchemaFormFieldTextArea';

export default {
  name: 'RenewalScheduleCall',
  components: { SchemaFormFieldTextArea },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('core'),
      submitPath: '',
      cardConfigPath: '2019-07-01--SCHEDULE_CALL',
      formFieldData: [
        {
          component: SchemaFormFieldBoolean,
          // Hack alert - this data is NOT being persisted in applicationData.
          //   and we don't really have a generic 'boolean' definition
          //   so, any boolean definition will do.
          schemaPath: '2019-07-01--SCHEDULE_CALL',
          dataPath: 'should_schedule_phone_call',
        },
      ],
    };
  },
  methods: {
    updateFn(options) {
      this.isLoading = true;
      this.requests.finalizeRenewals({ ...options, context: this });
    },
  },
};
</script>
