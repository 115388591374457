// ported from https://github.com/svinstech/greeter

// ignore this rule here, loops are explicitly used here to exit out of loops as early as possible
/* eslint-disable no-restricted-syntax */
import { autoYes } from '@/shared/routing/routingDecisionHelpers';
import {
  FlowModel,
  FlowItemCondtion,
  FlowItem,
  FlowItemConditions,
  FlowItemToolTip,
  QuestionPathConfig,
  FunnelWizardConfig,
  QuestionRoutingDecision,
} from '@/onboarding2/niche.interfaces';
import NicheQuestionCard from '@/onboarding2/NicheQuestionCard.vue';
import { FunnelNavSteps } from '@/onboarding/constants/Constants';
import nicheQuestions from '@/onboarding2/niche_questions.json';
import $RefParser from '@apidevtools/json-schema-ref-parser';
import { onboardingUrl } from '@/onboarding/router/routeHelper';
import featureFlagging from '@/onboarding/services/FeatureFlaggingService';
import { renewalUrl } from '@/members/router/routeHelper';
import { get } from '@/shared/lib/vouch_dash';

const NICHE_ID_BASE = '2021-06-01_NICHE_QUESTION_';
const NICHE_ID_PATH = `niche/${NICHE_ID_BASE}`;
const NICHE_CORE_QUESTIONS = 'Niche Question';
const NICHE_OUTCOMES = 'Niche Outcome';
const NICHE_FOLLOWUPS = 'Niche Follow-Up';
export const LEGACY_FIELD_MAP = {
  p1_technology: '2019-07-01--CATEGORY_TECHNOLOGY',
  p1_customer: '2019-07-01--CATEGORY_CUSTOMER',
  p1_vertical: '2019-07-01--CATEGORY_VERTICAL',
  p1_niche: '2019-07-01--CATEGORY_NICHE',
};
export const APPLICATION_DATA_WHITELIST = [
  '2021-06-01--NICHE-CATEGORY',
  '2021-06-01--NICHE_BUSINESS_MODEL_DESC',
  '2021-06-01--NICHE_SW_INDUSTRY',
  '2021-06-01--NICHE_HW_INDUSTRY',
  '2021-06-01--NICHE_GOODS_INDUSTRY',
  '2021-06-01--NICHE_PS_INDUSTRY',
  '2021-06-01--NICHE_USER_ENTERED',
];

export const canSendToNewNicheFlow = () => {
  return featureFlagging.getFlag({ flag: 'program-2.0-questions' });
};

const doHideNicheQuestions = () =>
  featureFlagging.getFlag({ flag: 'hide-niche-determination-questions' });

const generateCardId = (identifier: number | string): string => `${NICHE_ID_PATH}${identifier}`;
export const parseCardId = (cardId: string): string => {
  return cardId.replace(NICHE_ID_BASE, '');
};

export const generatePathForId = (
  identifier: string,
  applicationId: string,
  renewal = false,
  params?: {}
): string => {
  if (renewal) {
    return renewalUrl({ cardId: generateCardId(identifier), applicationId, params });
  }
  return onboardingUrl({ cardId: generateCardId(identifier), applicationId, params });
};

export const hasMatch = (answers: FlowModel['answers'], condition: FlowItemCondtion) => {
  let selectedAnswers = answers[condition.parentQuestion];

  if (!selectedAnswers) return false;

  // smooth over capitalization differences
  selectedAnswers = selectedAnswers.map(s => s.toLowerCase());

  if (!selectedAnswers.includes(condition.answer.toLowerCase())) return false;

  return true;
};

export const getMatchedConditions = (
  optionPool: FlowItem[],
  answers: FlowModel['answers']
): FlowItem[] => {
  // apply provided answers against each questions requirements.
  // To allow this function to be run quickly on each answer it will return false as soon as possible
  // Logic is applied by most strict requirements first
  return optionPool.filter(option => {
    const conditions = option.conditions as FlowItemConditions;

    // the question is always surfaced
    if (!conditions) return true;

    for (const condition of conditions.only) {
      const isMatch = hasMatch(answers, condition);

      if (!isMatch) return false;

      // this question can only be surfaced if answer matches /exactly/ this condition
      if (isMatch && answers[condition.parentQuestion].length !== 1) return false;
    }

    for (const condition of conditions.not) {
      if (hasMatch(answers, condition)) return false;
    }

    for (const condition of conditions.all) {
      if (!hasMatch(answers, condition)) return false;
    }

    const anyMatches = conditions.any.filter(condition => hasMatch(answers, condition));

    if (conditions.any.length && !anyMatches.length) return false;

    return true;
  });
};

export const generateCardConfigsFromJson = (flow: FlowItem[]): QuestionPathConfig[] => {
  return flow.map(item => ({
    path: generateCardId(item.identifier),
    component: NicheQuestionCard,
  }));
};

export const generateRoutingDecisions = (
  flow: FlowItem[],
  checkEligabilty = false
): QuestionRoutingDecision[] => {
  if (checkEligabilty && !canSendToNewNicheFlow()) return [];

  return flow.map(item => {
    return {
      id: generateCardId(item.identifier),
      isSatisfied: autoYes,
      shouldRender: ({ applicationData }) => {
        const isPrimaryNicheFilled = get(applicationData, '2021-06-01--PRIMARY_NICHE');
        return !isPrimaryNicheFilled || !doHideNicheQuestions();
      },
    };
  });
};

export const getCoreNicheQuestions = (): FlowItem[] => nicheQuestions[NICHE_CORE_QUESTIONS];
export const getOptionalNicheQuestions = (): FlowItem[] => nicheQuestions[NICHE_FOLLOWUPS];
export const getNicheOutcomes = (): FlowItem[] => nicheQuestions[NICHE_OUTCOMES];
export const getNicheTooltips = (): { [option: string]: FlowItemToolTip } =>
  nicheQuestions.tooltips;

export const getAllNicheQuestions = (): FlowItem[] => {
  return [...getCoreNicheQuestions(), ...getOptionalNicheQuestions()];
};

export const generateNicheCardsFunnelWizardConfig = (
  questions: QuestionPathConfig[],
  percent = 9,
  step = FunnelNavSteps.PREQUAL
): FunnelWizardConfig => {
  const config = {};

  questions.forEach(({ path }) => {
    config[path] = { percent, step };
  });

  return config;
};

export const generateSchemaDefinitionsForNiches = (
  schema: $RefParser.JSONSchema,
  items: FlowItem[]
): $RefParser.JSONSchema => {
  const extendedSchema = { ...schema };

  items.forEach(item => {
    const cardId = generateCardId(item.identifier);

    if (!extendedSchema.definitions) return;

    extendedSchema.definitions[cardId] = {
      default: null,
      type: ['array'],
      items: {
        type: 'string',
      },
    };
  });

  return extendedSchema;
};

export const getPostNicheCardId = (decisions: QuestionRoutingDecision[]): string => {
  const allNichePaths = generateRoutingDecisions(getAllNicheQuestions());
  const lastNichePathId = allNichePaths[allNichePaths.length - 1].id;
  const lastNicheQuestionIndex = decisions.findIndex(({ id }) => id === lastNichePathId);

  return decisions[lastNicheQuestionIndex + 1].id;
};

export const finalNicheReducer = (previous: FlowItem, current: FlowItem): FlowItem => {
  if (!previous) return current;

  let previousRisk = previous.risk || 0;
  let currentRisk = current.risk || 0;

  // supports risk override with priority number
  if (typeof previous.conditions !== 'string' && previous.conditions?.overrides) {
    previousRisk = previous.conditions.overrides.priority || previousRisk;
  }

  if (typeof current.conditions !== 'string' && current.conditions?.overrides) {
    currentRisk = current.conditions.overrides.priority || currentRisk;
  }

  if (previousRisk > currentRisk) return current;

  return previous;
};

export const getProgram1NicheMappings = (niche: FlowItem) => {
  if (!niche.legacy_data) return {};

  return Object.keys(niche.legacy_data).reduce((acc, key) => {
    const prop = LEGACY_FIELD_MAP[key as string];
    acc[prop] = niche.legacy_data ? niche.legacy_data[key] : '';

    return acc;
  }, {});
};

export const getUpdatedLegacyNicheMappingsBasedOnPrimaryNiche = (primaryNiche: string) => {
  const niche = getNicheOutcomes().find(
    niche => niche.internal_ids?.confluence_id === primaryNiche
  );
  return niche
    ? {
        ...getProgram1NicheMappings(niche),
        '2021-06-01--NICHE_LIST': [primaryNiche],
      }
    : null;
};

export const isNicheDataPrefilled = (applicationData: any) => {
  const prefilledP1Fields = Object.values(LEGACY_FIELD_MAP).every(
    applicationDataKey => applicationData[applicationDataKey]
  );
  const prefilledNicheList = applicationData['2021-06-01--NICHE_LIST'];

  return prefilledP1Fields && prefilledNicheList;
};

/* eslint-enable no-restricted-syntax */

// cardId niche/:id
