<script>
import BaseCardStatic from '@/shared/components/wizard/BaseCardStatic';
import {
  getCompanyName,
  getApplicationData,
  applicationIsProgram2,
} from '@/onboarding/lib/selectors/storeSelectors';
import { mapMutations } from 'vuex';
import {
  getUpdatedLegacyNicheMappingsBasedOnPrimaryNiche,
  isNicheDataPrefilled,
} from '@/onboarding2/niche.domain';
import { checkAppForRenewalNewOrOldFunnel } from '@/onboarding/router/newVsOldFunnelGuards';
import { newFunnelEnabled } from '@/onboarding/router/newFunnelHelper';
import { RENEWAL_LANDING_PAGE_VIEWED } from '@/onboarding/services/SegmentEventTypes';

export default {
  name: 'RenewalStartCard',
  dependencies: ['featureFlags', 'requests', 'tracking'],
  extends: BaseCardStatic,
  async mounted() {
    const routeAway = await checkAppForRenewalNewOrOldFunnel(this);
    const isNewFunnelEnabled = await newFunnelEnabled();

    if (!routeAway && isNewFunnelEnabled) {
      this.$auth.isAuthenticated ? this.markUserAsVueFunnel() : this.markAnonymousUserAsVueFunnel();
    }

    if (!routeAway) {
      this.tracking.sendEvent(RENEWAL_LANDING_PAGE_VIEWED);
    }
  },
  created() {
    this.cardData = {
      title: `Welcome back ${getCompanyName(this)}, and congratulations on another year!`,
      description:
        "Let's make sure your company information is up to date and refresh your coverage.",
    };
    const applicationData = getApplicationData(this);
    if (
      this.featureFlags.getFlag({ flag: 'hide-niche-determination-questions' }) &&
      applicationIsProgram2(this) &&
      !isNicheDataPrefilled(applicationData)
    ) {
      const primaryNiche = applicationData['2021-06-01--PRIMARY_NICHE'];
      const data = getUpdatedLegacyNicheMappingsBasedOnPrimaryNiche(primaryNiche);
      if (data) {
        this.sendLegacyNicheMappingData(data);
      }
    }
  },
  methods: {
    ...mapMutations(['updateApplicationData']),
    sendLegacyNicheMappingData(data, onSuccess) {
      const defaultSuccess = serverData => {
        this.onSubmitSuccess(serverData);
      };

      this.requests.updateApplicationData({
        context: this,
        applicationId: this.applicationId,
        path: 'program_2_pre_auth',
        data,
        onSuccess: onSuccess || defaultSuccess,
        onError: data => {
          this.onSubmitError(data);
        },
      });
    },
    markAnonymousUserAsVueFunnel() {
      window.analytics.identify({ 'web-app-rewrite-test': 'vue' });
    },
    markUserAsVueFunnel() {
      const email = this.$auth.getUserEmail();
      window.analytics.identify(email, { 'web-app-rewrite-test': 'vue' });
    },
    onSubmitError(data) {
      window.vueRoot.$rollbar.error('Unhandled niche onSubmitError', data);
    },
    async onSubmitSuccess(applicationData) {
      this.$store.commit('clearSubmitData');
      await this.updateApplicationData({ applicationData });
    },
  },
};
</script>
