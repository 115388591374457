<template>
  <div class="status-page-wrapper">
    <slot name="banner-content" />
    <v-container
      class="status-page"
    >
      <div>
        <v-img
          class="status-image"
          :src="image"
          height="125px"
          width="200px"
          :contain="true"
        />
      </div>
      <div
        class="status-title label"
        data-testid="status-page-title"
      >
        {{ title }}
      </div>
      <div
        class="status-primary-reason"
        data-testid="status-page-primary-reason"
      >
        <slot name="primary-reason-content" />
      </div>
      <div
        class="status-description"
        data-testid="status-page-description"
      >
        <slot name="description-content" />
      </div>
      <div
        v-if="$scopedSlots['status-button']"
        class="status-button"
        data-testid="status-page-buttons"
      >
        <slot name="status-button" />
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'GenericStatusPage',
  props: {
    title: {
      required: true,
      type: String,
    },
    image: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.status-page-wrapper {
  background-color: $vouch-primary-light;
  height: 100%;
}

.status-page {
  max-width: $white-status-page-max-width;
  text-align: center;
  background-color: $vouch-primary-light;

  p {
    margin-bottom: $space-medium;
  }
}

.status-image {
  margin: $space-xlarge auto 0 auto;
}

.status-title {
  margin-top: $space-large;
}

.status-description {
  margin-top: $space-medium;
}

.status-primary-reason {
  margin-top: $space-medium;
  font-weight: bold;
}

.status-button {
  margin-top: $space-large;
}
</style>
