import { render, staticRenderFns } from "./QuestionCardTitle.vue?vue&type=template&id=79c23aee&scoped=true&"
import script from "./QuestionCardTitle.vue?vue&type=script&lang=js&"
export * from "./QuestionCardTitle.vue?vue&type=script&lang=js&"
import style0 from "./QuestionCardTitle.vue?vue&type=style&index=0&id=79c23aee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79c23aee",
  null
  
)

export default component.exports