<template>
  <ScrollThresholdListener
    v-slot="{ isPastThreshold }"
    :threshold="50"
  >
    <div
      data-testid="header-space-container"
      :style="{ height: `${headerHeight}px` }"
    >
      <header
        ref="header"
        class="header"
        :class="{ floating: isPastThreshold }"
      >
        <div
          v-if="$scopedSlots['banner-content']"
          class="header-banner"
        >
          <slot name="banner-content" />
        </div>
        <div class="header-nav">
          <slot />
        </div>
      </header>
    </div>
  </ScrollThresholdListener>
</template>

<script>
import ScrollThresholdListener from '@/onboarding/views/ScrollThresholdListener';
import getClientHeight from '@/onboarding/views/getClientHeight';

export default {
  name: 'HeaderFixed',
  components: { ScrollThresholdListener },
  props: {},
  data() {
    return {
      headerHeight: this.headerHeight,
    };
  },
  mounted() {
    this.updateHeight();
  },
  updated() {
    this.updateHeight();
  },
  methods: {
    updateHeight() {
      const newHeight = getClientHeight(this.$refs.header);
      if (this.headerHeight !== newHeight) {
        // don't you dare infinite loop
        this.headerHeight = newHeight;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  border-bottom: 1px solid transparent;
  transition: border-bottom-color 300ms, filter 300ms;

  &.floating {
    border-bottom: 1px solid $header-border-color;
    filter: drop-shadow($header-drop-shadow);
  }
}
</style>
