<script>
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';
import SchemaFormFieldMultiSelect from '@/onboarding/components/formfields/SchemaFormFieldMultiSelect';

export default {
  name: 'FtInvestmentServicesActivitiesCard',
  components: { SchemaFormFieldMultiSelect },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('program_2_post_auth'),
      submitPath: getUpdateEndpoint('program_2_post_auth'),
      cardConfigPath: '2022-12-15--FT_INVESTMENT_SERVICES_ACTIVITIES',
      formFieldData: [
        {
          component: SchemaFormFieldMultiSelect,
          schemaPath: '2022-12-15--FT_INVESTMENT_SERVICES_ACTIVITIES',
          dataPath: '2022-12-15--FT_INVESTMENT_SERVICES_ACTIVITIES',
        },
      ],
    };
  },
};
</script>
