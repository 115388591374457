<template>
  <QuestionCardConfigFrame
    v-slot="{ hasBackButton, percentComplete, minutesLeft, currentNavStep }"
    :card-id="cardId"
    :funnel-config="funnelConfig"
    :time-total="timeTotal"
  >
    <WizardPageWrapper
      header-text="Get Your Quote"
      :has-back-button="hasBackButton"
      :progress="percentComplete"
      :minutes-left="minutesLeft"
    >
      <template v-slot:left-panel>
        <OnboardingNavigationBar
          class="nav-bar"
          :current-nav-step="currentNavStep"
        />
      </template>
      <router-view
        :card-id="cardId"
        :application-id="applicationId"
        v-bind="$attrs"
        :minutes-left="minutesLeft"
      />
      <template v-slot:below-card-content>
        <router-view
          :application-id="applicationId"
          name="belowCardContent"
        />
      </template>
    </WizardPageWrapper>
  </QuestionCardConfigFrame>
</template>

<script>
import { funnelWizardConfig } from '@/onboarding/services/funnelWizardConfig';
import { funnelWizardConfigProgram2 } from '@/onboarding/services/funnelWizardConfigProgram2';
import { getCardId } from '@/shared/routing/getCardId';
import { Funnel } from '@/onboarding/constants/Constants';
import OnboardingNavigationBar from '@/onboarding/components/wizard/OnboardingNavigationBar';
import WizardPageWrapper from '@/shared/components/wizard/WizardPageWrapper';
import QuestionCardConfigFrame from '@/shared/components/wizard/QuestionCardConfigFrame';
import { applicationIsProgram2 } from '@/onboarding/lib/selectors/storeSelectors';
import { checkAppForNewOrOldFunnel } from './newVsOldFunnelGuards';

export default {
  name: 'OnboardingQuestionBase',
  components: {
    QuestionCardConfigFrame,
    WizardPageWrapper,
    OnboardingNavigationBar,
  },
  dependencies: ['trackingSetup', 'featureFlags', 'store'],
  props: {
    applicationId: {
      type: String,
      required: true,
    },
  },
  computed: {
    cardId() {
      return getCardId(this.$route);
    },
    isProgram2() {
      return applicationIsProgram2(this);
    },
    funnelConfig() {
      return this.isProgram2 ? funnelWizardConfigProgram2 : funnelWizardConfig;
    },
    timeTotal() {
      return Funnel.assumedMinutes;
    },
  },
  async mounted() {
    await checkAppForNewOrOldFunnel(this);
  },
};
</script>
