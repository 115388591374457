<template>
  <v-row no-gutters>
    <v-col cols="6">
      <v-text-field
        :id="inputId"
        v-money="{ precision: 0 }"
        :aria-labelledby="showDescription ? descriptionId : null"
        :aria-describedby="showWarning ? warningId : null"
        :value="value / 100"
        prefix="$"
        filled
        min="0"
        :maxlength="19"
        :error="error"
        :error-count="1"
        :error-messages="errorMessage"
        :color="warningMessage && 'warning'"
        hide-details="auto"
        :placeholder="placeholder"
        :label="label"
        @input="handleInput"
        @blur="markTouched"
      />
    </v-col>
    <v-col
      cols="6"
      class="currency-description-container"
    >
      <div
        v-if="showDescription"
        :id="descriptionId"
        class="currency-description"
      >
        {{ description }}
      </div>
    </v-col>
    <v-col
      v-if="showWarning"
      cols="6"
    >
      <VouchIcon
        name="warning"
        class="icon-warning"
      />
      <span
        :id="warningId"
        class="text-caption text--secondary"
      >{{ warningMessage }}</span>
    </v-col>
  </v-row>
</template>

<script>
import { VMoney } from 'v-money';
import VouchIcon from '@/shared/components/ui/atoms/icons/VouchIcon';

export default {
  name: 'FormFieldBigMoney',
  directives: { money: VMoney },
  components: {
    VouchIcon,
  },
  props: {
    error: {
      type: Boolean,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    warningMessage: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Number,
      required: false,
      default: undefined,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    description: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      touched: false,
    };
  },
  computed: {
    showWarning() {
      return !!this.warningMessage && this.touched;
    },
    showDescription() {
      return !!this.description;
    },
    inputId() {
      return `input-${this._uid}`;
    },
    descriptionId() {
      return `description-${this._uid}`;
    },
    warningId() {
      return `warning-${this._uid}`;
    },
  },
  methods: {
    handleInput(rawValue) {
      const value = rawValue.replace(/,/g, '');
      const valueInCents = Math.max(0, parseInt(value, 10) * 100);

      this.$emit('input', Number.isNaN(valueInCents) ? null : valueInCents);
    },
    markTouched() {
      this.touched = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@include text-field-filled-bg-color;

.currency-description-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.currency-description {
  margin-left: $intra-form-spacing;
}

.icon-warning {
  margin-right: 5px;
  color: $warning-alert-icon-color;
}
</style>
