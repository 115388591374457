/* istanbul ignore file */
// this file is strictly configuration, and aligns with the 'humble object' pattern, so testing isn't necessary.

import {
  annualRevenueContainerCard,
  benefitsWorthCard,
  currentClaimsDescriptionCard,
  deniedCoverageDescriptionCard,
  has401kCard,
  hasERISACard,
  hasAnyPiiCard,
  hasEngineeringContractorsCard,
  hasOtherBoardDirectorsCard,
  hasPendingClaimsCard,
  hasProductInMarketCard,
  insuranceHistoryContainerCard,
  legalEntityContainerCard,
  liabilitiesClaimsItemizedContainerCard,
  locationContainerCard,
  locationContainerAttributesCard,
  locationContainerAttributesAndAdditionalCard,
  numEngineeringContractorsCard,
  numInvoluntaryTerminationCard,
  numPlannedInvoluntaryTerminationCard,
  pendingClaimsDescriptionCard,
  piiAmountCard,
  totalCashAndEquivalentsCentsCard,
  yearIncorporatedCard,
} from '@/shared/routing/questionDecisions';

export const coreRoutingDecisions = [
  legalEntityContainerCard,
  yearIncorporatedCard,
  hasProductInMarketCard,
  hasOtherBoardDirectorsCard,
  locationContainerCard,
  locationContainerAttributesCard,
  locationContainerAttributesAndAdditionalCard,
  hasEngineeringContractorsCard,
  numEngineeringContractorsCard,
  numInvoluntaryTerminationCard,
  numPlannedInvoluntaryTerminationCard,
  has401kCard,
  hasERISACard,
  benefitsWorthCard,
  annualRevenueContainerCard,
  totalCashAndEquivalentsCentsCard,
  hasAnyPiiCard,
  piiAmountCard,
  insuranceHistoryContainerCard,
  currentClaimsDescriptionCard,
  deniedCoverageDescriptionCard,
  liabilitiesClaimsItemizedContainerCard,
  hasPendingClaimsCard,
  pendingClaimsDescriptionCard,
];
