<template>
  <div>
    <FormFieldNumber
      :value="inputValue"
      :error="isValid === false"
      :error-message="errorMessage"
      @input="setInputValue"
    />
    <v-row
      v-show="isValid !== false"
      no-gutters
    >
      <v-col cols="12">
        <div class="spacing">
          <PreviousAnswer :value="inputParentValue" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseSchemaFormField from './BaseSchemaFormField';
import FormFieldNumber from '@/shared/components/ui/atoms/form_fields/FormFieldNumber';
import PreviousAnswer from '@/shared/components/wizard/PreviousAnswer';

export default {
  name: 'SchemaFormFieldNumber',
  components: { FormFieldNumber, PreviousAnswer },
  extends: BaseSchemaFormField,
};
</script>

<style lang="scss" scoped>
.spacing {
  padding: $space-xsmall 0 $space-xsmall;
}
</style>
