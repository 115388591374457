<template>
  <VouchCard
    class="card"
    hoverable
    padded
    @click="onClickCard"
  >
    <slot name="above-title" />
    <div class="card-heading">
      <h2 class="card-title">
        {{ title }}
      </h2>
    </div>
    <div class="card-body">
      <slot />
    </div>
    <div class="button-row">
      <ButtonSpecial
        v-if="isSpecial"
        :data-testid="testId"
        class="card-main-action-mobile"
        @click="onClickButton"
      >
        {{ buttonText }}
      </ButtonSpecial>
      <ButtonPrimary
        v-else
        :data-testid="testId"
        class="card-main-action-mobile"
        @click="onClickButton"
      >
        {{ buttonText }}
      </ButtonPrimary>
      <slot name="right-of-button" />
    </div>
    <div
      v-if="$scopedSlots['footer-content']"
      class="card-footer"
    >
      <slot name="footer-content" />
    </div>
  </VouchCard>
</template>
<script>
import ButtonPrimary from '@/shared/components/ui/atoms/buttons/ButtonPrimary';
import ButtonSpecial from '@/shared/components/ui/atoms/buttons/ButtonSpecial';
import VouchCard from '@/shared/components/ui/atoms/VouchCard';

export default {
  name: 'CardWithButton',
  components: {
    VouchCard,
    ButtonPrimary,
    ButtonSpecial,
  },
  props: {
    testId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
    },
    isSpecial: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    onClickButton() {
      this.$emit('click');
    },
    onClickCard(e) {
      if (e.target.closest('button')) {
        return;
      }

      this.$emit('click');
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  background-color: $card-background-color;

  cursor: pointer;

  .card-title {
    @include h4-text;
  }

  .card-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card-body {
    margin-top: $space-small;
  }

  .card-main-action {
    margin-left: $space-medium;
  }

  .card-main-action-mobile {
    margin-top: $space-medium;
    margin-right: $space-medium;
  }

  .button-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .card-footer {
    margin: $card-vertical-whitespace (-$card-horizontal-whitespace) (-$card-vertical-whitespace);
  }
}
</style>
