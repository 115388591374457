import Env from '../../shared/services/Env';
import LogRocket from 'logrocket';
import featureFlagging from '@/onboarding/services/FeatureFlaggingService';
import { TRACKING_SETUP_BOOT } from './SegmentEventTypes';

// String values for feature flags used for experiments
export enum ExperimentFlags {
  authBeforeMadlib = 'authenticate-before-madlib',
  baselineAATest = 'baseline-a-a-test',
  collectPhoneNumberOnReview = 'collect-phone-number-on-review',
  packageSelectionCustomerCta = 'package-selection-customize-cta',
  quoteCardsEditabilityRedesign = 'quote-cards-editability-redesign',
}

class TrackingServiceSetup {
  getStatusOfAllExperiments() {
    const allExperiments = {};
    Object.values(ExperimentFlags).forEach(flag => {
      allExperiments[flag] = featureFlagging.getFlag({ flag });
    });
    return allExperiments;
  }

  boot({ email, context, applicationId, companyName }) {
    this.bootLogRocket({ email });

    this.trackSegmentInit({
      email,
      applicationId,
      companyName,
    });
  }

  bootLogRocket({ email }) {
    const { logRocketKey } = Env.getConfig();
    if (logRocketKey !== '' && email) {
      LogRocket.identify(email, { email });
    }
  }

  trackSegmentInit({ email, applicationId, companyName }) {
    window.analytics.track(
      TRACKING_SETUP_BOOT,
      JSON.parse(
        JSON.stringify({
          email,
          applicationId,
          companyName,
        })
      )
    );
  }

  identifyForTracking({ email, applicationId, companyName }) {
    const experimentNamesAndStatus = this.getStatusOfAllExperiments();

    window.analytics.identify(email, {
      userId: email,
      email,
      applicationId,
      companyName,
      ...experimentNamesAndStatus,
    });
  }
}
const trackingSetup = new TrackingServiceSetup();
export default trackingSetup;
