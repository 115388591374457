<script src="../../../router.ts"></script>
<script>
import BaseCardStatic from '@/shared/components/wizard/BaseCardStatic';
import selectors from '@/onboarding/lib/selectors/decisionSelectors';
import { sendToAccountPath } from '@/onboarding/router/routeHelper';

export default {
  name: 'RenewalEndCard',
  extends: BaseCardStatic,
  data() {
    return { submitText: 'Back to my account' };
  },
  async created() {
    const { applicationId } = this;

    this.cardData = {
      title: 'Thanks for updating your information!',
      description:
        'Our Insurance Advisors will assess your coverage based on your new information and provide you with an updated recommendation and quote within the next two business days.',
    };
    await this.fetchPreCoreDecisionData({ applicationId });
    this.decisionData = getPreCoreDecisionData(this);

    if (this.isNoDecision) {
      this.questionRouter.abort(this);
    }
  },
  methods: {
    onSubmit() {
      sendToAccountPath('/members');
    },
  },
};
</script>
