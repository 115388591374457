const initialState = {
  reviewErrors: 0,
  setReviewErrors: false,
};

export const getters = {
  hasReviewErrors: state => {
    return state.reviewErrors > 0;
  },
  hasSetReviewErrors: state => {
    return state.setReviewErrors;
  },
};

export const actions = {};

export const mutations = {
  setReviewErrors: (state, { errorCount }) => {
    state.setReviewErrors = true;
    state.reviewErrors = errorCount;
  },
  wipeReviewErrors: state => {
    state.setReviewErrors = false;
    state.reviewErrors = 0;
  },
};

export default {
  state: initialState,
  getters,
  actions,
  mutations,
};
