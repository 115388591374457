<template>
  <div>
    <QuestionCardTitle
      data-testid="card-title"
    >
      <slot name="title" />
    </QuestionCardTitle>
    <QuestionCardDescription class="description">
      <slot name="description" />
    </QuestionCardDescription>
    <!-- eslint-enable -->
    <slot />
    <div class="card-actions">
      <ButtonPrimary
        type="submit"
        :loading="loading"
        :disabled="isSubmitDisabled"
        class="wizard-card-form-submit"
        data-testid="submit-button"
      >
        {{ submitText }}
      </ButtonPrimary>
    </div>
  </div>
</template>

<script>
import ButtonPrimary from '../atoms/buttons/ButtonPrimary';
import QuestionCardTitle from '@/shared/components/ui/atoms/question_card/QuestionCardTitle';
import QuestionCardDescription from '@/shared/components/ui/atoms/question_card/QuestionCardDescription';

export default {
  name: 'QuestionCardBody',
  components: { QuestionCardDescription, QuestionCardTitle, ButtonPrimary },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    isSubmitDisabled: {
      type: Boolean,
      required: false,
    },
    submitText: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.card-actions {
  margin-top: $card-spacing;
}

.description {
  margin-top: $form-spacing;
  margin-bottom: $form-spacing;
}

.card-form {
  width: 100%;
}
</style>
