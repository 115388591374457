<template>
  <div>
    <FormFieldMultiSelect
      :value="inputValue"
      :error="isValid === false"
      :error-messages="errorMessage"
      :items="propertyDefinition.items.enum"
      :multi-select-descriptions="itemDescriptions"
      @input="setInputValue"
    />
    <div class="spacing">
      <PreviousAnswer :value="inputParentValue" />
    </div>
  </div>
</template>

<script>
import BaseSchemaFormField from '@/onboarding/components/formfields/BaseSchemaFormField';
import FormFieldMultiSelect from '@/shared/components/ui/atoms/form_fields/FormFieldMultiSelect';
import { applicationIsProgram2 } from '@/onboarding/lib/selectors/storeSelectors';
import { getUIConfig } from '@/onboarding/services/questionUIConfig';
import { get } from '@/shared/lib/vouch_dash';
import PreviousAnswer from '@/shared/components/wizard/PreviousAnswer';

export default {
  name: 'SchemaFormFieldMultiSelect',
  components: { FormFieldMultiSelect, PreviousAnswer },
  extends: BaseSchemaFormField,
  computed: {
    isProgram2() {
      return applicationIsProgram2(this);
    },
    itemDescriptions() {
      return get(
        getUIConfig(this.propertyName, this.isProgram2),
        'question.multiSelectDescriptions',
        {}
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.spacing {
  padding: $space-xsmall 0 $space-xsmall;
}
</style>
