import withFeatureFlagSwitch from '@/shared/frames/withFeatureFlagSwitch';

import SVBCreditSolutionsBanner from '@/onboarding/components/page_banners/SVBCreditSolutionsBanner.vue';
import Brex10kPointsBanner from '@/onboarding/components/page_banners/Brex10kPointsBanner.vue';
import SVBEdgeBanner from '@/onboarding/components/page_banners/SVBEdgeBanner.vue';
import CheckoutHeaderStandard from '@/onboarding/views/wizard/card/checkout/checkout_page_header/CheckoutHeaderStandard.vue';
import CheckoutHeaderSVBC from '@/onboarding/views/wizard/card/checkout/checkout_page_header/CheckoutHeaderSVBC.vue';
import CheckoutHeaderSVBEdge from '@/onboarding/views/wizard/card/checkout/checkout_page_header/CheckoutHeaderSVBEdge.vue';
import CheckoutFooterPayWhenYouRaise from '@/onboarding/views/wizard/card/checkout/checkout_page_footer/CheckoutFooterPayWhenYouRaise.vue';
import CheckoutFormSectionSvbcCoiAgreement from '@/onboarding/views/wizard/card/checkout/checkout_form_sections/CheckoutFormSectionSvbcCoi.vue';
import CheckoutFormSectionPrice from '@/onboarding/views/wizard/card/checkout/checkout_form_sections/CheckoutFormSectionPrice.vue';
import CheckoutFormSectionPricePayWhenYouRaise from '@/onboarding/views/wizard/card/checkout/checkout_form_sections/CheckoutFormSectionPricePayWhenYouRaise.vue';
import CheckoutFormSectionPriceSvbEdge from '@/onboarding/views/wizard/card/checkout/checkout_form_sections/CheckoutFormSectionPriceSvbEdge.vue';
import OfficerSignatureFrame from '@/onboarding/views/dead_ends/officer_signature_page/OfficerSignatureFrame.vue';
import OfficerSignatureFramePWYR from '@/onboarding/views/dead_ends/officer_signature_page/OfficerSignatureFramePWYR.vue';
import OfficerSignatureFrameSVBCreditSolutions from '@/onboarding/views/dead_ends/officer_signature_page/OfficerSignatureFrameSVBCreditSolutions.vue';
import OfficerSignatureFrameSVBEdge from '@/onboarding/views/dead_ends/officer_signature_page/OfficerSignatureFrameSVBEdge.vue';
import CheckoutFormSectionBrex10KPointsPromo from '@/onboarding/views/wizard/card/checkout/checkout_form_sections/CheckoutFormSectionBrex10KPointsPromo.vue';
import CheckoutFormSectionSvbePromo from '@/onboarding/views/wizard/card/checkout/checkout_form_sections/CheckoutFormSectionSvbePromo.vue';

import Brex10kPointsDisclaimer from '@/onboarding/views/wizard/card/checkout/checkout_page_footer/Brex10kPointsDisclaimer.vue';
import RenewalDiscountBanner from '@/onboarding/components/page_banners/RenewalDiscountBanner.vue';
import withConditionSwitch from '@/shared/frames/withConditionSwitch';
import { hasRenewalDiscount } from '@/onboarding/lib/selectors/quote_selectors';
import { eligibleForPartnerDiscounts } from '@/onboarding/lib/selectors/storeSelectors';
import { PaymentMethod } from '@/onboarding/services/PaymentExperience';

interface PackageItem {
  slug: string;
  billingHeaderComponent: null | Record<never, any>;
  checkoutFormOfficerSignCancelReminder: boolean;
  checkoutFormPrice: Record<never, any>;
  checkoutFormSvbcCoiComponent: false | Record<never, any>;
  checkoutPageFooterComponent: false | Record<never, any>;
  checkoutPageHeaderComponent: null | Record<never, any>;
  officerSignatureComponent: Record<never, any>;
  packageTitleLong: string;
  creditCardEnabled: boolean;
  checkoutPromoComponent?: null | Record<never, any>;
  partnerName?: string;
}

const sharedPackageItems = {
  billingHeaderComponent: null,
  checkoutFormOfficerSignCancelReminder: true,
  checkoutFormSvbcCoiComponent: false,
  checkoutPageFooterComponent: false,
  creditCardEnabled: false,
};

export const basicPackage: PackageItem = {
  ...sharedPackageItems,
  slug: 'basic',
  checkoutFormPrice: CheckoutFormSectionPrice,
  checkoutPageHeaderComponent: CheckoutHeaderStandard,
  officerSignatureComponent: OfficerSignatureFrame,
  packageTitleLong: 'Basic Package',
};

export const recommendedPackage: PackageItem = {
  ...sharedPackageItems,
  slug: 'recommended',
  checkoutFormPrice: CheckoutFormSectionPrice,
  checkoutPageHeaderComponent: CheckoutHeaderStandard,
  checkoutPromoComponent: null,
  officerSignatureComponent: OfficerSignatureFrame,
  packageTitleLong: 'Customizable Package',
};

export const renewalPackage: PackageItem = {
  ...sharedPackageItems,
  slug: 'renewal',
  billingHeaderComponent: withConditionSwitch(hasRenewalDiscount)(RenewalDiscountBanner)(null),
  checkoutFormPrice: CheckoutFormSectionPrice,
  checkoutPageHeaderComponent: withConditionSwitch(hasRenewalDiscount)(RenewalDiscountBanner)(
    CheckoutHeaderStandard
  ),
  checkoutPromoComponent: null,
  officerSignatureComponent: OfficerSignatureFrame,
  packageTitleLong: "Last Year's Package",
};

export const recommendedRenewalPackage: PackageItem = {
  ...sharedPackageItems,
  slug: 'recommended_renewal',
  billingHeaderComponent: withConditionSwitch(hasRenewalDiscount)(RenewalDiscountBanner)(null),
  checkoutFormPrice: CheckoutFormSectionPrice,
  checkoutPageHeaderComponent: withConditionSwitch(hasRenewalDiscount)(RenewalDiscountBanner)(
    CheckoutHeaderStandard
  ),
  checkoutPromoComponent: null,
  officerSignatureComponent: OfficerSignatureFrame,
  packageTitleLong: 'Recommended Package',
};

const sharedBrexPackage = {
  // Use credit card as default payment method when we display the promo banner
  defaultPaymentMethod: promoComponent => {
    return promoComponent ? PaymentMethod.CREDIT_CARD : PaymentMethod.ACH;
  },
  checkoutPromoComponent: withFeatureFlagSwitch('brex-10k-points-package')(null)(
    withConditionSwitch(eligibleForPartnerDiscounts)(CheckoutFormSectionBrex10KPointsPromo)(null)
  ),
  billingHeaderComponent: withFeatureFlagSwitch('brex-10k-points-package')(false)(
    withConditionSwitch(eligibleForPartnerDiscounts)(Brex10kPointsBanner)(false)
  ),
  checkoutPageHeaderComponent: withFeatureFlagSwitch('brex-10k-points-package')(
    CheckoutHeaderStandard
  )(withConditionSwitch(eligibleForPartnerDiscounts)(Brex10kPointsBanner)(CheckoutHeaderStandard)),
  checkoutPageFooterComponent: withFeatureFlagSwitch('brex-10k-points-package')(false)(
    withConditionSwitch(eligibleForPartnerDiscounts)(Brex10kPointsDisclaimer)(false)
  ),
  partnerName: 'BREX',
  creditCardEnabled: true,
};

export const brexPointsBasicPackage: PackageItem = {
  ...basicPackage,
  ...sharedBrexPackage,
  slug: 'brex_10k_points_basic',
};

export const brexPointsRecommendedPackage: PackageItem = {
  ...recommendedPackage,
  ...sharedBrexPackage,
  slug: 'brex_10k_points_recommended',
};

export const payWhenYouRaiseBasicPackage: PackageItem = {
  ...basicPackage,
  slug: 'pay_when_you_raise_basic',
  packageTitleLong: 'YC Startup Package',
  checkoutPageFooterComponent: withFeatureFlagSwitch('pay-when-you-raise')(false)(
    CheckoutFooterPayWhenYouRaise
  ),
  checkoutFormOfficerSignCancelReminder: false,
  checkoutFormPrice: withFeatureFlagSwitch('pay-when-you-raise')(CheckoutFormSectionPrice)(
    CheckoutFormSectionPricePayWhenYouRaise
  ),
  officerSignatureComponent: withFeatureFlagSwitch('pay-when-you-raise')(OfficerSignatureFrame)(
    OfficerSignatureFramePWYR
  ),
  billingHeaderComponent: null,
};

export const payWhenYouRaiseRecommendedPackage: PackageItem = {
  ...recommendedPackage,
  slug: 'pay_when_you_raise_recommended',
};

export const svbCreditSolutionsBasicPackage: PackageItem = {
  ...basicPackage,
  slug: 'svb_credit_solutions_basic',
  packageTitleLong: 'Basic Package',
  checkoutFormSvbcCoiComponent: false,
  checkoutPageHeaderComponent: CheckoutHeaderStandard,
  officerSignatureComponent: OfficerSignatureFrame,
  billingHeaderComponent: false,
  partnerName: 'SVB',
  creditCardEnabled: true,
};

export const svbCreditSolutionsRecommendedPackage: PackageItem = {
  ...recommendedPackage,
  slug: 'svb_credit_solutions_recommended',
  checkoutFormSvbcCoiComponent: false,
  billingHeaderComponent: false,
  partnerName: 'SVB',
  creditCardEnabled: true,
};

export const svbCreditSolutionsIneligiblePackage: PackageItem = {
  ...recommendedPackage,
  slug: 'svb_credit_solutions_ineligible',
  checkoutFormSvbcCoiComponent: false,
  billingHeaderComponent: null,
  partnerName: 'SVB',
};

export const svbEdgeSharedPackage = {
  // Use credit card as default payment method when we display the promo banner
  defaultPaymentMethod: promoComponent => {
    return promoComponent ? PaymentMethod.CREDIT_CARD : PaymentMethod.ACH;
  },
  checkoutPromoComponent: false,
  officerSignatureComponent: OfficerSignatureFrame,
  checkoutFormPrice: CheckoutFormSectionPrice,
  checkoutPageHeaderComponent: CheckoutHeaderStandard,
  checkoutFormOfficerSignCancelReminder: false,
  billingHeaderComponent: false,
  partnerName: 'SVB',
  creditCardEnabled: true,
};

export const svbEdgeBasicPackage: PackageItem = {
  ...basicPackage,
  ...svbEdgeSharedPackage,
  slug: 'svb_edge_basic',
};

export const svbEdgeRecommendedPackage: PackageItem = {
  ...recommendedPackage,
  ...svbEdgeSharedPackage,
  slug: 'svb_edge_recommended',
};

export const PackageConfig = [
  // order does not matter
  basicPackage,
  recommendedPackage,
  payWhenYouRaiseBasicPackage,
  payWhenYouRaiseRecommendedPackage,
  svbCreditSolutionsBasicPackage,
  svbCreditSolutionsIneligiblePackage,
  svbCreditSolutionsRecommendedPackage,
  svbEdgeBasicPackage,
  svbEdgeRecommendedPackage,
  renewalPackage,
  recommendedRenewalPackage,
  brexPointsBasicPackage,
  brexPointsRecommendedPackage,
].reduce((acc, config) => {
  acc[config.slug] = config;
  return acc;
}, {});
