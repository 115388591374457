<template>
  <span
    v-bind="$attrs"
    class="vouch-icon"
    v-on="$listeners"
  >
    <component
      :is="IconComponent"
      class="vouch-icon__icon"
    />
  </span>
</template>

<script>
import IconLoading from '@/shared/components/ui/atoms/icons/IconLoading';

/* eslint-disable global-require */
const ICON_MAP = Object.freeze({
  account: require('@/shared/assets/icons/account.svg'),
  accountPlus: require('@/shared/assets/icons/account-plus.svg'),
  add: require('@/shared/assets/icons/add.svg'),
  alarm: require('@/shared/assets/icons/alarm.svg'),
  arrowLeft: require('@/shared/assets/icons/arrow-left.svg'),
  cancel: require('@/shared/assets/icons/cancel.svg'),
  certificate: require('@/shared/assets/icons/certificate-outline.svg'),
  checkmark: require('@/shared/assets/icons/checkmark.svg'),
  chevronLeft: require('@/shared/assets/icons/chevron-left.svg'),
  clear: require('@/shared/assets/icons/clear.svg'),
  create: require('@/shared/assets/icons/create.svg'),
  creditCardRemove: require('@/shared/assets/icons/credit-card-remove-outline.svg'),
  delete: require('@/shared/assets/icons/delete.svg'),
  documentEdit: require('@/shared/assets/icons/file-document-edit-outline.svg'),
  download: require('@/shared/assets/icons/download.svg'),
  draw: require('@/shared/assets/icons/draw.svg'),
  error: require('@/shared/assets/icons/error.svg'),
  fire: require('@/shared/assets/icons/fire.svg'),
  greenCheck: require('@/shared/assets/icons/green-check.svg'),
  help: require('@/shared/assets/icons/help.svg'),
  info: require('@/shared/assets/icons/info.svg'),
  kebab: require('@/shared/assets/icons/kebab.svg'),
  loading: IconLoading,
  lock: require('@/shared/assets/icons/lock.svg'),
  longArrow: require('@/shared/assets/icons/long-arrow.svg'),
  mail: require('@/shared/assets/icons/mail.svg'),
  restore: require('@/shared/assets/icons/restore.svg'),
  warning: require('@/shared/assets/icons/warning.svg'),
  wateringCan: require('@/shared/assets/icons/watering-can.svg'),
  x: require('@/shared/assets/icons/x.svg'),
});
/* eslint-enable global-require */

export const ICON_NAMES = Object.freeze(Object.keys(ICON_MAP).sort());

export default {
  name: 'VouchIcon',
  props: {
    /**
     * Name of the icon to display
     */
    name: {
      required: true,
      type: String,
      validator(name) {
        return ICON_NAMES.includes(name);
      },
    },
  },
  computed: {
    IconComponent() {
      return ICON_MAP[this.name];
    },
  },
};
</script>

<style lang="scss" scoped>
.vouch-icon {
  width: $vouch-icon-size;
  height: $vouch-icon-size;
  vertical-align: middle;

  display: inline-flex;

  align-items: center;
  justify-content: center;

  &__icon {
    display: block;
    position: absolute;
  }

  svg {
    width: $vouch-icon-size;
    height: $vouch-icon-size;

    path {
      fill: currentColor;
    }
  }
}
</style>
