import Vue from 'vue';

const withFeatureFlagSwitch = flagName => DisabledComponent => EnabledComponent =>
  Vue.extend({
    name: 'WithFeatureFlagSwitch',
    inject: ['featureFlags'],
    functional: true,
    render(h, context) {
      const enabled = context.injections.featureFlags.getFlag({ flag: flagName });
      if (enabled) {
        return h(EnabledComponent, {
          attrs: context.props,
          on: context.listeners,
        });
      }

      return h(DisabledComponent, {
        attrs: context.props,
        on: context.listeners,
      });
    },
  });

export default withFeatureFlagSwitch;
