import Router from 'vue-router';

const overridePush = router => {
  const originalPush = router.push;

  router.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalPush.call(this, location, onResolve, onReject);
    }

    return originalPush.call(this, location).catch(err => {
      if (Router.isNavigationFailure(err)) {
        return err;
      }

      return Promise.reject(err);
    });
  };
};

const overrideReplace = router => {
  const originalReplace = router.replace;

  router.replace = function replace(location, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalReplace.call(this, location, onResolve, onReject);
    }

    return originalReplace.call(this, location).catch(err => {
      if (Router.isNavigationFailure(err)) {
        return err;
      }

      return Promise.reject(err);
    });
  };
};

const setupSuppressNavigationErrors = router => {
  overridePush(router);
  overrideReplace(router);
};

export default setupSuppressNavigationErrors;
