<template>
  <div>
    <div
      :class="{
        'review-item': true,
        invalid: !isValid,
      }"
    >
      <v-row no-gutters>
        <v-col
          cols="4"
          class="review-title"
        >
          {{ title }}
        </v-col>
        <v-col cols="7">
          <div :class="{ wrapped: wrap, 'review-field-aligned-value': true }">
            {{ formattedValue(value) }}
          </div>
        </v-col>
        <v-col
          cols="1"
          class="review-field-edit-col"
        >
          <button
            v-if="isEditable"
            @click="navigate"
          >
            <VouchIcon
              v-if="!isValid"
              name="error"
            />
            <VouchIcon name="create" />
          </button>
          <VouchIcon
            v-else
            name="lock"
          />
        </v-col>
      </v-row>
    </div>
    <v-row no-gutters>
      <v-col cols="11">
        <div class="spacing">
          <div v-show="value">
            <PreviousAnswer :value="parentValue" />
          </div>
        </div>
      </v-col>
      <v-spacer cols="1" />
    </v-row>
  </div>
</template>

<script>
import VouchIcon from '@/shared/components/ui/atoms/icons/VouchIcon';
import PreviousAnswer from '@/shared/components/wizard/PreviousAnswer';
import { unescape } from 'lodash';

export default {
  name: 'ReviewField',
  components: {
    VouchIcon,
    PreviousAnswer,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      required: true,
    },
    parentValue: {
      type: [String, Number, Boolean],
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    isValid: {
      required: true,
      type: Boolean,
    },
    route: {
      type: Object,
      required: false,
      default: null,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    wrap: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    navigate() {
      this.$router.push(this.route);
    },
    formattedValue(val) {
      return unescape(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.review-title {
  padding-right: $space-medium;
  @include label-text;
}

.review-item {
  border-radius: $corner-roundness;
  border-bottom: 1px solid $review-row-border-color;
  padding: $space-small;
  transition: border-color 100ms;
  @include detail-text;
}

.review-item:hover {
  background-color: $review-row-hover-color;
}

.review-item.invalid {
  color: $review-row-error-color;
  border-bottom: 1px solid $review-row-error-color;
}

.review-field-edit-col {
  text-align: right;
}

.review-field-aligned-value {
  text-align: right;

  &.wrapped {
    white-space: pre;
  }
}
.spacing {
  padding: $space-xsmall $space-xsmall $space-medium;
}
</style>
