<script>
import BaseCardWithFields from '../../../../components/wizard/BaseCardWithFields';
import SchemaFormFieldTextArea from '../../../../../onboarding/components/formfields/SchemaFormFieldTextArea';
import {
  getSchemaEndpoint,
  getUpdateEndpoint,
} from '../../../../../onboarding/lib/selectors/apiPathSelectors';

export default {
  name: 'PendingClaimsDescriptionCard',
  components: { SchemaFormFieldTextArea },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('core'),
      submitPath: getUpdateEndpoint('core'),
      cardConfigPath: '2019-07-01--PENDING_CLAIMS_DESCRIPTION',
      formFieldData: [
        {
          component: SchemaFormFieldTextArea,
          schemaPath: '2019-07-01--PENDING_CLAIMS_DESCRIPTION',
          dataPath: '2019-07-01--PENDING_CLAIMS_DESCRIPTION',
        },
      ],
    };
  },
};
</script>
