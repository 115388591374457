import EnvironmentConfig from '@/shared/constants/EnvironmentConfig';
import { VouchEnv } from '@/shared/types/internal/globalTypes';

const env =
  process.env.VOUCH_ENV ||
  window.VOUCH_ENV ||
  process.env.VUE_APP_VOUCH_ENV ||
  process.env.NODE_ENV;

const getConfig = () => EnvironmentConfig[env as VouchEnv];
const getEnvName = () => env;
const isProduction = () => env === VouchEnv.production;

export default {
  getConfig,
  getEnvName,
  isProduction,
};
