import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Env from '@/shared/services/Env';
import { applicationIsProgram2 } from '@/onboarding/lib/selectors/storeSelectors';
import { clearDependentFields } from '@/onboarding/services/clearDependentFields';
import { clearDependentProg2Fields } from '@/onboarding/services/clearDependentFieldsProgram2';
import { checkAuthentication } from '@/shared/vue-plugins/auth0';
import { CompanyUser, CompanyUsersResponseDto } from '@/members/store/modules/companyUserTypes';
import featureFlagging from '@/onboarding/services/FeatureFlaggingService';
import { newFunnelEnabled, routeToNewFunnel } from '@/onboarding/router/newFunnelHelper';

// Helpers
const paShimV1URI = `${Env.getConfig().pa_shim_url}/v1`;
const peepsURI = Env.getConfig().peeps_url;
const graphqlURI = Env.getConfig().graphql_url;

// Madlib requests
const postUser = async userData =>
  axios.post(`${paShimV1URI}/users`, userData) as Promise<AxiosResponse>;

const postCompany = async companyData =>
  axios.post(`${paShimV1URI}/companies`, companyData) as Promise<AxiosResponse>;

const postApplication = async appData =>
  axios.post(`${paShimV1URI}/applications`, appData) as Promise<AxiosResponse>;

// Other requests
const catchBadUrls = maybeBadUrl => {
  if (new RegExp('undefined').exec(maybeBadUrl)) {
    window.vueRoot?.$rollbar?.error('Error in request, possibly a bad URL', { maybeBadUrl });
  }
};
const defaultOnError = e => {
  if (e.response) {
    if (e.response.status === 500) {
      window.vueRoot.$router.push({
        name: 'crumbs',
        params: {
          sourceUrl: window.location.href,
          error: JSON.stringify(e.response.data?.errors),
        },
      });
    } else if (e.response.status === 401) {
      window.vueRoot.$rollbar.log('Request made that requires authentication', e);
      // Some applications require auth and some don't (depending on how complete they are).
      // If an unauthed user tries a request that requires auth, we'll ask them to log in.
      checkAuthentication().then(async ({ isAuthenticated, isNewFunnelUser }) => {
        if (!isAuthenticated) {
          window.vueRoot.$router.replace('/loginportal/send' as any);
        } else {
          window.vueRoot.$rollbar.error('Request from logged-in user failed authentication', e);
          if (
            e.response.data.errorCode === 'user_not_found' &&
            isNewFunnelUser &&
            (await newFunnelEnabled())
          ) {
            // new funnel created an Auth0 user but hasn't created a Vouch user yet
            routeToNewFunnel();
          } else {
            window.vueRoot.$router.replace({
              name: 'AuthError',
              query: { errorCode: e.response.data.errorCode },
            });
          }
        }
      });
    } else if (e.response.status === 403) {
      window.vueRoot.$router.push('/loginportal/autherror' as any);
      window.vueRoot.$rollbar.error('Error making request', e);
    } else if (
      // User logs into /Members without a completed application
      e.response.status === 404 &&
      e.response.data.errors?.includes('no completed application for user')
    ) {
      window.vueRoot.$router.push('/loginportal/autherror' as any);
      window.vueRoot.$rollbar.error('Error making request', e);
    }
  } else {
    window.vueRoot.$rollbar.error('Error making request', e);
  }
};

// graphql request function
const requestWithGraphql = ({ query, variables, onSuccess, onError = defaultOnError }) => {
  return axios
    .post(graphqlURI, JSON.parse(JSON.stringify({ query, variables } || {})))
    .then(response => {
      onSuccess(response.data);
    })
    .catch(onError);
};

// Underlying Functions

const requestWithData = ({
  requestType,
  endpoint,
  data,
  params,
  onSuccess,
  onError = defaultOnError,
}) => {
  catchBadUrls(endpoint);
  if (requestType === 'delete') {
    return axios
      .delete(`${paShimV1URI}/${endpoint}`, { params })
      .then(response => {
        onSuccess(response.data);
      })
      .catch(onError);
  }

  return axios[requestType](`${paShimV1URI}/${endpoint}`, JSON.parse(JSON.stringify(data || {})))
    .then(response => {
      onSuccess(response.data);
    })
    .catch(onError);
};

const post = options => requestWithData({ ...options, requestType: 'post' });
const put = options => requestWithData({ ...options, requestType: 'put' });
const deleteRequest = options => requestWithData({ ...options, requestType: 'delete' });

const get = ({
  endpoint,
  onSuccess,
  onError = defaultOnError,
  opts = {},
}: {
  endpoint: string;
  onSuccess?: Function;
  onError?: any;
  opts?: AxiosRequestConfig;
  useV2Endpoint?: boolean;
}) => {
  catchBadUrls(endpoint);
  // consumers are using promises
  if (onSuccess) {
    return axios
      .get(`${paShimV1URI}/${endpoint}`, opts)
      .then(response => {
        onSuccess(response.data);
      })
      .catch(onError);
  }

  // consumers are using awaits
  return axios.get(`${paShimV1URI}/${endpoint}`, opts).catch(onError);
};

// External API

const postApplications = ({ data, onSuccess, onError }) =>
  post({
    endpoint: 'applications',
    data,
    onSuccess,
    onError,
  });

const postCompanies = ({ data, onSuccess, onError }) =>
  post({
    endpoint: 'companies',
    data,
    onSuccess,
    onError,
  });

const updatePaymentToken = ({ data, onSuccess, onError }) =>
  put({
    endpoint: 'member/payment',
    data,
    onSuccess,
    onError,
  });

// GrpahQL Gateway
const cancelDiscretion = ({ discretionId, onSuccess, onError }) =>
  requestWithGraphql({
    query: `
    mutation CancelDiscretion($discretionId: String!) {
      cancelDiscretion(discretionId: $discretionId) {
        ... on Package {
          slug
          activeQuote {
            id
          }
        }
      }
    }
    `,
    variables: { discretionId },
    onSuccess,
    onError,
  });

const getQuotePricing = ({ id, onSuccess, onError }) =>
  requestWithGraphql({
    query: `
    query GetQuote($id: String!) {
      quote(id: $id) {
        pricing {
          totalPremiumCents
          dueAtCheckout
          duePerTimeUnit
          taxes {
            uiToken
            descriptionToken
            amountCents
          }
          fees {
            uiToken
            descriptionToken
            amountCents
          }
          adjustments {
            uiToken
            active
            eligible
            premiumCents
            product {
              name
            }
          }
          discounts {
            uiToken
            active
            eligible
            premiumCents
            product {
              name
            }
          }
          billingInterval {
            interval
          }
        }
      }
    }
    `,
    variables: { id },
    onSuccess,
    onError,
  });

const getNicheMap = async () => (await get({ endpoint: 'application/niche_map' })) as AxiosResponse;

const getApplicationIndex = async () =>
  (await get({
    endpoint: 'applications',
    onError: async e => {
      // If the new auth-before-madlib workflow is active, return user to Madlib when applicationIndex returns
      // a 401 because no user was found for the authenticated email address
      // e.response will be defined when the request is made and the response has a non-2xx status code.
      // e.request will be defined when the request was made but no response received.
      if (e.response?.status === 401) {
        const canFetchIndexBeforeAppIsCreated = await featureFlagging.getFlagWhenReady({
          flag: 'authenticate-before-madlib',
        });
        if (canFetchIndexBeforeAppIsCreated) {
          e.preventDefault;
          return { data: { applications: [] } };
        }
      } else if (!e.response && e.request) {
        window.vueRoot.$rollbar.error(
          `[${getApplicationIndex.name}] Request was made to v1/applications but no response was received`
        );
      } else if (!e.response && !e.request) {
        window.vueRoot.$rollbar.error(
          `[${getApplicationIndex.name}] Error creating request for v1/applications`
        );
      }
      return defaultOnError(e);
    },
  })) as AxiosResponse;

const getApplicationData = async ({ applicationId }) => {
  const response = (await get({ endpoint: `applications/${applicationId}` })) as AxiosResponse;
  return response ? response.data : {};
};

const getApplicationParentData = async ({ applicationId }) => {
  const response = (await get({
    endpoint: `applications/${applicationId}/parent`,
  })) as AxiosResponse;
  return response ? response?.data : {};
};

const getApplicationDecision = async ({
  applicationId,
  onSuccess,
  onError,
}: {
  applicationId: string;
  onSuccess?: Function;
  onError?: Function;
}) =>
  (await get({
    endpoint: `applications/${applicationId}/decision`,
    onSuccess,
    onError,
  })) as AxiosResponse;

const getPreviousPaymentInfo = async ({ applicationId, onSuccess }) =>
  get({
    endpoint: `applications/${applicationId}/previous_payment_info`,
    onSuccess,
    onError: () => {},
  });

const getPrequalDecision = async ({ applicationId, onSuccess, onError }) =>
  get({ endpoint: `applications/${applicationId}/prequal_decision`, onSuccess, onError });

const getMadlibDecision = async ({ applicationId, onSuccess, onError }) =>
  get({ endpoint: `applications/${applicationId}/madlib_decision`, onSuccess, onError });

const getApplicationQuote = async ({ onSuccess, applicationId, onError }) =>
  get({ endpoint: `applications/${applicationId}/quote`, onSuccess, onError });

const getApplicationQuotes = async ({ onSuccess, applicationId, onError }) =>
  get({ endpoint: `applications/${applicationId}/quotes`, onSuccess, onError });

const getPackages = async ({ onSuccess, applicationId, onError }) =>
  get({ endpoint: `applications/${applicationId}/packages`, onSuccess, onError });

const getNichePrefillSearch = async ({ onSuccess, onError, applicationId }) =>
  get({ endpoint: `applications/${applicationId}/niche_prefill_search`, onSuccess, onError });

const getMemberInfo = ({ onSuccess }) =>
  get({ endpoint: 'member/user_info', onSuccess, onError: () => {} });

const getAdminStatus = () =>
  axios
    .get(`${paShimV1URI}/member/user_info`)
    .then(({ data }) => data?.is_admin as boolean)
    .catch(() => false);

const getExperimentBucket = ({ anonymousId, experimentName }) =>
  axios
    .get(`${paShimV1URI}/bucket?anonymous_id=${anonymousId}&experiment_name=${experimentName}`)
    .then(({ data }) => data)
    .catch(() => false);

const postMemberMessage = ({ data, onSuccess, onError }) =>
  post({
    endpoint: 'member/message',
    data,
    onSuccess,
    onError,
  });

const postPreCacheQuote = ({ applicationId }) => {
  post({
    endpoint: `applications/${applicationId}/precache_review`,
    data: {},
    onSuccess: () => {},
    onError: () => {},
  });
};
const postApplicationData = ({ path, applicationId, data, onSuccess, onError }) =>
  post({
    endpoint: `applications/${applicationId}/${path}`,
    data,
    onSuccess,
    onError,
  });

const postDataShareAgreementData = ({ data, onSuccess, onError }) =>
  post({
    endpoint: 'data_share_agreement',
    data,
    onSuccess,
    onError,
  });

const deleteDataShareAgreementData = ({ params, onSuccess, onError }) =>
  deleteRequest({
    endpoint: 'data_share_agreement',
    params,
    onSuccess,
    onError,
  });

const getOptInData = params =>
  get({ endpoint: `data_share_agreement`, opts: { params }, onError: defaultOnError });

const postUpdateEffectiveDate = ({ data, applicationId, onSuccess, onError }) =>
  post({
    endpoint: `applications/${applicationId}/quote/recreate_with_effective_date`,
    data,
    onSuccess,
    onError,
  });

const updateApplicationData = ({ path, applicationId, data, onSuccess, onError }) =>
  put({
    endpoint: `applications/${applicationId}/${path}`,
    data,
    onSuccess,
    onError,
  });

const deleteApplicationData = ({
  path,
  applicationId,
  ...rest
}: {
  path: string;
  applicationId: string;
  rest: any;
}) =>
  deleteRequest({
    endpoint: `applications/${applicationId}/${path}`,
    ...rest,
  });

const createPayment = ({ applicationId, data }) => {
  const endpoint = `${paShimV1URI}/applications/${applicationId}/payment`;
  catchBadUrls(endpoint);
  return axios.post(endpoint, data) as Promise<AxiosResponse>;
};

const createLinkToken = applicationId => {
  const endpoint = `${paShimV1URI}/applications/${applicationId}/create_link_token`;
  catchBadUrls(endpoint);
  return axios.get(endpoint) as Promise<AxiosResponse>;
};

const postTrackingEvent = ({ data, onSuccess, onError }) =>
  post({
    endpoint: 'tracking_events',
    data,
    onSuccess,
    onError,
  });

const wrappedUpdateApplicationData = ({
  data,
  context,
  onSuccess,
  ...rest
}: {
  context: any;
  data: any;
  onSuccess: any;
}) => {
  const fields = applicationIsProgram2(context)
    ? clearDependentProg2Fields(data, context.$store)
    : clearDependentFields(data, context.$store);

  const wrappedOnSuccess = result => {
    // The delete call will fail if attempted before auth/reg (i.e. in the case of niche deps)
    if (fields.length > 0 && context.$auth.isAuthenticated) {
      const args = {
        params: { fields },
        ...rest,
        path: 'delete_fields',
        onSuccess,
      };

      // @ts-ignore
      deleteApplicationData(args);
    } else {
      onSuccess(result);
    }
  };

  // @ts-ignore
  updateApplicationData({ ...rest, data, onSuccess: wrappedOnSuccess });
};

// Member portal requests
const getMemberData = () => get({ endpoint: 'member', onError: defaultOnError });

const getCompanyUsers = ({ companyId }): Promise<CompanyUsersResponseDto> =>
  axios
    .get(`${peepsURI}/company-users/${companyId}`)
    .then((response: AxiosResponse) => response.data)
    .catch(defaultOnError);

// Member portal / Peeps
const peepsRequestErrorHandler = (e: AxiosError<Record<'errors', string>>, msg: string) => {
  window.vueRoot.$rollbar.error(`[Peeps] ${msg}`, e);
  window.vueRoot.$rollbar.debug(`${e.response?.status} ${e.response?.data?.errors}`);
  return Promise.reject(e.response);
};

const inviteCompanyUser = ({
  companyId,
  invitee,
}: {
  companyId: string;
  invitee: CompanyUser;
}): Promise<CompanyUsersResponseDto> =>
  axios
    .put(`${peepsURI}/company-users/invite/${companyId}`, { ...invitee })
    .then(response => response.data)
    .catch(e => peepsRequestErrorHandler(e, 'Error inviting user'));

const activateCompanyUser = ({
  companyId,
  email,
}: {
  companyId: string;
  email: string;
}): Promise<CompanyUsersResponseDto> =>
  axios
    .post(`${peepsURI}/company-users/accept-invite/${companyId}`, { email })
    .then(response => response.data)
    .catch(e => peepsRequestErrorHandler(e, 'Error marking user active'));

const editCompanyUser = ({
  companyId,
  editedUser,
}: {
  companyId: string;
  editedUser: CompanyUser;
}): Promise<CompanyUsersResponseDto> =>
  axios
    .put(`${peepsURI}/company-users/edit/${companyId}`, { ...editedUser })
    .then(response => response.data)
    .catch(e => peepsRequestErrorHandler(e, 'Error updating user info'));

const removeCompanyUser = ({
  companyId,
  userId,
}: {
  companyId: string;
  userId: string;
}): Promise<CompanyUsersResponseDto> =>
  axios
    .put(`${peepsURI}/company-users/remove/${companyId}`, { id: userId })
    .then(response => response.data)
    .catch(e => peepsRequestErrorHandler(e, 'Error removing user from company'));

const getMemberDocuments = () =>
  get({
    endpoint: 'member/documents',
    opts: {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/zip',
      },
    },
  });

const postRenewals = ({ data, onSuccess }) => post({ endpoint: 'renewals', data, onSuccess });
const finalizeRenewals = ({ applicationId, data, onSuccess, onError }) =>
  post({
    endpoint: `renewals/${applicationId}/finalize`,
    data,
    onSuccess,
    onError,
  });

const putConfirmEmail = ({ applicationId }) =>
  axios.put(`${paShimV1URI}/applications/${applicationId}/confirm_email`);

export default {
  postUser,
  postCompany,
  postApplication,
  getExperimentBucket,

  putConfirmEmail,
  getApplicationIndex,
  getApplicationData,
  getApplicationParentData,
  getApplicationDecision,
  getApplicationQuote,
  getApplicationQuotes,
  getQuotePricing,
  getPackages,
  getPreviousPaymentInfo,

  getNicheMap,
  getPrequalDecision,
  getMadlibDecision,
  getNichePrefillSearch,

  postApplications,
  postApplicationData,
  postCompanies,
  postUpdateEffectiveDate,
  postDataShareAgreementData,
  deleteDataShareAgreementData,
  getOptInData,
  postTrackingEvent,
  postPreCacheQuote,

  createLinkToken,
  createPayment,
  defaultOnError,

  updateApplicationData: wrappedUpdateApplicationData,

  // shared requests
  getMemberInfo,
  getAdminStatus,
  // members requests
  getMemberDocuments,
  getMemberData,
  getCompanyUsers,
  inviteCompanyUser,
  activateCompanyUser,
  editCompanyUser,
  removeCompanyUser,
  postRenewals,
  finalizeRenewals,
  postMemberMessage,

  updatePaymentToken,

  // discretion
  cancelDiscretion,
};
