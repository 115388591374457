<template>
  <div class="niche-card-wrapper">
    <WizardPageWrapper
      header-text="Get Your Quote"
      :minutes-left="10"
      :progress="8"
      :has-back-button="true"
    >
      <template v-slot:left-panel>
        <OnboardingNavigationBar
          class="nav-bar"
          :current-nav-step="1"
        />
      </template>
      <template>
        <NicheQuestionCard
          :item="currentQuestion"
          :tooltips="tooltips"
          @onValueChange="recordAnswer"
          @submitAnswers="onSubmit"
        />
      </template>
    </WizardPageWrapper>
  </div>
</template>

<script>
import {
  getMatchedConditions,
  getCoreNicheQuestions,
  getOptionalNicheQuestions,
  parseCardId,
  getAllNicheQuestions,
  generatePathForId,
  APPLICATION_DATA_WHITELIST,
  getNicheOutcomes,
  finalNicheReducer,
  getPostNicheCardId,
  getNicheTooltips,
  getProgram1NicheMappings,
} from '@/onboarding2/niche.domain';
import WizardPageWrapper from '@/shared/components/wizard/WizardPageWrapper';
import OnboardingNavigationBar from '@/onboarding/components/wizard/OnboardingNavigationBar';
import NicheQuestionCard from '@/onboarding2/NicheQuestionCard';
import { mapMutations } from 'vuex';
import { FUNNEL_CARD_COMPLETED, FUNNEL_CARD_EDITED } from '@/onboarding/services/SegmentEventTypes';
import getOnboardingQuestionRouter from '@/onboarding/router/OnboardingQuestionRouter';
import { shouldRedirectBackToReview } from '@/onboarding/router/routeHelper';
import { debounce, keyBy, sortBy } from 'lodash';
import getRenewalQuestionRouter from '@/members/router/RenewalQuestionRouter';
import { applicationIsRenewal, isWindowRenewal } from '@/onboarding/lib/selectors/storeSelectors';
import {
  checkAppForNewOrOldFunnel,
  checkAppForRenewalNewOrOldFunnel,
} from '@/onboarding/router/newVsOldFunnelGuards';

function checkIdParam(params, caller) {
  if (params.id === undefined) {
    window.vueRoot.$rollbar.error(`Attempting to parse card ID in ${caller} that is not provided!
params: ${JSON.stringify(params)}`);
  }
}

export default {
  name: 'NicheQuestionCardBase',
  components: { WizardPageWrapper, OnboardingNavigationBar, NicheQuestionCard },
  provide() {
    return { questionRouter: this.questionRouter };
  },
  dependencies: ['featureFlags', 'requests', 'tracking', 'store'],
  props: {
    applicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      questions: getAllNicheQuestions(),
      answers: {},
      tooltips: getNicheTooltips(),
    };
  },
  computed: {
    questionRouter() {
      if (isWindowRenewal()) {
        return getRenewalQuestionRouter(true);
      }
      return getOnboardingQuestionRouter(true);
    },
    availableQuestions() {
      return [
        ...getCoreNicheQuestions(),
        ...getMatchedConditions(getOptionalNicheQuestions(), this.answers),
      ];
    },
    currentQuestion() {
      checkIdParam(this.$route.params, 'currentQuestion');
      const identifier = parseCardId(this.$route.params.id);

      return this.questions.find(question => question.identifier === identifier);
    },
    isAuthBeforeMadlibOn() {
      return this.featureFlags.getFlag({ flag: 'authenticate-before-madlib' });
    },
  },

  created() {
    checkIdParam(this.$route.params, 'created');
    if (parseCardId(this.$route.params.id) !== this.questions[0].identifier) {
      const nextRoute = generatePathForId(
        this.questions[0].identifier,
        this.applicationId,
        applicationIsRenewal(this),
        {}
      );
      this.$router.replace(nextRoute);
    }
  },

  async mounted() {
    if (applicationIsRenewal(this)) {
      await checkAppForRenewalNewOrOldFunnel(this);
    } else {
      await checkAppForNewOrOldFunnel(this);
    }
  },

  beforeRouteUpdate(to, from, next) {
    if (to.params.id === from.params.id) {
      next();

      return;
    }

    this.routeGuard(to, next);
  },

  methods: {
    ...mapMutations(['updateApplicationData']),

    recordAnswer(identifier, values = []) {
      this.$set(this.answers, identifier, values);

      const { schema_id } = this.currentQuestion;

      const value = this.answers[identifier];
      const trackingData = {
        cardSubmitData: {
          [schema_id]: value,
        },
      };

      this.sendFunnelCardEditedEvent(trackingData);
    },

    routeGuard(to, next) {
      checkIdParam(to.params, 'routeGuard');
      const toId = parseInt(parseCardId(to.params.id), 10);
      const nextQuestion = this.availableQuestions.find(q => q.identifier === `${toId}`);
      const answerKeys = Object.keys(this.answers);
      const firstQuestion = this.questions[0];

      // this gets called multiple times unfortunately, prevent redirect when unecesssary
      if (
        answerKeys.length &&
        `${toId}` === this.currentQuestion.identifier &&
        this.answers[toId]
      ) {
        next();

        return;
      }

      // catch cases where user ends up on an unavailable question (typically back button)
      if (!answerKeys.length && firstQuestion.identifier !== `${toId}`) {
        next(
          generatePathForId(
            firstQuestion.identifier,
            this.applicationId,
            applicationIsRenewal(this),
            {}
          )
        );

        return;
      }

      if (!nextQuestion || (nextQuestion.identifier !== `${toId}` && answerKeys.length)) {
        const answerIdsSorted = sortBy(
          answerKeys.map(key => parseInt(key, 10)),
          'asc'
        );
        const previousQuestionIndex = Math.max(
          answerIdsSorted.findIndex(id => id === parseInt(this.currentQuestion.identifier, 10)) - 1,
          0
        );

        next(
          generatePathForId(
            answerIdsSorted[previousQuestionIndex],
            this.applicationId,
            applicationIsRenewal(this),
            {}
          )
        );

        return;
      }

      next();
    },

    cleanAnswerData() {
      const availableQuestionMap = keyBy(this.availableQuestions, 'identifier');
      const currentAnswerIds = Object.keys(this.answers);

      currentAnswerIds.forEach(id => {
        if (availableQuestionMap[id]) return;

        this.$delete(this.answers, id);
      });
    },

    onSubmit() {
      const { schema_id, identifier } = this.currentQuestion;
      const trackingData = {
        value: this.answers[identifier],
        dataPath: schema_id,
        isValid: true,
        initialEvent: {},
      };

      this.$store.commit('updateSubmitData', trackingData);
      this.tracking.sendEvent(FUNNEL_CARD_COMPLETED, trackingData);

      this.cleanAnswerData();

      if (!APPLICATION_DATA_WHITELIST.includes(schema_id)) {
        this.goToNextAvailableQuestion();

        return;
      }

      this.sendData({ [schema_id]: this.answers[identifier] });
    },

    sendData(data, onSuccess) {
      const defaultSuccess = serverData => {
        this.onSubmitSuccess(serverData);
      };

      this.requests.updateApplicationData({
        context: this,
        applicationId: this.applicationId,
        path: 'program_2_pre_auth',
        data,
        onSuccess: onSuccess || defaultSuccess,
        onError: data => {
          this.onSubmitError(data);
        },
      });
    },
    sendFunnelCardEditedEvent: debounce(
      function trackNicheCardEdited(trackingData) {
        this.tracking.sendEvent(FUNNEL_CARD_EDITED, {
          id: this.$route.params.id,
          ...trackingData,
        });
      },
      500,
      {
        trailing: true,
      }
    ),

    onSubmitError(data) {
      window.vueRoot.$rollbar.error('Unhandled niche onSubmitError', data);
    },

    async onSubmitSuccess(applicationData) {
      this.$store.commit('clearSubmitData');
      await this.updateApplicationData({ applicationData });
      this.goToNextAvailableQuestion();
    },

    goToNextAvailableQuestion() {
      const currentId = this.currentQuestion.identifier;
      const currentIndex = this.availableQuestions.findIndex(
        ({ identifier }) => identifier === currentId
      );
      const nextQuestion = currentIndex >= 0 && this.availableQuestions[currentIndex + 1];

      if (nextQuestion) {
        this.$router.push(
          generatePathForId(
            nextQuestion.identifier,
            this.applicationId,
            applicationIsRenewal(this),
            this.$route.params
          )
        );

        return;
      }

      const finalNicheList = getMatchedConditions(getNicheOutcomes(), this.answers);
      const hasQualifiedNiches = !!finalNicheList.length;
      let primaryNiche = false;

      if (hasQualifiedNiches) {
        primaryNiche = finalNicheList.reduce(finalNicheReducer);
      }

      this.sendData(
        {
          '2021-06-01--NICHE_LIST': finalNicheList.map(
            ({ internal_ids = {} }) => internal_ids.confluence_id
          ),
          '2021-06-01--PRIMARY_NICHE': primaryNiche
            ? primaryNiche.internal_ids.confluence_id
            : undefined, // undefined will get stripped from request
          '2021-06-01--NICHE_USER_ENTERED': this.getUserEnteredNiche(),
          ...getProgram1NicheMappings(primaryNiche),
        },
        async applicationData => {
          if (shouldRedirectBackToReview(this)) {
            this.$store.commit('clearSubmitData');
            await this.updateApplicationData({ applicationData });

            this.questionRouter.goto({ cardId: 'review', component: this });

            return;
          }

          this.goToNextFunnelQuestion(applicationData);
        }
      );
    },

    getUserEnteredNiche() {
      const questionMap = keyBy(this.questions, 'identifier');
      const userInputs = Object.keys(this.answers)
        .map(id => questionMap[id])
        .filter(({ schema_id }) => schema_id === '2021-06-01--NICHE_USER_ENTERED')
        .map(({ identifier }) => this.answers[identifier]);

      return userInputs.join(' ');
    },

    goToNextFunnelQuestion(applicationData) {
      this.updateApplicationData({ applicationData });

      // Don't show the 'create an account' card if authBeforeMadlib is on
      const cardList =
        this.isAuthBeforeMadlibOn && this.$auth.isAuthenticated
          ? this.questionRouter.decisions.filter(decision => decision.id !== 'prequal-end')
          : this.questionRouter.decisions;

      const cardId = getPostNicheCardId(cardList);

      this.questionRouter.goto({
        cardId,
        component: this,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
