import { getUIConfig } from '@/onboarding/services/questionUIConfig';
import Mustache from 'mustache';
import { ApplicationVersions } from '@/onboarding/types/internal/applicationTypes';

export const marshallCardData = (cardConfigPath, applicationData) => {
  const isProgram2 = applicationData.sha_onboarding_funnel === ApplicationVersions.program2;
  const cardData = getUIConfig(cardConfigPath, isProgram2)?.card;
  // interpret any moustache templating that is in the card
  if (cardData?.title) {
    cardData.title = Mustache.render(cardData.title, applicationData);
  }
  if (cardData?.description) {
    cardData.description = Mustache.render(cardData.description, applicationData);
  }

  return { ...cardData, cardConfigPath };
};
