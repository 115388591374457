<template>
  <div class="question-card-nav-heading">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'QuestionCardNavHeading',
};
</script>

<style lang="scss" scoped>
.question-card-nav-heading {
  @include label-text;
}
</style>
