import QuestionRouter from '@/shared/routing/QuestionRouter';
import { routingDecisions } from '@/onboarding/services/routing_decisions/routingDecisions';
import { routingDecisionsProgram2 } from '@/onboarding/services/routing_decisions_program_2/routingDecisionsProgram2';
import { onboardingUrl, onboardingUrlP2 } from '@/onboarding/router/routeHelper';

const finalCard = () => 'package';
const abortCard = () => 'fin';
const onErrorCard = () => 'review';
const cancelDiscretionCard = () => 'discretion-quote';

export default (isProgram2 = false) =>
  new QuestionRouter({
    decisions: isProgram2 ? routingDecisionsProgram2 : routingDecisions,
    finalCard,
    abortCard,
    cancelDiscretionCard,
    onErrorCard,
    urlFormatter: isProgram2 ? onboardingUrlP2 : onboardingUrl,
  });
