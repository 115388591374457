<template>
  <v-progress-linear
    class="progress-bar"
    :value="value"
    :background-opacity="0.07"
    :height="7"
    :color="color"
  />
</template>

<script>
import Colors from '@/Colors';

export default {
  name: 'ProgressBar',
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    color: () => Colors.progressBarColor,
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  border-radius: $corner-roundness;
}
</style>
