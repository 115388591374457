<template>
  <div
    v-show="shouldShowComponent"
    class="previous-answer"
  >
    <div>
      Last year's answer: <span class="value">{{ value }}</span>
    </div>
  </div>
</template>

<script>
import {
  getApplicationVersion,
  getApplicationParentData,
} from '@/onboarding/lib/selectors/storeSelectors.ts';

export default {
  name: 'PreviousAnswer',
  dependencies: ['featureFlags'],
  props: {
    value: {
      type: [String, Number, Boolean],
      required: false,
      default: null,
    },
  },
  computed: {
    shouldShowComponent() {
      const isFlagEnabled = this.featureFlags.getFlag({ flag: 'previous-answers' });
      const programVersion = getApplicationVersion(this);
      const parentProgramVersion = getApplicationParentData(this).sha_onboarding_funnel;
      const sameVersionRenewal = programVersion === parentProgramVersion;
      const hasPreviousValue = this.value !== null && this.value !== undefined;

      return isFlagEnabled && hasPreviousValue && sameVersionRenewal;
    },
  },
};
</script>

<style lang="scss" scoped>
.previous-answer {
  @include detail-text;

  color: $vouch-placeholder;
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
}
.value {
  font-weight: 700;
}
</style>
