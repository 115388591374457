import { get } from '@/shared/lib/vouch_dash';

const getCoverageData = (
  context: any,
  policyName: string,
  coverageName: string,
  dataPath: string,
  defaultData: any
) => {
  const policyQuote = context.rawQuoteData.policy_quotes[policyName];
  if (policyQuote) {
    return get(policyQuote[coverageName], dataPath, defaultData);
  }
  return null;
};

const getPolicyData = (context: any, policyName: string, dataPath: string, defaultData: any) => {
  const policyQuote = get(context, ['rawQuoteData', 'policy_quotes', policyName]) || {};
  return get(policyQuote, dataPath, defaultData);
};

// turn collection of objects into flattened list of keys
const getFilteredCoverageKeys = (coverageCollection, filter) =>
  Object.keys(coverageCollection).filter(coverage =>
    filter(coverageCollection[coverage], coverage)
  );

const getCoveragesInCartForPolicy = (context, policyName) =>
  getFilteredCoverageKeys(
    getPolicyData(context, policyName, 'koverage_acceptance_decisions', []),
    value => value
  );

export const getInitialMonthlyPayment = context =>
  get(context.rawQuoteData, 'pricing.monthly_initial_amount_cents') || 0;
export const getMonthlyRestQuoteAmountCents = context =>
  get(context.rawQuoteData, 'pricing.monthly_rest_amount_cents') || 0;
export const getMonthlyQuotePremiumCents = context =>
  get(context.rawQuoteData, 'pricing.monthly_annual_cost_premium_cents') || 0;
export const isMonthlyBillingAvailable = context =>
  get(context.rawQuoteData, 'pricing.monthly_billing_available') || false;

export const getAnnualQuotePremiumCents = context =>
  get(context.rawQuoteData, 'pricing.annual_cost_premium_cents') || 0;
export const getAnnualQuoteAmountCents = context =>
  get(context.rawQuoteData, 'pricing.annual_amount_cents') || 0;

export const getMonthlyTotalCents = context =>
  get(context.rawQuoteData, 'pricing.monthly.total.total_cents') || 0;

export enum PricingType {
  Total = 'total',
  Initial = 'initial',
  Regular = 'regular',
}

export const getFees = (
  context: { rawQuoteData: object },
  cadence: string,
  pricingType: PricingType = PricingType.Total
) => get(context.rawQuoteData, `pricing.${cadence}.${pricingType}.fees`, []);

export const getTaxes = (
  context: { rawQuoteData: object },
  cadence: string,
  pricingType: PricingType = PricingType.Total
) => get(context.rawQuoteData, `pricing.${cadence}.${pricingType}.taxes`, []);

export const getTransactionFees = context => {
  const {
    paymentSchedule,
    transactionFees,
    activePaymentExperience: { paymentMethod },
    monthlyPaymentSchedule,
  } = context;
  if (!transactionFees) return 0;
  const convertedPaymentMethod = paymentMethod === 'credit_card' ? 'card' : 'ach_debit';

  return monthlyPaymentSchedule
    ? transactionFees[paymentSchedule].initial[convertedPaymentMethod]
    : transactionFees[paymentSchedule][convertedPaymentMethod];
};

export const getMonthlyTransactionFees = context => {
  const {
    transactionFees,
    activePaymentExperience: { paymentMethod },
  } = context;
  if (!transactionFees) return 0;
  const convertedPaymentMethod = paymentMethod === 'credit_card' ? 'card' : 'ach_debit';

  return transactionFees.monthly.per_month[convertedPaymentMethod];
};

const getAnnualCoveragePremiumCents = (context, policyName, coverageName) =>
  getCoverageData(context, policyName, 'koverage_premiums', coverageName, 0);

const getAnnualPremiumDiscountOrAdjustment = (context, policyName, coverageName) => {
  if (getCoveragesInCartForPolicy(context, policyName).includes(coverageName))
    return getAnnualCoveragePremiumCents(context, policyName, coverageName);
  return 0;
};

export const hasRenewalDiscount = context => {
  return !!(
    getAnnualPremiumDiscountOrAdjustment(context, 'BOP', 'bop_renewal_discount') +
    getAnnualPremiumDiscountOrAdjustment(context, 'MPL', 'mpl_renewal_discount')
  );
};
export const getHasAcceptedQuote = context =>
  get(context.rawQuoteData, 'metadata.accepted_quote', false);
