import Env from '@/shared/services/Env';
import featureFlagging from '@/onboarding/services/FeatureFlaggingService';
import {
  CHAT_WIDGET_AUTO_OPENED,
  CHAT_WIDGET_CLICKED,
} from '@/onboarding/services/SegmentEventTypes';
import { checkAuthentication } from '@/shared/vue-plugins/auth0';
import requests from '@/shared/services/requests';
import { isDuringBusinessHours } from './timeHelpers';

const key = Env.getConfig().zendesk_key;
const ZD_CHAT_AUTO_OPENED = 'zd_chat_auto_opened';
const ZD_CHAT_MANUAL_OPENED = 'zd_chat_manual_opened';

const authenticateZendeskUser = async ({ zendeskJwt }) => {
  const interval = setInterval(function() {
    if (typeof window.zE === 'function') {
      window.zE('messenger', 'loginUser', function(callback) {
        callback(zendeskJwt);
      });
      clearInterval(interval);
    }
  }, 500);
};

const attachAnalytics = async ({ tracking }) => {
  const interval = setInterval(function() {
    const iframe = document.querySelector(
      '[title="Button to launch messaging window"]'
    ) as HTMLIFrameElement;

    if (iframe && iframe.contentWindow) {
      const chatButton = iframe.contentWindow.document.querySelector(
        '[aria-label="Open messaging window"]'
      );

      chatButton?.addEventListener('click', function() {
        tracking.sendEvent(CHAT_WIDGET_CLICKED, { path: window.location.href.split('?')[0] });
        localStorage.setItem(ZD_CHAT_MANUAL_OPENED, 'true');
      });

      clearInterval(interval);
    }
  }, 500);
};

const zendeskAuthenticationCheck = async () => {
  const { isAuthenticated } = await checkAuthentication();

  if (isAuthenticated) {
    requests.getMemberInfo({
      onSuccess: ({ zendesk_jwt: zendeskJwt }) => {
        authenticateZendeskUser({ zendeskJwt });
      },
    });
  }
};

export const bootZendesk = async ({ tracking }) => {
  window.onerror = errorMsg => {
    if (
      typeof errorMsg === 'string' &&
      errorMsg.match(/Method webWidget:on\.open does not exist/)
    ) {
      return true;
    }
    return false;
  };

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'ze-snippet';
  script.async = true;
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
  script.onload = () => {
    attachAnalytics({ tracking });
    zendeskAuthenticationCheck();
  };

  document.getElementsByTagName('head')[0].appendChild(script);
};

export const startProactiveZendesk = ({ t = 30000, tracking, force_open = false }) => {
  if (!featureFlagging.getFlag({ flag: 'zendesk-proactive-messaging' })) return;

  setTimeout(() => {
    if (!isDuringBusinessHours()) return;
    if (!!localStorage.getItem(ZD_CHAT_MANUAL_OPENED) && !force_open) return;

    if (typeof window.zE === 'function') {
      window.zE('messenger', 'open');
      localStorage.setItem(ZD_CHAT_AUTO_OPENED, 'true');
      tracking.sendEvent(CHAT_WIDGET_AUTO_OPENED, {
        path: window.location.href.split('?')[0],
      });
    }
  }, t);
};

export default {
  bootZendesk,
  startProactiveZendesk,
};
