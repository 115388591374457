<template>
  <IsMobile v-slot="{ isMobile }">
    <LayoutTitlePage
      title="How would you like to pay?"
      :subtitle="packageTitleLong"
    >
      <template v-slot:banner-content>
        <component
          :is="headerComponent"
          v-if="headerComponent"
          :class="headerComponent.name"
          :small="isMobile"
          application-id="applicationId"
        />
      </template>
      <template v-slot:back-button>
        <BackButton
          destination="Quote"
          :action="back"
        />
      </template>
      <div
        class="billing-screen-content"
        :class="{ mobile: isMobile }"
      >
        <div class="card-container">
          <CardWithButton
            title="Monthly"
            button-text="Checkout"
            :is-mobile="isMobile"
            test-id="select-billing-monthly"
            @click="checkout('monthly')"
          >
            <div class="detail-text">
              Pay two months up front ({{ getInitialMonthlyPayment | longCurrency }}), followed by
              10 monthly payments of {{ monthlyPayment | longCurrency }}
            </div>

            <template v-slot:right-of-button>
              <PriceTag
                :amount="getInitialMonthlyPayment | longCurrency"
                frequency="due today"
                :large="true"
              />
            </template>
          </CardWithButton>
          <CardWithButton
            title="Annually"
            button-text="Checkout"
            :is-mobile="isMobile"
            test-id="select-billing-annual"
            @click="checkout('annual')"
          >
            Pay for your full year of Vouch coverage up front
            <span v-if="annualSavings > 0">
              and
            </span>
            <span
              v-if="annualSavings > 0"
              class="detail-text savings"
            >
              save an additional <b>{{ annualSavings | longCurrencyNoCents }}</b>
            </span>

            <template v-slot:right-of-button>
              <PriceTag
                :amount="annualQuoteAmountCents | longCurrency"
                frequency="due today"
                :large="true"
              />
            </template>
          </CardWithButton>
        </div>
        <TransactionFeesDisclosure 
          v-if="isTransactionFeesEnabled"
          class="fee-disclosure" 
        />
        <FAQs :is-mobile="isMobile" />
      </div>
    </LayoutTitlePage>
  </IsMobile>
</template>

<script>
import {
  getAnnualQuoteAmountCents,
  getMonthlyTotalCents,
  getMonthlyRestQuoteAmountCents,
  getInitialMonthlyPayment,
} from '@/onboarding/lib/selectors/quote_selectors';
import FAQs from './FAQs';
import { longCurrency, longCurrencyNoCents } from '@/shared/lib/filters/currency_filters';
import { onboardingUrl, sendToReactQuotePage } from '@/onboarding/router/routeHelper';
import { mapActions } from 'vuex';
import {
  getApplicationData,
  getSelectedPackageData,
} from '@/onboarding/lib/selectors/storeSelectors';
import BackButton from '@/onboarding/views/BackButton';
import CardWithButton from '@/onboarding/views/CardWithButton';

import LayoutTitlePage from '@/onboarding/components/layouts/LayoutTitlePage';
import IsMobile from '@/shared/frames/IsMobile';
import SVBCreditSolutionsBanner from '@/onboarding/components/page_banners/SVBCreditSolutionsBanner';
import WithFeatureFlag from '@/shared/frames/WithFeatureFlag';
import { PackageConfig } from '@/onboarding/constants/PackageConfig';
import PriceTag from '@/shared/components/ui/molecules/PriceTag';
import {
  BACK_TO_QUOTE_SCREEN_EVENT,
  PAYMENT_SELECTION_SCREEN_VIEWED,
  PAYMENT_SCHEDULE_SELECTED_EVENT,
} from '@/onboarding/services/SegmentEventTypes';
import { get } from 'lodash';
import TransactionFeesDisclosure from '@/onboarding/views/wizard/card/checkout/TransactionFeesDisclosure';

export default {
  name: 'BillingSelectionCard',
  components: {
    BackButton,
    CardWithButton,
    FAQs,
    IsMobile,
    LayoutTitlePage,
    PriceTag,
    SVBCreditSolutionsBanner,
    WithFeatureFlag,
    TransactionFeesDisclosure,
  },
  dependencies: ['requests', 'tracking', 'featureFlags'],
  filters: { longCurrency, longCurrencyNoCents },
  props: {
    cardId: {
      type: String,
      required: true,
    },
    applicationId: {
      type: String,
      required: true,
    },
    packageSlug: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isTransactionFeesEnabled: false,
      rawQuoteData: {
        metadata: {},
        policies: {},
      },

      loaded: false,
    };
  },

  computed: {
    headerComponent() {
      return get(PackageConfig[this.packageSlug], 'billingHeaderComponent');
    },
    packageTitleLong() {
      return get(PackageConfig[this.packageSlug], 'packageTitleLong');
    },
    annualQuoteAmountCents() {
      return getAnnualQuoteAmountCents(this);
    },
    monthlyTotalCents() {
      return getMonthlyTotalCents(this);
    },
    monthlyPayment() {
      return getMonthlyRestQuoteAmountCents(this);
    },
    getInitialMonthlyPayment() {
      return getInitialMonthlyPayment(this);
    },
    annualSavings() {
      return this.monthlyTotalCents - this.annualQuoteAmountCents;
    },
    applicationData() {
      return getApplicationData(this);
    },
  },

  async created() {
    await this.loadPackagesData();
    this.rawQuoteData = getSelectedPackageData(this).quote;
    this.loaded = true;

    this.tracking.sendEvent(PAYMENT_SELECTION_SCREEN_VIEWED);

    this.isTransactionFeesEnabled = await this.featureFlags.getFlagWhenReady({
      flag: 'charge-transaction-fees',
    });
  },

  methods: {
    ...mapActions(['loadPackagesData']),
    checkout(paymentSchedule) {
      const path = onboardingUrl({
        cardId: 'checkout',
        applicationId: this.applicationId,
        params: { packageSlug: this.packageSlug },
      });
      this.tracking.sendEvent(PAYMENT_SCHEDULE_SELECTED_EVENT, {
        applicationData: this.applicationData,
        paymentSchedule,
        quotePrice:
          paymentSchedule === 'monthly'
            ? this.rawQuoteData.pricing.monthly_annual_cost_premium_cents
            : this.rawQuoteData.pricing.annual_cost_premium_cents,
      });
      this.$router.push({ path, query: { paymentSchedule } });
    },
    back() {
      this.tracking.sendEvent(BACK_TO_QUOTE_SCREEN_EVENT, {
        applicationData: this.applicationData,
      });
      sendToReactQuotePage();
    },
  },
};
</script>

<style lang="scss" scoped>
.fee-disclosure {
  color: $vouch-dark-gray;
  margin-bottom: $space-small;
  margin-top: $space-large;
  text-align: center;
}
.billing-screen-content {
  padding: 0 0 $space-xxlarge;

  .card-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: $space-medium;
    grid-row-gap: $space-medium;
    align-items: start;
  }

  &.mobile {
    .card-container {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
  }

  .savings {
    color: $discount-text-color;
  }
}
</style>
