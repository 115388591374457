import questionCardConfig from '@/members/router/questionCardConfig';
import questionCardConfigProgram2 from '@/members/router/questionCardConfigProgram2';
import { authGuard } from '@/shared/routing/authGuard';
import TransitionWithBackgroundBase from '@/members/router/TransitionWithBackgroundBase.vue';

import RenewalQuoteBase from '@/members/router/RenewalQuoteBase.vue';
import RenewalQuestionBase from '@/members/views/layouts/RenewalQuestionBase.vue';
import BillingSelectionPage from '@/onboarding/views/wizard/card/checkout/BillingSelectionPage.vue';
import CheckoutPage from '@/onboarding/views/wizard/card/checkout/CheckoutPage.vue';
import FinPage from '@/members/views/FinPage.vue';
import NicheQuestionCardBase from '@/onboarding2/NicheQuestionCardBase.vue';
import { sendToAccountPath } from '@/onboarding/router/routeHelper';

const extractProps = route => ({ ...route.query, ...route.params });

const membersRoutes = [
  {
    // Program 1 Renewal path
    path: '/renew/',
    props: extractProps,
    component: TransitionWithBackgroundBase,
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        component: RenewalQuoteBase,
        children: [
          { path: 'billing', component: BillingSelectionPage, props: { cardId: 'billing' } },
          { path: 'checkout', component: CheckoutPage, props: { cardId: 'checkout' } },
        ],
      },
      // Dead ends
      {
        path: 'fin',
        component: FinPage,
      },
      {
        path: '',
        component: RenewalQuestionBase,
        name: 'renew',
        children: questionCardConfig,
      },
    ],
  },
  {
    // Program 2 Renewal path
    path: '/renewal/',
    props: extractProps,
    component: TransitionWithBackgroundBase,
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        component: RenewalQuoteBase,
        children: [
          { path: 'billing', component: BillingSelectionPage, props: { cardId: 'billing' } },
          { path: 'checkout', component: CheckoutPage, props: { cardId: 'checkout' } },
        ],
      },
      // Dead ends
      {
        path: 'fin',
        component: FinPage,
      },
      {
        path: '',
        component: RenewalQuestionBase,
        name: 'renewal',
        children: questionCardConfigProgram2,
      },
      {
        path: 'niche',
        props: extractProps,
        component: NicheQuestionCardBase,
      },
      {
        path: 'niche/:id',
        props: extractProps,
        component: NicheQuestionCardBase,
      },
    ],
  },
  // /members/ path must remain at the very end to function as a catchall
  // and not mess up anything else
  {
    path: '/members(.*)',
    name: 'members',
    beforeEnter(to, from, next) {
      sendToAccountPath();
    },
  },
];

export default membersRoutes;
