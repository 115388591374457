import trackingSetup from '@/onboarding/services/TrackingServiceSetup';
import featureFlagging from '@/onboarding/services/FeatureFlaggingService';
import { checkAuthentication } from '@/shared/vue-plugins/auth0';
import {
  getProgressiveDisclosureExperiment,
  PROGRESSIVE_DISCLOSURE_MADLIB,
} from '@/onboarding/services/progressiveDisclosureExperiment';

export const earlyIdentifyAnonymousUser = async () => {
  const { isAuthenticated } = await checkAuthentication();
  if (isAuthenticated) return;

  await featureFlagging.waitUntilReady();
  const experimentNamesAndStatus = trackingSetup.getStatusOfAllExperiments();
  const progressiveDisclosureExperiment = await getProgressiveDisclosureExperiment();
  experimentNamesAndStatus[PROGRESSIVE_DISCLOSURE_MADLIB] = progressiveDisclosureExperiment;

  window.analytics.identify({
    ...experimentNamesAndStatus,
  });
};
