import { Card, CardId } from '@/onboarding/types/internal/routingTypes';

const findAsync = async (arr, asyncCallback) => {
  const promises = arr.map(asyncCallback);
  const results = await Promise.all(promises);
  const index = results.findIndex(result => result);
  return arr[index];
};

const allDecisionsAfterIndex = ({ decisions, index }) => decisions.slice(index + 1);
const allDecisionsBeforeIndex = ({ decisions, index }) => decisions.slice(0, index);
const currentCardIndex = ({ decisions, cardId }) => decisions.findIndex(({ id }) => id === cardId);
const findRoutingDecision = ({ decisions, component }) =>
  findAsync(decisions, async decision => decision.shouldRender(component));

export const continuingCard = async ({ decisions, component, onErrorCardId }) => {
  const findContinuingCard = async ({ isSatisfied, shouldRender }) =>
    !isSatisfied(component) && (await shouldRender(component)); // eslint-disable-line no-return-await
  const { id } = (await findAsync(decisions, findContinuingCard)) || { id: onErrorCardId };
  return id;
};

export const previousCardForComponent = async ({ decisions, component, onErrorCardId }) => {
  const index = currentCardIndex({ decisions, cardId: component.cardId });
  const scopedDecisions = [...allDecisionsBeforeIndex({ decisions, index })].reverse();
  const { id } = (await findRoutingDecision({ decisions: scopedDecisions, component })) || {
    id: onErrorCardId,
  };
  return id;
};

export const nextCardForComponent = async ({ decisions, component, onErrorCardId }) => {
  const index = currentCardIndex({ decisions, cardId: component.cardId });
  const scopedDecisions = allDecisionsAfterIndex({ index, decisions });
  const { id } = (await findRoutingDecision({ decisions: scopedDecisions, component })) || {
    id: onErrorCardId,
  };
  return id;
};

class QuestionRouter {
  decisions: any;

  finalCard: Function;

  abortCard: Function;

  onErrorCard: Function;

  cancelDiscretionCard: Function;

  urlFormatter: Function;

  constructor({
    decisions,
    finalCard,
    abortCard,
    onErrorCard,
    cancelDiscretionCard,
    urlFormatter,
  }) {
    this.decisions = decisions;
    this.finalCard = finalCard;
    this.cancelDiscretionCard = cancelDiscretionCard;
    this.abortCard = abortCard;
    this.onErrorCard = onErrorCard;
    this.urlFormatter = urlFormatter;
  }

  finish(component: Card) {
    this.goto({ cardId: this.finalCard(), component });
  }

  abort(component: Card) {
    this.goto({ cardId: this.abortCard(), component });
  }

  async next(component: Card) {
    // this is an instantiated vue component, not just a vue component class

    const cardId = await nextCardForComponent({
      component,
      decisions: this.decisions,
      onErrorCardId: this.onErrorCard(),
    });
    this.goto({ cardId, component });
  }

  async previous(component: Card) {
    const cardId = await previousCardForComponent({
      component,
      decisions: this.decisions,
      onErrorCardId: this.onErrorCard(),
    });
    this.goto({ cardId, component });
  }

  async getReinsertionPath(component: Card) {
    const cardId = await continuingCard({
      component,
      decisions: this.decisions,
      onErrorCardId: this.onErrorCard(),
    });
    return this.urlFormatter({ cardId, applicationId: component.applicationId });
  }

  goto({ cardId, component, params }: { cardId: CardId; component: Card; params?: any }) {
    const path = this.urlFormatter({ cardId, applicationId: component.applicationId, params });

    component.$router
      .push({ path })
      .catch(e =>
        window.vueRoot.$rollbar.critical(
          'Question funnel routing error',
          { destinationPath: path },
          e
        )
      );
  }
}

export default QuestionRouter;
