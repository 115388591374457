<template>
  <div class="section">
    <h2 class="section-title">
      {{ title }}
    </h2>
    <div class="section-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionWithTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  .section-title {
    @include h3-text;
  }

  .section-body {
    margin-top: $space-medium;
  }
}
</style>
