import { authGuard } from '@/shared/routing/authGuard';
import { getCardId } from '@/shared/routing/getCardId';
import Env from '@/shared/services/Env';
import { RouteParam } from '@/onboarding/types/internal/routingTypes';
import { applicationIsProgram2 } from '@/onboarding/lib/selectors/storeSelectors';

export const onboardingUrl = ({
  cardId,
  applicationId,
  params = {},
}: {
  cardId: string;
  applicationId: string;
  params?: any;
}) => {
  const isProgram2 = applicationIsProgram2(window.vueRoot);
  const programRoute = isProgram2 ? 'application' : 'getquote';
  const fullParams = { applicationId, ...params };
  const queryString = Object.keys(fullParams)
    .map(key => `${key}=${fullParams[key]}`)
    .join('&');

  // Applicable to both application versions
  if (cardId.startsWith('discretion-quote')) {
    return `/${cardId}?${queryString}`;
  }

  return `/${programRoute}/${cardId}?${queryString}`;
};

// This method is solely for handling the way this logic is exposed via the urlFormatter property
// of the question router (where there are seprate P1 and P2 routers). Other calls in the app
// that use onboardingUrl() should use the above.
export const onboardingUrlP2 = ({
  cardId,
  applicationId,
  params = {},
}: {
  cardId: string;
  applicationId: string;
  params?: any;
}) => {
  const fullParams = { applicationId, ...params };
  const queryString = Object.keys(fullParams)
    .map(key => `${key}=${fullParams[key]}`)
    .join('&');

  // Applicabile to both application version
  if (cardId.startsWith('discretion-quote')) {
    return `/${cardId}?${queryString}`;
  }

  return `/application/${cardId}?${queryString}`;
};

export const withCurrentQueryParams = rawUrl => {
  let url = rawUrl;
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.forEach((value, key) => {
    url += `&${key}=${value}`;
  });
  return url;
};

export const passUtmParamsAlong = (to, from, next) => {
  const newQuery = { ...to.query };

  Object.keys(from.query).forEach(key => {
    if (RegExp('utm').test(key)) {
      newQuery[key] = newQuery[key] || from.query[key];
    }
  });

  if (Object.keys(newQuery).length === Object.keys(to.query).length) {
    next();
  } else {
    next({ path: to.path, query: newQuery });
  }
};

export const shouldRedirectBackToReview = ({ applicationId }) =>
  !!localStorage.getItem(`beenOnReview_${applicationId}`);

export const setUserHasBeenOnReview = ({ applicationId }) => {
  localStorage.setItem(`beenOnReview_${applicationId}`, 'sure has');
};

export const selectivelyApplyAuthGuard = (to, from, next) => {
  const componentsThatDontNeedAuth = [
    'prequal-start',
    '2019-07-01--HAS_INSURANCE_SELF',
    '2019-07-01--NUM_FULL_TIME_WORKERS',
    '2019-07-01--CAPITAL_RAISED_CONTAINER',
    '2019-07-01--VERTICAL_NICHE',
    'prequal-end',
  ];

  const cardName = getCardId(to);

  if (componentsThatDontNeedAuth.includes(cardName)) {
    return next();
  }

  return authGuard(to, from, next);
};

export const selectivelyApplyAuthGuardProgram2 = (to, from, next) => {
  const componentsThatDontNeedAuth = [
    'prequal-start',
    '2019-07-01--HAS_INSURANCE_SELF',
    '2019-07-01--NUM_FULL_TIME_WORKERS',
    '2019-07-01--CAPITAL_RAISED_CONTAINER',
    'prequal-end',
  ];

  const cardName = getCardId(to);

  if (componentsThatDontNeedAuth.includes(cardName) || to.path.includes('niche/')) {
    return next();
  }

  return authGuard(to, from, next);
};

export const quoteUrl = (extraParams?: RouteParam[]) => {
  const url = new URL(window.location.href);
  extraParams && extraParams.forEach(param => url.searchParams.set(param.name, param.value));
  const params = url.search;
  const destination = new URL(Env.getConfig().quote_page_url);
  destination.search = params;
  return destination;
};

export const sendToReactQuotePageWithId = applicationId => {
  const destination = new URL(Env.getConfig().quote_page_url);
  destination.searchParams.set('applicationId', applicationId);
  const params = destination.search;
  destination.search = params;
  window.location.href = destination.href;
};

export const sendToReactQuotePage = (extraParams?: RouteParam[]) => {
  // React app quote page
  window.location.href = quoteUrl(extraParams).href;
};

export const sendToAccountPath = (path?: String) => {
  const url = new URL(window.location.href);
  let pathname = path;

  if (!path) {
    pathname = url.pathname;
  }

  // React app account path
  window.location.href = `${Env.getConfig().account_url}${pathname}${url.search}`;
};

export const getDiscretionPath = ({
  applicationId,
  pendingDiscretionId,
  isUserRequested,
  isRenewal,
  onboardingQuestionRouter,
}): string | undefined => {
  let cardId;
  if (pendingDiscretionId) {
    if (isUserRequested) {
      cardId = 'discretion-quote';
    } else if (isRenewal) {
      cardId = 'discretion-quote-blocking-renewal';
    } else {
      cardId = 'discretion-quote-blocking';
    }

    return onboardingQuestionRouter.urlFormatter({
      cardId,
      applicationId,
      params: { pendingDiscretionId },
    });
  }
  return undefined;
};
