<template>
  <IconLink
    data-testid="back-button"
    icon-name="chevronLeft"
    :text="`Back to ${destination}`"
    @click="action"
  />
</template>

<script>
import IconLink from '@/shared/components/ui/molecules/IconLink';

export default {
  name: 'BackButton',
  components: { IconLink },
  props: {
    action: {
      type: Function,
      required: true,
    },
    destination: {
      type: String,
      required: true,
    },
  },
  methods: {
    back() {
      this.$emit('back');
    },
  },
};
</script>
