import { autoYes } from '@/shared/routing/routingDecisionHelpers';
import { ApplicationDecisions } from '@/onboarding/constants/Constants';
import {
  annualRevenueContainerCard,
  capitalRaisedContainerCard,
  hasAnyPiiCard,
  hasPendingClaimsCard,
  locationContainerAttributesAndAdditionalCard,
  locationContainerAttributesCard,
  locationContainerCard,
  numFullTimeWorkersCard,
  numInvoluntaryTerminationCard,
  numPlannedInvoluntaryTerminationCard,
  pendingClaimsDescriptionCard,
  piiAmountCard,
  totalCashAndEquivalentsCentsCard,
} from '@/shared/routing/questionDecisions';
import { generateRoutingDecisions, getAllNicheQuestions } from '@/onboarding2/niche.domain';

const satisfiedOnLockedAndYesDecision = ({ applicationData, decisionData }) =>
  applicationData.is_locked && decisionData.decision === ApplicationDecisions.yesDecision;

const renewalStartCard = {
  id: 'renewal-start',
  isSatisfied: autoYes,
  shouldRender: autoYes,
};

const renewalEndCard = {
  id: 'renewal-end',
  isSatisfied: autoYes,
  shouldRender: autoYes,
};

const reviewCard = {
  id: 'review',
  isSatisfied: satisfiedOnLockedAndYesDecision,
  shouldRender: autoYes,
};

const applicantCard = {
  id: 'applicant',
  isSatisfied: satisfiedOnLockedAndYesDecision,
  shouldRender: autoYes,
};

export const routingDecisions = [
  renewalStartCard,
  numFullTimeWorkersCard,
  numInvoluntaryTerminationCard,
  numPlannedInvoluntaryTerminationCard,
  capitalRaisedContainerCard,
  ...generateRoutingDecisions(getAllNicheQuestions(), true),
  locationContainerCard,
  locationContainerAttributesCard,
  locationContainerAttributesAndAdditionalCard,
  annualRevenueContainerCard,
  totalCashAndEquivalentsCentsCard,
  hasAnyPiiCard,
  piiAmountCard,
  hasPendingClaimsCard,
  pendingClaimsDescriptionCard,
  reviewCard,
  applicantCard,
  renewalEndCard,
];
