import { RenewalNavSteps } from '@/members/constants/Constants';

export const renewalWizardConfig = {
  'renewal-start': { percent: 1, step: RenewalNavSteps.REFRESH_INFO, hasBackButton: false },
  '2019-07-01--NUM_FULL_TIME_WORKERS': { percent: 10, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--NUM_INVOLUNTARY_TERMINATION': { percent: 15, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--NUM_PLANNED_INVOLUNTARY_TERMINATION': {
    percent: 17,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2019-07-01--CAPITAL_RAISED_CONTAINER': { percent: 20, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--LOCATION_CONTAINER': { percent: 25, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--LOCATION_CONTAINER_attributes': { percent: 30, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--LOCATIONS': { percent: 30, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--ANNUAL_REVENUE_CONTAINER': { percent: 35, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--TOTAL_CASH_AND_EQUIVALENTS_CENTS': {
    percent: 40,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2021-16-02--SUBJECT_TO_ERISA': { percent: 42, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_ANY_PII': { percent: 45, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--PII_AMOUNT': { percent: 50, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_PENDING_CLAIMS': { percent: 60, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--PENDING_CLAIMS_DESCRIPTION': { percent: 80, step: RenewalNavSteps.REFRESH_INFO },
  'renewal-setup-call': { percent: 90, step: RenewalNavSteps.REFRESH_INFO },
  review: { percent: 95, step: RenewalNavSteps.REFRESH_INFO },
  applicant: { percent: 97, step: RenewalNavSteps.REFRESH_INFO },
  'renewal-end': { percent: 100, step: RenewalNavSteps.REFRESH_INFO, hasBackButton: false },

  '2019-07-01--LEGAL_ENTITY_NAME': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--YEAR_INCORPORATED': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_PRODUCT_IN_MARKET': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_OTHER_BOARD_DIRECTORS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_ENGINEERING_CONTRACTORS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--NUM_ENGINEERING_CONTRACTORS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_401K': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--BENEFITS_WORTH': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--INSURANCE_HISTORY_CONTAINER': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--CURRENT_CLAIMS_DESCRIPTION': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--DENIED_COVERAGE_DESCRIPTION': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--LIABILITIES_CLAIMS_CONTAINER': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },

  '2019-07-01--HAS_DANGEROUS_PRODUCTS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_PROFESSIONAL_LICENSE_BUSINESS': {
    percent: 92,
    step: RenewalNavSteps.REFRESH_INFO,
  },
  '2019-07-01--HAS_CERTIFICATE_BUSINESS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_MOBILE_GAMES': { percent: 93, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_PHYSICAL_ACTIVITY_BUSINESS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_CONTRACTORS_DISPUTES': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_LOANS_BUSINESS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },

  '2019-07-01--HAS_INSURANCE_SELF': { percent: 5, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_INSURANCE_REQS': { percent: 7, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--VERTICAL_NICHE': { percent: 9, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_ANY_SENSITIVE_PII': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--BUSINESS_DESCRIPTION': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_MEDICAL_DATA': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_REGULATOR_LICENSE': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_REAL_ESTATE_LICENSE': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_ADVERTISING_CLAIM': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--INTERNATIONAL_SALES': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_PHYSICAL_PRODUCTS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--NUM_BIG_REVENUE_CONTRACTS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_SERVICES_BUSINESS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_BENEFITS_BUSINESS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_CHEMICALS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_POLLUTANTS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_CONSUMABLE_BUSINESS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_MONEY_TRANSFER_BUSINESS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_ACCOUNTING_BUSINESS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_FINANCE_CLIENTS_BUSINESS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_FINANCE_BUSINESS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_REAL_ESTATE_BUSINESS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_MOBILITY_BUSINESS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_HIGH_RISK_CLIENTS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_BLOCKCHAIN_BUSINESS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_ROBOTICS_BUSINESS': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
  '2019-07-01--HAS_COPYRIGHT_CLAIM': { percent: 92, step: RenewalNavSteps.REFRESH_INFO },
};
