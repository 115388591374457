<template>
  <div>
    <v-alert
      v-if="hasError"
      :value="true"
      color="error"
      :dismissible="true"
      transition="fade-transition"
    >
      Unable to update email for this application. Please reach out to an advisor for help.
    </v-alert>
    <BaseWizardPage
      :card-data="computedCardData"
      :is-submit-disabled="false"
      :loading="loading"
      submit-text="Update"
      @submit="onSubmit"
    />
  </div>
</template>

<script>
import { getApplicantEmail, getEmailConfirmed } from '@/onboarding/lib/selectors/storeSelectors';
import BaseCard from '@/shared/components/wizard/BaseCard';
import BaseWizardPage from '@/shared/components/wizard/BaseWizardPage';

export default {
  name: 'ConfirmEmail',
  components: { BaseWizardPage },
  extends: BaseCard,
  dependencies: ['requests', 'tracking'],
  data() {
    return {
      hasError: false,
      isLoading: false,
    };
  },
  computed: {
    applicationEmail() {
      return getApplicantEmail(this);
    },
    emailConfirmed() {
      return getEmailConfirmed(this);
    },
    computedCardData() {
      return {
        title: 'Your email does not match the email provided when the application was started',
        description: `By continuing, the email on the application will be updated to ${this.$auth.getUserEmail()}`,
      };
    },
  },
  async created() {
    if (this.emailConfirmed) {
      this.next();
    } else {
      await this.onSubmit();
    }
  },
  methods: {
    next() {
      this.questionRouter.goto({
        cardId: 'prequal-end',
        component: this,
        params: this.$route.query,
      });
    },
    async onSubmit() {
      try {
        this.loading = true;
        await this.requests.putConfirmEmail({
          applicationId: this.applicationId,
        });
        this.loading = false;
        this.next();
      } catch (error) {
        this.hasError = true;
        console.error(error);
      }
    },
  },
};
</script>
