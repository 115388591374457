<template>
  <div class="base-auth-card">
    <div
      class="card-title"
      data-testid="auth-card-title"
    >
      <slot name="card-title" />
    </div>

    <div>
      <div
        class="card-body"
        data-testid="auth-card-body"
      >
        <slot name="card-body" />
      </div>
    </div>

    <div
      v-if="displayFooter"
      class="card-footer"
      data-testid="auth-card-footer"
    >
      <slot name="card-footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseAuthCard',
  computed: {
    displayFooter() {
      return this.$slots['card-footer'];
    },
  },
};
</script>

<style lang="scss" scoped>
.base-auth-card {
  background-color: $vouch-white;
  border-radius: 4px;
  padding-top: $space-medium;
  overflow: auto;
  > * {
    padding: 0 $space-large;
  }
  > * + * {
    margin-top: $space-medium;
  }
}
.card-title {
  flex-direction: column;
  align-items: flex-start;
  @include card-title-text;

  > * + * {
    display: inline-block;
  }
}

.card-body {
  @include card-body-text;
  margin-bottom: $space-large;
}

.card-footer {
  @include card-footer-text;
  background-color: $card-footer-background;
  padding-top: $space-medium;
  padding-bottom: $space-medium;

  .href {
    color: $card-footer-text;
  }
}
</style>
