import requests from '@/shared/services/requests';

export const PROGRESSIVE_DISCLOSURE_MADLIB = 'progressive_disclosure_madlib';

export const getProgressiveDisclosureExperiment = async () => {
  const anonymousId = localStorage.getItem('anonymousId');
  let variant = localStorage.getItem(`${PROGRESSIVE_DISCLOSURE_MADLIB}_${anonymousId}`);

  if (!variant) {
    variant = await requests.getExperimentBucket({
      anonymousId,
      experimentName: PROGRESSIVE_DISCLOSURE_MADLIB,
    });
    localStorage.setItem(`${PROGRESSIVE_DISCLOSURE_MADLIB}_${anonymousId}`, variant || 'control');
  }
  return variant;
};
