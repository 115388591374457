<template>
  <div class="card-form">
    <div v-if="cardData.prefilledQuestion">
      <Tooltip>
        <template slot="trigger">
          <span class="prefilled-question">pre-filled question! <VouchIcon
            name="help"
            class="prefilled-question__icon"
          /> </span>
        </template>
        <template slot="text">
          <span>Pre-filled answers are based off of publicly available data on your startup from sources like Crunchbase, AngelList, and Pitchbook, and meant to help you get through our application process even faster. If our assumption is inaccurate, please update it to reflect an accurate description of your company.</span>
        </template>
      </Tooltip>
    </div>
    <form @submit.prevent="$emit('submit')">
      <QuestionCardBody
        :loading="loading"
        :is-submit-disabled="isSubmitDisabled"
        :submit-text="submitText"
      >
        <!-- eslint-disable -->
        <template v-slot:title>
          <div data-testid="card-title" v-html="cardData.title"></div>
        </template>
        <template v-slot:description>
          <component
            v-if="cardData.descriptionComponent"
            :is="cardData.descriptionComponent"
          />
          <div
            v-else
            v-html="cardData.description"
          />
        </template>
        <slot/>
        <!-- eslint-enable -->
      </QuestionCardBody>
    </form>
  </div>
</template>

<script>
import Tooltip from '@/onboarding/components/Tooltip';
import HasAnyPiiDescription from '@/onboarding/components/card_data/descriptions/HasAnyPiiDescription';
import ButtonDark from '@/shared/components/ui/atoms/buttons/ButtonDark';
import QuestionCardBody from '@/shared/components/ui/molecules/QuestionCardBody';
import VouchIcon from '@/shared/components/ui/atoms/icons/VouchIcon';

export default {
  name: 'WizardCardForm',
  components: {
    VouchIcon,
    QuestionCardBody,
    ButtonDark,
    Tooltip,
    HasAnyPiiDescription,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cardData: {
      type: Object,
      required: true,
    },
    isSubmitDisabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    submitText: {
      type: String,
      required: false,
      default: 'Next',
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
}

.prefilled-question {
  @include label-text;
  cursor: pointer;
  color: $prefilled-question-text-color;

  &__icon {
    bottom: -0.5em;
  }
}

.card-form {
  width: 100%;
}

.card-title {
  @include h3-text;
}
</style>
