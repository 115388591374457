<template>
  <WithDataSync
    :update-fn="updateFn"
    :submit-path="submitPath"
    :schema-path="schemaPath"
    :application-id="applicationId"
    :on-submit-success="onSubmitSuccess"
  >
    <template slot-scope="{onInputChange, onSubmit, isValidForm}">
      <WithCardData
        :schema-path="schemaPath"
        :card-config-path="cardConfigPath"
      >
        <template slot-scope="{cardData}">
          <BaseWizardPage
            :card-data="cardData"
            :loading="isLoading"
            :is-submit-disabled="isValidForm !== true"
            @submit="withLoading(onSubmit)"
          >
            <WithFormFields
              :form-field-data="formFieldData"
              :schema-path="schemaPath"
            >
              <template slot-scope="{formFields}">
                <CardFields
                  :index="0"
                  :form-fields="formFields"
                  :on-input-change="onInputChange"
                />
              </template>
            </WithFormFields>

            <WithFormFields
              v-for="(fieldsSetData, index) in selectedFormFields"
              :key="fieldsSetData.guid"
              :index="fieldsSetData.guid"
              :form-field-data="fieldsSetData.fields"
              :schema-path="schemaPath"
            >
              <template slot-scope="{formFields}">
                <div
                  class="claims-section"
                  :data-testid="`claims-section-${index}`"
                >
                  <h2 class="claims-section-heading">
                    {{ selectedClaims[index] }}
                  </h2>
                  <CardFields
                    class="claims-section-form-field"
                    :form-fields="formFields"
                    :on-input-change="onInputChange"
                  />
                </div>
              </template>
            </WithFormFields>
          </BaseWizardPage>
        </template>
      </WithCardData>
    </template>
  </WithDataSync>
</template>

<script>
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';
import SchemaFormFieldMultiSelect from '@/onboarding/components/formfields/SchemaFormFieldMultiSelect';
import SchemaFormFieldText from '@/onboarding/components/formfields/SchemaFormFieldText';
import SchemaFormFieldSingleSelect from '@/onboarding/components/formfields/SchemaFormFieldSingleSelect';
import { getKeyGuid } from '@/onboarding/services/keyGuid';
import WithFormFields from '../../../../components/wizard/WithFormFields';
import WithDataSync from '../../../../components/wizard/WithDataSync';
import WithCardData from '../../../../components/wizard/WithCardData';
import BaseCardWithFields from '../../../../components/wizard/BaseCardWithFields';
import {
  LIABILITY_CLAIM_ADDED,
  LIABILITY_CLAIM_REMOVED,
} from '@/onboarding/services/SegmentEventTypes';

export default {
  name: 'LiabilitiesClaimsItemizedCard',
  components: {
    WithFormFields,
    WithDataSync,
    WithCardData,
  },
  dependencies: ['tracking'],
  extends: BaseCardWithFields,
  data() {
    return {
      unsubscribe: () => {},
      selectedClaims: [], // 2019-07-01--FILED_CLAIMS_TYPES
      schemaPath: getSchemaEndpoint('core'),
      submitPath: getUpdateEndpoint('core'),
      cardConfigPath: '2019-07-01--LIABILITIES_CLAIMS_CONTAINER',
      formFieldData: [
        {
          component: SchemaFormFieldMultiSelect,
          schemaPath: '2019-07-01--FILED_CLAIMS_TYPES',
          dataPath: '2019-07-01--LIABILITIES_CLAIMS_CONTAINER.2019-07-01--FILED_CLAIMS_TYPES',
        },
      ],
      selectedFormFields: [],
    };
  },
  async created() {
    this.subscribeToStore();
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    withLoading(fn) {
      this.isLoading = true;
      fn();
    },

    getItemizedFieldsFor(type) {
      return {
        guid: getKeyGuid(),
        fields: [
          {
            component: SchemaFormFieldSingleSelect,
            schemaPath: '2019-07-01--FILED_CLAIMS_AMOUNTS',
            dataPath: `2019-07-01--LIABILITIES_CLAIMS_CONTAINER.2019-07-01--CLAIMS_AMOUNTS_ITEMIZED.${type}.2019-07-01--FILED_CLAIMS_AMOUNTS`,
          },
          {
            component: SchemaFormFieldText,
            schemaPath: '2019-07-01--FILED_CLAIMS_DESCRIPTION',
            dataPath: `2019-07-01--LIABILITIES_CLAIMS_CONTAINER.2019-07-01--CLAIMS_AMOUNTS_ITEMIZED.${type}.2019-07-01--FILED_CLAIMS_DESCRIPTION`,
          },
        ],
      };
    },

    subscribeToStore() {
      this.unsubscribe = this.$store.subscribe(({ type, payload }, state) => {
        // return if it's not updateSubmitData
        // if payload value is undefined/null
        // if we're updating a field that is not the multi-select field
        if (
          type !== 'updateSubmitData' ||
          !payload.value ||
          payload.dataPath !==
            '2019-07-01--LIABILITIES_CLAIMS_CONTAINER.2019-07-01--FILED_CLAIMS_TYPES'
        )
          return;

        if (payload.initialEvent) {
          const { '2019-07-01--FILED_CLAIMS_TYPES': selectedClaims } =
            this.applicationData['2019-07-01--LIABILITIES_CLAIMS_CONTAINER'] || {};
          this.selectedFormFields =
            selectedClaims && selectedClaims.map(claimType => this.getItemizedFieldsFor(claimType));
          this.selectedClaims = selectedClaims;
        } else {
          // this.selectedClaims === previous state of 2019-07-01--FILED_CLAIMS_TYPES
          // compare against this.selectedClaims, because current state has already updated to payload value
          if (payload.value.length > this.selectedClaims.length) {
            const index = payload.value.length - 1;
            const newType = payload.value[index];
            this.selectedFormFields.push(this.getItemizedFieldsFor(newType));
            this.tracking.sendEvent(LIABILITY_CLAIM_ADDED, {
              applicationId: this.applicationId,
              claimsData: {
                added: newType,
                allClaims: payload.value,
              },
            });
          } else if (payload.value.length < this.selectedClaims.length) {
            const deletedType = this.selectedClaims.find(i => !payload.value.includes(i));
            const claimIndex = this.selectedClaims.indexOf(deletedType);
            this.$store.commit('removeClaim', {
              guid: this.selectedFormFields[claimIndex].guid,
              type: deletedType,
            });
            this.selectedFormFields.splice(claimIndex, 1);
            this.tracking.sendEvent(LIABILITY_CLAIM_REMOVED, {
              applicationId: this.applicationId,
              claimsData: {
                removed: deletedType,
                allClaims: payload.value,
              },
            });
          }
          // update to current state
          this.selectedClaims = payload.value;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.claims-section {
  margin-top: $form-spacing;
}
.claims-section-heading {
  @include h4-text;
}
.claims-section-form-field {
  margin-top: $intra-form-spacing;
}
</style>
