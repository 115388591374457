export default {
  local: {
    auth0_client_id: 'NEWV3j4spE1YnkOgRnabZQFuduTVPJIf',
    auth0_custom_domain: 'auth.vouch-stg.us',
    auth0_redirect_url: 'http://localhost:8080/loginportal/loginCallback',
    funnel_url: 'http://localhost:8080',
    google_maps_api_key: 'AIzaSyBX6l5crF3_kyhI6BTnIQhGgZVzVqojMsA',
    intake_client_url: 'https://localhost:4043',
    launch_darkly_client_side_id: '5e1e150d6f45f1089e34a8d1',
    logRocketKey: '',
    marketing_site_url: 'https://www.vouch-stg.us',
    pa_shim_url: 'http://localhost:3000',
    graphql_url: 'http://localhost:4000/graphql',
    peeps_url: 'http://localhost:3030',
    quote_page_url: 'http://localhost:8090',
    account_url: 'http://localhost:9000',
    recaptcha: {
      enable: false,
      v2_key: '6LfBhsAUAAAAANKpbr4ynQHC4yYgrT7qV5R_Y6gp',
    },
    rollbar_access_token: 'b2dea8efefc646b39531eb79648c1b6c',
    stripe_key: 'pk_test_suArKrre2pj3iMMPz2RYHcVH00wditaVhE',
    zendesk_key: 'df47f319-41df-4d49-a9cf-7baee3742df6',
  },
  dev: {
    auth0_client_id: 'wzMI83i64uQ4b4gXK3ZbSHgDoymIByPB',
    auth0_custom_domain: 'auth.vouch-stg.us',
    auth0_redirect_url: 'https://app.vouch-dev.us/loginportal/loginCallback',
    funnel_url: 'https://app.vouch-dev.us',
    google_maps_api_key: 'AIzaSyDPFErogBNySAymc39QwhN0v0lZy-dzkqo',
    intake_client_url: 'https://apply.vouch-dev.us',
    launch_darkly_client_side_id: '5e1e1562ee1523088a0b5e96',
    logRocketKey: 'uhf7fk/vouch_dev',
    marketing_site_url: 'https://www.vouch-dev.us',
    pa_shim_url: 'https://api.vouch-dev.us',
    graphql_url: 'https://gateway.vouch-dev.us/graphql',
    peeps_url: 'https://peeps.vouch-dev.us',
    quote_page_url: 'https://quote.vouch-dev.us',
    account_url: 'https://account.vouch-dev.us',
    recaptcha: {
      v2_key: '6LfjV8AUAAAAAOLfzjhxvqkSiBCfIoVf894o_gdo',
      enable: false,
    },
    rollbar_access_token: 'bccc1a3e34b94c80b57d2d2628d0add8',
    stripe_key: 'pk_test_suArKrre2pj3iMMPz2RYHcVH00wditaVhE',
    zendesk_key: 'df47f319-41df-4d49-a9cf-7baee3742df6',
  },
  staging: {
    auth0_client_id: 'NEWV3j4spE1YnkOgRnabZQFuduTVPJIf',
    auth0_custom_domain: 'auth.vouch-stg.us',
    auth0_redirect_url: 'https://app.vouch-stg.us/loginportal/loginCallback',
    funnel_url: 'https://app.vouch-stg.us',
    google_maps_api_key: 'AIzaSyAPHdGcftr7OxcFQVCZA1adyVFE7w2gIw4',
    intake_client_url: 'https://apply.vouch-stg.us',
    launch_darkly_client_side_id: '5e1e1553ee1523088a0b5e8c',
    logRocketKey: 'uhf7fk/vouch_staging',
    marketing_site_url: 'https://www.vouch-stg.us',
    pa_shim_url: 'https://api.vouch-stg.us',
    graphql_url: 'https://gateway.vouch-stg.us/graphql',
    peeps_url: 'https://peeps.vouch-stg.us',
    quote_page_url: 'https://quote.vouch-stg.us',
    account_url: 'https://account.vouch-stg.us',
    recaptcha: {
      v2_key: '6LfjV8AUAAAAAOLfzjhxvqkSiBCfIoVf894o_gdo',
      enable: false,
    },
    rollbar_access_token: '13a4252f6566418a8d31fffabf29cdcb',
    stripe_key: 'pk_test_suArKrre2pj3iMMPz2RYHcVH00wditaVhE',
    zendesk_key: 'df47f319-41df-4d49-a9cf-7baee3742df6',
  },
  uat: {
    auth0_client_id: 'F82GhugPN9RyZ3tGr1HYuPGD6xribpk3',
    auth0_custom_domain: 'auth.vouch-stg.us',
    auth0_redirect_url: 'https://uat-app.vouch-stg.us/loginportal/loginCallback',
    funnel_url: 'https://uat-app.vouch-stg.us',
    google_maps_api_key: 'AIzaSyDPFErogBNySAymc39QwhN0v0lZy-dzkqo',
    intake_client_url: 'https://uat-apply.vouch-stg.us',
    launch_darkly_client_side_id: '5e1e1562ee1523088a0b5e96',
    logRocketKey: 'uhf7fk/vouch_uat',
    marketing_site_url: 'https://www.vouch-stg.us',
    pa_shim_url: 'https://uat-api.vouch-stg.us',
    graphql_url: 'https://uat-gateway.vouch-stg.us/graphql',
    peeps_url: 'https://uat-peeps.vouch-stg.us',
    quote_page_url: 'https://uat-quote.vouch-stg.us',
    account_url: 'https://uat-account.vouch-stg.us',
    recaptcha: {
      v2_key: '6LfjV8AUAAAAAOLfzjhxvqkSiBCfIoVf894o_gdo',
      enable: false,
    },
    rollbar_access_token: 'bccc1a3e34b94c80b57d2d2628d0add8',
    stripe_key:
      'pk_test_51O9UoCGKRqnZ4D1BLG11Gw7nFIcR9MVGLn8Bofs85Ue3GAlXtks1SNWiVVrthDtehuVNiaG7aQ5SzBdteKGctjI6008orra0Yg',
    zendesk_key: 'df47f319-41df-4d49-a9cf-7baee3742df6',
  },
  production: {
    auth0_client_id: 'igaNOnNjxXq5ekLjMIs6HdtmixRssdpz',
    auth0_custom_domain: 'auth.vouch.us',
    auth0_redirect_url: 'https://app.vouch.us/loginportal/loginCallback',
    funnel_url: 'https://app.vouch.us',
    google_maps_api_key: 'AIzaSyAZ_tG_mfOT5AJbU_DzdYSvc1UvvFgV8XY',
    intake_client_url: 'https://apply.vouch.us',
    launch_darkly_client_side_id: '5e1ce4c44bb70508e57fe796',
    logRocketKey: 'uhf7fk/vouch_prod',
    marketing_site_url: 'https://www.vouch.us',
    pa_shim_url: 'https://api.vouch.us',
    graphql_url: 'https://gateway.vouch.us/graphql',
    peeps_url: 'https://peeps.vouch.us',
    quote_page_url: 'https://quote.vouch.us',
    account_url: 'https://account.vouch.us',
    recaptcha: {
      v2_key: '6Lf1pMAUAAAAAGNhTaDTz5RpcgzhLK29TRxTx0mU',
      enable: true,
    },
    rollbar_access_token: '1ff133948beb44f4aed196a0d9bbdec6',
    stripe_key: 'pk_live_0OdR4SQlfaGzvosQyVmFN73c00LF4onBFk',
    zendesk_key: '9e7bc007-3ac1-48b2-9d90-0cda9da89ae6',
  },
};
