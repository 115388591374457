<script>
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import SchemaFormFieldBoolean from '@/onboarding/components/formfields/SchemaFormFieldBoolean';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';

export default {
  name: 'PrefShareSalesCard',
  components: { SchemaFormFieldBoolean },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('core'),
      submitPath: getUpdateEndpoint('core'),
      cardConfigPath: '2021-06-01--PREF_SHARES_SALES',
      formFieldData: [
        {
          component: SchemaFormFieldBoolean,
          schemaPath: '2021-06-01--PREF_SHARES_SALES',
          dataPath: '2021-06-01--PREF_SHARES_SALES',
        },
      ],
    };
  },
};
</script>
