<template>
  <PageBannerPromotion
    class="renewal-discount-banner"
    data-testid="renewal-discount-banner"
    :white-text="false"
    :small="small"
    title="Renewal Discount Activated"
    subtitle="Across eligible Vouch coverages"
  />
</template>

<script>
import PageBannerPromotion from '@/onboarding/components/page_banners/PageBannerPromotion';

export default {
  name: 'RenewalDiscountBanner',
  components: { PageBannerPromotion },
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.renewal-discount-banner {
  background-image: none;
  background-color: $special-offer-color;
}
</style>
