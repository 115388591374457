<template>
  <header class="header">
    <CenteredContainer>
      <v-row no-gutters>
        <v-col md="12">
          <VouchLogoSVG class="vouch-logo-header" />
        </v-col>
      </v-row>
    </CenteredContainer>
  </header>
</template>

<script>
import CenteredContainer from '@/shared/components/layouts/CenteredContainer';
import VouchLogoSVG from '@/shared/components/ui/atoms/logos/VouchLogoSVG';

export default {
  name: 'Header',
  components: { CenteredContainer, VouchLogoSVG },
};
</script>

<style scoped lang="scss">
.header {
  padding: $space-medium 0;
}
.vouch-logo-header {
  width: 36px;
  height: 36px;
}
</style>
