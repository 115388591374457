import { FunnelNavSteps } from '@/onboarding/constants/Constants';
import {
  generateCardConfigsFromJson,
  generateNicheCardsFunnelWizardConfig,
  getAllNicheQuestions,
} from '@/onboarding2/niche.domain';
import { RenewalNavSteps } from '@/members/constants/Constants';

const nicheConfig = generateNicheCardsFunnelWizardConfig(
  generateCardConfigsFromJson(getAllNicheQuestions()),
  10,
  RenewalNavSteps.REFRESH_INFO
);

export const funnelWizardConfigProgram2 = {
  'prequal-start': { percent: 1, step: FunnelNavSteps.PREQUAL, hasBackButton: false },
  '2019-07-01--HAS_INSURANCE_SELF': { percent: 1, step: FunnelNavSteps.PREQUAL },
  '2019-07-01--NUM_FULL_TIME_WORKERS': { percent: 3, step: FunnelNavSteps.PREQUAL },
  '2019-07-01--CAPITAL_RAISED_CONTAINER': { percent: 5, step: FunnelNavSteps.PREQUAL },
  ...nicheConfig,
  'prequal-end': { percent: 10, step: FunnelNavSteps.PREQUAL },
  'confirm-email': { percent: 10, step: FunnelNavSteps.PREQUAL },
  reengage: { percent: 10, step: FunnelNavSteps.CORE },

  '2019-07-01--HAS_INSURANCE_REQS': { percent: 16, step: FunnelNavSteps.CORE },
  '2021-06-01--HAS_INSURANCE_REQS_DESC': { percent: 16, step: FunnelNavSteps.CORE },
  '2019-07-01--LEGAL_ENTITY_NAME': { percent: 16, step: FunnelNavSteps.CORE },
  '2019-07-01--YEAR_INCORPORATED': { percent: 16, step: FunnelNavSteps.CORE },
  '2019-07-01--LOCATION_CONTAINER': { percent: 16, step: FunnelNavSteps.CORE },
  '2019-07-01--LOCATION_CONTAINER_attributes': { percent: 16, step: FunnelNavSteps.CORE },
  '2019-07-01--LOCATIONS': { percent: 16, step: FunnelNavSteps.CORE },
  '2021-06-01--HAS_BIG_TICKET_ITEM': { percent: 16, step: FunnelNavSteps.CORE },
  '2021-06-01--BIG_TICKET_ITEM_DESC': { percent: 20, step: FunnelNavSteps.CORE },
  '2019-07-01--HAS_OTHER_BOARD_DIRECTORS': { percent: 20, step: FunnelNavSteps.CORE },
  '2021-06-01--LEAD_INVESTOR_LAST_ROUND': { percent: 20, step: FunnelNavSteps.CORE },
  '2021-06-01--FOUNDER_PREVIOUS_RAISE': { percent: 20, step: FunnelNavSteps.CORE },
  '2021-06-01--PERCENT_OWNED_NONVC': { percent: 20, step: FunnelNavSteps.CORE },
  '2021-06-01--PREF_SHARES_SALES': { percent: 20, step: FunnelNavSteps.CORE },
  '2021-06-01--SHARES_PUBLICLY_LISTED': { percent: 20, step: FunnelNavSteps.CORE },
  '2019-07-01--ANNUAL_REVENUE_CONTAINER': { percent: 20, step: FunnelNavSteps.CORE },
  '2019-07-01--NUM_BIG_REVENUE_CONTRACTS': { percent: 20, step: FunnelNavSteps.CORE },
  '2019-07-01--TOTAL_CASH_AND_EQUIVALENTS_CENTS': { percent: 20, step: FunnelNavSteps.CORE },
  '2021-06-01--ACQUIRE_10M_COMPANY': { percent: 20, step: FunnelNavSteps.CORE },
  '2019-07-01--HAS_401K': { percent: 20, step: FunnelNavSteps.CORE },
  '2021-16-02--SUBJECT_TO_ERISA': { percent: 25, step: FunnelNavSteps.CORE },
  '2019-07-01--BENEFITS_WORTH': { percent: 25, step: FunnelNavSteps.CORE },
  '2019-07-01--HAS_PRODUCT_IN_MARKET': { percent: 25, step: FunnelNavSteps.CORE },
  '2021-06-01--OFFERS_CONSULTING': { percent: 25, step: FunnelNavSteps.CORE },
  '2019-07-01--HAS_ANY_PII': { percent: 25, step: FunnelNavSteps.CORE },
  '2019-07-01--PII_AMOUNT': { percent: 25, step: FunnelNavSteps.CORE },
  '2021-06-01--SOC_COMPLIANCE': { percent: 25, step: FunnelNavSteps.CORE },
  '2021-06-01--LOGIN_REQUIRES_MFA': { percent: 25, step: FunnelNavSteps.CORE },
  '2019-07-01--HAS_ENGINEERING_CONTRACTORS': { percent: 25, step: FunnelNavSteps.CORE },
  '2019-07-01--NUM_ENGINEERING_CONTRACTORS': { percent: 25, step: FunnelNavSteps.CORE },
  '2019-07-01--NUM_INVOLUNTARY_TERMINATION': { percent: 30, step: FunnelNavSteps.CORE },
  '2019-07-01--NUM_PLANNED_INVOLUNTARY_TERMINATION': {
    percent: 30,
    step: FunnelNavSteps.CORE,
  },
  '2019-07-01--INSURANCE_HISTORY_CONTAINER': { percent: 30, step: FunnelNavSteps.CORE },
  '2019-07-01--CURRENT_CLAIMS_DESCRIPTION': { percent: 30, step: FunnelNavSteps.CORE },
  '2021_06_01--CURRENT_CLAIMS_NUM': { percent: 30, step: FunnelNavSteps.CORE },
  '2019-07-01--DENIED_COVERAGE_DESCRIPTION': { percent: 30, step: FunnelNavSteps.CORE },
  '2019-07-01--LIABILITIES_CLAIMS_CONTAINER': { percent: 30, step: FunnelNavSteps.CORE },
  '2019-07-01--HAS_PENDING_CLAIMS': { percent: 30, step: FunnelNavSteps.CORE },
  '2019-07-01--PENDING_CLAIMS_DESCRIPTION': { percent: 30, step: FunnelNavSteps.CORE },

  // Supplemental questions
  '2021-06-01--HAS_DANGEROUS_PRODUCTS': { percent: 35, step: FunnelNavSteps.CORE },
  '2021-06-01--HAS_DANGEROUS_PRODUCTS_DESC': { percent: 35, step: FunnelNavSteps.CORE },
  '2021-06-01--MANUFACTURING_STATUS': { percent: 35, step: FunnelNavSteps.CORE },
  '2021-06-01--MANUFACTURING_STATUS_DESC': { percent: 35, step: FunnelNavSteps.CORE },
  '2021-06-01--CAUSED_HARM': { percent: 35, step: FunnelNavSteps.CORE },
  '2021-06-01--CAUSED_HARM_DESC': { percent: 35, step: FunnelNavSteps.CORE },
  '2021-06-01--HAS_PHYSICAL_RETAIL': { percent: 40, step: FunnelNavSteps.CORE },
  '2021-06-01--MEDIA_HAS_STATE_REG_DEMANDS': { percent: 40, step: FunnelNavSteps.CORE },
  '2021-06-01--MEDIA_HAS_STATE_REG_DEMANDS_DESC': { percent: 40, step: FunnelNavSteps.CORE },
  '2021-06-01--MEDIA_HAS_ACT_RELATED_DEMANDS': { percent: 40, step: FunnelNavSteps.CORE },
  '2021-06-01--MEDIA_HAS_ACT_RELATED_DEMANDS_DESC': {
    percent: 40,
    step: FunnelNavSteps.CORE,
  },
  '2021-06-01--MEDIA_HAS_COPYRIGHT_LIBEL_PRIVACY_DEMANDS': {
    percent: 40,
    step: FunnelNavSteps.CORE,
  },
  '2021-06-01--MEDIA_HAS_COPYRIGHT_LIBEL_PRIVACY_DEMANDS_DESC': {
    percent: 45,
    step: FunnelNavSteps.CORE,
  },
  '2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS': { percent: 45, step: FunnelNavSteps.CORE },
  '2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS_DESC': {
    percent: 45,
    step: FunnelNavSteps.CORE,
  },
  '2021-06-01--MEDIA_CONTROV_MATERIAL': { percent: 45, step: FunnelNavSteps.CORE },
  '2021-06-01--MEDIA_CONTROV_MATERIAL_DESC': { percent: 45, step: FunnelNavSteps.CORE },
  '2021-06-01--MEDIA_NUM_CONTENT_CONSUMERS': { percent: 45, step: FunnelNavSteps.CORE },
  '2021-06-01--MEDIA_MAKES_OR_SELLS_PG': { percent: 50, step: FunnelNavSteps.CORE },
  '2021-06-01--MEDIA_MAKES_OR_SELLS_PG_DESC': { percent: 50, step: FunnelNavSteps.CORE },
  '2021-06-01--MEDIA_LOANS_OR_TRANSFERS': { percent: 50, step: FunnelNavSteps.CORE },
  '2021-06-01--MEDIA_LOANS_OR_TRANSFERS_DESC': { percent: 50, step: FunnelNavSteps.CORE },
  '2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES': { percent: 50, step: FunnelNavSteps.CORE },
  '2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES_DESC': {
    percent: 50,
    step: FunnelNavSteps.CORE,
  },
  '2021-06-01--TRANSP_LICENSING_DISPUTES': { percent: 55, step: FunnelNavSteps.CORE },
  '2021-06-01--TRANSP_LICENSING_DISPUTES_DESC': { percent: 55, step: FunnelNavSteps.CORE },
  '2021-06-01--TRANSP_OWNS_TRANSPORT': { percent: 55, step: FunnelNavSteps.CORE },
  '2021-06-01--TRANSP_OWNS_TRANSPORT_DESC': { percent: 55, step: FunnelNavSteps.CORE },
  '2021-06-01--PS_PROF_SERVICES': { percent: 55, step: FunnelNavSteps.CORE },
  '2021-06-01--PS_PROF_SERVICES_DESC': { percent: 55, step: FunnelNavSteps.CORE },
  '2021-06-01--PS_DEMANDS_MADE': { percent: 60, step: FunnelNavSteps.CORE },
  '2021-06-01--PS_DEMANDS_MADE_DESC': { percent: 60, step: FunnelNavSteps.CORE },
  '2021-06-01--FT_OPERATES_FINTECH': { percent: 60, step: FunnelNavSteps.CORE },
  '2021-06-01--FT_OPERATES_FINTECH_DESC': { percent: 60, step: FunnelNavSteps.CORE },
  '2021-06-01--FT_HAS_DEMANDS': { percent: 60, step: FunnelNavSteps.CORE },
  '2021-06-01--FT_HAS_DEMANDS_DESC': { percent: 60, step: FunnelNavSteps.CORE },
  '2021-06-01--FT_GTM_STRATEGY': { percent: 65, step: FunnelNavSteps.CORE },
  '2021-06-01--FT_ANNUAL_LOANS': { percent: 65, step: FunnelNavSteps.CORE },
  '2021-06-01--FT_INTERNATIONAL_SALES': { percent: 65, step: FunnelNavSteps.CORE },
  '2021-06-01--FT_HAS_BLOCKCHAIN_BUSINESS': { percent: 65, step: FunnelNavSteps.CORE },
  '2021-06-01--FT_LARGEST_LOAN_SIZE': { percent: 65, step: FunnelNavSteps.CORE },
  '2021-06-01--FT_TOTAL_ANNUAL_LOANS': { percent: 70, step: FunnelNavSteps.CORE },
  '2021-06-01--FT_HANDLES_INSURANCE_CLAIMS': { percent: 70, step: FunnelNavSteps.CORE },
  '2021-06-01--FT_IS_DIRECTLY_REGULATED': { percent: 70, step: FunnelNavSteps.CORE },
  '2021-06-01--DID_TRIAL': { percent: 70, step: FunnelNavSteps.CORE },
  '2021-06-01--DID_TRIAL_DESC': { percent: 70, step: FunnelNavSteps.CORE },
  '2021-06-01--WILL_TRIAL': { percent: 75, step: FunnelNavSteps.CORE },
  '2021-06-01--WILL_TRIAL_DESC': { percent: 75, step: FunnelNavSteps.CORE },
  '2021-06-01--PHARMA_WHEN_IN_MARKET': { percent: 75, step: FunnelNavSteps.CORE },
  '2021-06-01--PHARMA_IS_IN_RADIO': { percent: 75, step: FunnelNavSteps.CORE },
  '2021-06-01--MEDDEV_SPECIAL_DEVICE': { percent: 75, step: FunnelNavSteps.CORE },
  '2021-06-01--MEDDEV_SPECIAL_DEVICE_DESC': { percent: 75, step: FunnelNavSteps.CORE },
  '2021-06-01--MEDDEV_IN_MARKET': { percent: 80, step: FunnelNavSteps.CORE },
  '2021-06-01--MEDDEV_IN_MARKET_DESC': { percent: 80, step: FunnelNavSteps.CORE },
  '2021-06-01--HW_HAS_DANGEROUS_PRODUCTS': { percent: 80, step: FunnelNavSteps.CORE },
  '2021-06-01--EPRISE_ADVICE_REPORTING': { percent: 80, step: FunnelNavSteps.CORE },
  '2021-06-01--EPRISE_ADVICE_REPORTING_DESC': { percent: 80, step: FunnelNavSteps.CORE },
  '2021-06-01--EPRISE_DEMANDS_MADE': { percent: 80, step: FunnelNavSteps.CORE },
  '2021-06-01--EPRISE_DEMANDS_MADE_DESC': { percent: 95, step: FunnelNavSteps.CORE },
  '2021-06-01--EPRISE_SPECIAL_CATEGORY': { percent: 95, step: FunnelNavSteps.CORE },
  '2021-06-01--EPRISE_EXPECTED_INTL_REVENUE': { percent: 95, step: FunnelNavSteps.CORE },
  '2021-06-01--EPRISE_LOAN_SERVICES': { percent: 95, step: FunnelNavSteps.CORE },
  '2021-06-01--EPRISE_LOAN_SERVICES_DESC': { percent: 95, step: FunnelNavSteps.CORE },
  '2022-12-15--FT_INVESTMENT_SERVICES_ACTIVITIES': { percent: 95, step: FunnelNavSteps.CORE },

  'svb-connect': { percent: 95, step: FunnelNavSteps.CORE },
  'carta-connect': { percent: 95, step: FunnelNavSteps.CORE },
  review: { percent: 99, step: FunnelNavSteps.CORE },
};
