import { getApplicantEmail, getCompanyName } from '@/onboarding/lib/selectors/storeSelectors';
import { fetchInitialData } from '@/shared/routing/fetchInitialData';
import featureFlagging from '@/onboarding/services/FeatureFlaggingService';

export const onQuestionLoad = async ({ context }) => {
  const authedEmail = context.$auth.getUserEmail();
  await fetchInitialData(context.$store, context);

  // If the user isn't auth'ed, identify them with the email from the application
  const email = authedEmail.length > 0 ? context.$auth.getUserEmail() : getApplicantEmail(context);
  const companyName = getCompanyName(context);

  context.trackingSetup?.boot({
    context,
    email,
    applicationId: context.applicationId,
    companyName,
  });

  // This will only make an LD call if the email is different than the current LD user
  await featureFlagging.identify({ email });
};
