<script>
const WithFeatureFlag = {
  name: 'WithFeatureFlag',
  inject: ['featureFlags'],
  props: {
    flagName: {
      type: String,
      required: true,
    },
  },
  render() {
    return this.$scopedSlots.default({
      isEnabled: this.featureFlags.getFlag({ flag: this.flagName }),
    });
  },
};

export default WithFeatureFlag;
</script>
