import { LDClient, initialize } from 'launchdarkly-js-client-sdk';
import Env from '@/shared/services/Env';

export class FeatureFlaggingService {
  ldclient?: LDClient;

  constructor() {
    try {
      this.ldclient = initialize(Env.getConfig().launch_darkly_client_side_id, {
        anonymous: true,
        kind: 'user',
      });
    } catch (e) {
      window.vueRoot?.$rollbar?.error('Issue loading up LaunchDarkly', e);
    }
  }

  async identify({ email = '' }) {
    window.vueRoot.$rollbar?.info(`Updating LD config with current user, ${email}`);
    const previousUser = this.ldclient!.getContext();
    const isEmailAlreadyConfigured = email === previousUser.email;
    if (isEmailAlreadyConfigured) return;

    await this.ldclient!.identify({ kind: 'user', key: email, email }).catch((e: Error) => {
      window.vueRoot.$rollbar?.error('Issue identifying user with LaunchDarkly', e);
    });
  }

  // Use for cases when we can be confident that LD has already loaded
  getFlag({ flag, defaultValue = false }: any) {
    try {
      return this.ldclient ? this.ldclient.variation(flag, defaultValue) : defaultValue;
    } catch (e) {
      window.vueRoot.$rollbar?.error(
        'Issue getting flag from LaunchDarkly. Loading default value.',
        e
      );
      return defaultValue;
    }
  }

  // Use for cases when flag is checked on page load and we need to ensure that LD has
  // loaded before checking the flag
  getFlagWhenReady({ flag, defaultValue = false }: any) {
    return this.ldclient!.waitUntilReady()
      .then(() => this.getFlag({ flag }))
      .catch((e: Error) => {
        window.vueRoot.$rollbar?.error(`Unable to get LD flag "${flag}`, e);
        return defaultValue;
      });
  }

  async waitUntilReady(): Promise<any> {
    return this.ldclient!.waitUntilReady();
  }
}
const featureFlagging = new FeatureFlaggingService();
export default featureFlagging;
