<template>
  <BaseAuthCard>
    <template v-slot:card-title>
      <div>
        Crumbs.
      </div>
      <div>
        {{ messageStrings.errorHeader }}
      </div>
    </template>

    <template v-slot:card-body>
      <div v-if="errorCode === 'user_not_found'">
        <div
          v-if="showSpinner"
          class="error-message"
        >
          <VouchIcon name="loading" />
        </div>
        <div
          v-else
          class="error-message"
        >
          {{ messageStrings.errorMessage }} <span class="user-email">{{ userEmail }}</span>
        </div>
      </div>
      <div
        v-else
        class="error-message"
      >
        {{ messageStrings.errorMessage }}
      </div>
      <div class="button-row">
        <ButtonDark
          data-testid="auth-error-primary-button"
          @click.native="handleClick"
        >
          {{ messageStrings.buttonLabel }}
        </ButtonDark>
        <ButtonOutline
          v-if="errorCode === 'user_not_found'"
          data-testid="auth-error-secondary-button"
          @click.native="handleNewApplicationClick"
        >
          Start an Application
        </ButtonOutline>
      </div>
    </template>
  </BaseAuthCard>
</template>

<script>
import BaseAuthCard from './BaseAuthCard';
import VouchIcon from '@/shared/components/ui/atoms/icons/VouchIcon';
import ButtonDark from '@/shared/components/ui/atoms/buttons/ButtonDark';
import ButtonOutline from '@/shared/components/ui/atoms/buttons/ButtonOutline';

export default {
  name: 'AuthError',
  components: { BaseAuthCard, ButtonDark, ButtonOutline, VouchIcon },
  props: {
    errorCode: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      messageStringsMap: {
        user_not_found: {
          errorHeader: 'No Vouch account found.',
          errorMessage: "There doesn't seem to be a Vouch account associated with",
          buttonLabel: 'Try again',
        },
        malformed_jwt_token: {
          errorHeader: 'You are not logged in.',
          errorMessage: 'Please log in to your Vouch account to continue.',
          buttonLabel: 'log in',
        },
        expired: {
          errorHeader: 'Your login has expired.',
          errorMessage: 'Please log in to your Vouch account to continue.',
          buttonLabel: 'Log in',
        },
        default: {
          errorHeader: 'Well, that was unexpected.',
          errorMessage: 'Something went wrong.',
          buttonLabel: 'Log in',
        },
      },
    };
  },
  computed: {
    messageStrings() {
      // Guard against error codes we don't recognize
      const errorCodeKey =
        ['user_not_found', 'malformed_jwt_token', 'expired'].indexOf(this.errorCode) === -1
          ? 'default'
          : this.errorCode;
      return this.messageStringsMap[errorCodeKey];
    },
    userEmail() {
      return this.$auth.isAuthenticated ? this.$auth.getUserEmail() : '';
    },
    showSpinner() {
      return this.errorCode !== 'user_not_found' && this.userEmail.length === 0;
    },
  },
  methods: {
    handleClick() {
      if (this.$auth.isAuthenticated) {
        // Log out and return to login screen
        this.$auth.logout({ logoutUrl: `${window.location.origin}/loginportal/send` });
      } else {
        this.$router.push('/loginportal/send');
      }
    },
    handleNewApplicationClick() {
      // Route to Madlib
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss" scoped>
.user-email {
  font-weight: bold;
}
.button-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: $space-large;

  .vouch-button {
    margin: 0 $space-small $space-small 0;
  }
}
</style>
