<template>
  <BaseNavigationBar
    :steps="steps"
    :current-nav-step="currentNavStep"
  />
</template>

<script>
import { RenewalNavSteps } from '@/members/constants/Constants';
import BaseNavigationBar from '@/shared/components/wizard/BaseNavigationBar';

export default {
  name: 'RenewalNavigationBar',
  components: { BaseNavigationBar },
  props: {
    currentNavStep: {
      type: Number,
      required: true,
    },
  },
  computed: {
    steps() {
      return [
        {
          title: 'Refresh Information',
          navStep: RenewalNavSteps.REFRESH_INFO,
        },
        {
          title: 'Schedule a Call',
          navStep: RenewalNavSteps.SCHEDULE_CALL,
        },
        {
          title: 'Review Quote',
          navStep: RenewalNavSteps.REVIEW_QUOTE,
        },
        {
          title: 'Sign & Pay',
          navStep: RenewalNavSteps.SIGN_AND_PAY,
        },
      ];
    },
  },
};
</script>
