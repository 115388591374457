import requests from '@/shared/services/requests';
import { ApplicationVersions } from '@/onboarding/types/internal/applicationTypes';
import getOnboardingQuestionRouter from '@/onboarding/router/OnboardingQuestionRouter';
import { get } from 'lodash';
import { getDiscretionPath, onboardingUrl, sendToReactQuotePage } from './routeHelper';
import featureFlagging from '@/onboarding/services/FeatureFlaggingService';
import { checkAuthentication } from '@/shared/vue-plugins/auth0';

export const rerouteAuthenticatedUser = async ({
  router,
  userAttemptedNewApp = false,
  givenApplicationId = '',
}) => {
  let message;
  let next;
  window.vueRoot.$rollbar.info(`Rerouting based on application status...`);

  window.vueRoot.$rollbar.info('Getting applicationList index...');
  let applicationData;
  let completedApplication;
  if (givenApplicationId) {
    applicationData = await requests.getApplicationData({ applicationId: givenApplicationId });
    completedApplication = !!applicationData.is_completed;
  } else {
    const applicationList = await requests
      .getApplicationIndex()
      .then(applicationIndexResponse => get(applicationIndexResponse, 'data.applications', []));

    // If application list is empty
    if (applicationList.length < 1) {
      return { next: '/', message: "Let's get started." };
    }
    // Redirect to appropriate location
    completedApplication = applicationList.find(app => !!app.is_completed);
    // Assuming ordered by createdAt, where the last one is the most recent:
    applicationData = applicationList[applicationList.length - 1];
  }

  const applicationId = applicationData.id;
  const isProgram2 = applicationData.sha_onboarding_funnel === ApplicationVersions.program2;
  const onboardingQuestionRouter = getOnboardingQuestionRouter(isProgram2);
  const lockedAndIncomplete = applicationData.is_locked && !applicationData.is_completed;
  const isRenewal = applicationData.renewal.is_renewal_application;
  const isActiveCoverageScreen = () => featureFlagging.getFlag({ flag: 'members-welcome-react' });

  let decisionData = {};
  decisionData =
    lockedAndIncomplete && (await requests.getApplicationDecision({ applicationId }))?.data;
  // in case that there are no quotes attached to the application, pulling in from decision data instead
  const isYesDecision = get(decisionData, 'decision') === 'yes';
  const isNoDecision = get(decisionData, 'decision') === 'no';
  let discretionPath;
  let isUserRequestedDiscretion;
  if (lockedAndIncomplete) {
    const {
      id: pendingDiscretionId,
      user_requested: isUserRequested,
    } = applicationData.pending_discretion_decision;
    discretionPath = getDiscretionPath({
      applicationId,
      pendingDiscretionId,
      isUserRequested,
      isRenewal,
      onboardingQuestionRouter,
    });
    isUserRequestedDiscretion = isUserRequested;
  }

  if (completedApplication && !discretionPath) {
    window.vueRoot.$rollbar.info(`Found completed application; routing to member portal`);
    message = userAttemptedNewApp ? 'You already have active coverage' : 'Welcome back!';
    next = isActiveCoverageScreen() ? '/members/policies' : '/members';
  } else if (isNoDecision) {
    window.vueRoot.$rollbar.info(`Found completed application with a no decision`);
    message = 'Welcome back!';
    next = onboardingQuestionRouter.urlFormatter({ cardId: 'fin', applicationId });
  } else {
    window.vueRoot.$rollbar.info(`Found incomplete application with id ${applicationId}`);
    if (applicationData.is_locked) {
      window.vueRoot.$rollbar.info(`Found locked application with id ${applicationId}`);
      if (isRenewal && discretionPath && !isUserRequestedDiscretion) {
        window.vueRoot.$rollbar.info(`Sending to route /members/policies`);
        return { next: '/members/policies' };
      }

      if (discretionPath) {
        window.vueRoot.$rollbar.info(`Sending to route ${discretionPath}`);
        return { next: discretionPath };
      }

      // looks like there is a different route for program 1 and 2
      // does this interfere with pay when you raise?
      // if quote accepted means they checked out I think
      if (applicationData.quote_accepted_at) {
        window.vueRoot.$rollbar.info('Sending to next steps page');
        return {
          next: onboardingUrl({
            cardId: 'officer-signature',
            applicationId: applicationData.id,
            params: { packageSlug: applicationData.package_slug },
          }),
        };
      }

      if (isYesDecision) {
        window.vueRoot.$rollbar.info(`Sending to Quote Page`);
        sendToReactQuotePage([{ name: 'applicationId', value: applicationId }]);
        return { next: '/' };
      }
    }

    const isAdmin = await checkAuthentication().then(requests.getAdminStatus);

    if (
      applicationData.email_confirmed !== undefined &&
      !applicationData.email_confirmed &&
      !isAdmin
    ) {
      return {
        next: onboardingUrl({
          cardId: 'confirm-email',
          applicationId: applicationData.id,
        }),
      };
    }

    const card = {
      cardId: '',
      applicationId,
      applicationData,
      decisionData,
      $router: router,
    };
    window.vueRoot.$rollbar.info(`Routing user to application in progress`);
    message = userAttemptedNewApp
      ? 'You have an application in progress'
      : 'Back to your application...';

    next = await onboardingQuestionRouter.getReinsertionPath(card);
  }
  return { message, next };
};
