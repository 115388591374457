enum stripeErrorMessageStrings {
  DEVELOPER_TESTING = 'Are you a developer testing our system? Contact us at careers@vouch.us.',
  INCOMPLETE_INFO = 'Your payment information is incomplete. Please check it and try again.',
  INVALID_ADDRESS = 'The address is invalid. Please check it and try again.',
  INVALID_CARD_NUMBER = 'The card number is invalid. Please check it and try again.',
  INVALID_CVC = 'The CVC is invalid. Please check it and try again.',
  INVALID_EXPIRATION_DATE = 'The expiration date is invalid. Please check it and try again.',
  INVALID_ZIP_CODE = 'The zip code is invalid. Please check it and try again.',
  CHECK_INFO = 'We were unable to process your payment. Please check your information and try again.',
  USE_DIFFERENT_CARD = 'We were unable to process your payment. Please use a different card or call your card company.',
  EXPIRED_CARD = 'Your card is expired. Please use a different card.',
  DEFAULT_ERROR = 'We were unable to process your payment.',
}

// There's very likely some redundancy here, but our bases should be covered.
const stripeErrorMessageMap = {
  card_declined: {
    testmode_decline: stripeErrorMessageStrings.DEVELOPER_TESTING,

    incomplete_number: stripeErrorMessageStrings.INCOMPLETE_INFO,
    incorrect_number: stripeErrorMessageStrings.INVALID_CARD_NUMBER,
    invalid_number: stripeErrorMessageStrings.INVALID_CARD_NUMBER,

    incomplete_cvc: stripeErrorMessageStrings.INCOMPLETE_INFO,
    incorrect_cvc: stripeErrorMessageStrings.INVALID_CVC,
    invalid_cvc: stripeErrorMessageStrings.INVALID_CVC,

    incomplete_expiry: stripeErrorMessageStrings.INCOMPLETE_INFO,
    invalid_expiry_month: stripeErrorMessageStrings.INVALID_EXPIRATION_DATE,
    invalid_expiry_month_past: stripeErrorMessageStrings.INVALID_EXPIRATION_DATE,
    invalid_expiry_year: stripeErrorMessageStrings.INVALID_EXPIRATION_DATE,
    invalid_expiry_year_past: stripeErrorMessageStrings.INVALID_EXPIRATION_DATE,

    incorrect_zip: stripeErrorMessageStrings.INVALID_ZIP_CODE,
    postal_code_invalid: stripeErrorMessageStrings.INVALID_ZIP_CODE,

    approve_with_id: stripeErrorMessageStrings.CHECK_INFO,
    authentication_required: stripeErrorMessageStrings.CHECK_INFO,
    issuer_not_available: stripeErrorMessageStrings.CHECK_INFO,
    processing_error: stripeErrorMessageStrings.CHECK_INFO,
    reenter_transaction: stripeErrorMessageStrings.CHECK_INFO,
    try_again_later: stripeErrorMessageStrings.CHECK_INFO,

    call_issuer: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    card_not_supported: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    card_velocity_exceeded: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    currency_not_supported: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    do_not_honor: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    do_not_try_again: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    fraudulent: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    generic_decline: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    insufficient_funds: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    invalid_account: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    invalid_amount: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    lost_card: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    merchant_blacklist: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    new_account_information_available: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    no_action_taken: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    not_permitted: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    pickup_card: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    restricted_card: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    revocation_of_all_authorizations: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    revocation_of_authorization: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    security_violation: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    service_not_allowed: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    stolen_card: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    stop_payment_order: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    transaction_not_allowed: stripeErrorMessageStrings.USE_DIFFERENT_CARD,
    withdrawal_count_limit_exceeded: stripeErrorMessageStrings.USE_DIFFERENT_CARD,

    expired_card: stripeErrorMessageStrings.EXPIRED_CARD,
  },
  validation_error: {
    incomplete_number: stripeErrorMessageStrings.INCOMPLETE_INFO,
    invalid_number: stripeErrorMessageStrings.INVALID_CARD_NUMBER,
    incomplete_cvc: stripeErrorMessageStrings.INCOMPLETE_INFO,
    incomplete_expiry: stripeErrorMessageStrings.INCOMPLETE_INFO,
    invalid_expiry_month_past: stripeErrorMessageStrings.INVALID_EXPIRATION_DATE,
    invalid_expiry_year: stripeErrorMessageStrings.INVALID_EXPIRATION_DATE,
    invalid_expiry_year_past: stripeErrorMessageStrings.INVALID_EXPIRATION_DATE,
  },
  expired_card: stripeErrorMessageStrings.EXPIRED_CARD,
  incorrect_address: stripeErrorMessageStrings.INVALID_ADDRESS,
  incorrect_cvc: stripeErrorMessageStrings.INVALID_CVC,
  incorrect_number: stripeErrorMessageStrings.INVALID_CARD_NUMBER,
  incorrect_zip: stripeErrorMessageStrings.INVALID_ZIP_CODE,
  invalid_cvc: stripeErrorMessageStrings.INVALID_CVC,
  invalid_expiry_month: stripeErrorMessageStrings.INVALID_EXPIRATION_DATE,
  invalid_expiry_year: stripeErrorMessageStrings.INVALID_EXPIRATION_DATE,
  invalid_number: stripeErrorMessageStrings.INVALID_CARD_NUMBER,
  postal_code_invalid: stripeErrorMessageStrings.INVALID_ZIP_CODE,
};

type ErrCode = keyof typeof stripeErrorMessageMap;
type NestedError =
  | typeof stripeErrorMessageMap.card_declined
  | typeof stripeErrorMessageMap.validation_error;
type SpecificCode = keyof NestedError;

export const getStripeErrorMessage = (errCode: ErrCode, specificCode: SpecificCode) => {
  const errResult = stripeErrorMessageMap[errCode];
  if (typeof errResult === 'string') {
    return errResult;
  }

  if (errResult === undefined) {
    window.vueRoot.$rollbar.error(`Error: Unknown Stripe error category: ${errCode}`, {
      errCode,
      specificCode,
    });
    return stripeErrorMessageStrings.DEFAULT_ERROR;
  }

  const msg = errResult[specificCode];

  if (msg === undefined) {
    window.vueRoot.$rollbar.warn(`Unknown Stripe error code: ${errCode}/${specificCode}`, {
      errCode,
      specificCode,
    });
    return stripeErrorMessageStrings.DEFAULT_ERROR;
  }

  return msg;
};
