<script>
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';
import SchemaFormFieldSingleSelect from '@/onboarding/components/formfields/SchemaFormFieldSingleSelect';
import SchemaFormFieldAutocomplete from '@/onboarding/components/formfields/SchemaFormFieldAutocomplete';
import requests from '@/shared/services/requests';
import { get } from '@/shared/lib/vouch_dash';

const ignoredNiches = [
  'Other Commerce',
  'Other Digital Content and Media',
  'Other Consumer FinTech',
  'Other Advertising Technology',
  'Other Enterprise Applications',
  'Other Commercial FinTech',
  'Other Consumer Electronics',
  'Other Enterprise Hardware',
  'Other Medical Devices',
  'Other Clinical Lab Equip. & Supplies',
  'Other Prescription Pharmaceutical Products',
  'Other Research Equip. & Supplies',
  'Other Medical Products Offered for Retail Sale',
  'Other Healthcare Providers',
  'Other Risk Management Providers',
  'Other Drug Development Services',
  'Other eHealth',
  'Other Outpatient Providers',
];

export default {
  name: 'VerticalNicheCard',
  components: { SchemaFormFieldSingleSelect },
  extends: BaseCardWithFields,
  data() {
    return {
      unsubscribe: () => {},
      niches: {},
      shouldShowCard: true,
      schemaPath: getSchemaEndpoint('prequal'),
      submitPath: getUpdateEndpoint('prequal'),
      cardConfigPath: '2019-07-01--VERTICAL_NICHE',
      formFieldData: [
        {
          component: SchemaFormFieldSingleSelect,
          schemaPath: '2019-07-01--CATEGORY_TECHNOLOGY',
          dataPath: '2019-07-01--CATEGORY_TECHNOLOGY',
        },
        {
          component: SchemaFormFieldSingleSelect,
          schemaPath: '2019-07-01--CATEGORY_CUSTOMER',
          dataPath: '2019-07-01--CATEGORY_CUSTOMER',
        },
        {
          component: SchemaFormFieldSingleSelect,
          schemaPath: '2019-07-01--CATEGORY_VERTICAL',
          dataPath: '2019-07-01--CATEGORY_VERTICAL',
        },
        {
          component: SchemaFormFieldAutocomplete,
          schemaPath: '2019-07-01--CATEGORY_NICHE',
          dataPath: '2019-07-01--CATEGORY_NICHE',
        },
      ],
    };
  },
  async beforeCreate() {
    this.niches = (await requests.getNicheMap()).data;
    this.subscribeToStore();
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    subscribeToStore() {
      const updateFieldOptions = ({ state }) => {
        const technologyEnumPath = '0.propertyDefinition.enum';
        const customerEnumPath = '1.propertyDefinition.enum';
        const verticalEnumPath = '2.propertyDefinition.enum';
        const nicheEnumPath = '3.propertyDefinition.enum';

        const {
          cards: { submitData },
        } = state;

        const customer = submitData['2019-07-01--CATEGORY_CUSTOMER'];
        const technology = submitData['2019-07-01--CATEGORY_TECHNOLOGY'];
        const vertical = submitData['2019-07-01--CATEGORY_VERTICAL'];

        if (state.application.applicationData.incubator_program !== 'wework') {
          const value = Object.keys(this.niches).filter(k => ['Hardware', 'Software'].includes(k));
          this.$store.commit('updateFieldDataProp', {
            path: technologyEnumPath,
            value,
          });
        }
        const validCustomers = technology ? Object.keys(this.niches[technology]) : [];
        const validVerticals =
          technology && customer ? Object.keys(this.niches[technology][customer]) : [];
        let validNiches = get(this.niches, [technology, customer, vertical], []).filter(
          niche => !ignoredNiches.includes(niche)
        );
        // Patch for OPERA-0017 documented in https://app.asana.com/0/0/1180729924779059/f
        // TODO: Add more permanent fix for all the filtering https://app.asana.com/0/1176558736499985/1180870637894805/f
        if (
          technology === 'Software' &&
          customer === 'Enterprise & SMB' &&
          vertical === 'Enterprise Applications'
        ) {
          validNiches = validNiches.filter(niche => niche !== 'Vertical Specific Software');
        }

        this.$store.commit('updateFieldDataProp', {
          path: customerEnumPath,
          value: validCustomers,
        });

        this.$store.commit('updateFieldDataProp', {
          path: verticalEnumPath,
          value: validVerticals,
        });
        this.$store.commit('updateFieldDataProp', { path: nicheEnumPath, value: validNiches });
      };
      const wipeNicheField = () => {
        this.$store.commit('updateSubmitData', {
          value: null,
          dataPath: '2019-07-01--CATEGORY_NICHE',
          isValid: false,
          initialEvent: false,
        });
      };
      const wipeCustomerField = () => {
        this.$store.commit('updateSubmitData', {
          value: null,
          dataPath: '2019-07-01--CATEGORY_CUSTOMER',
          isValid: false,
          initialEvent: false,
        });
      };
      const wipeVerticalField = () => {
        this.$store.commit('updateSubmitData', {
          value: null,
          dataPath: '2019-07-01--CATEGORY_VERTICAL',
          isValid: false,
          initialEvent: false,
        });
      };

      this.unsubscribe = this.$store.subscribe(({ type, payload }, state) => {
        // bail if it's not updateSubmitData, we don't care about those events.
        if (type !== 'updateSubmitData') return;

        const { initialEvent, dataPath } = payload;

        // if it's the first time we're loading data, update the fields so
        // that we're not showing bogus options
        if (initialEvent && dataPath === '2019-07-01--CATEGORY_NICHE') {
          updateFieldOptions({ state });
        }

        // if it's the initial event for other fields, bail so we're not submitting multiple events
        // if it's the niche field, we dont' need to change anything
        if (initialEvent || dataPath === '2019-07-01--CATEGORY_NICHE') return;

        if (dataPath === '2019-07-01--CATEGORY_TECHNOLOGY') {
          wipeCustomerField();
          wipeVerticalField();
        } else if (dataPath === '2019-07-01--CATEGORY_CUSTOMER') {
          wipeVerticalField();
        }

        wipeNicheField();
        updateFieldOptions({ state });
      });
      updateFieldOptions({ state: this.$store.state });
    },
  },
};
</script>
<style lang="scss" scoped>
.formField span.form-title {
  display: none;
}
</style>
