import Vue from 'vue';
import Router from 'vue-router';
import { passUtmParamsAlong } from '@/onboarding/router/routeHelper';
import onboardingRoutes from '@/onboarding/router/routes';
import membersRoutes from '@/members/router/routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [...onboardingRoutes, ...membersRoutes],
});

router.beforeEach(passUtmParamsAlong);

export default router;
