/* istanbul ignore file */
// this file is strictly configuration, and aligns with the 'humble object' pattern, so testing isn't necessary.

import {
  businessDescriptionCard,
  hasAccountingBusinessCard,
  hasAdvertisingClaimCard,
  hasBenefitsBusinessCard,
  hasBlockchainBusinessCard,
  hasCertificateBusinessCard,
  hasChemicalsCard,
  hasConsumableBusinessCard,
  hasContractorsDisputesCard,
  hasCopyrightClaimCard,
  hasDangerousProductsCard,
  hasFinanceBusinessCard,
  hasFinanceClientsBusinessCard,
  hasHighRiskClientsCard,
  hasLoansBusinessCard,
  hasMedicalDataCard,
  hasMobileGamesCard,
  hasMobilityBusinessCard,
  hasMoneyTransferBusinessCard,
  hasPhysicalActivityBusinessCard,
  hasPhysicalProductsCard,
  hasPollutantsCard,
  hasProfessionalLicenseBusinessCard,
  hasRealEstateBusinessCard,
  hasRealEstateLicenseCard,
  hasRegulatorLicenseCard,
  hasRoboticsBusinessCard,
  hasSensitivePIICard,
  hasServicesBusinessCard,
  internationalSalesCard,
  numBigRevenueContractsCard,
} from '@/shared/routing/questionDecisions';

export const nicheRoutingDecisions = [
  hasSensitivePIICard,
  hasDangerousProductsCard,
  hasProfessionalLicenseBusinessCard,
  hasCertificateBusinessCard,
  hasMobileGamesCard,
  hasPhysicalActivityBusinessCard,
  hasContractorsDisputesCard,
  businessDescriptionCard,
  hasMedicalDataCard,
  hasRegulatorLicenseCard,
  hasRealEstateLicenseCard,
  hasAdvertisingClaimCard,
  internationalSalesCard,
  hasPhysicalProductsCard,
  numBigRevenueContractsCard,
  hasServicesBusinessCard,
  hasBenefitsBusinessCard,
  hasChemicalsCard,
  hasPollutantsCard,
  hasConsumableBusinessCard,
  hasMoneyTransferBusinessCard,
  hasAccountingBusinessCard,
  hasFinanceClientsBusinessCard,
  hasFinanceBusinessCard,
  hasRealEstateBusinessCard,
  hasMobilityBusinessCard,
  hasHighRiskClientsCard,
  hasBlockchainBusinessCard,
  hasRoboticsBusinessCard,
  hasCopyrightClaimCard,
  hasLoansBusinessCard,
];
