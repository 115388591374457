const centsToDollars = (n: number) => n / 100;
const isNumber = (n: any) => typeof n === 'number';

export const abbreviatedCurrency = (valueInCents: number | any) => {
  if (!isNumber(valueInCents)) {
    return valueInCents;
  }
  const valueInDollars = centsToDollars(valueInCents);
  const formatNumbers = (value: number) => {
    if (value <= 999) {
      return value;
    }
    // thousands
    if (value >= 1000 && value <= 999999) {
      return `${value / 1000}K`;
    }
    // millions
    if (value >= 1000000 && value <= 999999999) {
      return `${value / 1000000}M`;
    }
    // billions
    if (value >= 1000000000 && value <= 999999999999) {
      return `${value / 1000000000}B`;
    }
    return value;
  };
  return `$${formatNumbers(valueInDollars)}`;
};

export const longCurrencyNoCents = (valueInCents: number | any) => {
  if (!isNumber(valueInCents)) {
    return valueInCents;
  }
  // Note the Math.floor call is to get rid of the cents
  // e.g. $123.00 -> $123
  // TODO: this can be improved
  // longCurrencyNoCents should be used for display only
  // ensure that this rounded number is not compared with actual values
  const valueInDollars = Math.floor(centsToDollars(valueInCents));
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  return formatter.format(valueInDollars);
};

export const longCurrency = (valueInCents: number | any) => {
  if (!isNumber(valueInCents)) {
    return valueInCents;
  }
  const valueInDollars = centsToDollars(valueInCents);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  return formatter.format(valueInDollars);
};
