<template>
  <div>
    Enter "NO" for this question if you only collect data like the names, addresses, and email addresses of your users, or use a third-party payment provider (like Stripe, Braintree, etc.) to store payment information, as opposed to handling payment data directly.
    <Tooltip>
      <template slot="trigger">
        <VouchIcon name="help" />
      </template>
      <span slot="text">
        <b>Personally Identifiable Information</b>
        <br><br>
        PII, or personal identifiable information, includes social security numbers, credit scores, credit card & payment information, birth dates, personal health information, and etc.
        <br><br>
        If you have any questions, please <a @click="showChat">talk to an advisor</a>
      </span>
    </Tooltip>
  </div>
</template>

<script>
import Tooltip from '@/onboarding/components/Tooltip';
import VouchIcon from '@/shared/components/ui/atoms/icons/VouchIcon';
import { showChatIfEnabled } from '@/onboarding/services/chatHelper';

export default {
  name: 'HasAnyPiiDescription',
  dependencies: ['tracking'],
  components: { VouchIcon, Tooltip },
  methods: {
    showChat() {
      showChatIfEnabled({ tracking: this.tracking });
    },
  },
};
</script>
