var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WithDataSync',{attrs:{"update-fn":_vm.updateFn,"submit-path":_vm.submitPath,"schema-path":_vm.schemaPath,"application-id":_vm.applicationId,"on-submit-success":_vm.onSubmitSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var onInputChange = ref.onInputChange;
var onSubmit = ref.onSubmit;
var isValidForm = ref.isValidForm;
return [_c('WithCardData',{attrs:{"schema-path":_vm.schemaPath,"card-config-path":_vm.cardConfigPath},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cardData = ref.cardData;
return [_c('BaseWizardPage',{attrs:{"card-data":cardData,"loading":_vm.isLoading,"is-submit-disabled":isValidForm !== true},on:{"submit":function($event){return _vm.withLoading(onSubmit)}}},_vm._l((_vm.formFieldData),function(formFieldDataSet,index){return _c('WithFormFields',{key:formFieldDataSet.guid,staticClass:"address-form-group",attrs:{"index":formFieldDataSet.guid,"form-field-data":formFieldDataSet.fields,"schema-path":_vm.schemaPath},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formFields = ref.formFields;
return [(index > 0)?_c('div',{staticClass:"text-h6"},[_c('h3',[_vm._v("Additional business address:")])]):_vm._e(),(index > 0)?_c('div',{staticClass:"description"},[_vm._v(" We only need coworking spaces or commercial offices at this time. You don't need to include employees working from home. ")]):_vm._e(),_c('span',{staticClass:"field-label"},[_vm._v("Address Line 1")]),_c('AutoCompleteLocation',{attrs:{"form-fields":formFields,"index":index,"on-input-change":onInputChange,"google-maps-loaded":_vm.googleMapsLoaded}}),_c('v-row',{staticClass:"button-row d-inline-flex",attrs:{"no-gutters":""}},[(index === _vm.formFieldData.length - 1)?_c('v-col',{staticClass:"button-col"},[_c('ButtonText',{staticClass:"add-address-button",attrs:{"data-testid":"add-address"},on:{"click":_vm.onAddAddress}},[_c('VouchIcon',{attrs:{"name":"add"}}),_vm._v(" Add another business address ")],1)],1):_vm._e(),_c('v-col',{staticClass:"button-col"},[(index > 0)?_c('ButtonText',{attrs:{"data-testid":("remove-row-" + index)},on:{"click":function($event){return _vm.onRemove({index: index, guid: formFieldDataSet.guid})}}},[_c('VouchIcon',{attrs:{"name":"clear"}}),_vm._v(" Remove address ")],1):_vm._e()],1)],1)]}}],null,true)})}),1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }