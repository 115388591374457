<template>
  <v-text-field
    ref="googlePlacesAutocomplete"
    v-model="autocompleteText"
    type="text"
    validate-on-blur
    :filled="filled"
    :class="classname"
    :placeholder="placeholder"
    :rules="[rules.addressIsValidPlace]"
    :disabled="disabled"
    @blur="onBlur"
  />
</template>

<script>
const ADDRESS_PROPERTIES_TO_KEEP = [
  'street_number',
  'route',
  'locality',
  'sublocality', // We see sublocality / sublocality_level_1 used for boroughs in NYC
  'sublocality_level_1',
  'administrative_area_level_1',
  'administrative_area_level_2',
  'administrative_area_level_3',
  'postal_code',
  'country',
];

export default {
  name: 'AutocompleteGooglePlaces',
  props: {
    value: {
      type: Object,
      required: true,
    },
    classname: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Street address, city, state, zip code',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    mapsApiKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      googlePlacesAutocomplete: null,
      autocompleteText: '',
      address: null,
      rules: {
        addressIsValidPlace: value => {
          if (!this.address) return 'Enter your address and select the matching result';

          const error = [
            this.requiredProperty(this.address.city, 'city'),
            this.requiredProperty(this.address.state, 'state'),
            this.requiredProperty(this.address.zipCode, 'zip code'),
          ].find(Boolean);

          return error || true;
        },
      },
    };
  },
  async mounted() {
    this.configurePlacesAPI();
  },
  methods: {
    configurePlacesAPI() {
      this.googlePlacesAutocomplete = new google.maps.places.Autocomplete(
        this.$refs.googlePlacesAutocomplete.$refs.input,
        {
          types: ['address'],
        }
      );
      this.googlePlacesAutocomplete.setComponentRestrictions({ country: ['us'] });
      // Avoid paying for data that we don't need by restricting the set of
      // place fields that are returned to just the address components.
      this.googlePlacesAutocomplete.setFields([
        'address_component',
        'formatted_address',
        'geometry',
      ]);
      this.googlePlacesAutocomplete.addListener('place_changed', this.onPlaceChanged);
    },
    onBlur() {
      this.onPlaceChanged();
    },
    onPlaceChanged() {
      const rawPlace = this.googlePlacesAutocomplete.getPlace();
      this.autocompleteText = rawPlace?.formatted_address || this.autocompleteText;
      this.address = this.parsePlace(rawPlace);
      this.$refs.googlePlacesAutocomplete.validate();
      this.$emit(
        'input',
        this.address || {
          address1: null,
          address2: null,
          city: null,
          county: null,
          state: null,
          zipCode: null,
        }
      );
    },
    parsePlace(rawPlace) {
      if (!rawPlace || !rawPlace.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        return null;
      }

      const {
        street_number,
        route,
        locality,
        sublocality,
        sublocality_level_1,
        administrative_area_level_1,
        administrative_area_level_2,
        administrative_area_level_3,
        postal_code,
      } = this.extractAddressFields(rawPlace);

      return {
        address1: street_number ? `${street_number} ${route}` : route,
        address2: '',
        city: locality || sublocality || sublocality_level_1 || administrative_area_level_3,
        county: administrative_area_level_2,
        state: administrative_area_level_1,
        zipCode: postal_code,
      };
    },
    extractAddressFields({ address_components }) {
      const addressObject = {};

      /* eslint-disable no-restricted-syntax */
      for (const component of address_components) {
        const addressType = component.types[0];
        // Only keep properties in our whitelist
        if (ADDRESS_PROPERTIES_TO_KEEP.includes(addressType)) {
          addressObject[addressType] = component.short_name || '';
        }
      }
      /* eslint-enable no-restricted-syntax */
      return addressObject;
    },
    requiredProperty(value, name) {
      if (!value) return `Invalid ${name}`;

      return null;
    },
  },
};
</script>