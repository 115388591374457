import Env from '@/shared/services/Env';
import axios from 'axios';

export class MadlibApi {
  private companiesUrl: string;

  constructor(apiBase) {
    this.companiesUrl = `${apiBase}/v1/companies`;
  }

  createCompanies(companyData) {
    return axios.post(this.companiesUrl, companyData);
  }
}

const api = new MadlibApi(Env.getConfig().pa_shim_url);
export default api;
