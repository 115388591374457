<template>
  <div>
    <span>Transaction fees calculated in next step</span>
    <Tooltip class="tooltip-spacing">
      <template slot="trigger">
        <VouchIcon name="info" />
      </template>
      <span slot="text">Transaction fees are calculated based on your payment method, which you’ll select at checkout</span>
    </Tooltip>
  </div>
</template>

<script>
import Tooltip from '@/onboarding/components/Tooltip';
import VouchIcon from '@/shared/components/ui/atoms/icons/VouchIcon';

export default {
  name: 'TransactionFeesDisclosure',
  components: {
    Tooltip,
    VouchIcon,
  },
};
</script>

<style lang="scss" scoped>
.tooltip-spacing {
  margin-left: 5px;
}
</style>