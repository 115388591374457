<script>
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import SchemaFormFieldMultiSelect from '@/onboarding/components/formfields/SchemaFormFieldMultiSelect';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';

export default {
  name: 'PsProfServicesCard',
  components: { SchemaFormFieldMultiSelect },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('program_2_post_auth'),
      submitPath: getUpdateEndpoint('program_2_post_auth'),
      cardConfigPath: '2021-06-01--PS_PROF_SERVICES',
      formFieldData: [
        {
          component: SchemaFormFieldMultiSelect,
          schemaPath: '2021-06-01--PS_PROF_SERVICES',
          dataPath: '2021-06-01--PS_PROF_SERVICES',
        },
      ],
    };
  },
};
</script>
