<template>
  <div class="madlib-progressive">
    <QuestionCardTitle data-testid="card-title">
      Let's create your Vouch account
    </QuestionCardTitle>
    <v-form
      id="stepOne"
      ref="stepOne"
      @submit.prevent="nextStep('stepOne')"
    >
      <div v-if="activeStep === 1">
        <h4>Tell us about yourself</h4>
        <p>
          Who'll be filling the application on behalf of the company?
        </p>
        <div data-testid="lead-first-name">
          <v-row>
            <v-col>
              <v-text-field
                ref="madlib-first-name-input"
                v-model="userFirstName"
                class="input"
                data-testid="madlib-first-name-input"
                placeholder="First name"
                filled
                autocorrect="off"
                spellcheck="false"
                :rules="[rules.userFirstName]"
                @change="value => onInputValueChange('userFirstName', value)"
              />
            </v-col>
            <v-col>
              <div data-testid="lead-last-name">
                <v-text-field
                  ref="madlib-last-name-input"
                  v-model="userLastName"
                  class="input"
                  data-testid="madlib-last-name-input"
                  placeholder="Last name"
                  filled
                  autocorrect="off"
                  spellcheck="false"
                  :rules="[rules.userLastName]"
                  @change="value => onInputValueChange('userLastName', value)"
                />
              </div>
            </v-col>
          </v-row>
        </div>
        <div data-testid="lead-job-title">
          <v-select
            ref="madlib-job-title-input"
            v-model="userJobTitle"
            data-testid="madlib-job-title-input"
            :items="jobTitleOptions"
            placeholder="Job Title"
            filled
            autocorrect="off"
            :rules="[rules.userJobTitle]"
            @change="value => onInputValueChange('userJobTitle', value)"
          />
        </div>
        <div
          v-if="showLeadJobTitleOther"
          data-testid="lead-job-title-other"
        >
          <v-text-field
            ref="madlib-job-title-other-input"
            v-model="userJobTitleOther"
            class="input"
            data-testid="madlib-job-title-other-input"
            placeholder="Tell us more"
            filled
            autocorrect="off"
            spellcheck="true"
            :rules="[rules.userJobTitleOther]"
            @change="value => onInputValueChange('userJobTitleOther', value)"
          />
        </div>
        <div data-testid="applicant-email">
          <v-text-field
            ref="madlib-email-input"
            v-model="userEmail"
            class="input"
            data-testid="madlib-email-input"
            type="email"
            placeholder="you@startup.com"
            filled
            autocorrect="off"
            :validate-on-blur="!isEmailFieldDisabled"
            :disabled="isEmailFieldDisabled"
            :rules="[rules.userEmail]"
            @change="value => onInputValueChange('userEmail', value)"
          />
        </div>
        <div class="madlib-action">
          <ButtonPrimary
            :loading="loading"
            type="submit"
            class="next-button"
          >
            Next <VouchIcon name="arrowLeft" />
          </ButtonPrimary>
        </div>
      </div>
    </v-form>
    <v-form
      id="stepTwo"
      ref="stepTwo"
      @submit.prevent="nextStep('stepTwo')"
    >
      <div v-if="activeStep === 2">
        <h4>Tell us about the business</h4>
        <p>
          This can be a leased office, coworking space, or home address.
        </p>
        <div data-testid="startup-name">
          <v-text-field
            ref="madlib-company-name-input"
            v-model="companyName"
            class="input"
            data-testid="madlib-company-name-input"
            placeholder="Startup name"
            filled
            autocorrect="off"
            spellcheck="false"
            :rules="[rules.companyName]"
            @change="value => onInputValueChange('companyName', value)"
          />
        </div>
        <div
          v-if="isBizAddressFlagOn"
          data-testid="startup-address"
        >
          <AutocompleteGooglePlaces
            ref="madlib-address-input"
            v-model="companyHqAddress"
            class="input"
            data-testid="madlib-address-input"
            filled
            :maps-api-key="mapsApiKey"
            @input="value => onInputValueChange('companyHqAddress', value)"
          />
          <div
            v-if="shouldShowTaxShelterWarning"
            class="tax-shelter-warning"
          >
            <VouchIcon
              name="warning"
              class="icon-warning"
            />
            Are you sure? We want where your company is headquartered, not incorporated. If your
            team is remote, choose the state where your founder or CEO resides.
          </div>
          <v-text-field
            ref="madlib-address-line2-input"
            v-model="companyHqAddress.address2"
            class="input"
            data-testid="madlib-address-line2-input"
            placeholder="Floor, Suite, etc. (optional)"
            filled
            autocorrect="off"
            spellcheck="false"
            @change="value => onInputValueChange('companyHqAddress.address2', value)"
          />
        </div>
        <div
          v-else
          data-testid="startup-state"
        >
          <div class="step-text">
            based in
            <Tooltip>
              <template slot="trigger">
                <VouchIcon name="help" />
              </template>
              <span slot="text">
                We want where your company is headquartered, not incorporated. If your team is
                remote, choose the state where your founder or CEO resides.
                <br><br>
                For example, if you're incorporated in Delaware, but headquartered in California,
                choose California.
              </span>
            </Tooltip>
          </div>
          <v-select
            ref="madlib-hq-state-input"
            v-model="companyHqState"
            data-testid="madlib-hq-state-input"
            :items="states"
            filled
            placeholder="State"
            :rules="[rules.companyHqState]"
            @change="value => onInputValueChange('companyHqState', value)"
          />
          <span
            v-if="shouldShowTaxShelterWarning"
            class="tax-shelter-warning"
          >
            <VouchIcon
              name="warning"
              class="icon-warning"
            />
            Are you sure? We want where your company is headquartered, not incorporated. If your
            team is remote, choose the state where your founder or CEO resides.
          </span>
        </div>
        <div data-testid="startup-website">
          <v-text-field
            ref="madlib-website-input"
            v-model="companyWebsiteUrl"
            class="input"
            data-testid="madlib-website-input"
            placeholder="example.com"
            filled
            type="url"
            :rules="[rules.companyWebsiteUrl]"
            @change="value => onInputValueChange('companyWebsiteUrl', value)"
          />
        </div>
        <div class="madlib-action">
          <ButtonOutline
            v-if="activeStep !== 1"
            :loading="loading"
            class="back-button"
            @click="previousStep()"
          >
            <VouchIcon name="arrowLeft" /> Back
          </ButtonOutline>
          <ButtonPrimary
            v-if="activeStep !== 3"
            :loading="loading"
            type="submit"
            class="next-button"
          >
            Next <VouchIcon
              name="arrowLeft"
              class="right-arrow"
            />
          </ButtonPrimary>
        </div>
      </div>
    </v-form>
    <v-form
      id="stepThree"
      ref="stepThree"
      class="step-three"
      @submit.prevent="handleSubmit"
    >
      <div v-if="activeStep === 3">
        <h4>Tell us how you heard about us</h4>
        <div data-testid="how-did-you-hear-about-us">
          <v-select
            ref="madlib-hdyhau-input"
            v-model="referralSource"
            data-testid="madlib-hdyhau-input"
            :items="referralSourceOptions"
            autocorrect="off"
            filled
            placeholder="Choose One"
            :rules="[rules.referralSource]"
            @change="value => onInputValueChange('referralSource', value)"
          />
        </div>
        <div
          v-if="showReferralSourceOther"
          data-testid="how-did-you-hear-about-us-other"
        >
          <v-text-field
            ref="madlib-hdyhau-other-input"
            v-model="referralSourceOther"
            class="input"
            data-testid="madlib-hdyhau-other-input"
            placeholder="Tell us more"
            filled
            autocorrect="off"
            spellcheck="true"
            :rules="[rules.referralSourceOther]"
            @change="value => onInputValueChange('referralSourceOther', value)"
          />
        </div>
        <div data-testid="terms-checkbox">
          <v-checkbox
            ref="madlib-agree-terms-checkbox"
            v-model="agreeTermsAndConditions"
            data-testid="madlib-agree-terms-checkbox"
            class="input"
            :rules="[rules.agreeTermsAndConditions]"
            @change="value => onInputValueChange('agreeTermsAndConditions', value)"
          >
            <template slot="label">
              <div class="no-uppercase">
                By checking this box, I affirm that I have read and understand Vouch's
                <LinkExternal
                  target="_blank"
                  href="https://www.vouch.us/legal/terms-of-use"
                  @click.stop
                >terms of use</LinkExternal>
                and
                <LinkExternal
                  target="_blank"
                  href="https://www.vouch.us/legal/privacy-notice"
                  @click.stop
                >privacy notice</LinkExternal>.
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="madlib-action">
          <ButtonOutline
            :loading="loading"
            class="back-button"
            @click="previousStep()"
          >
            <VouchIcon name="arrowLeft" /> Back
          </ButtonOutline>
          <ButtonPrimary
            id="startApplication"
            ref="start-application"
            :loading="loading"
            class="madlib-submit"
            data-testid="start-application"
            type="submit"
          >
            Begin Application
          </ButtonPrimary>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import Env from '@/shared/services/Env';
import AutocompleteGooglePlaces from '@/shared/views/wizard/core/shared/AutocompleteGooglePlaces';
import ButtonPrimary from '@/shared/components/ui/atoms/buttons/ButtonPrimary';
import ButtonOutline from '@/shared/components/ui/atoms/buttons/ButtonOutline';
import Tooltip from '@/onboarding/components/Tooltip';
import LinkExternal from '@/shared/components/ui/atoms/LinkExternal';
import VouchIcon from '@/shared/components/ui/atoms/icons/VouchIcon';
import { UserJobTitleOptions } from '@/onboarding/constants/Constants';
import QuestionCardTitle from '@/shared/components/ui/atoms/question_card/QuestionCardTitle';

import { validateEmailAddress } from '@/shared/services/validateEmailAddress';
import { getSchema, schemaNames } from '@/onboarding/questions_library/questions';

// Must stay in sync with the values of the schema prop LEAD_JOB_TITLE
const JOBS_THAT_TRIGGER_OTHER = {
  OTHER: 'Other',
};

// Key must match the values of the schema prop 2019-07-01--HOW_DID_YOU_HEAR_ABOUT_US
// Value must match partner codes set in the pa_shim admin UI
const PARTNER_SOURCES = {
  WeWork: 'wework',
  'Silicon Valley Bank': 'svb',
  YCombinator: 'yc',
};

// Must stay in sync with the values of the schema prop 2019-07-01--HOW_DID_YOU_HEAR_ABOUT_US
const SOURCES_THAT_TRIGGER_OTHER = {
  ACCELERATOR: 'Accelerator or Incubator',
  INVESTOR: 'Investor/Venture Firm',
  OTHER: 'Other',
  PERSONAL: 'Personal Referral',
  SERVICE: 'Service Provider (e.g. Accounting, Legal, HR)',
};

export default {
  name: 'MadlibProgressive',
  components: {
    AutocompleteGooglePlaces,
    ButtonOutline,
    ButtonPrimary,
    LinkExternal,
    QuestionCardTitle,
    Tooltip,
    VouchIcon,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    isEmailFieldDisabled: {
      type: Boolean,
      required: true,
    },
    userEmail: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      activeStep: 1,
      rules: {
        userFirstName: value => !!value || 'Enter your first name.',
        userLastName: value => !!value || 'Enter your last name.',
        userJobTitle: value => !!value || 'Choose your title.',
        userJobTitleOther: value => !!value || 'Enter your title.',
        userEmail: value => {
          return !value
            ? 'Enter your email address.'
            : validateEmailAddress(value) || 'Enter a valid email address.';
        },
        companyResponsibility: value => !!value || 'Enter your title.',
        companyName: value => !!value || 'Enter your company name',
        companyHqState: value => !!value || 'Choose the state in which you are headquartered ',
        companyWebsiteUrl: value => !!value || 'Enter your website URL',
        referralSource: value => !!value || 'Choose a source',
        referralSourceOther: value => !!value || 'Enter a source',
        agreeTermsAndConditions: value => !!value || 'Please agree to continue',
      },
      companyName: null,
      companyHqState: null,
      companyHqAddress: {
        address1: null,
        address2: null,
        city: null,
        county: null,
        state: null,
        zipCode: null,
      },
      companyWebsiteUrl: null,
      isBizAddressFlagOn: false,
      loading: false,
      userFirstName: null,
      userLastName: null,
      userJobTitle: null,
      userJobTitleOther: null,
      states: [],
      referralSource: null,
      referralSourceOther: null,
      referralSourceOptions: [],
      shouldShowTaxShelterWarning: false,
      agreeTermsAndConditions: false,
    };
  },
  computed: {
    jobTitleOptions() {
      return UserJobTitleOptions;
    },
    mapsApiKey() {
      return Env.getConfig().google_maps_api_key;
    },
    showLeadJobTitleOther() {
      return Object.values(JOBS_THAT_TRIGGER_OTHER).includes(this.userJobTitle);
    },
    showReferralSourceOther() {
      return Object.values(SOURCES_THAT_TRIGGER_OTHER).includes(this.referralSource);
    },
  },
  async mounted() {
    Object.assign(this, this.formData);
    this.loadEnumsFromSchema();
    this.isBizAddressFlagOn = await this.featureFlags.getFlagWhenReady({
      flag: 'biz-address-on-madlib',
    });
  },
  methods: {
    async loadEnumsFromSchema() {
      const createApplicationSchema = await getSchema({
        schemaName: schemaNames.createApplication,
      });
      this.states = createApplicationSchema.definitions['2019-07-01--HQ_PREQUAL_LOCATION'].enum;
      this.referralSourceOptions = createApplicationSchema.definitions[
        '2019-07-01--HOW_DID_YOU_HEAR_ABOUT_US'
      ].enum.filter(e => e);
    },
    onInputValueChange(fieldName, value) {
      this[fieldName] = value;
      this.$emit('inputChange', { fieldName, value });
      if (fieldName === 'userJobTitle') {
        this.handleJobTitleChange();
      } else if (fieldName === 'referralSource') {
        this.handleReferralSourceChange();
      }
    },
    focusEmailInput() {
      this.$refs['madlib-email-input'].focus();
    },
    handleJobTitleChange() {
      // When changing the jobTitle value, reset the JobTitleOther value to null
      this.userJobTitleOther = null;
    },
    handleReferralSourceChange() {
      // reset the referralSourceOther value
      this.referralSourceOther = null;

      if (PARTNER_SOURCES[this.referralSource]) {
        // Only set a partner code if one was not already set by URL param
        this.referralPartner = this.$route.query.partner
          ? this.referralPartner
          : PARTNER_SOURCES[this.referralSource];
      }
    },
    nextStep(step) {
      if (!this.$refs[step].validate()) return;
      this.activeStep++;
    },
    previousStep() {
      this.activeStep--;
    },
    handleSubmit() {
      if (!this.$refs.stepThree.validate()) return;
      this.$emit('formSubmit');
    },
  },
  dependencies: ['featureFlags', 'tracking'],
};
</script>

<style lang="scss" scoped>
* {
  box-shadow: none;
}

.madlib-progressive {
  font-family: 'GT-America-Standard';

  h1 {
    font-family: 'Mirador';
    font-size: 32px;
    font-weight: 400;
    letter-spacing: -0.002em;
    margin-bottom: 50px;

    @media only screen and (min-width: $medium-device) {
      font-size: 40px;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 700;
  }
}

.madlib-action {
  margin-top: 60px;
}

.madlib-submit {
  @media only screen and (max-width: $medium-device) {
    margin-top: 20px;
  }
}

.step-text {
  @include h4-text;
  margin-bottom: $space-xsmall;
}

.step-three h4 {
  margin-bottom: 20px;
}

.xs-size {
  .input {
    max-width: 100%;
    margin-left: 0px;
  }
}

.input,
.v-input {
  margin-top: $space-small;
  margin-bottom: $space-small;

  ::v-deep .v-select__selections {
    line-height: 25px;
  }
}

.v-input__slot {
  align-items: flex-start;
}

@include text-field-filled-bg-color;

.v-text-field--enclosed {
  margin-top: 0;
  margin-bottom: 0;
}

.no-uppercase {
  text-transform: initial;
}

.tax-shelter-warning {
  @include non-error-warning-text;
  word-break: break-word;
  max-width: 400px;
  margin-top: -$space-medium;
  margin-bottom: $space-medium;
  line-height: 1.5;
}

.icon-warning {
  margin-bottom: -2px;
  color: $warning-alert-icon-color;
}

.v-list-item__title {
  white-space: normal;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.next-button .vouch-icon {
  margin-left: 8px;
  display: inline-block;
  transform: rotate(180deg);
}

.back-button .vouch-icon {
  margin-right: 8px;
  display: inline-block;
}
</style>
