<template>
  <div class="top">
    <CenteredContainer class="checkout-form__container">
      <div
        v-if="loadingExperience"
        class="loading-spinner"
      >
        <v-progress-circular
          indeterminate
          :size="20"
          align="inherit"
        />
      </div>

      <template v-else>
        <v-form
          ref="form"
          @submit.prevent="onSubmitForm"
        >
          <v-row justify="space-between">
            <v-col>
              <div
                v-if="activePaymentExperience.isPreviousPaymentMethod"
                :class="{ 'checkout-form-section--mobile': isMobile }"
              >
                <SectionWithTitle title="Payment Information">
                  <div class="body-text">
                    <p>We will charge your payment method on file after we get your signature.</p>
                  </div>

                  <div class="mb-medium">
                    <PaymentMethodDetails :payment-method="previousPaymentExperience.data" />
                  </div>

                  <CoiAcknowledgement
                    v-if="requiresCoiAck"
                    :value="coiAcknowledged"
                    @change="handleCoiAcknowledgement"
                  />

                  <div class="mb-medium">
                    <ButtonPrimary @click="handleRequestToUpdatePayment">
                      Use another payment method
                    </ButtonPrimary>
                  </div>

                  <p class="mb-medium detail-text">
                    <b>Unable to use the same payment method?</b><br>
                    Click the button above to update your payment information.
                  </p>
                </SectionWithTitle>
              </div>

              <div
                v-else
                :class="{ 'checkout-form-section--mobile': isMobile }"
              >
                <NewPaymentMethodForm
                  :payment-experience="activePaymentExperience"
                  :package-slug="packageSlug"
                  :class="{
                    'mb-medium': true,
                    'checkout-form-section--mobile': isMobile,
                  }"
                  @stripeToken="setStripeToken"
                  @stripeErrorMessage="setErrorMessage"
                  @paymentMethodSelected="handleSelectedPaymentMethod"
                >
                  <template
                    v-if="promoComponent"
                    v-slot:promo-content
                  >
                    <component
                      :is="promoComponent"
                      :is-mobile="isMobile"
                    />
                  </template>
                </NewPaymentMethodForm>

                <CoiAcknowledgement
                  v-if="requiresCoiAck"
                  :value="coiAcknowledged"
                  @change="handleCoiAcknowledgement"
                />

                <div
                  v-if="previousPaymentExperience.isPreviousPaymentAvailable"
                  class="mb-medium"
                >
                  <ButtonOutline @click="handleRequestToUsePreviousPayment">
                    Use Previous Payment Method
                  </ButtonOutline>
                </div>
              </div>

              <p class="detail-text mb-xlarge">
                <b v-if="newPaymentExperience.isCreditCardOnly">Unable to pay by card?</b>
                <b v-else>Unable to pay by card or ACH?</b>
                <br>
                Reach out to <a href="mailto:payments@vouch.us">payments@vouch.us</a> to explore
                other payment options.
              </p>

              <div>
                <CheckoutFormSectionOfficerSignature
                  v-model="officerInfo"
                  :show-cancelation-reminder="showCancelationReminder"
                  :class="{
                    'mb-xlarge': true,
                    'checkout-form-section--mobile': isMobile,
                  }"
                  @officerTitleChange="handleOfficerTitleChange"
                />
                <component
                  :is="svbcCoiComponent"
                  v-model="agreeToSvbcCoi"
                />
              </div>
            </v-col>

            <v-col
              cols="12"
              md="5"
            >
              <div
                v-if="shouldShowPartnerOfferApplied"
                class="offer-applied-notification"
              >
                {{ partnerName }} OFFER APPLIED
              </div>
              <div class="premium-section">
                <div class="premium-section-row">
                  <h3
                    class="list-heading"
                    @click="includedCoveragesOnClick"
                  >
                    Included coverages ({{ includedCoverageNames.length }})
                    <Caret :flip="coveragesExpanded" />
                  </h3>
                  <div v-if="coveragesExpanded">
                    <CoverageList
                      class="package-coverages-list"
                      :coverage-names="includedCoverageNames"
                      is-checked
                    />
                  </div>
                </div>
                <div
                  v-if="!monthlyPaymentSchedule && !pricingLoaded"
                  class="price-breakdown-loading-spinner pricing-breakdown"
                >
                  <v-progress-circular
                    indeterminate
                    :size="20"
                    align="inherit"
                  />
                </div>
                <div
                  v-if="!monthlyPaymentSchedule && pricingLoaded"
                  class="premium-section-row pricing-breakdown"
                >
                  <CheckoutFormPricingBreakdown
                    :adjustments-amount="adjustmentsAmount"
                    :initial-monthly-fees="initialMonthlyFees"
                    :initial-monthly-taxes="initialMonthlyTaxes"
                    :is-monthly="monthlyPaymentSchedule"
                    :rest-monthly-fees="restMonthlyFees"
                    :rest-monthly-taxees="restMonthlyTaxes"
                    :savings-amount="savingsAmount"
                    :transaction-fees-amount="transactionFeesAmount"
                    :subtotal-amount="subtotalAmount"
                    :total-fees="fees"
                    :total-taxes="taxes"
                  />
                </div>
                <div class="premium-section-row">
                  <component
                    :is="priceSection"
                    :payment-amount="paymentAmount"
                    :payment-frequency-string="paymentFrequencyString"
                    :monthly-payment="monthlyPaymentSchedule ? monthlyPayment : 0"
                    :total-taxes="taxes"
                    :total-fees="fees"
                    :initial-monthly-taxes="initialMonthlyTaxes"
                    :initial-monthly-fees="initialMonthlyFees"
                    :rest-monthly-taxes="restMonthlyTaxes"
                    :rest-monthly-fees="restMonthlyFees"
                    :transaction-fees-amount="transactionFeesAmount"
                    :monthly-transaction-fees="monthlyTransactionFees"
                  />
                </div>
                <div class="premium-section-row">
                  <ButtonPrimary
                    class="pay-and-send-btn"
                    :disabled="cannotPurchase"
                    type="submit"
                    :loading="loading"
                  >
                    Send for signature
                  </ButtonPrimary>
                  <p class="coi-disclaimer">
                    <span
                      v-if="activePaymentExperience.isAch"
                      class="coi-disclaimer__text"
                    >You will not be charged yet.</span>
                    <span
                      v-else
                      class="coi-disclaimer__text"
                    >Certificate of Insurance will become available and your coverage will become
                      active after receiving your signature and payment.</span>
                    <Tooltip>
                      <template slot="trigger">
                        <VouchIcon name="help" />
                      </template>
                      <div slot="text">
                        <span
                          v-if="
                            activePaymentExperience.isAch &&
                              !activePaymentExperience.isPreviousPaymentMethod
                          "
                        >After connecting your account, you’ll receive your policy documents via
                          email. Your bank account will be charged once we receive your signed
                          policy documents.</span>
                        <span
                          v-else-if="
                            activePaymentExperience.isAch &&
                              activePaymentExperience.isPreviousPaymentMethod
                          "
                        >You’ll receive your policy documents via email. When we receive your
                          signature, the bank account will be charged and your coverage will become
                          active.</span>
                        <span
                          v-else
                        >After processing your payment, you'll receive an email with a link to
                          your Vouch Account, where you'll be able to download your COI, and your
                          coverage will become active.</span>
                      </div>
                    </Tooltip>
                  </p>
                  <!-- We have to override the background color here -->
                  <CheckoutError v-if="!!errorMessage">
                    {{ errorMessage }}
                  </CheckoutError>
                  <span
                    v-if="alreadyAcceptedQuote"
                    class="paid-block"
                  >
                    {{ alreadyAcceptedQuote }}
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <p
          v-if="activePaymentExperience.isAch"
          class="mandate-disclaimer"
        >
          By clicking Send for Signature and entering your payment information via Plaid, you
          authorize Vouch Insurance to debit your bank account for any amount owed for purchases of
          products from Vouch Insurance, including any recurring, periodic, or subscription
          payments. You may change or cancel your authorization at any time by emailing
          <a href="mailto:success@vouch.us">success@vouch.us</a>.
        </p>
        <p
          v-if="showTransactionFeeText"
          class="mandate-disclaimer"
        >
          If you choose to pay by credit or debit card, your quote will include a transaction
          surcharge of approximately 3% on the transaction amount. If you choose to pay by ACH, your
          quote will include a transaction surcharge of approximately 0.8% on transaction amount
          with a maximum of $5.
        </p>
      </template>
    </CenteredContainer>
  </div>
</template>

<script>
import {
  getMonthlyQuotePremiumCents,
  getMonthlyTotalCents,
  getMonthlyRestQuoteAmountCents,
  getAnnualQuotePremiumCents,
  getHasAcceptedQuote,
  getFees,
  getTaxes,
  getTransactionFees,
  getMonthlyTransactionFees,
  getInitialMonthlyPayment,
  getAnnualQuoteAmountCents,
} from '@/onboarding/lib/selectors/quote_selectors';
import { longCurrency } from '@/shared/lib/filters/currency_filters';
import { onboardingUrl } from '@/onboarding/router/routeHelper';
import { getStripeErrorMessage } from '@/onboarding/constants/stripeErrorStrings';
import ButtonPrimary from '@/shared/components/ui/atoms/buttons/ButtonPrimary';
import ButtonOutline from '@/shared/components/ui/atoms/buttons/ButtonOutline';
import CoverageList from '@/onboarding/views/wizard/card/checkout/CoverageList';
import { PackageConfig } from '@/onboarding/constants/PackageConfig';
import CheckoutFormSectionOfficerSignature from './checkout_form_sections/CheckoutFormSectionOfficerSignature';
import CheckoutFormSectionSvbcCoiAgreement from './checkout_form_sections/CheckoutFormSectionSvbcCoi';
import CheckoutFormSectionPrice from './checkout_form_sections/CheckoutFormSectionPrice';
import CheckoutError from '@/onboarding/views/wizard/card/checkout/CheckoutError';
import {
  getApplicationData,
  applicationIsRenewal,
  getApplicationUpgradeState,
} from '@/onboarding/lib/selectors/storeSelectors';
import CenteredContainer from '@/shared/components/layouts/CenteredContainer';
import CheckoutFormSectionSvbePromo from './checkout_form_sections/CheckoutFormSectionSvbePromo';
import {
  PAYMENT_COMPLETED,
  SVBC_COI_REQUESTED,
  PAYMENT_METHOD_SELECTED,
  PAYMENT_COMPLETED_RENEWAL,
  PAYMENT_COMPLETED_NEW_BIZ,
} from '@/onboarding/services/SegmentEventTypes';
import CheckoutFormSectionBrex10KPointsPromo from '@/onboarding/views/wizard/card/checkout/checkout_form_sections/CheckoutFormSectionBrex10KPointsPromo';
import PaymentExperience, { PaymentMethod } from '@/onboarding/services/PaymentExperience';
import Tooltip from '@/onboarding/components/Tooltip';
import VouchIcon from '@/shared/components/ui/atoms/icons/VouchIcon';
import { getPartnerDiscountAmount, totalOfType } from '@/onboarding/services/pricingHelper';
import Caret from '@/onboarding/components/Caret';
import CheckoutFormPricingBreakdown from './checkout_form_sections/CheckoutFormPricingBreakdown';
import PaymentMethodDetails from './PaymentMethodDetails';
import SectionWithTitle from '@/onboarding/views/SectionWithTitle';
import NewPaymentMethodForm from '@/onboarding/views/wizard/card/checkout/NewPaymentMethodForm';
import CoiAcknowledgement from '@/onboarding/views/wizard/card/checkout/CoiAcknowledgement';

const cardTitles = {
  general_liability: 'General Liability & Business Property',
  hired_and_nonowned_auto: 'Hired & Non-owned Auto',
  directors_and_officers: 'Directors & Officers',
  employment_practices_liability: 'Employment Practices Liability',
  cem_errors_and_omissions: 'Errors & Omissions',
  cem_cyber_crime: 'Cyber Coverage',
  cem_cyber_extortion: 'Cyber Coverage',
  fiduciary: 'Fiduciary Coverage',
  employee_benefits_liability: 'Employee Benefits Liability',
  ny_employment_practices_liability: 'Employment Practices Liability',
  ny_errors_and_omissions: 'Errors & Omissions',
  ny_cyber: 'Cyber Coverage',
  ny_fiduciary: 'Fiduciary Coverage',
  ny_employee_benefits_liability: 'Employee Benefits Liability',
  ny_directors_and_officers: 'Directors & Officers',
  employee_dishonesty: 'Crime Coverage',

  cpp_gl_prod_cops: 'General Liability', // 'Products and Completed Operations', in General Liability group
  cpp_gl_ebl: 'Employee Benefits Liability',
  cpp_gl_hnoa: 'Hired & Non-owned Auto',
  cpp_prop_contents: 'Business Property',
};

export default {
  name: 'CheckoutFormStandard',
  components: {
    CenteredContainer,
    CheckoutError,
    ButtonPrimary,
    ButtonOutline,
    CheckoutFormSectionOfficerSignature,
    CheckoutFormSectionSvbcCoiAgreement,
    CheckoutFormSectionPrice,
    CheckoutFormSectionSvbePromo,
    CheckoutFormSectionBrex10KPointsPromo,
    CoverageList,
    Tooltip,
    VouchIcon,
    Caret,
    CheckoutFormPricingBreakdown,
    PaymentMethodDetails,
    SectionWithTitle,
    NewPaymentMethodForm,
    CoiAcknowledgement,
  },
  dependencies: ['requests', 'tracking', 'featureFlags', 'plaid'],
  filters: { longCurrency },
  props: {
    applicationId: {
      type: String,
      required: true,
    },
    rawQuoteData: {
      type: Object,
      required: true,
    },
    packageSlug: {
      type: String,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
    formIsValid: {
      type: Boolean,
      required: true,
    },
    transactionFees: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      errorMessage: undefined,
      stripeToken: '',
      officerInfo: {
        email: '',
        name: '',
        title: '',
      },
      activePaymentExperience: PaymentExperience.creditCardOnly(),
      newPaymentExperience: PaymentExperience.creditCardOnly(),
      previousPaymentExperience: PaymentExperience.previousPaymentMethod(),
      coiAcknowledged: false,
      quotePricingDetails: [],
      agreeToSvbcCoi: !!PackageConfig[this.packageSlug].checkoutFormSvbcCoiComponent,
      loading: false,
      loadingExperience: false,
      coveragesExpanded: true,
    };
  },

  computed: {
    monthlyTotalPremiumCents() {
      return getMonthlyQuotePremiumCents(this);
    },
    monthlyPayment() {
      return getMonthlyRestQuoteAmountCents(this);
    },
    annualPremiumCents() {
      return getAnnualQuotePremiumCents(this);
    },
    alreadyAcceptedQuote() {
      if (getHasAcceptedQuote(this)) {
        return 'Quote already accepted';
      }
      return null;
    },
    fees() {
      return getFees(this, this.paymentSchedule, 'total');
    },
    taxes() {
      return getTaxes(this, this.paymentSchedule, 'total');
    },
    transactionFeesAmount() {
      return getTransactionFees(this);
    },
    monthlyTransactionFees() {
      return getMonthlyTransactionFees(this);
    },
    restMonthlyFees() {
      return getFees(this, this.paymentSchedule, 'regular');
    },
    restMonthlyTaxes() {
      return getTaxes(this, this.paymentSchedule, 'regular');
    },
    initialMonthlyFees() {
      return getFees(this, this.paymentSchedule, 'initial');
    },
    initialMonthlyTaxes() {
      return getTaxes(this, this.paymentSchedule, 'initial');
    },
    requiresCoiAck() {
      return this.activePaymentExperience.isAch;
    },
    cannotPurchase() {
      return (
        !!this.alreadyAcceptedQuote ||
        (this.requiresCoiAck && !this.coiAcknowledged) ||
        !this.$data.officerInfo.email ||
        !this.$data.officerInfo.name ||
        !this.$data.officerInfo.title
      );
    },
    paymentSchedule() {
      return this.$route.query.paymentSchedule;
    },
    monthlyPaymentSchedule() {
      return this.paymentSchedule === 'monthly';
    },
    includedCoverageNames() {
      return [
        ...new Set(
          Object.values(this.rawQuoteData.koverages_accepted)
            .map(acceptedCoverage => cardTitles[acceptedCoverage])
            .filter(coverage => coverage)
        ),
      ];
    },
    paymentFrequencyString() {
      return this.monthlyPaymentSchedule ? '2 months up front' : 'per year';
    },
    premiumAmount() {
      if (this.monthlyPaymentSchedule) {
        return this.monthlyTotalPremiumCents;
      }
      return this.annualPremiumCents;
    },
    adjustmentsAmount() {
      return totalOfType('adjustments', this.quotePricingDetails, this.paymentSchedule);
    },
    savingsAmount() {
      return totalOfType('discounts', this.quotePricingDetails, this.paymentSchedule);
    },
    subtotalAmount() {
      const taxes = totalOfType('taxes', this.quotePricingDetails, this.paymentSchedule);
      const fees = totalOfType('fees', this.quotePricingDetails, this.paymentSchedule);
      const transactionFees = this.transactionFeesAmount;
      let total;
      let adjustmentsSavings = this.adjustmentsAmount + this.savingsAmount;

      if (this.monthlyPaymentSchedule) {
        adjustmentsSavings /= 12;
        total = this.getTotal / 12;
      } else {
        total = this.getTotal;
      }

      return total - taxes - fees - adjustmentsSavings - transactionFees;
    },
    getTotal() {
      if (this.monthlyPaymentSchedule) {
        return getMonthlyTotalCents(this) + this.transactionFeesAmount;
      }
      return getAnnualQuoteAmountCents(this) + this.transactionFeesAmount;
    },
    partnerName() {
      const partnerName = PackageConfig[this.packageSlug].partnerName || 'PARTNER';
      return partnerName.toLowerCase() === 'svb' ? 'PARTNER' : partnerName;
    },
    pricingLoaded() {
      return this.quotePricingDetails.length > 0;
    },
    shouldShowPartnerOfferApplied() {
      const partnerDiscountAmount = getPartnerDiscountAmount(
        this.quotePricingDetails,
        this.paymentSchedule
      );
      return partnerDiscountAmount !== 0;
    },
    paymentAmount() {
      if (this.monthlyPaymentSchedule) {
        return getInitialMonthlyPayment(this) + this.transactionFeesAmount;
      }
      return getAnnualQuoteAmountCents(this) + this.transactionFeesAmount;
    },
    svbcCoiComponent() {
      return PackageConfig[this.packageSlug].checkoutFormSvbcCoiComponent;
    },
    showCancelationReminder() {
      return PackageConfig[this.packageSlug].checkoutFormOfficerSignCancelReminder;
    },
    priceSection() {
      return PackageConfig[this.packageSlug].checkoutFormPrice;
    },
    promoComponent() {
      return PackageConfig[this.packageSlug].checkoutPromoComponent;
    },
    transactionFeePaymentMethod() {
      return this.activePaymentExperience.paymentMethod === 'credit_card' ? 'card' : 'bank_account';
    },
    showTransactionFeeText() {
      return (
        this.featureFlags.getFlag({ flag: 'charge-transaction-fees' }) &&
        this.transactionFeesAmount > 0
      );
    },
  },

  async created() {
    this.loadingExperience = true;
    try {
      this.prefillOfficerInfo();
      await this.initPreviousPaymentExperience();
      this.initNewPaymentExperience();
      if (this.previousPaymentExperience.isPreviousPaymentAvailable) {
        this.activePaymentExperience = this.previousPaymentExperience;
      } else {
        this.activePaymentExperience = this.newPaymentExperience;
      }
    } finally {
      this.loadingExperience = false;
    }

    if (!this.monthlyPaymentSchedule) {
      await this.getQuotePricingDetails();
    }
  },

  methods: {
    prefillOfficerInfo() {
      const appData = getApplicationData(this);
      const reviewData = appData['2019-07-01--REVIEW_CONTAINER'];

      if (reviewData['2019-07-01--APPLICANT_RELATIONSHIP_TO_COMPANY'] === 'CEO') {
        this.officerInfo.name = reviewData['2019-07-01--APPLICANT_FULLNAME'];
        this.officerInfo.email = appData['2019-07-01--EMAIL'];
        this.officerInfo.title = reviewData['2019-07-01--APPLICANT_RELATIONSHIP_TO_COMPANY'];
      }
    },
    async initPreviousPaymentExperience() {
      const previous = await this.requests.getPreviousPaymentInfo({
        applicationId: this.applicationId,
      });

      this.previousPaymentExperience = PaymentExperience.previousPaymentMethod(
        previous?.data || {}
      );
    },
    async getQuotePricingDetails() {
      await this.requests.getQuotePricing({
        id: this.rawQuoteData.id,
        onSuccess: result => {
          this.quotePricingDetails = result.data.quote.pricing;
        },
      });
    },
    includedCoveragesOnClick() {
      this.coveragesExpanded = !this.coveragesExpanded;
    },
    initNewPaymentExperience() {
      const shouldIncludeAch = this.featureFlags.getFlag({ flag: 'ach-payment' });
      if (!shouldIncludeAch) {
        this.newPaymentExperience = PaymentExperience.creditCardOnly();
      } else if (!this.plaid.isInitialized()) {
        this.$rollbar.error('Plaid library could not be loaded');
        this.newPaymentExperience = PaymentExperience.creditCardOnly();
      } else {
        const defaultPaymentMethod =
          PackageConfig[this.packageSlug].defaultPaymentMethod?.(this.promoComponent) ||
          PaymentMethod.ACH;
        this.newPaymentExperience = PaymentExperience.achAndCreditCard(defaultPaymentMethod);
      }
    },
    handleRequestToUsePreviousPayment() {
      this.activePaymentExperience = this.previousPaymentExperience;
    },
    handleRequestToUpdatePayment() {
      this.activePaymentExperience = this.newPaymentExperience;
    },
    handleSelectedPaymentMethod(paymentMethod) {
      this.tracking.sendEvent(PAYMENT_METHOD_SELECTED, { type: paymentMethod });
      this.activePaymentExperience = PaymentExperience.achAndCreditCard(paymentMethod);
    },
    handleCoiAcknowledgement(acknowledged) {
      this.coiAcknowledged = acknowledged;
    },
    setErrorMessage(err) {
      this.errorMessage = err;
    },
    setStripeToken(token) {
      this.stripeToken = token;
    },
    async onSubmitForm() {
      this.loading = true;
      this.errorMessage = '';

      // Submitting is still permitted with the above errors; it'll just display that error
      if (this.cannotPurchase) {
        this.loading = false;
      } else if (!this.$refs.form.validate()) {
        this.errorMessage = 'The officer information is incomplete. Please check it and try again.';
        this.loading = false;
      } else {
        try {
          if (this.activePaymentExperience.isPreviousPaymentMethod) {
            await this.submitPayment();
          } else if (this.activePaymentExperience.isAch) {
            await this.plaidCheckout();
          } else {
            await this.submitPayment({ stripeToken: this.stripeToken });
          }
        } catch (error) {
          this.errorMessage = error;
        } finally {
          this.loading = false;
        }
      }
    },
    async plaidCheckout() {
      try {
        const linkToken = await this.createLinkToken();

        const { plaidToken, plaidAccountId, canceled } = await this.plaidLink(linkToken);

        if (canceled) return;

        await this.submitPayment({ plaidToken, plaidAccountId });
      } catch {
        this.errorMessage =
          'Unable to process your payment at this time. Please retry or use another method.';
      }
    },
    createLinkToken() {
      return this.requests
        .createLinkToken(this.applicationId)
        .then(({ data }) => data.link_token)
        .catch(error => {
          this.$rollbar.error('Failed to create link token.');
          throw error;
        });
    },
    plaidLink(linkToken) {
      return this.plaid.open(linkToken).catch(error => {
        const { error_message } = error;

        this.$rollbar.error(
          `Failed to successfully link payment method with Plaid. Error Message: ${error_message}`
        );

        throw error;
      });
    },

    async submitPayment({ stripeToken, plaidToken, plaidAccountId } = {}) {
      let data = {
        token: stripeToken,
        plaid_token: plaidToken,
        plaid_account_id: plaidAccountId,
        package_slug: this.packageSlug,
        amount_cents: this.paymentAmount,
        payment_schedule: this.paymentSchedule,
        officer_name: this.officerInfo.name,
        officer_email: this.officerInfo.email,
        officer_title: this.officerInfo.title,
        payment_method: this.transactionFeePaymentMethod,
        transaction_fees_displayed: this.showTransactionFeeText,
      };

      data = this.addAdditionalPaymentDataProperties(data);

      const paymentEventProperties = {
        experience: this.activePaymentExperience.mode,
        paymentMethod: this.activePaymentExperience.paymentMethod,
        paymentAmountCents: this.paymentAmount,
        paymentAmount: this.paymentAmount / 100,
        value: this.premiumAmount / 100,
      };

      this.tracking.sendEvent(PAYMENT_COMPLETED, paymentEventProperties);

      const isRenewal = applicationIsRenewal(this);
      const isUpgrade = getApplicationUpgradeState(this);

      if (!isUpgrade) {
        if (!isRenewal) {
          this.tracking.sendEvent(PAYMENT_COMPLETED_NEW_BIZ, paymentEventProperties);
        } else {
          this.tracking.sendEvent(PAYMENT_COMPLETED_RENEWAL, paymentEventProperties);
        }
      }

      if (this.agreeToSvbcCoi) {
        this.tracking.sendEvent(SVBC_COI_REQUESTED, { applicationId: this.applicationId });
      }

      return this.requests
        .createPayment({
          applicationId: this.applicationId,
          data,
        })
        .then(async () => {
          await this.$store.dispatch('fetchApplicationData');
          this.$router.push({
            path: onboardingUrl({
              cardId: 'officer-signature',
              applicationId: this.applicationId,
              params: { packageSlug: this.packageSlug },
            }),
          });
        })
        .catch(err => {
          this.errorMessage = getStripeErrorMessage(...err.response.data.errors);
        });
    },

    addAdditionalPaymentDataProperties(dataObj) {
      if (this.svbcCoiComponent) {
        dataObj.known_coi_recipient = 'svb';
      }
      return dataObj;
    },

    handleOfficerTitleChange() {
      this.officerInfo.title = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-spinner {
  padding-top: $space-xxlarge;
  text-align: center;
  min-height: 100vh;
}

.my-medium {
  margin-top: $space-medium;
  margin-bottom: $space-medium;
}

.mb-medium {
  margin-bottom: $space-medium;
}

.mb-xlarge {
  margin-bottom: $space-xlarge;
}

.detail-text {
  @include detail-text;
}

.body-text {
  @include body-text;
}

.price-breakdown-loading-spinner {
  padding-top: $space-large;
  text-align: center;
}

.checkout-form-section--mobile {
  padding-right: 0px;
}

.offer-applied-notification {
  @include detail-text;
  font-weight: bold;
  color: $special-offer-text-color;
  text-align: right;
  padding-bottom: 30px;
}

.pay-and-send-btn {
  margin-left: 0;
  max-width: 100%;
}

.premium-section {
  position: sticky;
  top: $space-large;
}

.premium-section-row {
  margin-bottom: $space-medium;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .list-heading {
    @include detail-text;
    margin-bottom: $space-small;
    color: $coverage-list-heading-color;
  }
  .package-coverages-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.paid-block {
  display: block;
  @include validation-error-text;
  margin-top: $space-small;
  padding-left: $space-small;
}

.pricing-breakdown {
  min-height: 9vh;
}

.checkout-form__container {
  padding-bottom: $space-xlarge;
}

.mandate-disclaimer {
  @include detail-text;
  color: $vouch-dark-gray;
  margin-top: $space-large;
}

.coi-disclaimer {
  @include detail-text;
  color: $vouch-dark-gray;
  margin-top: $space-small;
  text-align: right;
  display: flex;
  align-items: center;

  &__text {
    margin-right: $space-small;
  }
}
</style>
