interface NotificationContent {
  iconName?: string | null;
  isError?: boolean;
  message: string;
}

const initialState = {
  notificationContent: {
    iconName: null,
    isError: false,
    message: '',
  },
  isNotificationVisible: false,
};

export const getters = {
  getNotificationContent: state => state.notificationContent,
  getNotificationMessage: state => state.notificationContent.message,
  getVisibility: state => state.isNotificationVisible,
};

export const actions = {
  displayNotification(
    { commit },
    { iconName = null, isError = false, message }: NotificationContent
  ) {
    commit({
      type: 'setNotificationData',
      iconName: iconName || null,
      isError,
      message,
    });
    commit({
      type: 'setNotificationVisibility',
      isNotificationVisible: true,
    });
  },
};

export const mutations = {
  setNotificationData: (state, { iconName, isError, message }) => {
    state.notificationContent.iconName = iconName;
    state.notificationContent.isError = isError;
    state.notificationContent.message = message;
  },
  setNotificationVisibility: (state, isVisible: boolean) => {
    state.isNotificationVisible = isVisible;
  },
};

export default {
  state: initialState,
  getters,
  actions,
  mutations,
};
