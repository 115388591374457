<script>
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import SchemaFormFieldNumber from '@/onboarding/components/formfields/SchemaFormFieldNumber';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';

export default {
  name: 'WizardCardPrequalNumBigRevenueContracts',
  components: { SchemaFormFieldNumber },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('niche'),
      submitPath: getUpdateEndpoint('niche'),
      cardConfigPath: '2019-07-01--NUM_BIG_REVENUE_CONTRACTS',
      formFieldData: [
        {
          component: SchemaFormFieldNumber,
          dataPath: '2019-07-01--NUM_BIG_REVENUE_CONTRACTS',
          schemaPath: '2019-07-01--NUM_BIG_REVENUE_CONTRACTS',
        },
      ],
    };
  },
};
</script>
