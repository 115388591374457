<template>
  <ButtonBase
    v-bind="$attrs"
    class="button-dark"
    v-on="$listeners"
  >
    <slot />
  </ButtonBase>
</template>

<style lang="scss" scoped>
.button-dark.button-dark.button-dark.button-dark {
  background-color: $button-dark-background-color;
  color: $button-dark-text-color;
}
</style>

<script>
import ButtonBase from './ButtonBase';

export default {
  name: 'ButtonDark',
  components: { ButtonBase },
};
</script>

<style lang="scss" scoped>
.button-dark {
  &:hover {
    opacity: 0.9;
    box-shadow: 1px 1px 5px $button-primary-box-shadow-color--hover !important;
  }
}
</style>
