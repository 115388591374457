<template>
  <v-container
    fluid
    :class="{ 'no-side-padding': $vuetify.breakpoint.xs }"
  >
    <v-row
      no-gutters
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="8"
        xl="6"
      >
        <h2 class="review-fields-heading">
          Review your information
        </h2>
        <ul class="review-fields-list">
          <li
            v-for="reviewField in reviewFields"
            :key="reviewField.key"
          >
            <ReviewField
              :data-testid="reviewField.name + '-review-field'"
              :card-id="reviewField.name"
              :value="reviewField.value"
              :parent-value="reviewField.parentValue"
              :title="reviewField.title"
              :route="reviewField.route"
              :is-valid="reviewField.isValid"
              :is-editable="isEditable && reviewField.isEditable"
              :wrap="reviewField.wrap"
            />
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ReviewField from '@/onboarding/components/formfields/ReviewField';
import $RefParser from '@apidevtools/json-schema-ref-parser';
import { getSchema, schemaNames } from '@/onboarding/questions_library/questions';
import { GenerateReviewFields } from '@/onboarding/services/GenerateReviewFields';
import { getCardId } from '@/shared/routing/getCardId';
import {
  applicationIsProgram2,
  applicationIsRenewal,
} from '@/onboarding/lib/selectors/storeSelectors';
import { mapMutations } from 'vuex';
import {
  getUpdatedLegacyNicheMappingsBasedOnPrimaryNiche,
  isNicheDataPrefilled,
} from '@/onboarding2/niche.domain';

export default {
  name: 'ReviewFields',
  components: { ReviewField },
  dependencies: ['requests', 'featureFlags'],
  props: {
    routeFormatter: {
      required: true,
      type: Function,
    },
    applicationData: {
      required: true,
      type: Object,
    },
    applicationParentData: {
      type: Object,
      default: () => ({}),
    },
    applicationId: {
      required: true,
      type: String,
    },
    isEditable: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    reviewFields: [],
    canEdit: null,
    routingComponent: {},
    derefSchema: null,
  }),
  computed: {
    isProgram2() {
      return applicationIsProgram2(this);
    },
  },
  beforeDestroy() {
    this.wipeReviewErrors();
  },
  async created() {
    const cardId = getCardId(this.$route);
    const routingComponent = {
      cardId,
      applicationId: this.applicationId,
      applicationData: this.applicationData,
      $auth: this.$auth,
    };
    const schema = await getSchema({
      schemaName: schemaNames.definitions,
      isProgram2: this.isProgram2,
    });
    const { definitions } = await $RefParser.dereference(schema, { resolve: { external: false } });
    let newApplicationData = this.applicationData;
    if (
      this.featureFlags.getFlag({ flag: 'hide-niche-determination-questions' }) &&
      applicationIsProgram2(this) &&
      !isNicheDataPrefilled(this.applicationData)
    ) {
      const primaryNiche = this.applicationData['2021-06-01--PRIMARY_NICHE'];
      const data = getUpdatedLegacyNicheMappingsBasedOnPrimaryNiche(primaryNiche);
      if (data) {
        newApplicationData = {
          ...newApplicationData,
          ...data,
        };
        this.sendLegacyNicheMappingData(data);
      }
    }

    this.reviewFields = new GenerateReviewFields({
      ...this,
      applicationId: this.applicationId,
      applicationData: newApplicationData,
      applicationParentData: this.applicationParentData,
      definitions,
      routingComponent,
      isProgram2: this.isProgram2,
      isRenewal: applicationIsRenewal(this),
    }).generate(this.routeFormatter);

    const errorCount = this.reviewFields.reduce((acc, { isValid }) => (isValid ? acc : acc + 1), 0);
    this.setReviewErrors({ errorCount });
  },
  methods: {
    ...mapMutations(['setReviewErrors', 'wipeReviewErrors', 'updateApplicationData']),
    sendLegacyNicheMappingData(data, onSuccess) {
      const defaultSuccess = serverData => {
        this.onSubmitSuccessLegacyNicheData(serverData);
      };

      this.requests.updateApplicationData({
        context: this,
        applicationId: this.applicationId,
        path: 'program_2_pre_auth',
        data,
        onSuccess: onSuccess || defaultSuccess,
        onError: data => {
          this.onSubmitErrorLegacyNicheData(data);
        },
      });
    },
    onSubmitErrorLegacyNicheData(data) {
      window.vueRoot.$rollbar.error('Unhandled niche onSubmitErrorLegacyNicheData', data);
    },
    async onSubmitSuccessLegacyNicheData(applicationData) {
      this.$store.commit('clearSubmitData');
      await this.updateApplicationData({ applicationData });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-side-padding {
  padding-left: 0;
  padding-right: 0;
}

.review-fields-list {
  list-style: none;
  padding: 0 !important;
}

.review-fields-heading {
  @include h3-text;
  margin: 0 $space-small $space-large $space-small;
}
</style>
