import * as VueDeepSet from 'vue-deepset';
import Vue from 'vue';

import _set from 'lodash/set';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _sortedUniq from 'lodash/sortedUniq';
import _isArray from 'lodash/isArray';
import _isPlainObject from 'lodash/isPlainObject';

export const set = _set;
export const get = _get;
export const isNil = _isNil;
export const isArray = _isArray;
export const isPlainObject = _isPlainObject;
export const setProp = (obj: any, props: any, value: any) => {
  VueDeepSet.vueSet(obj, props, value);
};
export const sortedUniq = _sortedUniq;

export function deleteProp(obj: any, prop: any) {
  Vue.delete(obj, prop);
}

export const eachLeaves = (obj: any) => {
  const values = Object.values(obj);
  const mapped: any = values.map(value => {
    if (typeof value === 'object') {
      // covers {} and []
      return eachLeaves(value);
    }
    return value;
  });
  return [].concat(...mapped);
};

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
export function storageAvailable(type: 'localStorage' | 'sessionStorage') {
  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}
