import { applicationIsProgram2 } from '@/onboarding/lib/selectors/storeSelectors';

export const renewalUrl = ({
  cardId,
  applicationId,
  params = {},
  isProgram2 = applicationIsProgram2(window.vueRoot, applicationId),
}: {
  cardId: string;
  applicationId: string;
  params?: any;
  isProgram2?: boolean;
}) => {
  const programRenewalRoute = isProgram2 ? 'renewal' : 'renew';
  const fullParams = { applicationId, ...params };
  const queryString = Object.keys(fullParams)
    .map(key => `${key}=${fullParams[key]}`)
    .join('&');

  return `/${programRenewalRoute}/${cardId}?${queryString}`;
};

// This method is solely for handling the way this logic is exposed via the urlFormatter property
// of the question router (where there are seprate P1 and P2 routers). Other calls in the app
// that use onboardingUrl() should use the above.
export const renewalUrlP2 = ({
  cardId,
  applicationId,
  params = {},
}: {
  cardId: string;
  applicationId: string;
  params?: any;
}) => {
  const fullParams = { applicationId, ...params };
  const queryString = Object.keys(fullParams)
    .map(key => `${key}=${fullParams[key]}`)
    .join('&');

  return `/renewal/${cardId}?${queryString}`;
};
