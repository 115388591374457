<template>
  <div>
    <template v-if="!loaded">
      <div class="page-loading">
        <v-progress-circular indeterminate />
      </div>
    </template>
    <template v-else>
      <WithBackground :color="backgroundColor">
        <QuestionCardConfigFrame
          v-slot="{ hasBackButton, percentComplete, minutesLeft, currentNavStep }"
          :card-id="cardId"
          :funnel-config="funnelConfig"
          :time-total="timeTotal"
        >
          <WizardPageWrapper
            header-text="Renew Coverage"
            :has-back-button="hasBackButton"
            :progress="percentComplete"
            :minutes-left="minutesLeft"
          >
            <template v-slot:left-panel>
              <RenewalNavigationBar
                class="nav-bar"
                :current-nav-step="currentNavStep"
              />
            </template>
            <router-view
              v-if="loaded"
              :card-id="cardId"
              :application-id="applicationId"
              v-bind="$attrs"
            />
            <template v-slot:below-card-content>
              <router-view
                :application-id="applicationId"
                name="belowCardContent"
              />
            </template>
          </WizardPageWrapper>
        </QuestionCardConfigFrame>
      </WithBackground>
    </template>
  </div>
</template>

<script>
import { renewalWizardConfig } from '@/members/services/renewalWizardConfig';
import { renewalWizardConfigProgram2 } from '@/members/services/renewalWizardConfigProgram2';

import { getCardId } from '@/shared/routing/getCardId';
import { onQuestionLoad } from '@/shared/services/onQuestionLoad';
import { RenwalQuestions } from '@/members/constants/Constants';
import RenewalNavigationBar from '@/members/components/wizard/RenewalNavigationBar';
import WizardPageWrapper from '@/shared/components/wizard/WizardPageWrapper';
import QuestionCardConfigFrame from '@/shared/components/wizard/QuestionCardConfigFrame';
import WithBackground from '@/shared/frames/WithBackground';
import Colors from '@/Colors';
import { applicationIsProgram2 } from '@/onboarding/lib/selectors/storeSelectors';
import {
  renewalRedirectAwayOnPageLoad,
  renewalRedirectAwayOnRouteChange,
} from '@/members/router/renewalsRedirect';
import { checkAppForRenewalNewOrOldFunnel } from '@/onboarding/router/newVsOldFunnelGuards';

export default {
  name: 'RenewalQuestionBase',
  components: {
    WithBackground,
    QuestionCardConfigFrame,
    WizardPageWrapper,
    RenewalNavigationBar,
  },
  props: {
    applicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  beforeRouteUpdate(nextRoute, previousRoute, next) {
    this.onCardChange();
    renewalRedirectAwayOnRouteChange({
      next,
      nextRoute,
      $store: this.$store,
      applicationId: this.applicationId,
    });
  },
  computed: {
    cardId() {
      return getCardId(this.$route);
    },
    isProgram2() {
      return applicationIsProgram2(this);
    },
    funnelConfig() {
      return this.isProgram2 ? renewalWizardConfigProgram2 : renewalWizardConfig;
    },
    timeTotal() {
      return RenwalQuestions.assumedMinutes;
    },
    backgroundColor() {
      return Colors.lightBackgroundColor;
    },
  },
  async created() {
    await onQuestionLoad({ context: this });
    renewalRedirectAwayOnPageLoad(this);
    const routeAway = await checkAppForRenewalNewOrOldFunnel(this);
    this.loaded = !routeAway;
    this.onCardChange();
  },
  dependencies: ['trackingSetup', 'featureFlags', 'store'],
  async mounted() {
    await onQuestionLoad({ context: this });
    await checkAppForRenewalNewOrOldFunnel(this);
  },
  methods: {
    onCardChange() {
      this.$store.commit('clearSubmitData');
    },
  },
};
</script>

<style lang="scss" scoped>
.page-loading {
  text-align: center;
  margin-top: $space-xxxlarge;
}
</style>
