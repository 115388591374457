<script>
import {
  getSchemaEndpoint,
  getUpdateEndpoint,
} from '../../../../../onboarding/lib/selectors/apiPathSelectors';
import SchemaFormFieldAutocomplete from '@/onboarding/components/formfields/SchemaFormFieldAutocomplete';
import BaseCardWithFields from '../../../../components/wizard/BaseCardWithFields';

// TODO
export default {
  name: 'BenefitsCard',
  components: { SchemaFormFieldAutocomplete },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('core'),
      submitPath: getUpdateEndpoint('core'),
      cardConfigPath: '2019-07-01--BENEFITS_WORTH',
      formFieldData: [
        {
          component: SchemaFormFieldAutocomplete,
          schemaPath: '2019-07-01--BENEFITS_WORTH',
          dataPath: '2019-07-01--BENEFITS_WORTH',
        },
      ],
    };
  },
};
</script>
