<template>
  <IsMobile v-slot="{ isMobile }">
    <div
      data="root"
      class="checkout-page"
    >
      <component
        :is="headerComponent"
        :is-mobile="isMobile"
        :application-id="applicationId"
      />
      <div class="page-content">
        <template v-if="loadingPrice">
          <div class="page-loading">
            <v-progress-circular indeterminate />
          </div>
        </template>
        <template v-else>
          <CheckoutFormStandard
            :card-id="cardId"
            :is-mobile="isMobile"
            :application-id="applicationId"
            :loading-price="loadingPrice"
            :raw-quote-data="rawQuoteData"
            :package-slug="packageSlug"
            :transaction-fees="transactionFees"
            v-bind="$attrs"
          />
        </template>
        <component :is="footerComponent" />
      </div>
    </div>
  </IsMobile>
</template>

<script>
import CheckoutFormStandard from '@/onboarding/views/wizard/card/checkout/CheckoutFormStandard';
import WithFeatureFlag from '@/shared/frames/WithFeatureFlag';
import { getSelectedPackageData } from '@/onboarding/lib/selectors/storeSelectors';
import { mapActions } from 'vuex';
import IsMobile from '@/shared/frames/IsMobile';
import { PackageConfig } from '@/onboarding/constants/PackageConfig';
import { PAYMENT_VIEWED } from '@/onboarding/services/SegmentEventTypes';

export default {
  name: 'CheckoutPage',
  components: {
    CheckoutFormStandard,
    IsMobile,
    WithFeatureFlag,
  },
  dependencies: ['tracking'],
  props: {
    cardId: {
      type: String,
      required: true,
    },
    applicationId: {
      type: String,
      required: true,
    },
    packageSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rawQuoteData: {
        metadata: {},
        policies: {},
      },
      transactionFees: {},
      loadingPrice: true,
    };
  },
  computed: {
    isPayWhenYouRaise() {
      // eslint-disable-next-line camelcase
      return this.rawQuoteData?.metadata?.is_pay_when_you_raise;
    },
    headerComponent() {
      return PackageConfig[this.packageSlug].checkoutPageHeaderComponent;
    },
    footerComponent() {
      return PackageConfig[this.packageSlug].checkoutPageFooterComponent;
    },
  },
  async created() {
    await this.loadPackagesData();
    this.rawQuoteData = getSelectedPackageData(this).quote;
    this.transactionFees = getSelectedPackageData(this).metadata.transaction_fees;
    this.loadingPrice = false;
    this.tracking.sendEvent(PAYMENT_VIEWED);
  },
  methods: {
    ...mapActions(['loadPackagesData']),
  },
};
</script>

<style lang="scss" scoped>
.checkout-page {
  background-color: $vouch-white;
}
.page-content {
  margin-top: $space-large;
}

.page-loading {
  text-align: center;
}
</style>
