<template>
  <IconLink
    data-testid="back-button"
    class="back-button"
    icon-name="chevronLeft"
    text="Back"
    @click="goBack"
  />
</template>

<script>
import { getApplicationData } from '@/onboarding/lib/selectors/storeSelectors';
import IconLink from '@/shared/components/ui/molecules/IconLink';
import { getCardId } from '@/shared/routing/getCardId';

export default {
  name: 'BackButton',
  components: { IconLink },
  dependencies: ['questionRouter'],
  methods: {
    goBack() {
      const cardId = getCardId(this.$route);
      const { applicationId } = this.$root._route.query;
      const applicationData = getApplicationData(this);
      const fakeCardComponent = {
        cardId,
        applicationId,
        applicationData,
        $router: this.$router,
        $auth: this.$auth,
      };
      if (cardId === '2019-07-01--LOCATIONS') {
        // The AddressAttributesAndAdditionalCard manages its own back button logic
        this.$root.$emit('address-card-step-back', fakeCardComponent);
      } else {
        this.questionRouter.previous(fakeCardComponent);
      }
    },
  },
};
</script>
