<template>
  <router-view
    v-if="loaded"
    :application-id="applicationId"
    v-bind="$attrs"
  />
</template>

<script>
import Colors from '@/Colors';

import { getCardId } from '@/shared/routing/getCardId';
import { onQuestionLoad } from '@/shared/services/onQuestionLoad';
import { applicationIsProgram2 } from '@/onboarding/lib/selectors/storeSelectors';
import {
  onboardingredirectAwayOnPageLoad,
  onboardingRedirectAwayOnRouteChange,
} from '@/onboarding/router/onboardingRedirectAway';

export default {
  name: 'OnboardingWizardBase',
  dependencies: ['trackingSetup', 'featureFlags'],
  props: {
    applicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      // This is the fastest way to verify Program 2 status; in some instances, the store
      // containing application data has not booted at the time the questionRouter is added.
      isProgram2: this.$route.path.includes('application') || applicationIsProgram2(this),
    };
  },
  computed: {
    cardId() {
      return getCardId(this.$route);
    },
    backgroundColor() {
      return Colors.pageBackgroundColor;
    },
  },
  beforeRouteUpdate(nextRoute, previousRoute, next) {
    this.onCardChange();

    if (['fin', 'discretion-quote-blocking'].includes(getCardId(previousRoute))) {
      // going back from the fin page means that you'll be going to the
      // page that directed you to it. so we gotta skip over that page
      // so you're not stuck.
      this.$router.go(-1);
    } else {
      onboardingRedirectAwayOnRouteChange({
        next,
        nextRoute,
        previousRoute,
        $store: this.$store,
        applicationId: this.applicationId,
      });
    }
  },
  async created() {
    await onQuestionLoad({ context: this });
    this.onCardChange();
    this.loaded = true;
    onboardingredirectAwayOnPageLoad(this);
  },
  methods: {
    onCardChange() {
      this.$store.commit('clearSubmitData');
    },
  },
};
</script>
