<template>
  <div
    v-bind="$attrs"
    class="caret"
    :class="{'caret--flip': flip}"
    v-on="$listeners"
  >
    <CaretSvg />
  </div>
</template>

<script>
import CaretSvg from '@/onboarding/assets/images/caret.svg';

export default {
  name: 'Caret',
  components: { CaretSvg },
  props: {
    flip: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.caret {
  width: $vouch-icon-size;
  height: $vouch-icon-size;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  transition: transform $easing-timing;

  &--flip {
    transform: rotate(180deg);
  }
}
</style>
