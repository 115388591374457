<script>
import BaseCardWithFields from '@/shared/components/wizard/BaseCardWithFields';
import SchemaFormFieldAutocomplete from '@/onboarding/components/formfields/SchemaFormFieldAutocomplete';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';

export default {
  name: 'CurrentClaimsNumCard',
  components: { SchemaFormFieldAutocomplete },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('core'),
      submitPath: getUpdateEndpoint('core'),
      cardConfigPath: '2021_06_01--CURRENT_CLAIMS_NUM',
      formFieldData: [
        {
          component: SchemaFormFieldAutocomplete,
          schemaPath: '2021_06_01--CURRENT_CLAIMS_NUM',
          dataPath: '2021_06_01--CURRENT_CLAIMS_NUM',
        },
      ],
    };
  },
};
</script>
