import HasAnyPiiDescription from '@/onboarding/components/card_data/descriptions/HasAnyPiiDescription.vue';
import HasAnyPiiDescriptionProgram2 from '@/onboarding/components/card_data/descriptions/HasAnyPiiDescriptionProgram2.vue';

// this is used for Id's where...we don't really use this config, but for duck typing, it's necessary .
const blankUIConfig = {
  meta: {},
  question: {},
  review: {},
  card: {},
};

const questionUIConfig = {
  '2019-07-01--VERTICAL_NICHE': {
    meta: {},
    question: {
      title: 'How would you describe what your business does?',
    },
    review: {
      title: 'Business niche',
    },
    card: {
      title: 'How would you describe what your business does?',
      description:
        'These categories aren’t a perfect representation of your startup, but they help us determine the right coverage for you.',
    },
  },
  '2019-07-01--CATEGORY_TECHNOLOGY': {
    question: {
      title: 'We are a',
    },
    review: {
      title: 'Category',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--CATEGORY_CUSTOMER': {
    question: {
      title: 'startup for',
    },
    review: {
      title: 'Category',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--FRIENDLY_ENTITY_NAME': {
    question: {
      title: 'FRIENDLY ENTITY NAME',
    },
    review: {
      title: 'Startup name',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--CATEGORY_VERTICAL': {
    question: {
      title: 'we provide',
    },
    review: {
      title: 'Category',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--CATEGORY_NICHE': {
    question: {
      title: 'through',
    },
    review: {
      title: 'Category',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--WEBSITE': {
    question: {
      title: 'WEBSITE',
    },
    review: {
      title: 'Startup website',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--EMAIL': {
    question: {
      title: 'EMAIL',
    },
    review: {
      title: 'Startup email contact',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--AGREED_TERMS_AND_CONDITIONS': {
    question: {
      title: 'AGREED TERMS AND CONDITIONS',
    },
    review: {},
    meta: {},
    card: {},
  },
  '2019-07-01--HOW_DID_YOU_HEAR_ABOUT_US': {
    question: {
      title: 'HOW DID YOU HEAR ABOUT US',
    },
    review: {},
    meta: {},
    card: {},
  },
  '2019-07-01--HOW_DID_YOU_HEAR_ABOUT_US_OTHER': {
    question: {
      title: 'HOW DID YOU HEAR ABOUT US OTHER',
    },
    review: {},
    meta: {},
    card: {},
  },
  '2019-07-01--HQ_PREQUAL_LOCATION': {
    meta: {},
    question: {
      title: 'Where is your business headquarters?',
    },
    review: {
      title: 'Location of HQ',
    },
    card: {
      title: 'Where is your business headquarters?',
      description: '',
    },
  },
  '2019-07-01--HAS_INSURANCE_SELF': {
    meta: {},
    question: {
      title: 'Does your company already have insurance?',
    },
    review: {
      title: 'Already insured?',
    },
    card: {
      title: 'Does your company already have insurance?',
      description:
        "Not including health insurance or workers' compensation. We're talking about coverages like General Liability, Property, Directors & Officers, etc.",
    },
  },
  '2019-07-01--HAS_INSURANCE_REQS': {
    meta: {},
    question: {
      title:
        'Does your company have any specific insurance requirements from a client, partner, or service provider?',
    },
    review: {
      title: 'Has insurance requirements',
    },
    card: {
      title:
        'Does your company have any specific insurance requirements from a client, partner, or service provider?',
      description:
        "<i>Investors, customer contracts, and landlords often require specific types of coverage and limits. We'll gather your specific needs later in the application.</i>",
    },
  },
  '2019-07-01--NUM_FULL_TIME_WORKERS': {
    meta: {},
    question: {
      title: 'How many full-time employees does your company have today?',
    },
    review: {
      title: 'Number of full-time employees',
    },
    card: {
      title: 'How many full-time employees does your company have today?',
      description:
        "Don't count contractors.  Please be as accurate as possible, because your application and policy will be specific to the number of employees you enter.",
    },
  },
  '2019-07-01--HAS_PRODUCT_IN_MARKET': {
    meta: {},
    question: {
      title:
        'Has your company launched a product already, or is your company planning to do so in the next 12 months?',
    },
    review: {
      title: "Is the business's product in market?",
    },
    card: {
      title:
        'Has your company launched a product already, or is your company planning to do so in the next 12 months?',
      description:
        'Pushed your app to the store, started your private beta online, shipped the widget to your early adopters - whether you are charging for it or not - is your product live?',
    },
  },
  '2019-07-01--LEGAL_ENTITY_NAME': {
    meta: {},
    question: {
      title: "What is your company's legal business name?",
    },
    review: {
      title: 'Legal business name',
    },
    card: {
      title: "What is your company's legal business name?",
      description:
        'In your policy and application, we will refer to this or {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} as <b>Company</b>.',
    },
  },
  '2019-07-01--YEAR_INCORPORATED': {
    meta: {},
    question: {
      title: 'When was your company incorporated?',
    },
    review: {
      title: 'Year incorporated',
    },
    card: {
      title: 'When was your company incorporated?',
      description: '',
    },
  },
  '2019-07-01--HAS_OTHER_BOARD_DIRECTORS': {
    meta: {},
    question: {
      title: 'Does your company have board members other than the founders?',
    },
    review: {
      title: 'Has non-founding board members?',
    },
    card: {
      title: 'Does your company have board members other than the founders?',
      description: '',
    },
  },
  '2019-07-01--CAPITAL_RAISED_CONTAINER': {
    meta: {},
    question: {
      title: 'How much capital have you raised?',
    },
    review: {
      title: 'Capital raised',
    },
    card: {
      title: 'How much capital have you raised?',
      description:
        'Please put in the total amount you have raised since company formation. This includes venture capital, angel investment, syndicates, grants, and funding from friends and family. If you expect to close a new funding round in the next 90 days, include that round in your total.',
    },
  },
  '2019-07-01--CAPITAL_RAISED_EQUITY_CENTS': {
    question: {
      title: 'equity raised',
    },
    review: {
      title: 'Capital raised: equity',
    },
    meta: {
      displayType: 'currency',
    },
    card: {},
  },
  '2019-07-01--CAPITAL_RAISED_DEBT_CENTS': {
    question: {
      title: 'debt raised',
    },
    review: {
      title: 'Capital raised: debt',
    },
    meta: {
      displayType: 'currency',
    },
    card: {},
  },
  '2019-07-01--ANNUAL_REVENUE_CONTAINER': {
    meta: {},
    question: {
      title: 'Estimated Revenue',
    },
    review: {
      title: 'Annual Revenue',
    },
    card: {
      title: 'Estimated Revenue',
      description:
        'If your company is pre-revenue, provide a rough estimate. Your responses will always be kept confidential.',
    },
  },
  '2019-07-01--ANNUAL_REVENUE_CURRENT_CENTS': {
    question: {
      title: 'last 12 months (from today)',
    },
    review: {
      title: 'Current annual revenue',
    },
    meta: {
      displayType: 'currency',
    },
    card: {},
  },
  '2019-07-01--ANNUAL_REVENUE_NEXT_YEAR_CENTS': {
    question: {
      title: 'next 12 months (from today)',
    },
    review: {
      title: "Next year's annual revenue",
    },
    meta: {
      displayType: 'currency',
    },
    card: {},
  },
  '2019-07-01--TOTAL_CASH_AND_EQUIVALENTS_CENTS': {
    question: {
      title: 'Total cash and equivalents',
    },
    review: {
      title: 'Total cash and equivalents',
    },
    meta: {
      displayType: 'currency',
    },
    card: {
      title: 'Total cash and equivalents',
      description:
        'This includes cash in your business bank account, plus any liquid securities. Please do not include crypto-currencies.',
    },
  },
  '2019-07-01--LOCATION_CONTAINER': {
    meta: {},
    question: {
      title: 'What is your primary business address?',
    },
    review: {
      title: 'Business address(es)',
    },
    card: {
      title: 'What is your primary business address?',
      description:
        'If your team is currently working remotely, enter wherever the majority of your team would typically meet to get work done. This can be a leased office, coworking space, or home. Always fully remote? Enter the address of the founder or CEO.',
    },
  },
  '2019-07-01--LOCATION_CONTAINER_ADDITIONAL': {
    meta: {},
    question: {
      title: 'Additional business address',
    },
    review: {},
    card: {
      title: 'Additional business address',
      description: '',
    },
  },
  '2019-07-01--LOCATION_CONTAINER_ADDITIONAL__review': {
    question: {
      title: 'LOCATION CONTAINER ADDITIONAL review',
    },
    review: {},
    meta: {},
    card: {},
  },
  '2019-07-01--ADDRESS_LINE_1': {
    question: {
      title: 'Address Line 1',
    },
    review: {
      title: 'Address',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--ADDRESS_LINE_2': {
    question: {
      title: 'Floor, Suite, etc. (optional)',
      hidePreviousAnswer: true,
    },
    review: {
      title: 'Additional Address Info',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--ADDRESS_CITY': {
    question: {
      title: 'City',
    },
    review: {},
    meta: {},
    card: {},
  },
  '2019-07-01--ADDRESS_COUNTY': {
    question: {
      title: 'County',
    },
    review: {},
    meta: {},
    card: {},
  },
  '2019-07-01--ADDRESS_STATE': {
    question: {
      title: 'State',
    },
    review: {},
    meta: {},
    card: {},
  },
  '2019-07-01--ADDRESS_POSTAL_CODE': {
    question: {
      title: 'ZIP/Postal Code',
    },
    review: {},
    meta: {},
    card: {},
  },
  '2019-07-01--LOCATION_TYPE_PRIMARY': {
    question: {
      title: 'Location type',
    },
    review: {
      title: 'Location type',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--LOCATION_TYPE_ADDITIONAL': {
    question: {
      title: 'Location type',
    },
    review: {
      title: 'Location type',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--LOCATION_ASSET_RANGE': {
    meta: {},
    question: {
      title: 'Estimated cost to replace assets at that location',
    },
    review: {
      title: 'Location asset value',
    },
    card: {
      title: 'Estimated cost to replace assets at that location',
      description:
        '<strong>Our pre-loaded estimate</strong> assumes that you have the typical assets for a startup your size (mostly laptops and desks). If you have special equipment or unique circumstances, please select the appropriate range below to ensure your property is covered.',
    },
  },
  '2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS': {
    meta: {},
    question: {
      title: 'Full-time employees at that location',
    },
    review: {
      title: 'Number of full-time workers at location',
    },
    card: {
      title: 'Full-time employees at that location',
      description: '',
    },
  },
  '2019-07-01--INSURANCE_HISTORY_CONTAINER': {
    meta: {},
    question: {
      title: 'In the last three years, has your <b>Company</b> experienced any of the following:',
    },
    review: {
      title: 'Insurance History',
    },
    card: {
      title: 'In the last three years, has your <b>Company</b> experienced any of the following:',
      description: '<b>Claim</b> will be defined in your policy and application.',
    },
  },
  '2019-07-01--HAS_CURRENT_CLAIMS': {
    meta: {},
    question: {
      title:
        'An <b>Employee</b>, customer, shareholder, investor, competitor or regulator of your Company has made a written demand or filed a civil proceeding against your Company',
    },
    review: {
      title: 'Claim against the business?',
    },
    card: {
      title:
        'An <b>Employee</b>, customer, shareholder, investor, competitor or regulator of your Company has made a written demand or filed a civil proceeding against your Company',
      description: '',
    },
  },
  '2019-07-01--HAS_DENIED_COVERAGE': {
    meta: {},
    question: {
      title:
        'An insurer of your <b>company</b> (other than an employee benefits provider) cancelled or refused to renew an insurance policy due to claims, losses or fraud.',
    },
    review: {
      title: 'Previously denied coverage?',
    },
    card: {
      title:
        'An insurer of your <b>company</b> (other than an employee benefits provider) cancelled or refused to renew an insurance policy due to claims, losses or fraud.',
      description: '',
    },
  },
  '2019-07-01--HAS_FILED_LIABILITY_CLAIM': {
    meta: {},
    question: {
      title:
        'Your <b>company</b> filed a liability <b>claim</b> with their insurance company (not including any property claims).',
    },
    review: {
      title: 'Has filed a liability claim?',
    },
    card: {
      title:
        'Your <b>company</b> filed a liability <b>claim</b> with their insurance company (not including any property claims).',
      description: 'Description here.',
    },
  },
  '2019-07-01--CURRENT_CLAIMS_DESCRIPTION': {
    meta: {},
    question: {
      title: 'For any formal actions or claims...',
    },
    review: {
      title: 'Claims Pending',
    },
    card: {
      title: 'For any formal actions or claims...',
      description:
        'Please provide details, including any actions taken by your company to mitigate a future loss.',
    },
  },
  '2019-07-01--DENIED_COVERAGE_DESCRIPTION': {
    meta: {},
    question: {
      title: 'For any cancellations or denials of coverage...',
    },
    review: {
      title: 'Denied Coverage',
    },
    card: {
      title: 'For any cancellations or denials of coverage...',
      description:
        'Please provide details, including any actions taken by your company to mitigate a future loss.',
    },
  },
  '2019-07-01--FILED_CLAIMS_TYPES': {
    meta: {},
    question: {
      title: 'Please select all that apply.',
      multiSelectDescriptions: {
        'Employment Practices Liability':
          'Claims related to employees (or former employees) around employment-related issues, except for workers’ compensation.',
        'Directors & Officers':
          'Claims against the directors or officers of your business alleging mismanagement or breach of fiduciary duty.',
        'General Liability': 'Claims alleging either bodily injury or property damage.',
        'Errors & Omissions': 'Claims alleging errors in my services.',
        Cyber: 'Claims for a data breach and cyber incident.',
        "Workers' Compensation": 'A workers compensation claim for an employee that got injured.',
      },
    },
    review: {},
    card: {},
  },
  '2019-07-01--FILED_CLAIMS_AMOUNTS': {
    meta: {},
    question: {
      title: 'How much do you expect to be paid out by your insurer for these claims?',
    },
    review: {},
    card: {},
  },
  '2019-07-01--FILED_CLAIMS_DESCRIPTION': {
    meta: {},
    question: {
      title:
        'Please provide details, including any actions taken by your company, to mitigate a future loss.',
    },
    review: {},
    card: {},
  },
  '2019-07-01--LIABILITIES_CLAIMS_CONTAINER': {
    meta: {},
    question: {
      title: '',
    },
    review: {
      title: 'Liability Claims Filed',
    },
    card: {
      title: 'What types of liability claims have been filed?',
      description: '',
    },
  },
  '2019-07-01--HAS_PENDING_CLAIMS': {
    meta: {},
    question: {
      title:
        'Are you aware of any fact, circumstance, or situation that might reasonably result in a written demand or civil or criminal proceeding against your company or any additional insured listed on the policy?',
    },
    review: {
      title: 'Pending claim against the business?',
    },
    card: {
      title:
        'Are you aware of any fact, circumstance, or situation that might reasonably result in a written demand or civil or criminal proceeding against your company or any additional insured listed on the policy?',
      description: '',
    },
  },
  '2019-07-01--PENDING_CLAIMS_DESCRIPTION': {
    meta: {},
    question: {
      title:
        'Please provide details on the facts, circumstances, or situations that might reasonably result in a written demand or civil or criminal proceeding against your business or any additional insured for this policy...',
    },
    review: {
      title: 'Pending Claims Description',
    },
    card: {
      title:
        'Please provide details on the facts, circumstances, or situations that might reasonably result in a written demand or civil or criminal proceeding against your business or any additional insured for this policy...',
      description: 'Include any actions taken by your company to mitigate a future loss',
    },
  },
  '2019-07-01--HAS_ENGINEERING_CONTRACTORS': {
    meta: {},
    question: {
      title: 'Do you have any engineering contractors?',
    },
    review: {
      title: 'Has engineering contractors?',
    },
    card: {
      title: 'Do you have any engineering contractors?',
      description:
        'Please answer yes if you also employ with any engineering contract firms or agencies.',
    },
  },
  '2019-07-01--NUM_ENGINEERING_CONTRACTORS': {
    meta: {},
    question: {
      title: 'How many engineering contractors do you have? ',
    },
    review: {
      title: 'Number of engineering contractors',
    },
    card: {
      title: 'How many engineering contractors do you have? ',
      description: '',
    },
  },
  '2019-07-01--HAS_401K': {
    meta: {},
    question: {
      title: 'Does your company sponsor a 401(k) plan for its employees?',
    },
    review: {
      title: 'Has 401(k) Plan',
    },
    card: {
      title: 'Does your company sponsor a 401(k) plan for its employees?',
      description: '',
    },
  },
  '2021-16-02--SUBJECT_TO_ERISA': {
    meta: {},
    question: {
      title:
        'Does your company sponsor any benefit plans subject to ERISA for its employees, other than 401(k) plans or health and welfare plans?',
    },
    review: {
      title: 'Subject to ERISA?',
    },
    card: {
      title:
        'Does your company sponsor any benefit plans subject to ERISA for its employees, other than 401(k) plans or health and welfare plans?',
      description:
        'This includes benefit plans like a pension plan, deferred compensation plans, or profit sharing plans. This does not include benefits health and welfare benefit plans like medical, dental, vision, life or disability.',
    },
  },
  '2019-07-01--BENEFITS_WORTH': {
    meta: {},
    question: {
      title: 'What are the total assets of your benefit plans?',
    },
    review: {
      title: 'Benefits Value',
    },
    card: {
      title: 'What are the total assets of your benefit plans?',
      description:
        'Include all plans subject to ERISA - 401ks, pensions, everything, deferred compensation plans, etc.',
    },
  },
  '2019-07-01--NUM_INVOLUNTARY_TERMINATION': {
    meta: {},
    question: {
      title:
        'How many full-time employees have been involuntarily terminated within the last 12 months?',
    },
    review: {
      title: 'Number of employees involuntarily terminated',
    },
    card: {
      title:
        'How many full-time employees have been involuntarily terminated within the last 12 months?',
      description: '',
    },
  },
  '2019-07-01--NUM_PLANNED_INVOLUNTARY_TERMINATION': {
    meta: {},
    question: {
      title:
        'How many full-time employees does your company plan to involuntarily terminate in the next 12 months?',
    },
    review: {
      title: 'Planned number of employees to be involuntarily terminated',
    },
    card: {
      title:
        'How many full-time employees does your company plan to involuntarily terminate in the next 12 months?',
      description: '',
    },
  },
  '2019-07-01--HAS_ANY_PII': {
    meta: {},
    question: {
      title: 'Does your company collect PII in its products, or plan to in the next 12 months?',
    },
    review: {
      title: 'Collects PII?',
    },
    card: {
      title: 'Does your company collect PII in its products, or plan to in the next 12 months?',
      descriptionComponent: HasAnyPiiDescription,
    },
  },
  '2019-07-01--PII_AMOUNT': {
    meta: {},
    question: {
      title: "How many individuals' PII will be collected after the 12 months?",
    },
    review: {
      title: 'Number of PII Records',
    },
    card: {
      title: "How many individuals' PII will be collected after the 12 months?",
      description:
        'PII, or personal identifiable information, includes social security numbers, credit scores, credit card & payment information, birth dates, personal health information, and etc. Enter "NO" for this question if you only use a third-party service to collect and store this type of information (for example, Stripe).',
    },
  },
  '2019-07-01--REVIEW_INFORMATION': {
    meta: {},
    question: {
      title: 'Has anything else changed with your business?',
    },
    review: {},
    card: {
      title: 'Has anything else changed with your business?',
      description:
        "Review last year's answers below, and please update to reflect any other, less common changes.",
    },
  },
  '2019-07-01--REVIEW_CONTAINER': {
    meta: {},
    question: {
      title: 'Confirm your information below, and we’ll calculate your price.',
    },
    review: {},
    card: {
      title: 'Review your information below, and we’ll calculate your price.',
      description:
        'I verify that the information presented here is accurate to the best of my knowledge',
    },
  },
  '2019-07-01--APPLICANT_FULLNAME': {
    meta: {},
    question: {
      title: 'APPLICANT FULL NAME',
    },
    review: {
      title: 'N/A?',
    },
    card: {
      title: 'APPLICANT FULL NAME',
      description: 'Full Name',
    },
  },
  '2019-07-01--APPLICANT_RELATIONSHIP_TO_COMPANY': {
    meta: {},
    question: {
      title: 'APPLICANT RELATIONSHIP TO COMPANY',
    },
    review: {},
    card: {
      title: '',
      description: 'Relationship to {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}',
    },
  },
  '2019-07-01--HAS_ANY_SENSITIVE_PII': {
    meta: {},
    question: {
      title:
        "Does your company collect social security numbers, driver's license or passport numbers, or individual health and medical information?",
    },
    review: {
      title: 'Collects sensitive PII?',
    },
    card: {
      title:
        "Does your company collect social security numbers, driver's license or passport numbers, or individual health and medical information?",
      description: '',
    },
  },
  '2019-07-01--HAS_DANGEROUS_PRODUCTS': {
    meta: {},
    question: {
      title: 'Does your company provide products that can potentially cause injury to people?',
    },
    review: {
      title: 'Has dangerous products?',
    },
    card: {
      title: 'Does your company provide products that can potentially cause injury to people?',
      description:
        'This includes products such as firearms, ammunition, tobacco, alcohol, cannabis, pharmaceuticals, chemicals, medical equipment, medical devices, military supplies, smoke detectors, carbon monoxide detectors, dietary supplements, sports equipment, children’s toys, baby products, automobiles, airplanes, scooters or motorcycles.',
    },
  },
  '2019-07-01--HAS_CONTRACTORS_DISPUTES': {
    meta: {},
    question: {
      title:
        'Has your company been involved in any disputes related to status of employees or contractors?',
    },
    review: {
      title: 'Has Contractors Dispute',
    },
    card: {
      title:
        'Has your company been involved in any disputes related to status of employees or contractors?',
      description:
        'This includes any dispute or demands involving (1) the status of your 1099 contractors and whether or not they can be classified as such or (2) any licensing or other regulatory requirements for your employees or contractors within the industry you operate?',
    },
  },
  '2019-07-01--HAS_PROFESSIONAL_LICENSE_BUSINESS': {
    meta: {},
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide any advice, services or consulting that usually requires a professional license?',
    },
    review: {
      title: 'Is Professional License Business',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide any advice, services or consulting that usually requires a professional license?',
      description:
        'This includes legal advice, medical services, investment advice, accounting services, architectural, and engineering services.',
    },
  },
  '2019-07-01--HAS_CERTIFICATE_BUSINESS': {
    meta: {},
    question: {
      title:
        'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} responsible for, or do you plan to become responsible for, designing or administering any accreditation or certification programs, member peer review, standard setting, or compliance certifications?',
    },
    review: {
      title: 'Is Certificate Business',
    },
    card: {
      title:
        'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} responsible for, or do you plan to become responsible for, designing or administering any accreditation or certification programs, member peer review, standard setting, or compliance certifications?',
      description:
        'This includes designing or administering any accreditation or certification programs, member peer review, standard setting, or compliance certifications.',
    },
  },
  '2019-07-01--HAS_MOBILE_GAMES': {
    meta: {},
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} develop or provide access to games that are played on mobile devices?',
    },
    review: {
      title: 'Has Mobile Games',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} develop or provide access to games that are played on mobile devices?',
      description: '',
    },
  },
  '2019-07-01--HAS_PHYSICAL_ACTIVITY_BUSINESS': {
    meta: {},
    question: {
      title: 'Does your company provide products or apps used for physical activity?',
    },
    review: {
      title: 'Has Physical Activity Business',
    },
    card: {
      title: 'Does your company provide products or apps used for physical activity?',
      description:
        'This includes products or applications used by consumers in conjunction with, or associated with, physical activity such as running, biking, swimming, skiing, hiking, or weightlifting.',
    },
  },
  '2019-07-01--BUSINESS_DESCRIPTION': {
    meta: {},
    question: {
      title: 'Please provide a description of products and/or services you provide.',
    },
    review: {
      title: 'Business Description',
    },
    card: {
      title: 'Please provide a description of products and/or services you provide.',
      description: '',
    },
  },
  '2019-07-01--HAS_MEDICAL_DATA': {
    meta: {},
    question: {
      title: 'Does your company collect or facilitate the collection of medical information?',
    },
    review: {
      title: 'Has Medical Data',
    },
    card: {
      title: 'Does your company collect or facilitate the collection of medical information?',
      description:
        'This includes individual medical information such as allergies, medical conditions or pharmaceutical use, collected either directly or through applications. ',
    },
  },
  '2019-07-01--HAS_REGULATOR_LICENSE': {
    meta: {},
    question: {
      title: 'Is your company licensed or registered with any regulators?',
    },
    review: {
      title: 'Has Regulator License',
    },
    card: {
      title: 'Is your company licensed or registered with any regulators?',
      description: '',
    },
  },
  '2019-07-01--HAS_REAL_ESTATE_LICENSE': {
    meta: {},
    question: {
      title: 'Is your company a licensed or registered real estate broker?',
    },
    review: {
      title: 'Has Real Estate License',
    },
    card: {
      title: 'Is your company a licensed or registered real estate broker?',
      description: '',
    },
  },
  '2019-07-01--HAS_ADVERTISING_CLAIM': {
    meta: {},
    question: {
      title:
        'Has {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} had any demands or claims related to communications or advertising?',
    },
    review: {
      title: 'Has Advertising Claim',
    },
    card: {
      title:
        'Has {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} had any demands or claims related to communications or advertising?',
      description:
        'This includes any demands or claims either: <br>(1) brought by the: Federal Trade Commission (FTC), Federal Communications Commission (FCC), CTIA (Cellular Telecommunications Industry Association), MMA (Mobile Marketing Association), or similar state regulatory body, or <br><br>(2) brought by any person, company or body and alleging or related to violations of: TCPA (Telephone Consumer Protection Act), “CAN-SPAM” (Controlling The Assault of Non-Solicited Pornography and Marketing Act), Federal Communications Act, FTCA (Federal Trade Commission Act), or similar state or other local act.',
    },
  },
  '2019-07-01--INTERNATIONAL_SALES': {
    meta: {},
    question: {
      title:
        "What percentage of your company's revenue do you expect to come from sales outside of the United States over the next 18 months?",
    },
    review: {
      title: 'Has International Sales',
    },
    card: {
      title:
        "What percentage of your company's revenue do you expect to come from sales outside of the United States over the next 18 months?",
      description: '',
    },
  },
  '2019-07-01--HAS_PHYSICAL_PRODUCTS': {
    meta: {},
    question: {
      title:
        'In addition to software development, does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} sell any hardware or physical products?',
    },
    review: {
      title: 'Has Physical Products',
    },
    card: {
      title:
        'In addition to software development, does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} sell any hardware or physical products?',
      description: '',
    },
  },
  '2019-07-01--NUM_BIG_REVENUE_CONTRACTS': {
    meta: {},
    question: {
      title:
        'How many customer contracts, including current contracts and ones expected in the next twelve months, does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} have that will produce more than $100,000 in annual revenue?',
    },
    review: {
      title: 'Has Big Revenue Contracts',
    },
    card: {
      title:
        'How many customer contracts, including current contracts and ones expected in the next twelve months, does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} have that will produce more than $100,000 in annual revenue?',
      description: '',
    },
  },
  '2019-07-01--HAS_SERVICES_BUSINESS': {
    meta: {},
    question: {
      title:
        'Does your company provide medical services, investment advice, accounting services, architectural, or engineering services?',
    },
    review: {
      title: 'Has Services Business',
    },
    card: {
      title:
        'Does your company provide medical services, investment advice, accounting services, architectural, or engineering services?',
      description:
        'This includes advice, services or consulting provided to your clients for a fee or just as part of your services to clients. Please select "yes" if you intend to provide these kinds of advice, services or consulting in the future.',
    },
  },
  '2019-07-01--HAS_BENEFITS_BUSINESS': {
    meta: {},
    question: {
      title: 'Does your company provide any benefit or retirement plan design consulting?',
    },
    review: {
      title: 'Has Benefits Business',
    },
    card: {
      title: 'Does your company provide any benefit or retirement plan design consulting?',
      description:
        'Benefit consulting includes any advice, services, or consulting related to medical, dental, vision, life or disability. These could be provided your clients for a fee or just as part of your services to clients. Please select "yes" if you intend to provide these kinds of advice, services, or consulting in the future.',
    },
  },
  '2019-07-01--HAS_CHEMICALS': {
    meta: {},
    question: {
      title:
        'Does your company directly manufacture products or directly handle chemicals at a site managed by your company?',
    },
    review: {
      title: 'Has Chemicals Business',
    },
    card: {
      title:
        'Does your company directly manufacture products or directly handle chemicals at a site managed by your company?',
      description: '',
    },
  },
  '2019-07-01--HAS_POLLUTANTS': {
    meta: {},
    question: {
      title: 'Does your company handle pollutants or nuclear material?',
    },
    review: {
      title: 'Has Pollutants',
    },
    card: {
      title: 'Does your company handle pollutants or nuclear material?',
      description:
        'This includes directly engaging in or handling, or providing services or products to other enterprises to support their process of, testing, monitoring, clean up, removal, storage, treating, detoxifying, neutralizing, or in any way responding to, or assessing, pollutants or nuclear material.',
    },
  },
  '2019-07-01--HAS_CONSUMABLE_BUSINESS': {
    meta: {},
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide products or applications that allow a user to transfer or exchange money, currency or remit payment?',
    },
    review: {
      title: 'Has Consumables Business',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide products or applications that allow a user to transfer or exchange money, currency or remit payment?',
      description: '',
    },
  },
  '2019-07-01--HAS_MONEY_TRANSFER_BUSINESS': {
    meta: {},
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide products or applications that allow a user to transfer or exchange money, currency or remit payment?',
    },
    review: {
      title: 'Has Money Transfer Business',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide products or applications that allow a user to transfer or exchange money, currency or remit payment?',
      description: '',
    },
  },
  '2019-07-01--HAS_ACCOUNTING_BUSINESS': {
    meta: {},
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide any accounting, auditing or tax preparation advice or services for a fee or as part of your offering?',
    },
    review: {
      title: 'Has Accounting Business',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide any accounting, auditing or tax preparation advice or services for a fee or as part of your offering?',
      description: '',
    },
  },
  '2019-07-01--HAS_FINANCE_CLIENTS_BUSINESS': {
    meta: {},
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide products or services to any financial institutions?',
    },
    review: {
      title: 'Has Finance Clients',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide products or services to any financial institutions?',
      description: 'This includes banks, securities brokers, or insurance companies.',
    },
  },
  '2019-07-01--HAS_FINANCE_BUSINESS': {
    meta: {},
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide or facilitate mortgages, loans, or insurance?',
    },
    review: {
      title: 'Has Finance Business',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide or facilitate mortgages, loans, or insurance?',
      description: '',
    },
  },
  '2019-07-01--HAS_LOANS_BUSINESS': {
    meta: {},
    question: {
      title:
        'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} currently (or does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} plan to) make or facilitate loans? ',
    },
    review: {
      title: 'Has Loans Business',
    },
    card: {
      title:
        'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} currently (or does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} plan to) make or facilitate loans? ',
      description: '',
    },
  },
  '2019-07-01--HAS_REAL_ESTATE_BUSINESS': {
    meta: {},
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide or facilitate any type of real estate investing or real estate transactions?',
    },
    review: {
      title: 'Has Real Estate Business',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide or facilitate any type of real estate investing or real estate transactions?',
      description: '',
    },
  },
  '2019-07-01--HAS_MOBILITY_BUSINESS': {
    meta: {},
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} sell or provide access to any device or service used for motorized transportation, including scooters, cars, bikes, skateboards, etc',
    },
    review: {
      title: 'Has Mobility Business',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} sell or provide access to any device or service used for motorized transportation, including scooters,  cars, bikes, skateboards, etc',
      description: '',
    },
  },
  '2019-07-01--HAS_HIGH_RISK_CLIENTS': {
    meta: {},
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide hardware or software that is used by other manufacturers to develop high risk products?',
    },
    review: {
      title: 'Has High Risk Clients',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide hardware or software that is used by other manufacturers to develop high risk products?',
      description:
        'This includes products such as firearms, ammunition, tobacco, alcohol, cannabis, pharmaceuticals, chemicals, medical equipment, medical devices, military supplies, smoke detectors, carbon monoxide detectors, dietary supplements, sports equipment, children’s toys, baby products, automobiles, airplanes, scooters or motorcycles.',
    },
  },
  '2019-07-01--HAS_BLOCKCHAIN_BUSINESS': {
    meta: {},
    question: {
      title:
        'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} buying, selling, investing, trading, microtipping, or other engaging in other monetary aspects deals with a blockchain native token or subtoken.',
    },
    review: {
      title: 'Uses Blockchain',
    },
    card: {
      title:
        'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} buying, selling, investing, trading, microtipping, or other engaging in other monetary aspects deals with a blockchain native token or subtoken.',
      description: '',
    },
  },
  '2019-07-01--HAS_ROBOTICS_BUSINESS': {
    meta: {},
    question: {
      title: 'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} selling robotics as a service?',
    },
    review: {
      title: 'Uses Robotics',
    },
    card: {
      title: 'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} selling robotics as a service?',
      description: '',
    },
  },
  '2019-07-01--HAS_COPYRIGHT_CLAIM': {
    meta: {},
    question: {
      title:
        'Have any demands or claims been made against {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} related to possible copyright infringement, libel, slander, disparagement, or invasion of privacy?',
    },
    review: {
      title: 'Has Copyright claim',
    },
    card: {
      title:
        'Have any demands or claims been made against {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} related to possible copyright infringement, libel, slander, disparagement, or invasion of privacy?',
      description: '',
    },
  },
  '2019-07-01--OTHER_BOARD_MEMBERS': {
    question: {
      title:
        'Does your startup have board members other than the founders of {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?',
    },
    review: {
      title: 'Has other members on the board',
    },
    meta: {},
    card: {},
  },

  '2019-07-01--SCHEDULE_CALL': {
    question: {},
    review: {},
    meta: {},
    card: {
      title: 'Would you like your Insurance Pro to reach out and set up a call?',
      description:
        'We are always available to discuss your current coverage, or how your needs may have changed for the upcoming year.',
    },
  },
  // blank configs
  location_id: blankUIConfig,
  '2019-07-01--LOCATION_CONTAINER_attributes': blankUIConfig,
};

const questionUIConfigProgram2 = {
  '2019-07-01--FRIENDLY_ENTITY_NAME': {
    question: {
      title: 'FRIENDLY ENTITY NAME',
    },
    review: {
      title: 'Startup name',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--CATEGORY_VERTICAL': {
    question: {
      title: 'we provide',
    },
    review: {
      title: 'Category',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--CATEGORY_NICHE': {
    question: {
      title: 'through',
    },
    review: {
      title: 'Category',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--WEBSITE': {
    question: {
      title: 'WEBSITE',
    },
    review: {
      title: 'Startup website',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--EMAIL': {
    question: {
      title: 'EMAIL',
    },
    review: {
      title: 'Startup email contact',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--AGREED_TERMS_AND_CONDITIONS': {
    question: {
      title: 'AGREED TERMS AND CONDITIONS',
    },
    review: {},
    meta: {},
    card: {},
  },
  '2019-07-01--HOW_DID_YOU_HEAR_ABOUT_US': {
    question: {
      title: 'HOW DID YOU HEAR ABOUT US',
    },
    review: {},
    meta: {},
    card: {},
  },
  '2019-07-01--HOW_DID_YOU_HEAR_ABOUT_US_OTHER': {
    question: {
      title: 'HOW DID YOU HEAR ABOUT US OTHER',
    },
    review: {},
    meta: {},
    card: {},
  },
  '2019-07-01--HQ_PREQUAL_LOCATION': {
    meta: {},
    question: {
      title: 'Where is your business headquarters?',
    },
    review: {
      title: 'Location of HQ',
    },
    card: {
      title: 'Where is your business headquarters?',
      description: '',
    },
  },
  '2019-07-01--HAS_INSURANCE_SELF': {
    meta: {},
    question: {
      title: 'Does your company already have insurance?',
    },
    review: {
      title: 'Already insured?',
    },
    card: {
      title: 'Does your company already have insurance?',
      description:
        "Not including health insurance or workers' compensation. We're talking about coverages like General Liability, Property, Directors & Officers, etc.",
    },
  },
  '2019-07-01--HAS_INSURANCE_REQS': {
    meta: {},
    question: {
      title:
        'Does your company have any specific insurance requirements from a client, partner, or service provider?',
    },
    review: {
      title: 'Has insurance requirements',
    },
    card: {
      title:
        'Does your company have any specific insurance requirements from a client, partner, or service provider?',
      description:
        'These requests can come from investors, customer contracts, and landlords which often require specific types of coverage and limits.',
    },
  },
  '2021-06-01--HAS_INSURANCE_REQS_DESC': {
    meta: {},
    question: {
      title:
        'Please describe your insurance requirements so that our underwriters and Insurance Advisors have more context:',
    },
    review: {
      title: 'Insurance requirements description',
    },
    card: {
      title:
        'Please describe your insurance requirements so that our underwriters and Insurance Advisors have more context:',
      description: '',
    },
  },
  '2019-07-01--NUM_FULL_TIME_WORKERS': {
    meta: {},
    question: {
      title: 'How many full-time employees does your company have today?',
    },
    review: {
      title: 'Number of full-time employees',
    },
    card: {
      title: 'How many full-time employees does your company have today?',
      description:
        'Your quote and policy will be specific to your employee count. Include any founders and senior officers (even if they are not on payroll), W-2 employees, and any permanent, temporary, leased, or volunteer workers. Do not include any full or part-time contractors.',
    },
  },
  '2019-07-01--HAS_PRODUCT_IN_MARKET': {
    meta: {},
    question: {
      title:
        'Has your company launched a product already, or is your company planning to do so in the next 12 months?',
    },
    review: {
      title: "Is the business's product in market?",
    },
    card: {
      title:
        'Has your company launched a product already, or is your company planning to do so in the next 12 months?',
      description:
        'This could include pushing an app to the store, starting a private beta, or shipping a widget to your early adopters, regardless of payment status.',
    },
  },
  '2019-07-01--LEGAL_ENTITY_NAME': {
    meta: {},
    question: {
      title: "What is your company's legal business name?",
    },
    review: {
      title: 'Legal business name',
    },
    card: {
      title: "What is your company's legal business name?",
      description:
        'In your policy and application, we will refer to this or {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} as <b>Company</b>.',
    },
  },
  '2019-07-01--YEAR_INCORPORATED': {
    meta: {},
    question: {
      title: 'What year was your company incorporated?',
    },
    review: {
      title: 'Year incorporated',
    },
    card: {
      title: 'What year was your company incorporated?',
      description: '',
    },
  },
  '2019-07-01--HAS_OTHER_BOARD_DIRECTORS': {
    meta: {},
    question: {
      title: 'Does your company have board members other than the founders?',
    },
    review: {
      title: 'Has non-founding board members?',
    },
    card: {
      title: 'Does your company have board members other than the founders?',
      description: '',
    },
  },
  '2019-07-01--CAPITAL_RAISED_CONTAINER': {
    meta: {},
    question: {
      title: 'How much capital have you raised?',
    },
    review: {
      title: 'Capital raised',
    },
    card: {
      title: 'How much capital have you raised?',
      description:
        'Please put in the total amount you have raised since company formation. This includes venture capital, angel investment, syndicates, grants, and funding from friends and family. If you expect to close a new funding round in the next 90 days, include that round in your total.',
    },
  },
  '2019-07-01--CAPITAL_RAISED_EQUITY_CENTS': {
    question: {
      title: 'equity raised',
    },
    review: {
      title: 'Capital raised: equity',
    },
    meta: {
      displayType: 'currency',
    },
    card: {},
  },
  '2019-07-01--CAPITAL_RAISED_DEBT_CENTS': {
    question: {
      title: 'debt raised',
    },
    review: {
      title: 'Capital raised: debt',
    },
    meta: {
      displayType: 'currency',
    },
    card: {},
  },
  '2019-07-01--ANNUAL_REVENUE_CONTAINER': {
    meta: {},
    question: {
      title: 'Estimated Revenue',
    },
    review: {
      title: 'Annual Revenue',
    },
    card: {
      title: 'Estimated Revenue',
      description:
        'If your company is pre-revenue, provide a rough estimate. Your responses will always be kept confidential.',
    },
  },
  '2019-07-01--ANNUAL_REVENUE_CURRENT_CENTS': {
    question: {
      title: 'last 12 months (from today)',
    },
    review: {
      title: 'Current annual revenue',
    },
    meta: {
      displayType: 'currency',
    },
    card: {},
  },
  '2019-07-01--ANNUAL_REVENUE_NEXT_YEAR_CENTS': {
    question: {
      title: 'next 12 months (from today)',
    },
    review: {
      title: "Next year's annual revenue",
    },
    meta: {
      displayType: 'currency',
    },
    card: {},
  },
  '2019-07-01--TOTAL_CASH_AND_EQUIVALENTS_CENTS': {
    question: {
      title: 'Total cash and equivalents',
    },
    review: {
      title: 'Total cash and equivalents',
    },
    meta: {
      displayType: 'currency',
    },
    card: {
      title: 'Total cash and equivalents',
      description:
        'This includes cash in your business bank account, plus any liquid securities. Please do not include crypto-currencies.',
    },
  },
  '2019-07-01--LOCATION_CONTAINER': {
    meta: {},
    question: {
      title: "What's the primary business address of your company?",
    },
    review: {
      title: 'Business address(es)',
    },
    card: {
      title: "What's the primary business address of your company?",
      description:
        'If your team is currently working remotely, enter wherever the majority of your team would typically meet to get work done. This can be a leased office, coworking space, or home. If your company is fully remote, enter the address of the founder or CEO.',
    },
  },
  '2019-07-01--LOCATION_CONTAINER_ADDITIONAL': {
    meta: {},
    question: {
      title: 'Additional business address',
    },
    review: {},
    card: {
      title: 'Additional business address',
      description: '',
    },
  },
  '2019-07-01--LOCATION_CONTAINER_ADDITIONAL__review': {
    question: {
      title: 'LOCATION CONTAINER ADDITIONAL review',
    },
    review: {},
    meta: {},
    card: {},
  },
  '2019-07-01--ADDRESS_LINE_1': {
    question: {
      title: 'Address Line 1',
    },
    review: {
      title: 'Address',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--ADDRESS_LINE_2': {
    question: {
      title: 'Floor, Suite, etc. (optional)',
      hidePreviousAnswer: true,
    },
    review: {
      title: 'Additional Address Info',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--ADDRESS_CITY': {
    question: {
      title: 'City',
    },
    review: {},
    meta: {},
    card: {},
  },
  '2019-07-01--ADDRESS_COUNTY': {
    question: {
      title: 'County',
    },
    review: {},
    meta: {},
    card: {},
  },
  '2019-07-01--ADDRESS_STATE': {
    question: {
      title: 'State',
    },
    review: {},
    meta: {},
    card: {},
  },
  '2019-07-01--ADDRESS_POSTAL_CODE': {
    question: {
      title: 'ZIP/Postal Code',
    },
    review: {},
    meta: {},
    card: {},
  },
  '2019-07-01--LOCATION_TYPE_PRIMARY': {
    question: {
      title: 'Location type',
    },
    review: {
      title: 'Location type',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--LOCATION_TYPE_ADDITIONAL': {
    question: {
      title: 'Location type',
    },
    review: {
      title: 'Location type',
    },
    meta: {},
    card: {},
  },
  '2019-07-01--LOCATION_ASSET_RANGE': {
    meta: {},
    question: {
      title: 'Estimated cost to replace assets at that location',
    },
    review: {
      title: 'Location asset value',
    },
    card: {
      title: 'Estimated cost to replace assets at that location',
      description:
        '<strong>Our pre-loaded estimate</strong> assumes that you have the typical assets for a startup your size (mostly laptops and desks). If you have special equipment or unique circumstances, please select the appropriate range below to ensure your property is covered.',
    },
  },
  '2019-07-01--LOCATION_NUM_FULL_TIME_WORKERS': {
    meta: {},
    question: {
      title: 'Full-time employees at that location',
    },
    review: {
      title: 'Number of full-time workers at location',
    },
    card: {
      title: 'Full-time employees at that location',
      description: '',
    },
  },
  '2021-06-01--HAS_BIG_TICKET_ITEM': {
    meta: {},
    question: {
      title:
        'Does your company have any individual assets or equipment that would cost over $100,000 to replace?',
    },
    review: {
      title: 'High value asset',
    },
    card: {
      title:
        'Does your company have any individual assets or equipment that would cost over $100,000 to replace?',
      description:
        'Only if the assets/equipment are in locations that you have listed in this application. Do not include any supply chain related locations unless they are managed by your company.',
    },
  },
  '2021-06-01--BIG_TICKET_ITEM_DESC': {
    meta: {},
    question: {
      title: "Please describe the individual asset that's worth more than $100,000",
    },
    review: {
      title: 'High value asset description',
    },
    card: {
      title: "Please describe the individual asset that's worth more than $100,000",
      description: '',
    },
  },
  '2021-06-01--LEAD_INVESTOR_LAST_ROUND': {
    meta: {},
    question: {
      title: 'What VC or accelerator was the largest lead investor in your last funding round?',
    },
    review: {
      title: 'Lead investor last round',
    },
    card: {
      title: 'What VC or accelerator was the largest lead investor in your last funding round?',
      description: '',
    },
  },
  '2021-06-01--FOUNDER_PREVIOUS_RAISE': {
    meta: {},
    question: {
      title:
        'Have any founders at your company previously raised VC, SAFEs, or other types of equity as a founder of another company?',
    },
    review: {
      title: 'Founders have previously raised',
    },
    card: {
      title:
        'Have any founders at your company previously raised VC, SAFEs, or other types of equity as a founder of another company?',
      description: '',
    },
  },
  '2021-06-01--PERCENT_OWNED_NONVC': {
    meta: {},
    question: {
      title: 'What percentage of your company is owned by non-VC institutional investors?',
    },
    review: {
      title: '% owned by non-VC investors',
    },
    card: {
      title: 'What percentage of your company is owned by non-VC institutional investors?',
      description:
        'Common examples of non-VC institutional investors include banks, private equity, etc.',
    },
  },
  '2021-06-01--PREF_SHARES_SALES': {
    meta: {},
    question: {
      title:
        'Have any series of preferred shares included any of the following terms or structures:',
    },
    review: {
      title: 'Preferred share sales',
    },
    card: {
      title:
        'Have any series of preferred shares included any of the following terms or structures:',
      description:
        '<ul><li>Liquidation preference greater than 1x</li><li>Participating preferred</li><li>Reduction in stock price from the prior round</li></ul>',
    },
  },
  '2021-06-01--SHARES_PUBLICLY_LISTED': {
    meta: {},
    question: {
      title:
        'Are shares of your company listed on a public stock exchange, or planned to be listed in the next 12 months?',
    },
    review: {
      title: 'Shares are/will soon be publicly listed',
    },
    card: {
      title:
        'Are shares of your company listed on a public stock exchange, or planned to be listed in the next 12 months?',
      description:
        'This includes an IPO, Direct Listing, and SPACs. This does not include secondary private exchanges like CartaX.',
    },
  },
  '2021-06-01--ACQUIRE_10M_COMPANY': {
    meta: {},
    question: {
      title: 'Do either of these apply to {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?:',
    },
    review: {
      title: 'Company acquisition over $10 million',
    },
    card: {
      title: 'Do either of these apply to {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?:',
      description:
        "<ol><li>You have acquired a company in the last 12 months with a valuation of more than $10 million</li><li>You're planning to acquire a company in the next 6 months at a valuation of more than $10 million</li></ol>",
    },
  },
  '2019-07-01--INSURANCE_HISTORY_CONTAINER': {
    meta: {},
    question: {
      title: 'In the last three years, has your <b>Company</b> experienced any of the following:',
    },
    review: {
      title: 'Insurance History',
    },
    card: {
      title: 'In the last three years, has your <b>Company</b> experienced any of the following:',
      description: '<b>Claim</b> will be defined in your policy and application.',
    },
  },
  '2019-07-01--HAS_CURRENT_CLAIMS': {
    meta: {},
    question: {
      title:
        'An <b>Employee</b>, customer, shareholder, investor, competitor or regulator of your Company has made a written demand or filed a civil proceeding against your Company',
    },
    review: {
      title: 'Claim against the business?',
    },
    card: {
      title:
        'An <b>Employee</b>, customer, shareholder, investor, competitor or regulator of your Company has made a written demand or filed a civil proceeding against your Company',
      description: '',
    },
  },
  '2019-07-01--HAS_DENIED_COVERAGE': {
    meta: {},
    question: {
      title:
        'An insurer of your <b>company</b> (other than an employee benefits provider) cancelled or refused to renew an insurance policy due to claims, losses or fraud.',
    },
    review: {
      title: 'Previously denied coverage?',
    },
    card: {
      title:
        'An insurer of your <b>company</b> (other than an employee benefits provider) cancelled or refused to renew an insurance policy due to claims, losses or fraud.',
      description: '',
    },
  },
  '2019-07-01--HAS_FILED_LIABILITY_CLAIM': {
    meta: {},
    question: {
      title:
        '{{ 2019-07-01--FRIENDLY_ENTITY_NAME }} filed a liability claim with an insurance company (Not property claims).',
    },
    review: {
      title: 'Has filed a liability claim?',
    },
    card: {
      title:
        '{{ 2019-07-01--FRIENDLY_ENTITY_NAME }} filed a liability claim with an insurance company (Not property claims).',
      description: 'Description here.',
    },
  },
  '2019-07-01--CURRENT_CLAIMS_DESCRIPTION': {
    meta: {},
    question: {
      title: 'For any formal actions or claims...',
    },
    review: {
      title: 'Claims Pending',
    },
    card: {
      title: 'For any formal actions or claims...',
      description:
        'Please provide details, including any actions taken by your company to mitigate a future loss.',
    },
  },
  '2021_06_01--CURRENT_CLAIMS_NUM': {
    meta: {},
    question: {
      title: 'For those liability claims your company filed...',
    },
    review: {
      title: 'Number of liability claims filed',
    },
    card: {
      title: 'For those liability claims your company filed...',
      description:
        'How many liability claims have been filed by your company in the last three years?',
    },
  },
  '2019-07-01--DENIED_COVERAGE_DESCRIPTION': {
    meta: {},
    question: {
      title: 'For any cancellations or denials of coverage...',
    },
    review: {
      title: 'Denied Coverage',
    },
    card: {
      title: 'For any cancellations or denials of coverage...',
      description:
        'Please provide details, including any actions taken by your company to mitigate a future loss.',
    },
  },
  '2019-07-01--FILED_CLAIMS_TYPES': {
    meta: {},
    question: {
      title: 'Please select all that apply.',
      multiSelectDescriptions: {
        'Employment Practices Liability':
          'Claims related to employees (or former employees) around employment-related issues, except for workers’ compensation.',
        'Directors & Officers':
          'Claims against the directors or officers of your business alleging mismanagement or breach of fiduciary duty.',
        'General Liability': 'Claims alleging either bodily injury or property damage.',
        'Errors & Omissions': 'Claims alleging errors in my services.',
        Cyber: 'Claims for a data breach and cyber incident.',
        "Workers' Compensation": 'A workers compensation claim for an employee that got injured.',
      },
    },
    review: {},
    card: {},
  },
  '2019-07-01--FILED_CLAIMS_AMOUNTS': {
    meta: {},
    question: {
      title: 'How much do you expect to be paid out by your insurer for these claims?',
    },
    review: {},
    card: {},
  },
  '2019-07-01--FILED_CLAIMS_DESCRIPTION': {
    meta: {},
    question: {
      title:
        'Please provide details, including any actions taken by your company, to mitigate a future loss.',
    },
    review: {},
    card: {},
  },
  '2019-07-01--LIABILITIES_CLAIMS_CONTAINER': {
    meta: {},
    question: {
      title: '',
    },
    review: {
      title: 'Liability Claims Filed',
    },
    card: {
      title: 'What types of liability claims have been filed?',
      description: '',
    },
  },
  '2019-07-01--HAS_PENDING_CLAIMS': {
    meta: {},
    question: {
      title:
        'Are you aware of any fact, circumstance, or situation that might reasonably result in a written demand or civil or criminal proceeding against your business or any proposed Insured?',
    },
    review: {
      title: 'Pending claim against the business?',
    },
    card: {
      title:
        'Are you aware of any fact, circumstance, or situation that might reasonably result in a written demand or civil or criminal proceeding against your business or any proposed Insured?',
      description:
        'This question will not be applicable to Vouch renewals for like coverage or limit.',
    },
  },
  '2019-07-01--PENDING_CLAIMS_DESCRIPTION': {
    meta: {},
    question: {
      title:
        'Please provide details on the facts, circumstances, or situations that might reasonably result in a written demand or civil or criminal proceeding against your business or any additional insured for this policy...',
    },
    review: {
      title: 'Pending Claims Description',
    },
    card: {
      title:
        'Please provide details on the facts, circumstances, or situations that might reasonably result in a written demand or civil or criminal proceeding against your business or any additional insured for this policy...',
      description: 'Include any actions taken by your company to mitigate a future loss',
    },
  },
  '2019-07-01--HAS_ENGINEERING_CONTRACTORS': {
    meta: {},
    question: {
      title: 'Do you have any engineering contractors?',
    },
    review: {
      title: 'Has engineering contractors?',
    },
    card: {
      title: 'Do you have any engineering contractors?',
      description:
        'Please answer yes if you also employ with any engineering contract firms or agencies.',
    },
  },
  '2019-07-01--NUM_ENGINEERING_CONTRACTORS': {
    meta: {},
    question: {
      title: 'How many engineering contractors do you have? ',
    },
    review: {
      title: 'Number of engineering contractors',
    },
    card: {
      title: 'How many engineering contractors do you have? ',
      description: 'Please count each engineering contract firm or agency as a single contractor.',
    },
  },
  '2019-07-01--HAS_401K': {
    meta: {},
    question: {
      title: 'Does your company sponsor a 401(k) plan for its employees?',
    },
    review: {
      title: 'Has 401(k) Plan',
    },
    card: {
      title: 'Does your company sponsor a 401(k) plan for its employees?',
      description: '',
    },
  },
  '2021-16-02--SUBJECT_TO_ERISA': {
    meta: {},
    question: {
      title:
        'Other than 401(k) plans, does your company sponsor any other benefit plans for its employees?',
    },
    review: {
      title: 'Subject to ERISA?',
    },
    card: {
      title:
        'Other than 401(k) plans, does your company sponsor any other benefit plans for its employees?',
      description:
        'This includes pension, deferred compensation, profit sharing, or any plan subject to ERISA (Employee Retirement Income Security Act). Do not include employee health and welfare plans such as medical, dental, vision, life, or disability.',
    },
  },
  '2019-07-01--BENEFITS_WORTH': {
    meta: {},
    question: {
      title: 'What are the total assets of your benefit plans?',
    },
    review: {
      title: 'Benefits Value',
    },
    card: {
      title: 'What are the total assets of your benefit plans?',
      description:
        'Include all plans subject to ERISA - 401ks, pensions, everything, deferred compensation plans, etc.',
    },
  },
  '2019-07-01--NUM_INVOLUNTARY_TERMINATION': {
    meta: {},
    question: {
      title:
        'How many full-time employees have been involuntarily terminated within the last 12 months?',
    },
    review: {
      title: 'Number of employees involuntarily terminated',
    },
    card: {
      title:
        'How many full-time employees have been involuntarily terminated within the last 12 months?',
    },
  },
  '2019-07-01--NUM_PLANNED_INVOLUNTARY_TERMINATION': {
    meta: {},
    question: {
      title:
        'How many full-time employees does your company plan to involuntarily terminate in the next 12 months?',
    },
    review: {
      title: 'Planned number of employees to be involuntarily terminated',
    },
    card: {
      title:
        'How many full-time employees does your company plan to involuntarily terminate in the next 12 months?',
      description:
        'Please only count employees that are already scheduled to be involuntarily terminated or any planned layoffs.',
    },
  },
  '2019-07-01--HAS_ANY_PII': {
    meta: {},
    question: {
      title: 'Does your company collect PII in its products, or plan to in the next 12 months?',
    },
    review: {
      title: 'Collects PII?',
    },
    card: {
      title: 'Does your company collect PII in its products, or plan to in the next 12 months?',
      descriptionComponent: HasAnyPiiDescriptionProgram2,
    },
  },
  '2019-07-01--PII_AMOUNT': {
    meta: {},
    question: {
      title: "How many individuals' PII will be collected after the 12 months?",
    },
    review: {
      title: 'Number of PII Records',
    },
    card: {
      title: "How many individuals' PII will be collected after the 12 months?",
      description:
        'PII, or personally identifiable information, could include social security numbers, credit scores, credit card & payment info, birth dates, health information, etc. This only includes data collected and stored on your systems. If you do not store this data directly (e.g. use Stripe for payments) then do not include that data.',
    },
  },
  '2021-06-01--SOC_COMPLIANCE': {
    meta: {},
    question: {
      title: 'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} SOC 2 compliant? If so, which type?',
    },
    review: {
      title: 'SOC compliance',
    },
    card: {
      title: 'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} SOC 2 compliant? If so, which type?',
      description:
        'SOC 2 is an auditing procedure that ensures your service providers securely manage your data to protect the interests of your organization and the privacy of its clients.',
    },
  },
  '2021-06-01--LOGIN_REQUIRES_MFA': {
    meta: {},
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} require that its employees use multi-factor authentication to log into more than one of its core systems?',
    },
    review: {
      title: 'Require MFA for login',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} require that its employees use multi-factor authentication to log into more than one of its core systems?',
      description:
        'This includes code repositories, databases, and any other systems where sensitive information may be stored or accessed',
    },
  },
  '2019-07-01--REVIEW_INFORMATION': {
    meta: {},
    question: {
      title: 'Has anything else changed with your business?',
    },
    review: {},
    card: {
      title: 'Has anything else changed with your business?',
      description:
        "Review last year's answers below, and please update to reflect any other, less common changes.",
    },
  },
  '2019-07-01--REVIEW_CONTAINER': {
    meta: {},
    question: {
      title: 'Confirm your information below, and we’ll calculate your price.',
    },
    review: {},
    card: {
      title: 'Review your information below, and we’ll calculate your price.',
      description:
        'I verify that the information presented here is accurate to the best of my knowledge',
    },
  },
  '2019-07-01--APPLICANT_FULLNAME': {
    meta: {},
    question: {
      title: 'APPLICANT FULL NAME',
    },
    review: {
      title: 'N/A?',
    },
    card: {
      title: 'APPLICANT FULL NAME',
      description: 'Full Name',
    },
  },
  '2019-07-01--APPLICANT_RELATIONSHIP_TO_COMPANY': {
    meta: {},
    question: {
      title: 'APPLICANT RELATIONSHIP TO COMPANY',
    },
    review: {},
    card: {
      title: '',
      description: 'Relationship to {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}',
    },
  },
  '2019-07-01--HAS_PHYSICAL_ACTIVITY_BUSINESS': {
    meta: {},
    question: {
      title: 'Does your company provide products or apps used for physical activity?',
    },
    review: {
      title: 'Has Physical Activity Business',
    },
    card: {
      title: 'Does your company provide products or apps used for physical activity?',
      description:
        'This includes products or applications used by consumers in conjunction with, or associated with, physical activity such as running, biking, swimming, skiing, hiking, or weightlifting.',
    },
  },
  '2021-06-01--OFFERS_CONSULTING': {
    meta: {},
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} offer any services or consultation for the products developed, distributed or provided by {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?',
    },
    review: {
      title: 'Offer consulting services',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} offer any services or consultation for the products developed, distributed or provided by {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?',
      description: '',
    },
  },
  '2019-07-01--NUM_BIG_REVENUE_CONTRACTS': {
    meta: {},
    question: {
      title:
        'How many large customer contracts does <company name> have, or expect to have, in the next 12 months?',
    },
    review: {
      title: 'Has Big Revenue Contracts',
    },
    card: {
      title:
        'How many large customer contracts does <company name> have, or expect to have, in the next 12 months?',
      description:
        "Use your best estimate. Large contracts are defined as those that'll produce more than $100,000 in revenue.",
    },
  },
  '2019-07-01--SCHEDULE_CALL': {
    question: {},
    review: {},
    meta: {},
    card: {
      title: 'Would you like your Insurance Pro to reach out and set up a call?',
      description:
        'We are always available to discuss your current coverage, or how your needs may have changed for the upcoming year.',
    },
  },
  // blank configs
  location_id: blankUIConfig,
  '2019-07-01--LOCATION_CONTAINER_attributes': blankUIConfig,

  // Program 2 supplemental questions
  '2021-06-01--HAS_DANGEROUS_PRODUCTS': {
    meta: {},
    review: {
      title: 'Make dangerous products',
    },
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} produce, manufacture, sell or allow for the exchange of any of the following?',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} produce, manufacture, sell or allow for the exchange of any of the following?',
      description: '',
    },
  },
  '2021-06-01--HAS_DANGEROUS_PRODUCTS_DESC': {
    meta: {},
    review: {
      title: 'Dangerous Products Description',
    },
    question: {
      title:
        'If yes to any of the above, please explain what exactly you produce, manufacture, sell, or allow the exchange of:',
    },
    card: {
      title:
        'If yes to any of the above, please explain what exactly you produce, manufacture, sell, or allow the exchange of:',
      description: '',
    },
  },
  '2021-06-01--MANUFACTURING_STATUS': {
    meta: {},
    review: {
      title: 'Manufacturing status',
    },
    question: {
      title:
        'What best describes the current state of manufacturing for {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?',
    },
    card: {
      title:
        'What best describes the current state of manufacturing for {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?',
      description: `"We" here means any company seeking insurance in this application. If your company contracts all its manufacturing to another organization, and they're not getting insurance through you, you don't need to call yourself a manufacturer. Prototypes and test products do not count as manufactured products for this question`,
    },
  },
  '2021-06-01--MANUFACTURING_STATUS_DESC': {
    meta: {},
    review: {
      title: 'Manufacturing status description',
    },
    question: {
      title: 'Please describe the state of your manufacturing:',
    },
    card: {
      title: 'Please describe the state of your manufacturing:',
      description: 'Scale of manufacturing, location, amount of company oversight, etc.',
    },
  },
  '2021-06-01--CAUSED_HARM': {
    meta: {},
    review: {
      title: 'Have your products caused harm?',
    },
    question: {
      title:
        'Are you aware of any physical harm, injury or illness being caused by a product sold or manufactured by {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?',
    },
    card: {
      title:
        'Are you aware of any physical harm, injury or illness being caused by a product sold or manufactured by {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?',
      description: '',
    },
  },
  '2021-06-01--CAUSED_HARM_DESC': {
    meta: {},
    review: {
      title: 'Caused harm description',
    },
    question: {
      title: 'Please explain:',
    },
    card: {
      title: 'Please explain:',
      description: '',
    },
  },
  '2021-06-01--HAS_PHYSICAL_RETAIL': {
    meta: {},
    review: {
      title: 'Do you operate physical retail?',
    },
    question: {
      title:
        'Do you operate any physical retail location(s) or storefront(s) or plan to in the next 12 months?',
    },
    card: {
      title:
        'Do you operate any physical retail location(s) or storefront(s) or plan to in the next 12 months?',
      description: '',
    },
  },
  '2021-06-01--MEDIA_HAS_STATE_REG_DEMANDS': {
    meta: {},
    review: {
      title: 'Do you have written demands from a regulator?',
    },
    question: {
      title:
        'Has {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} had any written demands or civil/criminal proceedings brought by a regulatory body?',
    },
    card: {
      title:
        'Has {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} had any written demands or civil/criminal proceedings brought by a regulatory body?',
      description:
        'Examples include:<ul><li>FTC (Federal Trade Commission)</li><li>CTIA (Cellular Telecommunications Industry Association)</li><li>FCC (Federal Communications Commission)</li><li>MMA (Mobile Marketing Association)</li><li>similar US state regulatory body</li></ul>',
    },
  },
  '2021-06-01--MEDIA_HAS_STATE_REG_DEMANDS_DESC': {
    meta: {},
    review: {
      title: 'Regulatory demands description',
    },
    question: {
      title: 'Please explain the actions brought against {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}:',
    },
    card: {
      title: 'Please explain the actions brought against {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}:',
      description: '',
    },
  },
  '2021-06-01--MEDIA_HAS_ACT_RELATED_DEMANDS': {
    meta: {},
    review: {
      title: 'Do you have any demands related to media acts?',
    },
    question: {
      title:
        'Has {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} had any written demands or civil/criminal proceedings alleging or related to violations of a federal, state, or local act?',
    },
    card: {
      title:
        'Has {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} had any written demands or civil/criminal proceedings alleging or related to violations of a federal, state, or local act?',
      description:
        'Examples include:<ul><li>TCPA (Telephone Consumer Protection Act)</li><li>“CAN-SPAM” (Controlling The Assault of Non-solicited Pornography and Marketing Act) </li><li>Federal Communications Act</li><li>FTCA (Federal Trade Commission Act)</li></ul>',
    },
  },
  '2021-06-01--MEDIA_HAS_ACT_RELATED_DEMANDS_DESC': {
    meta: {},
    review: {
      title: 'media act description',
    },
    question: {
      title: 'Please explain the actions brought against {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}:',
    },
    card: {
      title: 'Please explain the actions brought against {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}:',
      description: '',
    },
  },
  '2021-06-01--MEDIA_HAS_COPYRIGHT_LIBEL_PRIVACY_DEMANDS': {
    meta: {},
    review: {
      title: 'libel privacy demands',
    },
    question: {
      title:
        'Have any written demands or civil/criminal proceedings been made against {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} related to possible copyright infringement, libel, slander, disparagement, or invasion of privacy?',
    },
    card: {
      title:
        'Have any written demands or civil/criminal proceedings been made against {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} related to possible copyright infringement, libel, slander, disparagement, or invasion of privacy?',
      description: '',
    },
  },
  '2021-06-01--MEDIA_HAS_COPYRIGHT_LIBEL_PRIVACY_DEMANDS_DESC': {
    meta: {},
    review: {
      title: 'libel privacy demands description',
    },
    question: {
      title: 'Please explain the actions brought against {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}:',
    },
    card: {
      title: 'Please explain the actions brought against {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}:',
      description: '',
    },
  },
  '2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS': {
    meta: {},
    review: {
      title: 'Do you design or administer a certification business?',
    },
    question: {
      title:
        'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} responsible for designing or administering any accreditation or certification programs, member peer review, standard-setting, or compliance certifications?',
    },
    card: {
      title:
        'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} responsible for designing or administering any accreditation or certification programs, member peer review, standard-setting, or compliance certifications?',
      description: '',
    },
  },
  '2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS_DESC': {
    meta: {},
    review: {
      title: 'Media Certification Description',
    },
    question: {
      title: 'If yes, please explain:',
    },
    card: {
      title: 'If yes, please explain:',
      description: '',
    },
  },
  '2021-06-01--MEDIA_CONTROV_MATERIAL': {
    meta: {},
    review: {
      title: 'controversial materials?',
    },
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} produce, distribute, or deliver material derived from the use of the following materials/methods: ',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} produce, distribute, or deliver material derived from the use of the following materials/methods: ',
      description:
        '<ul><li>Investigative journalism</li><li>Satire or parody</li><li>Tabloid journalism</li><li>Photography of high-profile individuals without their knowledge or consent (paparazzi photography)</li></ul>',
    },
  },
  '2021-06-01--MEDIA_CONTROV_MATERIAL_DESC': {
    meta: {},
    review: {
      title: 'controversial materials description',
    },
    question: {
      title: 'If yes, please explain:',
    },
    card: {
      title: 'If yes, please explain:',
      description: '',
    },
  },
  '2021-06-01--MEDIA_NUM_CONTENT_CONSUMERS': {
    meta: {},
    review: {
      title: 'number of consumers',
    },
    question: {
      title:
        'How many individuals do you estimate will consume content of {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} over the next 12 months?',
    },
    card: {
      title:
        'How many individuals do you estimate will consume content of {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} over the next 12 months?',
      description: '',
    },
  },
  '2021-06-01--MEDIA_MAKES_OR_SELLS_PG': {
    meta: {},
    review: {
      title: 'Do you do pornography or gambling?',
    },
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} operate, market, distribute or sell any of the following:',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} operate, market, distribute or sell any of the following:',
      description:
        '<ul><li>Pornography or other adult content</li><li>Online gambling or gambling related content</li></ul>',
    },
  },
  '2021-06-01--MEDIA_MAKES_OR_SELLS_PG_DESC': {
    meta: {},
    review: {
      title: 'Pornography/Gambling description',
    },
    question: {
      title: 'If yes, please explain:',
    },
    card: {
      title: 'If yes, please explain:',
      description: '',
    },
  },
  '2021-06-01--MEDIA_LOANS_OR_TRANSFERS': {
    meta: {},
    review: {
      title: 'Media Loans or transfers?',
    },
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} do any of the following as a part of its products or services?',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} do any of the following as a part of its products or services?',
      description: '',
    },
  },
  '2021-06-01--MEDIA_LOANS_OR_TRANSFERS_DESC': {
    meta: {},
    review: {
      title: 'Media Loans or transfers description',
    },
    question: {
      title: 'Please explain:',
    },
    card: {
      title: 'Please explain:',
      description: '',
    },
  },
  '2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES': {
    meta: {},
    review: {
      title: 'Contractors dispute?',
    },
    question: {
      title:
        'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} involved in any dispute or written demand involving the status of its 1099 contractors and whether or not they can be classified as such?',
    },
    card: {
      title:
        'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} involved in any dispute or written demand involving the status of its 1099 contractors and whether or not they can be classified as such?',
      description: '',
    },
  },
  '2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES_DESC': {
    meta: {},
    review: {
      title: 'Contractor disputes description',
    },
    question: {
      title: 'If yes, please explain:',
    },
    card: {
      title: 'If yes, please explain:',
      description: '',
    },
  },
  '2021-06-01--TRANSP_LICENSING_DISPUTES': {
    meta: {},
    review: {
      title: 'Licensing dispute?',
    },
    question: {
      title:
        'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} involved in any disputes involving licensing or other regulatory requirements under local or state transportation laws, regulations, statutes or rules?',
    },
    card: {
      title:
        'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} involved in any disputes involving licensing or other regulatory requirements under local or state transportation laws, regulations, statutes or rules?',
      description: '',
    },
  },
  '2021-06-01--TRANSP_LICENSING_DISPUTES_DESC': {
    meta: {},
    review: {
      title: 'Licensing dispute description?',
    },
    question: {
      title: 'If yes, please explain:',
    },
    card: {
      title: 'If yes, please explain:',
      description: '',
    },
  },
  '2021-06-01--TRANSP_OWNS_TRANSPORT': {
    meta: {},
    review: {
      title: 'Own your own transportation?',
    },
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} directly own automobiles or other devices for powered transportation that are used to support core business activities?',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} directly own automobiles or other devices for powered transportation that are used to support core business activities?',
      description: '',
    },
  },
  '2021-06-01--TRANSP_OWNS_TRANSPORT_DESC': {
    meta: {},
    review: {
      title: 'Own transportation description',
    },
    question: {
      title: 'If yes, please explain:',
    },
    card: {
      title: 'If yes, please explain:',
      description: '',
    },
  },
  '2021-06-01--PS_PROF_SERVICES': {
    meta: {},
    review: {
      title: 'Provide Professional Services?',
    },
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide any of the following professional services:',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide any of the following professional services:',
      description: '',
    },
  },
  '2021-06-01--PS_PROF_SERVICES_DESC': {
    meta: {},
    review: {
      title: 'Professional Services Description',
    },
    question: {
      title: 'If yes to any of the above, please explain:',
    },
    card: {
      title: 'If yes to any of the above, please explain:',
      description: '',
    },
  },
  '2021-06-01--PS_DEMANDS_MADE': {
    meta: {},
    review: {
      title: 'Professional Services written demands',
    },
    question: {
      title:
        'Are you aware of any written demand or civil or criminal proceeding against {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} related to any of the professional services above?',
    },
    card: {
      title:
        'Are you aware of any written demand or civil or criminal proceeding against {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} related to any of the professional services above?',
      description: '',
    },
  },
  '2021-06-01--PS_DEMANDS_MADE_DESC': {
    meta: {},
    review: {
      title: 'Professional Services written demands description',
    },
    question: {
      title: 'If yes, please explain:',
    },
    card: {
      title: 'If yes, please explain:',
      description: '',
    },
  },
  '2021-06-01--FT_OPERATES_FINTECH': {
    meta: {},
    review: {
      title: 'Financial Operator',
    },
    question: {
      title: 'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} operate or act as any of the following:',
    },
    card: {
      title: 'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} operate or act as any of the following:',
      description: '',
    },
  },
  '2021-06-01--FT_OPERATES_FINTECH_DESC': {
    meta: {},
    review: {
      title: 'Financial Operator Description',
    },
    question: {
      title: 'If yes to any of the above, please explain:',
    },
    card: {
      title: 'If yes to any of the above, please explain:',
      description: '',
    },
  },
  '2021-06-01--FT_HAS_DEMANDS': {
    meta: {},
    review: {
      title: 'Financial Written Demands',
    },
    question: {
      title:
        'Are you aware of any written demand or civil or criminal proceeding against {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} related to any of the above financial services?',
    },
    card: {
      title:
        'Are you aware of any written demand or civil or criminal proceeding against {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} related to any of the above financial services?',
      description: '',
    },
  },
  '2021-06-01--FT_HAS_DEMANDS_DESC': {
    meta: {},
    review: {
      title: 'Financial Written Demands Description',
    },
    question: {
      title: 'If yes, please explain:',
    },
    card: {
      title: 'If yes, please explain:',
      description: '',
    },
  },
  '2021-06-01--FT_GTM_STRATEGY': {
    review: {
      title: 'Financial GTM Strategy',
    },
    meta: {},
    question: {
      title: 'What best describes the business strategy of {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?',
    },
    card: {
      title: 'What best describes the business strategy of {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?',
      description: '',
    },
  },
  '2021-06-01--FT_ANNUAL_LOANS': {
    meta: {},
    review: {
      title: 'Financial Annual Loans',
    },
    question: {
      title: 'Please explain:',
    },
    card: {
      title: 'Please explain:',
      description: '',
    },
  },
  '2021-06-01--FT_INTERNATIONAL_SALES': {
    meta: {},
    review: {
      title: 'Financial International Sales',
    },
    question: {
      title:
        'What percentage of {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} total revenue is expected to be derived from customers outside the United States over the next 12 months?',
    },
    card: {
      title:
        'What percentage of {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} total revenue is expected to be derived from customers outside the United States over the next 12 months?',
      description: '',
    },
  },
  '2021-06-01--FT_HAS_BLOCKCHAIN_BUSINESS': {
    meta: {},
    review: {
      title: 'Financial Blockchain Business',
    },
    question: {
      title:
        'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} buying, selling, investing, trading, microtipping, or engaging in other monetary activities with a blockchain native token or subtoken?',
    },
    card: {
      title:
        'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} buying, selling, investing, trading, microtipping, or engaging in other monetary activities with a blockchain native token or subtoken?',
      description: 'Please select "yes" if you plan to in the next 12 months.',
    },
  },
  '2021-06-01--FT_LARGEST_LOAN_SIZE': {
    meta: {},
    review: {
      title: 'Largest Loan Size',
    },
    question: {
      title: 'What is the largest loan size you provide to a consumer or business?',
    },
    card: {
      title: 'What is the largest loan size you provide to a consumer or business?',
      description: '',
    },
  },
  '2021-06-01--FT_TOTAL_ANNUAL_LOANS': {
    meta: {},
    review: {
      title: 'Total Loan Size',
    },
    question: {
      title: 'What are the total annual loans generated by {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?',
    },
    card: {
      title: 'What are the total annual loans generated by {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?',
      description: '',
    },
  },
  '2021-06-01--FT_HANDLES_INSURANCE_CLAIMS': {
    meta: {},
    review: {
      title: 'Handles Insurance Claims?',
    },
    question: {
      title: 'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} handle or administer insurance claims?',
    },
    card: {
      title: 'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} handle or administer insurance claims?',
      description: '',
    },
  },
  '2021-06-01--FT_IS_DIRECTLY_REGULATED': {
    meta: {},
    review: {
      title: 'Directly Regulated?',
    },
    question: {
      title:
        'Is your company currently directly regulated by a state insurance regulator, a state financial regulator/agency, or any of the following:',
    },
    card: {
      title:
        'Is your company currently directly regulated by a state insurance regulator, a state financial regulator/agency, or any of the following:',
      description:
        '<ul><li>FINRA</li><li>SEC</li><li>CFTC</li><li>FDIC</li><li>OCC</li><li>NCUA</li><li>CFPB</li><li>NAIC</li></ul>',
    },
  },
  '2021-06-01--DID_TRIAL': {
    meta: {},
    review: {
      title: 'Initiated Trial?',
    },
    question: {
      title:
        'Within the last 3 years, has {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} initiated any of the following in the United States (or similar type trial in a country outside the US):',
    },
    card: {
      title:
        'Within the last 3 years, has {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} initiated any of the following in the United States (or similar type trial in a country outside the US):',
      description: '',
    },
  },
  '2021-06-01--DID_TRIAL_DESC': {
    meta: {},
    review: {
      title: 'Initiated Trial description',
    },
    question: {
      title: 'Please provide details:',
    },
    card: {
      title: 'Please provide details:',
      description: '',
    },
  },
  '2021-06-01--WILL_TRIAL': {
    meta: {},
    review: {
      title: 'Will Trial?',
    },
    question: {
      title:
        'Over the next 12 months, does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} anticipate initiating any of the following in the United States (or similar type trial in a country outside the US):',
    },
    card: {
      title:
        'Over the next 12 months, does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} anticipate initiating any of the following in the United States (or similar type trial in a country outside the US):',
      description: '',
    },
  },
  '2021-06-01--WILL_TRIAL_DESC': {
    meta: {},
    review: {
      title: 'Will Trial description',
    },
    question: {
      title: 'Please provide details:',
    },
    card: {
      title: 'Please provide details:',
      description: '',
    },
  },
  '2021-06-01--PHARMA_WHEN_IN_MARKET': {
    meta: {},
    review: {
      title: 'Pharma - When in market',
    },
    question: {
      title:
        'When can {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} expect to be in market with any approved pharmaceutical, nutraceutical, or other medical product that you manufacture, distribute, or license?',
    },
    card: {
      title:
        'When can {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} expect to be in market with any approved pharmaceutical, nutraceutical, or other medical product that you manufacture, distribute, or license?',
      description: '',
    },
  },
  '2021-06-01--PHARMA_IS_IN_RADIO': {
    review: {
      title: 'Pharma - radiology?',
    },
    meta: {},
    question: {
      title:
        'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} involved in the research, manufacturing, or distribution of radiopharmaceuticals or medicinal radiocompounds?',
    },
    card: {
      title:
        'Is {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} involved in the research, manufacturing, or distribution of radiopharmaceuticals or medicinal radiocompounds?',
      description: '',
    },
  },
  '2021-06-01--MEDDEV_SPECIAL_DEVICE': {
    review: {
      title: 'Pharma - develop special device?',
    },
    meta: {},
    question: {
      title:
        'For any medical device {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} is currently providing or plans to develop, do any of the following apply?',
    },
    card: {
      title:
        'For any medical device {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} is currently providing or plans to develop, do any of the following apply?',
      description: '',
    },
  },
  '2021-06-01--MEDDEV_SPECIAL_DEVICE_DESC': {
    meta: {},
    review: {
      title: 'Pharma - develop special device description',
    },
    question: {
      title: 'Please provide details:',
    },
    card: {
      title: 'Please provide details:',
      description: '',
    },
  },
  '2021-06-01--MEDDEV_IN_MARKET': {
    meta: {},
    review: {
      title: 'Medical Device - when in market?',
    },
    question: {
      title:
        'When does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} expect to be in market with any approved medical device (or tests/labs) either manufacturing, distributing or licensing a medical product (or generally making it publicly available).',
    },
    card: {
      title:
        'When does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} expect to be in market with any approved medical device (or tests/labs) either manufacturing, distributing or licensing a medical product (or generally making it publicly available).',
      description: '',
    },
  },
  '2021-06-01--MEDDEV_IN_MARKET_DESC': {
    meta: {},
    review: {
      title: 'Medical Device - when in market description',
    },
    question: {
      title:
        'For any medical device {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} is currently providing or plans to develop, do any of the following apply?',
    },
    card: {
      title:
        'For any medical device {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} is currently providing or plans to develop, do any of the following apply?',
      description: '',
    },
  },
  '2021-06-01--HW_HAS_DANGEROUS_PRODUCTS': {
    meta: {},
    review: {
      title: 'Hardware - Do you sell dangerous products?',
    },
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} produce, manufacture or sell hardware used in any of the following products?',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} produce, manufacture or sell hardware used in any of the following products?',
      description: '',
    },
  },
  '2021-06-01--EPRISE_ADVICE_REPORTING': {
    review: {
      title: 'Do you provide enterprise advice?',
    },
    meta: {},
    question: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide any of the following, along with its software:',
    },
    card: {
      title:
        'Does {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} provide any of the following, along with its software:',
      description: '',
    },
  },
  '2021-06-01--EPRISE_ADVICE_REPORTING_DESC': {
    meta: {},
    review: {
      title: 'Enterprise advice reporting description',
    },
    question: {
      title: 'If yes to any of the above, please explain:',
    },
    card: {
      title: 'If yes to any of the above, please explain:',
      description: '',
    },
  },
  '2021-06-01--EPRISE_DEMANDS_MADE': {
    meta: {},
    review: {
      title: 'Enterprise written demands',
    },
    question: {
      title:
        'Are you aware of any written demand or civil or criminal proceeding against {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} related to any of the above services?',
    },
    card: {
      title:
        'Are you aware of any written demand or civil or criminal proceeding against {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} related to any of the above services?',
      description: '',
    },
  },
  '2021-06-01--EPRISE_DEMANDS_MADE_DESC': {
    meta: {},
    review: {
      title: 'Enterprise written demands description',
    },
    question: {
      title: 'If yes, please explain:',
    },
    card: {
      title: 'If yes, please explain:',
      description: '',
    },
  },
  '2021-06-01--EPRISE_SPECIAL_CATEGORY': {
    meta: {},
    review: {
      title: 'Enterprise Specific Category',
    },
    question: {
      title: 'Do any of the following apply to {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?',
    },
    card: {
      title: 'Do any of the following apply to {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?',
      description: '',
    },
  },
  '2021-06-01--EPRISE_EXPECTED_INTL_REVENUE': {
    meta: {},
    review: {
      title: 'Enterprise International Revenue',
    },
    question: {
      title:
        'What percentage of {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} total revenue is expected to be derived from customers outside the United States over the next 12 months?',
    },
    card: {
      title:
        'What percentage of {{ 2019-07-01--FRIENDLY_ENTITY_NAME }} total revenue is expected to be derived from customers outside the United States over the next 12 months?',
      description: '',
    },
  },
  '2022-12-15--FT_INVESTMENT_SERVICES_ACTIVITIES': {
    meta: {},
    review: {
      title: 'Financial Investment Services',
    },
    question: {
      title:
        'With regard to the advice provided on securities, which of the following applies to your business?',
    },
    card: {
      title:
        'With regard to the advice provided on securities, which of the following applies to your business?',
      description: '',
    },
  },
  '2021-06-01--EPRISE_LOAN_SERVICES': {
    meta: {},
    review: {
      title: 'Enterprise Loan Services',
    },
    question: {
      title:
        'As part of its products or services, do any of the following apply to {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?',
    },
    card: {
      title:
        'As part of its products or services, do any of the following apply to {{ 2019-07-01--FRIENDLY_ENTITY_NAME }}?',
      description: '',
    },
  },
  '2021-06-01--EPRISE_LOAN_SERVICES_DESC': {
    meta: {},
    review: {
      title: 'Enterprise Loan Services Description',
    },
    question: {
      title: 'If yes to any of the above, please explain:',
    },
    card: {
      title: 'If yes to any of the above, please explain:',
      description: '',
    },
  },
};

export const getUIConfig = (id, isProgram2 = false) =>
  isProgram2 ? questionUIConfigProgram2[id] : questionUIConfig[id];
