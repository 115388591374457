/* istanbul ignore file */
// this file is strictly configuration, and aligns with the 'humble object' pattern, so testing isn't necessary.

import { prequalRoutingDecisions } from './prequalRoutingDecisions';
import { coreRoutingDecisions } from './coreRoutingDecisions';
import { endOfFunnelRoutingDecisions } from '../routing_decisions/endOfFunnelRoutingDecisions';
import { supplementalRoutingDecisions } from './supplementalRoutingDecisions';

export const routingDecisionsProgram2 = [
  ...prequalRoutingDecisions,
  ...coreRoutingDecisions,
  ...supplementalRoutingDecisions,
  ...endOfFunnelRoutingDecisions,
];
