import Rollbar from 'rollbar';
import { VueConstructor } from 'vue';
import LogRocket from 'logrocket';

export default {
  install(Vue: VueConstructor, options: Rollbar.Configuration) {
    try {
      const rollbarInstance = new Rollbar(options);
      LogRocket.getSessionURL(sessionURL => {
        rollbarInstance.configure({
          transform: (payload: any) => {
            payload.sessionURL = sessionURL;
          },
        });
      });
      Vue.prototype.$rollbar = rollbarInstance;
    } catch (e) {
      // adding this here because the rest of the app expects
      // rollbar to be available in order to log errors. This will
      // at least allow us to log something that logrocket can capture
      // as a fallback
      Vue.prototype.$rollbar = {
        // eslint-disable-next-line no-console
        critical: (...args: any[]) => console.error('Rollbar critical: ', args),
        // eslint-disable-next-line no-console
        error: (...args: any[]) => console.error('Rollbar error: ', args),
        // eslint-disable-next-line no-console
        info: (...args: any[]) => console.error('Rollbar info: ', args),
        // eslint-disable-next-line no-console
        debug: (...args: any[]) => console.error('Rollbar debug: ', args),
        // eslint-disable-next-line no-console
        warning: (...args: any[]) => console.error('Rollbar warn: ', args),
      };
      // eslint-disable-next-line no-console
      console.error('Could not initialize rollbar: ', e);
    }
  },
};
