<template>
  <v-snackbar
    v-model="isVisible"
    content-class="vouch-notification"
    :color="notificationColor"
    top
    min-width="200"
    text
  >
    <VouchIcon
      v-if="iconName"
      class="vouch-icon"
      :name="iconName"
    />
    {{ message }}
  </v-snackbar>
</template>

<script>
import VouchIcon from '@/shared/components/ui/atoms/icons/VouchIcon';
import { mapGetters, mapMutations } from 'vuex';
import Colors from '@/Colors';

export default {
  name: 'VouchNotification',
  components: {
    VouchIcon,
  },
  computed: {
    ...mapGetters(['getNotificationContent', 'getVisibility']),
    notificationColor() {
      return this.isError ? Colors.notificationErrorColor : Colors.notificationColor;
    },
    iconName() {
      return this.getNotificationContent.iconName;
    },
    isError() {
      return !!this.getNotificationContent.isError;
    },
    isVisible: {
      get() {
        return this.getVisibility;
      },
      set(isVisible) {
        this.setNotificationVisibility(isVisible);
      },
    },
    message() {
      return this.getNotificationContent.message;
    },
  },
  methods: {
    ...mapMutations(['setNotificationVisibility']),
  },
};
</script>

<style lang="scss">
.vouch-notification {
  box-shadow: 0px 4px 4px rgba(26, 87, 69, 0.2);
  border-radius: 4px;

  display: flex;
  justify-content: center;

  @include notification-text;

  .vouch-icon {
    margin-right: $space-small;
  }
}

.v-snack__action {
  display: none !important;
}
</style>
