<script>
import BaseCardWithFields from '../../../../components/wizard/BaseCardWithFields';
import SchemaFormFieldNumber from '../../../../../onboarding/components/formfields/SchemaFormFieldNumber';
import {
  getSchemaEndpoint,
  getUpdateEndpoint,
} from '../../../../../onboarding/lib/selectors/apiPathSelectors';

export default {
  name: 'NumEngContractorsCard',
  components: { SchemaFormFieldNumber },
  extends: BaseCardWithFields,
  data() {
    return {
      schemaPath: getSchemaEndpoint('core'),
      submitPath: getUpdateEndpoint('core'),
      cardConfigPath: '2019-07-01--NUM_ENGINEERING_CONTRACTORS',
      formFieldData: [
        {
          component: SchemaFormFieldNumber,
          schemaPath: '2019-07-01--NUM_ENGINEERING_CONTRACTORS',
          dataPath: '2019-07-01--NUM_ENGINEERING_CONTRACTORS',
          default: 0,
        },
      ],
    };
  },
};
</script>
