import { get, isNil } from '../../shared/lib/vouch_dash';
import has from 'lodash/has';
import {
  nicheIndustryDependencies,
  noneOfTheseApply,
  P2NicheCategories,
  P2NicheSwIndustries,
  P2NicheHwIndustries,
  P2NicheGoodsIndustries,
  P2NicheServicesIndustries,
  primaryNicheIsAddOnServiceClass,
  primaryNicheIsB2BServiceClass,
  primaryNicheHasMediumHighDataIntensity,
  primaryNicheCouldIncludeDangerousProduct,
  primaryNicheCouldInvolveManufacturing,
  primaryNicheIsMediaRelated,
  primaryNicheIsTransportRelated,
  primaryNicheIsProfServicesRelated,
  primaryNicheIsFintechRelated,
  primaryNicheIsPharmaRelated,
  primaryNicheIsMedDeviceRelated,
  primaryNicheIsMedDeviceorPharmaRelated,
  primaryNicheIsHwRelated,
  primaryNicheIsEnterpriseRelated,
} from '@/onboarding/constants/P2NicheConstants';
import { dollarValues } from '@/onboarding/constants/Constants';
import { APPLICATION_DATA_WHITELIST, LEGACY_FIELD_MAP } from '@/onboarding2/niche.domain';

const dependencyMapBoolean = [
  {
    dataPath: ['2019-07-01--HAS_INSURANCE_REQS'],
    dependentFields: ['2021-06-01--HAS_INSURANCE_REQS_DESC'],
  },
  {
    dataPath: ['2021-06-01--HAS_BIG_TICKET_ITEM'],
    dependentFields: ['2021-06-01--BIG_TICKET_ITEM_DESC'],
  },
  {
    dataPath: ['2019-07-01--HAS_ENGINEERING_CONTRACTORS'],
    dependentFields: ['2019-07-01--NUM_ENGINEERING_CONTRACTORS'],
  },
  {
    dataPath: ['2019-07-01--HAS_ANY_PII'],
    dependentFields: ['2019-07-01--PII_AMOUNT'],
  },
  {
    dataPath: ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_CURRENT_CLAIMS'],
    dependentFields: ['2019-07-01--CURRENT_CLAIMS_DESCRIPTION'],
  },
  {
    dataPath: ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_DENIED_COVERAGE'],
    dependentFields: ['2019-07-01--DENIED_COVERAGE_DESCRIPTION'],
  },
  {
    dataPath: ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_FILED_LIABILITY_CLAIM'],
    dependentFields: ['2021_06_01--CURRENT_CLAIMS_NUM'],
  },
  {
    dataPath: ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_FILED_LIABILITY_CLAIM'],
    dependentFields: ['2019-07-01--LIABILITIES_CLAIMS_CONTAINER', '2019-07-01--FILED_CLAIMS_TYPES'],
  },
  {
    dataPath: ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_FILED_LIABILITY_CLAIM'],
    dependentFields: ['2019-07-01--FILED_CLAIMS_DESCRIPTION'],
  },
  {
    dataPath: ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_FILED_LIABILITY_CLAIM'],
    dependentFields: ['2019-07-01--FILED_CLAIMS_AMOUNTS'],
  },
  {
    dataPath: ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_FILED_LIABILITY_CLAIM'],
    dependentFields: ['2019-07-01--LIABILITIES_CLAIMS_CONTAINER'],
  },
  {
    dataPath: ['2019-07-01--HAS_PENDING_CLAIMS'],
    dependentFields: ['2019-07-01--PENDING_CLAIMS_DESCRIPTION'],
  },
  // Supplemental
  {
    dataPath: ['2021-06-01--CAUSED_HARM'],
    dependentFields: ['2021-06-01--CAUSED_HARM_DESC'],
  },
  {
    dataPath: ['2021-06-01--MEDIA_HAS_STATE_REG_DEMANDS'],
    dependentFields: ['2021-06-01--MEDIA_HAS_STATE_REG_DEMANDS_DESC'],
  },
  {
    dataPath: ['2021-06-01--MEDIA_HAS_ACT_RELATED_DEMANDS'],
    dependentFields: ['2021-06-01--MEDIA_HAS_ACT_RELATED_DEMANDS_DESC'],
  },
  {
    dataPath: ['2021-06-01--MEDIA_HAS_COPYRIGHT_LIBEL_PRIVACY_DEMANDS'],
    dependentFields: ['2021-06-01--MEDIA_HAS_COPYRIGHT_LIBEL_PRIVACY_DEMANDS_DESC'],
  },
  {
    dataPath: ['2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS'],
    dependentFields: ['2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS_DESC'],
  },
  {
    dataPath: ['2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS'],
    dependentFields: ['2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS_DESC'],
  },
  {
    dataPath: ['2021-06-01--MEDIA_CONTROV_MATERIAL'],
    dependentFields: ['2021-06-01--MEDIA_CONTROV_MATERIAL_DESC'],
  },
  {
    dataPath: ['2021-06-01--MEDIA_MAKES_OR_SELLS_PG'],
    dependentFields: ['2021-06-01--MEDIA_MAKES_OR_SELLS_PG_DESC'],
  },
  {
    dataPath: ['2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES'],
    dependentFields: ['2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES_DESC'],
  },
  {
    dataPath: ['2021-06-01--TRANSP_LICENSING_DISPUTES'],
    dependentFields: ['2021-06-01--TRANSP_LICENSING_DISPUTES_DESC'],
  },
  {
    dataPath: ['2021-06-01--TRANSP_OWNS_TRANSPORT'],
    dependentFields: ['2021-06-01--TRANSP_OWNS_TRANSPORT_DESC'],
  },
  {
    dataPath: ['2021-06-01--PS_DEMANDS_MADE'],
    dependentFields: ['2021-06-01--PS_DEMANDS_MADE_DESC'],
  },
  {
    dataPath: ['2021-06-01--FT_HAS_DEMANDS'],
    dependentFields: ['2021-06-01--FT_HAS_DEMANDS_DESC'],
  },
  {
    dataPath: ['2021-06-01--DID_TRIAL'],
    dependentFields: ['2021-06-01--DID_TRIAL_DESC'],
  },
  {
    dataPath: ['2021-06-01--WILL_TRIAL'],
    dependentFields: ['2021-06-01--WILL_TRIAL_DESC'],
  },
  {
    dataPath: ['2021-06-01--EPRISE_DEMANDS_MADE'],
    dependentFields: ['2021-06-01--EPRISE_DEMANDS_MADE_DESC'],
  },
];

const dependencyMapPrimaryNicheConditions = [
  {
    // Niche is labelled B2B Services Class
    condition: applicationData => {
      return primaryNicheIsB2BServiceClass({ applicationData });
    },
    dependentFields: ['2019-07-01--NUM_BIG_REVENUE_CONTRACTS'],
  },
  {
    // Niche is labelled Add-on Services Class
    condition: applicationData => {
      return primaryNicheIsAddOnServiceClass({ applicationData });
    },
    dependentFields: ['2021-06-01--OFFERS_CONSULTING'],
  },
  {
    // Niche is labelled Add-on Services Class
    condition: applicationData => {
      return primaryNicheHasMediumHighDataIntensity({ applicationData });
    },
    dependentFields: ['2019-07-01--HAS_ANY_PII'],
  },
  {
    // Niche might involve dangerous products
    condition: applicationData => {
      return primaryNicheCouldIncludeDangerousProduct({ applicationData });
    },
    dependentFields: ['2021-06-01--HAS_DANGEROUS_PRODUCTS'],
  },
  {
    // Niche involves manufacturing
    condition: applicationData => {
      return primaryNicheCouldInvolveManufacturing({ applicationData });
    },
    dependentFields: [
      '2021-06-01--MANUFACTURING_STATUS',
      '2021-06-01--CAUSED_HARM',
      '2021-06-01--HAS_PHYSICAL_RETAIL',
    ],
  },
  {
    // Niche is in media
    condition: applicationData => {
      return primaryNicheIsMediaRelated({ applicationData });
    },
    dependentFields: [
      '2021-06-01--MEDIA_HAS_STATE_REG_DEMANDS',
      '2021-06-01--MEDIA_HAS_ACT_RELATED_DEMANDS',
      '2021-06-01--MEDIA_HAS_COPYRIGHT_LIBEL_PRIVACY_DEMANDS',
      '2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS',
      '2021-06-01--MEDIA_CONTROV_MATERIAL',
      '2021-06-01--MEDIA_NUM_CONTENT_CONSUMERS',
      '2021-06-01--MEDIA_MAKES_OR_SELLS_PG',
      '2021-06-01--MEDIA_LOANS_OR_TRANSFERS',
    ],
  },
  {
    // Niche is in transport
    condition: applicationData => {
      return primaryNicheIsTransportRelated({ applicationData });
    },
    dependentFields: [
      '2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES',
      '2021-06-01--TRANSP_LICENSING_DISPUTES',
      '2021-06-01--TRANSP_OWNS_TRANSPORT',
    ],
  },
  {
    // Niche is in professional services
    condition: applicationData => {
      return primaryNicheIsProfServicesRelated({ applicationData });
    },
    dependentFields: ['2021-06-01--PS_PROF_SERVICES', '2021-06-01--PS_DEMANDS_MADE'],
  },
  {
    // Niche is in FinTech
    condition: applicationData => {
      return primaryNicheIsFintechRelated({ applicationData });
    },
    dependentFields: [
      '2021-06-01--FT_OPERATES_FINTECH',
      '2021-06-01--FT_HAS_DEMANDS',
      '2021-06-01--FT_GTM_STRATEGY',
      '2021-06-01--FT_INTERNATIONAL_SALES',
      '2021-06-01--FT_HAS_BLOCKCHAIN_BUSINESS',
      '2021-06-01--FT_LARGEST_LOAN_SIZE',
      '2021-06-01--FT_TOTAL_ANNUAL_LOANS',
      '2021-06-01--FT_HANDLES_INSURANCE_CLAIMS',
      '2021-06-01--FT_IS_DIRECTLY_REGULATED',
    ],
  },
  {
    // Niche is in Pharma
    condition: applicationData => {
      return primaryNicheIsPharmaRelated({ applicationData });
    },
    dependentFields: ['2021-06-01--PHARMA_WHEN_IN_MARKET', '2021-06-01--PHARMA_IS_IN_RADIO'],
  },
  {
    // Niche is in MedDev
    condition: applicationData => {
      return primaryNicheIsMedDeviceRelated({ applicationData });
    },
    dependentFields: [
      '2021-06-01--MEDDEV_SPECIAL_DEVICE',
      '2021-06-01--MEDDEV_IN_MARKET',
      '2021-06-01--MEDDEV_IN_MARKET_DESC',
    ],
  },
  {
    // Niche is in MedDev or Pharma
    condition: applicationData => {
      return primaryNicheIsMedDeviceorPharmaRelated({ applicationData });
    },
    dependentFields: ['2021-06-01--DID_TRIAL', '2021-06-01--WILL_TRIAL'],
  },
  {
    // Niche is in HW
    condition: applicationData => {
      return primaryNicheIsHwRelated({ applicationData });
    },
    dependentFields: ['2021-06-01--HW_HAS_DANGEROUS_PRODUCTS'],
  },
  {
    // Niche is in Enterprise
    condition: applicationData => {
      return primaryNicheIsEnterpriseRelated({ applicationData });
    },
    dependentFields: [
      '2021-06-01--EPRISE_ADVICE_REPORTING',
      '2021-06-01--EPRISE_DEMANDS_MADE',
      '2021-06-01--EPRISE_SPECIAL_CATEGORY',
      '2021-06-01--EPRISE_EXPECTED_INTL_REVENUE',
      '2021-06-01--EPRISE_LOAN_SERVICES',
    ],
  },
];

const dependencyMapComplex = [
  // Niche-related
  {
    // If 2021-06-01--NICHE-CATEGORY includes "Software"
    condition: appData =>
      appData['2021-06-01--NICHE-CATEGORY']?.includes(P2NicheCategories.SOFTWARE),
    dependentFields: nicheIndustryDependencies[P2NicheCategories.SOFTWARE],
  },
  {
    // If 2021-06-01--NICHE-CATEGORY includes "Hardware"
    condition: appData =>
      appData['2021-06-01--NICHE-CATEGORY']?.includes(P2NicheCategories.HARDWARE),
    dependentFields: nicheIndustryDependencies[P2NicheCategories.HARDWARE],
  },
  {
    // If 2021-06-01--NICHE-CATEGORY includes "Physical or Consumer Goods"
    condition: appData => appData['2021-06-01--NICHE-CATEGORY']?.includes(P2NicheCategories.GOODS),
    dependentFields: nicheIndustryDependencies[P2NicheCategories.GOODS],
  },
  {
    // If 2021-06-01--NICHE-CATEGORY includes "Services"
    condition: appData =>
      appData['2021-06-01--NICHE-CATEGORY']?.includes(P2NicheCategories.SERVICES),
    dependentFields: nicheIndustryDependencies[P2NicheCategories.SERVICES],
  },
  {
    // If "Something Else" selected in Question 7 OR "Cannabis" selected in Question 7 OR
    // "Something Else" selected in Question 24 OR "Healthcare or Life Sciences" selected in
    // Question 32 OR "Something Else" selected in Question 32 OR "Cannabis" selected in
    // Question 32 OR "Something Else" selected in Question 40
    condition: appData => {
      return (
        get(appData, '2021-06-01--NICHE_SW_INDUSTRY', []).includes(
          P2NicheSwIndustries.SOMETHING_ELSE
        ) ||
        get(appData, '2021-06-01--NICHE_SW_INDUSTRY', []).includes(P2NicheSwIndustries.CANNABIS) ||
        get(appData, '2021-06-01--NICHE_HW_INDUSTRY', []).includes(
          P2NicheHwIndustries.SOMETHING_ELSE
        ) ||
        get(appData, '2021-06-01--NICHE_GOODS_INDUSTRY', []).includes(
          P2NicheGoodsIndustries.HEALTH
        ) ||
        get(appData, '2021-06-01--NICHE_GOODS_INDUSTRY', []).includes(
          P2NicheGoodsIndustries.SOMETHING_ELSE
        ) ||
        get(appData, '2021-06-01--NICHE_GOODS_INDUSTRY', []).includes(
          P2NicheGoodsIndustries.CANNABIS
        ) ||
        get(appData, '2021-06-01--NICHE_PS_INDUSTRY', []).includes(
          P2NicheServicesIndustries.SOMETHING_ELSE
        )
      );
    },
    dependentFields: ['2021-06-01--NICHE_USER_ENTERED'],
  },
  {
    // If Total Capital Raised > $100,000,000
    condition: appData => {
      const LIMIT = dollarValues.oneHundredMillionInCents;
      return (
        get(
          appData,
          ['2019-07-01--CAPITAL_RAISED_CONTAINER', '2019-07-01--CAPITAL_RAISED_EQUITY_CENTS'],
          0
        ) +
          get(
            appData,
            ['2019-07-01--CAPITAL_RAISED_CONTAINER', '2019-07-01--CAPITAL_RAISED_DEBT_CENTS'],
            0
          ) >
        LIMIT
      );
    },
    dependentFields: ['2021-06-01--PERCENT_OWNED_NONVC'],
  },
  {
    // If Total Capital Raised > $100,000,000
    condition: appData => {
      const LIMIT = dollarValues.oneHundredMillionInCents;
      return (
        get(
          appData,
          ['2019-07-01--CAPITAL_RAISED_CONTAINER', '2019-07-01--CAPITAL_RAISED_EQUITY_CENTS'],
          0
        ) +
          get(
            appData,
            ['2019-07-01--CAPITAL_RAISED_CONTAINER', '2019-07-01--CAPITAL_RAISED_DEBT_CENTS'],
            0
          ) >
        LIMIT
      );
    },
    dependentFields: ['2021-06-01--PREF_SHARES_SALES'],
  },
  {
    // If Total Capital Raised > $20,000,000
    condition: appData => {
      const LIMIT = dollarValues.twentyMillionInCents;
      return (
        get(
          appData,
          ['2019-07-01--CAPITAL_RAISED_CONTAINER', '2019-07-01--CAPITAL_RAISED_EQUITY_CENTS'],
          0
        ) +
          get(
            appData,
            ['2019-07-01--CAPITAL_RAISED_CONTAINER', '2019-07-01--CAPITAL_RAISED_DEBT_CENTS'],
            0
          ) >
        LIMIT
      );
    },
    dependentFields: ['2021-06-01--SHARES_PUBLICLY_LISTED'],
  },
  {
    // If Total Capital Raised > $100,000,000
    condition: appData => {
      const LIMIT = dollarValues.oneHundredMillionInCents;
      return (
        get(
          appData,
          ['2019-07-01--CAPITAL_RAISED_CONTAINER', '2019-07-01--CAPITAL_RAISED_EQUITY_CENTS'],
          0
        ) +
          get(
            appData,
            ['2019-07-01--CAPITAL_RAISED_CONTAINER', '2019-07-01--CAPITAL_RAISED_DEBT_CENTS'],
            0
          ) >
        LIMIT
      );
    },
    dependentFields: ['2021-06-01--ACQUIRE_10M_COMPANY'],
  },
  {
    // If either 2019-07-01--HAS_401K or 2021-16-02--SUBJECT_TO_ERISA is true
    condition: appData =>
      appData['2019-07-01--HAS_401K'] || appData['2021-16-02--SUBJECT_TO_ERISA'],
    dependentFields: ['2019-07-01--BENEFITS_WORTH'],
  },
  {
    // If next 12 Month Revenue > $2,500,000
    condition: appData => {
      const LIMIT = dollarValues.twoPointFiveMillionInCents;
      return (
        get(
          appData,
          ['2019-07-01--ANNUAL_REVENUE_CONTAINER', '2019-07-01--ANNUAL_REVENUE_NEXT_YEAR_CENTS'],
          0
        ) > LIMIT
      );
    },
    dependentFields: ['2021-06-01--SOC_COMPLIANCE'],
  },
  {
    // If 2021-06-01--SOC_COMPLIANCE is "Not SOC 2 Compliant" OR If "Unsure"
    condition: appData =>
      ['Unsure', 'Not SOC 2 Compliant'].includes(appData['2021-06-01--SOC_COMPLIANCE']),
    dependentFields: ['2021-06-01--LOGIN_REQUIRES_MFA'],
  },
  {
    // If 2021-06-01--HAS_DANGEROUS_PRODUCTS is NOT "None of these apply"
    condition: appData => appData['2021-06-01--HAS_DANGEROUS_PRODUCTS'] !== noneOfTheseApply,
    dependentFields: ['2021-06-01--HAS_DANGEROUS_PRODUCTS_DESC'],
  },
  {
    // If 2021-06-01--MANUFACTURING_STATUS is "We manufacture products" OR "Other"
    condition: appData =>
      ['Other', 'We manufacture products'].includes(appData['2021-06-01--MANUFACTURING_STATUS']),
    dependentFields: ['2021-06-01--MANUFACTURING_STATUS_DESC'],
  },
  {
    // If "None of these apply" not selected in 2021-06-01--MEDIA_LOANS_OR_TRANSFERS
    condition: appData => {
      const preConditionAnswer = get(appData, '2021-06-01--MEDIA_LOANS_OR_TRANSFERS', []);
      return preConditionAnswer.length > 0 && !preConditionAnswer.includes(noneOfTheseApply);
    },
    dependentFields: ['2021-06-01--MEDIA_LOANS_OR_TRANSFERS_DESC'],
  },
  {
    // If "None of these apply" not selected in 2021-06-01--PS_PROF_SERVICES
    condition: appData => {
      const preConditionAnswer = get(appData, '2021-06-01--PS_PROF_SERVICES', []);
      return preConditionAnswer.length > 0 && !preConditionAnswer.includes(noneOfTheseApply);
    },
    dependentFields: ['2021-06-01--PS_PROF_SERVICES_DESC'],
  },
  {
    // If "None of these apply" not selected in 2021-06-01--FT_OPERATES_FINTECH_DESC
    condition: appData => {
      const preConditionAnswer = get(appData, '2021-06-01--FT_OPERATES_FINTECH', []);
      return preConditionAnswer.length > 0 && !preConditionAnswer.includes(noneOfTheseApply);
    },
    dependentFields: ['2021-06-01--FT_OPERATES_FINTECH_DESC'],
  },
  {
    // If "Other" not selected in 2021-06-01--FT_ANNUAL_LOANS
    condition: appData => appData['2021-06-01--FT_GTM_STRATEGY'] === 'Other',
    dependentFields: ['2021-06-01--FT_ANNUAL_LOANS'],
  },
  {
    // If "Phase 1, 2 or 3" selected in 2021-06-01--DID_TRIAL
    condition: appData =>
      ['Phase 1 clinical trials', 'Phase 2 clinical trials', 'Phase 3 clinical trials'].includes(
        appData['2021-06-01--DID_TRIAL']
      ),
    dependentFields: ['2021-06-01--DID_TRIAL_DESC'],
  },
  {
    // If "Phase 1, 2 or 3" selected in 2021-06-01--WILL_TRIAL
    condition: appData =>
      ['Phase 1 clinical trials', 'Phase 2 clinical trials', 'Phase 3 clinical trials'].includes(
        appData['2021-06-01--WILL_TRIAL']
      ),
    dependentFields: ['2021-06-01--WILL_TRIAL_DESC'],
  },
  {
    // If "None of these apply" not selected in 2021-06-01--MEDDEV_SPECIAL_DEVICE
    condition: appData => {
      const preConditionAnswer = get(appData, '2021-06-01--MEDDEV_SPECIAL_DEVICE', []);
      return preConditionAnswer.length > 0 && !preConditionAnswer.includes(noneOfTheseApply);
    },
    dependentFields: ['2021-06-01--MEDDEV_SPECIAL_DEVICE_DESC'],
  },
  {
    // If "None of these apply" not selected in 2021-06-01--EPRISE_ADVICE_REPORTING
    condition: appData => {
      const preConditionAnswer = get(appData, '2021-06-01--EPRISE_ADVICE_REPORTING', []);
      return preConditionAnswer.length > 0 && !preConditionAnswer.includes(noneOfTheseApply);
    },
    dependentFields: ['2021-06-01--EPRISE_ADVICE_REPORTING_DESC'],
  },
  {
    // If "None of these apply" not selected in 2021-06-01--EPRISE_LOAN_SERVICES
    condition: appData => {
      const preConditionAnswer = get(appData, '2021-06-01--EPRISE_LOAN_SERVICES', []);
      return preConditionAnswer.length > 0 && !preConditionAnswer.includes(noneOfTheseApply);
    },
    dependentFields: ['2021-06-01--EPRISE_LOAN_SERVICES_DESC'],
  },
];

// For each dependency, if it's not fullfilled, delete the dependent property
export const clearDependentProg2Fields = (data, store) => {
  let fields = [] as string[];
  const { applicationData } = store.state.application;
  // Combine the changes made on the card with our application data from Vuex to check conditions
  const updatedAppData = { ...applicationData, ...data };

  // Boolean conditions
  dependencyMapBoolean.forEach(({ dataPath, dependentFields }) => {
    if (get(updatedAppData, dataPath) === false) {
      fields = [...fields, ...dependentFields.filter(f => !isNil(get(updatedAppData, f)))];
    }
  });

  // Clear all existing niche-related fields if we are re-entering niche data
  if (
    '2021-06-01--NICHE-CATEGORY' in data &&
    '2021-06-01--NICHE_BUSINESS_MODEL_DESC' in store.state.application.applicationData
  ) {
    const [, ...NICHE_INPUTS_MINUS_CATEGORY] = APPLICATION_DATA_WHITELIST;
    const NICHE_FIELDS_TO_REMOVE = [
      ...Object.values(LEGACY_FIELD_MAP),
      ...NICHE_INPUTS_MINUS_CATEGORY,
      '2021-06-01--NICHE_LIST',
      '2021-06-01--PRIMARY_NICHE',
    ];
    fields = [...fields, ...NICHE_FIELDS_TO_REMOVE];
  }

  // Conditions dependent on primary niche - only run these if niche changed
  dependencyMapPrimaryNicheConditions.forEach(({ condition, dependentFields }) => {
    if (has(data, '2021-06-01--PRIMARY_NICHE') && condition(updatedAppData) === false) {
      fields = [...fields, ...dependentFields.filter(f => !isNil(get(updatedAppData, f)))];
    }
  });

  // Conditions with complex dependencies
  dependencyMapComplex.forEach(({ condition, dependentFields }) => {
    if (condition(updatedAppData) === false) {
      fields = [...fields, ...dependentFields.filter(f => !isNil(get(updatedAppData, f)))];
    }
  });

  return fields;
};
