<template>
  <v-text-field
    :value="value"
    pattern="[0-9]*"
    type="number"
    inputmode="numeric"
    min="0"
    filled
    :error="error"
    :error-count="1"
    :error-messages="errorMessage"
    hide-details="auto"
    :placeholder="placeholder"
    :label="label"
    @input="handleInput"
  />
</template>

<script>
export default {
  name: 'FormFieldNumber',
  props: {
    error: {
      type: Boolean,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Number,
      required: false,
      default: undefined,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    handleInput(value) {
      const safeValue = Math.max(0, Number(value));

      this.$emit('input', safeValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@include text-field-filled-bg-color;
</style>
