import { ApplicationVersions } from '@/onboarding/types/internal/applicationTypes';

const prequalQuestions = [
  '2019-07-01--CAPITAL_RAISED_CONTAINER',
  '2019-07-01--CATEGORY_CUSTOMER',
  '2019-07-01--CATEGORY_NICHE',
  '2019-07-01--CATEGORY_TECHNOLOGY',
  '2019-07-01--CATEGORY_VERTICAL',
  '2019-07-01--CURRENCY_DENOMINATION',
  '2019-07-01--HAS_INSURANCE_SELF',
  '2019-07-01--NUM_FULL_TIME_WORKERS',
];

const prequalQuestionsProgram2 = [
  '2019-07-01--CAPITAL_RAISED_CONTAINER',
  '2019-07-01--HAS_INSURANCE_SELF',
  '2019-07-01--NUM_FULL_TIME_WORKERS',
  '2019-07-01--CURRENCY_DENOMINATION',
  // TO-DO enable these requirements once they properties are on the response
  '2021-06-01--NICHE-CATEGORY',
  '2021-06-01--NICHE_BUSINESS_MODEL_DESC',
  '2021-06-01--NICHE_LIST',
  '2021-06-01--PRIMARY_NICHE',
  '2021-06-01--NICHE_USER_ENTERED',
];

// if all prequalQuestions ∩ state.applicationData
export const isInPrequal = applicationData => {
  if (applicationData.sha_onboarding_funnel === ApplicationVersions.program2) {
    return prequalQuestionsProgram2.slice().reduce((i, j) => i && j in applicationData, true);
  }
  return prequalQuestions.slice().reduce((i, j) => i && j in applicationData, true);
};
