<template>
  <div>
    <template
      v-if="$scopedSlots['banner-content']"
    >
      <slot name="banner-content" />
    </template>
    <Header />
    <h1 class="page-heading">
      Checkout
    </h1>
    <p class="page-sub-heading">
      Let's get you covered
    </p>
  </div>
</template>

<script>
import Header from '@/onboarding/views/Header';

export default {
  name: 'CheckoutHeaderStandard',
  components: {
    Header,
  },
  props: {
    isMobile: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.page-heading {
  @include h2-text;
  text-align: center;
}

.page-sub-heading {
  @include page-subtitle-text;
  text-align: center;

  margin: 0;
  margin-top: $space-small;
}
</style>
