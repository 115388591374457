import { render, staticRenderFns } from "./LayoutCenteredCard.vue?vue&type=template&id=44ee6d63&scoped=true&"
import script from "./LayoutCenteredCard.vue?vue&type=script&lang=js&"
export * from "./LayoutCenteredCard.vue?vue&type=script&lang=js&"
import style0 from "./LayoutCenteredCard.vue?vue&type=style&index=0&id=44ee6d63&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ee6d63",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VFooter,VRow})
