<template>
  <div class="nav">
    <QuestionCardNavHeading>
      Steps
    </QuestionCardNavHeading>

    <ul class="nav__steps">
      <QuestionCardNavStep
        v-for="{title, navStep} in steps"
        :key="title"
        :current="navStep && (currentNavStep === navStep)"
      >
        {{ title }}
      </QuestionCardNavStep>
    </ul>
  </div>
</template>

<script>
import QuestionCardNavHeading from '../ui/atoms/question_card/QuestionCardNavHeading';
import QuestionCardNavStep from '../ui/atoms/question_card/QuestionCardNavStep';

export default {
  name: 'BaseNavigationBar',
  components: { QuestionCardNavStep, QuestionCardNavHeading },
  props: {
    steps: {
      required: true,
      type: Array,
    },
    currentNavStep: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.nav__steps {
  margin-top: $space-medium;
  padding-left: 0;
  & > * + * {
    margin-top: $space-small;
  }
}
</style>
