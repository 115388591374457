<template>
  <Interstitial
    :message="messageToUser"
    :next="next"
    :dont-reroute="dontLetInterstitialReroute"
  />
</template>

<script>
import Interstitial from '@/shared/views/layouts/Interstitial.vue';
import { AUTH_LOGOUT_ATTEMPTED_BY_UNAUTHED_USER } from '@/onboarding/services/SegmentEventTypes';

export default {
  name: 'LogOut',
  components: { Interstitial },
  data() {
    return {
      dontLetInterstitialReroute: false,
      next: '/',
    };
  },
  dependencies: ['tracking'],
  computed: {
    messageToUser() {
      return this.$auth.isAuthenticated
        ? 'Signing out of Vouch...'
        : 'You are not currently signed in.';
    },
  },
  mounted() {
    if (this.$auth.isAuthenticated) {
      this.dontLetInterstitialReroute = true;
      this.$auth.logout();
    } else {
      this.$rollbar?.info(`Logout attempted by unauthenticated user`);
      this.tracking.sendEvent(AUTH_LOGOUT_ATTEMPTED_BY_UNAUTHED_USER);
    }
  },
};
</script>
