<template>
  <v-btn
    text
    :ripple="false"
    v-bind="$attrs"
    :class="'vouch-button'"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'ButtonText',
};
</script>

<style lang="scss" scoped>
.vouch-button {
  margin: 0;

  transition: color $easing-timing, background-color $easing-timing;
}

.vouch-button.vouch-button.vouch-button.vouch-button {
  padding: 0 $space-large;
  height: $button-height;
  &:focus::before,
  &:hover::before {
    opacity: 0;
  }
}
</style>

