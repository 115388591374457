<template>
  <CardDataSync
    :submit-path="submitPath"
    :schema-path="schemaPath"
    :card-config-path="cardConfigPath"
    :form-field-data="formFieldData"
    :application-id="applicationId"
    :update-fn="updateFn"
    :on-submit-success="onSubmitSuccess"
    :on-submit-error="onSubmitError"
  >
    <template slot-scope="{ onSubmit, isValidForm, formFields, onInputChange, cardData }">
      <v-alert
        v-if="hasError"
        :value="true"
        color="error"
        :dismissible="true"
        transition="fade-transition"
      >
        Something has gone wrong. Please talk with an adviser for help.
      </v-alert>
      <BaseWizardPage
        :loading="isLoading"
        :card-data="cardData"
        :is-submit-disabled="hasReviewErrors || !isValidForm"
        :has-back-button="true"
        :fill-height="false"
        submit-text="Submit"
        @submit="submitReview(onSubmit)"
      >
        <CardFields
          :form-fields="formFields"
          :on-input-change="onInputChange"
        />
        <div
          v-if="shouldCollectPhoneNumber"
          class="formField"
          data-testid="phone-number-input-container"
        >
          <span
            class="form-title field-label"
            v-html="'PHONE NUMBER (OPTIONAL)'"
          />
          <CardField
            :form-field="phoneNumberField"
            :on-input-change="onPhoneChange"
          />
        </div>
      </BaseWizardPage>
    </template>
  </CardDataSync>
</template>

<script>
import BaseCard from '@/shared/components/wizard/BaseCard';
import SchemaFormFieldText from '@/onboarding/components/formfields/SchemaFormFieldText';
import SchemaFormFieldAutocomplete from '@/onboarding/components/formfields/SchemaFormFieldAutocomplete';
import CardFields from '@/shared/views/wizard/core/shared/CardFields';
import CardField from '@/shared/views/wizard/core/shared/CardField';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';
import CardDataSync from '@/shared/components/wizard/CardDataSync';
import { getDecisionData } from '@/onboarding/lib/selectors/storeSelectors';
import { mapActions, mapMutations } from 'vuex';
import selectors from '../../../../lib/selectors/decisionSelectors';
import {
  sendToReactQuotePage,
  setUserHasBeenOnReview,
  getDiscretionPath,
} from '@/onboarding/router/routeHelper';
import BaseWizardPage from '@/shared/components/wizard/BaseWizardPage';
import {
  CORE_DECISION,
  REVIEW_PAGE_ERRORED,
  PHONE_NUMBER_ENTERED,
} from '@/onboarding/services/SegmentEventTypes';

export default {
  name: 'ReviewCard',
  components: {
    BaseWizardPage,
    CardFields,
    CardField,
    CardDataSync,
  },
  extends: BaseCard,
  dependencies: ['featureFlags', 'tracking', 'requests'],

  data() {
    return {
      isLoading: false,
      shouldAlert: false,
      hasError: false,
      phoneNumber: '',
      schemaPath: getSchemaEndpoint('review'),
      submitPath: getUpdateEndpoint('review'),
      cardConfigPath: '2019-07-01--REVIEW_CONTAINER',
      formFieldData: [
        {
          component: SchemaFormFieldText,
          dataPath: '2019-07-01--REVIEW_CONTAINER.2019-07-01--APPLICANT_FULLNAME',
          schemaPath: '2019-07-01--APPLICANT_FULLNAME',
        },
        {
          component: SchemaFormFieldAutocomplete,
          dataPath: '2019-07-01--REVIEW_CONTAINER.2019-07-01--APPLICANT_RELATIONSHIP_TO_COMPANY',
          schemaPath: '2019-07-01--APPLICANT_RELATIONSHIP_TO_COMPANY',
        },
      ],
    };
  },
  computed: {
    decisionData() {
      return getDecisionData(this);
    },
    hasReviewErrors() {
      return this.$store.getters.hasReviewErrors;
    },
    hasSetReviewErrors() {
      return this.$store.getters.hasSetReviewErrors;
    },
    shouldCheckEmailVerification() {
      return this.featureFlags.getFlag({ flag: 'authenticate-before-madlib' });
    },
    shouldCollectPhoneNumber() {
      return this.featureFlags.getFlag({ flag: 'collect-phone-number-on-review' });
    },
    phoneNumberField() {
      return {
        component: SchemaFormFieldText,
        value: '',
        name: 'phone-input',
        dataPath: '',
        validator: () => true,
        warning: () => {},
        propertyDefinition: {
          examples: [],
        },
      };
    },
  },
  watch: {
    hasSetReviewErrors: {
      immediate: true,
      handler(newVal, _oldVal) {
        if (newVal === true && !this.hasReviewErrors) {
          this.requests.postPreCacheQuote({ applicationId: this.applicationId });
        }
      },
    },
  },
  created() {
    setUserHasBeenOnReview(this);
  },
  methods: {
    ...mapActions(['fetchDecisionData', 'fetchPackagesData']),
    ...mapMutations(['updateDecisionData', 'updateApplicationData']),
    updateFn(args) {
      this.requests.postApplicationData({ ...args, context: this });
    },
    submitReview(onSubmit) {
      this.isLoading = true;
      this.hasError = false;
      if (this.phoneNumber) {
        this.tracking.sendEvent(PHONE_NUMBER_ENTERED, { phoneNumber: this.phoneNumber });
      }
      onSubmit();
    },
    onPhoneChange(value) {
      this.phoneNumber = value;
    },
    onSubmitError(data) {
      this.hasError = true;
      this.isLoading = false;
      window.vueRoot.$rollbar.critical('Unhandled Review Page Error', data);
      this.tracking.sendEvent(REVIEW_PAGE_ERRORED, { applicationData: this.applicationData });
    },
    async onSubmitSuccess(applicationData) {
      this.updateApplicationData({ applicationData });
      await this.fetchDecisionData();
      this.isLoading = false;
      this.tracking.sendEvent(CORE_DECISION, {
        decision: this.decisionData.decision,
        applicationId: this.applicationId,
      });

      // If user has not yet verified their email, route to email verification page)
      if (this.shouldCheckEmailVerification && !this.$auth.user.email_verified) {
        const { email } = this.$auth.user;
        this.$router.push({
          name: 'EmailVerification',
          query: { code: 'newlyRegistered', email },
        });
        return;
      }

      const isRenewal = applicationData.renewal.is_renewal_application;
      const discretionPath = await getDiscretionPath({
        applicationId: this.applicationId,
        onboardingQuestionRouter: this.questionRouter,
        isRenewal,
      });
      if (discretionPath) {
        this.$router.push({ path: discretionPath });
      } else if (selectors.isYesDecision(this.decisionData)) {
        sendToReactQuotePage();
      } else {
        this.questionRouter.abort(this);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.review-fields {
  margin-bottom: $space-xlarge;
}
.field-label {
  @include label-text;
}
</style>
