<template>
  <div
    class="price"
    :class="{ large }"
  >
    <span
      class="price-amount"
      :data-testid="`${frequency}-price`"
      :style="{ color }"
    >
      {{ amount }}
    </span>
    <span
      class="payment-frequency"
      data-testid="payment-frequency"
    >
      {{ frequency }}
      <span
        v-if="$scopedSlots['tooltip']"
        class="tooltip-container"
      >
        <slot name="tooltip" />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'PriceTag',
  props: {
    amount: {
      type: String,
      required: true,
    },
    frequency: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'inherit',
    },
    large: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
.price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: wrap;

  .price-amount {
    @include h3-text;
    margin-bottom: $space-small;
  }

  &.large {
    flex-direction: column;
    align-items: flex-end;

    .price-amount {
      @include price-amount-text-large;
      margin-right: 0;
    }
  }

  .payment-frequency {
    @include label-text;
  }

  .tooltip-container {
    margin-left: $space-xsmall;
  }
}
</style>
