<template>
  <v-select
    :value="value"
    :items="items"
    :error="error"
    :error-messages="errorMessage"
    filled
    @input="handleInput"
  />
</template>

<script>
export default {
  name: 'FormFieldSingleSelect',
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    error: {
      type: Boolean,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
@include text-field-filled-bg-color;
</style>
