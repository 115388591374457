<template>
  <div class="question-card-header">
    <VouchLogoSVG class="question-card-header__logo" />
    <span class="question-card-header__text">{{ text }}</span>
  </div>
</template>

<style lang="scss" scoped>
.question-card-header {
  display: flex;
  align-items: center;
}

.question-card-header__logo {
  flex-shrink: 0;
}

.question-card-header__text {
  margin-left: $vouch-logo-padding;
  font-weight: bold;
}
</style>

<script>
import VouchLogoSVG from '../atoms/logos/VouchLogoSVG';

export default {
  name: 'QuestionCardHeader',
  components: { VouchLogoSVG },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
