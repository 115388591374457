<script>
import {
  applicationIsProgram2,
  getApplicationData,
  getApplicationParentData,
} from '@/onboarding/lib/selectors/storeSelectors';

/**
 * Root class of all wizard cards.
 */

export default {
  name: 'BaseCard',
  dependencies: ['questionRouter'],
  props: {
    cardId: {
      type: String,
      required: true,
    },
    applicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cardData: {},
    };
  },
  computed: {
    applicationData() {
      return getApplicationData(this);
    },
    applicationParentData() {
      return getApplicationParentData(this);
    },
    isProgram2() {
      return applicationIsProgram2(this);
    },
  },
  methods: {
    // If subclasses need to access applicationData in create or data callbacks, use this method.
    // The computed attribute will not be available to you at those points in the lifecycle.
    getApplicationData() {
      return getApplicationData(this);
    },
    getApplicationParentData() {
      return getApplicationParentData(this);
    },
  },
};
</script>
