<template>
  <WithBackground :color="backgroundColor">
    <TransitionFade>
      <router-view
        v-bind="$attrs"
      />
    </TransitionFade>
  </WithBackground>
</template>

<script>
import TransitionFade from '@/shared/components/stateful/TransitionFade';
import WithBackground from '@/shared/frames/WithBackground';
import Colors from '@/Colors';

export default {
  name: 'TransitionWithBackgroundBase',
  components: { WithBackground, TransitionFade },
  computed: {
    backgroundColor() {
      return Colors.pageBackgroundColor;
    },
  },
};
</script>
