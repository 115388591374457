<script>
import BaseCardStatic from '@/shared/components/wizard/BaseCardStatic';
import { getCompanyName } from '../../../../lib/selectors/storeSelectors';

export default {
  name: 'StartCard',
  extends: BaseCardStatic,
  created() {
    this.cardData = {
      title: `Welcome to Vouch, ${getCompanyName(this)}!`,
      description:
        "Let's start with a few questions to make sure we can handle your coverage needs.",
    };
  },
};
</script>
