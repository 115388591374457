<script>
const IsMobile = {
  name: 'IsMobile',
  props: {},
  render() {
    const isMobile = ['xs', 's', 'm'].includes(this.$mq);

    return this.$scopedSlots.default({ isMobile });
  },
};

export default IsMobile;
</script>
