<template>
  <div>
    <div>
      <CardTitle>{{ item.question }}</CardTitle>
      <CardDescription>{{ item.small_text }}</CardDescription>
    </div>
    <div>
      <FormFieldTextArea
        v-if="isUserEnteredNiche"
        :value="inputValue"
        :placeholder="item.small_text"
        @input="onInput"
      />
      <v-chip-group
        v-else
        column
      >
        <div
          v-for="option in options"
          :key="option"
        >
          <Tooltip
            v-if="getTooltip(option)"
            click-to-show
          >
            <template slot="trigger">
              <v-chip
                class="vi-niche-chip with-tooltip"
                :outlined="!isSelected(option)"
                :disabled="isMaxSelected"
                :close="isSelected(option)"
                :color="isSelected(option) ? 'primary' : ''"
                @click:close="onDeselect(option)"
                @click="onSelect(option)"
              >
                {{ option }}
                <v-icon
                  v-show="!isSelected(option)"
                  dense
                  right
                  class="material-icons-outlined"
                >
                  info
                </v-icon>
              </v-chip>
            </template>
            <span slot="text">
              {{ getTooltip(option) }}
            </span>
          </Tooltip>
          <v-chip
            v-else
            class="vi-niche-chip"
            :outlined="!isSelected(option)"
            :disabled="isMaxSelected && !isSelected(option)"
            :close="isSelected(option)"
            :color="isSelected(option) ? 'primary' : ''"
            @click:close="onDeselect(option)"
            @click="onSelect(option)"
          >
            {{ option }}
          </v-chip>
        </div>
      </v-chip-group>
      <v-row no-gutters>
        <v-col cols="12">
          <div
            v-show="inputParentValue"
            class="previousAnswer"
          >
            <PreviousAnswer :value="inputParentValue" />
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="card-actions">
      <ButtonPrimary
        type="submit"
        :loading="loading"
        :disabled="isSubmitDisabled"
        class="wizard-card-form-submit"
        data-testid="submit-button"
        @click.prevent="onSubmit"
      >
        {{ submitText }}
      </ButtonPrimary>
    </div>
  </div>
</template>

<script>
import { uniq } from 'lodash';
import ButtonPrimary from '@/shared/components/ui/atoms/buttons/ButtonPrimary';
import Tooltip from '@/onboarding/components/Tooltip';
import CardTitle from '@/shared/components/ui/atoms/question_card/QuestionCardTitle';
import CardDescription from '@/shared/components/ui/atoms/question_card/QuestionCardDescription';
import FormFieldTextArea from '@/shared/components/ui/atoms/form_fields/FormFieldTextArea';
import PreviousAnswer from '@/shared/components/wizard/PreviousAnswer';
import { get } from '@/shared/lib/vouch_dash';

export default {
  name: 'NicheQuestionCard',
  components: {
    ButtonPrimary,
    Tooltip,
    CardTitle,
    CardDescription,
    FormFieldTextArea,
    PreviousAnswer,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    tooltips: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selected: [],
    inputValue: '',
  }),
  computed: {
    options() {
      if (this.isUserEnteredNiche) {
        return [];
      }

      return this.item.options;
    },
    isUserEnteredNiche() {
      return this.item.options[0] === '[Text Box]';
    },
    loading() {
      return false;
    },
    isSubmitDisabled() {
      return !this.selected.length && !this.inputValue;
    },
    submitText() {
      return 'Next';
    },
    isMaxSelected() {
      if (this.item.limit === '*') return false;

      const limit = parseInt(this.item.limit, 10);

      if (limit === this.selected.length) return true;

      return false;
    },
    inputParentValue() {
      const v = get(this.$store.state.application.applicationParentData, this.item.schema_id);
      return v ? this.formatGetInput(v) : null;
    },
  },
  watch: {
    item(newNicheItem, oldNicheItem) {
      // If we change niche cards, reset selected to avoid mixed props when user hits back button
      this.selected = [];
    },
  },
  methods: {
    onInput(val) {
      this.inputValue = val;
      this.$emit('onValueChange', this.item.identifier, this.inputValue);
    },
    onSelect(option) {
      this.selected = uniq([...this.selected, option]);
      this.$emit('onValueChange', this.item.identifier, this.selected);
    },
    onDeselect(option) {
      this.selected = this.selected.filter(selected => selected !== option);
      this.$emit('onValueChange', this.item.identifier, this.selected);
    },
    isSelected(option) {
      return !!this.selected.find(selected => selected === option);
    },
    onSubmit() {
      this.$emit('submitAnswers');
      this.selected = [];
      this.inputValue = '';
    },
    getTooltip(option) {
      const tooltip = this.tooltips[option];

      return tooltip ? tooltip.marketing_text || tooltip.pm_text : false;
    },
    formatGetInput(value) {
      if (typeof value === 'string') {
        return value;
      }
      return value.reduce((prev, curr) => prev.concat(` + ${curr}`));
    },
  },
};
</script>

<style lang="scss" scoped>
.card-actions {
  margin-top: $card-spacing;
}

.question-card-description {
  margin-top: $form-spacing;
  margin-bottom: $form-spacing;
}

.v-chip-group {
  div {
    &:focus {
      outline: none;
    }
  }
  .vi-niche-chip {
    margin: 20px 20px 0 0;
    padding-left: 24px;
    padding-right: 24px;
    border-color: $vouch-primary-dark-blue;
    font-family: $sans-font-family;
    outline: none;
    color: $vouch-primary-dark-blue;

    &.v-chip:not(.v-chip--outlined).primary {
      color: inherit;
    }

    &.v-chip--active:not(:hover):before {
      opacity: 0;
      background: initial;
      color: $vouch-primary-dark-blue;
    }

    &.primary {
      padding-right: 12px;
    }

    &.with-tooltip {
      padding-right: 12px;

      .v-icon.material-icons-outlined {
        opacity: 0.7;
      }
    }
  }
}

.previousAnswer {
  margin-top: $form-spacing;
}
</style>
