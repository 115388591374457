export const getCardId = route => {
  const onboardingResult = route.path.match(/\/getquote\/(.*)/i);
  if (onboardingResult && onboardingResult[1]) {
    return onboardingResult[1];
  }

  const program2onboardingResult = route.path.match(/\/application\/(.*)/i);
  if (program2onboardingResult && program2onboardingResult[1]) {
    return program2onboardingResult[1];
  }

  const renewalResultProgram2 = route.path.match(/\/renewal\/(.*)/i);
  if (renewalResultProgram2 && renewalResultProgram2[1]) {
    return renewalResultProgram2[1];
  }

  const renewalResult = route.path.match(/\/renew\/(.*)/i);
  if (renewalResult && renewalResult[1]) {
    return renewalResult[1];
  }

  return '';
};
