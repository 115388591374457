import { eachLeaves, setProp, deleteProp } from '@/shared/lib/vouch_dash';

const LOC_CONTAINER_KEY = '2019-07-01--LOCATION_CONTAINER';

const initialState = {
  fieldData: {}, // make me an object, keyed by index
  submitData: {},
  parentData: {},
  submitDataValidity: {},
};

const getters = {
  isValidSubmitData: state => eachLeaves(state.submitDataValidity).every(v => v === true),
  getSubmitData: state => state.submitData,
  getSubmitDataValidity: state => state.submitDataValidity,
  getFieldData: state => (index = 0) => state.fieldData[index] || [],
};

const actions = {};

const mutations = {
  removeAddress: (state, { index, guid }) => {
    deleteProp(state.fieldData, guid);

    const locationIndex = index - 1;
    const newSubmitData = { ...state.submitData };
    newSubmitData[LOC_CONTAINER_KEY].additional_locations.splice(locationIndex, 1);
    state.submitData = newSubmitData;

    const newSubmitDataValidity = { ...state.submitDataValidity };
    newSubmitDataValidity[LOC_CONTAINER_KEY].additional_locations.splice(locationIndex, 1);
    state.submitDataValidity = newSubmitDataValidity;
  },
  // For newer mixed-address/attribute card, `fieldData` contains fields for all the
  // available addresses and uses an index to track which fields to display at one one time
  removeAddressV2: (state, { additionalLocIndex }) => {
    state.submitData[LOC_CONTAINER_KEY].additional_locations.splice(additionalLocIndex, 1);
    state.submitDataValidity[LOC_CONTAINER_KEY].additional_locations.splice(additionalLocIndex, 1);
  },
  removeClaim: (state, { guid, type }) => {
    deleteProp(state.fieldData, guid);

    const newSubmitData = { ...state.submitData };
    deleteProp(
      newSubmitData['2019-07-01--LIABILITIES_CLAIMS_CONTAINER'][
        '2019-07-01--CLAIMS_AMOUNTS_ITEMIZED'
      ],
      type
    );
    state.submitData = newSubmitData;

    const newSubmitDataValidity = { ...state.submitDataValidity };
    deleteProp(
      newSubmitDataValidity['2019-07-01--LIABILITIES_CLAIMS_CONTAINER'][
        '2019-07-01--CLAIMS_AMOUNTS_ITEMIZED'
      ],
      type
    );
    state.submitDataValidity = newSubmitDataValidity;
  },
  appendFieldData: (state, { fieldData }) => {
    const index = Object.keys(state.fieldData).length;
    setProp(state, `fieldData.${index}`, fieldData);
  },
  updateFieldData: (state, { fieldData, index = 0 }) => {
    setProp(state, `fieldData.${index}`, fieldData);
  },
  updateFieldDataProp: (state, { path, value, index = 0 }) => {
    setProp(state.fieldData, `${index}.${path}`, value);
  },
  clearSubmitData: state => {
    state.fieldData = {};
    state.submitData = {};
    state.submitDataValidity = {};
  },
  // Necessary for the case where user adds an address, proceeds to attributes, then moves back
  // to previous; this removes the null properties so we can still submit without error on "Next"
  clearEmptyAddrAttributeValidity: (state, curAddrIndex) => {
    const addlLocData = state.submitData[LOC_CONTAINER_KEY].additional_locations;
    const addlLocValidityData = state.submitDataValidity[LOC_CONTAINER_KEY].additional_locations;
    if (curAddrIndex === addlLocValidityData.length) {
      Object.entries(addlLocData?.[curAddrIndex - 1]).forEach(([key, value]) => {
        if (value === null) {
          deleteProp(addlLocData[curAddrIndex - 1], key);
          deleteProp(addlLocValidityData[curAddrIndex - 1], key);
        }
      });
    }
  },
  updateSubmitData: (state, { dataPath, value, isValid }) => {
    const { submitData, submitDataValidity } = state;

    setProp(submitData, dataPath, value);
    setProp(submitDataValidity, dataPath, isValid);
  },
  patchSubmitData: (state, { dataPath, value }) => {
    const { submitData } = state;
    setProp(submitData, dataPath, value);
  },
};

export default {
  state: initialState,
  getters,
  actions,
  mutations,
};
