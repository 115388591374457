<template>
  <div>
    <slot
      :isValidForm="isValidForm"
      :onSubmit="onSubmit"
      :onInputChange="onInputChange"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { Program2EndpointMap } from '@/onboarding/constants/Constants';
import { applicationIsProgram2 } from '@/onboarding/lib/selectors/storeSelectors';

export default {
  name: 'WithDataSync',
  dependencies: ['requests'],
  props: {
    applicationId: {
      type: String,
      required: true,
    },
    updateFn: {
      type: Function,
      required: true,
    },
    submitPath: {
      type: String,
      required: true,
    },
    onSubmitSuccess: {
      type: Function,
      required: true,
    },
    onSubmitError: {
      type: Function,
      required: false,
      default: data => {
        window.vueRoot.$rollbar.error('Unhandled onSubmitError', data);
      },
    },
  },
  computed: {
    ...mapGetters({
      isValidForm: 'isValidSubmitData',
    }),
    isProgram2() {
      return applicationIsProgram2(this);
    },
    mappedSubmitPath() {
      return this.isProgram2 ? Program2EndpointMap[this.submitPath] : this.submitPath;
    },
  },
  methods: {
    onInputChange(value, { dataPath, isValid, initialEvent }) {
      this.$store.commit('updateSubmitData', {
        value,
        dataPath,
        isValid,
        initialEvent,
      });
    },
    onSubmit() {
      if (!this.isValidForm) {
        throw new Error('Cant submit wizard card, should never hit this');
      }

      const submitData = this.$store.getters.getSubmitData;

      this.updateFn({
        applicationId: this.applicationId,
        path: this.mappedSubmitPath,
        data: submitData,
        onSuccess: data => {
          this.onSubmitSuccess(data);
        },
        onError: data => {
          this.onSubmitError(data);
        },
      });
    },
  },
};
</script>
