import {
  appDataPathDoesNotInclude,
  appDataPathIs,
  appDataPathIsOneOf,
  appDataPathIsGreaterThan,
  anyPropertyIsTrue,
  applicationDataHas,
  appDataPathsSummedAreGreaterThan,
  autoYes,
  appDataPathIncludesAny,
  locationDetailsComplete,
} from '@/shared/routing/routingDecisionHelpers';
import { dollarValues } from '@/onboarding/constants/Constants';
import {
  noneOfTheseApply,
  primaryNicheIsAddOnServiceClass,
  primaryNicheIsB2BServiceClass,
  primaryNicheHasMediumHighDataIntensity,
  primaryNicheCouldIncludeDangerousProduct,
  primaryNicheCouldInvolveManufacturing,
  selectedDangerousManufacturingOption,
  primaryNicheIsMediaRelated,
  primaryNicheIsTransportRelated,
  primaryNicheIsProfServicesRelated,
  primaryNicheIsFintechRelated,
  primaryNicheIsPharmaRelated,
  primaryNicheIsMedDeviceRelated,
  primaryNicheIsMedDeviceorPharmaRelated,
  primaryNicheIsHwRelated,
  primaryNicheIsEnterpriseRelated,
} from '@/onboarding/constants/P2NicheConstants';
import { get } from '../lib/vouch_dash';
import featureFlagging from '@/onboarding/services/FeatureFlaggingService';

// Prequal
export const hasInsuranceSelfCard = {
  id: '2019-07-01--HAS_INSURANCE_SELF',
  isSatisfied: applicationDataHas('2019-07-01--HAS_INSURANCE_SELF'),
  shouldRender: autoYes,
};
export const numFullTimeWorkersCard = {
  id: '2019-07-01--NUM_FULL_TIME_WORKERS',
  isSatisfied: applicationDataHas('2019-07-01--NUM_FULL_TIME_WORKERS'),
  shouldRender: autoYes,
};
export const capitalRaisedContainerCard = {
  id: '2019-07-01--CAPITAL_RAISED_CONTAINER',
  isSatisfied: applicationDataHas('2019-07-01--CAPITAL_RAISED_CONTAINER'),
  shouldRender: autoYes,
};

// Core
export const hasInsuranceReqsCard = {
  id: '2019-07-01--HAS_INSURANCE_REQS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_INSURANCE_REQS'),
  shouldRender: autoYes,
};

export const hasInsuranceReqsDescCard = {
  id: '2021-06-01--HAS_INSURANCE_REQS_DESC',
  isSatisfied: applicationDataHas('2021-06-01--HAS_INSURANCE_REQS_DESC'),
  shouldRender: appDataPathIs('2019-07-01--HAS_INSURANCE_REQS', true),
};

export const legalEntityContainerCard = {
  id: '2019-07-01--LEGAL_ENTITY_NAME',
  isSatisfied: applicationDataHas('2019-07-01--LEGAL_ENTITY_NAME'),
  shouldRender: autoYes,
};

export const yearIncorporatedCard = {
  id: '2019-07-01--YEAR_INCORPORATED',
  isSatisfied: applicationDataHas('2019-07-01--YEAR_INCORPORATED'),
  shouldRender: autoYes,
};

export const locationContainerCard = {
  id: '2019-07-01--LOCATION_CONTAINER',
  isSatisfied: applicationDataHas('2019-07-01--LOCATION_CONTAINER'),
  shouldRender: ({ applicationData }) => {
    return (
      !get(applicationData, '2019-07-01--LOCATION_CONTAINER') ||
      !featureFlagging.getFlag({ flag: 'biz-address-on-madlib' })
    );
  },
};

export const locationContainerAttributesCard = {
  id: '2019-07-01--LOCATION_CONTAINER_attributes',
  isSatisfied: autoYes,
  shouldRender: () => {
    return !featureFlagging.getFlag({ flag: 'biz-address-on-madlib' });
  },
};

export const locationContainerAttributesAndAdditionalCard = {
  id: '2019-07-01--LOCATIONS',
  isSatisfied: locationDetailsComplete(),
  shouldRender: ({ applicationData }) => {
    return featureFlagging.getFlag({ flag: 'biz-address-on-madlib' });
  },
};

export const hasBigTicketItemCard = {
  id: '2021-06-01--HAS_BIG_TICKET_ITEM',
  isSatisfied: applicationDataHas('2021-06-01--HAS_BIG_TICKET_ITEM'),
  shouldRender: autoYes,
};

export const bigTicketItemDescCard = {
  id: '2021-06-01--BIG_TICKET_ITEM_DESC',
  isSatisfied: applicationDataHas('2021-06-01--BIG_TICKET_ITEM_DESC'),
  shouldRender: appDataPathIs('2021-06-01--HAS_BIG_TICKET_ITEM', true),
};

export const hasOtherBoardDirectorsCard = {
  id: '2019-07-01--HAS_OTHER_BOARD_DIRECTORS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_OTHER_BOARD_DIRECTORS'),
  shouldRender: autoYes,
};

export const leadInvestorLastRoundCard = {
  id: '2021-06-01--LEAD_INVESTOR_LAST_ROUND',
  isSatisfied: applicationDataHas('2021-06-01--LEAD_INVESTOR_LAST_ROUND'),
  shouldRender: autoYes,
};

export const founderPreviousRaiseCard = {
  id: '2021-06-01--FOUNDER_PREVIOUS_RAISE',
  isSatisfied: applicationDataHas('2021-06-01--FOUNDER_PREVIOUS_RAISE'),
  shouldRender: autoYes,
};

export const percentOwnedNonVcCard = {
  id: '2021-06-01--PERCENT_OWNED_NONVC',
  isSatisfied: applicationDataHas('2021-06-01--PERCENT_OWNED_NONVC'),
  // If "2019-07-01--CAPITAL_RAISED_EQUITY_CENTS"+"2019-07-01--CAPITAL_RAISED_DEBT_CENTS" > $100,000,000
  shouldRender: appDataPathsSummedAreGreaterThan(
    '2019-07-01--CAPITAL_RAISED_CONTAINER.2019-07-01--CAPITAL_RAISED_EQUITY_CENTS',
    '2019-07-01--CAPITAL_RAISED_CONTAINER.2019-07-01--CAPITAL_RAISED_DEBT_CENTS',
    dollarValues.oneHundredMillionInCents
  ),
};

export const prefSharesSalesCard = {
  id: '2021-06-01--PREF_SHARES_SALES',
  isSatisfied: applicationDataHas('2021-06-01--PREF_SHARES_SALES'),
  // If "2019-07-01--CAPITAL_RAISED_EQUITY_CENTS"+"2019-07-01--CAPITAL_RAISED_DEBT_CENTS" > $100,000,000
  shouldRender: appDataPathsSummedAreGreaterThan(
    '2019-07-01--CAPITAL_RAISED_CONTAINER.2019-07-01--CAPITAL_RAISED_EQUITY_CENTS',
    '2019-07-01--CAPITAL_RAISED_CONTAINER.2019-07-01--CAPITAL_RAISED_DEBT_CENTS',
    dollarValues.oneHundredMillionInCents
  ),
};

export const sharesPubliclyListedCard = {
  id: '2021-06-01--SHARES_PUBLICLY_LISTED',
  isSatisfied: applicationDataHas('2021-06-01--SHARES_PUBLICLY_LISTED'),
  // If "2019-07-01--CAPITAL_RAISED_EQUITY_CENTS"+"2019-07-01--CAPITAL_RAISED_DEBT_CENTS" > $100,000,000
  shouldRender: appDataPathsSummedAreGreaterThan(
    '2019-07-01--CAPITAL_RAISED_CONTAINER.2019-07-01--CAPITAL_RAISED_EQUITY_CENTS',
    '2019-07-01--CAPITAL_RAISED_CONTAINER.2019-07-01--CAPITAL_RAISED_DEBT_CENTS',
    dollarValues.twentyMillionInCents
  ),
};

export const annualRevenueContainerCard = {
  id: '2019-07-01--ANNUAL_REVENUE_CONTAINER',
  isSatisfied: applicationDataHas('2019-07-01--ANNUAL_REVENUE_CONTAINER'),
  shouldRender: autoYes,
};

export const numBigRevenueContractsCard = {
  id: '2019-07-01--NUM_BIG_REVENUE_CONTRACTS',
  isSatisfied: applicationDataHas('2019-07-01--NUM_BIG_REVENUE_CONTRACTS'),
  shouldRender: primaryNicheIsB2BServiceClass,
};

export const totalCashAndEquivalentsCentsCard = {
  id: '2019-07-01--TOTAL_CASH_AND_EQUIVALENTS_CENTS',
  isSatisfied: applicationDataHas('2019-07-01--TOTAL_CASH_AND_EQUIVALENTS_CENTS'),
  shouldRender: autoYes,
};

export const acquire10MCard = {
  id: '2021-06-01--ACQUIRE_10M_COMPANY',
  isSatisfied: applicationDataHas('2021-06-01--ACQUIRE_10M_COMPANY'),
  // If "2019-07-01--CAPITAL_RAISED_EQUITY_CENTS"+"2019-07-01--CAPITAL_RAISED_DEBT_CENTS" > $100,000,000
  shouldRender: appDataPathsSummedAreGreaterThan(
    '2019-07-01--CAPITAL_RAISED_CONTAINER.2019-07-01--CAPITAL_RAISED_EQUITY_CENTS',
    '2019-07-01--CAPITAL_RAISED_CONTAINER.2019-07-01--CAPITAL_RAISED_DEBT_CENTS',
    dollarValues.oneHundredMillionInCents
  ),
};

export const has401kCard = {
  id: '2019-07-01--HAS_401K',
  isSatisfied: applicationDataHas('2019-07-01--HAS_401K'),
  shouldRender: autoYes,
};

export const hasERISACard = {
  id: '2021-16-02--SUBJECT_TO_ERISA',
  isSatisfied: applicationDataHas('2021-16-02--SUBJECT_TO_ERISA'),
  shouldRender: autoYes,
};

export const benefitsWorthCard = {
  id: '2019-07-01--BENEFITS_WORTH',
  isSatisfied: applicationDataHas('2019-07-01--BENEFITS_WORTH'),
  shouldRender: anyPropertyIsTrue(['2019-07-01--HAS_401K', '2021-16-02--SUBJECT_TO_ERISA']),
};

export const hasProductInMarketCard = {
  id: '2019-07-01--HAS_PRODUCT_IN_MARKET',
  isSatisfied: applicationDataHas('2019-07-01--HAS_PRODUCT_IN_MARKET'),
  shouldRender: autoYes,
};

export const offersConsultingCard = {
  id: '2021-06-01--OFFERS_CONSULTING',
  isSatisfied: applicationDataHas('2021-06-01--OFFERS_CONSULTING'),
  shouldRender: primaryNicheIsAddOnServiceClass,
};

export const hasAnyPiiCard = {
  id: '2019-07-01--HAS_ANY_PII',
  isSatisfied: applicationDataHas('2019-07-01--HAS_ANY_PII'),
  shouldRender: primaryNicheHasMediumHighDataIntensity,
};

export const piiAmountCard = {
  id: '2019-07-01--PII_AMOUNT',
  isSatisfied: applicationDataHas('2019-07-01--PII_AMOUNT'),
  shouldRender: appDataPathIs('2019-07-01--HAS_ANY_PII', true),
};

export const socComplianceCard = {
  id: '2021-06-01--SOC_COMPLIANCE',
  isSatisfied: applicationDataHas('2021-06-01--SOC_COMPLIANCE'),
  shouldRender: appDataPathIsGreaterThan(
    '2019-07-01--ANNUAL_REVENUE_CONTAINER.2019-07-01--ANNUAL_REVENUE_NEXT_YEAR_CENTS',
    dollarValues.twoPointFiveMillionInCents
  ),
};

const notSocCompliantOptions = ['Not SOC 2 Compliant', 'Unsure'];
export const loginRequiresMfaCard = {
  id: '2021-06-01--LOGIN_REQUIRES_MFA',
  isSatisfied: applicationDataHas('2021-06-01--LOGIN_REQUIRES_MFA'),
  // If "Not SOC 2 Compliant" OR If "Unsure" selected in 2021-06-01--SOC_COMPLIANCE
  shouldRender: appDataPathIsOneOf('2021-06-01--SOC_COMPLIANCE', notSocCompliantOptions),
};

export const hasEngineeringContractorsCard = {
  id: '2019-07-01--HAS_ENGINEERING_CONTRACTORS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_ENGINEERING_CONTRACTORS'),
  shouldRender: autoYes,
};

export const numEngineeringContractorsCard = {
  id: '2019-07-01--NUM_ENGINEERING_CONTRACTORS',
  isSatisfied: applicationDataHas('2019-07-01--NUM_ENGINEERING_CONTRACTORS'),
  shouldRender: appDataPathIs('2019-07-01--HAS_ENGINEERING_CONTRACTORS', true),
};

export const numInvoluntaryTerminationCard = {
  id: '2019-07-01--NUM_INVOLUNTARY_TERMINATION',
  isSatisfied: applicationDataHas('2019-07-01--NUM_INVOLUNTARY_TERMINATION'),
  shouldRender: autoYes,
};

export const numPlannedInvoluntaryTerminationCard = {
  id: '2019-07-01--NUM_PLANNED_INVOLUNTARY_TERMINATION',
  isSatisfied: applicationDataHas('2019-07-01--NUM_PLANNED_INVOLUNTARY_TERMINATION'),
  shouldRender: autoYes,
};

export const insuranceHistoryContainerCard = {
  id: '2019-07-01--INSURANCE_HISTORY_CONTAINER',
  isSatisfied: applicationDataHas('2019-07-01--INSURANCE_HISTORY_CONTAINER'),
  shouldRender: autoYes,
};

export const currentClaimsDescriptionCard = {
  id: '2019-07-01--CURRENT_CLAIMS_DESCRIPTION',
  isSatisfied: applicationDataHas('2019-07-01--CURRENT_CLAIMS_DESCRIPTION'),
  shouldRender: appDataPathIs(
    ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_CURRENT_CLAIMS'],
    true
  ),
};

export const deniedCoverageDescriptionCard = {
  id: '2019-07-01--DENIED_COVERAGE_DESCRIPTION',
  isSatisfied: applicationDataHas('2019-07-01--DENIED_COVERAGE_DESCRIPTION'),
  shouldRender: appDataPathIs(
    ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_DENIED_COVERAGE'],
    true
  ),
};

export const currentClaimsNumCard = {
  id: '2021_06_01--CURRENT_CLAIMS_NUM',
  isSatisfied: applicationDataHas('2021_06_01--CURRENT_CLAIMS_NUM'),
  shouldRender: appDataPathIs(
    ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_FILED_LIABILITY_CLAIM'],
    true
  ),
};

export const hasPendingClaimsCard = {
  id: '2019-07-01--HAS_PENDING_CLAIMS',
  isSatisfied: applicationDataHas('2019-07-01--HAS_PENDING_CLAIMS'),
  shouldRender: autoYes,
};

export const pendingClaimsDescriptionCard = {
  id: '2019-07-01--PENDING_CLAIMS_DESCRIPTION',
  isSatisfied: applicationDataHas('2019-07-01--PENDING_CLAIMS_DESCRIPTION'),
  shouldRender: appDataPathIs('2019-07-01--HAS_PENDING_CLAIMS', true),
};

export const liabilitiesClaimsItemizedContainerCard = {
  id: '2019-07-01--LIABILITIES_CLAIMS_CONTAINER',
  isSatisfied: applicationDataHas('2019-07-01--LIABILITIES_CLAIMS_CONTAINER'),
  shouldRender: appDataPathIs(
    ['2019-07-01--INSURANCE_HISTORY_CONTAINER', '2019-07-01--HAS_FILED_LIABILITY_CLAIM'],
    true
  ),
};

// Supplemental question cards

export const hasDangerousProductsP2Card = {
  id: '2021-06-01--HAS_DANGEROUS_PRODUCTS',
  isSatisfied: applicationDataHas('2021-06-01--HAS_DANGEROUS_PRODUCTS'),
  shouldRender: primaryNicheCouldIncludeDangerousProduct,
};

export const hasDangerousProductsDescP2Card = {
  id: '2021-06-01--HAS_DANGEROUS_PRODUCTS_DESC',
  isSatisfied: applicationDataHas('2021-06-01--HAS_DANGEROUS_PRODUCTS_DESC'),
  shouldRender:
    applicationDataHas('2021-06-01--HAS_DANGEROUS_PRODUCTS') &&
    appDataPathDoesNotInclude('2021-06-01--HAS_DANGEROUS_PRODUCTS', noneOfTheseApply),
};

export const manufacturingStatusCard = {
  id: '2021-06-01--MANUFACTURING_STATUS',
  isSatisfied: applicationDataHas('2021-06-01--MANUFACTURING_STATUS'),
  shouldRender: primaryNicheCouldInvolveManufacturing,
};

export const manufacturingStatusDescCard = {
  id: '2021-06-01--MANUFACTURING_STATUS_DESC',
  isSatisfied: applicationDataHas('2021-06-01--MANUFACTURING_STATUS_DESC'),
  shouldRender: selectedDangerousManufacturingOption,
};

export const causedHarmCard = {
  id: '2021-06-01--CAUSED_HARM',
  isSatisfied: applicationDataHas('2021-06-01--CAUSED_HARM'),
  shouldRender: primaryNicheCouldInvolveManufacturing,
};

export const causedHarmDescCard = {
  id: '2021-06-01--CAUSED_HARM_DESC',
  isSatisfied: applicationDataHas('2021-06-01--CAUSED_HARM_DESC'),
  shouldRender: appDataPathIs('2021-06-01--CAUSED_HARM', true),
};

export const hasPhysicalRetailCard = {
  id: '2021-06-01--HAS_PHYSICAL_RETAIL',
  isSatisfied: applicationDataHas('2021-06-01--HAS_PHYSICAL_RETAIL'),
  shouldRender: primaryNicheCouldInvolveManufacturing,
};

export const mediaHasStateRegDemandsCard = {
  id: '2021-06-01--MEDIA_HAS_STATE_REG_DEMANDS',
  isSatisfied: applicationDataHas('2021-06-01--MEDIA_HAS_STATE_REG_DEMANDS'),
  shouldRender: primaryNicheIsMediaRelated,
};

export const mediaHasStateRegDemandsDescCard = {
  id: '2021-06-01--MEDIA_HAS_STATE_REG_DEMANDS_DESC',
  isSatisfied: applicationDataHas('2021-06-01--MEDIA_HAS_STATE_REG_DEMANDS_DESC'),
  shouldRender: appDataPathIs('2021-06-01--MEDIA_HAS_STATE_REG_DEMANDS', true),
};

export const mediaHasActRelatedDemandsCard = {
  id: '2021-06-01--MEDIA_HAS_ACT_RELATED_DEMANDS',
  isSatisfied: applicationDataHas('2021-06-01--MEDIA_HAS_ACT_RELATED_DEMANDS'),
  shouldRender: primaryNicheIsMediaRelated,
};

export const mediaHasActRelatedDemandsDescCard = {
  id: '2021-06-01--MEDIA_HAS_ACT_RELATED_DEMANDS_DESC',
  isSatisfied: applicationDataHas('2021-06-01--MEDIA_HAS_ACT_RELATED_DEMANDS_DESC'),
  shouldRender: appDataPathIs('2021-06-01--MEDIA_HAS_ACT_RELATED_DEMANDS', true),
};

export const mediaHasClpDemandsCard = {
  id: '2021-06-01--MEDIA_HAS_COPYRIGHT_LIBEL_PRIVACY_DEMANDS',
  isSatisfied: applicationDataHas('2021-06-01--MEDIA_HAS_COPYRIGHT_LIBEL_PRIVACY_DEMANDS'),
  shouldRender: primaryNicheIsMediaRelated,
};

export const mediaHasClpDemandsDescCard = {
  id: '2021-06-01--MEDIA_HAS_COPYRIGHT_LIBEL_PRIVACY_DEMANDS_DESC',
  isSatisfied: applicationDataHas('2021-06-01--MEDIA_HAS_COPYRIGHT_LIBEL_PRIVACY_DEMANDS_DESC'),
  shouldRender: appDataPathIs('2021-06-01--MEDIA_HAS_COPYRIGHT_LIBEL_PRIVACY_DEMANDS', true),
};

export const mediaHasCertificateBizCard = {
  id: '2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS',
  isSatisfied: applicationDataHas('2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS'),
  shouldRender: primaryNicheIsMediaRelated,
};

export const mediaHasCertificateBizDescCard = {
  id: '2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS_DESC',
  isSatisfied: applicationDataHas('2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS_DESC'),
  shouldRender: appDataPathIs('2021-06-01--MEDIA_HAS_CERTIFICATE_BUSINESS', true),
};

export const mediaHasControvMaterialCard = {
  id: '2021-06-01--MEDIA_CONTROV_MATERIAL',
  isSatisfied: applicationDataHas('2021-06-01--MEDIA_CONTROV_MATERIAL'),
  shouldRender: primaryNicheIsMediaRelated,
};

export const mediaHasControvMaterialDescCard = {
  id: '2021-06-01--MEDIA_CONTROV_MATERIAL_DESC',
  isSatisfied: applicationDataHas('2021-06-01--MEDIA_CONTROV_MATERIAL_DESC'),
  shouldRender: appDataPathIs('2021-06-01--MEDIA_CONTROV_MATERIAL', true),
};

export const mediaNumContentConsumersCard = {
  id: '2021-06-01--MEDIA_NUM_CONTENT_CONSUMERS',
  isSatisfied: applicationDataHas('2021-06-01--MEDIA_NUM_CONTENT_CONSUMERS'),
  shouldRender: primaryNicheIsMediaRelated,
};

export const mediaMakesOrSellsPgCard = {
  id: '2021-06-01--MEDIA_MAKES_OR_SELLS_PG',
  isSatisfied: applicationDataHas('2021-06-01--MEDIA_MAKES_OR_SELLS_PG'),
  shouldRender: primaryNicheIsMediaRelated,
};

export const mediaMakesOrSellsPgDescCard = {
  id: '2021-06-01--MEDIA_MAKES_OR_SELLS_PG_DESC',
  isSatisfied: applicationDataHas('2021-06-01--MEDIA_MAKES_OR_SELLS_PG_DESC'),
  shouldRender: appDataPathIs('2021-06-01--MEDIA_MAKES_OR_SELLS_PG', true),
};

export const mediaLoansOrTransfersCard = {
  id: '2021-06-01--MEDIA_LOANS_OR_TRANSFERS',
  isSatisfied: applicationDataHas('2021-06-01--MEDIA_LOANS_OR_TRANSFERS'),
  shouldRender: primaryNicheIsMediaRelated,
};

export const mediaLoansOrTransfersDescCard = {
  id: '2021-06-01--MEDIA_LOANS_OR_TRANSFERS_DESC',
  isSatisfied: applicationDataHas('2021-06-01--MEDIA_LOANS_OR_TRANSFERS_DESC'),
  shouldRender: appDataPathDoesNotInclude('2021-06-01--MEDIA_LOANS_OR_TRANSFERS', noneOfTheseApply),
};

export const transpHasContractDisputesCard = {
  id: '2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES',
  isSatisfied: applicationDataHas('2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES'),
  shouldRender: primaryNicheIsTransportRelated,
};

export const transpHasContractDisputesDescCard = {
  id: '2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES_DESC',
  isSatisfied: applicationDataHas('2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES_DESC'),
  shouldRender: appDataPathIs('2021-06-01--TRANSP_HAS_CONTRACTORS_DISPUTES', true),
};

export const transpLicensingDisputesCard = {
  id: '2021-06-01--TRANSP_LICENSING_DISPUTES',
  isSatisfied: applicationDataHas('2021-06-01--TRANSP_LICENSING_DISPUTES'),
  shouldRender: primaryNicheIsTransportRelated,
};

export const transpLicensingDisputesDescCard = {
  id: '2021-06-01--TRANSP_LICENSING_DISPUTES_DESC',
  isSatisfied: applicationDataHas('2021-06-01--TRANSP_LICENSING_DISPUTES_DESC'),
  shouldRender: appDataPathIs('2021-06-01--TRANSP_LICENSING_DISPUTES', true),
};

export const transpOwnsTransportCard = {
  id: '2021-06-01--TRANSP_OWNS_TRANSPORT',
  isSatisfied: applicationDataHas('2021-06-01--TRANSP_OWNS_TRANSPORT'),
  shouldRender: primaryNicheIsTransportRelated,
};

export const transpOwnsTransportDescCard = {
  id: '2021-06-01--TRANSP_OWNS_TRANSPORT_DESC',
  isSatisfied: applicationDataHas('2021-06-01--TRANSP_OWNS_TRANSPORT_DESC'),
  shouldRender: appDataPathIs('2021-06-01--TRANSP_OWNS_TRANSPORT', true),
};

export const psProfServicesCard = {
  id: '2021-06-01--PS_PROF_SERVICES',
  isSatisfied: applicationDataHas('2021-06-01--PS_PROF_SERVICES'),
  shouldRender: primaryNicheIsProfServicesRelated,
};

export const psProfServicesDescCard = {
  id: '2021-06-01--PS_PROF_SERVICES_DESC',
  isSatisfied: applicationDataHas('2021-06-01--PS_PROF_SERVICES_DESC'),
  shouldRender: appDataPathDoesNotInclude('2021-06-01--PS_PROF_SERVICES', noneOfTheseApply),
};

export const psDemandsMadeCard = {
  id: '2021-06-01--PS_DEMANDS_MADE',
  isSatisfied: applicationDataHas('2021-06-01--PS_DEMANDS_MADE'),
  shouldRender: primaryNicheIsProfServicesRelated,
};

export const psDemandsMadeDescCard = {
  id: '2021-06-01--PS_DEMANDS_MADE_DESC',
  isSatisfied: applicationDataHas('2021-06-01--PS_DEMANDS_MADE_DESC'),
  shouldRender: appDataPathIs('2021-06-01--PS_DEMANDS_MADE', true),
};

export const ftOperatesFintechCard = {
  id: '2021-06-01--FT_OPERATES_FINTECH',
  isSatisfied: applicationDataHas('2021-06-01--FT_OPERATES_FINTECH'),
  shouldRender: primaryNicheIsFintechRelated,
};

export const ftOperatesFintechDescCard = {
  id: '2021-06-01--FT_OPERATES_FINTECH_DESC',
  isSatisfied: applicationDataHas('2021-06-01--FT_OPERATES_FINTECH_DESC'),
  shouldRender: appDataPathDoesNotInclude('2021-06-01--FT_OPERATES_FINTECH', noneOfTheseApply),
};

export const ftHasDemandsCard = {
  id: '2021-06-01--FT_HAS_DEMANDS',
  isSatisfied: applicationDataHas('2021-06-01--FT_HAS_DEMANDS'),
  shouldRender: primaryNicheIsFintechRelated,
};

export const ftHasDemandsDescCard = {
  id: '2021-06-01--FT_HAS_DEMANDS_DESC',
  isSatisfied: applicationDataHas('2021-06-01--FT_HAS_DEMANDS_DESC'),
  shouldRender: appDataPathIs('2021-06-01--FT_HAS_DEMANDS', true),
};

export const ftGtmStrategyCard = {
  id: '2021-06-01--FT_GTM_STRATEGY',
  isSatisfied: applicationDataHas('2021-06-01--FT_GTM_STRATEGY'),
  shouldRender: primaryNicheIsFintechRelated,
};

export const ftAnnualLoansCard = {
  id: '2021-06-01--FT_ANNUAL_LOANS',
  isSatisfied: applicationDataHas('2021-06-01--FT_ANNUAL_LOANS'),
  shouldRender: appDataPathIs('2021-06-01--FT_GTM_STRATEGY', 'Other'),
};

export const ftInternationalSalesCard = {
  id: '2021-06-01--FT_INTERNATIONAL_SALES',
  isSatisfied: applicationDataHas('2021-06-01--FT_INTERNATIONAL_SALES'),
  shouldRender: primaryNicheIsFintechRelated,
};

export const ftHasBlockchainBizCard = {
  id: '2021-06-01--FT_HAS_BLOCKCHAIN_BUSINESS',
  isSatisfied: applicationDataHas('2021-06-01--FT_HAS_BLOCKCHAIN_BUSINESS'),
  shouldRender: primaryNicheIsFintechRelated,
};

export const ftLargestLoanSize = {
  id: '2021-06-01--FT_LARGEST_LOAN_SIZE',
  isSatisfied: applicationDataHas('2021-06-01--FT_LARGEST_LOAN_SIZE'),
  shouldRender: primaryNicheIsFintechRelated,
};

export const ftTotalAnnualLoansCard = {
  id: '2021-06-01--FT_TOTAL_ANNUAL_LOANS',
  isSatisfied: applicationDataHas('2021-06-01--FT_TOTAL_ANNUAL_LOANS'),
  shouldRender: primaryNicheIsFintechRelated,
};

export const ftHandlesInsuranceClaimsCard = {
  id: '2021-06-01--FT_HANDLES_INSURANCE_CLAIMS',
  isSatisfied: applicationDataHas('2021-06-01--FT_HANDLES_INSURANCE_CLAIMS'),
  shouldRender: primaryNicheIsFintechRelated,
};

export const ftIsDirectlyRegulatedCard = {
  id: '2021-06-01--FT_IS_DIRECTLY_REGULATED',
  isSatisfied: applicationDataHas('2021-06-01--FT_IS_DIRECTLY_REGULATED'),
  shouldRender: primaryNicheIsFintechRelated,
};

export const didTrialCard = {
  id: '2021-06-01--DID_TRIAL',
  isSatisfied: applicationDataHas('2021-06-01--DID_TRIAL'),
  shouldRender: primaryNicheIsMedDeviceorPharmaRelated,
};

export const didTrialDescCard = {
  id: '2021-06-01--DID_TRIAL_DESC',
  isSatisfied: applicationDataHas('2021-06-01--DID_TRIAL_DESC'),
  shouldRender: appDataPathIs('2021-06-01--DID_TRIAL', true),
};

export const willTrialCard = {
  id: '2021-06-01--WILL_TRIAL',
  isSatisfied: applicationDataHas('2021-06-01--WILL_TRIAL'),
  shouldRender: primaryNicheIsMedDeviceorPharmaRelated,
};

export const willTrialDescCard = {
  id: '2021-06-01--WILL_TRIAL_DESC',
  isSatisfied: applicationDataHas('2021-06-01--WILL_TRIAL_DESC'),
  shouldRender: appDataPathIs('2021-06-01--WILL_TRIAL', true),
};

export const pharmaWhenInMarketCard = {
  id: '2021-06-01--PHARMA_WHEN_IN_MARKET',
  isSatisfied: applicationDataHas('2021-06-01--PHARMA_WHEN_IN_MARKET'),
  shouldRender: primaryNicheIsPharmaRelated,
};

export const pharmaIsInRadioCard = {
  id: '2021-06-01--PHARMA_IS_IN_RADIO',
  isSatisfied: applicationDataHas('2021-06-01--PHARMA_IS_IN_RADIO'),
  shouldRender: primaryNicheIsPharmaRelated,
};

export const meddevSpecialDeviceCard = {
  id: '2021-06-01--MEDDEV_SPECIAL_DEVICE',
  isSatisfied: applicationDataHas('2021-06-01--MEDDEV_SPECIAL_DEVICE'),
  shouldRender: primaryNicheIsMedDeviceRelated,
};

export const meddevSpecialDeviceDescCard = {
  id: '2021-06-01--MEDDEV_SPECIAL_DEVICE_DESC',
  isSatisfied: applicationDataHas('2021-06-01--MEDDEV_SPECIAL_DEVICE_DESC'),
  shouldRender: appDataPathDoesNotInclude('2021-06-01--MEDDEV_SPECIAL_DEVICE', noneOfTheseApply),
};

export const meddevInMarketCard = {
  id: '2021-06-01--MEDDEV_IN_MARKET',
  isSatisfied: applicationDataHas('2021-06-01--MEDDEV_IN_MARKET'),
  shouldRender: primaryNicheIsMedDeviceRelated,
};

export const meddevInMarketDescCard = {
  id: '2021-06-01--MEDDEV_IN_MARKET_DESC',
  isSatisfied: applicationDataHas('2021-06-01--MEDDEV_IN_MARKET_DESC'),
  shouldRender: primaryNicheIsMedDeviceRelated,
};

export const hwHasDangerousProductsCard = {
  id: '2021-06-01--HW_HAS_DANGEROUS_PRODUCTS',
  isSatisfied: applicationDataHas('2021-06-01--HW_HAS_DANGEROUS_PRODUCTS'),
  shouldRender: primaryNicheIsHwRelated,
};

export const epriseAdviceReportingCard = {
  id: '2021-06-01--EPRISE_ADVICE_REPORTING',
  isSatisfied: applicationDataHas('2021-06-01--EPRISE_ADVICE_REPORTING'),
  shouldRender: primaryNicheIsEnterpriseRelated,
};

export const epriseAdviceReportingDescCard = {
  id: '2021-06-01--EPRISE_ADVICE_REPORTING_DESC',
  isSatisfied: applicationDataHas('2021-06-01--EPRISE_ADVICE_REPORTING_DESC'),
  shouldRender: appDataPathDoesNotInclude('2021-06-01--EPRISE_ADVICE_REPORTING', noneOfTheseApply),
};

export const epriseDemandsMadeCard = {
  id: '2021-06-01--EPRISE_DEMANDS_MADE',
  isSatisfied: applicationDataHas('2021-06-01--EPRISE_DEMANDS_MADE'),
  shouldRender: primaryNicheIsEnterpriseRelated,
};

export const epriseDemandsMadeDescCard = {
  id: '2021-06-01--EPRISE_DEMANDS_MADE_DESC',
  isSatisfied: applicationDataHas('2021-06-01--EPRISE_DEMANDS_MADE_DESC'),
  shouldRender: appDataPathIs('2021-06-01--EPRISE_DEMANDS_MADE', true),
};

export const epriseSpecialCategoryCard = {
  id: '2021-06-01--EPRISE_SPECIAL_CATEGORY',
  isSatisfied: applicationDataHas('2021-06-01--EPRISE_SPECIAL_CATEGORY'),
  shouldRender: primaryNicheIsEnterpriseRelated,
};

export const epriseExpectedIntlRevenueCard = {
  id: '2021-06-01--EPRISE_EXPECTED_INTL_REVENUE',
  isSatisfied: applicationDataHas('2021-06-01--EPRISE_EXPECTED_INTL_REVENUE'),
  shouldRender: primaryNicheIsEnterpriseRelated,
};

export const epriseLoanServicesCard = {
  id: '2021-06-01--EPRISE_LOAN_SERVICES',
  isSatisfied: applicationDataHas('2021-06-01--EPRISE_LOAN_SERVICES'),
  shouldRender: primaryNicheIsEnterpriseRelated,
};

export const epriseLoanServicesDescCard = {
  id: '2021-06-01--EPRISE_LOAN_SERVICES_DESC',
  isSatisfied: applicationDataHas('2021-06-01--EPRISE_LOAN_SERVICES_DESC'),
  shouldRender: appDataPathDoesNotInclude('2021-06-01--EPRISE_LOAN_SERVICES', noneOfTheseApply),
};

export const ftInvestmentServicesActivities = {
  id: '2022-12-15--FT_INVESTMENT_SERVICES_ACTIVITIES',
  isSatisfied: applicationDataHas('2022-12-15--FT_INVESTMENT_SERVICES_ACTIVITIES'),
  shouldRender: appDataPathIncludesAny('2021-06-01--FT_OPERATES_FINTECH', [
    'Securities trading activities',
    'Any financial services directly to consumers or businesses that typically require material specialization and training or a designation and/or specialized license (such as a banking license, insurance agent license or FINRA designation)',
    'Investment advice related to real estate',
  ]),
};
