import { set } from '../../../shared/lib/vouch_dash';
import { FormFields } from '@/onboarding/types/internal/fieldsTypes';

export const reduceFormFields = (formFields: FormFields) =>
  formFields.reduce((map, formField) => {
    set(map, formField.dataPath, formField.value);
    return map;
  }, {});

export default {
  arrayMarshall: ({ formFields }: { formFields: FormFields }) => reduceFormFields(formFields),
};
