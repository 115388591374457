<template>
  <div class="layout-title-page">
    <BasicHeader :white-header="whiteHeader">
      <template
        v-if="$scopedSlots['banner-content']"
        v-slot:banner-content
      >
        <slot name="banner-content" />
      </template>
    </BasicHeader>
    <div class="layout-title-page-hero">
      <CenteredContainer>
        <div
          v-if="$scopedSlots['back-button']"
          class="back-button-container"
        >
          <slot name="back-button" />
        </div>
        <h1 class="header">
          {{ title }}
        </h1>
        <div class="subheader">
          {{ subtitle }}
        </div>
      </CenteredContainer>
    </div>
    <div class="layout-title-page-body">
      <CenteredContainer>
        <slot />
      </CenteredContainer>
    </div>
  </div>
</template>

<script>
import BasicHeader from '@/onboarding/views/BasicHeader';
import CenteredContainer from '@/shared/components/layouts/CenteredContainer';

export default {
  name: 'LayoutTitlePage',
  components: { CenteredContainer, BasicHeader },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    whiteHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-title-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $page-background-color;

  height: 100%;

  .layout-title-page-hero {
    padding-top: $space-xlarge;
    text-align: center;

    background-color: $page-background-color;
    position: relative;

    .header {
      @include h2-text;
    }

    .subheader {
      margin-top: $space-medium;

      @include page-subtitle-text;
    }

    .back-button-container {
      position: absolute;
      top: 0;
    }
  }

  .layout-title-page-body {
    margin-top: $space-xlarge;
    flex-grow: 1;

    position: relative;
  }
}
</style>
