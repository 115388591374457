<template>
  <v-btn
    v-bind="$attrs"
    :class="'vouch-button'"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<style lang="scss" scoped>
.vouch-button {
  margin: 0;

  transition: color $easing-timing, background-color $easing-timing;

  letter-spacing: 1px;
}

.vouch-button.vouch-button.vouch-button.vouch-button {
  @include button-text;

  padding: 0 $space-large;
  height: $button-height;
  box-shadow: none;
  border-radius: $button-corner;

  &::before,
  &::after,
  &::before,
  &::after {
    background-color: transparent;
  }

  &:focus {
    border-style: solid;
    border-color: white !important;
    border-width: thin;
    outline: none !important;
    box-shadow: 0 0 8px $button-box-shadow-color--focused !important;
  }

  &.vouch-button:disabled {
    background-color: $button-background-color--disabled !important;
    color: $button-text-color !important;
  }
}
</style>

<script>
export default {
  name: 'ButtonBase',
};
</script>
