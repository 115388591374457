<template>
  <v-app>
    <v-main>
      <VouchNotification />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { getCardId } from '@/shared/routing/getCardId';
import VouchNotification from '@/shared/components/ui/molecules/VouchNotification';

export default {
  name: 'App',
  dependencies: ['tracking', 'featureFlags'],
  components: { VouchNotification },
  provide() {
    return {
      featureFlags: this.featureFlags,
    };
  },
  computed: {
    cardId() {
      return getCardId(this.$route);
    },
  },
  watch: {
    '$route.path': {
      handler() {
        this.tracking.onPageChange();
      },
    },
  },
  created() {
    window.session = Math.random();
  },
};
</script>

<style lang="scss">
::selection {
  background-color: $text-selection-color;
  // note that in development this rule sometimes doesn't seem to register
  // why? I have no idea, but it seems to change between subsequent hot-module reloads
}

*:focus {
  outline: medium solid $focus-outline-color;
}

body {
  background-color: $white-background-color;
}

#app {
  .tooltip-text {
    max-width: $tooltip-max-width;
    padding: $space-small;
  }

  // remove ios banana yellow autofill
  // https://stackoverflow.com/a/32505530
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.application {
  font-family: $sans-font-family !important;
}

.material-icons.vouchGreen {
  color: $vouch-primary-green;
}

.material-icons.svbBlue {
  color: $svb-emphasis-text-color;
}
</style>
