<template>
  <TransitionFade
    v-if="shouldTransition"
  >
    <slot />
  </TransitionFade>
  <div v-else>
    <slot />
  </div>
</template>

<script>
import TransitionFade from '@/shared/components/ui/atoms/TransitionFade';
import featureFlagging from '@/onboarding/services/FeatureFlaggingService';

export default {
  name: 'StatefulTransitionFade',
  components: { TransitionFade },
  computed: {
    shouldTransition() {
      return featureFlagging.getFlag({ flag: 'allow-transitions' });
    },
  },
};
</script>
