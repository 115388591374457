/* istanbul ignore file */
// this file is strictly configuration, and aligns with the 'humble object' pattern, so testing isn't necessary.

import QuestionRouter from '@/shared/routing/QuestionRouter';
import { routingDecisions } from '@/members/services/routingDecisions';
import { routingDecisionsProgram2 } from '@/members/services/routingDecisionsProgram2';
import { renewalUrl, renewalUrlP2 } from '@/members/router/routeHelper';
import { isWindowProgram2 } from '@/onboarding/lib/selectors/storeSelectors';

const finalCard = () => 'package';
const abortCard = () => 'fin';
const onErrorCard = () => 'renewal-start';
const cancelDiscretionCard = () => 'discretion-quote';

export default (isProgram2 = isWindowProgram2()) => {
  return new QuestionRouter({
    decisions: isProgram2 ? routingDecisionsProgram2 : routingDecisions,
    finalCard,
    abortCard,
    onErrorCard,
    cancelDiscretionCard,
    urlFormatter: isProgram2 ? renewalUrlP2 : renewalUrl,
  });
};
