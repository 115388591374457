<template>
  <div>
    <FormFieldText
      :name="propertyName"
      :value="inputValue"
      :error="isValid === false"
      :error-messages="errorMessage"
      :placeholder="propertyDefinition.examples[0]"
      @input="setInputValue"
    />
    <v-row
      v-show="isValid !== false"
      no-gutters
    >
      <v-col cols="12">
        <div
          v-show="!hidePreviousAnswer"
          class="spacing"
        >
          <PreviousAnswer :value="inputParentValue" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseSchemaFormField from './BaseSchemaFormField';
import FormFieldText from '@/shared/components/ui/atoms/form_fields/FormFieldText';
import PreviousAnswer from '@/shared/components/wizard/PreviousAnswer';

export default {
  name: 'SchemaFormFieldText',
  components: { FormFieldText, PreviousAnswer },
  extends: BaseSchemaFormField,
};
</script>

<style lang="scss" scoped>
.spacing {
  padding: $space-xsmall 0 $space-xsmall;
}
</style>
