<template>
  <GenericStatusPage
    :image="image"
    :title="title"
  >
    <template v-slot:description-content>
      This page does not exist.
    </template>
  </GenericStatusPage>
</template>

<script>
import GenericStatusPage from '@/shared/views/layouts/GenericStatusPage';
import bigLogo from '@/shared/assets/images/big-logo.png';

export default {
  name: 'CrumbsPage',
  components: { GenericStatusPage },
  data() {
    return {
      decisionData: { decision: null },
      title: 'Crumbs.',
      image: bigLogo,
    };
  },
};
</script>
