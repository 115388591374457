<template>
  <span
    @mouseenter="showHover"
    @mousedown="showClick"
    @mouseleave="delayHide"
  >
    <slot name="trigger" />
    <v-tooltip
      v-model="showTip"
      :bottom="!isMobileScreen"
      :top="isMobileScreen"
      :position-x="-100"
      :max-width="300"
    >
      <template v-slot:activator="{ on }">
        <div
          class="tool"
          v-on="on"
        />
      </template>
      <span
        @mouseenter="show"
        @mouseleave="delayHide"
      >
        <div
          class="tooltip-text"
        >
          <slot name="text" />
        </div>
      </span>
    </v-tooltip>
  </span>
</template>

<script>
import { debounce } from 'lodash';
import { mobileViewportDimensions } from '@/onboarding/constants/Constants.ts';

export default {
  name: 'Tooltip',
  props: {
    clickToShow: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      showTip: false,
      delay: 0,
      hiding: null,
    };
  },
  computed: {
    isMobileScreen() {
      // To avoid overlapping the Zendesk widget, position the tooltip above its anchor on mobile devices
      return (
        this.$root.$el?.getBoundingClientRect().height <= mobileViewportDimensions.iPhoneXR.height
      );
    },
  },
  methods: {
    show() {
      this.showTip = true;
      if (this.hiding) {
        this.hiding.cancel();
        this.hiding = null;
      }
    },
    showClick() {
      if (this.clickToShow) {
        this.show();
      }
    },
    showHover() {
      if (!this.clickToShow) {
        this.show();
      }
    },
    hide() {
      this.showTip = false;
    },
    delayHide() {
      this.hiding = debounce(this.hide, 800);
      this.hiding();
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-text {
  padding: $space-small $space-small $space-large;
  cursor: default;
  span:last-child {
    cursor: pointer;
    text-decoration: underline;
  }
}
.tool {
  position: relative;
  left: $space-xlarge;
}
.v-tooltip__content {
  // using !important gives me anxiety
  background: $tooltip-background-color !important;
  opacity: 1 !important;
}
</style>
