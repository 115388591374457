<template>
  <WizardCardForm
    :loading="loading"
    :card-data="cardData"
    :is-submit-disabled="isSubmitDisabled"
    :submit-text="submitText"
    @submit="emitSubmit"
  >
    <slot />
  </WizardCardForm>
</template>

<script>
import WizardCardForm from '@/shared/components/wizard/WizardCardForm';
import { FUNNEL_CARD_COMPLETED } from '@/onboarding/services/SegmentEventTypes';

export default {
  name: 'BaseWizardPage',
  components: {
    WizardCardForm,
  },
  dependencies: ['tracking'],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cardData: {
      type: Object,
      required: true,
    },
    isSubmitDisabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    submitText: {
      type: String,
      required: false,
      default: 'Next',
    },
  },
  methods: {
    emitSubmit() {
      this.tracking.sendEvent(FUNNEL_CARD_COMPLETED);
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
}
</style>
