<template>
  <Tooltip v-if="showTaxesAndFees">
    <template slot="trigger">
      <VouchIcon name="help" />
    </template>
    <div
      slot="text"
      class="taxes-and-fees"
    >
      <div class="taxes-and-fees-item">
        <h4>Taxes: {{ taxes | longCurrency }}</h4>
        <span class="required-text">State required.</span>
      </div>

      <br>

      <div class="taxes-and-fees-item">
        <h4>Fees: {{ fees | longCurrency }}</h4>
        <span class="required-text">State required.</span>
      </div>

      <br>

      <span
        class="state-required-text"
      >Only required when purchasing certain coverages in your state.</span>
    </div>
  </Tooltip>
  <Tooltip
    v-else
    class="info-icon"
  >
    <template slot="trigger">
      <VouchIcon name="help" />
    </template>
    <div slot="text">
      <span
        class="state-required-text"
      >Only required when purchasing certain coverages in your state.</span>
    </div>
  </Tooltip>
</template>

<script>
import { longCurrency } from '@/shared/lib/filters/currency_filters';
import Tooltip from '@/onboarding/components/Tooltip';
import VouchIcon from '@/shared/components/ui/atoms/icons/VouchIcon';

export default {
  name: 'CheckoutFormTaxesTooltip',
  components: {
    Tooltip,
    VouchIcon,
  },
  filters: { longCurrency },
  props: {
    showTaxesAndFees: {
      type: Boolean,
      required: true,
      default: false,
    },
    taxes: {
      type: Number,
      required: false,
      default: 0,
    },
    fees: {
      type: Number,
      required: false,
      default: 0,
    },
  },
};
</script>
