<template>
  <PageBannerPromotion
    class="brex-10k-points-banner"
    data-testid="banner-brex-10k"
    :small="small"
    :white-text="false"
    title="BREX OFFER AVAILABLE"
    subtitle="Get up to a 5% discount and 10K Brex Points by using your Brex card at checkout."
  />
</template>

<script>
import PageBannerPromotion from '@/onboarding/components/page_banners/PageBannerPromotion';

export default {
  name: 'Brex10kPointsBanner',
  components: { PageBannerPromotion },
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.brex-10k-points-banner {
  background-image: $brex-background-gradient;
  animation-duration: $banner-animation-duration;
}
</style>
