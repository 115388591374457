<template>
  <BaseWizardPage
    :card-data="cardData"
    :is-submit-disabled="false"
    :has-back-button="hasBackButton"
    :submit-text="submitText"
    @submit="onSubmit"
  />
</template>

<script>
import BaseCard from './BaseCard';
import BaseWizardPage from '@/shared/components/wizard/BaseWizardPage';

/**
 * Extend from this class to create a card that is non-interactive, i.e. doesn't rely on schema data from the backend
 */
export default {
  name: 'BaseCardStatic',
  components: { BaseWizardPage },
  extends: BaseCard,
  data() {
    return { hasBackButton: true, submitText: 'Next' };
  },
  methods: {
    onSubmit() {
      this.questionRouter.next(this);
    },
  },
};
</script>
