<template>
  <v-stepper-content
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-stepper-content>
</template>

<script>
export default {
  name: 'StepperContent',
};
</script>
