import { sendToReactQuotePageWithId } from '@/onboarding/router/routeHelper';

export const redirectAwayOnRouteChange = (redirectTo, urlFormatter) => ({
  $store,
  next,
  nextRoute,
  applicationId,
  previousRoute,
}) => {
  const redirectCardId = redirectTo({ $store, route: nextRoute });
  if (previousRoute === undefined) {
    next();
  } else if (redirectCardId) {
    if (redirectCardId === 'package') sendToReactQuotePageWithId(applicationId);
    const newUrl = urlFormatter({ cardId: redirectCardId, applicationId });
    next({ path: newUrl });
  } else {
    next();
  }
};

export const redirectAwayOnPageLoad = (redirectTo, urlFormatter) => ({
  $store,
  $route,
  $router,
  applicationId,
}) => {
  const redirectCardId = redirectTo({ $store, route: $route });
  if (redirectCardId) {
    if (redirectCardId === 'package') sendToReactQuotePageWithId(applicationId);
    const newUrl = urlFormatter({ cardId: redirectCardId, applicationId });
    $router.push({ path: newUrl });
  }
};
