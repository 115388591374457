<template>
  <div class="carta-data-share-opt-in">
    <PageBannerPromotion
      class="carta-banner"
      data-testid="carta-banner"
      title="CARTA OFFER AVAILABLE"
      subtitle="Save up to 5% on your business insurance policies."
      :white-text="false"
    />
    <WizardPageWrapper
      header-text="Get Your Quote"
      :minutes-left="0.1"
      :progress="99"
      :has-back-button="true"
    >
      <template v-slot:left-panel>
        <OnboardingNavigationBar
          class="nav-bar"
          :current-nav-step="currentNavStep"
        />
      </template>
      <template>
        <div class="carta-card-body">
          <h2>Opt-in to data sharing between Carta and Vouch to get 10% off</h2>
          <p class="text--secondary">
            Vouch may use this data to proactively help you manage risk as your business evolves.
            Vouch protects your information in accordance with its Privacy Notice at
            <a
              target="_blank"
              href="https://www.vouch.us/privacy"
              @click.stop
            >
              vouch.us/privacy.
            </a>
          </p>
          <v-form
            v-if="!connectionSuccess"
            ref="form"
            v-model="valid"
          >
            <v-checkbox
              v-model="cartaAcceptedDataShare"
              data-testid="carta-terms-checkbox"
            >
              <div
                slot="label"
                class="label"
              >
                <div class="carta-vouch-terms">
                  By clicking "Connect with Carta" you authorize Carta and Vouch to share data with
                  each other to provide you with, and inform you of, products and services. You may
                  opt out at any time by emailing
                  <a
                    target="_blank"
                    href="mailto:hello@vouch.us"
                    @click.stop
                  >hello@vouch.us</a>.
                </div>
              </div>
            </v-checkbox>
            <div class="mt-5">
              <ButtonPrimary
                data-testid="submit-carta-data"
                :loading="loading"
                :disabled="!cartaAcceptedDataShare"
                class="connect-page-button"
                @click="submitCartaData"
              >
                Connect With Carta
              </ButtonPrimary>
              <ButtonText
                class="connect-page-button css-magic"
                @click="continueToReview"
              >
                X Skip
              </ButtonText>
            </div>
          </v-form>
        </div>
      </template>
    </WizardPageWrapper>
  </div>
</template>

<script>
import { onboardingUrl } from '@/onboarding/router/routeHelper';
import WizardPageWrapper from '@/shared/components/wizard/WizardPageWrapper';
import OnboardingNavigationBar from '@/onboarding/components/wizard/OnboardingNavigationBar';
import { FunnelNavSteps } from '@/onboarding/constants/Constants';
import ButtonText from '@/shared/components/ui/atoms/buttons/ButtonText';
import ButtonPrimary from '@/shared/components/ui/atoms/buttons/ButtonPrimary';
import PageBannerPromotion from '@/onboarding/components/page_banners/PageBannerPromotion';

export default {
  name: 'CartaDataShareAgreement',
  components: {
    OnboardingNavigationBar,
    WizardPageWrapper,
    ButtonText,
    ButtonPrimary,
    PageBannerPromotion,
  },
  dependencies: ['requests'],
  props: {
    applicationId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      cartaAcceptedDataShare: false,
      valid: true,
      apiError: false,
      errorMsg: null,
      connectionSuccess: false, // holdover from svb
      loading: false,
      currentNavStep: FunnelNavSteps.CORE,
    };
  },
  methods: {
    continueToReview() {
      this.$router.push({
        path: onboardingUrl({
          cardId: 'review',
          applicationId: this.applicationId,
        }),
      });
    },
    submitCartaData() {
      this.loading = true;
      this.apiError = false;
      this.errorMsg = null;

      if (this.cartaAcceptedDataShare) {
        this.requests
          .postDataShareAgreementData({
            data: {
              application_id: this.applicationId,
              partner_name: 'carta',
            },
            onSuccess: () => {
              this.requestAmountComplete = 100;
              this.connectionSuccess = true;
            },
            onError: response_data => {
              this.requestAmountComplete = 99;
              this.errorMsg = this.getDisplayedErrorText(response_data.response.data);
              this.apiError = true;
            },
          })
          .then(() => {
            this.loading = false;
            this.continueToReview();
          });
      } else {
        this.continueToReview();
      }
    },
    getDisplayedErrorText(response_code) {
      return 'There was an error accepting Carta Data Share terms. Please try again or skip this step.';
    },
  },
};
</script>

<style lang="scss" scoped>
.carta-data-share-opt-in {
  .carta-banner {
    background-image: none;
    background-color: $vouch-accent-orange;
  }
  .carta-card-body {
    h2 {
      margin-top: 56px;
      font-size: 28px;
      margin-bottom: 48px;
    }

    .carta-vouch-terms {
      @include detail-text;
      text-transform: none;
    }
  }
}
</style>
