<template>
  <div>
    <slot :formFields="formFields" />
  </div>
</template>
<script>
import {
  applicationIsProgram2,
  getApplicationData,
} from '@/onboarding/lib/selectors/storeSelectors';

export default {
  name: 'WithFormFields',
  dependencies: ['SchemaServiceFactory', 'FunnelCardSchemaHelper'],
  props: {
    formFieldData: {
      type: Array,
      required: true,
    },
    schemaPath: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    formFields() {
      return this.$store.getters.getFieldData(this.index);
    },
    isProgram2() {
      return applicationIsProgram2(this);
    },
  },
  watch: {
    formFieldData: {
      handler: 'updateFieldData',
      immediate: true,
    },
  },
  methods: {
    async getNewFieldData() {
      const schemaService = await this.SchemaServiceFactory.getService(
        this.schemaPath,
        this.isProgram2
      );
      const schemaDataHelper = new this.FunnelCardSchemaHelper(
        schemaService,
        getApplicationData(this)
      );

      return schemaDataHelper.marshallCardFields(this.formFieldData);
    },
    async updateFieldData() {
      const fieldData = await this.getNewFieldData();
      this.$store.commit('updateFieldData', { fieldData, index: this.index });
    },
  },
};
</script>
