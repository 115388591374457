<template>
  <div class="vouch-logo-svg" />
</template>

<script>
export default {
  name: 'VouchLogoSVG',
};
</script>

<style lang="scss" scoped>
.vouch-logo-svg {
  background: url('~@/shared/assets/images/Vouch-Favicon-Transparent.svg?file-loader') no-repeat;
  width: $vouch-logo-dimension;
  height: $vouch-logo-dimension;
  display: inline-block;
}
</style>
