import Colors from '@/Colors';

export default {
  primary: Colors.primaryGreen,
  default: Colors.primaryLight,
  mint: Colors.primaryMint,
  anchor: Colors.primaryGreenForLightBG,
  svbBlue: Colors.vuetifySvbColor,
  error: Colors.errorColor,
  warning: Colors.warningColor,
  disabled: Colors.vuetifyDisabledColor,
  black: Colors.vuetifyBlackColor,
};
