import Vue from 'vue';
import requests from '@/shared/services/requests';
import featureFlagging from '@/onboarding/services/FeatureFlaggingService';
import { CompanyUser } from '@/members/store/modules/companyUserTypes';

const initialState = {
  applications: [],
  applicationData: {
    id: '',
  },
  company: {
    id: '',
    users: [],
    selectedUserId: '',
  },
};

export const getters = {
  companyId: (state): string => state.company.id,
  companyUsers: (state): Array<CompanyUser> => state.company.users,
  // Getters are called before state is initalized. Silly Vue.
  selectedUser: (state): object =>
    state.company?.users?.length > 0 && state.company.selectedUserId?.length > 0
      ? state.company.users.find(({ id }) => id === state.company.selectedUserId)
      : {},
};

const isUsersPageEnabled = () => featureFlagging.getFlag({ flag: 'member-portal-users-page' });

export const actions = {
  async initMemberData({ dispatch }) {
    return dispatch('fetchMemberData').then(fetchedMemberData => {
      return isUsersPageEnabled() ? dispatch('fetchCompanyUsers') : fetchedMemberData;
    });
  },

  async fetchMemberData({ commit }) {
    // @ts-ignore
    const { data } = await requests.getMemberData();
    commit({
      type: 'updateMemberData',
      applicationData: data.applicationData,
      applications: data.applications,
      company: data.company,
    });
  },

  async fetchCompanyUsers({ commit, getters }) {
    const { companyId } = getters;
    return requests.getCompanyUsers({ companyId }).then(data => {
      commit({
        type: 'updateCompanyUsers',
        users: data.users,
      });
    });
  },

  async inviteCompanyUser({ commit, getters }, invitee: CompanyUser) {
    const { companyId } = getters;
    return requests
      .inviteCompanyUser({
        companyId,
        invitee,
      })
      .then(data => {
        commit({
          type: 'updateCompanyUsers',
          users: data.users,
        });
      });
  },

  async activateInvitedCompanyUser({ commit, getters }, email: string) {
    const { companyId } = getters;
    return requests
      .activateCompanyUser({
        companyId,
        email,
      })
      .then(data =>
        commit({
          type: 'updateCompanyUsers',
          users: data.users,
        })
      );
  },

  async editCompanyUser({ commit, getters }, editedUser: CompanyUser) {
    const { companyId } = getters;
    return requests
      .editCompanyUser({
        companyId,
        editedUser,
      })
      .then(data =>
        commit({
          type: 'updateCompanyUsers',
          users: data.users,
        })
      );
  },

  async removeCompanyUser({ commit, getters }, exUser: CompanyUser) {
    const { companyId } = getters;
    return requests
      .removeCompanyUser({
        companyId,
        userId: exUser.id,
      })
      .then(data =>
        commit({
          type: 'updateCompanyUsers',
          users: data.users,
        })
      );
  },

  setSelectedUser({ commit }, selectedUserId) {
    commit({
      type: 'selectCompanyUser',
      userId: selectedUserId,
    });
  },

  unsetSelectedUser({ commit }) {
    commit({
      type: 'deselectCompanyUser',
    });
  },
};

export const mutations = {
  selectCompanyUser: (state, { userId }) => {
    Vue.set(state.company, 'selectedUserId', userId);
  },
  deselectCompanyUser: state => {
    Vue.set(state.company, 'selectedUserId', {});
  },
  updateMemberData: (state, { applicationData, applications, company }) => {
    Vue.set(state, 'applicationData', applicationData);
    Vue.set(state, 'applications', applications);
    Vue.set(state, 'company', company);
  },
  updateCompanyUsers: (state, { users }) => {
    Vue.set(state.company, 'users', users);
  },
};

export default {
  state: initialState,
  getters,
  actions,
  mutations,
};
