<template>
  <CardDataSync
    :submit-path="submitPath"
    :schema-path="schemaPath"
    :card-config-path="cardConfigPath"
    :form-field-data="formFieldData"
    :application-id="applicationId"
    :update-fn="updateFn"
    :on-submit-success="onSubmitSuccess"
    :on-submit-error="onSubmitError"
  >
    <template slot-scope="{ onSubmit, isValidForm, formFields, onInputChange, cardData }">
      <v-progress-linear
        v-if="isLoading"
        color="black"
        :indeterminate="true"
      />
      <v-alert
        v-if="hasError"
        :value="true"
        color="error"
        :dismissible="true"
        transition="fade-transition"
      >
        Something has gone wrong. Please talk with an adviser for help.
      </v-alert>
      <BaseWizardPage
        :loading="isLoading"
        :card-data="cardData"
        :is-submit-disabled="hasReviewErrors || !isValidForm"
        :has-back-button="true"
        :fill-height="false"
        submit-text="Submit"
        @submit="submitReview(onSubmit)"
      >
        <CardFields
          :form-fields="formFields"
          :on-input-change="onInputChange"
        />
      </BaseWizardPage>
    </template>
  </CardDataSync>
</template>

<script>
import BaseCard from '@/shared/components/wizard/BaseCard';
import SchemaFormFieldText from '@/onboarding/components/formfields/SchemaFormFieldText';
import SchemaFormFieldAutocomplete from '@/onboarding/components/formfields/SchemaFormFieldAutocomplete';
import CardFields from '@/shared/views/wizard/core/shared/CardFields';
import { getSchemaEndpoint, getUpdateEndpoint } from '@/onboarding/lib/selectors/apiPathSelectors';
import CardDataSync from '@/shared/components/wizard/CardDataSync';
import selectors from '@/onboarding/lib/selectors/decisionSelectors';
import { getDecisionData } from '@/onboarding/lib/selectors/storeSelectors';
import { mapActions, mapMutations } from 'vuex';
import { sendToReactQuotePage, setUserHasBeenOnReview } from '@/onboarding/router/routeHelper';
import BaseWizardPage from '@/shared/components/wizard/BaseWizardPage';
import { CORE_DECISION, REVIEW_PAGE_ERRORED } from '@/onboarding/services/SegmentEventTypes';

export default {
  name: 'ApplicantInformationCard',
  components: {
    BaseWizardPage,
    CardFields,
    CardDataSync,
  },
  extends: BaseCard,
  dependencies: ['tracking', 'requests'],

  data() {
    return {
      isLoading: false,
      shouldAlert: false,
      hasError: false,
      schemaPath: getSchemaEndpoint('review'),
      submitPath: getUpdateEndpoint('review'),
      cardConfigPath: '2019-07-01--REVIEW_CONTAINER',
      formFieldData: [
        {
          component: SchemaFormFieldText,
          dataPath: '2019-07-01--REVIEW_CONTAINER.2019-07-01--APPLICANT_FULLNAME',
          schemaPath: '2019-07-01--APPLICANT_FULLNAME',
        },
        {
          component: SchemaFormFieldAutocomplete,
          dataPath: '2019-07-01--REVIEW_CONTAINER.2019-07-01--APPLICANT_RELATIONSHIP_TO_COMPANY',
          schemaPath: '2019-07-01--APPLICANT_RELATIONSHIP_TO_COMPANY',
        },
      ],
    };
  },
  computed: {
    hasReviewErrors() {
      return this.$store.getters.hasReviewErrors;
    },
    decisionData() {
      return getDecisionData(this);
    },
  },
  created() {
    setUserHasBeenOnReview(this);
  },
  methods: {
    ...mapActions(['fetchDecisionData', 'fetchPackagesData']),
    ...mapMutations(['updateDecisionData', 'updateApplicationData']),
    updateFn(args) {
      this.requests.finalizeRenewals({ ...args, context: this });
    },
    submitReview(onSubmit) {
      this.isLoading = true;
      this.hasError = false;
      onSubmit();
    },
    onSubmitError(data) {
      this.hasError = true;
      this.isLoading = false;
      this.tracking.sendEvent(REVIEW_PAGE_ERRORED, { applicationData: this.applicationData });
    },
    async onSubmitSuccess(applicationData) {
      this.updateApplicationData({ applicationData });
      await this.fetchDecisionData();
      await this.fetchPackagesData();
      this.isLoading = false;
      this.tracking.sendEvent(CORE_DECISION, {
        decision: this.decisionData.decision,
        applicationId: this.applicationId,
      });

      if (selectors.isYesDecision(this.decisionData)) {
        sendToReactQuotePage();
      } else {
        this.questionRouter.abort(this);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.review-fields {
  margin-bottom: $space-xlarge;
}
</style>
