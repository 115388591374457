import { getApplicationData } from '../lib/selectors/storeSelectors';
import { ApplicationData } from '../types/internal/applicationTypes';
import { newFunnelEnabled, routeToNewFunnel, newRenewalFunnelEnabled } from './newFunnelHelper';

export const isNewFunnelApplication = (application: ApplicationData): boolean => {
  return application?.intake_application === true;
};

/**
 * Used to bucket users into the old or new funnel experience after they have
 * created an application.
 */
export const checkAppForNewOrOldFunnel = async vm => {
  const isNewFunnelEnabled = await newFunnelEnabled();
  if (!isNewFunnelEnabled) return;

  const applicationData = getApplicationData(vm);

  if (isNewFunnelApplication(applicationData)) {
    routeToNewFunnel(applicationData.id);
  }
};

/**
 * Used to bucket users into the old or new funnel renewal experience after they have
 * created an application.
 */
export const checkAppForRenewalNewOrOldFunnel = async vm => {
  const isNewRenewalFunnelEnabled = await newRenewalFunnelEnabled();
  if (!isNewRenewalFunnelEnabled) return false;

  const applicationData = getApplicationData(vm);

  if (isNewFunnelApplication(applicationData)) {
    routeToNewFunnel(applicationData.id);
    return true;
  }
  return false;
};
