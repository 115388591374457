<template>
  <LayoutCenteredCard :has-back-button="false">
    <v-row no-gutters>
      <v-col>
        <v-form
          id="madlibForm"
          ref="madlibForm"
          v-model="isFormValid"
          @submit.prevent="executeRecaptcha"
        >
          <MadlibSingle
            v-if="isProgressiveFlagLoaded && !isProgressiveDisclosureTestActive"
            ref="madlibInputs"
            :form-data="formData"
            :is-email-field-disabled="isEmailFieldDisabled"
            :user-email="userEmail"
            @inputChange="onInputValueChange"
            @formSubmit="onFormSubmit"
          />
          <MadlibProgressive
            v-if="isProgressiveFlagLoaded && isProgressiveDisclosureTestActive"
            ref="madlibInputs"
            :form-data="formData"
            :is-email-field-disabled="isEmailFieldDisabled"
            :user-email="userEmail"
            @inputChange="onInputValueChange"
            @formSubmit="onFormSubmit"
          />
          <div>
            <Recaptcha
              ref="recaptcha"
              @verify="onRecaptchaCallback"
            />
          </div>
          <v-alert
            v-if="submissionError"
            text
            dense
            type="error"
            class="form-error"
          >
            The application failed to submit. Please try again.
          </v-alert>
        </v-form>
      </v-col>
      <v-col
        v-if="showGraphic"
        cols="4"
        class="graphic-container"
      >
        <div class="madlib-graphic" />
      </v-col>
    </v-row>
  </LayoutCenteredCard>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import requests from '@/shared/services/requests';
import LayoutCenteredCard from '@/shared/components/layouts/LayoutCenteredCard';
import Recaptcha from '@/onboarding/views/madlib/Recaptcha';
import { get } from '@/shared/lib/vouch_dash';
import { ApplicationVersions } from '@/onboarding/types/internal/applicationTypes.ts';
import { getPreCoreDecisionData } from '@/onboarding/lib/selectors/storeSelectors';
import {
  MADLIB_COMPLETE,
  MADLIB_DECISION,
  MADLIB_SIGNUP_WIDGET_VIEWED,
  MADLIB_LOADED,
} from '@/onboarding/services/SegmentEventTypes';
import { onboardingUrl, withCurrentQueryParams } from '@/onboarding/router/routeHelper';
import selectors from '../../lib/selectors/decisionSelectors';
import { validateEmailAddress } from '@/shared/services/validateEmailAddress';
import madlibApi from '@/onboarding/views/madlib/madlibApi';
import { stateNameMap } from '@/onboarding/lib/selectors/addressSelectors';
import { checkAuthentication } from '@/shared/vue-plugins/auth0';
import MadlibSingle from '@/onboarding/views/madlib/MadlibSingle.vue';
import MadlibProgressive from '@/onboarding/views/madlib/MadlibProgressive.vue';
import { States } from '@/onboarding/constants/Constants';
import { getProgressiveDisclosureExperiment } from '@/onboarding/services/progressiveDisclosureExperiment';
import { newFunnelEnabled, routeToNewFunnel } from '@/onboarding/router/newFunnelHelper';
import { isNewFunnelApplication } from '@/onboarding/router/newVsOldFunnelGuards';

// Map to schema field names
const LEGACY_FIELD_NAME_MAP = {
  userFirstName: 'LEAD_FIRST_NAME',
  userLastName: 'LEAD_LAST_NAME',
  userJobTitle: 'LEAD_JOB_TITLE',
  userJobTitleOther: 'LEAD_JOB_TITLE_OTHER',
  userEmail: '2019-07-01--EMAIL',
  companyName: '2019-07-01--FRIENDLY_ENTITY_NAME',
  companyHqAddress: '2019-07-01--LOCATION_CONTAINER',
  companyHqState: '2019-07-01--HQ_PREQUAL_LOCATION',
  companyWebsiteUrl: '2019-07-01--WEBSITE',
  referralSource: '2019-07-01--HOW_DID_YOU_HEAR_ABOUT_US',
  referralSourceOther: '2019-07-01--HOW_DID_YOU_HEAR_ABOUT_US_OTHER',
  agreeTermsAndConditions: '2019-07-01--AGREED_TERMS_AND_CONDITIONS',
};

const FUNNEL_OVERRIDES = {
  rc: 'new',
  cj: 'old',
};

export default {
  name: 'Madlib',
  components: {
    MadlibSingle,
    MadlibProgressive,
    Recaptcha,
    LayoutCenteredCard,
  },
  dependencies: ['tracking', 'featureFlags'],
  props: {
    rcnb: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      isAuthBeforeMadlibFlagOn: false,
      isBizAddressFlagOn: false,
      isProgressiveDisclosureTestActive: false,
      isProgressiveFlagLoaded: false,
      isEmailFieldDisabled: false,
      isNewFunnelEnabled: false,
      isUserBucketedIntoNewFunnel: false,
      latestApplication: null,
      isFormValid: false,
      userFirstName: null,
      userLastName: null,
      userJobTitle: null,
      userJobTitleOther: null,
      userEmail: this.$route.query.email || null,
      companyName: null,
      companyHqAddress: {
        address1: null,
        address2: null,
        city: null,
        county: null,
        state: null,
        zipCode: null,
      },
      companyHqState: null,
      companyWebsiteUrl: null,
      referralSource: null,
      referralSourceOther: null,
      agreeTermsAndConditions: null,
      states: [],
      referralSourceOptions: [],
      loading: false,
      submissionError: false,
      referralPartner: this.$route.query.partner || null,
      offerCode: this.$route.query.offer_code || null,
      partnerReferralId: this.$route.query.partner_referral_id || null, // For Zebra phase 1
      rules: {
        userFirstName: value => !!value || 'Enter your first name.',
        userLastName: value => !!value || 'Enter your last name.',
        userJobTitle: value => !!value || 'Choose your title.',
        userJobTitleOther: value => !!value || 'Enter your title.',
        userEmail: value => {
          return !value
            ? 'Enter your email address.'
            : validateEmailAddress(value) || 'Enter a valid email address.';
        },
        companyResponsibility: value => !!value || 'Enter your title.',
        companyName: value => !!value || 'Enter your company name',
        companyHqState: value => !!value || 'Choose the state in which you are headquartered ',
        companyWebsiteUrl: value => !!value || 'Enter your website URL',
        referralSource: value => !!value || 'Choose a source',
        referralSourceOther: value => !!value || 'Enter a source',
        agreeTermsAndConditions: value => !!value || 'Please agree to continue',
      },
    };
  },
  computed: {
    formData() {
      return {
        isEmailFieldDisabled: this.isEmailFieldDisabled || false,
        shouldShowTaxShelterWarning: this.shouldShowTaxShelterWarning,
        userEmail: this.userEmail || null,
        loading: this.loading || false,
        referralPartner: this.referralPartner,
      };
    },

    shouldShowTaxShelterWarning() {
      return (
        (!this.isBizAddressFlagOn && this.companyHqState === States.delaware) ||
        (this.isBizAddressFlagOn && stateNameMap[this.companyHqAddress.state] === States.delaware)
      );
    },
    showGraphic() {
      return !['xs', 's', 'm'].includes(this.$mq) && !this.isProgressiveDisclosureTestActive;
    },
    hasReferralPartner() {
      return !!this.referralPartner;
    },
    errorReportPayload() {
      return {
        email: this.userEmail,
        companyName: this.companyName,
        companyUrl: this.companyWebsiteUrl,
      };
    },
  },
  async created() {
    this.isProgressiveDisclosureTestActive = false;
    // Temporarily Disabling Madlib Progressive Disclosure Experiment
    // const variant = await getProgressiveDisclosureExperiment();
    // this.isProgressiveDisclosureTestActive = variant === 'experiment';
    this.isProgressiveFlagLoaded = true;
  },
  async mounted() {
    await checkAuthentication();

    this.isBizAddressFlagOn = await this.featureFlags.getFlagWhenReady({
      flag: 'biz-address-on-madlib',
    });
    this.isAuthBeforeMadlibFlagOn = await this.featureFlags.getFlagWhenReady({
      flag: 'authenticate-before-madlib',
    });
    this.isNewFunnelEnabled = await newFunnelEnabled();
    this.isUserBucketedIntoNewFunnel = await this.featureFlags.getFlagWhenReady({
      flag: 'onboarding-distribution',
    });

    this.tracking.sendEvent(MADLIB_LOADED);

    const hasApp = await this.checkIfUserHasApplication();
    if (hasApp) {
      if (this.isNewFunnelEnabled && isNewFunnelApplication(this.latestApplication)) {
        routeToNewFunnel();
      }
    } else if (this.shouldSendUserWithoutAppToNewFunnel()) {
      routeToNewFunnel();
    } else {
      this.$auth.isAuthenticated ? this.markUserAsVueFunnel() : this.markAnonymousUserAsVueFunnel();
    }

    if (!this.$auth.isAuthenticated) {
      if (this.isAuthBeforeMadlibFlagOn) {
        this.showSignupWidget();
      }
    } else {
      this.setEmailFieldFromAuth();
    }
  },
  methods: {
    ...mapActions(['fetchPreCoreDecisionData']),
    ...mapMutations(['updateApplicationData', 'updatePreCoreDecisionData']),

    shouldSendUserWithoutAppToNewFunnel() {
      if (FUNNEL_OVERRIDES[this.rcnb] === 'old') return false;
      if (this.$auth.isAuthenticated && this.$auth.isNewFunnelUser) return true;

      const isNewFunnelUser =
        this.isUserBucketedIntoNewFunnel || FUNNEL_OVERRIDES[this.rcnb] === 'new';
      return this.isNewFunnelEnabled && isNewFunnelUser;
    },
    markAnonymousUserAsVueFunnel() {
      if (this.isNewFunnelEnabled) {
        window.analytics.identify({ 'web-app-rewrite-test': 'vue' });
      }
    },
    markUserAsVueFunnel() {
      if (this.isNewFunnelEnabled) {
        const email = this.$auth.getUserEmail();
        window.analytics.identify(email, { 'web-app-rewrite-test': 'vue' });
      }
    },

    setEmailFieldFromAuth() {
      this.userEmail = this.$auth.getUserEmail();
      this.$refs.madlibInputs.focusEmailInput();
      this.isEmailFieldDisabled = true;
    },
    showSignupWidget() {
      this.tracking.sendEvent(MADLIB_SIGNUP_WIDGET_VIEWED);
      this.$auth.showLogin({
        allowSignUp: true,
        initialScreen: 'signUp',
      });
    },

    executeRecaptcha() {
      if (!this.$refs.madlibForm.validate()) return;

      this.$refs.recaptcha.execute();
    },
    async onRecaptchaCallback(recaptchaToken) {
      let alreadyHasApplication = false;

      this.loading = true;
      this.submissionError = false;

      if (this.$auth.isAuthenticated) {
        alreadyHasApplication = await this.checkIfUserHasApplication();
      }
      if (alreadyHasApplication) {
        // If this user has an application already, route them accordingly
        this.$router.replace('/loginportal/send?appAttempt=true');
      } else {
        await this.updateLDWithUserEmail(this.userEmail);

        this.createAllTheThings(recaptchaToken);
      }
    },
    async createAllTheThings(recaptchaToken) {
      await this.createUser(recaptchaToken)
        .then(postUserResponse => this.createCompany(postUserResponse.data))
        .then(postCompanyResponse => this.createApplication(postCompanyResponse.data))
        .then(({ data }) => {
          // This response provides the applicationVersion that will be referenced for all
          // Program 1 vs Program 2 logic, routing, etc.
          this.updateApplicationData({ applicationData: data });
          return data;
        })
        .then(this.getApplicationPreCoreDecision)
        .then(this.handleApplicationPreCoreDecision)
        .catch(() => {
          this.submissionError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async createUser(recaptchaToken) {
      try {
        return await requests.postUser({
          recaptcha_token: recaptchaToken,
          '2019-07-01--EMAIL': this.userEmail,
          first_name: this.userFirstName,
          last_name: this.userLastName,
          lead_job_title: this.userJobTitle,
          lead_job_title_other: this.userJobTitleOther,
        });
      } catch (e) {
        this.$rollbar.critical('Error creating user', e, this.errorReportPayload);
        throw e;
      }
    },
    async createCompany(companyData) {
      if (this.isBizAddressFlagOn) {
        Object.assign(companyData, {
          company_name: this.companyName,
          address_1: this.companyHqAddress.address1,
          address_2: this.companyHqAddress.address2,
          city: this.companyHqAddress.city,
          county: this.companyHqAddress.county || '',
          state: this.companyHqAddress.state,
          zip_code: this.companyHqAddress.zipCode,
        });
      }

      try {
        return await madlibApi.createCompanies(companyData);
      } catch (e) {
        this.$rollbar.critical('Error creating company', e, this.errorReportPayload);
        throw e;
      }
    },
    async createApplication({ company_id }) {
      // When biz address flag is on, use the state from the address as the required application input
      const companyHqState = this.isBizAddressFlagOn
        ? stateNameMap[this.companyHqAddress.state]
        : this.companyHqState;

      try {
        return await requests.postApplication({
          // This version string is a lie: we set the property here to satisfy the validation logic that
          // relies on the schema, but the value is ignored by pa_shim, which sets its own value according
          // to feature flag, supported states, etc.
          sha_onboarding_funnel: ApplicationVersions.program1,

          LEAD_FIRST_NAME: this.userFirstName,
          LEAD_LAST_NAME: this.userLastName,
          LEAD_JOB_TITLE: this.userJobTitle,
          LEAD_JOB_TITLE_OTHER: this.userJobTitleOther,
          '2019-07-01--EMAIL': this.userEmail,
          '2019-07-01--FRIENDLY_ENTITY_NAME': this.companyName,
          '2019-07-01--HQ_PREQUAL_LOCATION': companyHqState,
          '2019-07-01--WEBSITE': this.companyWebsiteUrl,
          '2019-07-01--HOW_DID_YOU_HEAR_ABOUT_US': this.referralSource,
          '2019-07-01--HOW_DID_YOU_HEAR_ABOUT_US_OTHER': this.referralSourceOther,
          '2019-07-01--AGREED_TERMS_AND_CONDITIONS': this.agreeTermsAndConditions,
          '2019-07-01--CURRENCY_DENOMINATION': 'US',
          company_id,
          in_incubator_program: this.hasReferralPartner,
          incubator_program: this.hasReferralPartner ? this.referralPartner : null,
          offer_code: this.offerCode,
          // Optional Zebra param:
          partner_referral_id: this.partnerReferralId,
        });
      } catch (e) {
        this.$rollbar.critical('Error creating application', e, this.errorReportPayload);
        throw e;
      }
    },
    async getApplicationPreCoreDecision(applicationData) {
      await this.fetchPreCoreDecisionData({
        applicationId: applicationData.id,
      });

      const preCoreDecisionData = getPreCoreDecisionData(this);
      return {
        preCoreDecisionData,
        appData: applicationData,
      };
    },
    handleApplicationPreCoreDecision({ preCoreDecisionData, appData }) {
      const applicationId = appData.id;
      this.tracking.sendEvent(MADLIB_DECISION, {
        email: appData['2019-07-01--EMAIL'],
        decision: preCoreDecisionData.decision,
        decision_token: preCoreDecisionData.reason_token,
        application_id: applicationId,
        ...appData,
      });
      if (selectors.isNoDecision(preCoreDecisionData)) {
        const url = withCurrentQueryParams(onboardingUrl({ cardId: 'fin', applicationId }));
        this.$router.push(url);
        return;
      }
      this.redirectToFunnel({ appData, applicationId });
    },
    redirectToFunnel({ appData, applicationId }) {
      this.tracking.sendEvent(MADLIB_COMPLETE, {
        applicationId,
        email: appData['2019-07-01--EMAIL'],
        ...appData,
      });
      const url = withCurrentQueryParams(onboardingUrl({ cardId: 'prequal-start', applicationId }));
      this.$router.push(url);
    },
    async checkIfUserHasApplication() {
      if (!this.$auth.isAuthenticated) return false;
      if (this.latestApplication) return true;
      const applicationIndexResponse = await requests.getApplicationIndex();
      const applicationList = get(applicationIndexResponse, 'data.applications', []);
      if (applicationList.length < 1) return false;
      // Assuming ordered by createdAt, where the last one is the most recent:
      this.latestApplication = applicationList[applicationList.length - 1];
      return true;
    },
    async updateLDWithUserEmail(email) {
      await this.featureFlags.identify({ email });
    },
    onInputValueChange({ fieldName, value }) {
      this[fieldName] = value;
      this.trackFieldValueChanges(fieldName, this[fieldName]);
    },
    trackFieldValueChanges(key, value) {
      // Maintain continuity of Segment event payloads by using the old key values
      const legacyFieldName = LEGACY_FIELD_NAME_MAP[key];
      this.tracking.madlibEditedEvent({ key: legacyFieldName, value });
    },
    onFormSubmit() {
      this.executeRecaptcha();
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-shadow: none;
}

.graphic-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: $space-large;
}

.form-error {
  margin-top: $space-medium;
}

@media only screen {
  .madlib-graphic {
    background: url('~@/onboarding/assets/images/laptop-pedestal.png') no-repeat;
    width: gridUnit(30);
    height: gridUnit(45);
    background-size: contain;
    background-position: bottom right;
  }
}

// turn the auto-completed fields into calculated widths
// https://stackoverflow.com/questions/50155369/vuetify-calculate-grid-width-by-passed-props
</style>
